import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Supplier } from 'depoto-core/src/entities'
import { historyBack } from '../../utils'
import { NotificationService } from '../../services'
import { CoreService } from '../../services'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { TranslateService } from '@ngx-translate/core'

const supplierSchema = {
  id: null,
  name: null,
  externalId: null,
  company: {
    id: null,
    name: null,
  },
}

@Component({
  selector: 'app-supplier',
  templateUrl: 'supplier.component.html',
})
export class SupplierComponent implements OnInit, CheckDataChanges {
  supplier: Supplier
  isCreating = false
  loading = true
  submitted = false

  supplierForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, [Validators.required]),
  })

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private core: CoreService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
  }

  ngOnInit() {
    const id = this.route.snapshot.params['id']
    if (isNaN(id)) {
      this.isCreating = true
      this.supplier = new Supplier()
      this.loading = false
    } else {
      this.refreshData(id)
    }
  }

  public fillFormValues() {
    this.supplierForm.setValue({ name: this.supplier.name })
  }

  public getFormValues() {
    this.supplier.name = this.supplierForm.value.name
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  goBack(): void {
    historyBack()
  }

  async refreshData(id: number) {
    this.loading = true
    const supplier = await this.core.services.supplier.getById(id, supplierSchema)
    this.loading = false
    if (supplier) {
      this.supplier = new Supplier(supplier)
      this.fillFormValues()
    }
  }

  async createSupplier() {
    this.submitted = true
    if (this.supplierForm.invalid) {
      return
    }
    this.loading = true
    try {
      this.getFormValues()
      const result = await this.core.services.supplier.create(this.supplier)
      if (result) {
        this.notificationService.success(this.translateService.instant('alert.supplier.created'))
        this.router.navigate(['/supplier/', result.id], { replaceUrl: true })
      }
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
    }
  }

  async updateSupplier() {
    this.submitted = true
    if (this.supplierForm.invalid) {
      return
    }
    this.loading = true
    try {
      this.getFormValues()
      await this.core.services.supplier.update(this.supplier).then(result => {
        this.notificationService.success(this.translateService.instant('alert.supplier.updated'))
        this.refreshData(result.id)
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.submitted = false
      this.loading = false
    }
  }

  async deleteSupplier() {
    const confirmation = confirm(
      this.translateService.instant('suplier.delete.confirmation', { name: this.supplier.name })
    )
    if (confirmation) {
      try {
        await this.core.services.supplier.delete(this.supplier).then(() => {
          this.notificationService.success(this.translateService.instant('alert.supplier.deleted'))
        })
        historyBack()
      } catch (err) {
        console.error(err)
      }
    }
  }
  get formControls(): { [key: string]: AbstractControl } {
    return this.supplierForm.controls
  }
}
