import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { Customer } from 'depoto-core/src/entities'
import { CustomerSearchComponent } from '../../customer-search/customer-search.component'

@Component({
  selector: 'modal-customer-search',
  templateUrl: './modal-customer-search.component.html',
  styleUrls: ['modal-customer-search.component.scss'],
})
export class ModalCustomerSearchComponent {
  @ViewChild('modal') private childModal: ModalContainerComponent
  @ViewChild('customerSearch') private customerSearch: CustomerSearchComponent
  @Input() public customer: Customer
  @Input() public btnTitle: string
  @Input() dataCyAttribute: string
  @Output() customerChange: EventEmitter<any> = new EventEmitter()
  @Input() isOnOrdersPage = false

  public showChildModal(): void {
    this.childModal.showChildModal()
    if (this.customerSearch) {
      this.customerSearch.searchForCustomer()
    }
  }

  public onSelectedCustomer(customer: Customer) {
    this.customer = customer
    this.customerChange.emit(customer)
    this.childModal.hideChildModal()
  }

  public removeCustomer() {
    this.customer = null
    this.customerChange.emit(null)
  }
}
