import { Component, OnInit } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { CoreService, NotificationService } from '../../services'
import { printInventoryExportsColumns } from '../../utils/definitions.util'
import { SchemaUtil } from '../../utils'
import { TranslateService } from '@ngx-translate/core'

const DEFAULT_FILTERS = {
  // sortProp: 'created',
  // sortDir: 'desc',
}

@Component({
  selector: 'app-print-inventory-exports',
  templateUrl: 'print-inventory-exports.component.html',
})
export class PrintInventoryExportsComponent extends BaseListing implements OnInit {
  listingColumns: ListingItem[] = printInventoryExportsColumns
  reports: any[] = []
  totalItems = 0
  currentPage: number
  endPage: number
  // filters = { ...DEFAULT_FILTERS }
  loading = true
  storageKey: { type: string; key: string }
  sortProp = 'created'
  sortDir = 'desc'
  constructor(
    protected core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {
    super(core)
    this.getStorageKey()
  }

  ngOnInit() {
    // this.filters = this.loadViewSettings('printInventoryExports') || { ...DEFAULT_FILTERS }
    this.loadSorting('printInventoryExports')
    this.filter()
  }

  clearFilters(): void {
    this.filter(1)
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.currentPage = pageNo
    const result = await this.core.services.inventoryExport.getList(
      { page: pageNo, sort: sortProp, direction: sortDir },
      SchemaUtil.inventoryExport
    )
    if (result === undefined) return
    this.loading = false
    this.reloading = false
    if (!concat) {
      this.reports = result.items
    } else {
      result.items.forEach(ex => {
        this.reports.push(ex)
      })
    }
    this.totalItems = result.paginator ? result.paginator.totalCount : 0
    this.endPage = result.paginator ? result.paginator.endPage : 0
    this.currentPage++
    // this.saveViewSettings('printInventoryExports', this.filters)
  }

  async createExportInventory(inventoryExport) {
    this.loading = true
    try {
      this.startRefreshing('filter')
      await this.core.services.inventoryExport.create(inventoryExport, { id: null }).then(() => {
        this.notificationService.success(this.translateService.instant('alert.inventory.created'))
      })
      await this.filter(1)
    } catch (err) {
      console.error(err)
    } finally {
      this.reloading = false
      this.loading = false
    }
  }
}
