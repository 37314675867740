<div class="app-container">
  <page-header
    *ngIf="productMovePack"
    [icon]="'far-exchange'"
    [title]="'product-move-pack.' + productMovePack.type.id | translate"
    [appendNoTranslationTitlePart]="productMovePack.number.toString()"
  >
    <ng-container class="header-button">
      <div class="d-flex align-items-center flex-gap-5">
        <ng-container *ngIf="productMovePack.url?.length && productMovePack.pdfWOPricesUrl?.length">
          <btn
            id="dropdownPdf"
            [classes]="['btn-third', 'float-right', 'me-1', 'dropdown-toggle']"
            [icon]="'far-file-pdf'"
            [label]="'download.pdf' | translate"
            [title]="('select' | translate) + ' ' + ('type' | translate | lowercase)"
            [dataCyAttribute]="'download-pdf'"
            data-bs-toggle="dropdown"
          >
          </btn>
          <div class="dropdown-menu margin-right-150" aria-labelledby="dropdownPdf">
            <button class="dropdown-item small" type="button" (click)="downloadPdf('issueSlip')">
              {{
                productMovePack.type?.id === 'out'
                  ? ('download.pdf-issueSlip.out' | translate)
                  : productMovePack.type?.id === 'in'
                    ? ('download.pdf-issueSlip.in' | translate)
                    : ('download.pdf-issueSlip.transfer' | translate)
              }}
            </button>
            <button class="dropdown-item small" type="button" (click)="downloadPdf('deliveryNote')">
              {{ 'download.pdf-deliveryNote' | translate }}
            </button>
          </div>
          <btn
            *ngIf="productMovePack?.moves?.length > 0"
            [label]="isPrintingEANs ? 'back' : 'product.tab.barcode'"
            [title]="isPrintingEANs ? ('back' | translate) : ('product.tab.barcode' | translate)"
            [icon]="isPrintingEANs ? 'far-list' : 'far-sticky-note'"
            [classes]="['btn-third', 'float-right', 'me-2']"
            (onEvent)="isPrintingEANs = !isPrintingEANs"
          ></btn>
        </ng-container>
        <btn
          *ngIf="productMovePack.deletable && userCanDeleteProductMoves"
          [btnType]="'button'"
          [classes]="['btn-first-red', 'float-right', 'me-2']"
          [btnDisabled]="loading"
          [label]="'delete'"
          [icon]="'far-trash-alt'"
          [dataCyAttribute]="'delete-product'"
          (onEvent)="deleteProductMovePack()"
        ></btn>
      </div>
    </ng-container>
    <ng-container class="back-button">
      <btn
        [label]="'back'"
        [title]="'back' | translate"
        [isBackButton]="true"
        [classes]="['btn-third', 'float-right', 'me-2']"
        [icon]="'far-arrow-left'"
        (onEvent)="goBack()"
      ></btn>
    </ng-container>
  </page-header>
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <div class="container-fluid" *ngIf="!!productMovePack && isPrintingEANs">
    <div class="row">
      <div class="col-12">
        <!--            <barcode-list-print-array [operations]="operations"></barcode-list-print-array>-->
        <print-code #printCodeComponent [productMovePack]="productMovePack" [doRecalculateArray]="false"></print-code>
      </div>
    </div>
  </div>
  <div class="container-fluid" *ngIf="!!productMovePack && !isPrintingEANs">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 ps-0">
        <div class="card">
          <div>
            <h3 class="card-title mb-3">
              {{ 'info' | translate }}
            </h3>
          </div>
          <div>
            <table class="table m-0">
              <tbody>
                <tr>
                  <td class="ps-0 card-subheading no-hover">
                    {{ 'dateCreated' | translate }}
                  </td>
                  <td class="text-end no-hover">
                    {{ productMovePack.dateCreated | strToDate | date: 'dd.MM.yyyy HH:mm' }}
                  </td>
                </tr>
                <tr *ngIf="productMovePack.order && productMovePack.order.checkout">
                  <td class="ps-0 card-subheading no-hover">
                    {{ 'checkout' | translate }}
                  </td>
                  <td class="text-end no-hover">
                    <a class="link" [routerLink]="['/checkout', productMovePack.order.checkout.id]">{{
                      productMovePack.order.checkout ? productMovePack.order.checkout.name : ''
                    }}</a>
                  </td>
                </tr>
                <tr>
                  <td class="ps-0 card-subheading no-hover">
                    {{ 'createdBy' | translate }}
                  </td>
                  <td class="text-end no-hover">
                    <a
                      class="link"
                      *ngIf="productMovePack.createdBy"
                      [routerLink]="['/user', productMovePack.createdBy.id]"
                      >{{
                        productMovePack.createdBy.firstName +
                          ' ' +
                          productMovePack.createdBy.lastName +
                          ' (' +
                          productMovePack.createdBy.email +
                          ')'
                      }}</a
                    >
                  </td>
                </tr>
                <tr *ngIf="productMovePack.depotFrom?.id">
                  <td class="ps-0 card-subheading no-hover">{{ 'depotFrom' | translate }}</td>
                  <td class="text-end no-hover">
                    <a class="link" [routerLink]="['/depot', productMovePack.depotFrom.id]">{{
                      productMovePack.depotFrom.name
                    }}</a>
                  </td>
                </tr>
                <tr *ngIf="productMovePack.depotTo?.id">
                  <td class="ps-0 card-subheading no-hover">{{ 'depotTo' | translate }}</td>
                  <td class="text-end no-hover">
                    <a class="link" [routerLink]="['/depot', productMovePack.depotTo.id]">{{
                      productMovePack.depotTo.name
                    }}</a>
                  </td>
                </tr>
                <tr *ngIf="productMovePack.order?.vs > 0">
                  <td class="ps-0 card-subheading no-hover">{{ 'vs' | translate }}</td>
                  <td class="text-end">{{ productMovePack.order.vs ? productMovePack.order.vs : '' }}</td>
                </tr>
                <tr *ngIf="productMovePack.order?.id">
                  <td class="ps-0 card-subheading no-hover">
                    {{ 'product-move-pack.status.' + productMovePack.order.status?.id | translate }}
                  </td>
                  <td class="flex-gap-5 justify-content-end text-end no-hover">
                    <app-order-preview
                      [order]="productMovePack.order"
                      [isOnProductMovePackPage]="true"
                    ></app-order-preview>
                  </td>
                </tr>
                <tr *ngFor="let move of productMovePack.moves | slice: 0 : 1">
                  <td class="ps-0 card-subheading no-hover text-nowrap">
                    {{ 'bill.quantity' | translate }} / {{ 'bill.amount' | translate }}
                  </td>
                  <td class="text-end no-hover">
                    <div class="flex-gap-10 flex-row-reverse">
                      <strong>{{ quantityItemsSum | number: '1.0-2' | replaceCommaToDot }}</strong>
                      <strong>/</strong>
                      <strong>{{ productMovePack.moves | listLength }}</strong>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="productMovePack?.externalId">
                  <td class="ps-0 card-subheading no-hover">
                    {{ 'externalId' | translate }}
                  </td>
                  <td class="text-end no-hover">
                    {{ productMovePack.externalId }}
                  </td>
                </tr>
                <tr>
                  <td class="ps-0 card-subheading no-hover">
                    {{ 'externalReference' | translate }}
                  </td>
                  <td class="text-end no-hover">
                    {{ productMovePack.externalReference || '–' }}
                  </td>
                </tr>
                <tr>
                  <td class="ps-0 card-subheading no-hover">
                    {{ 'tags' | translate }}
                  </td>
                  <td class="d-flex gap-1 justify-content-right">
                    <form-row-select-multiple
                      [ngClass]="'width-20rem'"
                      [options]="tags"
                      [isTags]="true"
                      [(selectedValues)]="productMovePack.tags"
                      [dataCyAttribute]="'orders-tags-filter'"
                    ></form-row-select-multiple>
                    <btn
                      [label]="'save'"
                      [title]="'save' | translate"
                      [classes]="['btn-first']"
                      [icon]="'far-check'"
                      (click)="updateTags()"
                      [btnDisabled]="loading"
                      [isLoading]="loading"
                      [dataCyAttribute]="'create-depot'"
                    ></btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-8 pe-0">
        <div class="card" *ngIf="productMovePack?.note?.length">
          <div class="row col">
            <h3 class="card-title mb-3">
              {{ 'note' | translate }}
            </h3>
          </div>
          <div [innerHtml]="productMovePack.note | nl2br">
            {{ productMovePack?.note }}
          </div>
        </div>
        <div class="card" *ngIf="productMovePack?.files?.length">
          <div class="row col">
            <h3 class="card-title mb-3">
              {{ 'files' | translate }}
            </h3>
          </div>
          <div>
            <div *ngFor="let f of productMovePack.files">
              <i class="fa fa-file-image" *ngIf="f.mimeType?.includes('image/')" title="Image"></i>
              <i
                class="fa fa-file-text"
                *ngIf="
                  f.mimeType?.includes('text/') ||
                  f.mimeType?.includes('/msword') ||
                  f.mimeType?.includes('wordprocessingml')
                "
                title="Text"
              ></i>
              <i class="fa fa-file-pdf" *ngIf="f.mimeType?.includes('application/pdf')" title="Pdf"></i>
              <i
                class="fa fa-file-excel"
                *ngIf="f.mimeType?.includes('spreadsheet') || f.mimeType?.includes('ms-excel')"
                title="Excel"
              ></i>
              <i class="fa fa-file" *ngIf="unknownType(f.mimeType)" title="File"></i>
              <a class="link" href="{{ f.url }}" target="_blank">&ensp;{{ f.originalFilename || f.text || 'file' }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="productMovePack.moves?.length">
      <div class="col-12 col-md-12 ps-0 pe-0">
        <div class="card">
          <div class="box box-default">
            <div class="box-body">
              <div class="table-responsive">
                <table class="table m-0" [photoGalleryGroup]="{ index: 1 }">
                  <thead>
                    <tr>
                      <th>{{ 'product.name' | translate }}</th>
                      <th>{{ 'product.code' | translate }}</th>
                      <th>{{ 'product.ean' | translate }}</th>
                      <th>{{ 'product.batch' | translate }}</th>
                      <th>{{ 'expiration' | translate }}</th>
                      <th>{{ 'position' | translate }}</th>
                      <th
                        *ngIf="productMovePack.type.id === 'in' || (productMovePack.type.id === 'out' && hasOrderItems)"
                      >
                        {{ 'supplier' | translate }}
                      </th>
                      <th
                        *ngIf="
                          (productMovePack.type.id === 'out' && !hasOrderItems) ||
                          productMovePack.type.id === 'transfer'
                        "
                      >
                        {{ 'bill.vatrate' | translate }}
                      </th>
                      <th class="text-end">{{ 'purchasePrice' | translate }}</th>
                      <th
                        class="text-end"
                        *ngIf="
                          (productMovePack.type.id === 'out' && hasOrderItems) ||
                          productMovePack.type.id === 'reservation'
                        "
                      >
                        {{ 'sellPrice' | translate }}
                      </th>
                      <th class="text-end">{{ 'bill.qty' | translate }}</th>
                      <th class="text-end">
                        {{ hasOrderItems ? ('sellPrice.total' | translate) : ('purchasePrice.total' | translate) }}
                      </th>
                      <th *ngIf="isAnyOfMovesDeletable">&nbsp;</th>
                      <th *ngIf="isRequiredSerialNumbers">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let move of productMovePack.moves">
                    <!--todo: move.orderItem // has Order todo returns-->
                    <tr *ngIf="move?.orderItem">
                      <td *ngIf="move.orderItem.product?.id" class="ps-1 no-hover">
                        <div class="d-flex flex-column">
                          <div class="flex-gap-10 align-items-center">
                            <ng-template [ngIf]="move.orderItem.product.mainImage">
                              <div
                                class="align-items-center"
                                [photoGallery]="move.orderItem.product.mainImage.url"
                                [photoGalleryCaption]="
                                  move.orderItem.product.fullName +
                                  '<br>' +
                                  (move.orderItem.product.code
                                    ? ('product.code' | translate) + move.orderItem.product.code
                                    : '') +
                                  '<br>' +
                                  (move.orderItem.product.ean
                                    ? ('product.ean' | translate) + move.orderItem.product.ean
                                    : '')
                                "
                              >
                                <div class="images-item">
                                  <img
                                    class="images-item-image"
                                    [src]="getThumbUrl(move.orderItem.product.mainImage)"
                                    [alt]="move.orderItem.product.fullName"
                                  />
                                </div>
                              </div>
                            </ng-template>
                            <div class="flex-gap-5 align-items-center">
                              <a class="link-bold" [routerLink]="['/product', move.orderItem.product.id]">{{
                                move.orderItem.product.fullName
                              }}</a>
                              <span
                                *ngIf="
                                  move.orderItem.product.quantityUnavailable > 0 &&
                                  productMovePack.type.id === 'reservation'
                                "
                                class="badge bg-red badge-unavail"
                                title="{{ 'unavailables' | translate }}"
                              >
                                {{
                                  (move.orderItem.product.quantityUnavailable | number: '1.0-2' | replaceCommaToDot) +
                                    (move.orderItem.product && move.orderItem.product.quantityUnavailable > 0
                                      ? '/' +
                                        (move.orderItem.product.quantityUnavailable
                                          | number: '1.0-2'
                                          | replaceCommaToDot)
                                      : '')
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="no-hover" *ngIf="move.orderItem && !move.orderItem.product">
                        <strong>&nbsp;</strong>
                      </td>
                      <td class="no-hover text-nowrap">
                        {{ move.orderItem?.product?.code ?? '' }}
                      </td>
                      <td class="no-hover text-nowrap">
                        {{ move.orderItem?.product?.ean ?? '' }}
                      </td>
                      <td class="no-hover">
                        {{ move.productDepotFrom?.batch || move.productDepotTo?.batch || '' }}
                      </td>
                      <td class="no-hover">
                        {{
                          move.productDepotFrom?.expirationDate?.length
                            ? (move.productDepotFrom.expirationDate | fmtDate: 'dd.MM.yyyy')
                            : move.productDepotTo?.expirationDate?.length
                              ? (move.productDepotTo.expirationDate | fmtDate: 'dd.MM.yyyy')
                              : ''
                        }}
                      </td>
                      <td class="no-hover">
                        {{
                          move.productDepotFrom?.position?.length > 2
                            ? move.productDepotFrom.position
                            : move.productDepotTo?.position?.length > 2
                              ? move.productDepotTo.position
                              : ''
                        }}
                      </td>
                      <td class="no-hover">
                        {{ move.productDepotFrom?.supplier?.name || '' | translate }}
                      </td>
                      <td class="no-hover text-nowrap text-end">
                        {{ move.purchasePrice ?? 0 | formatPrice }}
                      </td>
                      <td
                        class="no-hover text-nowrap text-end"
                        *ngIf="productMovePack.type.id === 'out' || productMovePack.type.id === 'reservation'"
                      >
                        {{ move.orderItem?.priceWithoutVat ?? 0 | formatPrice }}
                      </td>
                      <td class="no-hover text-end">{{ move.amount | number: '1.0-2' | replaceCommaToDot }}</td>
                      <td class="no-hover text-nowrap text-end" *ngIf="productMovePack.type.id === 'in'">
                        {{ move.orderItem?.priceWithoutVat ?? 0 | formatPrice }}
                      </td>
                      <td class="no-hover text-nowrap text-end" *ngIf="productMovePack.type.id !== 'in'">
                        {{ (move.orderItem ? move.orderItem.priceWithoutVat * move.amount : 0) | formatPrice }}
                      </td>
                      <td>
                        <btn
                          *ngIf="move.deletable && userCanDeleteProductMoves"
                          [btnType]="'button'"
                          [classes]="['btn-trash', 'btn-sm', 'float-right']"
                          (onEvent)="deleteProductMove(move)"
                          [icon]="'far-trash-alt'"
                          [title]="'delete' | translate"
                          data-cy="delete-row-category"
                        ></btn>
                      </td>
                      <td *ngIf="isRequiredSerialNumbers">
                        <app-modal-serial-numbers
                          #modalSerialNumbers
                          *ngIf="move.product.requiredSerialNumbers"
                          [productMove]="move"
                          (serialNumbersEdited)="updateProductMovePack()"
                        >
                        </app-modal-serial-numbers>
                      </td>
                    </tr>
                    <tr *ngIf="move && !move.orderItem && !!move.productDepotTo && !move.productDepotFrom">
                      <td class="ps-1 no-hover" *ngIf="move.productDepotTo?.product?.id">
                        <div class="flex-gap-10 align-items-center" [photoGalleryGroup]="{ index: 2 }">
                          <div
                            [photoGallery]="move.productDepotTo.product.mainImage.url"
                            [photoGalleryCaption]="
                              move.productDepotTo.product.fullName +
                              '<br>' +
                              (move.productDepotTo.product.code
                                ? ('product.code' | translate) + move.productDepotTo.product.code
                                : '') +
                              '<br>' +
                              (move.productDepotTo.product.ean
                                ? ('product.ean' | translate) + move.productDepotTo.product.ean
                                : '')
                            "
                          >
                            <div class="images-item">
                              <img
                                class="images-item-image"
                                [src]="getThumbUrl(move.productDepotTo.product.mainImage)"
                                [alt]="move.productDepotTo.product.fullName"
                              />
                            </div>
                          </div>
                          <div>
                            <div>
                              <a class="link-bold" [routerLink]="['/product', move.productDepotTo.product.id]">
                                {{ move.productDepotTo.product.fullName }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="no-hover" *ngIf="move.productDepotTo && !move.productDepotTo.product">
                        <strong>&nbsp;</strong>
                      </td>
                      <td class="text-nowrap no-hover">
                        {{ move.productDepotTo && move.productDepotTo.product ? move.productDepotTo.product.code : '' }}
                      </td>
                      <td class="text-nowrap no-hover">
                        {{ move.productDepotTo && move.productDepotTo.product ? move.productDepotTo.product.ean : '' }}
                      </td>
                      <td class="text-nowrap no-hover">
                        {{ move.productDepotTo?.batch ?? '' }}
                      </td>
                      <td class="text-nowrap no-hover">
                        {{
                          move.productDepotTo?.expirationDate?.length
                            ? (move.productDepotTo.expirationDate | fmtDate: 'dd.MM.yyyy')
                            : ''
                        }}
                      </td>
                      <td class="text-nowrap no-hover">
                        {{
                          !!move.productDepotTo.position && move.productDepotTo.position.length > 2
                            ? move.productDepotTo.position
                            : ''
                        }}
                      </td>
                      <td class="no-hover">
                        {{ move.productDepotTo?.supplier?.name || '' | translate }}
                      </td>
                      <td class="text-nowrap no-hover text-end">
                        {{ move.productDepotTo?.purchasePrice ?? 0 | formatPrice }}
                      </td>
                      <td class="text-nowrap no-hover text-end">
                        {{ move.amount | number: '1.0-2' | replaceCommaToDot }}
                      </td>
                      <td class="text-nowrap no-hover text-end" *ngIf="!!move.productDepotTo">
                        {{ move.productDepotTo.purchasePrice * move.amount | formatPrice }}
                      </td>
                      <td>
                        <btn
                          *ngIf="move.deletable && userCanDeleteProductMoves"
                          [btnType]="'button'"
                          [classes]="['btn-trash', 'btn-sm', 'float-right']"
                          (onEvent)="deleteProductMove(move)"
                          [icon]="'far-trash-alt'"
                          [title]="'delete' | translate"
                          data-cy="delete-row-category"
                        ></btn>
                      </td>
                      <td *ngIf="isRequiredSerialNumbers">
                        <app-modal-serial-numbers
                          *ngIf="move.product?.requiredSerialNumbers"
                          [productMove]="move"
                          (serialNumbersEdited)="updateProductMovePack()"
                        >
                        </app-modal-serial-numbers>
                      </td>
                    </tr>

                    <tr *ngIf="move && !move.orderItem && !!move.productDepotFrom && !move.productDepotTo">
                      <td class="no-hover" *ngIf="move.productDepotFrom.product?.id">
                        &nbsp;
                        <a class="link" [routerLink]="['/product', move.productDepotFrom.product.id]"
                          ><strong>{{ move.productDepotFrom.product.fullName }}</strong></a
                        >
                      </td>
                      <td class="no-hover" *ngIf="!move.productDepotFrom.product">
                        <strong>&nbsp;</strong>
                      </td>
                      <td class="no-hover">
                        {{ move.productDepotFrom.product?.code ?? '' }}
                      </td>
                      <td class="no-hover">
                        {{ move.productDepotFrom.product?.ean ?? '' }}
                      </td>
                      <td class="no-hover">
                        {{ move.productDepotFrom.batch ?? '' }}
                      </td>
                      <td class="no-hover">
                        {{
                          move.productDepotFrom.expirationDate?.length
                            ? (move.productDepotFrom.expirationDate | fmtDate: 'dd.MM.yyyy')
                            : ''
                        }}
                      </td>
                      <td class="no-hover">
                        {{ move.productDepotFrom.position?.length > 2 ? move.productDepotFrom.position : '' }}
                      </td>
                      <td class="no-hover">{{ move.productDepotFrom.product?.vat?.percent ?? 0 }}%</td>
                      <td class="text-nowrap no-hover text-end">
                        {{ move.productDepotFrom.purchasePrice ?? 0 | formatPrice }}
                      </td>
                      <td class="no-hover text-end">{{ move.amount | number: '1.0-2' | replaceCommaToDot }}</td>
                      <td class="text-nowrap no-hover text-end">
                        {{ move.productDepotFrom.purchasePrice * move.amount | formatPrice }}
                      </td>
                      <td>
                        <btn
                          *ngIf="move.deletable && userCanDeleteProductMoves"
                          [btnType]="'button'"
                          [classes]="['btn-trash', 'btn-sm', 'float-right']"
                          (onEvent)="deleteProductMove(move)"
                          [icon]="'far-trash-alt'"
                          [title]="'delete' | translate"
                          data-cy="delete-row-category"
                        ></btn>
                      </td>
                      <td *ngIf="isRequiredSerialNumbers">
                        <app-modal-serial-numbers
                          *ngIf="move.product.requiredSerialNumbers"
                          [productMove]="move"
                          (serialNumbersEdited)="updateProductMovePack()"
                        >
                        </app-modal-serial-numbers>
                      </td>
                    </tr>
                    <!--todo: move.productDepotFrom && to // transfer-->
                    <tr *ngIf="move && !move.orderItem && !!move.productDepotFrom && !!move.productDepotTo">
                      <td class="no-hover" *ngIf="move.productDepotFrom.product?.id">
                        &nbsp;
                        <a class="link" [routerLink]="['/product', move.productDepotFrom.product.id]"
                          ><strong>{{ move.productDepotFrom.product.fullName }}</strong></a
                        >
                      </td>
                      <td class="no-hover" *ngIf="!move.productDepotFrom.product">
                        <strong>&nbsp;</strong>
                      </td>
                      <td class="no-hover">
                        {{ move.productDepotFrom.product?.code ?? '' }}
                      </td>
                      <td class="no-hover">
                        {{ move.productDepotFrom.product?.ean ?? '' }}
                      </td>
                      <td class="no-hover">
                        <span *ngIf="move.productDepotFrom.batch || move.productDepotTo.batch">
                          {{ (move.productDepotFrom.batch || '-') + ' -> ' + (move.productDepotTo.batch || '-') }}
                        </span>
                      </td>
                      <td class="no-hover">
                        <span *ngIf="move.productDepotFrom.expirationDate || move.productDepotTo.expirationDate">
                          {{
                            (move.productDepotFrom.expirationDate?.length
                              ? (move.productDepotFrom.expirationDate | fmtDate: 'dd.MM.yyyy')
                              : '-') +
                              ' -> ' +
                              (move.productDepotTo.expirationDate?.length
                                ? (move.productDepotTo.expirationDate | fmtDate: 'dd.MM.yyyy')
                                : '-')
                          }}
                        </span>
                      </td>
                      <td class="no-hover">
                        <span
                          *ngIf="move.productDepotFrom.position?.length > 2 || move.productDepotTo.position?.length > 2"
                        >
                          {{
                            (move.productDepotFrom.position?.length > 2 ? move.productDepotFrom.position : '-') +
                              ' -> ' +
                              (move.productDepotTo.position?.length > 2 ? move.productDepotTo.position : '')
                          }}
                        </span>
                      </td>
                      <td class="no-hover">{{ move.productDepotFrom.product?.vat?.percent ?? 0 }}%</td>
                      <td class="text-nowrap no-hover text-end">
                        {{ move.productDepotFrom.purchasePrice || 0 | formatPrice }}
                      </td>
                      <td class="no-hover text-end">{{ move.amount | number: '1.0-2' | replaceCommaToDot }}</td>
                      <td class="text-nowrap no-hover text-end">
                        {{ move.productDepotFrom.purchasePrice * move.amount | formatPrice }}
                      </td>
                      <td>
                        <btn
                          *ngIf="move.deletable && userCanDeleteProductMoves"
                          [btnType]="'button'"
                          [classes]="['btn-trash', 'btn-sm', 'float-right']"
                          (onEvent)="deleteProductMove(move)"
                          [icon]="'far-trash-alt'"
                          [title]="'delete' | translate"
                          data-cy="delete-row-category"
                        ></btn>
                      </td>
                    </tr>
                    <tr *ngIf="move?.note?.length">
                      <td class="no-hover" colspan="8"><i [innerHtml]="move.note | nl2br"></i></td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td class="td-invisible border-0 no-hover" [colSpan]="6">&nbsp;</td>
                      <td
                        *ngIf="productMovePack.type.id === 'in' || (productMovePack.type.id === 'out' && hasOrderItems)"
                      >
                        &nbsp;
                      </td>
                      <td
                        *ngIf="
                          (productMovePack.type.id === 'out' && !hasOrderItems) ||
                          productMovePack.type.id === 'transfer'
                        "
                      >
                        &nbsp;
                      </td>
                      <td
                        *ngIf="
                          (productMovePack.type.id === 'out' && hasOrderItems) ||
                          productMovePack.type.id === 'reservation'
                        "
                      >
                        &nbsp;
                      </td>
                      <td *ngIf="productMovePack.type.id === 'reservation'">&nbsp;</td>
                      <td class="border-0">
                        <b>{{ 'total' | translate }}</b>
                      </td>
                      <td class="text-end">
                        {{ getQuantitySum() | number: '1.0-2' | replaceCommaToDot }}
                      </td>
                      <td class="text-end">
                        {{ getAmountSum() | formatPrice }}
                      </td>
                      <td *ngIf="isAnyOfMovesDeletable" class="td-invisible no-hover">&nbsp;</td>
                      <td *ngIf="isRequiredSerialNumbers">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
