import { Component, OnInit, OnDestroy } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { CoreService, NotificationService } from '../../services'
import { SchemaUtil } from '../../utils'
import { TranslateService } from '@ngx-translate/core'
import { printOrderExportsColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  // sortProp: 'created',
  // sortDir: 'desc',
}

@Component({
  selector: 'app-print-order-exports',
  templateUrl: 'print-order-exports.component.html',
})
export class PrintOrderExportsComponent extends BaseListing implements OnInit, OnDestroy {
  listingColumns: ListingItem[] = printOrderExportsColumns
  reports: any[] = []
  totalItems = 0
  currentPage: number
  endPage: number
  // filters = { ...DEFAULT_FILTERS }
  refreshInterval: any = null
  loading = true
  storageKey: { type: string; key: string }
  sortProp = 'created'
  sortDir = 'desc'

  constructor(
    protected core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {
    super(core)
    this.getStorageKey()
  }

  ngOnInit() {
    // this.filters = this.loadViewSettings('printOrderExports') || { ...DEFAULT_FILTERS }
    this.loadSorting('printOrderExports')
    this.filter()
  }

  ngOnDestroy() {
    this.stopRefreshing()
  }

  clearFilters(): void {
    this.filter(1)
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.currentPage = pageNo
    const result = await this.core.services.orderExport.getList(
      { page: pageNo, sort: sortProp, direction: sortDir },
      SchemaUtil.exportOrders
    )
    if (result === undefined) return
    this.loading = false
    this.reloading = false
    if (!concat) {
      this.reports = result.items
    } else {
      result.items.forEach(ex => {
        this.reports.push(ex)
      })
    }
    this.totalItems = result.paginator ? result.paginator.totalCount : 0
    this.endPage = result.paginator ? result.paginator.endPage : 0
    this.currentPage++
    // this.saveViewSettings('printOrderExports', this.filters)
  }

  async createExportOrders(ordersExport) {
    this.loading = true
    try {
      this.startRefreshing('filter')
      await this.core.services.orderExport.create(ordersExport, { id: null }).then(() => {
        this.notificationService.success(this.translateService.instant('alert.order-summary.created'))
      })
      await this.filter(1)
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }

  downloadReport(report: any) {
    this.core.services.download.downloadDocumentAndShowInNewWindow(report.url)
  }
}
