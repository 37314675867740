<div class="card">
  <div class="card-body p-0" [ngClass]="{ h150: loading }">
    <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
    <div class="col-12" *ngIf="isLoaded && (!priceLevels || (priceLevels && !priceLevels.length))">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <p class="m-0">
            {{ 'product-price-levels.no-price-group.1' | translate }}
            <a [routerLink]="['/price-levels']">{{ 'product-price-levels.no-price-group.2' | translate }}</a
            >.
          </p>
        </div>
      </div>
    </div>

    <div class="row margin-bottom" *ngIf="productPriceLevels && productPriceLevelsFixed.length">
      <div class="col-12">
        <h3 class="card-title">
          {{ 'productPriceLevels.title.fixed' | translate }}
        </h3>
      </div>
    </div>

    <table class="margin-bottom">
      <thead>
        <tr>
          <th>
            {{ 'title' | translate }}
          </th>
          <th>
            {{ 'value' | translate }}
          </th>
          <th>
            {{ 'priceLevel.withVat' | translate }}
          </th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody *ngIf="!productPriceLevelsFixed?.length">
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <p class="m-0">
              V systému nejsou zadány žádné cenové skupiny s fixní částkou. Vytvořit je můžete v
              <a [routerLink]="['/price-levels']">nastavení cenových skupin</a>.
            </p>
          </div>
        </div>
      </tbody>
      <tbody *ngIf="productPriceLevelsFixed?.length">
        <tr *ngFor="let productPriceLevel of productPriceLevelsFixed; let i; of: productPriceLevelsFixed">
          <td>
            <p class="m-0">{{ productPriceLevel.priceLevel.name }}</p>
          </td>
          <td class="d-flex align-items-baseline margin-top-8-px">
            <form-row-input
              [label]=""
              [(model)]="productPriceLevel.sellPrice"
              (modelChange)="productPriceLevelChanged($event, productPriceLevel)"
              [replaceComma]="true"
              [appendUnitCurrency]="true"
              [currency]="productPriceLevel.priceLevel.currency"
              [dataCyAttribute]="'proudct-price-level-sell-price'"
            >
            </form-row-input>
          </td>
          <td class="text-center">
            <span class="badge bg-success" *ngIf="productPriceLevel?.priceLevel?.withVat">
              {{ 'yes' | translate }}
            </span>
            <span class="badge bg-danger" *ngIf="!productPriceLevel?.priceLevel?.withVat">
              {{ 'no' | translate }}
            </span>
          </td>
          <td class="text-center">
            <btn
              *ngIf="productPriceLevel.id"
              [icon]="'far-trash'"
              [classes]="['btn-first-red', 'float-right', 'ms-2']"
              (onEvent)="deleteProductPriceLevel(productPriceLevel)"
            >
            </btn>
          </td>
          <td>
            <btn
              *ngIf="productDetailService.product.children?.length > 0 && productPriceLevel.id"
              [title]="'insert.values.into.variants'"
              [icon]="'far-chevron-right'"
              [classes]="['btn-second', 'float-right', 'ms-2']"
              [btnDisabled]="
                loading ||
                productPriceLevel.sellPrice === 0 ||
                productPriceLevel.sellPrice === null ||
                productPriceLevel.sellPrice === undefined
              "
              (onEvent)="updateProductChildrenWithProductPriceLevel(productPriceLevel)"
            >
            </btn>
          </td>
        </tr>
        <tr>
          &nbsp;
        </tr>
        <tr>
          <div col="5" class="row margin-bottom">
            <div class="col-12">
              <h3 class="card-title">
                {{ 'productPriceLevels.title.percent' | translate }}
              </h3>
            </div>
          </div>
        </tr>

        <tr *ngIf="productPriceLevels && !productPriceLevelsPercent.length">
          <div col="5" class="row">
            <div class="col-12 d-flex justify-content-center">
              <p class="m-0">
                V systému nejsou zadány žádné cenové skupiny s fixní částkou. Vytvořit je můžete v
                <a [routerLink]="['/price-levels']">nastavení cenových skupin</a>.
              </p>
            </div>
          </div>
        </tr>

        <ng-container *ngIf="productPriceLevels && productPriceLevelsPercent.length">
          <tr *ngFor="let productPriceLevel of productPriceLevelsFixed; let i; of: productPriceLevelsPercent">
            <td>
              <p class="m-0">{{ productPriceLevel.priceLevel.name }}</p>
            </td>
            <td>
              <div class="text-start text-nowrap">
                {{ productPriceLevel.priceLevel.percent + ' %' }}
              </div>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
