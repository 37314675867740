<div class="app-container">
  <page-header [icon]="'far-credit-card'" [title]="'payments'">
    <ng-container class="header-button">
      <btn
        *ngIf="canCreate"
        [routerLink]="['/payment']"
        [classes]="['btn-first', 'float-right']"
        [icon]="'far-plus'"
        [label]="'payment.create'"
        [title]="'payment.create' | translate"
        [dataCyAttribute]="'go-payment'"
      ></btn>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form
      [filters]="filters"
      [entity]="'payments'"
      (onSubmit)="filter()"
      (onClearClick)="clearFilters()"
      [isFilterInvisible]="true"
    >
      <form-row-input
        [(model)]="filters.name"
        [icon]="'far-list'"
        [label]="'payment.name'"
        (keydown.enter)="filter()"
        [dataCyAttribute]="'payment-name-filter'"
      ></form-row-input>
    </app-filter-form>

    <app-base-listing
      [listName]="'payments'"
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="payments"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [(sortProp)]="sortProp"
      [(sortDir)]="sortDir"
      (reloadList)="filter(1)"
      (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
      (callAction)="callAction($event)"
    ></app-base-listing>
  </section>
</div>
