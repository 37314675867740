import { ErrorHandler as DefaultErrorHandler, Injectable } from '@angular/core'
import { DevLog } from 'depoto-core/src/entities'
import { Events } from 'depoto-core/src/utils'

@Injectable()
export class ErrorHandler extends DefaultErrorHandler {
  constructor() {
    super()
  }

  public handleError(e: any): void {
    const log = new DevLog({
      name: DevLog.names.error,
      content: JSON.stringify({
        msg: e.message,
        url: e.url || window.location.href,
        lineNo: e.lineNo,
        columnNo: e.columnNo,
        stack: e.stack,
      }),
    })
    Events.dispatch('logger-error', log)
    super.handleError(e)
  }
}
