import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core'
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown'

export interface DropdownListItem {
  action: string
  label: string
  isSelected: boolean
}

@Component({
  selector: 'dropdown',
  templateUrl: 'dropdown.component.html',
  styles: [
    `
      .selected {
        font-weight: 600;
      }
    `,
  ], // todo
  styleUrls: ['dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnChanges {
  @ViewChild('dropdown') dropdown: BsDropdownDirective
  @Input() label: string
  @Input() theme = 'primary'
  @Input() items: DropdownListItem[] = []
  @Input() classes: string[] = []
  @Input() isArrowHidden = false
  @Input() autoClose = false
  @Input() icon = 'fa-list'
  @Output() onResult: EventEmitter<any> = new EventEmitter()
  classesString = ''
  iconClassesString = ''
  // isOpen: boolean;

  @HostListener('document:click', ['$event'])
  closeDropdownList(event): void {
    if (!this.autoClose) {
      return
    }
    const isClickedOutsideDropdown =
      (event.path || (event.composedPath && event.composedPath())).filter(
        t => t && t.className && t.className.includes('dropdown')
      ).length === 0
    if (this.dropdown.isOpen && isClickedOutsideDropdown) {
      this.dropdown.isOpen = false
    }
  }

  ngOnInit() {
    this.updateComponent()
  }

  ngOnChanges(changes) {
    // console.warn('changes', changes); // TODO: remove
    this.updateComponent()
  }

  onItemClick(event: any): void {
    // console.warn('DROPDOWN:ITEM:CLICK', event); // TODO: remove
    this.onResult.emit(event)
  }

  private updateComponent(): void {
    let classesAdditional = ''
    if (this.classes && this.classes.length > 0) {
      this.classes.forEach(cls => {
        classesAdditional += ' ' + cls
      })
    }
    this.classesString = classesAdditional

    // icon
    let iconClasses = ''
    if (this.icon && this.icon.length > 0) {
      iconClasses += this.icon.split('-')[0]
      iconClasses +=
        ' ' +
        this.icon.replace('far-', 'fa-').replace('fas-', 'fa-') +
        (this.label && this.label.length > 0 ? ' me-2' : '')
    }
    this.iconClassesString = iconClasses
  }
}
