import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, FormRowBase } from '../form-row-base/form-row-base'

@Component({
  selector: 'form-row-select',
  templateUrl: 'form-row-select.component.html',
  styleUrls: ['form-row-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR(FormRowSelectComponent)],
})
export class FormRowSelectComponent extends FormRowBase implements OnInit, OnChanges {
  @ViewChild('formRow') formRow: ElementRef
  @Input() label: string
  @Input() translationPrefix: string
  @Input() tooltipLabel: string
  @Input() tooltipIcon: string
  @Input() options: any[]
  @Input() optionKey = 'id' // in case of options object, specify prop
  @Input() optionKeyText = 'name' // in case of options object, specify prop to show on option textValue
  @Input() model: any
  @Input() required = false
  @Output() modelChange: EventEmitter<any> = new EventEmitter()
  @Output() valueChangeNative: EventEmitter<any> = new EventEmitter()
  @Output() onKeyPress: EventEmitter<any> = new EventEmitter()
  @Input() inputDisabled = false
  @Input() noMarginBottom = false
  @Input() classes: string[] = []
  @Input() icon = null
  @Input() btnLabel: string
  @Input() btnClasses: string[] = []
  @Input() btnIcon: string
  @Input() trackCategory = 'event-click-select'
  @Input() trackAction: string
  @Input() bindToObject = false
  @Output() onBtnEvent: EventEmitter<any> = new EventEmitter()
  @Input() formControlName: string
  @Input() appendUnitCm: boolean
  @Input() appendUnitUS: boolean
  @Input() appendUnitUK: boolean
  @Input() appendUnitEU: boolean
  @Input() formGroup: FormGroup
  @Input() requiredCssClass: any
  @Input() isSelectDisabled: boolean
  @Input() dataCyAttribute: string
  @Input() cypressSelector: string
  private classesDefault = 'form-control'
  classesString = ''
  iconClassesString = ''

  constructor() {
    super()
  }

  ngOnInit() {
    this.updateComponent()
    this.initFormControl()
  }

  ngOnChanges() {
    this.updateComponent()
  }

  onBtnClick(event: any): void {
    this.onBtnEvent.emit(event)
  }

  onInputValueChange(event: Event): void {
    this.onChange(event)
    this.valueChangeNative.emit(event)
  }

  onNgModelChange(value: string | unknown): void {
    this.modelChange.emit(value)
    if (this.required && !this.formControlName) {
      this.errors = value ? [] : ['required']
    }
  }

  onInputKeyPress(value: string): void {
    this.onKeyPress.emit(value)
  }

  compareWithFn = (object1: any, object2: any): boolean => {
    return object1 && object2 ? object1[this.optionKey] === object2[this.optionKey] : object1 === object2
  }

  private updateComponent(): void {
    let classesAdditional = ''
    if (this.classes && this.classes.length > 0) {
      this.classes.forEach(cls => {
        classesAdditional += ' ' + cls
      })
    }
    this.classesString = this.classesDefault + classesAdditional

    // icon
    let iconClasses = ''
    if (this.icon && this.icon.length > 0) {
      iconClasses += this.icon.split('-')[0]
      iconClasses += ' ' + this.icon.replace('far-', 'fa-').replace('fas-', 'fa-')
    }
    this.iconClassesString = iconClasses
    if (!this.trackAction) {
      this.trackAction = this.label
    }
  }
}
