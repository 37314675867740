<div #formRow class="form-group d-flex flex-column" [ngClass]="{ 'mb-0': noMarginBottom }">
  <label *ngIf="label?.length" class="d-flex justify-content-between">
    {{ label | translate }}
    <info-tooltip *ngIf="tooltipLabel?.length" [label]="tooltipLabel" [icon]="tooltipIcon"></info-tooltip>
    <label class="float-right required" *ngIf="required"> ({{ 'required' | translate }}) </label>
  </label>
  <div class="input-group" style="width: -webkit-fill-available">
    <div class="input-group-prepend" *ngIf="icon?.length"></div>
    <select
      class="form-control"
      [class]="classesString"
      [ngModel]="model"
      *ngIf="inputDisabled && !bindToObject"
      [attr.data-cy]="dataCyAttribute"
      disabled
    >
      <option *ngFor="let opt of options" value="{{ opt?.[optionKey] ?? opt }}">
        {{ opt?.[optionKey] ?? (translationPrefix ?? '') + opt | translate }}
      </option>
    </select>
    <select
      [ngClass]="{
        'is-invalid': requiredCssClass
      }"
      class="form-control"
      [attr.required]="required"
      [class]="classesString"
      [ngModel]="model"
      (ngModelChange)="onNgModelChange($event)"
      (keyup)="onInputKeyPress($event)"
      (change)="onInputValueChange($event)"
      (blur)="onChange($event)"
      *ngIf="!inputDisabled && !bindToObject"
      [attr.data-track-category]="trackCategory"
      [attr.data-track-action]="trackAction"
      [attr.data-cy]="dataCyAttribute"
      [disabled]="isSelectDisabled"
    >
      <option *ngFor="let opt of options" value="{{ opt?.[optionKey] ?? opt }}">
        {{ opt?.[optionKeyText] ?? (translationPrefix ?? '') + opt | translate }}
      </option>
    </select>
    <select
      class="form-control"
      [compareWith]="compareWithFn"
      *ngIf="bindToObject"
      [ngModel]="model"
      (ngModelChange)="onNgModelChange($event)"
      (blur)="onChange($event)"
      [attr.data-cy]="dataCyAttribute"
    >
      <option *ngFor="let opt of options" [ngValue]="opt">
        {{ opt?.[optionKeyText] ?? (translationPrefix ?? '') + opt | translate }}
      </option>
    </select>
    <div class="input-group-btn" *ngIf="btnLabel?.length || btnIcon?.length">
      <btn [label]="btnLabel" [classes]="btnClasses" [icon]="btnIcon" (onEvent)="onBtnClick($event)"></btn>
    </div>
    <div
      *ngIf="requiredCssClass"
      [ngClass]="{ 'invalid-feedback': requiredCssClass }"
      style="color: #f0142f"
      data-cy="error_msg"
    >
      {{ errors | formError: formControlName | translate }}
    </div>
  </div>
  <span class="input-group-unit" *ngIf="appendUnitEU"> EU </span>
  <span class="input-group-unit" *ngIf="appendUnitUK"> UK </span>
  <span class="input-group-unit" *ngIf="appendUnitUS"> US </span>
  <span class="input-group-unit" *ngIf="appendUnitCm"> cm </span>
</div>
