<modal-container #modal [headerTitle]="modalTitle" [isLargeModal]="true">
  <ng-container class="modal-button-show">
    <btn data-cy="modal-address-btn" [label]="btnLabel" [title]="btnTitle | translate" [classes]="btnClasses" (onEvent)="showChildModal()" [icon]="btnIcon" [attr.data-cy]="dataCyAttribute"></btn>
  </ng-container>
  <ng-container class="modal-body-content">
    <div class="card">
      <div *ngIf="address" class="operation-form">
        <div class="form-row">
          <div class="col-sm-6">
            <form-row-input
              [label]="'firstName'"
              [icon]="'fa-user'"
              [(model)]="address.firstName"
              data-cy="address-firstName"
            ></form-row-input>
          </div>
          <div class="col-sm-6">
            <form-row-input
              [label]="'lastName'"
              [icon]="'fa-user'"
              [(model)]="address.lastName"
              data-cy="address-lastName"
            ></form-row-input>
          </div>
        </div>
        <div class="form-row">
          <div class="col-sm-6">
            <form-row-input
              [label]="'email'"
              [icon]="'fa-envelope'"
              [(model)]="address.email"
              data-cy="address-email"
            ></form-row-input>
          </div>
          <div class="col-sm-6">
            <form-row-input
              [label]="'phone'"
              [icon]="'fa-phone'"
              [(model)]="address.phone"
              data-cy="address-phone"
            ></form-row-input>
          </div>
        </div>
        <form-row-input
          [label]="'company.name'"
          [icon]="'fa-industry'"
          [(model)]="address.companyName"
          data-cy="address-companyName"
        ></form-row-input>
        <div class="form-row">
          <div class="col-sm-6">
            <form-row-input
              [label]="'company.ic'"
              [icon]="'fa-list'"
              [(model)]="address.ic"
              data-cy="address-ic"
            ></form-row-input>
          </div>
          <div class="col-sm-6">
            <form-row-input
              [label]="'company.dic'"
              [icon]="'fa-list'"
              [(model)]="address.dic"
              data-cy="address-dic"
            ></form-row-input>
          </div>
        </div>
        <form-row-input
          [label]="'street'"
          [icon]="'fa-map'"
          [(model)]="address.street"
          data-cy="address-street"
        ></form-row-input>
        <div class="form-row">
          <div class="col-sm-6">
            <form-row-input
              [label]="'city'"
              [icon]="'fa-map-marker'"
              [(model)]="address.city"
              data-cy="address-city"
            ></form-row-input>
          </div>
          <div class="col-sm-6">
            <form-row-input
              [label]="'zip'"
              [icon]="'fa-map-signs'"
              [(model)]="address.zip"
              data-cy="address-zip"
            ></form-row-input>
          </div>
        </div>
        <div *ngIf="states && states.length > 0" class="form-row">
          <div class="col-sm-6">
            <form-row-select
              [label]="'country'"
              [model]="address.country"
              (modelChange)="selectCountry($event)"
              [options]="countries"
              [optionKey]="'code'"
              [dataCyAttribute]="'address-country'"
            ></form-row-select>
          </div>
          <div class="col-sm-6">
            <form-row-select
              [label]="'state'"
              [(model)]="address.state"
              [options]="states"
              [optionKey]="'code'"
              [dataCyAttribute]="'address.state'"
            ></form-row-select>
          </div>
        </div>
        <form-row-select
          *ngIf="!states || (states && states.length === 0)"
          [label]="'country'"
          [model]="address.country"
          (modelChange)="selectCountry($event)"
          [options]="countries"
          [optionKey]="'code'"
          [dataCyAttribute]="'address-country'">
        </form-row-select>
        <form-row-input [label]="'shopAddressId'" [icon]="'far-hashtag'" [(model)]="address.branchId"></form-row-input>
        <form-row-input [label]="'modal-address.external-id'" [icon]="'far-hashtag'" [(model)]="address.externalId"></form-row-input>
        <form-row-textarea [icon]="'far-note'" [label]="'note'" [(model)]="address.note"></form-row-textarea>
        <form-row-toggle [label]="'address.invoice'" [(model)]="isBillingAddress"></form-row-toggle>
      </div>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content">
    <btn
      [label]="isCreating ? 'create' : 'save'"
      [title]="isCreating ? ('create' | translate) : ('save' | translate)"
      [icon]="'far-check'"
      [btnType]="'submit'"
      (onEvent)="isCreating ? createCustomer() : updateCustomer()"
      [classes]="['btn-first']"
    ></btn>
  </ng-container>
</modal-container>
