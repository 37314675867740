<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <page-header
    [icon]="'far-file-check'"
    [title]="isCreating ? 'inventory.new' : !!inventoryExport ? 'inventory.detail' : ''"
    [appendNoTranslationTitlePart]="
      inventoryExport?.date ? (inventoryExport.date | strToDate | date: 'dd.MM.yyyy') : ''
    "
  >
    <ng-container class="header-button">
      <btn
        *ngIf="inventoryExport && inventoryExport.generated && !inventoryExport.approved && !inventoryExport.rejected"
        [label]="'download'"
        [title]="'download' | translate"
        [classes]="['btn-first', 'float-right']"
        [icon]="'fa-download'"
        (onEvent)="downloadGenerated()"
        [spinnerClassesString]="'spinner-white'"
      ></btn>
    </ng-container>
  </page-header>

  <section class="content" *ngIf="inventoryExport">
    <div class="card">
      <div class="card-body">
        <div>
          <div class="row">
            <div class="col-sm-6">
              <div class="row margin-bottom-sm">
                <div class="col-sm-3">{{ 'dateCreated' | translate }}:</div>
                <div class="col-sm-9">
                  {{ inventoryExport.created ? (inventoryExport.created | strToDate | date: 'dd.MM.yyyy HH:mm') : '' }}
                </div>
              </div>
              <div class="row margin-bottom-sm">
                <div class="col-sm-3">{{ 'reports.date.state' | translate }}:</div>
                <div class="col-sm-9">
                  {{ inventoryExport.date ? (inventoryExport.date | strToDate | date: 'dd.MM.yyyy') : '' }}
                </div>
              </div>
              <div class="row margin-bottom-sm" *ngIf="inventoryExport.approved">
                <div class="col-sm-3">{{ 'reports.dateApproved' | translate }}:</div>
                <div class="col-sm-9">
                  {{
                    inventoryExport.approved ? (inventoryExport.approved | strToDate | date: 'dd.MM.yyyy HH:mm') : ''
                  }}
                </div>
              </div>
              <div class="row margin-bottom-sm" *ngIf="inventoryExport.rejected">
                <div class="col-sm-3">{{ 'reports.dateRejected' | translate }}:</div>
                <div class="col-sm-9">
                  {{
                    inventoryExport.rejected ? (inventoryExport.rejected | strToDate | date: 'dd.MM.yyyy HH:mm') : ''
                  }}
                </div>
              </div>
              <div class="row margin-bottom-sm" *ngIf="inventoryExport.depots && inventoryExport.depots.length > 0">
                <div class="col-sm-3">{{ 'reports.depots' | translate }}:</div>
                <div class="col-sm-9">
                  <span class="label label-default" *ngFor="let depot of inventoryExport.depots">
                    {{ depot.name }}
                  </span>
                </div>
              </div>
              <div
                class="row margin-bottom-sm"
                *ngIf="inventoryExport.suppliers && inventoryExport.suppliers.length > 0"
              >
                <div class="col-sm-3">{{ 'reports.suppliers' | translate }}:</div>
                <div class="col-sm-9">
                  <span class="label label-default" *ngFor="let supplier of inventoryExport.suppliers">
                    {{ supplier.name }}
                  </span>
                </div>
              </div>
              <div
                class="row margin-bottom-sm"
                *ngIf="inventoryExport.producers && inventoryExport.producers.length > 0"
              >
                <div class="col-sm-3">{{ 'brands' | translate }}:</div>
                <div class="col-sm-9">
                  <span class="label label-default" *ngFor="let producer of inventoryExport.producers">
                    {{ producer.name }}
                  </span>
                </div>
              </div>
              <div class="row margin-bottom-sm" *ngIf="!isGeneratedFinal()">
                <div class="col-sm-3">{{ 'inventory.file.generated.1' | translate }}:</div>
                <div class="flex-gap-1">
                  <btn
                    [label]="'download'"
                    [title]="'download' | translate"
                    [classes]="['btn-first', 'btn-sm']"
                    [icon]="'fa-download'"
                    (onEvent)="downloadGenerated()"
                    *ngIf="inventoryExport.generated"
                  ></btn>
                  <button class="btn btn-second btn-sm" *ngIf="!inventoryExport.generated" disabled>
                    <i class="fa fa-spinner fa-spin margin-right"></i>
                    {{ 'reports.generating' | translate }}
                  </button>
                  <btn
                    *ngIf="
                      inventoryExport &&
                      inventoryExport.generated &&
                      !inventoryExport.approved &&
                      !inventoryExport.rejected
                    "
                    [title]="'inventory.refresh' | translate"
                    [classes]="['btn-third', 'float-right', 'me-2', 'margin-left']"
                    [icon]="'fas fa-sync-alt'"
                    (onEvent)="refreshExportInventory()"
                    [spinnerClassesString]="'spinner-white'"
                  >
                  </btn>
                </div>
              </div>
              <div
                class="row margin-bottom-sm"
                *ngIf="inventoryExport.generated && !inventoryExport.approved && !inventoryExport.rejected"
              >
                <div class="col-sm-3">{{ 'inventory.file.upload' | translate }}:</div>
                <div class="col-sm-9">
                  <form-row-input
                    [icon]="'fa-upload'"
                    [inputType]="'file'"
                    (valueChangeNative)="handleFileUpload($event)"
                  ></form-row-input>
                </div>
              </div>
              <div class="row margin-bottom-sm" *ngIf="isGeneratedFinal()">
                <div class="col-sm-3">{{ 'inventory.file.generated.2' | translate }}:</div>
                <div class="col-sm-9">
                  <btn
                    [label]="'download'"
                    [title]="'download' | translate"
                    [classes]="['btn-first', 'btn-sm']"
                    [icon]="'fa-download'"
                    (onEvent)="downloadGeneratedFinal()"
                    *ngIf="inventoryExport.generated"
                  ></btn>
                  <button class="btn btn-second btn-sm" *ngIf="!inventoryExport.generated" disabled>
                    <i class="fa fa-spinner fa-spin margin-right"></i>
                    {{ 'reports.generating' | translate }}
                  </button>
                </div>
              </div>
            </div>
            <div
              class="col-sm-6 flexbox-centered-col mh-15rem"
              *ngIf="inventoryExport.files && inventoryExport.files.length > 0 && inventoryExport.generated"
            >
              <div class="margin-bottom" *ngIf="!inventoryExport.approved && !inventoryExport.rejected">
                <btn
                  [label]="'inventory.btn.approve'"
                  [title]="'inventory.btn.approve' | translate"
                  [classes]="['btn-first', 'btn-lg']"
                  [icon]="'fa fa-check-square'"
                  (onEvent)="approveInventory()"
                ></btn>
              </div>
              <div class="margin-bottom" *ngIf="!inventoryExport.approved && !inventoryExport.rejected">
                <btn
                  [label]="'inventory.btn.reject'"
                  [title]="'inventory.btn.reject' | translate"
                  [classes]="['btn-first-red', 'btn-lg']"
                  [icon]="'far-trash-alt'"
                  (onEvent)="rejectInventory()"
                ></btn>
              </div>
              <div class="margin-bottom" *ngIf="inventoryExport.approved">
                <btn
                  [label]="'inventory.approved'"
                  [title]="'inventory.approved' | translate"
                  [classes]="['btn-first', 'btn-lg']"
                  [icon]="'fa fa-check-square'"
                  [btnDisabled]="true"
                ></btn>
              </div>
              <div class="margin-bottom" *ngIf="inventoryExport.rejected">
                <btn
                  [label]="'inventory.rejected'"
                  [title]="'inventory.rejected' | translate"
                  [classes]="['btn-first-red', 'btn-lg']"
                  [icon]="'far-trash-alt'"
                  [btnDisabled]="true"
                ></btn>
              </div>

              <div
                *ngIf="
                  inventoryExport.approved &&
                  !inventoryExport.pmpIn &&
                  !inventoryExport.pmpOut &&
                  !this.isGeneratedPmps()
                "
                class="margin-bottom"
              >
                <button class="btn btn-second" disabled>
                  <i class="fa fa-spinner fa-spin margin-right"></i>
                  {{ 'reports.generating' | translate }}
                </button>
              </div>
              <div
                class="margin-bottom"
                *ngIf="
                  inventoryExport.approved &&
                  inventoryExport.pmpIn &&
                  inventoryExport.pmpIn.url &&
                  inventoryExport.pmpIn.url.length > 0
                "
              >
                <btn
                  [label]="'download.pmp.in'"
                  [title]="'download.pmp.in' | translate"
                  [classes]="['btn-first', 'btn-lg']"
                  [icon]="'fa-download'"
                  (onEvent)="downloadProductMovePack(inventoryExport.pmpIn)"
                ></btn>
              </div>
              <div
                class="margin-bottom"
                *ngIf="
                  inventoryExport.approved &&
                  inventoryExport.pmpOut &&
                  inventoryExport.pmpOut.url &&
                  inventoryExport.pmpOut.url.length > 0
                "
              >
                <btn
                  [label]="'download.pmp.out'"
                  [title]="'download.pmp.out' | translate"
                  [classes]="['btn-primary']"
                  [icon]="'fa-download'"
                  (onEvent)="downloadProductMovePack(inventoryExport.pmpOut)"
                ></btn>
              </div>
            </div>
          </div>
        </div>
        <div>
          <alert type="danger" *ngIf="error">
            {{ 'print-inventory.error' | translate }}
          </alert>
        </div>
      </div>
    </div>
  </section>
</div>
