<div class="app-container">
  <page-header [icon]="'far-file-invoice-dollar'" [title]="'saleExports'">
    <ng-container class="header-button">
      <modal-print-export
        [exportType]="'sale-exports'"
        (resultExport)="createExportSales($event)"
        [dataCyAttribute]="'add-inventory'"
      ></modal-print-export>
    </ng-container>
  </page-header>

  <section class="content">
    <div class="container-fluid ps-0 pe-0">
      <app-base-listing
        [listName]="'printSaleExports'"
        [storageKey]="storageKey"
        [loadingNextItems]="loading"
        [loadingItems]="reloading"
        [items]="reports"
        [columns]="listingColumns"
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [endPage]="endPage"
        [(sortProp)]="sortProp"
        [(sortDir)]="sortDir"
        (reloadList)="filter(1)"
        (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
        (callAction)="callAction($event)"
      ></app-base-listing>
    </div>
  </section>
</div>
