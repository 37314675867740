import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import * as XLSX from 'xlsx'
import { asyncForEach, SchemaUtil, detectUTF8, UI_langs, Possible_Encodings, fileTemplatesURL } from '../../../utils'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { Address, Carrier, Checkout, Order, OrderItem, Payment, PaymentItem, Product } from 'depoto-core/src/entities'
import { CoreService } from '../../../services'
import { TranslateService } from '@ngx-translate/core'
import * as chardet from 'chardet'

@Component({
  selector: 'modal-orders-import',
  templateUrl: 'modal-orders-import.component.html',
  styleUrls: ['modal-orders-import.component.scss'],
})
export class ModalOrdersImportComponent {
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Input() public carriers: Carrier[] = []
  @Input() public checkouts: Checkout[] = []
  @Input() public payments: Payment[] = []
  @Input() dataCyAttribute: string
  @Output() public onOrdersImported: EventEmitter<Array<Order>> = new EventEmitter()
  loadedFileEv: any
  orders: Order[] = []
  fileTypes: string[] = ['depoto-orders.xlsx', 'go-orders.csv', 'flexibee-order.xml']
  selectedFileType = 'depoto-orders.xlsx'
  goPaymentId: string = 'cash_on_delivery' // Dobírka // todo: ok for go, select for depoto-orders.xlsx
  goPayment2Id: string = 'bank_account' // Prevodem // todo: ok for go, select for depoto-orders.xlsx
  selectedCheckout: number
  isImportFileLoaded = false
  templatesURL = fileTemplatesURL
  progress = 0
  finalMessage = ''
  loading = false
  skippedOrders = []
  defaultColumnTypesCS = {
    // a property must totally coincide with the template
    reservationNumber: 'Číslo objednávky',
    ean: 'EAN produktu',
    code: 'Kód produktu',
    productName: 'Název Produktu',
    quantity: 'Množství',
    firstName: 'Jméno',
    lastName: 'Příjmení',
    street: 'Ulice',
    city: 'Město',
    zip: 'PSČ',
    country: 'Země',
    phone: 'Telefon',
    email: 'E-mail',
    paymentType: 'Platba - typ',
    paymentAmount: 'Platba - Částka',
    currency: 'Měna',
    carrier: 'Dopravce',
    note: 'Poznámka',
  }
  defaultColumnTypesEN = {
    reservationNumber: 'Order number',
    ean: 'Product EAN',
    code: 'Product SKU',
    productName: 'Product name',
    quantity: 'Quantity',
    firstName: 'Name',
    lastName: 'Last Name',
    street: 'Address', // as in the template
    city: 'City',
    zip: 'ZIP',
    country: 'Country code',
    phone: 'Phone',
    email: 'E-mail',
    paymentType: 'Payment type',
    paymentAmount: 'Payment price',
    currency: 'Currency',
    carrier: 'Delivery method',
    note: 'Note',
  }
  columnTypes: Record<string, unknown>[] = []
  importMap: any = {
    reservationNumber: -1,
    ean: -1,
    code: -1,
    productName: -1,
    quantity: -1,
    firstName: -1,
    lastName: -1,
    street: -1,
    city: -1,
    zip: -1,
    country: -1,
    // branchId: 11,
    phone: -1,
    email: -1,
    paymentType: -1,
    paymentAmount: -1,
    currency: -1,
    carrier: -1,
    note: -1,
  }
  isImportMapSet = false
  isImportMapUpdating = false
  uintArray: Uint8Array | null = null
  errors: string[] = []
  constructor(
    private core: CoreService,
    private translateService: TranslateService
  ) {}

  showChildModal(): void {
    this.loadedFileEv = null
    this.isImportFileLoaded = false
    this.orders = []
    this.selectedCheckout = undefined
    this.errors = []
    this.skippedOrders = []
    this.isImportMapSet = false
    this.isImportMapUpdating = false
    this.childModal.showChildModal()
    if (this.checkouts && this.checkouts.length === 1) {
      this.selectedCheckout = this.checkouts[0].id
    }
  }

  hideChildModal(): void {
    this.childModal.hideChildModal()
  }

  loadFile(event): void {
    this.loadedFileEv = event
  }

  async handleImportFile(): Promise<any> {
    if (!this.loadedFileEv) {
      alert(this.translateService.instant('modal-orders-import.no-file'))
      return
    }
    if (!this.selectedCheckout) {
      alert(this.translateService.instant('modal-orders-import.select-checkout'))
      return
    }
    this.loading = true
    let arrayBuff = null
    const isXls = this.loadedFileEv.target.value.endsWith('.xlsx')
    const isXml = this.loadedFileEv.target.value.endsWith('.xml')
    const isCsv = this.loadedFileEv.target.value.endsWith('.csv')
    if (
      (!isXls && !isCsv && !isXml) ||
      (isXls && this.selectedFileType != 'depoto-orders.xlsx') ||
      (isCsv && this.selectedFileType != 'go-orders.csv') ||
      (isXml && this.selectedFileType != 'flexibee-order.xml')
    ) {
      alert(this.translateService.instant('modal-orders-import.wrong-format'))
      this.loading = false
      return
    }
    try {
      arrayBuff = await this.getDataFromImportedFile(this.loadedFileEv)
      this.uintArray = new Uint8Array(arrayBuff)
      const encoding = chardet
        .analyse(this.uintArray)
        .filter(x => Possible_Encodings.includes(x.name))
        .sort((a, b) => b.confidence - a.confidence)[0].name

      console.log('Detected:', detectUTF8(this.uintArray), encoding == 'UTF-8' ? '' : encoding)
      let binaryString = isXls ? null : new TextDecoder(encoding).decode(this.uintArray)

      if (isCsv) {
        binaryString = binaryString.replace(/=CONCATENATE\(|\)/g, '')
      }
      if (isXls) {
        this.orders = await this.parseXlsDepoto(this.uintArray)
        /*  this.selectedFileType === 'depoto-orders.xlsx'
            ? await this.parseXlsDepoto(this.uintArray)
            : await this.parseXls(this.uintArray) */
      }
      if (isXml) {
        this.orders = await this.parseFlexibeeXml(binaryString)
      }
      if (isCsv) {
        this.orders = await this.parseCsv(binaryString)
      }
      if (isCsv || isXml) {
        this.isImportFileLoaded = true
      }
    } catch (err) {
      alert(
        typeof err === 'string'
          ? err
          : err.message
            ? err.message === "Cannot read property 'childNodes' of undefined"
              ? this.translateService.instant('modal-orders-import.import.error')
              : err.message
            : JSON.stringify(err)
      )
    } finally {
      this.loading = false
    }
  }

  getDataFromImportedFile(inputEvent: Event): Promise<string | any> {
    const target = inputEvent.target as HTMLInputElement
    return new Promise((resolve, reject) => {
      const file = target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = evt => {
          resolve(evt.target.result)
        }
        reader.onerror = () => {
          reject(this.translateService.instant('file-processing-error', { name: file.name }))
        }
      } else {
        reject(this.translateService.instant('no-file'))
      }
    })
  }

  async setImportMapAndContinue() {
    this.loading = true
    this.isImportMapUpdating = false
    this.isImportMapSet = true
    this.orders = await this.parseXlsDepoto(this.uintArray)
    this.loading = false
    this.isImportFileLoaded = true
  }

  async import() {
    const results = []
    this.skippedOrders = []
    this.progress = 0
    this.finalMessage = ''
    this.loading = true
    let x = 1
    for (const o of this.orders) {
      if (!o.reservationNumber) {
        alert(this.translateService.instant('modal-orders-import.no-reservation'))
        continue
      }
      const existing = await this.core.services.order.getList(
        { filters: { reservationNumber: Number(o.reservationNumber) } },
        { id: null }
      )
      if (existing.items?.length) {
        this.skippedOrders.push(o.reservationNumber)
      } else {
        try {
          o.shippingAddress = await this.core.services.address.create(o.shippingAddress)
          o.invoiceAddress = o.shippingAddress
          o.checkout = new Checkout()
          o.checkout.id = Number(this.selectedCheckout)
          const withPayment = o.paymentItems.filter(p => p.payment)
          if (!withPayment.length && this.selectedFileType != 'flexibee-order.xml') {
            this.errors.push(
              this.translateService.instant('failed.to.import.payment.0') +
                o.reservationNumber +
                this.translateService.instant('failed.to.import.payment.1') +
                o.paymentItems[0]?.payment +
                this.translateService.instant('failed.to.import.payment.2')
            )
          } else {
            o.paymentItems = withPayment
            const res = await this.core.services.order.create(o)
            results.push(res)
          }
        } catch (e) {
          this.errors.push(e.message)
          console.error(e)
        }
      }
      this.progress = x / (this.orders.length / 100)
      x++
    }
    this.loading = false
    this.finalMessage = this.translateService.instant('import.finished')
    this.onOrdersImported.emit(this.orders)
    this.isImportFileLoaded = false
  }
  /*   Not fixed, not even reviewed

  protected async parseXls(typedArray: Uint8Array): Promise<Array<Order>> {
    const wb: XLSX.WorkBook = XLSX.read(typedArray, { cellDates: true })
    const orders: any = {}
    this.orders = []
    this.progress = 0
    this.finalMessage = ''
    await asyncForEach(wb.SheetNames, async sheetName => {
      const ws: XLSX.WorkSheet = wb.Sheets[sheetName]
      const data: Array<Array<any>> | any = XLSX.utils.sheet_to_json(ws, { header: 1 })
      this.isImportMapSet = true // just hide form
      const castToStr = val =>
        typeof val === 'string' ? val : typeof val === 'number' ? val.toFixed(0) : !!val ? val : ''
      await asyncForEach(data, async (n, i) => {
        if (i > 0) {
          const rowNumber = `${n[2]}`
          if (!orders[rowNumber]) {
            orders[rowNumber] = new Order()
          }
          const dStr = castToStr(n[0])
          const dStr2 = !!dStr ? `${dStr.substr(0, 4)}/${dStr.substr(4, 2)}/${dStr.substr(6, 2)}` : null
          const d = new Date(dStr2)
          d.setHours(d.getHours() + 2)
          orders[rowNumber].dateCreated = d.toISOString()
          orders[rowNumber].reservationNumber = rowNumber
          orders[rowNumber].billNumber = castToStr(n[3])
          const products =
            `${n[6]}`?.length > 0
              ? await this.core.services.product.getList(
                  { filters: { ean: `${n[6]}` } },
                  SchemaUtil.productImportSearch,
                )
              : `${n[4]}`?.length > 0
                ? await this.core.services.product.getList(
                    { filters: { code: `${n[4]}` } },
                    SchemaUtil.productImportSearch,
                  )
                : `${n[5]}`?.length > 0
                  ? await this.core.services.product.getList(
                      { filters: { fulltext: `${n[5]}` } },
                      SchemaUtil.productImportSearch,
                    )
                  : []
          const product = products && products.products && products.products.length > 0 ? products.products[0] : null
          const name = !!castToStr(n[5]) && castToStr(n[5]).length > 0 ? castToStr(n[5]) : product.name
          const oItem = new OrderItem({
            code: `${n[4]}`,
            name: name,
            ean: `${n[6]}`,
            quantity: Number(n[7]),
            vat: this.core.lists.vats.find(v => v.default),
            product: product,
          })
          orders[rowNumber].items.push(oItem)
          orders[rowNumber].note =
            castToStr(n[18]) + '\n' + castToStr(n[19]) + '\n čas:' + castToStr(n[20]) + '\n OZ:' + castToStr(n[21])
          const clientName = castToStr(n[10])
          orders[rowNumber].shippingAddress = new Address({
            companyName: castToStr(n[9]),
            firstName: clientName && clientName.length > 0 ? clientName.split(' ', 2)[1] : '',
            lastName: clientName && clientName.length > 0 ? clientName.split(' ', 2)[0] : '',
            street: castToStr(n[11]),
            city: castToStr(n[12]),
            zip: castToStr(n[13]),
            phone: castToStr(n[14]),
            country: 'CZ', // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! TODO
          })
          const paymentItem = new PaymentItem({
            payment:
              Number(n[15]) === 0
                ? this.payments.find(p => p.type.id === this.goPayment2Id)
                : this.payments.find(p => p.type.id === this.goPaymentId),
            checkout: this.checkouts.find(c => c.id === this.selectedCheckout),
            amount: Number(n[15]),
          })
          if (orders[rowNumber].paymentItems && orders[rowNumber].paymentItems.length === 0) {
            orders[rowNumber].paymentItems.push(paymentItem)
          }
        }
        this.progress = (i + 1) / (data.length / 100)
      })
    })
    Object.keys(orders).forEach(k => {
      this.orders.push(orders[k])
    })
    return this.orders
  } */

  protected async parseXlsDepoto(typedArray: Uint8Array): Promise<Array<Order>> {
    const wb: XLSX.WorkBook = XLSX.read(typedArray, { cellDates: true })
    const orders: any = {}
    this.orders = []
    this.progress = 0
    this.finalMessage = ''
    await asyncForEach(wb.SheetNames, async sheetName => {
      const ws: XLSX.WorkSheet = wb.Sheets[sheetName]
      const data: Array<Array<any>> | any = XLSX.utils.sheet_to_json(ws, { header: 1 })
      const castToStr = val =>
        typeof val === 'string' ? val : typeof val === 'number' ? val.toFixed(0) : val ? val : ''
      await asyncForEach(data, async (n, i) => {
        if (n?.length > 0) {
          if (i === 0 && !this.isImportMapSet) {
            const headings = n.map(c => castToStr(c))
            this.columnTypes = [{ id: 'none', name: ' ' }, ...headings.map((h, index) => ({ id: index, name: h }))]
            const defaultColumnsByLang = UI_langs.map(lang => this['defaultColumnTypes' + lang])
            let colindex = -1
            defaultColumnsByLang.some(columns => {
              const importMap = {}
              for (const colHeadingKey of Object.keys(columns)) {
                colindex = headings.indexOf(columns[colHeadingKey])
                if (colindex >= 0) {
                  importMap[colHeadingKey] = colindex
                } else {
                  break
                }
                this.importMap = importMap
              }
              return colindex >= 0
            })
            this.isImportMapUpdating = true
            return
          } else if (i > 0 && this.isImportMapSet) {
            const importMap = this.importMap
            const rowNumber = `${n[importMap.reservationNumber]}`
            if (!orders[rowNumber]) {
              orders[rowNumber] = new Order()
            }
            const eanOrCodeOrName =
              importMap.ean >= 0
                ? 'ean'
                : importMap.code >= 0
                  ? 'code'
                  : importMap.productName >= 0
                    ? 'productName'
                    : ''
            const products = eanOrCodeOrName
              ? await this.core.services.product.getList(
                  {
                    filters: {
                      [eanOrCodeOrName === 'productName' ? 'fulltext' : eanOrCodeOrName]:
                        `${n[importMap[eanOrCodeOrName]]}`,
                    },
                  },
                  SchemaUtil.productImportSearch
                ) // todo: ean->code->fulltext
              : []

            const product = products && products.items && products.items.length > 0 ? products.items[0] : null
            const oItem = new OrderItem({
              name: (product?.fullName || n[importMap.productName]) ?? '',
              quantity: Number(n[importMap.quantity]),
              vat: this.core.lists.vats.find(v => v.default),
              product,
            })
            if (importMap.ean >= 0) {
              oItem.ean = `${n[importMap.ean]}`
            }
            if (importMap.code >= 0) {
              oItem.code = `${n[importMap.code]}`
            }
            orders[rowNumber].items.push(oItem)
            // address, note etc on first row of multiitem orders
            if (!orders[rowNumber].shippingAddress) {
              orders[rowNumber].dateCreated = new Date().toISOString()
              // orders[rowNumber].billNumber = rowNumber
              orders[rowNumber].reservationNumber = rowNumber
              if (importMap.carrier >= 0) {
                orders[rowNumber].carrier = new Carrier({ id: castToStr(n[importMap.carrier]).toLowerCase() })
              }
              if (importMap.note >= 0) {
                orders[rowNumber].note = castToStr(n[importMap.note])
              }
              orders[rowNumber].shippingAddress = new Address({
                firstName: castToStr(n[importMap.firstName]),
                lastName: castToStr(n[importMap.lastName]),
                street: castToStr(n[importMap.street]),
                city: castToStr(n[importMap.city]),
                zip: castToStr(n[importMap.zip]),
                phone: castToStr(n[importMap.phone]),
                email: castToStr(n[importMap.email]),
                country: castToStr(n[importMap.country]),
              })
              const paymentItem = new PaymentItem({
                payment: this.payments.filter(p => p.type.id === n[importMap.paymentType])[0],
                checkout: this.checkouts.filter(c => c.id === this.selectedCheckout)[0],
                amount: Number(n[importMap.paymentAmount]),
              })
              orders[rowNumber].paymentItems = [paymentItem]
            }
          }
        }
        this.progress = (i + 1) / (data.length / 100)
      })
    })
    Object.keys(orders).forEach(k => {
      if (orders[k].paymentItems.filter(p => p.payment).length) {
        this.orders.push(orders[k])
      }
    })
    return this.orders
  }

  async parseFlexibeeXml(str) {
    const getNode = (tag, nodes) => {
      for (const n of nodes) {
        if (n.tagName === tag) {
          return n
        }
      }
    }
    const res = []
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(str, 'application/xml')

    const invoices = Array.from(xmlDoc.getElementsByTagName('faktura-vydana'))
    for (const invoice of invoices) {
      const order = new Order()
      order.reservationNumber = Number(getNode('varSym', invoice.childNodes).innerHTML)
      const items = getNode('polozkyFaktury', invoice.childNodes).childNodes
      for (const item of items) {
        if (item.tagName === 'faktura-vydana-polozka') {
          const orderItem = new OrderItem()
          orderItem.product = new Product()
          orderItem.name = getNode('nazev', item.childNodes)?.innerHTML || ''
          orderItem.product.fullName = getNode('nazev', item.childNodes)?.innerHTML || ''
          orderItem.code = getNode('kod', item.childNodes)?.innerHTML || null
          orderItem.product.code = getNode('kod', item.childNodes)?.innerHTML || null
          orderItem.ean = getNode('eanKod', item.childNodes)?.innerHTML || null
          orderItem.product.ean = getNode('eanKod', item.childNodes)?.innerHTML || null
          orderItem.quantity = Number(getNode('mnozMj', item.childNodes)?.innerHTML || 1)
          orderItem.price = Number(getNode('sumCelkem', item.childNodes)?.innerHTML || 0) / orderItem.quantity
          orderItem.priceWithoutVat = Number(getNode('sumZkl', item.childNodes)?.innerHTML || 0) / orderItem.quantity
          orderItem.priceAll = Number(getNode('sumCelkem', item.childNodes)?.innerHTML || 0)
          orderItem.vat = this.core.lists.vats.find(v => v.default)
          if (orderItem.price === -Infinity || orderItem.price === Infinity) {
            orderItem.price = 0
          }
          if (orderItem.priceWithoutVat === -Infinity || orderItem.priceWithoutVat === Infinity) {
            orderItem.priceWithoutVat = 0
          }
          if (orderItem.priceAll === -Infinity || orderItem.priceAll === Infinity) {
            orderItem.priceAll = 0
          }
          const products = orderItem.ean
            ? await this.core.services.product.getList(
                { filters: { ean: orderItem.ean } },
                SchemaUtil.productImportSearch
              ) // todo: ean->code->fulltext
            : orderItem.code
              ? await this.core.services.product.getList(
                  { filters: { code: orderItem.code } },
                  SchemaUtil.productImportSearch
                )
              : orderItem.name
                ? await this.core.services.product.getList(
                    { filters: { fulltext: orderItem.name } },
                    SchemaUtil.productImportSearch
                  )
                : []
          const product = products && products.items && products.items.length > 0 ? products.items[0] : null
          if (product) {
            orderItem.product = product
          }
          order.items.push(orderItem)
        }
      }
      order.shippingAddress = new Address({
        firstName: getNode('nazFirmy', invoice.childNodes)?.innerHTML || '',
        street: getNode('ulice', invoice.childNodes)?.innerHTML || '',
        city: getNode('mesto', invoice.childNodes)?.innerHTML || '',
        zip: getNode('psc', invoice.childNodes)?.innerHTML || '',
        phone: getNode('kontaktTel', invoice.childNodes)?.innerHTML || '',
        country: getNode('stat', invoice.childNodes)?.innerHTML?.replace('code:', '') || 'CZ',
      })
      const isInvoiceSameAsShipping = getNode('postovniShodna', invoice.childNodes)?.innerHTML === 'true'
      if (!isInvoiceSameAsShipping) {
        order.invoiceAddress = new Address({
          firstName: getNode('faNazev', invoice.childNodes)?.innerHTML || '',
          street: getNode('faUlice', invoice.childNodes)?.innerHTML || '',
          city: getNode('faMesto', invoice.childNodes)?.innerHTML || '',
          zip: getNode('faPsc', invoice.childNodes)?.innerHTML || '',
          country: getNode('faStat', invoice.childNodes)?.innerHTML?.replace('code:', '') || 'CZ',
        })
      }
      res.push(order)
    }
    return res
  }

  async parseCsv(str) {
    const arrOfOrdersInArrays = []
    let quote = false // 'true' means we're inside a quoted field
    const delimeter = str.indexOf(';') > 0 && (str.indexOf(',') < 0 || str.indexOf(';') < str.indexOf(',')) ? ';' : ','

    // Iterate over each character, keep track of current row and column (of the returned array)
    for (let row = 0, col = 0, c = 0; c < str.length; c++) {
      const cc = str[c],
        nc = str[c + 1] // Current character, next character
      arrOfOrdersInArrays[row] = arrOfOrdersInArrays[row] || [] // Create a new row if necessary
      arrOfOrdersInArrays[row][col] = arrOfOrdersInArrays[row][col] || '' // Create a new column (start with empty string) if necessary

      // If the current character is a quotation mark, and we're inside a
      // quoted field, and the next character is also a quotation mark,
      // add a quotation mark to the current column and skip the next character
      if (cc === '"' && quote && nc === '"') {
        arrOfOrdersInArrays[row][col] += cc
        ++c
        continue
      }

      // If it's just one quotation mark, begin/end quoted field
      if (cc === '"') {
        quote = !quote
        continue
      }

      // If it's a delimeter and we're not in a quoted field, move on to the next column
      if (cc === delimeter && !quote) {
        ++col
        continue
      }

      // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
      // and move on to the next row and move to column 0 of that new row
      if (cc === '\r' && nc === '\n' && !quote) {
        ++row
        col = 0
        ++c
        continue
      }

      // If it's a newline (LF or CR) and we're not in a quoted field,
      // move on to the next row and move to column 0 of that new row
      if (cc === '\n' && !quote) {
        ++row
        col = 0
        continue
      }
      if (cc === '\r' && !quote) {
        ++row
        col = 0
        continue
      }

      // Otherwise, append the current character to the current column
      arrOfOrdersInArrays[row][col] += cc
    }

    const orders = []
    for (const orderPropertiesInArray of arrOfOrdersInArrays) {
      const index = arrOfOrdersInArrays.indexOf(orderPropertiesInArray)
      // skip first line -> header
      if (index === 0) continue

      const newOrder: Order = new Order({})
      const newOrderItem: OrderItem = new OrderItem({})
      const newAddress: Address = new Address({ country: 'CZ' })

      orderPropertiesInArray.forEach((orderProperty, index2) => {
        switch (index2) {
          case 0:
            newOrder.dateExpedition = orderProperty
            return
          case 1:
            newOrder.externalReference = orderProperty
            return
          case 2:
            newOrder.reservationNumber = Number(orderProperty)
            return
          case 3:
            // cispovlistu skip
            return
          case 4:
            newOrderItem.code = orderProperty
            return
          case 5:
            newOrderItem.name = orderProperty
            return
          case 6:
            newOrderItem.ean = orderProperty ? parseInt(orderProperty) + '' : undefined
            return
          case 7:
            if (orderProperty.includes(',')) orderProperty = orderProperty.split(',').join('.')
            newOrderItem.quantity =
              typeof orderProperty === 'string' && orderProperty?.length
                ? Number(orderProperty)
                : newOrderItem.name
                  ? 1
                  : 0
            return
          case 8:
            // MJSkladova skip
            return
          case 9:
            newAddress.firstName = orderProperty
            return
          case 10:
            newAddress.lastName = orderProperty
            return
          case 11:
            newAddress.street = orderProperty
            return
          case 12:
            newAddress.city = orderProperty
            return
          case 13:
            newAddress.zip = orderProperty
            return
          case 14:
            newAddress.phone = orderProperty
            return
          case 15:
            if (orderProperty.includes(',')) orderProperty = orderProperty.split(',').join('.')
            newOrder.priceAll = orderProperty
            return
          case 16:
            newOrder.currency = orderProperty
            return
          case 17:
            newOrder.billNumber = orderProperty
            newOrder.privateNote = orderProperty
            return
          case 18:
            newOrder.note = orderProperty
            return
          case 19:
            newOrder.privateNote = newOrder.privateNote + '\n' + orderProperty
            return
          case 20:
            newOrder.privateNote = newOrder.privateNote + '\n' + orderProperty
            return
          case 21:
            // OZ skip
            return
          case 22:
            // VlastniOdber skip
            return
          case 23:
            // Expresni dodani skip
            return
        }
      })
      // set OrderItem
      if (newOrderItem.name?.length) {
        await this.setOrderItem(newOrderItem, newOrder)
      }

      // check if is not another line for same order
      const alreadyPushedOrder = orders.find(
        order => Number(order?.reservationNumber) === Number(orderPropertiesInArray[2])
      )

      if (alreadyPushedOrder) {
        alreadyPushedOrder.items.push(newOrderItem)
      } else {
        // set Address
        newOrder.invoiceAddress = newAddress
        newOrder.shippingAddress = newAddress
        // set paymentItem
        const paymentItem = new PaymentItem({
          payment:
            Number(newOrder.priceAll) === 0
              ? this.payments.find(p => p.type.id === this.goPayment2Id)
              : this.payments.find(p => p.type.id === this.goPaymentId),
          checkout: this.checkouts.find(c => c.id === this.selectedCheckout),
          amount: newOrder.priceAll,
        })
        if (newOrder.paymentItems && newOrder.paymentItems.length === 0) {
          newOrder.paymentItems.push(paymentItem)
        }
        orders.push(newOrder)
      }
    }
    return orders
  }

  async setOrderItem(newOrderItem, newOrder) {
    // check if the product exists
    let existingProduct
    if (newOrderItem.code || newOrderItem.ean) {
      const prop = newOrderItem.ean ? 'ean' : 'code'
      existingProduct = await this.findProduct(prop, newOrderItem)

      if (!existingProduct) {
        const candidate = ['ean', 'code'].filter(x => x !== prop)[0]
        if (newOrderItem[candidate]) {
          existingProduct = await this.findProduct(candidate, newOrderItem)
        }
      }
    }
    if (existingProduct) {
      newOrderItem.product = existingProduct
    }
    newOrderItem.vat = this.core.lists.vats.find(v => v.default)
    newOrderItem.sale = 0
    newOrder.items.push(newOrderItem)
  }

  findProduct(prop, newOrderItem) {
    return this.core.services.product
      .getList(
        {
          filters: {
            [prop]: newOrderItem[prop],
          },
        },
        SchemaUtil.productImportSearch
      )
      .then(result => result.items[0])
  }
}
