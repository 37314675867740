import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core'
import { GeoConfig, GeoListItemType } from '../../../configs'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { Address, Customer } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../../services'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { SchemaUtil } from '../../../utils'

@Component({
  selector: 'modal-address',
  templateUrl: './modal-address.component.html',
  styleUrls: ['modal-address.component.scss'],
})
export class ModalAddressComponent implements OnChanges {
  @ViewChild('modal') public modal: ModalContainerComponent
  @Input() public address: Address = new Address({ country: 'CZ' })
  @Input() public customer: Customer
  @Input() public isCreating = false
  @Input() public isHeadingBtn = false
  @Input() public isListingBtn = false
  @Input() public isModalOnOrderPage = false
  @Output() addressChange: EventEmitter<Address> = new EventEmitter<Address>()
  @Output() public result: EventEmitter<any> = new EventEmitter()
  @Output() public onEditedAddress: EventEmitter<Address> = new EventEmitter()
  @Output() public editedAddressChange: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() public onEditedAddressChange: EventEmitter<Address> = new EventEmitter()
  @Input() btnLabel: string
  @Input() btnIcon: string
  @Input() btnTitle: string
  @Input() dataCyAttribute: string
  loading = false
  formGroup: FormGroup
  submitted = false
  isFormShown = false
  public btnClasses: string[] = []
  public modalTitle: string
  public countries: GeoListItemType[] = GeoConfig.countries
  public states: GeoListItemType[] = []
  public addressForm = new FormGroup({
    firstName: new FormControl({ value: '', disabled: false }, []),
    lastName: new FormControl({ value: '', disabled: false }, []),
    email: new FormControl({ value: '', disabled: false }, [Validators.email]),
    phone: new FormControl({ value: '', disabled: false }, []),
    companyName: new FormControl({ value: '', disabled: false }, []),
    ic: new FormControl({ value: '', disabled: false }, []),
    dic: new FormControl({ value: '', disabled: false }, []),
    street: new FormControl({ value: '', disabled: false }, []),
    city: new FormControl({ value: '', disabled: false }, []),
    zip: new FormControl({ value: '', disabled: false }, []),
    country: new FormControl({ value: '', disabled: false }, [Validators.required]),
    state: new FormControl({ value: '', disabled: false }, []),
    isBilling: new FormControl({ value: false, disabled: false }, []),
  })

  constructor(
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.address) {
      this.selectCountry(this.address.country)
    }
    this.getVariablesForModal()
  }

  public showChildModal(): void {
    this.loading = true
    if (this.isModalOnOrderPage && this.customer?.id && this.customer?.addresses?.length < 1) {
      this.getCustomersAddresses()
    }
    this.isFormShown = true
    this.fillFormValue()
    this.loading = false
    this.modal.showChildModal()
  }

  public hideChildModal(): void {
    this.isFormShown = false
    this.modal.hideChildModal()
  }

  public async hideChildModalWithOutput() {
    this.submitted = true
    if (this.addressForm.invalid || !this.validateInput()) {
      return
    }
    await this.getFormValue()
    await this.addressChange.emit(this.address)
    this.editedAddressChange.emit(true)
    this.getVariablesForModal()
    this.submitted = false
    this.hideChildModal()
  }

  async getCustomersAddresses() {
    try {
      await this.core.services.customer
        .getById(this.customer.id, { id: null, addresses: SchemaUtil.address })
        .then(result => {
          this.customer.addresses = result.addresses
        })
    } catch (err) {
      console.error(err)
    }
  }

  public async createAddress(): Promise<any> {
    this.submitted = true
    if (this.addressForm.invalid || !this.validateInput()) {
      return
    }
    this.getFormValue()
    const address = await this.core.services.address.create(this.address, this.customer)
    if (address && address.id > 0) {
      this.address = address
      this.hideChildModal()
      this.onEditedAddressChange.emit(this.address)
      this.result.emit(this.address)
      this.address = new Address({ isStored: true, country: 'CZ' })
    }
  }

  public async updateAddress(): Promise<any> {
    this.submitted = true
    if (this.addressForm.invalid || !this.validateInput()) {
      return
    }
    this.getFormValue()
    const address = await this.core.services.address.update(this.address, this.customer)
    if (address && address.id > 0) {
      this.notificationService.success(this.translateService.instant('alert.address.updated'))
      this.hideChildModal()
      this.onEditedAddressChange.emit(this.address)
      this.result.emit(['update', true])
    }
  }

  private validateInput(): boolean {
    if (this.addressForm.value.firstName || this.addressForm.value.companyName) {
      return true
    } else {
      alert(this.translateService.instant('address.validation'))
      return false
    }
  }

  public selectCountry(country: string): void {
    this.address.country = country
    switch (country) {
      case 'US':
        this.states = GeoConfig.statesUS
        break
      case 'CA':
        this.states = GeoConfig.statesCA
        break
      default:
        this.states = []
        this.address.state = ''
        break
    }
  }

  public async triggerOnSelectedAddress(address: Address) {
    this.onEditedAddressChange.emit(address)
    this.modal.hideChildModal()
  }

  private getVariablesForModal(): void {
    if (this.isCreating) {
      this.modalTitle = 'address.create'
      this.btnLabel = 'address.add'
      this.btnIcon = 'far-plus'
      if (this.isHeadingBtn) {
        this.btnClasses = ['btn-third', 'float-right']
      } else {
        this.btnClasses = ['btn-second', 'btn-sm', 'float-right']
      }
    } else {
      this.modalTitle = 'address.update'
      this.btnLabel = 'address.update'
      if (this.isListingBtn) {
        this.btnIcon = 'fa-chevron-right'
        this.btnClasses = ['btn-second', 'btn-sm']
      } else {
        this.btnIcon = 'far-pencil'
        this.btnClasses = ['btn-second', 'btn-sm', 'float-right']
      }
    }
  }

  areAddresses(): boolean {
    return !!(this.isModalOnOrderPage && this.customer?.addresses?.length > 0)
  }

  public fillFormValue() {
    this.addressForm.setValue({
      firstName: this.address.firstName || '',
      lastName: this.address.lastName || '',
      email: this.address.email || '',
      phone: this.address.phone || '',
      companyName: this.address.companyName || '',
      ic: this.address.ic || '',
      dic: this.address.dic || '',
      street: this.address.street || '',
      city: this.address.city || '',
      zip: this.address.zip || '',
      country: this.address.country || 'CZ',
      state: this.address.state || '',
      isBilling: this.address.isBilling || false,
    })
  }

  public getFormValue() {
    this.address.firstName = this.addressForm.value.firstName
    this.address.lastName = this.addressForm.value.lastName
    this.address.email = this.addressForm.value.email
    this.address.phone = this.addressForm.value.phone
    this.address.companyName = this.addressForm.value.companyName
    this.address.ic = this.addressForm.value.ic
    this.address.dic = this.addressForm.value.dic
    this.address.street = this.addressForm.value.street
    this.address.city = this.addressForm.value.city
    this.address.zip = this.addressForm.value.zip
    this.address.country = this.addressForm.value.country
    this.address.state = this.addressForm.value.state
    this.address.isBilling = this.addressForm.value.isBilling
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.addressForm.controls
  }
}
