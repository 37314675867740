<button
  #btn
  *ngIf="!btnDisabled"
  [type]="btnType"
  [class]="classesString"
  (click)="triggerEvent()"
  [attr.data-track-category]="trackCategory"
  [attr.data-track-action]="trackAction"
  [attr.data-track-value]="trackValue"
  [attr.data-cy]="dataCyAttribute"
>
  <i
    class="spinner-border spinner-border-sm text-primary my-auto"
    role="status"
    [ngClass]="spinnerClassesString"
    *ngIf="isLoading"
  ></i>
  <i *ngIf="icon && icon.length && !isLoading" [class]="iconClassesString"></i>
  {{ label | translate }}
</button>
<button
  #btn
  *ngIf="btnDisabled"
  disabled
  [type]="btnType"
  [class]="classesString"
  [attr.data-track-category]="trackCategory"
  [attr.data-track-action]="trackAction + '-disabled'"
  [attr.data-track-value]="trackValue"
  [attr.data-cy]="dataCyAttribute"
>
  <i
    class="spinner-border spinner-border-sm text-primary my-auto"
    role="status"
    [ngClass]="spinnerClassesString"
    *ngIf="isLoading"
  ></i>
  <i *ngIf="icon && icon.length && !isLoading" [class]="iconClassesString"></i>
  {{ label | translate }}
</button>
