<div #loadNextPageElement>
  <button
    id="pagination-next-btn"
    class="btn btn-block bg-white border-0 mt-4"
    (click)="paginate()"
    [title]="'load.next' | translate"
  >
    <i *ngIf="loadingPage" class="spinner-border text-primary spinner-border-sm me-2" role="status"> </i>
    {{ loadingPage ? ('loading' | translate) : ('load.next' | translate) }}
  </button>
</div>
