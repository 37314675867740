<modal-container
  #modal
  [headerIconClass]="'fa-download'"
  [headerTitle]="'import.do'"
  [ignoreBackdropClick]="true"
  [isLargeModal]="false"
>
  <ng-container class="modal-button-show">
    <btn
      [label]="'import.do'"
      [title]="'import.do' | translate"
      [icon]="'far-download'"
      [isLoading]="loading"
      [spinnerClassesString]="'spinner-blue'"
      [classes]="['btn-third', 'me-2']"
      (onEvent)="showChildModal()"
      [attr.data-cy]="'modal-purchase-orders-import-open'"
    ></btn>
  </ng-container>

  <ng-container class="modal-body-content">
    <div class="card">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
      <div *ngIf="!isImportMapUpdating && !isImportMapSet">
        <div class="row">
          <div class="col-12">
            <form-row-select
              [label]="'batch.file.type'"
              [icon]="'far-list'"
              [(model)]="selectedFileType"
              [options]="fileTypes"
            ></form-row-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 ps-0 pe-0" style="font-size: 12px">
            <div
              class="col-12 flex-gap-5 align-items-center"
              *ngIf="selectedFileType === 'depoto-purchase-orders.xlsx'"
            >
              <span>{{ 'modal-purchase-orders-import.template' | translate }}</span>
              <a
                class="inline-flex-gap-5 align-items-center"
                [href]="templatesURL + 'depoto-purchase-orders' + ('lang-url-fragment' | translate) + '.tpl.xlsx'"
              >
                <span class="far fa-file-download"></span>
                <span>depoto-purchase-orders.tpl.xlsx</span>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <form-row-input
              [label]="'file'"
              [icon]="'far-upload'"
              [inputType]="'file'"
              [appendBtnLabel]="'load'"
              [appendBtnClasses]="['btn-input']"
              (valueChangeNative)="loadFile($event)"
              (onAppendBtnEvent)="handleImportFile()"
            ></form-row-input>
          </div>
        </div>
      </div>
      <progressbar *ngIf="isImportMapSet" type="success" [value]="progress" [striped]="true" [max]="100"></progressbar>
      <span class="sr-only">{{ progress }}{{ 'modal-products-import.done' | translate }}</span>
      <div *ngIf="isImportMapUpdating" class="mt-2">
        <h3>{{ 'modal-purchase-orders-import.check-column-layout' | translate }}</h3>
        <div class="row">
          <div class="col-6">
            <form-row-select
              [label]="'purchaseOrder.supplierReference'"
              [(model)]="importMap.supplierReference"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select [label]="'ean'" [(model)]="importMap.ean" [options]="columnTypes"></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select [label]="'code'" [(model)]="importMap.code" [options]="columnTypes"></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'product.name'"
              [(model)]="importMap.productName"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'quantity'"
              [(model)]="importMap.quantity"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'purchaseOrder.purchasePriceWithoutVat'"
              [(model)]="importMap.purchasePriceWithoutVat"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'currency'"
              [(model)]="importMap.currency"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select [label]="'depot'" [(model)]="importMap.depot" [options]="columnTypes"></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'supplier'"
              [(model)]="importMap.supplier"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'purchaseOrder.reference'"
              [(model)]="importMap.reference"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'purchaseOrder.dateOfExpectedReceipt'"
              [(model)]="importMap.dateEstimatedArrival"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select [label]="'note'" [(model)]="importMap.note" [options]="columnTypes"></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'privateNote'"
              [(model)]="importMap.privateNote"
              [options]="columnTypes"
            ></form-row-select>
          </div>
        </div>
        <btn
          [btnType]="'button'"
          [label]="'continue'"
          [title]="'continue' | translate"
          (onEvent)="setImportMapAndContinue()"
        ></btn>
      </div>
      <div *ngIf="isImportFileLoaded" class="mt-2">
        <alert type="info"
          >{{ purchaseOrders.length }} {{ 'modal-purchase-orders-import.items-ready' | translate }}</alert
        >
      </div>
      <!--div *ngIf="skippedOrders.length > 0" class="mt-2">
        <alert type="warning" *ngFor="let number of skippedOrders">
          {{ ('purchaseOrders.number' | translate) + number + ('already.exist' | translate) }}
        </alert>
      </div -->
      <div *ngIf="errors && errors.length > 0" class="mt-2">
        {{ 'failed.to.import' | translate }}
        <alert type="warning" *ngFor="let e of errors">{{ e }}</alert>
      </div>
    </div>
  </ng-container>

  <ng-container class="modal-footer-content">
    <btn
      [label]="'import.do'"
      [title]="'import.do' | translate"
      [icon]="'far-download'"
      [classes]="['btn-first']"
      [btnDisabled]="!isImportFileLoaded || loading"
      (onEvent)="import()"
    ></btn>
  </ng-container>
</modal-container>
