<div class="app-container">
  <page-header [icon]="'fa-users-cog'" [title]="'groups'">
    <ng-container class="header-button">
      <btn
        [routerLink]="['/group']"
        [classes]="['btn-first', 'float-right']"
        [icon]="'fa-plus'"
        [label]="'group.btn.create'"
        [title]="'group.btn.create' | translate"
        [dataCyAttribute]="'create-user-group'"
      ></btn>
    </ng-container>
  </page-header>

  <section class="content">
    <app-base-listing
      [listName]="'userGroups'"
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="groups"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [sortProp]="sortProp"
      [sortDir]="sortDir"
      (reloadList)="filter(1)"
      (switchPage)="filter($event.currentPage)"
      (callAction)="callAction($event)"
    ></app-base-listing>
  </section>
</div>
