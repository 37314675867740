import { Pipe, PipeTransform } from '@angular/core'
import { Order } from 'depoto-core/src/entities'
import { DecimalPipe } from '@angular/common'
import { ReplacePipe } from './replaceCommaWithDot.pipe'

@Pipe({ name: 'orderItemsPriceSum' })
export class OrderItemsPriceSumPipe implements PipeTransform {
  constructor(
    private decimalPipe: DecimalPipe,
    private replacePipe: ReplacePipe
  ) {}

  transform(value: Order, args?: string[]): string {
    if (!value.items || (value.items && value.items.length < 1)) {
      return '0.00'
    }
    const sum = value.items
      .map(item => {
        return item.priceAll
      })
      .reduce((sum, amount) => {
        return Number(sum) + Number(amount)
      })
      .toFixed(2)

    const decimalSum = this.decimalPipe.transform(sum, '1.2-2')
    return this.replacePipe.transform(decimalSum)
  }
}
