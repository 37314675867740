<div class="card">
  <div class="card-body p-0">
    <div class="filter-form">
      <ng-content></ng-content>
      <div class="row">
        <div class="filter-lg-flex filter-lg-none col-12">
          <div class="flex-gap-5" [ngClass]="{ invisible: !(filters && entity), 'me-auto': !isInProducts }">
            <modal-export-create
              *ngIf="isExportButtonShown"
              [filters]="filters"
              [entity]="entity"
              (onResult)="triggerExportResult($event); onCreateModalHidden.emit(null)"
            ></modal-export-create>
            <modal-bulk-edits
              *ngIf="isInProducts"
              [checkedProducts]="checkedProducts"
              [allProductsSelected]="areAllProductsChecked"
              [filters]="filters"
              [tags]="tags"
              (refreshProductsPage)="refreshProduct()"
              (setCheckedProductsToFalse)="setCheckedProductsToFalseMethod()"
              [dataCyAttribute]="'bulk-edit-modal'"
            >
            </modal-bulk-edits>
            <app-exports *ngIf="areExportsInFilter" #exportsModal [entity]="entity"></app-exports>
            <btn
              *ngIf="isInProducts && isPurchaseOrderShown"
              [label]="'products.purchase-orders.stocking-orders'"
              [title]="'products.purchase-orders.stocking-orders' | translate"
              [classes]="['btn-third', 'float-right', 'me-2']"
              [icon]="''"
              [btnType]="'button'"
              [dataCyAttribute]="'create-purchase-orders-button'"
              (click)="checkIfAtLeastOneItemIsSelected()"
            >
            </btn>
            <div
              class="d-flex align-items-center"
              *ngIf="totalItems > 0 && (entity === 'products' || entity === 'orders')"
            >
              {{ ('record.amount' | translate) + (totalItems | number: '1.0-2' | replaceCommaToDot) }}
            </div>
          </div>
          <div
            class="rolling-content-btn align-self-center"
            (click)="triggerOnVisibilityChange()"
            (keyup)="triggerOnVisibilityChange()"
            [ngClass]="{ invisible: isFilterInvisible, 'margin-0-auto': isInProducts }"
            data-cy="rolling-filters-btn"
            role="button"
            tabIndex="0"
            aria-disabled="true"
          >
            <div class="flex-gap-5 align-items-center">
              <i [ngClass]="{ rotate: filterVisible }" class="fa fa-chevron-down" data-cy="filter"></i>
              <div>
                {{ (filterVisible ? 'filters.hide' : 'filters.show') | translate }}
              </div>
            </div>
          </div>
          <div class="flex-gap-5 flex-row-reverse ms-auto">
            <btn
              [btnType]="'submit'"
              [icon]="'far-search'"
              (onEvent)="triggerOnSubmit()"
              [label]="'filter'"
              [classes]="['btn-first']"
              [dataCyAttribute]="'submit-filter'"
              [title]="'filter' | translate"
            ></btn>
            <btn
              [btnType]="'button'"
              [icon]="'far-times'"
              (onEvent)="triggerOnClear()"
              [label]="'clear'"
              [classes]="['btn-third']"
              [dataCyAttribute]="'cancel-filter'"
              [title]="'clear' | translate"
            ></btn>
          </div>
        </div>

        <div class="filter-md-flex filter-md-none col-12 filter-responsive">
          <div
            class="rolling-content-btn align-self-center"
            (click)="triggerOnVisibilityChange()"
            (keyup)="triggerOnVisibilityChange()"
            [ngClass]="{ invisible: isFilterInvisible }"
            role="button"
            tabIndex="0"
            aria-disabled="true"
          >
            <div class="flex-gap-5 align-items-center">
              <i [ngClass]="{ rotate: filterVisible }" class="fa fa-chevron-down" data-cy="filter"></i>
              <div>
                {{ (filterVisible ? 'filters.hide' : 'filters.show') | translate }}
              </div>
            </div>
          </div>
          <div class="flex-gap-5 flex-row-reverse">
            <btn
              [btnType]="'submit'"
              [icon]="'far-search'"
              (onEvent)="triggerOnSubmit()"
              [label]="'filter'"
              [title]="'filter' | translate"
              [classes]="['btn-first']"
            ></btn>
            <btn
              [btnType]="'button'"
              [icon]="'far-times'"
              (onEvent)="triggerOnClear()"
              [label]="'clear'"
              [title]="'clear' | translate"
              [classes]="['btn-third']"
            ></btn>
            <modal-export-create
              [filters]="filters"
              [entity]="entity"
              (onResult)="triggerExportResult($event); onCreateModalHidden.emit(null)"
            ></modal-export-create>
            <app-exports *ngIf="areExportsInFilter" #exportsModal [entity]="entity"></app-exports>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
