import { Component } from '@angular/core'
import { ProductDepot } from 'depoto-core/src/entities'
import { CoreService } from '../../../services'
import { ProductDetailService } from '../../../product-detail.service'
import { Router } from '@angular/router'

@Component({
  selector: 'product-depots-form',
  templateUrl: './product-depots-form.component.html',
  styles: [
    `
      .quantityStock {
        color: #000;
      }
      .quantityReservation {
        color: #e8261e;
      }
      .quantityAvailable {
        color: #086e11;
      }
    `,
  ],
  styleUrls: ['product-depots-form.component.scss'],
})
export class ProductDepotsFormComponent {
  isShowingNullStock = false

  constructor(
    private core: CoreService,
    public productDetailService: ProductDetailService,
    public router: Router
  ) {}

  public modalStockResult() {
    this.productDetailService.resolveStock()
  }

  areJustNullQuantitiesInTable(stockItem) {
    // filter all productDepots with quantityStock or quantityReservation - if there aren't any -> show no items text
    return !!stockItem.productDepots.every(pDepot => {
      return (
        (!pDepot.quantityStock || pDepot.quantityStock <= 0) &&
        (!pDepot.quantityReservation || pDepot.quantityReservation <= 0)
      )
    })
  }

  printQR(depot: ProductDepot) {
    window['dataQR'] = {
      ean: this.productDetailService.product.ean || '',
      batch: depot.batch || '',
      expirationDate: depot.expirationDate || '',
    }
    this.router.navigate(['/product', this.productDetailService.product.id, 'properPrint'])
  }

  refreshDepotStats() {
    this.core.services.product.refreshProductDepots(this.productDetailService.product.id).then(() => {
      this.productDetailService.resolveStock()
    })
  }

  trackByFn(index: any) {
    return index
  }
}
