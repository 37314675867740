import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'formError' })
export class FormErrorPipe implements PipeTransform {
  transform(errors: string[], fieldType: string): string {
    if (!errors || (errors && !errors.length)) {
      return ''
    }
    if (errors.includes('required')) {
      return 'form-error.enter-value'
    }
    if (errors.includes('email')) {
      return 'form-error.invalid-email'
    }
    if (errors.includes('minlength')) {
      return 'form-error.min-length'
    }
    switch (fieldType) {
      case 'email':
        return errors.includes('required')
          ? 'form-error.enter-email'
          : errors.includes('email')
            ? 'form-error.invalid-email'
            : ''
      case 'password':
        return errors.includes('required') ? 'form-error.enter-password' : ''
      case 'clientType':
        return errors.includes('required') ? 'form-error.select-server' : ''
    }
  }
}
