<modal-container
  #modal
  [headerTitle]="isCreating ? 'orderItem.create' : 'orderItem.edit'"
  [headerIconClass]="isCreating ? 'far-plus' : 'far-edit'"
  class="h-75"
  [isLargeModal]="true"
>
  <ng-container class="modal-button-show">
    <btn
      *ngIf="isCreating"
      [label]="'orderItem.add'"
      [title]="'orderItem.add' | translate"
      [icon]="'far-plus'"
      [classes]="['btn-third', 'float-right', 'me-2']"
      (onEvent)="showChildModal()"
      [dataCyAttribute]="dataCyAttribute"
    ></btn>
    <btn
      *ngIf="!isCreating"
      [icon]="'far-pencil'"
      [title]="'edit' | translate"
      [classes]="['btn-edit', 'btn-sm']"
      (onEvent)="showChildModal()"
      [dataCyAttribute]="dataCyAttribute"
    ></btn>
  </ng-container>
  <ng-container class="modal-body-content p-0">
    <div class="card">
      <div class="card-body p-0">
        <div class="container-fluid">
          <div class="row justify-content-end">
            <div class="col"></div>
            <div
              class="btn-group mb-2 p-0 justify-content-end"
              btnRadioGroup
              [ngModel]="orderItem.type"
              (ngModelChange)="setItemType($event)"
              *ngIf="isCreating"
              data-cy="selected-order-item-type"
            >
              <span>
                <label
                  class="btn btn-primary btn-bg-success-active mb-0"
                  btnRadio="product"
                  data-cy="orderItem-type-item"
                >
                  {{ 'item' | translate }}
                </label>
                <label
                  class="btn btn-primary btn-bg-success-active mb-0"
                  btnRadio="shipping"
                  data-cy="orderItem-type-shipping"
                >
                  {{ 'shipping' | translate }}
                </label>
                <label
                  class="btn btn-primary btn-bg-success-active mb-0"
                  btnRadio="payment"
                  data-cy="orderItem-type-payment"
                >
                  {{ 'payment' | translate }}
                </label>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-9" *ngIf="!orderItem?.product">
            <form-row-input
              [icon]="null"
              [label]="'name'"
              [(model)]="orderItem.name"
              [dataCyAttribute]="'orderItem-name'"
            ></form-row-input>
          </div>
          <div
            class="col-sm-9"
            *ngIf="orderItem?.product"
            [hidden]="orderItem?.product?.mainImage.url && orderItem?.product?.mainImage?.url > 0"
          >
            <!--            ?? todo-->
            <label>{{ 'product.name' | translate }}:</label>
            <div class="card-subheading">
              {{ orderItem.name }}
            </div>
          </div>
          <div class="col-sm-3 d-flex align-items-center">
            <form-row-number-with-controls
              *ngIf="canEditQuantity && canCreateOrEdit"
              [(model)]="orderItem.quantity"
              [minWidthPx]="60"
              [label]="'quantity'"
              [noMarginBottom]="true"
              [dataCyAttribute]="'order-item-quantity'"
            ></form-row-number-with-controls>
            <form-row-input
              *ngIf="!canEditQuantity || !canCreateOrEdit"
              [inputDisabled]="true"
              [label]="'quantity'"
              [model]="orderItem.quantity"
            >
            </form-row-input>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <form-row-input
              [icon]="null"
              [label]="'code'"
              [(model)]="orderItem.code"
              [dataCyAttribute]="'product-code'"
            ></form-row-input>
          </div>
          <div class="col-sm-6">
            <form-row-input
              [icon]="null"
              [label]="'ean'"
              [(model)]="orderItem.ean"
              [dataCyAttribute]="'product-ean'"
            ></form-row-input>
          </div>
          <div class="col-sm-4" *ngIf="vats && vats.length > 0 && vats[0].id">
            <div class="form-group">
              <label class="mb-0">{{ 'product.vatrate' | translate }}:</label>
              <select
                class="form-control"
                [compareWith]="compareWithFn"
                [(ngModel)]="orderItem.vat"
                data-cy="product-vat"
              >
                <option *ngFor="let vat of vats" [ngValue]="vat">{{ vat.name }} ({{ vat.percent }}%)</option>
              </select>
            </div>
          </div>
          <div class="col-sm-4 flex-gap-5">
            <form-row-input
              [icon]="null"
              [label]="'price'"
              [inputType]="'number'"
              [(model)]="orderItem.price"
              [inputDisabled]="!canEditPrice && !canCreateOrEdit"
              [dataCyAttribute]="'orderItem-price'"
            >
            </form-row-input>
            <p class="my-auto pt-2 font-size-input">{{ currency | formatCurrencyWithoutPrice }}</p>
          </div>
          <div class="col-sm-4 flex-gap-5">
            <form-row-input
              [icon]="null"
              [label]="'sale'"
              [inputType]="'number'"
              [(model)]="orderItem.sale"
              [inputDisabled]="!canCreateOrEdit"
              [dataCyAttribute]="'orderItem-sale'"
            >
            </form-row-input>
            <p class="my-auto pt-2 font-size-input">%</p>
          </div>
        </div>
        <div class="col-sm-12 ps-0 pe-0">
          <form-row-textarea
            [icon]="null"
            [label]="'note'"
            [(model)]="orderItem.note"
            [dataCyAttribute]="'product-note'"
          ></form-row-textarea>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content">
    <btn
      *ngIf="isCreating"
      [label]="'add'"
      [title]="'add' | translate"
      [icon]="'far-plus'"
      (onEvent)="createOrderItem()"
      [classes]="['btn-first']"
      [btnType]="'submit'"
      [dataCyAttribute]="'submit'"
    ></btn>
    <btn
      *ngIf="!isCreating"
      [label]="'save'"
      [title]="'save' | translate"
      [icon]="'far-check'"
      (onEvent)="updateOrderItem()"
      [classes]="['btn-first']"
      [btnType]="'submit'"
      [dataCyAttribute]="'orderItem-submit'"
    ></btn>
  </ng-container>
</modal-container>
