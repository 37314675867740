import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Tag } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../services'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { TranslateService } from '@ngx-translate/core'
import { historyBack } from '../../utils'

const tagSchema = {
  id: null,
  name: null,
  type: null,
  color: null,
  externalId: null,
  company: {
    id: null,
    name: null,
  },
  created: null,
  updated: null,
}

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
})
export class TagComponent implements OnInit, CheckDataChanges {
  public isCreating = false
  public loading = false
  public tag: Tag
  public tagTypes = []
  submitted = false

  tagForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, [Validators.required]),
    type: new FormControl({ value: '', disabled: false }, [Validators.required]),
    color: new FormControl({ value: '', disabled: false }, []),
    externalId: new FormControl({ value: '', disabled: false }, []),
  })

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    const types = await this.core.services.tag.getTypes({}, { id: null })
    if (Array.isArray(types?.items)) {
      this.tagTypes = types.items.map(item => item.id)
    }
    const id = this.route.snapshot.params['id']
    if (isNaN(id)) {
      this.isCreating = true
      this.tag = new Tag()
      this.loading = false
    } else {
      this.tag = await this.core.services.tag.getById(id, tagSchema)
      this.fillFormValue()
      this.loading = false
    }
  }

  public fillFormValue() {
    this.tagForm.setValue({
      name: this.tag.name,
      type: this.tag.type,
      color: this.tag.color,
      externalId: this.tag.externalId,
    })
  }

  public getFormValues() {
    this.tag.name = this.tagForm.value.name
    this.tag.type = this.tagForm.value.type
    this.tag.color = this.tagForm.value.color
    this.tag.externalId = this.tagForm.value.externalId
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  goBack(): void {
    historyBack()
  }

  public async createTag() {
    this.submitted = true
    if (this.tagForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValues()
      await this.core.services.tag.create(this.tag).then(result => {
        this.notificationService.success(this.translateService.instant('alert.tag.created'))
        this.router.navigate(['/tag', result.id], { replaceUrl: true })
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  public async updateTag() {
    this.submitted = true
    if (this.tagForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValues()
      this.tag = await this.core.services.tag.update(this.tag).then(result => {
        this.notificationService.success(this.translateService.instant('alert.tag.updated'))
        return result
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  public async deleteTag() {
    try {
      await this.core.services.tag.delete(this.tag).then(() => {
        this.notificationService.success(this.translateService.instant('alert.tag.deleted'))
        this.router.navigate(['/tags'], { replaceUrl: true })
      })
    } catch (err) {
      console.error(err)
    }
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.tagForm.controls
  }
}
