<div>
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <div [ngClass]="{ 'd-none': isNoDataForChart }">
    <canvas
      #chartElement
      [id]="chartId"
      class="chart w-100 h-auto"
      [width]="chartCanvasWidth"
      [height]="chartCanvasHeight"
    ></canvas>
  </div>
  <div *ngIf="isNoDataForChart" class="text-center m-3">{{ 'chart.noDataFor' | translate }} {{ type | translate }}</div>
</div>
