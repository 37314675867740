import { Injectable } from '@angular/core'
import { UserService } from 'depoto-core/src/services/entities/UserService'
import { TranslateService } from '@ngx-translate/core'
import { CoreService } from '../services'

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(
    private readonly translateService: TranslateService,
    private core: CoreService
  ) {}

  menuItems(userService: UserService): MenuItems[] {
    const isAdmin = userService.hasRole('ROLE_SUPER_ADMIN') || userService.hasRole('ROLE_ADMIN')
    const isDepotoUser =
      userService?.user?.email?.includes('client.depoto.cz') || userService?.user?.email?.includes('tomatom.cz')
    const canViewPurchaseOrders =
      userService.hasRole('CLIENT_MENU_PURCHASE_ORDERS') &&
      this.core.services.user.user.company.moduleRelations?.some(
        moduleRelation => moduleRelation.module.id === 'purchase-orders'
      )
    const menuLinks: MenuItems[] = [
      {
        title: this.translateService.instant('menu.overview'),
        show: true,
        icon: 'home',
        link: ['/home'],
      },
      {
        title: this.translateService.instant('menu.orders'),
        show: isAdmin || userService.hasRole('CLIENT_MENU_ORDERS'),
        icon: 'shopping-cart',
        link: ['/orders'],
      },
      {
        title: this.translateService.instant('menu.purchase-orders'),
        show: isAdmin || canViewPurchaseOrders,
        icon: 'truck',
        link: ['/purchase-orders'],
      },
      {
        title: this.translateService.instant('menu.products'),
        show: isAdmin || userService.hasRole('CLIENT_MENU_PRODUCTS'),
        // icon: 'fad fa-list-alt', // todo icon which one
        icon: 'box',
        link: ['/products'],
      },
      {
        title: this.translateService.instant('menu.stock-batch'),
        show: isAdmin || userService.hasRole('CLIENT_MENU_BATCH_OPERATIONS'),
        // icon: 'fad fa-inbox-out', // todo ktera icon
        icon: 'layer-group',
        link: ['/stock-batch'],
      },
      {
        title: this.translateService.instant('menu.moves'),
        show: isAdmin || userService.hasRole('CLIENT_MENU_PRODUCT_MOVES'),
        icon: 'warehouse-alt',
        link: ['/product-move-packs'],
      },
      {
        title: this.translateService.instant('menu.customers'),
        show: isAdmin || userService.hasRole('CLIENT_MENU_CUSTOMERS'),
        icon: 'users',
        link: ['/customers'],
      },
      {
        title: this.translateService.instant('menu.vouchers'),
        show: isAdmin || userService.hasRole('CLIENT_MENU_VOUCHERS'),
        icon: 'badge-percent',
        link: ['/vouchers'],
      },
      {
        title: this.translateService.instant('menu.sold-items'),
        show: isAdmin || userService.hasRole('CLIENT_MENU_SOLDITEMS'),
        icon: 'far fa-analytics',
        link: ['/sold-items'],
      },
      {
        title: this.translateService.instant('menu.prints'),
        show: isAdmin || userService.hasRole('CLIENT_MENU_PRINTS'),
        icon: 'print',
        sublinks: [
          {
            title: this.translateService.instant('menu.prints.purchase-exports'),
            show: true,
            icon: 'file-invoice',
            link: ['/print/purchase-exports'],
          },
          {
            title: this.translateService.instant('menu.prints.sale-exports'),
            show: true,
            icon: 'file-invoice-dollar',
            link: ['/print/sale-exports'],
          },
          {
            title: this.translateService.instant('menu.prints.stock-exports'),
            show: true,
            icon: 'file-alt',
            link: ['/print/stock-exports'],
          },
          {
            title: this.translateService.instant('menu.prints.inventory-exports'),
            show: true,
            icon: 'file-check',
            link: ['/print/inventory-exports'],
          },
          {
            title: this.translateService.instant('menu.prints.orders-exports'),
            show: true,
            icon: 'file-contract',
            link: ['/print/orders-exports'],
          },
        ],
      },
      {
        title: this.translateService.instant('menu.settings'),
        show: isAdmin || userService.hasRole('CLIENT_MENU_SETTINGS'),
        icon: 'cog',
        sublinks: [
          {
            title: this.translateService.instant('menu.users'),
            show: isAdmin || userService.hasRole('ROLE_USER_LIST'),
            icon: 'user',
            link: ['/users'],
          },
          {
            title: this.translateService.instant('menu.checkouts'),
            show: isAdmin || userService.hasRole('ROLE_CHECKOUT_LIST'),
            icon: 'cash-register',
            link: ['/checkouts'],
          },
          {
            title: this.translateService.instant('menu.tags'),
            show: isAdmin || userService.hasRole('ROLE_TAG_LIST'),
            icon: 'tags',
            link: ['/tags'],
          },
          {
            title: this.translateService.instant('menu.categories'),
            show: isAdmin || userService.hasRole('ROLE_CATEGORY_LIST'),
            icon: 'sitemap',
            link: ['/categories'],
          },
          {
            title: this.translateService.instant('menu.depots'),
            show: isAdmin || userService.hasRole('ROLE_DEPOT_LIST'),
            icon: 'warehouse',
            link: ['/depots'],
          },
          {
            title: this.translateService.instant('menu.suppliers'),
            show: isAdmin || userService.hasRole('ROLE_SUPPLIER_LIST'),
            icon: 'handshake',
            link: ['/suppliers'],
          },
          {
            title: this.translateService.instant('menu.producers'),
            show: isAdmin || userService.hasRole('ROLE_PRODUCER_LIST'),
            icon: 'industry',
            link: ['/producers'],
          },
          {
            title: this.translateService.instant('menu.company-carriers'),
            show: isAdmin || userService.hasRole('ROLE_CARRIER_LIST') || userService.hasRole('ROLE_SUPPLIER_LIST'),
            icon: 'truck',
            link: ['/company-carriers'],
          },
          {
            title: this.translateService.instant('menu.parameters'),
            show: isAdmin || userService.hasRole('ROLE_PARAMETERS_LIST'),
            icon: 'tag',
            link: ['/parameters'],
          },
          {
            title: this.translateService.instant('menu.payments'),
            show: isAdmin || userService.hasRole('ROLE_PAYMENTS_LIST'),
            icon: 'credit-card',
            link: ['/payments'],
          },
          {
            title: this.translateService.instant('menu.vats'),
            show: isAdmin || userService.hasRole('ROLE_VAT_LIST'),
            icon: 'badge-percent',
            link: ['/vats'],
          },
          {
            title: this.translateService.instant('menu.consents'),
            show: isAdmin || userService.hasRole('ROLE_CONSENTS_LIST'),
            icon: 'file-check',
            link: ['/consents'],
          },
          {
            title: this.translateService.instant('menu.price-levels'),
            show: isAdmin || userService.hasRole('ROLE_PRICE_LEVEL_LIST'),
            icon: 'hands-usd',
            link: ['/price-levels'],
          },
          {
            title: this.translateService.instant('menu.company'),
            show: isAdmin,
            icon: 'cog',
            link: ['/company'],
          },
        ],
      },
      {
        title: this.translateService.instant('menu.label-print'),
        show: isAdmin || userService.hasRole('CLIENT_MENU_LABELS'),
        icon: 'print',
        sublinks: [
          {
            title: this.translateService.instant('menu.label-print.box'),
            show: true,
            icon: 'box',
            link: ['/label-print', 'box'],
          },
          {
            title: this.translateService.instant('menu.label-print.position'),
            show: true,
            icon: 'tag',
            link: ['/label-print', 'position'],
          },
        ],
      },
    ]

    if (window.location.href.includes('local') || window.location.href.includes('stage') || isDepotoUser) {
      menuLinks.push({
        title: 'Dev options',
        show: true,
        icon: 'code',
        sublinks: [
          {
            title: 'Atomic Components',
            show: true,
            icon: 'cube',
            link: ['/atomic-components'],
          },
        ],
      })
    }
    return menuLinks
  }
}
interface MenuItem {
  title: string
  show: boolean
  icon: string
  link?: string[]
}

export interface MenuItems {
  title: string
  show: boolean
  icon: string
  link?: string[]
  sublinks?: MenuItem[]
}
