import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

export interface CheckDataChanges {
  hasUnsavedDataChanges(): boolean
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuardService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly location: Location,
    private readonly router: Router
  ) {}
  canDeactivate(
    component: CheckDataChanges,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.hasUnsavedDataChanges()) {
      return confirm(this.translateService.instant('unsaved-changes.confirmation'))
    } else {
      return true
    }
  }
}
