import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { CoreService } from '../../../services'
import { Product, OrderItem, Customer, Checkout, File, Currency, Category } from 'depoto-core/src/entities'
import { SchemaUtil } from '../../../utils'
import { DateUtil } from 'depoto-core/src/utils'

@Component({
  selector: 'modal-product-choose',
  templateUrl: 'modal-product-choose.component.html',
  styleUrls: ['modal-product-choose.component.scss'],
})
export class ModalProductChooseComponent implements OnInit {
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Input() public checkout: Checkout
  @Input() public customer: Customer
  @Input() currency: Currency
  @Input() currencyDate: string
  @Input() dataCyAttribute: string
  @Output() public chosenProduct: EventEmitter<any> = new EventEmitter()
  @Output() public chosenOrderItem: EventEmitter<any> = new EventEmitter()
  searchValue = ''
  products: Product[] = []
  availabilityTypes: string[] = ['all', 'available', 'reservation', 'stock']
  selectedAvailability = 'available'
  selectedCategories: Category[] = []
  pageNumber = 1
  maxPageNumber = 999
  loading = false
  hasSpecialAddOrderItemsRole = false
  intersectionObserver = null
  scrollDebounceTimeout = null

  constructor(private core: CoreService) {}

  ngOnInit() {
    this.hasSpecialAddOrderItemsRole = this.core.services.user.hasRole('ROLE_ORDER_SELLITEMS')
  }

  public async chooseProduct(product: Product) {
    try {
      await this.chosenProduct.emit(product)
    } catch (err) {
      console.error(err)
    }
  }

  async searchForProduct(addPage?: number) {
    if (addPage) {
      this.pageNumber += addPage
    } else {
      this.pageNumber = 1
    }
    this.loading = true
    const products = await this.core.services.product
      .getList(
        {
          page: this.pageNumber,
          currency: this.currency?.id ? this.currency?.id : 'CZK',
          currencyDate: DateUtil.getDateStringFromUTCString(this.currencyDate),
          filters: {
            fulltext: this.searchValue.trim(),
            listType: 'children_and_parents_without_children', // todo to je dobre?:D
            availability: this.selectedAvailability,
            categories: this.selectedCategories?.length > 0 ? this.selectedCategories?.map(cat => cat.id) : [],
            enabled: true,
          },
        },
        SchemaUtil.productOrderAdd
      )
      .then(result => {
        this.maxPageNumber = result.paginator.endPage
        return result.items.map(p => new Product(p))
      })
    if (this.pageNumber === 1) {
      this.products = products
    } else {
      this.products = [...this.products, ...products]
    }
    this.loading = false
    setTimeout(() => this.initIntersectionObserver(), 300)
  }

  public async showChildModal() {
    this.loading = true
    await this.searchForProduct()
    this.childModal.showChildModal()
  }

  public chosenProductDepot(orderItem: OrderItem) {
    this.chosenOrderItem.emit(orderItem)
  }

  getThumbUrl(image: File): string {
    return image.thumbnails.filter(t => t.format === 'w135wp')[0].url
  }

  updateQuantityFromPacks(product, pack, quantity) {
    pack['quantityTmp'] = Number(quantity)
    product.orderQuantity = product.packs
      .map(p => (p['quantityTmp'] || 0) * p.quantity)
      .reduce((a, b) => Number(a) + Number(b))
  }

  initIntersectionObserver() {
    const el = document.getElementById('next-page-btn')
    if (el) {
      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(el)
      }
      this.intersectionObserver = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && !this.loading && !this.scrollDebounceTimeout) {
            this.searchForProduct(1)
            this.scrollDebounceTimeout = setTimeout(() => {
              this.scrollDebounceTimeout = null
            }, 700)
          }
        },
        { threshold: [0] }
      )
      this.intersectionObserver.observe(el)
    }
  }
}
