import { Component, HostListener } from '@angular/core'
import { User } from 'depoto-core/src/entities'
import { CoreService } from '../../services'
// @ts-ignore
import packageInfo from './../../../../package.json'
import corePackageInfo from './../../../../node_modules/depoto-core/package.json'

@Component({
  selector: 'box-user',
  templateUrl: './box-user.component.html',
  styles: [
    `
      .dropdown-menu .dropdown-item:active {
        background-color: #f8f9fa;
        color: #16181b;
      }
    `,
  ],
  styleUrls: ['box-user.component.scss'],
})
export class BoxUserComponent {
  appVersion = `${packageInfo.version} [${packageInfo.lastCommit}/${corePackageInfo.version}]`
  user: User = new User({
    email: '',
    avatar_url: 'assets/img/default-user.png',
  })
  isHidden: boolean
  constructor(private core: CoreService) {
    window.getAppVersion = () => {
      return [
        `app:             ${this.appVersion}`,
        `core should be:  ${packageInfo.dependencies['depoto-core']?.split('#')?.[1]}`,
        `core is:         ${corePackageInfo.version}`,
      ]
    }
    this.core.services.user.currentUserEmitter.subscribe().then((user: User) => {
      this.user = user
    })
  }

  logout() {
    this.core.services.oauth.logout()
  }

  @HostListener('window:mousedown', ['$event'])
  onUserBoxClick(event) {
    const isUserBox =
      (event.path || (event.composedPath && event.composedPath())).filter(
        d => d && typeof d.className?.includes === 'function' && d.className.includes('nav-settings')
      ).length > 0
    if (isUserBox) {
      this.isHidden = !this.isHidden
    } else {
      this.isHidden = false
    }
  }
}
