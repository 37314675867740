import { Component, OnInit } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { CoreService } from '../../services'
import { Payment } from 'depoto-core/src/entities'
import { SchemaUtil } from '../../utils'
import { paymentsColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  name: '',
}

@Component({
  selector: 'app-payments',
  templateUrl: 'payments.component.html',
})
export class PaymentsComponent extends BaseListing implements OnInit {
  listingColumns: ListingItem[] = paymentsColumns
  sortProp = 'name'
  sortDir = 'asc'
  payments: Payment[] = []
  totalItems = 0
  currentPage: number
  endPage: number
  filters = { ...DEFAULT_FILTERS }
  canCreate = false
  loading = true
  storageKey: { type: string; key: string }
  constructor(protected core: CoreService) {
    super(core)
    this.getStorageKey()
  }

  ngOnInit() {
    this.filters = this.loadViewSettings('payments') || { ...DEFAULT_FILTERS }
    if (this.core.services.user.user) {
      this.checkPerms()
    }
    this.core.services.user.currentUserEmitter.subscribe().then(() => {
      this.checkPerms()
    })
    this.loadSorting('payments')
    this.filter()
  }

  checkPerms() {
    this.canCreate =
      this.core.services.user.hasRole('ROLE_PAYMENT_CREATE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
  }

  clearFilters(): void {
    this.filters.name = ''
    this.filter(1)
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.currentPage = pageNo
    if (!concat) {
      this.reloading = true
    }
    try {
      const result = await this.core.services.payment.getList(
        { page: pageNo, sort: sortProp, direction: sortDir, filters: { name: this.filters.name } },
        SchemaUtil.payment
      )
      if (!result?.items) return
      concat
        ? result.items.forEach(payment => this.payments.push(new Payment(payment)))
        : (this.payments = result.items)
      this.totalItems = result.paginator ? result.paginator.totalCount : 0
      this.endPage = result.paginator ? result.paginator.endPage : 0
      this.currentPage++
      this.saveViewSettings('payments', this.filters)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }
}
