import { Component, OnInit, ViewChildren, ViewChild, QueryList, AfterViewInit } from '@angular/core'
import { ProductMove, Tag, User } from 'depoto-core/src/entities'
import { CoreService } from '../../../services'
import { PopoverDirective } from 'ngx-bootstrap/popover'
import { FilterFormComponent } from '../../_common/filter-form/filter-form.component'
import { SchemaUtil } from '../../../utils'
import { ProductDetailService } from '../../../product-detail.service'
import { BaseListing } from 'src/app/pages/_base-listing/base-listing'

@Component({
  selector: 'product-moves-list',
  templateUrl: 'product-moves-list.component.html',
  styleUrls: ['product-moves-list.component.scss'],
})
export class ProductMovesListComponent extends BaseListing implements AfterViewInit, OnInit {
  @ViewChildren(PopoverDirective) popovers: QueryList<PopoverDirective>
  @ViewChild('filterForm') filter_form: FilterFormComponent
  productMoves: ProductMove[] = []
  operationTypes: string[] = ['all', 'in', 'out', 'transfer', 'reservation']
  selectedOperationType = 'all'
  selectedDepotFrom = 0
  selectedDepotTo = 0
  filterFrom: string
  filterTo: string
  filterUser = 0
  filterTags: Tag[] = []
  users: User[] = []
  tags: Tag[] = []
  batches: string[] = ['all']
  selectedBatch = 'all'
  expirationDates: string[] = ['all']
  totalItems = 0
  currentPage: number
  endPage: number
  sortProp = 'created'
  sortDir = 'desc'
  loading = true
  scrollDebounceTimeout = null
  intersectionObserver = null
  expirations: string[] = ['all', 'monthEnd', 'after1month', 'after3months', 'custom']
  selectedExpiration = 'all'
  selectedExpirationDate: string

  constructor(
    public core: CoreService,
    public productDetailService: ProductDetailService
  ) {
    super(core)
  }

  async ngOnInit() {
    this.loadViewSettings()
    await this.refreshFiltersData()
    await this.filter(1)
  }

  loadViewSettings(): void {
    const settings = this.core.services.storage.getSync(this.getStorageKeyForEntity('productMovesList'))
    if (settings && Object.keys(settings).length) {
      this.selectedOperationType = settings.selectedOperationType || 'all'
      this.selectedDepotFrom = settings.selectedDepotFrom
      this.selectedDepotTo = settings.selectedDepotTo
      this.sortProp = settings.sortProp
      this.sortDir = settings.sortDir
    }
  }

  saveViewSettings(): void {
    const settings = {
      selectedOperationType: this.selectedOperationType,
      selectedDepotFrom: this.selectedDepotFrom,
      selectedDepotTo: this.selectedDepotTo,
      sortProp: this.sortProp,
      sortDir: this.sortDir,
    }
    this.core.services.storage.set(this.getStorageKeyForEntity('productMovesList'), settings)
  }

  get queryFilters() {
    const f: any = {
      product: this.productDetailService.product.id,
      depotFrom: this.selectedDepotFrom > 0 ? this.selectedDepotFrom : undefined,
      depotTo: this.selectedDepotTo > 0 ? this.selectedDepotTo : undefined,
      dateFrom: this.filterFrom?.length ? this.filterFrom : undefined,
      dateTo: this.filterTo?.length ? this.filterTo : undefined,
      operationType: this.selectedOperationType !== 'all' ? this.selectedOperationType : undefined,
      batch: this.selectedBatch !== 'all' ? this.selectedBatch : undefined,
      expirationDate: this.selectedExpirationDate !== 'all' ? this.selectedExpirationDate : undefined,
      user: this.filterUser > 0 ? this.filterUser : undefined,
      tags: this.filterTags?.length ? this.filterTags : [],
    }
    Object.keys(f).forEach(k => {
      if (f[k] === undefined) {
        delete f[k]
      }
    })
    return f
  }

  async clearFilters(type?: string) {
    if (type !== null && type === 'depots') {
      this.selectedDepotFrom = 0
      this.selectedDepotTo = 0
    } else {
      this.selectedOperationType = 'all'
      this.selectedDepotFrom = 0
      this.selectedDepotTo = 0
      this.selectedBatch = 'all'
      this.selectedExpirationDate = 'all'
      this.filterUser = 0
      this.filterTags = []
    }
    this.saveViewSettings()
  }

  async refreshFiltersData(): Promise<any> {
    if (!this.productDetailService.depots.filter(d => d.id === 0).length) {
      this.productDetailService.depots.unshift({ id: 0, name: 'all', unavailablesUrl: '', checkouts: [], users: [] })
    }
    this.users = [
      { id: 0, email: 'all' },
      ...(await this.core.services.user
        .getList({}, { id: null, firstName: null, lastName: null, name: null, email: null })
        .then(result => result.items)),
    ]
    this.tags = this.core.lists.tags
    this.loadFiltersFromStock()
  }

  loadFiltersFromStock() {
    const batches = []
    const expirationDates = []
    if (this.productDetailService.stock?.length) {
      this.productDetailService.stock.forEach(s => {
        s.productDepots.forEach(si => {
          if (si.batch) {
            batches.push(si.batch)
          }
          if (si.expirationDate) {
            expirationDates.push(si.expirationDate)
          }
        })
      })
    }
    this.batches = ['all', ...batches]
    this.expirationDates = ['all', ...Array.from(new Set(expirationDates))]
    // this.expirationDates = ['all', ...expirationDates]
  }

  setExpirationFilter(expStr: string = null, expDate: string = null) {
    if (expStr) {
      this.selectedExpiration = expStr
      let date = new Date()
      switch (this.selectedExpiration) {
        case 'all':
          this.selectedExpirationDate = null
          return
        case 'monthEnd':
          date = new Date(date.getFullYear(), date.getMonth() + 1, 0)
          break
        case 'after1month':
          date.setMonth(date.getMonth() + 1)
          break
        case 'after3months':
          date.setMonth(date.getMonth() + 3)
          break
        case 'custom':
          return
      }

      const tzOffset = date.getTimezoneOffset()
      date.setTime(date.getTime() + tzOffset * 60 * -1000)

      this.selectedExpirationDate = `${date.toISOString().substring(0, 10)} 23:59:59`
    } else if (expDate) {
      this.selectedExpirationDate = `${new Date(expDate).toISOString().substring(0, 10)} 23:59:59`
    }
  }

  async switchDepots() {
    const depotFrom: number = this.selectedDepotFrom
    this.selectedDepotFrom = this.selectedDepotTo
    this.selectedDepotTo = depotFrom
    this.saveViewSettings()
    await this.filter(1)
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.currentPage = pageNo

    setTimeout(async () => {
      const options: any = {
        product: this.productDetailService.product.id,
      }
      if (this.selectedOperationType !== 'all') {
        options.type = this.selectedOperationType
      }
      if (this.selectedDepotFrom > 0) {
        options.depotFrom = Number(this.selectedDepotFrom)
      }
      if (this.selectedDepotTo > 0) {
        options.depotTo = Number(this.selectedDepotTo)
      }
      if (this.filterUser > 0) {
        options.createdBy = Number(this.filterUser)
      }
      if (this.filterTags?.length) {
        options.tags = this.filterTags
      }
      if (this.selectedBatch?.length && this.selectedBatch !== 'all') {
        options.batch = this.selectedBatch
      }
      if (this.selectedExpirationDate?.length && this.selectedExpirationDate !== 'all') {
        options.expirationDate = this.selectedExpirationDate
      }
      if (this.filterFrom || this.filterTo) {
        options.created = {
          ...(this.filterFrom?.length && {
            left_date: this.filterFrom,
          }),
          ...(this.filterTo && {
            right_date: this.filterTo,
          }),
        }
      }
      // TODO filter depots  ??
      // if (!this.core.userHasAllDepots && this.core.services.user.user?.depots?.length && !options.depots?.length) {
      //   options.depots = this.core.lists.depots.map(d => d.id)
      // }
      const result = await this.core.services.productMove.getList(
        { page: pageNo, sort: sortProp, direction: sortDir, filters: options },
        SchemaUtil.productMove
      )
      if (concat) {
        result.items.forEach(move => {
          this.productMoves.push(new ProductMove(move))
        })
      } else {
        this.productMoves = result.items.map(move => new ProductMove(move))
      }
      this.totalItems = result.paginator ? result.paginator.totalCount : 0
      this.endPage = result.paginator ? result.paginator.endPage : 0
      this.currentPage++
      this.saveViewSettings()
      this.productDetailService.productMoves = this.productDetailService.stockDepotsChartData = this.productMoves
      setTimeout(() => {
        this.loading = false
        this.initIntersectionObserver()
      }, 300)
    }, 300)
  }

  async sortBy(property: string) {
    if (this.sortProp === property) {
      this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc'
    } else {
      this.sortProp = property
    }
    this.currentPage = 1
    await this.filter()
    this.saveViewSettings()
  }

  initIntersectionObserver() {
    const el = document.getElementById('next-page-btn')
    if (el) {
      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(el)
      }
      this.intersectionObserver = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && !this.loading && !this.scrollDebounceTimeout) {
            this.filter(this.currentPage, this.sortProp, this.sortDir, true)
            this.scrollDebounceTimeout = setTimeout(() => {
              this.scrollDebounceTimeout = null
            }, 700)
          }
        },
        { threshold: [0] }
      )
      this.intersectionObserver.observe(el)
    }
  }
  ngAfterViewInit() {
    this.popovers.forEach((popover: PopoverDirective) => {
      popover.onShown.subscribe(() => {
        this.popovers.filter(p => p !== popover).forEach(p => p.hide())
      })
    })
  }
}
