<ng-template #caretIcon let-prop="prop">
  <i
    [ngClass]="
      sortProp !== prop
        ? 'far fa-caret-up ps-1'
        : sortDir === 'asc'
          ? 'fas fa-caret-down ps-1'
          : sortDir === 'desc'
            ? 'fas fa-caret-up ps-1'
            : ''
    "
  ></i>
</ng-template>
<div class="app-container">
  <page-header [icon]="'far-cube'" [title]="'products'">
    <ng-container class="header-button">
      <btn
        *ngIf="canCreate"
        [routerLink]="['/product']"
        [label]="'product.create'"
        [title]="'product.create' | translate"
        [classes]="['btn-first', 'float-right']"
        [icon]="'far-plus'"
        [dataCyAttribute]="'add-product'"
      ></btn>
      <modal-batch-import-from-file
        class="me-2"
        [suppliers]="suppliers"
        [isCreatingProducts]="true"
        [isInProducts]="true"
        (resultBatch)="createBatchFromFile($event)"
      ></modal-batch-import-from-file>
      <app-exports *ngIf="canCreate" #exportsModal [entity]="'products'"></app-exports>
      <btn
        [routerLink]="['/print/inventory-exports']"
        [classes]="['btn-third', 'float-right', 'me-2']"
        [icon]="'far-file-check'"
        [label]="'Inventury'"
        [title]="'Inventury'"
        [dataCyAttribute]="'inventories'"
      ></btn>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form
      [filters]="queryFilters"
      [loading]="loading || reloading"
      [entity]="'products'"
      [totalItems]="totalItems"
      [filterVisible]="filtersExpanded"
      (onSubmit)="filter()"
      (onClearClick)="clearFilters()"
      (onExportResult)="filter()"
      (onCreateModalHidden)="exportsModal.showChildModal()"
      (filterVisibleChange)="filtersExpanded = $event; changeDetector.detectChanges(); saveView()"
      [checkedProducts]="checkedProducts"
      [areAllProductsChecked]="areAllProductsChecked"
      [tags]="tags"
      [isInProducts]="true"
      [isPurchaseOrderShown]="isPurchaseOrderAllowed"
      [isExportButtonShown]="true"
      (setCheckedProductsToFalse)="setCheckedProductsToFalse()"
      (onPurchaseOrderClick)="modalproductPurchase.showChildModal()"
    >
      <div class="container-fluid ps-0 pe-0">
        <!--      FIRST ROW OF INPUTS-->
        <div class="row mr-0">
          <div class="col-md-4">
            <form-row-input
              [label]="'fulltext'"
              [icon]="null"
              [(model)]="filters.fulltext"
              (keyup.enter)="filter()"
              [dataCyAttribute]="'product-name-filter'"
            ></form-row-input>
          </div>
          <div class="col-md-2">
            <form-row-select-multiple
              [label]="'products.depots'"
              [options]="depots"
              [(selectedValues)]="filters.depots"
              [dataCyAttribute]="'product-depots-filter'"
            ></form-row-select-multiple>
          </div>
          <div class="col-md-2">
            <form-row-select
              [label]="'products.availability'"
              [icon]="null"
              [(model)]="filters.availability"
              [options]="availabilityTypes"
              [dataCyAttribute]="'product-availability-filter'"
            ></form-row-select>
          </div>
          <div class="col-md-2">
            <form-row-select-multiple
              [label]="'tags'"
              [options]="tags"
              [(selectedValues)]="filters.tags"
              [dataCyAttribute]="'product-tags-filter'"
            ></form-row-select-multiple>
          </div>
          <div class="col-md-1" title="{{ 'product.isBundle.tooltip' | translate }}">
            <form-row-select
              [label]="'product.virtual'"
              [icon]="null"
              [(model)]="filters.isBundle"
              [options]="abilityTypes"
              [dataCyAttribute]="'product-is-bundle-filter'"
            ></form-row-select>
          </div>
          <div class="col-md-1 pe-0">
            <form-row-select
              [label]="'product.enabled'"
              [icon]="null"
              [(model)]="filters.enabled"
              [options]="abilityTypes"
              [dataCyAttribute]="'product-enabled-filter'"
            ></form-row-select>
          </div>
        </div>
        <div class="custom-dropdown" [ngClass]="{ open: filtersExpanded }">
          <div class="row mr-0">
            <div class="col-md-2">
              <form-row-input
                [label]="'product.code'"
                [icon]="null"
                [(model)]="filters.code"
                (keyup.enter)="filter()"
                [dataCyAttribute]="'product-code-filter'"
              ></form-row-input>
            </div>
            <div class="col-md-2">
              <form-row-input
                [label]="'ean'"
                [icon]="null"
                [(model)]="filters.ean"
                (keyup.enter)="filter()"
                [dataCyAttribute]="'product-ean-filter'"
              ></form-row-input>
            </div>
            <div class="col-md-2">
              <form-row-select-multiple
                [label]="'products.suppliers'"
                [options]="suppliers"
                [(selectedValues)]="filters.suppliers"
                [dataCyAttribute]="'product-suppliers-filter'"
              ></form-row-select-multiple>
            </div>
            <div class="col-md-2">
              <form-row-select-multiple
                [label]="'products.producers'"
                [options]="producers"
                [(selectedValues)]="filters.producers"
                [dataCyAttribute]="'product-producers-filter'"
              ></form-row-select-multiple>
            </div>
            <div class="col-md-2">
              <form-row-select
                [label]="'expirationDateTo'"
                [icon]="null"
                [model]="selectedExpiration"
                (modelChange)="setExpirationFilter($event)"
                [options]="expirations"
                [dataCyAttribute]="'product-selected-expiration-filter'"
              ></form-row-select>
              <form-row-input
                *ngIf="selectedExpiration === 'custom'"
                [label]="'expirationDateTo'"
                [icon]="null"
                [inputType]="'date'"
                (modelChange)="setExpirationFilter(null, $event)"
                [ngModel]="filters.expirationDateTo?.substr(0, 10)"
                [dataCyAttribute]="'product-expiration-date-filter'"
              ></form-row-input>
            </div>
            <div class="col-md-2 pe-0">
              <form-row-select
                [label]="'product.type'"
                [icon]="null"
                [(model)]="filters.listType"
                [options]="listTypes"
                [optionKey]="'val'"
                [dataCyAttribute]="'product-list-type-filter'"
              ></form-row-select>
            </div>
          </div>
        </div>
        <div class="custom-dropdown" [ngClass]="{ open: filtersExpanded }">
          <div class="row mr-0">
            <div class="col-md-4">
              <app-modal-category-search [isForFilterCategories]="true" [(selectedCategories)]="filters.categories">
              </app-modal-category-search>
            </div>
            <div class="col-md-2">
              <form-row-select
                [label]="'products.metrics'"
                [icon]="null"
                [(model)]="filters.metrics"
                [options]="purchaseOrdersTypes"
                [dataCyAttribute]="'metrics-filter'"
              ></form-row-select>
            </div>
            <div class="col-md-2">
              <form-row-select
                [label]="'products.hasHsCode'"
                [icon]="null"
                [(model)]="filters.hasHsCode"
                [options]="abilityTypes"
                [dataCyAttribute]="'hasHsCode-filter'"
              ></form-row-select>
            </div>
          </div>
        </div>
      </div>
    </app-filter-form>

    <div class="card mb-0 pt-3">
      <div
        class="card-body p-0"
        [ngClass]="{
          h150: (!products.length && loading) || (!loading && (!products || (products && products.length === 0)))
        }"
      >
        <loading-spinner [isLoading]="reloading" [classesString]="'pt-88'"></loading-spinner>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="padding-th-checkbox">
                  <form-row-checkbox
                    [isOnProductsPage]="true"
                    [title]="'select.all' | translate"
                    [model]="areAllProductsChecked"
                    (modelChange)="allProductsChecked($event)"
                    [dataCySelector]="'all-products-checkbox'"
                  >
                  </form-row-checkbox>
                </th>
                <th *ngIf="isColumnSelected('name')" scope="col" class="cursor-pointer" (click)="sortBy('name')">
                  {{ 'product.name' | translate }}
                  <ng-container *ngTemplateOutlet="caretIcon; context: { prop: 'name' }"></ng-container>
                </th>
                <th *ngIf="isColumnSelected('availability')" scope="col" class="text-center fixed-column-width">
                  {{ 'products.availability' | translate }}
                </th>
                <th
                  *ngIf="isColumnSelected('code')"
                  scope="col"
                  class="cursor-pointer text-nowrap d-none d-xl-table-cell"
                  (click)="sortBy('code')"
                >
                  {{ 'product.code' | translate }}
                  <ng-container *ngTemplateOutlet="caretIcon; context: { prop: 'code' }"></ng-container>
                </th>
                <th
                  *ngIf="isColumnSelected('ean')"
                  scope="col"
                  class="cursor-pointer text-nowrap d-none d-xl-table-cell"
                  (click)="sortBy('ean')"
                >
                  {{ 'product.ean' | translate }}
                  <ng-container *ngTemplateOutlet="caretIcon; context: { prop: 'ean' }"></ng-container>
                </th>
                <th
                  *ngIf="isColumnSelected('tags')"
                  scope="col"
                  class="cursor-pointer text-nowrap d-none d-xl-table-cell"
                >
                  {{ 'product.tag' | translate }}
                </th>
                <th
                  *ngIf="isColumnSelected('sellPrice')"
                  scope="col"
                  class="cursor-pointer text-nowrap text-end"
                  (click)="sortBy('sellPrice')"
                >
                  {{ 'product.price' | translate }}
                  <ng-container *ngTemplateOutlet="caretIcon; context: { prop: 'sellPrice' }"></ng-container>
                </th>
                <th
                  *ngIf="isColumnSelected('created')"
                  scope="col"
                  class="cursor-pointer text-nowrap text-end"
                  (click)="sortBy('created')"
                >
                  {{ 'products.created' | translate }}
                  <ng-container *ngTemplateOutlet="caretIcon; context: { prop: 'created' }"></ng-container>
                </th>
                <th class="fixed-column-width" scope="col">
                  <dropdown
                    class="float-right"
                    [icon]="'far-cog'"
                    [items]="listingColumns | colSettings: selectedColumns"
                    [isArrowHidden]="true"
                    [autoClose]="true"
                    (onResult)="onColumnSettingDropdownClick($event)"
                  ></dropdown>
                </th>
              </tr>
            </thead>

            <tbody *ngIf="!loading && (!products || (products && products.length === 0))">
              <tr>
                <td colspan="7" class="text-center p-5 border-bottom-0 no-hover">
                  <div class="empty-list">
                    <i class="far fa-sad-tear"></i>
                    <div>
                      {{ 'no.record.found' | translate }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <ng-template [ngIf]="products && products.length > 0" class="images" [photoGalleryGroup]="{ index: 1 }">
              <ng-container *ngFor="let product of products">
                <tbody>
                  <tr [ngClass]="{ 'disabled-product': !product.enabled }">
                    <td class="padding-td-checkbox">
                      <form-row-checkbox
                        [isOnProductsPage]="true"
                        [model]="isProductRowChecked(product.id)"
                        (modelChange)="productChecked($event, product)"
                        [cursorPointer]="true"
                        [dataCySelector]="'product-row-checkbox'"
                      >
                      </form-row-checkbox>
                    </td>
                    <td *ngIf="isColumnSelected('name')" class="ps-1">
                      <div class="flex-gap-10 align-items-center">
                        <div
                          [photoGallery]="product.mainImage.url"
                          [photoGalleryCaption]="
                            product.fullName +
                            '<br>' +
                            (product.code ? ('home.code' | translate) + product.code : '') +
                            '<br>' +
                            (product.ean ? ('home.ean' | translate) + product.ean : '')
                          "
                        >
                          <div class="images-item">
                            <img
                              class="images-item-image"
                              [src]="getThumbUrl(product.mainImage)"
                              [alt]="product.fullName"
                            />
                          </div>
                        </div>
                        <div>
                          <div>{{ product.fullName }}</div>
                          <div class="product-details flex-gap-5 flex-wrap d-xl-none">
                            <div *ngIf="product.code && product.code.length > 0">
                              {{ 'product.code' | translate }}:<span> {{ product.code }}</span>
                            </div>
                            <div *ngIf="product.ean && product.ean.length > 0">
                              {{ 'product.ean' | translate }}:<span> {{ product.ean }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td *ngIf="isColumnSelected('availability')" class="text-center">
                      <modal-stock-overview
                        *ngIf="!product.isBundle && (!product.children || !product.children.length)"
                        [isCalculatedOfProductDepots]="true"
                        [isSumCalculated]="true"
                        [product]="product"
                        [depots]="depots"
                        [suppliers]="suppliers"
                        [filterDepots]="filters.depots"
                        [showPurchaseQuantity]="true"
                      >
                      </modal-stock-overview>
                      <ng-template [ngIf]="product.children && product.children.length"> - </ng-template>
                    </td>
                    <td *ngIf="isColumnSelected('code')" class="d-none d-xl-table-cell text-nowrap">
                      {{ product.code && product.code.length > 0 ? product.code : '' }}
                    </td>
                    <td *ngIf="isColumnSelected('ean')" class="d-none d-xl-table-cell text-nowrap">
                      {{ product.ean && product.ean.length > 0 ? product.ean : '' }}
                    </td>
                    <td *ngIf="isColumnSelected('tags')" class="d-none d-xl-table-cell">
                      <div class="container-tags">
                        <span
                          class="tag-span"
                          *ngFor="let tag of product.tags"
                          [ngStyle]="{ 'background-color': tag.color ? tag.color : '#0058FF', color: 'white' }"
                        >
                          {{ tag.name }}
                        </span>
                      </div>
                    </td>
                    <td
                      *ngIf="isColumnSelected('sellPrice')"
                      [ngClass]="{
                        'text-center': product.children && product.children.length,
                        'text-end': !product.children || !product.children.length
                      }"
                    >
                      <ng-template [ngIf]="!product.children || !product.children.length">
                        <div class="d-flex flex-column align-items-end">
                          <div class="text-nowrap">
                            <span [ngClass]="product.sellPrice !== product.actualSellPrice ? 'price-cross' : ''">{{
                              product.sellPrice | formatPrice
                            }}</span>
                          </div>
                          <ng-template
                            [ngIf]="product.sellPrice !== product.actualSellPrice && product.actualSellPrice != 0"
                          >
                            <div class="flex-gap-5 price-red" *ngIf="product.sellPrice !== product.actualSellPrice">
                              <div><i class="far fa-arrow-alt-down"></i></div>
                              <div>{{ product.sellPrice | formatPrice }}</div>
                            </div>
                          </ng-template>
                        </div>
                      </ng-template>
                      <ng-template [ngIf]="product.children && product.children.length"> - </ng-template>
                    </td>
                    <td *ngIf="isColumnSelected('created')" class="d-none d-xl-table-cell">
                      {{ product.created ? (product.created | strToDate | date: 'dd.MM.yyyy HH:mm') : '' }}
                    </td>
                    <td class="text-end pe-1">
                      <div class="flex-gap-5">
                        <div class="d-flex">
                          <a
                            *ngIf="product.children && product.children.length"
                            class="btn-third btn-sm"
                            data-toggle="collapse"
                            href="#collapse_{{ product.id }}"
                            [title]="'variants' | translate"
                          >
                            <i class="far fa-arrow-down me-2"></i>
                            {{ 'variants' | translate }}
                          </a>
                        </div>
                        <div>
                          <a
                            class="btn-second"
                            [routerLink]="['/product', product.id]"
                            [title]="'open' | translate"
                            data-cy="open-row"
                          >
                            {{ 'open' | translate }}
                            <i class="far fa-chevron-right align-self-center ms-2"></i>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody
                  *ngIf="product.children && product.children.length"
                  id="collapse_{{ product.id }}"
                  class="collapse"
                >
                  <tr *ngFor="let child of product.children" [ngClass]="{ 'disabled-product': !child.enabled }">
                    <td></td>
                    <td class="ps-1">
                      <div class="flex-gap-10 align-items-center">
                        <div
                          [photoGallery]="child.mainImage.url"
                          [photoGalleryCaption]="
                            child.fullName +
                            '<br>' +
                            (child.code ? ('product.code' | translate) + child.code : '') +
                            '<br>' +
                            (child.ean ? ' EAN: ' + child.ean : '')
                          "
                        >
                          <div class="images-item">
                            <img
                              class="images-item-image"
                              [src]="getThumbUrl(child.mainImage)"
                              [alt]="child.fullName"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="align-self-center">
                            {{ child.name }}
                          </div>
                          <div class="product-details flex-gap-5 flex-wrap d-xl-none">
                            <div *ngIf="child.code && child.code.length > 0">
                              {{ 'product.code' | translate }}:<span> {{ child.code }}</span>
                            </div>
                            <div *ngIf="child.ean && child.ean.length > 0">
                              {{ 'product.ean' | translate }}:<span> {{ child.ean }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class="text-center">
                      <modal-stock-overview
                        [isCalculatedOfProductDepots]="true"
                        [isSumCalculated]="true"
                        [product]="child"
                        [depots]="depots"
                        [suppliers]="suppliers"
                        [filterDepots]="filters.depots"
                        [showPurchaseQuantity]="true"
                      >
                      </modal-stock-overview>
                    </td>
                    <td class="text-start d-none d-xl-table-cell">
                      {{ child.code }}
                    </td>
                    <td colspan="2" class="text-start d-none d-xl-table-cell">
                      {{ child.ean }}
                    </td>

                    <td class="text-end pe-0">
                      <div class="d-flex flex-column align-items-end">
                        <div class="text-nowrap">
                          <span [ngClass]="child.sellPrice !== child.sellPrice ? 'price-cross' : ''">{{
                            child.sellPrice | formatPrice
                          }}</span>
                        </div>
                        <ng-template [ngIf]="child.sellPrice !== child.actualSellPrice && child.actualSellPrice != 0">
                          <div class="flex-gap-5 price-red" *ngIf="child.sellPrice !== child.actualSellPrice">
                            <div><i class="far fa-arrow-alt-down"></i></div>
                            <div>{{ child.sellPrice | formatPrice }}</div>
                          </div>
                        </ng-template>
                      </div>
                    </td>
                    <td colspan="2" class="text-end">
                      <div>
                        <a
                          [routerLink]="['/product', child.id]"
                          class="btn-second btn-sm float-right ms-2"
                          [title]="'open' | translate"
                        >
                          {{ 'open' | translate }}
                          <i class="far fa-chevron-right ms-2"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </ng-container>
            </ng-template>
          </table>
          <app-pagination-btn
            *ngIf="(!this.currentPage || this.currentPage <= this.endPage) && this.totalItems > this.products.length"
            (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
            [currentPage]="currentPage"
            [endPage]="endPage"
            [sortDir]="sortDir"
            [sortProp]="sortProp"
            [loadingPage]="loading"
          >
          </app-pagination-btn>
        </div>
      </div>
    </div>
  </section>
</div>
<modal-product-purchase #modalproductPurchase (result)="redirect($event)" [depots]="depots"></modal-product-purchase>
