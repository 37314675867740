<modal-container
  #modal
  [headerTitle]="'product.add'"
  [headerIconClass]="'far-plus'"
  [isModalFromRight]="true"
  [isModalForProductChoose]="true"
  [isHideBtnHidden]="true"
  [hasBackdrop]="false"
  [ignoreBackdropClick]="true"
>
  <ng-container class="modal-button-show">
    <btn
      [label]="'product.add'"
      [title]="'product.add' | translate"
      [icon]="'far-plus'"
      [classes]="['btn-second', 'float-right']"
      (onEvent)="showChildModal()"
    ></btn>
  </ng-container>
  <ng-container class="modal-body-content">
    <div class="card">
      <div class="product-search-form">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <form-row-select
              [label]="'products.availability'"
              [icon]="null"
              [(model)]="selectedAvailability"
              [options]="availabilityTypes"
              (modelChange)="searchForProduct()"
            ></form-row-select>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <app-modal-category-search
              [isForFilterCategories]="true"
              [selectedCategories]="selectedCategories"
              (selectedCategoriesChange)="selectedCategories = $event; searchForProduct()"
            >
            </app-modal-category-search>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <form-row-input
              [(model)]="searchValue"
              (keyup.enter)="searchForProduct()"
              [label]="'product.search'"
              [appendBtnTitle]="'product.search' | translate"
              [appendBtnClasses]="['btn-input']"
              [appendBtnIcon]="'far-search'"
              (onAppendBtnEvent)="searchForProduct()"
              data-cy="product-search"
            >
            </form-row-input>
          </div>
        </div>
      </div>
    </div>
    <div class="card" [ngClass]="{ h150: !products.length && loading }">
      <loading-spinner
        [isLoading]="loading && ((searchValue && searchValue.length > 0) || !products.length)"
        [classesString]="!products.length && loading ? 'top-50' : 'pt-6'"
      ></loading-spinner>
      <div class="m-0" *ngIf="!products || (products && products.length === 0 && !loading)">
        {{ 'no.records' | translate }}
      </div>
      <div class="table-responsive">
        <table class="table" *ngIf="products.length > 0">
          <thead>
            <tr>
              <th class="ps-0">{{ 'product.name' | translate }}</th>
              <th class="text-nowrap text-end">{{ 'purchase.price.actual' | translate }}</th>
              <th class="text-center">{{ 'products.availability' | translate }}</th>
              <th>{{ 'product.tag' | translate }}</th>
              <th *ngIf="!hasSpecialAddOrderItemsRole">&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody *ngFor="let product of products" class="images" photoGalleryGroup>
            <tr>
              <td class="ps-1 text-start">
                <div class="flex-gap-10 align-items-center">
                  <div
                    [photoGallery]="product.mainImage.url"
                    [photoGalleryCaption]="
                      product.fullName +
                      '<br>' +
                      (product.code ? ('product.code' | translate) + product.code : '') +
                      '<br>' +
                      (product.ean ? ' EAN: ' + product.ean : '')
                    "
                  >
                    <div class="images-item">
                      <img class="images-item-image" [src]="getThumbUrl(product.mainImage)" [alt]="product.fullName" />
                    </div>
                  </div>
                  <div>
                    <a class="link-bold" [routerLink]="['/product', product.id]">{{ product.fullName }}</a>
                    <div class="product-details flex-gap-5 flex-wrap">
                      <div *ngIf="product.code && product.code.length > 0">
                        {{ 'product.code' | translate }}:<span> {{ product.code }}</span>
                      </div>
                      <div *ngIf="product.ean && product.ean.length > 0">
                        {{ 'product.ean' | translate }}:<span> {{ product.ean }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-end">
                {{ product.purchasePrice | formatPrice: product.purchaseCurrency }}
              </td>
              <td class="text-nowrap text-center">
                <modal-stock-overview
                  [product]="product"
                  [checkout]="checkout"
                  [isFullWidth]="true"
                  [isSumCalculated]="false"
                  [isSpecialAddOrderItemsInstance]="hasSpecialAddOrderItemsRole"
                  (onSelectedProductDepot)="chosenProductDepot($event)"
                  [customer]="customer"
                ></modal-stock-overview>
              </td>
              <td class="text-end">
                <div class="container-tags">
                  <ng-container *ngFor="let tag of product['tags']">
                    <span
                      class="tag-span"
                      [ngStyle]="{ 'background-color': tag.color ? tag.color : '#0058FF', color: 'white' }"
                    >
                      {{ tag.name }}
                    </span>
                  </ng-container>
                </div>
              </td>
              <td class="pe-1">
                <btn
                  [label]="'add'"
                  [title]="'add' | translate"
                  [icon]="'far-plus'"
                  [classes]="['btn-second', 'float-right']"
                  (onEvent)="showModalForProductSelection(product)"
                  data-cy="add"
                ></btn>
              </td>
            </tr>
            <tr *ngIf="product.packs?.length > 0">
              <td colspan="5" class="no-hover p-0">
                <div class="accordion" [ngClass]="{ open: product['isAccordionOpen'] }" *ngFor="let p of product.packs">
                  <table class="accordion-table">
                    <thead style="visibility: collapse">
                      <tr>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <div class="d-flex justify-content-end">
                            <div>
                              <div>{{ 'Balení ' + p.quantity + ('piece' | translate) }}</div>
                              <div class="product-details flex-gap-5 flex-wrap">
                                <div *ngIf="p.code && p.code.length > 0">
                                  {{ 'product.code' | translate }}:<span> {{ p.code }}</span>
                                </div>
                                <div *ngIf="p.ean && p.ean.length > 0">
                                  {{ 'product.ean' | translate }}:<span> {{ p.ean }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="text-nowrap text-center" style="width: 141px">
                          <div>
                            <form-row-number-with-controls
                              [label]="null"
                              [model]="p['quantityTmp'] || 0"
                              (modelChange)="updateQuantityFromPacks(product, p, $event)"
                              [maxWidthPx]="82"
                              class="d-flex justify-content-center"
                              style="width: 105px"
                              [noMarginBottom]="true"
                            >
                            </form-row-number-with-controls>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr *ngIf="pageNumber < maxPageNumber" id="next-page-btn">
              <td class="p-0 border-0 text-center no-hover" colspan="5" *ngIf="hasSpecialAddOrderItemsRole">
                <btn
                  [label]="'load.next'"
                  [title]="'load.next' | translate"
                  [classes]="['btn', 'btn-third', 'mt-2', 'w-50']"
                  (onEvent)="searchForProduct(1)"
                  [btnDisabled]="loading"
                ></btn>
              </td>
              <td class="p-0 border-0 text-center no-hover" colspan="5" *ngIf="!hasSpecialAddOrderItemsRole">
                <btn
                  [label]="'load.next'"
                  [title]="'load.next' | translate"
                  [classes]="['btn', 'btn-third', 'mt-2', 'w-50']"
                  (onEvent)="searchForProduct(1)"
                  [btnDisabled]="loading"
                ></btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</modal-container>
<modal-stock-operation-card-purchase (result)="chooseProduct($event)"></modal-stock-operation-card-purchase>
