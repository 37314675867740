<div #formRow class="form-group d-flex flex-column" [ngClass]="{ 'mb-0': noMarginBottom }">
  <label *ngIf="label && label.length">
    {{ label | translate }} &nbsp;
    <info-tooltip
      *ngIf="tooltipLabel && tooltipLabel.length > 0"
      [label]="tooltipLabel"
      [icon]="tooltipIcon"
    ></info-tooltip>
  </label>
  <ng-select
    *ngIf="!options || (options && !options.length)"
    [multiple]="isMultiple"
    [id]="elementId"
    [attr.data-cy]="dataCyAttribute"
    disabled="true"
  ></ng-select>
  <ng-select
    class="ng-select-tags"
    *ngIf="options && options.length && areOptionsLoaded && isTags"
    [multiple]="isMultiple"
    [id]="elementId"
    [items]="options"
    [(ngModel)]="selectedValues"
    [bindLabel]="optionKeyText"
    [bindValue]="optionKey"
    [attr.data-track-category]="trackCategory"
    [attr.data-track-action]="trackAction"
    [attr.data-cy]="dataCyAttribute"
    (change)="onChange()"
    (add)="onAdd()"
    (remove)="onRemove()"
    [trackByFn]="trackById"
    [compareWith]="compareWith"
    [closeOnSelect]="false"
  >
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <div [style.background-color]="item.color ? item.color : '#0058FF'" class="ng-value-label-tags">
        <span>{{ item.name }}</span>
        <span
          class="ng-value-icon right"
          [style.background-color]="item.color ? item.color : '#0058FF'"
          (click)="clear(item)"
          data-cy="tags-clear-btn"
          aria-hidden="true"
          >×</span
        >
      </div>
    </ng-template>
  </ng-select>
  <ng-select
    *ngIf="options && options.length && areOptionsLoaded && !isTags"
    [multiple]="isMultiple"
    [id]="elementId"
    [items]="options"
    [(ngModel)]="selectedValues"
    [bindLabel]="optionKeyText"
    [bindValue]="optionKey"
    [attr.data-track-category]="trackCategory"
    [attr.data-track-action]="trackAction"
    [attr.data-cy]="dataCyAttribute"
    (change)="onChange()"
    (add)="onAdd()"
    (remove)="onRemove()"
    [trackByFn]="trackById"
    [compareWith]="compareWith"
    [closeOnSelect]="false"
  >
  </ng-select>
</div>
