import { Component, ChangeDetectorRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { BaseListingComponent } from '../../components/_common/base-listing/base-listing.component'
import { CoreService } from '../../services'
import { Checkout, Depot, PurchaseOrder, Supplier, Tag } from 'depoto-core/src/entities'
import { purchaseOrdersColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  status: '',
  depot: undefined,
  payments: [],
  dateCreatedFrom: '',
  dateCreatedTo: '',
  supplier: undefined,
  dateEstimatedArrivalFrom: '',
  dateEstimatedArrivalTo: '',
  fulltext: '',
  tags: [],
}

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.scss'],
})
export class PurchaseOrdersComponent extends BaseListing implements OnInit {
  // , Ondestroy
  @ViewChild('baseListingComponent', { static: true }) baseListingComponent: BaseListingComponent
  listingColumns: ListingItem[] = purchaseOrdersColumns
  purchaseOrders: PurchaseOrder[] = []
  depots: (Depot | 'all')[] = []
  checkouts: Checkout[] = []
  tags: Tag[] = []
  totalItems = 0
  currentPage: number
  endPage: number
  filters = structuredClone(DEFAULT_FILTERS)
  sortProp = 'created'
  sortDir = 'desc'
  suppliers: (Supplier | 'all')[] = []
  statusOptions = ['all', 'new', 'partly_stocked', 'stocked']
  filtersExpanded = false
  canCreate = false
  loading = true
  // subscription
  queryFilters
  storageKey: { type: string; key: string }
  userHavePermissionForThisPage = false

  constructor(
    protected core: CoreService,
    protected changeDetector: ChangeDetectorRef
  ) {
    super(core)
    this.getStorageKey()
    this.userHavePermissionForThisPage = this.core.services.user.user.company.moduleRelations?.some(
      moduleRelation => moduleRelation.module.id === 'purchase-orders'
    )
  }

  async ngOnInit() {
    if (!this.userHavePermissionForThisPage) {
      this.loading = false
    } else {
      this.filters = this.loadViewSettings('purchaseOrders') || structuredClone(DEFAULT_FILTERS)
      await this.refreshFilterData()
      this.canCreate =
        this.core.services.user.hasRole('ROLE_ORDER_CREATE') ||
        this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
        this.core.services.user.hasRole('ROLE_ADMIN')

      this.loadSorting('purchaseOrders')
      this.filter()
    }
  }

  /* async ngOnDestroy() {
    this.subscription?.unsubscribe()
  }*/

  async refreshFilterData() {
    try {
      this.loading = true
      this.suppliers = ['all', ...this.core.lists.suppliers]
      this.depots = ['all', ...this.core.lists.depots]
      this.tags = this.core.lists.tags.filter(tag => tag.type === 'purchase_order')
    } finally {
      this.loading = false
    }
  }

  getQueryFilters() {
    this.queryFilters = {
      depot: this.filters.depot || undefined,
      supplier: this.filters.supplier || undefined,
      fulltext: this.filters.fulltext?.length ? this.filters.fulltext.trim() : undefined,
      status: this.filters.status?.length ? this.filters.status.trim() : undefined,

      tags: this.filters.tags?.length ? this.filters?.tags : undefined,
    }
    Object.keys(this.queryFilters).forEach(k => {
      if (this.queryFilters[k] === undefined) {
        delete this.queryFilters[k]
      }
    })
    if (this.filters.dateCreatedFrom?.length > 0 || this.filters.dateCreatedTo?.length > 0) {
      this.queryFilters.created = {
        ...(this.filters.dateCreatedFrom?.length && {
          left_date: this.filters.dateCreatedFrom,
        }),
        ...(this.filters.dateCreatedTo?.length && {
          right_date: this.filters.dateCreatedTo + 'T23:59:59',
        }),
      }
    }

    if (this.filters.dateEstimatedArrivalFrom?.length > 0 || this.filters.dateEstimatedArrivalTo?.length > 0) {
      this.queryFilters.dateEstimatedArrival = {
        ...(this.filters.dateEstimatedArrivalFrom?.length && {
          left_date: this.filters.dateEstimatedArrivalFrom,
        }),
        ...(this.filters.dateEstimatedArrivalTo?.length && {
          right_date: this.filters.dateEstimatedArrivalTo + 'T23:59:59',
        }),
      }
    }
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.getQueryFilters()
    this.loading = true
    this.currentPage = pageNo
    if (!concat) {
      this.reloading = true
    }

    try {
      const result = await this.core.services.purchase.getList(
        {
          page: pageNo,
          sort: sortProp,
          direction: sortDir,
          filters: {
            ...this.queryFilters,
          },
        },
        {
          id: null,
          number: null,
          status: {
            id: null,
            name: null,
          },
          createdBy: {
            name: null,
          },
          tags: {
            name: null,
          },
          depot: {
            name: null,
          },
          supplier: {
            name: null,
          },
          created: null,
          externalReference: null,
          purchasePriceAll: null,
          dateEstimatedArrival: null,
          supplierReference: null,
        }
      )
      if (!result?.items) {
        return
      }
      if (!concat) {
        this.purchaseOrders = result.items
      } else {
        result.items.forEach(prod => {
          this.purchaseOrders.push(new PurchaseOrder(prod))
        })
      }
      this.totalItems = result.paginator?.totalCount ? result.paginator?.totalCount : 0
      this.endPage = result.paginator?.endPage ? result.paginator?.endPage : 0
      this.currentPage++
      this.saveViewSettings('purchaseOrders', this.filters)
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }

  public clearFilters() {
    this.filters = structuredClone(DEFAULT_FILTERS)
    this.filter(1)
  }
}
