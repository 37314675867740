import { Pipe, PipeTransform } from '@angular/core'
import { DatePipe } from '@angular/common'

@Pipe({ name: 'fmtDate' })
export class FmtDatePipe implements PipeTransform {
  transform(value: string, args: string): string {
    let format = 'dd.MM.yyyy HH:mm'
    if (args && args.length > 0) {
      format = args
    }
    try {
      if (!value) {
        return ''
      } else {
        const result = new Date(value)
        if (!this.isValidDate(result)) {
          return 'INVALID'
        }
        return new DatePipe('cs').transform(result, format)
      }
    } catch (e) {
      return 'ERROR'
    }
  }

  isValidDate(date) {
    return date instanceof Date && !isNaN(date.getTime())
  }
}
