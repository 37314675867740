<div class="card">
  <div class="row">
    <loading-spinner [isLoading]="loading" [classesString]="'top-25'"></loading-spinner>
    <div class="col-12 col-md-6">
      <category-tree
        [categoryTreeLabel]="'product.tab.categories.mainCategory'"
        [type]="categoryTreeTypeEnum.radioProductMainCategory"
        [categories]="categories"
        [(mainCategory)]="productDetailService.product.mainCategory"
      ></category-tree>
    </div>
    <div class="col-12 col-md-6">
      <category-tree
        [categoryTreeLabel]="'product.tab.categories.categories'"
        [type]="categoryTreeTypeEnum.checkboxProductCategories"
        [categories]="categories"
        [(selectedCategories)]="productDetailService.product.categories"
      ></category-tree>
    </div>
    <div class="col-12" *ngIf="!productDetailService.isCreating && !loading && (!categories || (categories && !categories.length))">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <p class="m-0">
            {{'product-categories.no-category.1' | translate}}
            <a [routerLink]="['/categories']">{{'product-categories.no-category.2' | translate}}</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
