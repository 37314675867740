import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { Address } from 'depoto-core/src/entities'
import { CoreService } from '../../../services'
import { OrderDetailService } from '../../../services/order-detail.service'
import { SchemaUtil } from '../../../utils'

@Component({
  selector: 'order-address-page',
  templateUrl: './order-address-page.component.html',
  styleUrls: ['./order-address-page.component.scss'],
})
export class OrderAddressPageComponent implements OnChanges, OnInit {
  shippingAddresses: Address[] = []
  invoiceAddresses: Address[] = []

  constructor(
    private core: CoreService,
    public orderDetailService: OrderDetailService
  ) {}

  async ngOnInit() {
    this.orderDetailService.loading = true
    if (this.orderDetailService.order.customer) {
      this.shippingAddresses = this.orderDetailService.order.customer.addresses.filter(
        address => address.isBilling === false
      )
      this.invoiceAddresses = this.orderDetailService.order.customer.addresses.filter(
        address => address.isBilling === true
      )
    }
    this.orderDetailService.loading = false
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (
      (this.order.customer.addresses === null || this.order.customer.addresses === undefined) &&
      this.orderDetailService.order.customer
    ) {
      this.orderDetailService.order.customer.addresses = await this.core.services.address.getList(
        {
          filters: { customer: this.orderDetailService.order.customer.id },
        },
        SchemaUtil.address
      )
    }
    if (this.order.customer.addresses?.length) {
      this.shippingAddresses = this.orderDetailService.order.customer.addresses.filter(a => a.isStored && !a.isBilling)
      this.invoiceAddresses = this.orderDetailService.order.customer.addresses.filter(a => a.isStored && a.isBilling)
    }
  }

  public selectAddress(address: Address, type: 'invoice' | 'shipping'): void {
    if (type === 'invoice') {
      this.orderDetailService.order.invoiceAddress = address
      if (this.orderDetailService.isShippingAddressSameAsInvoice) {
        this.orderDetailService.order.shippingAddress = this.orderDetailService.order.invoiceAddress
      }
    } else if (type === 'shipping') {
      this.orderDetailService.order.shippingAddress = address
    }
  }

  public invoiceShippingRelationChange(value: boolean): void {
    this.orderDetailService.isShippingAddressSameAsInvoice = value
    if (this.orderDetailService.isShippingAddressSameAsInvoice) {
      this.orderDetailService.order.shippingAddress = this.orderDetailService.order.invoiceAddress
    } else {
      this.orderDetailService.order.shippingAddress = new Address()
    }
  }

  get order() {
    return this.orderDetailService.order
  }
}
