import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core'
import { ModalDirective } from 'ngx-bootstrap/modal'

@Component({
  selector: 'modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
})
export class ModalContainerComponent {
  @ViewChild('childModal') public childModal: ModalDirective
  @Output() hide: EventEmitter<any> = new EventEmitter()

  @Input() public headerIconClass: string
  @Input() public headerTitle: string
  @Input() public headerTitleAfterTranslatedTitle: string
  @Input() public modalHeaderBgColorClass: string
  @Input() public modalBodyBgColorClass: string
  @Input() public modalFooterBgColorClass: string
  @Input() public translateTitle = true
  @Input() public hasBackdrop = true
  @Input() public ignoreBackdropClick = false
  @Input() public noPaddingModalBody = false
  @Input() public isSmallModal = false
  @Input() public isLargeModal = false
  @Input() public isExtraLargeModal = false
  @Input() public isModalFromRight = false
  @Input() public isModalFromLeft = false
  @Input() public isFullWidth = false
  @Input() public isModalInModal = false
  @Input() public isLoading = false
  @Input() public isHideBtnHidden = false
  @Input() public isModalForProductChoose = false

  public showChildModal(): void {
    this.childModal.show()
  }

  public hideChildModal(): void {
    this.hide.emit(null)
    this.childModal.hide()
  }

  public isShown(): boolean {
    return this.childModal.isShown
  }
}
