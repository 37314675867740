<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="parameterForm" (ngSubmit)="isCreating ? createParameter() : updateParameter()" *ngIf="parameter">
    <page-header
      [icon]="'fa-credit-card'"
      [title]="isCreating ? 'parameter.new' : 'parameter'"
      [appendNoTranslationTitlePart]="parameter ? parameter.name : ''"
    >
      <ng-container class="header-button">
        <btn
          [label]="'save'"
          [title]="'save' | translate"
          [classes]="['btn-first', 'float-right', isCreating ? '' : 'ms-2']"
          [btnDisabled]="loading"
          [icon]="'far-check'"
          [btnType]="'submit'"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-white'"
          [dataCyAttribute]="'create-parameter'"
        ></btn>
        <btn
          *ngIf="!isCreating"
          [label]="'delete'"
          [title]="'delete' | translate"
          [classes]="['btn-first-red', 'float-right']"
          [icon]="'far-trash-alt'"
          (onEvent)="deleteParameter()"
          [dataCyAttribute]="'delete-parameter'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'fa-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <div class="row">
      <div class="col-6">
        <div class="card">
          <div class="row">
            <div class="col-12">
              <form-row-input
                [formGroup]="parameterForm"
                formControlName="name"
                [required]="parameterForm.get('name').errors !== null && parameterForm.get('name').errors.required"
                [requiredCssClass]="submitted && formControls.name.errors"
                [label]="'name'"
                [dataCyAttribute]="'name'"
              ></form-row-input>
              <form-row-select
                [label]="'type'"
                [options]="parameterTypes"
                [formGroup]="parameterForm"
                formControlName="type"
                [required]="parameterForm.get('type').errors !== null && parameterForm.get('type').errors.required"
                [requiredCssClass]="submitted && formControls.type.errors"
                *ngIf="parameter && parameterTypes.length > 0"
                [dataCyAttribute]="'parameter-type-select'"
              ></form-row-select>
              <form-row-textarea
                [label]="'text'"
                [(model)]="parameter.text"
                [dataCyAttribute]="'text'"
              ></form-row-textarea>
              <form-row-input
                [label]="'unit'"
                [formGroup]="parameterForm"
                formControlName="unit"
                [dataCyAttribute]="'unit'"
              ></form-row-input>
              <form-row-input
                [label]="'externalId'"
                [formGroup]="parameterForm"
                formControlName="externalId"
                [dataCyAttribute]="'external-id'"
              ></form-row-input>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6" *ngIf="parameterForm.value.type === 'enum'">
        <div class="card">
          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-column">
                <form-row-textarea [label]="'enumValue'" [(model)]="enumValuesString" [rows]="6"></form-row-textarea>
                <div class="product-details">{{ 'parameter.only-line-option' | translate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6" *ngIf="parameterForm.value.type === 'enum_multi'">
        <div class="card">
          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-column">
                <form-row-textarea
                  [label]="'enumValue'"
                  [(model)]="enumValuesString"
                  [rows]="6"
                  [dataCyAttribute]="'enumValuesString'"
                ></form-row-textarea>
                <div class="product-details">{{ 'parameter.only-line-option' | translate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
