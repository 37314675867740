import { Component } from '@angular/core'
import { ProductPack } from 'depoto-core/src/entities'
import { ProductDetailService } from '../../../product-detail.service'
import { Router } from '@angular/router'

@Component({
  selector: 'product-packs',
  templateUrl: './product-packs.component.html',
})
export class ProductPacksComponent {
  newPack: ProductPack = new ProductPack()
  quantity = 0
  ean: string

  constructor(
    public productDetailService: ProductDetailService,
    public router: Router
  ) {}

  printPackQR(pack: ProductPack) {
    window['dataQR'] = {
      title: `${pack.quantity}x ${this.productDetailService.product.fullName}`,
      ean: pack.ean || '',
      batch: '',
      expirationDate: '',
    }
    this.router.navigate(['/product', this.productDetailService.product.id, 'properPrint'])
  }
}
