<div
  class="payment-fixed-alert-stripe"
  *ngIf="!isShutdown && isOverdue && !isStripeHidden"
  [style.background-color]="level > 1 ? 'red' : '#e08e0b'"
>
  <div
    class="d-flex justify-content-center payment-fixed-alert-stripe-content"
    [style.color]="level > 1 ? '#fff' : '#000'"
  >
    {{ 'overdue.payment.title1' | translate }} &nbsp;
    <span *ngIf="level > 1"> {{ 'modal-overdue-payments.shutdown' | translate: { shutdownCounterString } }}</span>
    <btn
      class="margin-auto"
      [label]="'more'"
      [title]="'more' | translate"
      [classes]="['btn-third', 'float-right', 'me-2']"
      (onEvent)="showChildModal()"
    ></btn>
  </div>
</div>
<div class="payment-fixed-alert-full" *ngIf="isShutdown">
  <div class="payment-fixed-alert-full-content">
    <h1 class="text-center mb-2">{{ 'modal-overdue-payments.access-blocked' | translate }}</h1>
    <div>
      <p>
        {{
          (invoices.length > 1 ? 'modal-overdue-payments.unpaid-invoices' : 'modal-overdue-payments.unpaid-invoice')
            | translate
        }}
      </p>
      <table class="table" *ngIf="invoices && invoices.length > 0">
        <thead>
          <tr>
            <th>{{ 'supplier' | translate }}</th>
            <th class="text-end">{{ 'modal-overdue-payments.vs' | translate }}</th>
            <th class="text-end">{{ 'modal-overdue-payments.amount' | translate }}</th>
            <th class="text-end">{{ 'modal-overdue-payments.due-date' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let invoice of invoices">
            <td>{{ invoice.contractor }}</td>
            <td class="text-end">{{ invoice.variable }}</td>
            <td class="text-end">{{ invoice.price | number: '1.2-2' | replaceCommaToDot }} Kč</td>
            <td class="text-end">{{ invoice.due_date | fmtDate: 'dd.MM.yyyy' }}</td>
          </tr>
        </tbody>
      </table>
      <h1 class="text-center mt-2">{{ 'modal-overdue-payments.message.1' | translate }}</h1>
      <p>{{ 'modal-overdue-payments.message.2' | translate }}</p>
      <p>{{ 'modal-overdue-payments.message.3' | translate }}</p>
      <p>{{ 'modal-overdue-payments.message.4' | translate }} <a href="mailto:info@depoto.cz">info&#64;depoto.cz</a></p>
    </div>
  </div>
</div>

<modal-container
  #modal
  [headerTitle]="'modal-overdue-payments.overdue-invoice'"
  [headerIconClass]="'fa-exclamation-triangle'"
  [hasBackdrop]="true"
  [ignoreBackdropClick]="true"
>
  <ng-container class="modal-body-content">
    <p>
      {{
        (invoices.length > 1 ? 'modal-overdue-payments.unpaid-invoices' : 'modal-overdue-payments.unpaid-invoice')
          | translate
      }}
    </p>
    <table class="table" *ngIf="invoices && invoices.length > 0">
      <thead>
        <tr>
          <th scope="col">{{ 'supplier' | translate }}</th>
          <th scope="col" class="text-end">{{ 'modal-overdue-payments.vs' | translate }}</th>
          <th scope="col" class="text-end">{{ 'modal-overdue-payments.amount' | translate }}</th>
          <th scope="col" class="text-end">{{ 'modal-overdue-payments.due-date' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let invoice of invoices">
          <td>{{ invoice.contractor }}</td>
          <td class="text-end">{{ invoice.variable }}</td>
          <td class="text-end">{{ invoice.price | number: '1.2-2' | replaceCommaToDot }} Kč</td>
          <td class="text-end">{{ invoice.due_date | fmtDate: 'dd.MM.yyyy' }}</td>
        </tr>
      </tbody>
    </table>
    <p>{{ 'modal-overdue-payments.message.2' | translate }}</p>
    <p>{{ 'modal-overdue-payments.message.3' | translate }}</p>
    <p>{{ 'modal-overdue-payments.message.4' | translate }} <a href="mailto:info@depoto.cz">info&#64;depoto.cz</a></p>
  </ng-container>
</modal-container>
