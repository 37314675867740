import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import * as Sentry from '@sentry/angular'
// @ts-ignore
import packageJson from '../package.json'

declare global {
  interface Window {
    _depoto: any
    getAppVersion: () => string[]
    CYPRESS?: {
      mutation?: any
      response?: any
      errors?: any
    }
  }
}

Sentry.init({
  dsn: environment.sentryDsn,
  environment: environment.sentryEnv,
  enabled: environment.sentryEnv === 'PROD',
  release: `depoto-client@${packageJson.version}#${packageJson.lastCommit}`,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracePropagationTargets: [environment.urlDeployment],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))
