import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { File, Product } from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../modal-container/modal-container.component'

@Component({
  selector: 'product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss'],
})
export class ProductImageComponent implements OnInit {
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Input() product: Product
  @Input() image: File
  // thumbnails: FileThumbnail[] = [];
  thumbnailUrl: string
  imageUrl: string
  label: string
  isImageLoaded = false

  ngOnInit() {
    try {
      if (this.image && this.image.id > 0) {
        this.thumbnailUrl = this.image.thumbnails.filter(t => t.format === 'w135wp')[0].url
        this.imageUrl = this.image.thumbnails.filter(t => t.format === 'w2048wp')[0].url
        this.label = this.image.text
      } else if (
        this.product &&
        this.product.id > 0 &&
        this.product.mainImage.thumbnails &&
        this.product.mainImage.thumbnails.length
      ) {
        this.thumbnailUrl = this.product.mainImage
          ? this.product.mainImage.thumbnails.filter(t => t.format === 'w135wp')[0].url
          : null
        this.imageUrl = this.product.mainImage
          ? this.product.mainImage.thumbnails.filter(t => t.format === 'w2048wp')[0].url
          : null
        this.label = this.product.fullName
      }
    } catch (e) {
      console.warn(e)
    }
  }

  show(): void {
    this.isImageLoaded = true
    setTimeout(() => this.childModal.showChildModal(), 250)
  }

  hide(): void {
    this.childModal.hideChildModal()
    this.isImageLoaded = false
  }

  get restOfProductImages(): File[] {
    return this.product.images.slice(1)
  }
}
