import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core'
import { NotificationService } from '../../../services'
import { Depot, Vat, Order, OrderItem, Product, File, ClearanceItem } from 'depoto-core/src/entities'
import { CoreService } from '../../../services'
import { ProductPriceLevelUtil } from 'depoto-core/src/utils/ProductPriceLevelUtil'
import { SchemaUtil, sortOrderItems } from '../../../utils'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-order-products',
  templateUrl: './order-products.component.html',
  styleUrls: ['order-products.component.scss'],
})
export class OrderProductsComponent implements OnInit, OnChanges {
  @Input() isCreating = false
  @Input() canCreateOrEdit = false
  @Input() canEditPrice = false
  @Input() order: Order
  @Output() orderChange: EventEmitter<any> = new EventEmitter()
  @Output() orderReload: EventEmitter<any> = new EventEmitter()
  @Output() fetchClearanceItems: EventEmitter<any> = new EventEmitter()
  @Output() refreshPaymentAmount: EventEmitter<any> = new EventEmitter()
  @Input() vats: Vat[] = []
  @Input() depots: Depot[] = []
  @Input() items: any[] = []
  isShowingClearanceItems = false
  clearanceItemsWereFetched = false
  quantityItemsSum = 0
  quantityAmountSum = 0
  clearanceItems: ClearanceItem[] = []
  areClearanceItemsInSync = true
  checkedItems = []
  areAllItemsChecked = false
  loading = false

  selectedOrder: Order
  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private core: CoreService
  ) {}

  ngOnInit() {
    if (this.isShowingClearanceItems) this.prepareClearanceItems()
    this.setDefaultValues()
  }

  ngOnChanges(changes: SimpleChanges) {
    const areCIUpdated =
      JSON.stringify(changes?.order?.currentValue?.clearanceItems || []) !==
      JSON.stringify(changes?.order?.previousValue?.clearanceItems || [])
    if (areCIUpdated && !this.areClearanceItemsInSync) {
      this.areClearanceItemsInSync = true
      this.prepareClearanceItems()
    }
  }

  showClearanceItems() {
    this.isShowingClearanceItems = true
    if (!this.clearanceItemsWereFetched) {
      this.prepareClearanceItems()
    }
  }

  async prepareClearanceItems(order = this.order) {
    this.loading = true
    try {
      this.clearanceItems = await this.core.services.order
        .getById(order.id, SchemaUtil.orderClearanceItems)
        .then(result => result.clearanceItems)
      this.clearanceItemsWereFetched = true
      this.quantityItemsSum = order.items.map(i => i.quantity).reduce((a, b) => Number(a) + Number(b))
      this.quantityAmountSum = this.clearanceItems.map(i => i.amount).reduce((a, b) => Number(a) + Number(b))
    } catch (e) {
      this.clearanceItems = this.clearanceItems || []
      this.quantityItemsSum = this.quantityItemsSum ?? 0
      this.quantityAmountSum = this.quantityAmountSum ?? 0
    } finally {
      this.loading = false
    }
  }

  hasExpirationsOnUpdate(oItem: OrderItem): boolean {
    return oItem.moves.filter(m => m.productDepotFrom && !!m.productDepotFrom.expirationDate).length > 0
  }

  hasBatchesOnUpdate(oItem: OrderItem): boolean {
    return oItem.moves.filter(m => m.productDepotFrom && !!m.productDepotFrom.batch).length > 0
  }

  sortOrderItems(order = this.order) {
    order.items = sortOrderItems([...order.items])
  }

  isItemRowChecked(id) {
    if (this.areAllItemsChecked) return true
    else {
      return this.checkedItems.find(checkedItem => checkedItem.id === id) !== undefined
    }
  }

  setDefaultValues() {
    this.checkedItems = []
    this.areAllItemsChecked = false
  }

  allItemsChecked(event: boolean) {
    if (event) {
      this.areAllItemsChecked = true
      this.checkedItems = this.order.items
    } else {
      this.areAllItemsChecked = false
      this.checkedItems = []
    }
  }

  itemChecked(value, item) {
    if (value) {
      this.checkedItems.push(item)
    } else {
      this.areAllItemsChecked = false
      this.checkedItems = this.checkedItems.filter(checkedItem => checkedItem.id != item.id)
    }
  }

  orderReloadEmit() {
    this.orderReload.emit()
  }

  addProductToOrder(product: Product) {
    const newOrderItem = new OrderItem({ ...product, ...{ id: null } })
    newOrderItem.quantity = product.orderQuantity
    newOrderItem.price = Number(
      ProductPriceLevelUtil.getProductPriceForCustomerPriceLevel(product, this.order.customer)
    )

    if (!this.order.currency?.id || (this.order.currency && this.order.currency.id !== 'CZK')) {
      newOrderItem.price = Number(this.recalculatePrice(newOrderItem.price, this.order.currency))
    }
    newOrderItem.priceAll = newOrderItem.price * product.orderQuantity
    newOrderItem.product = product
    newOrderItem.name = product.fullName ? product.fullName : product.name
    newOrderItem.sale = 0
    for (const [index, orderItem] of this.order.items.entries()) {
      if (orderItem.product && orderItem.product.id === product.id) {
        this.order.items[index] = newOrderItem
        if (this.order.id === this.order.id) {
          this.orderChange.emit(this.order)
          if (Number(newOrderItem.price) > 0) this.refreshPaymentAmount.emit()
        }
        return
      }
    }
    this.order.items.push(newOrderItem)
    this.sortOrderItems(this.order)
    if (this.order.id === this.order.id) {
      this.orderChange.emit(this.order)
      if (Number(newOrderItem.price) > 0) this.refreshPaymentAmount.emit()
    }
    setTimeout(() => {
      if (this.isShowingClearanceItems) {
        this.prepareClearanceItems()
      } else {
        this.clearanceItemsWereFetched = false
      }
    }, 500)
    if (this.order.id === this.order.id) this.areClearanceItemsInSync = false
    else this.selectedOrder = this.order
  }

  recalculatePrice(price, newCurrency) {
    console.log('recalculatePrice()')
    console.log(price)
    console.log(newCurrency)
    return Number((price * newCurrency.ratio).toFixed(2))
  }

  addOrderItemToOrderFromMoving(event) {
    this.addOrderItemToOrder(event.item, event.order)
  }

  public addOrderItemToOrder(orderItem: OrderItem, order = this.order) {
    if (order.id === this.order.id) {
      for (const [index, existingOrderItem] of order.items.entries()) {
        if (
          existingOrderItem.product &&
          existingOrderItem.product.id === orderItem.product.id &&
          existingOrderItem.price === orderItem.price &&
          existingOrderItem.expirationDate === orderItem.expirationDate &&
          existingOrderItem.batch === orderItem.batch
        ) {
          order.items[index] = orderItem
          if (order.id === this.order.id) {
            this.orderChange.emit(order)
            this.refreshPaymentAmount.emit()
          }
          return
        }
      }
    }
    order.items.push(orderItem)
    this.sortOrderItems(order)
    if (order.id === this.order.id) {
      this.orderChange.emit(order)
      if (Number(orderItem.price) > 0) this.refreshPaymentAmount.emit()
    }
    setTimeout(() => {
      if (this.isShowingClearanceItems) {
        this.prepareClearanceItems(order)
      } else {
        this.clearanceItemsWereFetched = false
      }
    }, 500)
    if (order.id === this.order.id) this.areClearanceItemsInSync = false
  }

  createOrderItemFromMoving(event) {
    this.createOrderItem(event.item, event.selectedOrder)
  }

  createOrderItem(orderItem: OrderItem, order: Order = this.order) {
    if (!orderItem) {
      alert(this.translateService.instant('order-products.null-item.error'))
      return
    }
    try {
      orderItem.price = orderItem.price ? Number(orderItem.price) : 0
      orderItem.sale = orderItem.sale ? Number(orderItem.sale) : 0
      orderItem.priceAll = orderItem.price * Number(orderItem.quantity)
      order.items.push(orderItem)
      this.sortOrderItems()
      if (order.id === this.order.id) {
        this.orderChange.emit(order)
        if (Number(orderItem.price) > 0) this.refreshPaymentAmount.emit()
      }
      setTimeout(() => {
        if (this.isShowingClearanceItems) {
          this.prepareClearanceItems(order)
        } else {
          this.clearanceItemsWereFetched = false
        }
      }, 500)
      // if (order.id === this.order.id)
      // this.notificationService.success(this.translateService.instant('ALERT ITEM ADDED'))
    } catch (err) {
      console.error(err)
    }
  }

  updateOrderItem(orderItem: OrderItem) {
    if (!orderItem) {
      alert(this.translateService.instant('order-products.null-item.error'))
      return
    }

    for (const [index, oi] of this.order.items.entries()) {
      if (oi.uuid === orderItem.uuid) {
        oi.price = Number(orderItem.price)
        oi.priceAll = Number(orderItem.priceAll)
        this.order.items[index] = orderItem
        this.orderChange.emit(this.order)
        this.refreshPaymentAmount.emit()
      }
    }
    this.orderChange.emit(this.order)
    this.refreshPaymentAmount.emit()
    if (orderItem?.product) {
      this.areClearanceItemsInSync = false
    }
  }

  removeOrderItemFromMoving(event) {
    this.removeOrderItem(event.movedItem, event.isFromMoving)
  }

  async removeOrderItem(oItem: OrderItem, isFromMoving = false) {
    if (!oItem) {
      alert(this.translateService.instant('order-products.null-item.error'))
      return
    }
    if (!isFromMoving) {
      if (!confirm(this.translateService.instant('order-products.delete-item-confirm', { name: oItem.name }))) {
        return
      }
    }
    this.order.items = this.order.items.filter(oi => oi.uuid !== oItem.uuid)
    if (!oItem.id) {
      if (!isFromMoving) {
        // this.notificationService.success(this.translateService.instant('order-products.delete-item-success'))
      }
      this.orderChange.emit(this.order)
      this.refreshPaymentAmount.emit()
      return
    }

    await this.core.services.order.deleteOrderItem(oItem)
    this.orderChange.emit(this.order)
    this.refreshPaymentAmount.emit()
    this.areClearanceItemsInSync = false
  }

  orderItemsPriceSumHack(): string {
    // pipe wont refresh here?
    if (!this.order || !this.order.items || (this.order.items && this.order.items.length < 1)) {
      return '0.00'
    }
    const sum = this.order.items
      .map(item => {
        return item.priceAll
      })
      .reduce((a, b) => {
        return Number(a) + Number(b)
      })
      .toFixed(2)
    return Number(sum)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  getQuantityItemsSum() {
    return this.order?.items?.map(i => i.quantity)?.reduce((a, b) => Number(a) + Number(b), 0) ?? 0
  }

  async onClearanceItemsUpdate() {
    await this.core.services.order
      .getById(this.order.id, {
        id: null,
        clearanceItems: SchemaUtil.clearanceItem,
      })
      .then(result => {
        this.order.clearanceItems = result.clearanceItems
        this.orderChange.emit(this.order)
        this.prepareClearanceItems()
      })
  }

  orderItemsEdited(event) {
    this.order.items = event
    this.orderChange.emit(this.order)
    this.refreshPaymentAmount.emit()
  }

  changeQuantityFromMoving(event) {
    this.changeQuantity(event.newQuantity, event.itemOnSelectedOrder, event.selectedOrder, event.isFromMoving)
  }

  changeQuantity(quantity: number, orderItem: any, order = this.order, isFromMoving = false) {
    orderItem.quantity = quantity
    orderItem.priceAll = orderItem.price * Number(orderItem.quantity)
    if (orderItem.product) {
      orderItem.product.orderQuantity = quantity
    }
    for (const [index, item] of order.items.entries()) {
      if (item.uuid === orderItem.uuid) {
        order.items[index] = orderItem
        break
      }
    }
    if (!isFromMoving && order.id === this.order.id) {
      this.orderChange.emit(order)
      this.refreshPaymentAmount.emit()
    }
    this.clearanceItemsWereFetched = false
    if (!isFromMoving && order.id === this.order.id) this.areClearanceItemsInSync = false
  }

  getProductNameForExpirationDate(pdId: number, oItem: OrderItem) {
    const pb = oItem.product.bundleChildren.filter(c => !!c.child.productDepots.filter(d => d.id === pdId)[0])[0]
    if (pb) {
      return pb.child.name
    }
    return ''
  }

  getThumbUrl(image: File): string {
    return image.thumbnails.filter(t => t.format === 'w135wp')[0].url
  }

  isShownTagsColumn(): boolean {
    return this.order.items.some(orderItem => orderItem?.product?.tags?.length > 0)
  }

  isSomeSale(): boolean {
    return this.order.items.some(item => item.sale > 0)
  }

  getSaleInPrice(price: number, sale: number): number {
    return (price / 100) * sale
  }
}
