<form>
  <div class="adress-form" *ngIf="address">
    <div class="form-row">
      <div class="col-sm-6">
        <form-row-input [label]="'firstName'" [(model)]="address.firstName" [dataCyAttribute]="'first-name'"></form-row-input>
      </div>
      <div class="col-sm-6">
        <form-row-input [label]="'lastName'" [(model)]="address.lastName" data-cy="last-name"></form-row-input>
      </div>
    </div>
    <div class="form-row">
      <div class="col-sm-6">
        <form-row-input
          [label]="'email'"
          [(model)]="address.email"
          data-cy="email"
          [inputType]="'email'">
        </form-row-input>
      </div>
      <div class="col-sm-6">
        <form-row-input [label]="'phone'" [(model)]="address.phone" data-cy="phone"></form-row-input>
      </div>
    </div>
    <div class="row">
      <form-row-input
        class="col-6"
        [label]="'company.name'"
        [(model)]="address.companyName"
        [dataCyAttribute]="'address-company-name'"
      ></form-row-input>
    </div>
    <div class="form-row">
      <div class="col-sm-6">
        <form-row-input [label]="'company.ic'" [(model)]="address.ic" data-cy="company-ic"></form-row-input>
      </div>
      <div class="col-sm-6">
        <form-row-input [label]="'company.dic'" [(model)]="address.dic" data-cy="company-dic"></form-row-input>
      </div>
    </div>
    <form-row-input [label]="'street'" [(model)]="address.street" data-cy="street"></form-row-input>
    <div class="form-row">
      <div class="col-sm-6">
        <form-row-input [label]="'city'" [(model)]="address.city" data-cy="city"></form-row-input>
      </div>
      <div class="col-sm-6">
        <form-row-input [label]="'zip'" [(model)]="address.zip" data-cy="zip"></form-row-input>
      </div>
    </div>
    <div *ngIf="states && states.length > 0 && (address.country === 'US' || address.country === 'CA')" class="form-row">
      <div class="col-sm-6">
        <form-row-select
          [label]="'country'"
          [model]="address.country"
          (modelChange)="selectCountry($event)"
          [options]="countriesWithNullOption"
          [optionKey]="'code'"
          [dataCyAttribute]="'country-combination'"
        ></form-row-select>
      </div>
      <div class="col-sm-6">
        <form-row-select
          [label]="'state'"
          [(model)]="address.state"
          [options]="states"
          [optionKey]="'code'"
          [dataCyAttribute]="'state'"
        ></form-row-select>
      </div>
    </div>
    <form-row-select
      *ngIf="!states || (states && states.length === 0)"
      [label]="'country'"
      [model]="address.country"
      (modelChange)="selectCountry($event)"
      [options]="countriesWithNullOption"
      [optionKey]="'code'"
      [dataCyAttribute]="'country'"
    >
    </form-row-select>
    <form-row-input
      [label]="'shopAddressId'"
      [icon]="'far-hashtag'"
      [(model)]="address.branchId"
      data-cy="shop-id"
    ></form-row-input>
  </div>
</form>
