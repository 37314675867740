import { Component, AfterViewInit, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { CategoryTreeType } from '../../models'
import { DndDropEvent } from 'ngx-drag-drop'
import { Category } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../services'
import { TranslateService } from '@ngx-translate/core'
import { SchemaUtil } from '../../utils'

@Component({
  selector: 'category-tree-row',
  templateUrl: './category-tree-row.component.html',
  styleUrls: ['./category-tree-row.component.scss'],
})
export class CategoryTreeRowComponent implements OnInit, AfterViewInit {
  @ViewChild('arrowIconRadioParentCategory') arrowIconRadioParentCategory: ElementRef
  @ViewChild('arrowIconRadioProductMainCategory') arrowIconRadioProductMainCategory: ElementRef
  @ViewChild('arrowIconCheckboxProductCategories') arrowIconCheckboxProductCategories: ElementRef

  @Input() public editedCategory: Category
  @Output() editedCategoryChange: EventEmitter<any> = new EventEmitter()

  @Input() public mainCategory: Category
  @Output() mainCategoryChange: EventEmitter<any> = new EventEmitter()

  @Input() selectedCategories: Category[]
  @Output() selectedCategoriesChange: EventEmitter<any> = new EventEmitter()

  @Input() public category: Category
  @Input() public type: CategoryTreeType

  @Input() isInBulkEdits = false
  @Input() isForFilterCategories = false

  public categoryTreeTypeEnum = CategoryTreeType
  public isShow = true
  public subcategories: Category[] = []
  public showSubcategories = true
  public checkedCategory: number | boolean = false

  constructor(
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.type === this.categoryTreeTypeEnum.radioParentCategory) {
      this.editedCategory?.parent
        ? (this.checkedCategory = this.editedCategory?.parent.id)
        : (this.checkedCategory = null)
    } else if (this.type === this.categoryTreeTypeEnum.radioProductMainCategory) {
      this.mainCategory ? (this.checkedCategory = this.mainCategory.id) : (this.checkedCategory = null)
    } else if (this.type === this.categoryTreeTypeEnum.checkboxProductCategories) {
      if (this.selectedCategories?.length) {
        this.selectedCategories.forEach((category: Category) => {
          if (category.id === this.category.id) {
            this.checkedCategory = true
            return
          }
        })
      }
    }
  }

  ngAfterViewInit() {
    if (this.type === this.categoryTreeTypeEnum.radioParentCategory && this.isForSubcategoryShow('parentCategory')) {
      this.arrowIconRadioParentCategory.nativeElement.click()
    } else if (
      this.type === this.categoryTreeTypeEnum.radioProductMainCategory &&
      this.isForSubcategoryShow('productMainCategory')
    ) {
      this.arrowIconRadioProductMainCategory.nativeElement.click()
    } else if (
      this.type === this.categoryTreeTypeEnum.checkboxProductCategories &&
      this.isForSubcategoryShow('productCategories')
    ) {
      this.selectedCategories.forEach(category => {
        if (
          category.treePath.indexOf(this.category.id) > -1 &&
          category.treePath[category.treePath.length - 1] !== this.category.id
        ) {
          this.arrowIconCheckboxProductCategories.nativeElement.click()
        }
      })
    }
  }

  public unsetCategory(radioInput: HTMLInputElement): void {
    this.checkedCategory = null
    radioInput.checked = false
    this.mainCategory = null
    this.mainCategoryChange.emit(this.mainCategory)
  }

  public async subcategoriesShow(): Promise<any> {
    if (this.subcategories && this.subcategories.length > 0) {
      this.showSubcategories ? (this.showSubcategories = false) : (this.showSubcategories = true)
    } else {
      this.subcategories = await this.core.services.category
        .getList({ filters: { parent: this.category.id } }, SchemaUtil.category)
        .then(result => result.items)
    }
  }

  public async categoryDelete(): Promise<any> {
    if (!confirm(this.translateService.instant('category-tree-row.delete.confirmation'))) {
      return new Promise<void>(r => r())
    }
    try {
      await this.core.services.category.delete(this.category).then(() => {
        this.isShow = false
        this.notificationService.success(this.translateService.instant('alert.category.deleted'))
      })
    } catch (err) {
      this.isShow = true
      console.error(err)
    }
  }

  public onNgModelChange(category: Category): void {
    if (this.type === this.categoryTreeTypeEnum.radioParentCategory) {
      this.editedCategory.parent = category
      this.editedCategoryChange.emit(this.editedCategory)
    } else if (this.type === this.categoryTreeTypeEnum.radioProductMainCategory) {
      this.mainCategory = category
      this.mainCategoryChange.emit(this.mainCategory)
    }
  }

  public onNgModelChangeCheckbox(value: boolean, category: Category): void {
    if (value) {
      this.selectedCategories.push(category)
      this.selectedCategoriesChange.emit(this.selectedCategories)
    } else {
      this.selectedCategories = this.selectedCategories.filter(
        (categoryInArray: Category) => categoryInArray.id !== category.id
      )
      this.selectedCategoriesChange.emit(this.selectedCategories)
    }
  }

  selectedCategoriesChanged(event) {
    this.selectedCategories = event
    this.selectedCategoriesChange.emit(this.selectedCategories)
  }

  private isForSubcategoryShow(type: 'parentCategory' | 'productMainCategory' | 'productCategories'): boolean {
    if (type === 'parentCategory') {
      return (
        this.editedCategory &&
        this.editedCategory.treePath &&
        this.editedCategory.treePath.indexOf(this.category.id) > -1 &&
        this.editedCategory.treePath[this.editedCategory.treePath.length - 2] !== this.category.id &&
        this.editedCategory.treePath[this.editedCategory.treePath.length - 1] !== this.category.id
      )
    } else if (type === 'productMainCategory') {
      return (
        this.mainCategory?.treePath &&
        this.mainCategory.treePath.indexOf(this.category.id) > -1 &&
        this.mainCategory.treePath[this.mainCategory.treePath.length - 1] !== this.category.id
      )
    } else if (type === 'productCategories') {
      return this.selectedCategories?.length > 0
    }
  }

  public isRadioSelected(radioInput: HTMLInputElement): boolean {
    return radioInput.checked
  }

  public async onDrop(event: DndDropEvent, list: Category[], parentCategory: Category) {
    if (list) {
      let index = event.index
      if (typeof index === 'undefined') {
        index = list.length
      }
      const droppedCategory: Category = event.data
      list.splice(index, 0, droppedCategory)
      droppedCategory.parent = new Category(parentCategory)
      droppedCategory.position = index
      await this.core.services.category.update(droppedCategory)
    }
  }

  public onDragMove(item: Category, list: Category[]) {
    const index = list.indexOf(item)
    list.splice(index, 1)
  }
}
