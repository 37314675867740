<modal-container
  #modal
  [headerTitle]="!itemsMoved ? getTitle() : ''"
  [headerIconClass]="'far-edit'"
  [isLargeModal]="!itemsMoved"
>
  <ng-container class="modal-button-show">
    <btn
      [btnType]="'button'"
      [icon]="'far-upload'"
      (click)="showChildModal()"
      [label]="getTitle()"
      [title]="getTitle() | translate"
      [classes]="['btn-third']"
      [dataCyAttribute]="'modal-move-order-items-open'"
    >
    </btn>
  </ng-container>
  <ng-container *ngIf="!itemsMoved" class="modal-body-content min-width">
    <div class="card">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>

      <div class="table-responsive div-table-items">
        <table class="table">
          <thead>
            <tr>
              <th class="text-start">{{ 'item' | translate }}</th>
              <th class="text-end">{{ 'code' | translate }}</th>
              <th>{{ 'bill.vatrate' | translate }}</th>
              <th class="text-end">{{ 'bill.price' | translate }}</th>
              <th class="text-center">{{ 'bill.qty' | translate }}</th>
            </tr>
          </thead>
          <tbody class="images" data-cy="order-products" [photoGalleryGroup]="{ index: 1 }">
            <tr *ngFor="let oItem of items">
              <td *ngIf="oItem.product" class="ps-1">
                <div class="flex-direction-column-gap-5">
                  <div class="flex-gap-10 align-items-center">
                    <div
                      [photoGallery]="oItem.product.mainImage.url"
                      [photoGalleryCaption]="
                        oItem.product
                          ? oItem.product.fullName +
                            '<br>' +
                            (oItem.product.code ? ('product.code' | translate) + oItem.product.code : '') +
                            '<br>' +
                            (oItem.product.ean ? ' EAN: ' + oItem.product.ean : '')
                          : ''
                      "
                    >
                      <div class="images-item">
                        <!--                    <img-->
                        <!--                      class="images-item-image"-->
                        <!--                      [src]="getThumbUrl(oItem.product.mainImage)"-->
                        <!--                      [alt]="oItem.product.fullName"-->
                        <!--                    />-->
                      </div>
                    </div>
                    <div>
                      <div class="flex-gap-5">
                        <a class="link-bold" [routerLink]="['/product', oItem.product.id]" data-cy="product-link">{{
                          oItem.name
                        }}</a>
                        <span
                          *ngIf="oItem.quantityUnavailable > 0"
                          class="badge bg-red badge-unavail align-self-center"
                          title="Nedostupné položky/produkty"
                        >
                          {{
                            (oItem.quantityUnavailable | number: '1.0-2' | replaceCommaToDot) +
                              (oItem.product && oItem.product.quantityUnavailable > 0
                                ? '/' + (oItem.product.quantityUnavailable | number: '1.0-2' | replaceCommaToDot)
                                : '')
                          }}
                        </span>
                      </div>
                      <div class="product-details flex-gap-5 flex-wrap">
                        <div *ngIf="oItem.code">
                          {{ 'product.code' | translate }}:<span> {{ oItem.code }}</span>
                        </div>
                        <div *ngIf="oItem.ean">
                          {{ 'product.ean' | translate }}:<span data-cy="ean"> {{ oItem.ean }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let bc of oItem.product.bundleChildren" class="ms-4">
                    <div class="flex-gap-5 align-items-center ps-3 virtual-product">
                      <i class="fas fa-angle-right"></i>
                      <div>
                        <a class="link-bold" [routerLink]="['/product', bc.child.id]" data-cy="bundle-product-link">{{
                          bc.child.name
                        }}</a>
                      </div>
                    </div>
                    <div class="product-details flex-gap-5 flex-wrap ms-4">
                      <div *ngIf="bc.child.code">
                        {{ 'product.code' | translate }}:<span data-cy="bundle-code">{{ bc.child.code }}</span>
                      </div>
                      <div *ngIf="bc.child.ean">
                        {{ 'product.ean' | translate }}:<span data-cy="bundle-ean">{{ bc.child.ean }}</span>
                      </div>
                      <div>
                        •
                        <span data-cy="bundle-quantity">{{ bc.quantity | number: '1.0-2' | replaceCommaToDot }}x</span>
                      </div>
                    </div>
                  </div>
                  <div class="product-details flex-gap-5 flex-wrap">
                    <div *ngIf="oItem.sale > 0">
                      {{ 'sale' | translate }}: {{ oItem.sale }}% ({{
                        (oItem.quantity > 1 ? oItem.quantity + 'x' : '') + oItem.price * (oItem.sale / 100)
                          | formatPrice
                      }})
                    </div>
                    <div *ngIf="oItem.serial && oItem.serial.length">
                      <div [innerHtml]="oItem.serial | nl2br"></div>
                    </div>
                    <div *ngIf="oItem.note && oItem.note.length">
                      <div [innerHtml]="oItem.note | nl2br"></div>
                    </div>
                    <div
                      *ngIf="
                        (oItem.expirationDate && oItem.expirationDate.length > 0) ||
                        (oItem.batch && oItem.batch.length > 0)
                      "
                    >
                      <div class="ps-3">
                        <span *ngIf="oItem.expirationDate && oItem.expirationDate.length > 0">
                          <!--              Expirace: {{oItem.product && oItem.product.bundleChildren[0].child.name}} - {{ oItem.expirationDate }}<span *ngIf="(oItem.batch && oItem.batch.length > 0) || (oItem.position && oItem.position.length > 0 && oItem.position !== '&#45;&#45;') ">,</span>-->
                          Expirace: {{ oItem.expirationDate
                          }}<span
                            *ngIf="
                              (oItem.batch && oItem.batch.length > 0) ||
                              (oItem.position && oItem.position.length > 0 && oItem.position !== '--')
                            "
                            >,</span
                          >
                        </span>
                        <span *ngIf="oItem.batch && oItem.batch.length > 0">
                          Šarže: {{ oItem.batch
                          }}<span *ngIf="oItem.position && oItem.position.length > 0 && oItem.position !== '--'"
                            >,</span
                          >
                        </span>
                        <span *ngIf="oItem.position && oItem.position.length > 0 && oItem.position !== '--'">
                          Pozice: {{ oItem.position }}
                        </span>
                      </div>
                    </div>
                    <!--                <div *ngIf="(hasExpirationsOnUpdate(oItem) || hasBatchesOnUpdate(oItem))">-->
                    <!--                  <div *ngFor="let move of oItem.moves">-->
                    <!--                    <div-->
                    <!--                      [innerHtml]="-->
                    <!--                        hasExpirationsOnUpdate(oItem)-->
                    <!--                          ? 'Expirace: ' +-->
                    <!--                            getProductNameForExpirationDate(move.productDepotFrom.id, oItem) +-->
                    <!--                            ' - ' +-->
                    <!--                            move.amount +-->
                    <!--                            'x ' +-->
                    <!--                            (move.productDepotFrom.expirationDate.substring(0, 10) | date: 'dd.MM.yyyy')-->
                    <!--                          : hasBatchesOnUpdate(oItem)-->
                    <!--                          ? 'Šarže: ' + move.amount + 'x ' + move.productDepotFrom.batch-->
                    <!--                          : null-->
                    <!--                      "-->
                    <!--                    ></div>-->
                    <!--                  </div>-->
                    <!--                </div>-->
                  </div>
                </div>
              </td>
              <td *ngIf="!oItem?.product" class="top-align ptop-10">
                <h3 class="card-subheading mb-0">{{ oItem.name }}</h3>
              </td>
              <td *ngIf="oItem.product" class="text-nowrap text-end top-align ptop-10">
                {{ oItem?.product?.code ? oItem?.product?.code : '' }}
              </td>
              <td *ngIf="!oItem.product" class="text-nowrap text-end top-align ptop-10">
                {{ oItem.code ? oItem.code : '' }}
              </td>
              <td class="text-center top-align ptop-10">{{ oItem?.vat?.percent }}%</td>
              <td class="text-nowrap text-end top-align ptop-10">
                {{ (oItem ? oItem.price : 0) | formatPrice: order?.currency }}
              </td>
              <td class="text-end top-align">
                <div class="d-flex justify-content-center">
                  <form-row-number-with-controls
                    [noMarginBottom]="true"
                    [label]="null"
                    [(model)]="oItem.quantity"
                    [minWidthPx]="95"
                    [maxWidthPx]="95"
                    [maxValue]="maxValue(oItem)"
                    [minValue]="1"
                  ></form-row-number-with-controls>
                </div>
              </td>
              <!--          <td *ngIf="isCreating || canCreateOrEdit">-->
              <!--            <div class="d-flex justify-content-center">-->
              <!--              <form-row-number-with-controls-->
              <!--                [noMarginBottom]="true"-->
              <!--                [label]="null"-->
              <!--                [model]="oItem.quantity"-->
              <!--                [minWidthPx]="95"-->
              <!--                [maxWidthPx]="95"-->
              <!--                (modelChange)="changeQuantity($event, oItem)"-->
              <!--              ></form-row-number-with-controls>-->
              <!--            </div>-->
              <!--          </td>-->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex-gap-5 align-items-center div-selects">
        <div *ngIf="order.status.id !== 'bill'">
          <select class="form-control" [(ngModel)]="selectedOperationOption" data-cy="opeation-option">
            <option *ngFor="let operaionOpt of operationOptions" [value]="operaionOpt">
              {{ operaionOpt | translate }}
            </option>
          </select>
        </div>
        <div>
          <select
            class="form-control"
            [(ngModel)]="selectedTargetOption"
            data-cy="target-option"
            (change)="negateSigns()"
          >
            <option *ngFor="let targetOpt of targetOptions" [value]="targetOpt">{{ targetOpt | translate }}</option>
          </select>
        </div>
        <div *ngIf="selectedTargetOption === 'intoExistingOrder'" class="form-group">
          <form-row-input
            [icon]="'far-user'"
            [label]="'orders.fulltext'"
            [(model)]="searchPhrase"
            (onKeyPress)="$event && $event.key === 'Enter' ? searchForOrder() : null"
            [appendBtnLabel]="'filter'"
            [appendBtnTitle]="'filter' | translate"
            [appendBtnClasses]="['btn-search']"
            (onAppendBtnEvent)="searchForOrder()"
          >
          </form-row-input>
        </div>
        <div *ngIf="selectedTargetOption === 'corrective.tax.document'" class="text-danger ms-2 text-bold odd-warn">
          {{ 'ODD.warn' | translate }}
        </div>
      </div>
      <div
        *ngIf="searchedOrders?.length && selectedTargetOption !== 'intoNewOrder'"
        class="table-responsive div-table-items"
      >
        <table class="table">
          <thead>
            <tr>
              <th class="text-center">{{ 'reservation.short' | translate }}</th>
              <th>{{ 'customer' | translate }}</th>
              <th>{{ 'dateCreated' | translate }}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let searchedOrder of searchedOrders">
              <td class="text-center">{{ searchedOrder?.reservationNumber }}</td>
              <td>{{ searchedOrder?.customer?.name }}</td>
              <td>{{ searchedOrder?.dateCreated | fmtDate: 'dd.MM.yyyy' }}</td>
              <td class="text-center">
                <btn
                  *ngIf="selectedOrder && selectedOrder?.id === searchedOrder?.id"
                  [label]="'isSelected'"
                  [title]="'isSelected' | translate"
                  [icon]="'fa-check far me-2'"
                  [classes]="['btn-first']"
                  (click)="orderUnselected()"
                >
                </btn>
                <btn
                  *ngIf="!selectedOrder || selectedOrder?.id !== searchedOrder?.id"
                  [label]="'select'"
                  [title]="'select' | translate"
                  [icon]="'fa-chevron-right far ms-2'"
                  (click)="orderSelected(searchedOrder)"
                >
                </btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="itemsMoved" class="modal-body-content min-width">
    <div class="card">
      <div class="card-subheading">
        {{
          selectedTargetOption === 'intoNewOrder'
            ? ('order.with.items.created' | translate)
            : selectedTargetOption === 'corrective.tax.document'
              ? ('ODD.created' | translate)
              : selectedOperationOption === 'move'
                ? ('order.items.moved' | translate)
                : ('order.items.copied' | translate)
        }}
        <a
          class="link product-name"
          (click)="openUpdatedOrder(targetOrder.id)"
          (keyup)="openUpdatedOrder(targetOrder.id)"
          role="button"
          tabIndex="0"
          aria-disabled="true"
        >
          {{ targetOrder?.reservationNumber ? targetOrder.reservationNumber : targetOrder?.billNumber }}
        </a>
        .
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!itemsMoved" class="modal-footer-content">
    <btn [label]="'save'" [title]="'save' | translate" [icon]="'far-check'" [classes]="['btn-first']" (click)="save()">
    </btn>
  </ng-container>
</modal-container>
