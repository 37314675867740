import { Component, Input } from '@angular/core'
import { Order } from 'depoto-core/src/entities'
import { CoreService } from '../../../services'

@Component({
  selector: 'app-order-packages',
  templateUrl: './order-packages.component.html',
  styleUrls: ['order-packages.component.scss'],
})
export class OrderPackagesComponent {
  @Input() order: Order

  constructor(private core: CoreService) {}

  downloadFromUrl(url: string): void {
    this.core.services.download.downloadDocumentAndShowInNewWindow(url)
  }
}
