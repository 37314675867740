<modal-container
  #modalStock
  [headerIconClass]="'far-building'"
  [headerTitle]="'product.stock'"
  [isExtraLargeModal]="true"
  [isLoading]="isLoading"
  [hasBackdrop]="true"
  [ignoreBackdropClick]="false"
  [isModalInModal]="true"
>
  <ng-container class="modal-button-show" *ngIf="!isSpecialAddOrderItemsInstance">
    <button
      type="button"
      class="btn text-nowrap"
      [ngClass]="{ 'btn-sm': isSmallBtn }"
      (click)="showChildModal()"
      *ngIf="!(isLoading && (!sellItems || (sellItems && !sellItems.length)))"
    >
      <span class="quantityStock badge badge-pill">{{ totalQuantityStock | number: '1.0-2' | replaceCommaToDot }}</span>
      <span class="quantityReservation badge badge-pill">{{
        totalQuantityReservation | number: '1.0-2' | replaceCommaToDot
      }}</span>
      <span class="quantityAvailable badge badge-pill" [ngClass]="{ radius: !showPurchaseQuantity }">{{
        totalQuantityAvailable | number: '1.0-2' | replaceCommaToDot
      }}</span>
      <span *ngIf="showPurchaseQuantity" class="quantityPurchase badge badge-pill">{{
        totalQuantityPurchase | number: '1.0-2' | replaceCommaToDot
      }}</span>
    </button>
    <div
      class="spinner-border text-primary spinner-border-sm"
      role="status"
      *ngIf="isLoading && (!sellItems || (sellItems && !sellItems.length))"
    >
      <span class="sr-only">{{ 'modal-stock-overview.loading' | translate }}</span>
    </div>
  </ng-container>
  <ng-container class="modal-button-show" *ngIf="isSpecialAddOrderItemsInstance">
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" [ngClass]="{ 'btn-sm': isSmallBtn }" (click)="showChildModal()">
        <span class="quantityStock badge badge-pill">{{
          totalQuantityStock | number: '1.0-2' | replaceCommaToDot
        }}</span>
        <span class="quantityReservation badge badge-pill">{{
          totalQuantityReservation | number: '1.0-2' | replaceCommaToDot
        }}</span>
        <span class="quantityAvailable badge badge-pill" [ngClass]="{ radius: !showPurchaseQuantity }">{{
          totalQuantityAvailable | number: '1.0-2' | replaceCommaToDot
        }}</span>
      </button>
      <btn
        (onEvent)="showChildModal()"
        [icon]="'far-plus'"
        [label]="'select'"
        [classes]="['btn-second', 'btn-sm']"
      ></btn>
    </div>
  </ng-container>
  <ng-container class="modal-body-content" *ngIf="!isSpecialAddOrderItemsInstance">
    <div class="card">
      <div class="table-responsive">
        <table class="table mb-0">
          <tbody>
            <tr>
              <td class="border-0 no-hover p-0">
                <div class="flex-gap-10 align-items-center">
                  <product-image [product]="product"></product-image>
                  <div class="d-flex flex-column align-items-start">
                    <a class="link-bold" [routerLink]="['/product', product.id]">{{ product.fullName }}</a>
                    <div class="product-details flex-gap-5">
                      <div *ngIf="product.code">{{ 'product.code' | translate }}: {{ product.code }}</div>
                      <div *ngIf="product.ean">{{ 'product.ean' | translate }}: {{ product.ean }}</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="container ps-0 pe-0">
      <div class="row">
        <div [ngClass]="{ 'col-3': showPurchaseQuantity, 'col-4': !showPurchaseQuantity }">
          <div class="h-100px card-smaller card">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column align-items-start">
                <div class="available-number">
                  {{ totalQuantityStock | number: '1.0-2' | replaceCommaToDot }}
                </div>
                <div class="available-amount">
                  {{ 'totalInStock' | translate }}
                </div>
              </div>
              <div>
                <div class="circle-blue">
                  <span class="circle-blue-icon"><i class="far fa-warehouse-alt"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="{ 'col-3': showPurchaseQuantity, 'col-4': !showPurchaseQuantity }">
          <div class="h-100px card-smaller card">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column align-items-start">
                <div class="available-number">
                  {{ totalQuantityReservation | number: '1.0-2' | replaceCommaToDot }}
                </div>
                <div class="available-amount">
                  {{ 'totalReservation' | translate }}
                </div>
              </div>
              <div>
                <div class="circle-red">
                  <span class="circle-red-icon"><i class="far fa-hourglass"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="{ 'col-3': showPurchaseQuantity, 'col-4': !showPurchaseQuantity }">
          <div class="h-100px card-smaller card">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column align-items-start">
                <div class="available-number">
                  {{ totalQuantityAvailable | number: '1.0-2' | replaceCommaToDot }}
                </div>
                <div class="available-amount">
                  {{ 'totalAvailable' | translate }}
                </div>
              </div>
              <div>
                <div class="circle-green">
                  <span class="circle-green-icon"><i class="far fa-check"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3" *ngIf="showPurchaseQuantity">
          <div class="h-100px card-smaller card">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column align-items-start">
                <div class="available-number">
                  <!--                  todo ?-->
                  {{ totalQuantityPurchase | number: '1.0-2' | replaceCommaToDot }}
                </div>
                <div class="available-amount">
                  {{ 'goodsOnTheWay' | translate }}
                </div>
              </div>
              <div>
                <div class="circle-orange">
                  <span class="circle-orange-icon"><i class="far fa-truck"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="sellItems && sellItems.length > 0 && !isSpecialAddOrderItemsInstance">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="text-start ps-0 pt-0">
                <div class="flex-direction-column-gap-20">
                  <div>
                    <h2
                      class="mb-0 text-nowrap align-self-center card-title"
                      *ngFor="let sellItem of sellItems | slice: 0 : 1"
                    >
                      {{ 'depot' | translate }}ble
                      {{ sellItem.productDepots[0].depot ? sellItem.productDepots[0].depot.name : '' }}
                    </h2>
                  </div>
                  <div>
                    {{ 'supplier' | translate }}
                  </div>
                </div>
              </th>
              <th>{{ 'product.batch' | translate }}</th>
              <th>{{ 'product.expirationDate' | translate }}</th>
              <th>{{ 'position' | translate }}</th>
              <th class="text-center pt-0">
                <div class="flex-direction-column-gap-20">
                  <div class="availability-icons">
                    <div class="flex-gap-5 align-items-center" *ngFor="let sellItem of sellItems | slice: 0 : 1">
                      <div>
                        <i class="far fa-warehouse-alt"></i>
                      </div>
                      <div>
                        {{ sellItem.quantityStock | number: '1.0-2' | replaceCommaToDot }}
                      </div>
                    </div>

                    <div class="flex-gap-5 align-items-center" *ngFor="let sellItem of sellItems | slice: 0 : 1">
                      <div>
                        <i class="far fa-hourglass"></i>
                      </div>
                      <div>
                        {{ sellItem.quantityReservation | number: '1.0-2' | replaceCommaToDot }}
                      </div>
                    </div>

                    <div class="flex-gap-5 align-items-center" *ngFor="let sellItem of sellItems | slice: 0 : 1">
                      <div>
                        <i class="far fa-check"></i>
                      </div>
                      <div>
                        {{
                          sellItem.quantityStock - sellItem.quantityReservation | number: '1.0-2' | replaceCommaToDot
                        }}
                      </div>
                    </div>
                  </div>
                  <div>
                    {{ 'products.availability' | translate }}
                  </div>
                </div>
              </th>
              <th class="pe-0 text-end">{{ 'purchasePrice' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" *ngIf="areJustNullQuantitiesInTable(sellItems)">
              <td colspan="12">
                {{ 'no.items' | translate }}
              </td>
            </tr>
            <ng-container *ngFor="let sellItem of sellItems">
              <ng-template [ngIf]="sellItem.quantityStock > 0 || sellItem.quantityReservation > 0">
                <tr>
                  <td class="ps-1 no-hover text-start">
                    {{ sellItem.productDepots[0].supplier ? sellItem.productDepots[0].supplier.name : '' }}
                  </td>
                  <td class="no-hover">{{ sellItem.batch }}</td>
                  <td class="no-hover">{{ sellItem.expirationDate | fmtDate: 'dd.MM.yyyy' }}</td>
                  <td class="no-hover">{{ sellItem.productDepots[0].position }}</td>
                  <td class="text-center no-hover">
                    <span class="quantityStock badge badge-pill">{{ sellItem.quantityStock }}</span>
                    <span class="quantityReservation badge badge-pill">{{ sellItem.quantityReservation }}</span>
                    <span class="quantityAvailable badge badge-pill" [ngClass]="{ radius: !showPurchaseQuantity }">{{
                      sellItem.quantityStock - sellItem.quantityReservation > 0
                        ? sellItem.quantityStock - sellItem.quantityReservation
                        : 0
                    }}</span>
                  </td>
                  <td class="text-end pe-1 no-hover">{{ sellItem.productDepots[0].purchasePrice | formatPrice }}</td>
                </tr>
              </ng-template>
            </ng-container>
          </tbody>
        </table>
        <table class="table" *ngIf="isSpecialAddOrderItemsInstance">
          <thead>
            <tr>
              <th>{{ 'product.batch' | translate }}</th>
              <th>{{ 'product.expirationDate' | translate }}</th>
              <th class="text-end">{{ 'price' | translate }}</th>
              <th>{{ 'item.availability' | translate }}</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sellItem of sellItems">
              <td>{{ sellItem.batch }}</td>
              <td>{{ sellItem.expirationDate | fmtDate: 'dd.MM.yyyy' }}</td>
              <td class="text-end">{{ productPrice | formatPrice }} Kč</td>
              <td class="text-nowrap">
                <span class="quantityStock badge badge-pill">{{
                  totalQuantityStock | number: '1.0-2' | replaceCommaToDot
                }}</span>
                <span class="quantityReservation badge badge-pill">{{
                  totalQuantityReservation | number: '1.0-2' | replaceCommaToDot
                }}</span>
                <span class="quantityAvailable badge badge-pill" [ngClass]="{ radius: !showPurchaseQuantity }">{{
                  totalQuantityAvailable | number: '1.0-2' | replaceCommaToDot
                }}</span>
              </td>
              <td>
                <form-row-number-with-controls
                  [minWidthPx]="90"
                  [maxWidthPx]="100"
                  [noMarginBottom]="true"
                  [model]="sellItem.orderQuantity"
                ></form-row-number-with-controls>
              </td>
              <td>
                <btn
                  [label]="'add'"
                  [icon]="'far-plus'"
                  [classes]="['btn-first', 'btn-sm']"
                  (onEvent)="addOrderItem(sellItem)"
                ></btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="stock && stock.length">
      <div *ngFor="let stockItem of stock">
        <div
          class="card"
          *ngIf="
            (stockItem.productDepots && stockItem.productDepots.length > 0) ||
            ((stockItem.sumReservations || stockItem.sumAvailable || stockItem.sumPurchase) &&
              (filterDepots.length === 0 || filterDepots.includes(stockItem.depot.id)) &&
              showPurchaseQuantity)
          "
        >
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-start ps-0 pt-0">
                    <div class="flex-direction-column-gap-20">
                      <div>
                        <h2 class="mb-0 text-nowrap align-self-center card-title">
                          {{ 'depot' | translate }} {{ stockItem.depot ? stockItem.depot.name : stockItem.depot.name }}
                        </h2>
                      </div>
                      <div>
                        {{ 'supplier' | translate }}
                      </div>
                    </div>
                  </th>
                  <th>{{ 'product.batch' | translate }}</th>
                  <th>{{ 'product.expirationDate' | translate }}</th>
                  <th>{{ 'position' | translate }}</th>
                  <th class="text-center pt-0">
                    <div class="flex-direction-column-gap-20">
                      <div class="availability-icons">
                        <div class="flex-gap-5 align-items-center">
                          <div>
                            <i class="far fa-warehouse-alt"></i>
                          </div>
                          <div>
                            {{ stockItem.sumAll | number: '1.0-2' | replaceCommaToDot }}
                          </div>
                        </div>

                        <div class="flex-gap-5 align-items-center">
                          <div>
                            <i class="far fa-hourglass"></i>
                          </div>
                          <div>
                            {{ stockItem.sumReservations | number: '1.0-2' | replaceCommaToDot }}
                          </div>
                        </div>

                        <div class="flex-gap-5 align-items-center">
                          <div>
                            <i class="far fa-check"></i>
                          </div>
                          <div>
                            {{ stockItem.sumAvailable | number: '1.0-2' | replaceCommaToDot }}
                          </div>
                        </div>
                        <div class="flex-gap-5 align-items-center" *ngIf="showPurchaseQuantity">
                          <div>
                            <i class="far fa-truck"></i>
                          </div>
                          <div>
                            {{ stockItem.sumPurchase | number: '1.0-2' | replaceCommaToDot }}
                          </div>
                        </div>
                      </div>
                      <div>
                        {{ 'products.availability' | translate }}
                      </div>
                    </div>
                  </th>
                  <th class="pe-0 text-nowrap text-end">{{ 'purchasePrice' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" *ngIf="areJustNullQuantitiesInTable(stockItem.productDepots)">
                  <td colspan="12">
                    {{ 'no.items' | translate }}
                  </td>
                </tr>
                <ng-container *ngFor="let pDepot of stockItem.productDepots">
                  <ng-template [ngIf]="pDepot.quantityStock > 0 || pDepot.quantityReservation > 0">
                    <tr>
                      <td class="ps-1 no-hover text-start">
                        {{ pDepot.supplier ? pDepot.supplier.name : '' }}
                      </td>
                      <td class="no-hover">
                        {{ pDepot.batch }}
                      </td>
                      <td class="no-hover">
                        {{ pDepot.expirationDate | fmtDate: 'dd.MM.yyyy' }}
                      </td>
                      <td class="no-hover">
                        {{ pDepot.position }}
                      </td>
                      <td class="text-center no-hover text-nowrap">
                        <span class="quantityStock badge badge-pill">{{ pDepot.quantityStock }}</span>
                        <span class="quantityReservation badge badge-pill">{{ pDepot.quantityReservation }}</span>
                        <span class="quantityAvailable badge badge-pill radius"> {{ pDepot.quantityAvailable }}</span>
                      </td>
                      <td class="text-end text-nowrap no-hover pe-1">
                        {{ pDepot.purchasePrice | formatPrice }}
                      </td>
                    </tr>
                  </ng-template>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</modal-container>
