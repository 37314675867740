import { Component, ChangeDetectorRef, OnInit, ViewChild } from '@angular/core'
import { Voucher } from 'depoto-core/src/entities'
import { ListingItem } from 'depoto-core/src/models'
import { BaseListing } from '../_base-listing/base-listing'
import { ExportsComponent } from '../exports/exports.component'
import { CoreService } from '../../services'
import { SchemaUtil } from '../../utils'
import { vouchersColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  name: null,
  code: null,
  discountType: 'all',
  isValid: 'all',
  isUsed: 'all',
  enabled: 'all',
  validFrom: null,
  validTo: null,
}

@Component({
  selector: 'app-vouchers',
  templateUrl: './vouchers.component.html',
})
export class VouchersComponent extends BaseListing implements OnInit {
  @ViewChild('exportsModal') exportsModal: ExportsComponent
  public listingColumns: ListingItem[] = vouchersColumns
  public vouchers: Voucher[] = []
  public sortProp = 'name'
  public sortDir = 'asc'
  public filters = { ...DEFAULT_FILTERS }
  public discountTypeOptions = [
    {
      id: 'all',
      name: 'all',
    },
    {
      id: 'percentage',
      name: 'voucher.discountType.percentage',
    },
    {
      id: 'value',
      name: 'voucher.discountType.value',
    },
  ]
  public isValidOptions = [
    {
      id: 'all',
      name: 'all',
    },
    {
      id: true,
      name: 'yes',
    },
    {
      id: false,
      name: 'no',
    },
  ]
  public enabledOptions = [
    {
      id: 'all',
      name: 'all',
    },
    {
      id: true,
      name: 'yes',
    },
    {
      id: false,
      name: 'no',
    },
  ]
  public isUsedOptions = [
    {
      id: 'all',
      name: 'all',
    },
    {
      id: true,
      name: 'yes',
    },
    {
      id: false,
      name: 'no',
    },
  ]

  public totalItems = 0
  public currentPage = 1
  public endPage = 1
  filtersExpanded = false
  storageKey: { type: string; key: string }

  constructor(
    protected core: CoreService,
    protected changeDetector: ChangeDetectorRef
  ) {
    super(core)
    this.getStorageKey()
  }

  ngOnInit(): void {
    this.filters = this.loadViewSettings('vouchers') || { ...DEFAULT_FILTERS }
    this.loadSorting('vouchers')
    this.filter()
  }

  get queryFilters() {
    const f: any = {
      name: this.filters.name?.length > 0 ? this.filters.name : undefined,
      code: this.filters.code?.length > 0 ? this.filters.code : undefined,
      discountType:
        this.filters.discountType !== 'all'
          ? this.filters.discountType === 'percentage'
            ? 'percentage'
            : 'value'
          : undefined,
      enabled: this.filters.enabled !== 'all' ? this.filters.enabled == 'true' : undefined,
      isValid: this.filters.isValid !== 'all' ? this.filters.isValid == 'true' : undefined,
      isUsed: this.filters.isUsed !== 'all' ? this.filters.isUsed == 'true' : undefined,
    }
    Object.keys(f).forEach(k => {
      if (f[k] === undefined) {
        delete f[k]
      }
    })
    if (this.filters.validFrom || this.filters.validTo) {
      f.validDate = {}
      if (this.filters.validFrom && this.filters.validFrom.length > 0) {
        f.validDate.left_date = this.filters.validFrom
      }
      if (this.filters.validTo && this.filters.validTo.length > 0) {
        f.validDate.right_date = this.filters.validTo + 'T23:59:59'
      }
    }
    return f
  }

  public async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir) {
    this.loading = true
    this.reloading = true
    this.currentPage = pageNo
    try {
      const result = await this.core.services.voucher.getList(
        { page: pageNo, sort: sortProp, direction: sortDir, filters: this.queryFilters },
        SchemaUtil.voucher
      )
      if (!result?.items) return
      if (pageNo === 1) {
        this.vouchers = [...result.items]
      } else {
        this.vouchers = [...this.vouchers, ...result.items]
      }
      this.totalItems = result.paginator ? result.paginator.totalCount : 0
      this.endPage = result.paginator ? result.paginator.endPage : 0
      this.currentPage = pageNo + 1
      this.saveViewSettings('vouchers', this.filters)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }

  clearFilters() {
    this.filters = { ...DEFAULT_FILTERS }
    this.filter(1)
  }
}
