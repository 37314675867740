import { Component, Input } from '@angular/core'

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() title: string
  @Input() icon: string
  @Input() appendNoTranslationTitlePart: string
  @Input() fullWidthPageTitle: boolean
  @Input() isOrderLayout: boolean
  @Input() isFlexWrap: boolean
  @Input() isHeadingNoWrap: boolean
  @Input() isStockOperation: boolean
  @Input() isOnProductWithAllBtns: boolean
}
