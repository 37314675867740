import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import { printableStyles } from '../../../utils'
import 'jsbarcode'
import { CoreService, NotificationService } from '../../../services'
import { ProductDetailService } from '../../../product-detail.service'
import { TranslateService } from '@ngx-translate/core'

declare const JsBarcode // workaround

@Component({
  selector: 'barcode-print-array',
  templateUrl: 'barcode-print-array.component.html',
  styleUrls: ['barcode-print-array.component.scss'],
})
export class BarcodePrintArrayComponent implements OnInit {
  @ViewChild('barcode', { static: true }) barcode: ElementRef
  @ViewChild('printable') printableEl: ElementRef
  printArray: any[] = []
  quantity = 1
  startAt = 0
  barcodeTitleVisible = true
  barcodeEanVisible = true
  barcodePriceVisible = true
  barcodeWidth = false
  barcodeBase64 = ''
  barcodePaddingHorizontal = 0
  barcodePaddingVertical = 0
  pageMarginTop = 0
  pageMarginLeft = 0
  oneTicket = false
  soutokTicket = false

  constructor(
    private notificationService: NotificationService,
    private core: CoreService,
    public productDetailService: ProductDetailService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.loadViewSettings()
    this.recalculateArray()
    this.generateBarcode()
  }

  loadViewSettings(): void {
    const settings = this.core.services.storage.getSync(this.core.services.storage.keys.views.barcodePrintArray)
    if (settings && Object.keys(settings).length > 0) {
      this.barcodeTitleVisible = settings.barcodeTitleVisible || true
      this.barcodeEanVisible = settings.barcodeEanVisible || true
      this.barcodePriceVisible = settings.barcodePriceVisible || true
      this.barcodeWidth = settings.barcodeWidth || false
      this.barcodePaddingHorizontal = settings.barcodePaddingHorizontal || 0
      this.barcodePaddingVertical = settings.barcodePaddingVertical || 0
      this.pageMarginTop = settings.pageMarginTop || 0
      this.pageMarginLeft = settings.pageMarginLeft || 0
      if (settings.oneTicket) {
        this.toggleOneTicketMode()
      }
      if (settings.soutokTicket) {
        this.toggleSoutokTicketMode()
      }
    }
  }

  saveViewSettings(): void {
    const settings = {
      barcodeTitleVisible: this.barcodeTitleVisible,
      barcodeEanVisible: this.barcodeEanVisible,
      barcodePriceVisible: this.barcodePriceVisible,
      barcodeWidth: this.barcodeWidth,
      barcodePaddingHorizontal: this.barcodePaddingHorizontal,
      barcodePaddingVertical: this.barcodePaddingVertical,
      pageMarginTop: this.pageMarginTop,
      oneTicket: this.oneTicket,
      soutokTicket: this.soutokTicket,
    }
    this.core.services.storage.set(this.core.services.storage.keys.views.barcodePrintArray, settings)
  }

  updateStartAt(field: any): void {
    this.startAt = field.id
    this.recalculateArray()
  }

  recalculateArray(): void {
    if (this.oneTicket || this.soutokTicket) {
      this.printArray = [
        {
          id: 0,
          val: this.barcodeBase64,
        },
      ]
    } else {
      this.printArray = []
      let quantity = 0
      for (let x = 0; x < 39; x++) {
        let value: string = null
        if (x >= this.startAt && quantity < this.quantity) {
          value = this.barcodeBase64
          quantity++
        }
        this.printArray.push({
          id: x,
          val: value,
        })
      }
    }
  }

  toggleBarcodeTitleVisible(): void {
    this.barcodeTitleVisible = !this.barcodeTitleVisible
    this.saveViewSettings()
    this.generateBarcode()
  }

  toggleBarcodeEanVisible(): void {
    this.barcodeEanVisible = !this.barcodeEanVisible
    this.saveViewSettings()
    this.generateBarcode()
  }

  toggleBarcodePriceVisible(): void {
    this.barcodePriceVisible = !this.barcodePriceVisible
    this.saveViewSettings()
    this.generateBarcode()
  }

  toggleBarcodeWidth(): void {
    this.barcodeWidth = !this.barcodeWidth
    this.saveViewSettings()
    this.generateBarcode()
  }

  toggleOneTicketMode(): void {
    this.oneTicket = !this.oneTicket
    this.soutokTicket = false
    this.barcodeWidth = this.oneTicket ? true : !this.barcodeWidth
    this.saveViewSettings()
    this.generateBarcode()
  }

  toggleSoutokTicketMode(): void {
    this.soutokTicket = !this.soutokTicket
    this.oneTicket = false
    this.barcodeWidth = this.soutokTicket ? true : !this.barcodeWidth
    this.saveViewSettings()
    this.generateBarcode()
  }

  generateBarcode(): void {
    if (!this.productDetailService.product.ean || this.productDetailService.product.ean.length === 0) {
      console.warn('Barcode null')
      this.notificationService.error(this.translateService.instant('barcode-print-array.no-ean'))
      return
    }
    JsBarcode(this.barcode.nativeElement, this.productDetailService.product.ean, {
      lineColor: '#000',
      width: this.barcodeWidth ? 2 : 1,
      height: 25,
      displayValue: false,
    })
    this.barcodeBase64 = this.barcode.nativeElement.toDataURL()
    this.recalculateArray()
  }

  confirmPreview(): void {
    const pWidth = 900
    const pHeight = 720
    const popupWin = window.open(
      '',
      'Tisk štítků',
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${pWidth},height=${pHeight},top=${
        screen.height - (screen.height + pHeight) / 2
      },left=${screen.width - (screen.width + pWidth) / 2}`
    )
    popupWin.document.body.innerHTML = this.printableEl.nativeElement.innerHTML
    const styles = document.createElement('style')
    styles.innerHTML = printableStyles()
    popupWin.document.head.appendChild(styles)
    setTimeout(() => {
      popupWin.print()
    }, 400)
    setTimeout(() => {
      popupWin.close()
    }, 800)
  }
}
