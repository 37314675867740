import { Component, OnInit } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { CoreService } from '../../services'
import { UserGroup } from 'depoto-core/src/entities'
import { SchemaUtil } from '../../utils'
import { groupsColumns } from '../../utils/definitions.util'

@Component({
  selector: 'app-usergroups',
  templateUrl: './usergroups.component.html',
})
export class UserGroupsComponent extends BaseListing implements OnInit {
  listingColumns: ListingItem[] = groupsColumns
  groups: UserGroup[] = []
  loading = true
  storageKey: { type: string; key: string }

  constructor(protected core: CoreService) {
    super(core)
    this.getStorageKey()
  }

  ngOnInit() {
    // this.loadSorting('userGroups')  any sortable column
    this.filter(1)
  }

  async filter(page: number) {
    this.loading = true
    this.currentPage = page
    this.reloading = true
    const result = await this.core.services.userGroup.getList({}, SchemaUtil.userGroup)
    if (result === undefined) return
    this.groups = []
    result.items.forEach(group => {
      this.groups.push(new UserGroup(group))
    })
    this.loading = false
    this.reloading = false
  }
}
