import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ApplicationRef } from '@angular/core'
import { BarcodeUtil } from 'depoto-core/src/utils'
import { Depot, Supplier, Product, Vat, Producer, ProductMove, ProductDepot, Currency } from 'depoto-core/src/entities'
import { FormRowTextareaComponent } from '../../_common/form-row-textarea/form-row-textarea.component'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { CoreService } from '../../../services'
import { generateUuidV4 } from 'depoto-core/src/utils/UuidUtil'
import { TranslateService } from '@ngx-translate/core'
import { StorageKeyService } from '../../../services/storage-key.service'
import { isObjectEmpty } from 'src/app/utils'

declare let disableBarcodeScanner: boolean

@Component({
  selector: 'modal-stock-operation-card',
  templateUrl: 'modal-stock-operation-card.component.html',
  styleUrls: ['modal-stock-operation-card.component.scss'],
})
export class ModalStockOperationCardComponent implements OnInit {
  @ViewChild('modal') public modal: ModalContainerComponent
  @ViewChild('inputNote') public inputNoteComponent: FormRowTextareaComponent
  @Input() public product: Product
  @Input() public type: string
  @Input() public depotFrom: number
  @Input() public depotTo: number
  @Input() public isCreatingProduct: boolean
  @Input() public alwaysLoadSelectData: boolean
  @Input() public hideButton: boolean
  @Input() public initDateDUZP: string
  @Input() public operationToUpdate: ProductMove
  @Input() public depots: Depot[] = []
  @Input() public suppliers: Supplier[] = []
  @Input() public producers: Producer[] = []
  @Input() public vats: Vat[] = []
  @Input() moves: ProductMove[] = []
  @Input() movesOriginPositions: { productMoveUuid: string | number; originPosition: string }[] = []
  @Output() movesOriginPositionsChange: EventEmitter<any> = new EventEmitter()
  @Input() productDepot: ProductDepot
  @Input() depotQuantity: number
  @Input() dataCyAttribute: string
  @Output() public result: EventEmitter<any> = new EventEmitter()
  @Output() openModalSerialNumbers: EventEmitter<ProductMove> = new EventEmitter()
  operation: ProductMove
  selectedSupplier: number
  purchasePrice
  currencies: Currency[] = []
  selectedCurrency = 'CZK'
  dateDUZP: string
  sellPrice = ''
  quantity = 1
  note = ''
  batch = ''
  expirationDate = ''
  position1 = ''
  position2 = ''
  position3 = ''
  resultMessage = ''
  isCreatedProduct = false
  isQuantityLocked = false
  loading = false
  keyEventSubscriptions: any = {}
  headerTitle: string
  public modalHeaderIcon: string
  public modalTitle: string

  productDepots = []
  selectCustomPositions = false

  constructor(
    private appRef: ApplicationRef,
    private core: CoreService,
    private translateService: TranslateService,
    private keyService: StorageKeyService
  ) {}

  ngOnInit() {
    this.getTotalQuantityStock()
    this.getSelectData()
    this.getModalData()
    this.setDateDUZP()
    this.getCurrencies()
  }

  getCurrencies() {
    this.currencies = this.core.lists.currencies
  }

  setDateDUZP() {
    if (!!this.initDateDUZP && (!this.dateDUZP || (this.dateDUZP && !this.dateDUZP.length))) {
      this.dateDUZP = this.initDateDUZP
    }
  }

  selectCustomPositionChange(event) {
    this.selectCustomPositions = event
    this.isQuantityLocked = !!event
    // this.appRef.tick()
  }

  clearOptions() {
    this.isQuantityLocked = false
    this.selectCustomPositions = false
    // this.operationToUpdate = null
    // this.isCreatedProduct = false
    // this.isCreatingProduct = false
  }

  private getModalData() {
    if (this.isCreatedProduct) {
      if (this.type === 'in') {
        this.modalHeaderIcon = 'fa-truck'
        if (this.product) {
          this.modalTitle = this.translateService.instant('modal-stock-operation-card.load', {
            name: this.product.name,
          })
        }
      } else if (this.type === 'out') {
        this.modalHeaderIcon = 'fa-upload'
        if (this.product) {
          this.modalTitle = this.translateService.instant('modal-stock-operation-card.unload', {
            name: this.product.name,
          })
        }
      } else if (this.type === 'transfer') {
        this.modalHeaderIcon = 'fa-exchange'
        if (this.product) {
          this.modalTitle = this.translateService.instant('modal-stock-operation-card.transfer', {
            name: this.product.name,
          })
        }
      }
    } else {
      this.modalHeaderIcon = 'fa-file'
      this.isCreatingProduct ? (this.modalTitle = 'product.create') : (this.modalTitle = 'product.update')
    }
  }

  getTotalQuantityStock() {
    this.depotQuantity = this.productDepots.reduce((currentTotal, pDepot) => pDepot.quantityStock + currentTotal, 0)
  }

  createProduct(): void {
    this.loading = true
    if (!this.product?.vat?.id) this.vats.filter(v => v.default)[0]
    this.core.services.product.create(this.product).then(res => {
      this.loading = false
      if (res && res.id) {
        this.product = new Product(res)
        this.isCreatedProduct = true
      } else {
        this.result.emit(res)
      }
      this.onHideChildModal()
    })
  }

  updateProduct(): void {
    this.loading = true
    this.core.services.product
      .update(this.product)
      .then(res => {
        this.loading = false
        if (res && res.id) {
          this.product = new Product(res)
          this.result.emit(this.product)
        } else {
          this.result.emit(res)
        }
        this.onHideChildModal()
      })
      .catch(err => this.result.emit(err))
  }

  createOperation(): void {
    if (this.type === 'in' && (!this.selectedSupplier || this.selectedSupplier < 1)) {
      alert(this.translateService.instant('modal-stock-operation-card.supplier.error'))
      return
    }
    if (this.quantity <= 0) {
      alert(this.translateService.instant('modal-stock-operation-card.quantity.error'))
      return
    }
    if (this.type === 'transfer' && (!this.purchasePrice || this.purchasePrice <= 0)) {
      alert(this.translateService.instant('modal-stock-operation-card.price.error'))
      return
    }
    if (
      this.type === 'out' &&
      Number(
        this.productDepots.reduce((currentTotal, pDepot) => {
          return Number(pDepot.amountToUnload) + Number(currentTotal)
        }, 0)
      ) !== Number(this.quantity)
    ) {
      alert(this.translateService.instant('modal-stock-operation-card.quantity-not-match'))
      this.onHideChildModal()
      return
    }
    let wasQuantityChanged = false
    let op: ProductMove
    switch (this.type) {
      case 'in': {
        const sameItemAlreadyInMovesIn = this.moves.find(
          move =>
            move.product.id == this.product.id &&
            (move.batch == this.batch || (!move.batch && !this.batch)) &&
            (move.expirationDate == this.expirationDate || (!move.expirationDate && !this.expirationDate)) &&
            (move.position1 == this.position1 || (!move.position1 && !this.position1)) &&
            (move.position2 == this.position2 || (!move.position2 && !this.position2)) &&
            (move.position3 == this.position3 || (!move.position3 && !this.position3)) &&
            (move.supplier == this.selectedSupplier || (!move.supplier && !this.selectedSupplier)) &&
            (move.purchaseCurrency == this.selectedCurrency || (!move.purchaseCurrency && !this.selectedCurrency)) &&
            (move.purchaseCurrencyDate == this.dateDUZP || (!move.purchaseCurrencyDate && !this.dateDUZP)) &&
            (move.note == this.note || (!move.note && !this.note)) &&
            (Number(move.purchasePrice) == Number(this.purchasePrice) || (!move.purchasePrice && !this.purchasePrice))
        )
        if (sameItemAlreadyInMovesIn) {
          sameItemAlreadyInMovesIn.amount = sameItemAlreadyInMovesIn.amount + this.quantity
        } else if (this.operationToUpdate) {
          if (this.operationToUpdate.amount !== this.quantity) {
            wasQuantityChanged = true
          }
          this.operationToUpdate.amount = this.quantity
          this.operationToUpdate.depotFrom = this.depotFrom
          this.operationToUpdate.purchasePrice = Number(this.purchasePrice)
          this.operationToUpdate.purchasePriceSum = Number(this.purchasePrice) * Number(this.quantity)
          this.operationToUpdate.purchaseCurrency = this.selectedCurrency
          this.operationToUpdate.batch = this.batch
          this.operationToUpdate.expirationDate = this.expirationDate
          this.operationToUpdate.position1 = this.position1
          this.operationToUpdate.position2 = this.position2
          this.operationToUpdate.position3 = this.position3
          this.operationToUpdate.note = this.note
        } else {
          op = new ProductMove({
            depotTo: this.depotTo,
            supplier: this.selectedSupplier,
            amount: Number(this.quantity),
            purchasePrice: Number(this.purchasePrice),
            purchasePriceSum: Number(this.purchasePrice) * Number(this.quantity),
            note: this.note,
            product: this.product,
            batch: this.batch,
            expirationDate: this.expirationDate,
            position1: this.position1,
            position2: this.position2,
            position3: this.position3,
            purchaseCurrency: this.selectedCurrency,
            serialNumbers: this.operationToUpdate?.serialNumbers,
          })
          this.moves.push(op)
          if (this.dateDUZP) {
            op.purchaseCurrencyDate = this.dateDUZP
          }
        }
        break
      }
      case 'out': {
        const sameItemAlreadyInMovesOut = this.moves.find(
          move =>
            move.product.id == this.product.id &&
            (move.batch == this.batch || (!move.batch && !this.batch)) &&
            (move.expirationDate == this.expirationDate || (!move.expirationDate && !this.expirationDate)) &&
            (move.position1 == this.position1 || (!move.position1 && !this.position1)) &&
            (move.position2 == this.position2 || (!move.position2 && !this.position2)) &&
            (move.position3 == this.position3 || (!move.position3 && !this.position3)) &&
            (move.supplier == this.selectedSupplier || (!move.supplier && !this.selectedSupplier)) &&
            (move.purchaseCurrency == this.selectedCurrency || (!move.purchaseCurrency && !this.selectedCurrency)) &&
            (move.purchaseCurrencyDate == this.dateDUZP || (!move.purchaseCurrencyDate && !this.dateDUZP)) &&
            (move.note == this.note || (!move.note && !this.note)) &&
            (Number(move.purchasePrice) == Number(this.purchasePrice) || (!move.purchasePrice && !this.purchasePrice))
        )
        if (sameItemAlreadyInMovesOut) {
          sameItemAlreadyInMovesOut.amount = sameItemAlreadyInMovesOut.amount + this.quantity
        } else if (this.operationToUpdate) {
          this.operationToUpdate.amount = this.quantity
          this.operationToUpdate.depotFrom = this.depotFrom
          this.operationToUpdate.purchasePrice = Number(this.purchasePrice)
          this.operationToUpdate.purchasePriceSum = Number(this.purchasePrice) * Number(this.quantity)
          this.operationToUpdate.purchaseCurrency = this.selectedCurrency
          this.operationToUpdate.batch = this.batch
          this.operationToUpdate.expirationDate = this.expirationDate
          this.operationToUpdate.position1 = this.position1
          this.operationToUpdate.position2 = this.position2
          this.operationToUpdate.position3 = this.position3
        } else {
          this.productDepots.forEach(pDepot => {
            if (pDepot.amountToUnload > 0) {
              op = new ProductMove({
                depotFrom: this.depotFrom,
                amount: Number(pDepot.amountToUnload),
                purchasePrice: Number(pDepot.purchasePrice),
                purchasePriceSum: Number(pDepot.purchasePrice) * Number(pDepot.amountToUnload),
                product: this.product,
                productDepot: pDepot.id,
                batch: pDepot.batch,
                expirationDate: pDepot.expirationDate,
                position1: pDepot.position1,
                position2: pDepot.position2,
                position3: pDepot.position3,
                serialNumbers: this.operationToUpdate?.serialNumbers,
              })
              this.moves.push(op)
            }
          })
        }
        break
      }
      case 'transfer': {
        const sameItemAlreadyInMovesTransfer = this.moves.find(
          move =>
            move.product.id == this.product.id &&
            (move.batch == this.batch || (!move.batch && !this.batch)) &&
            (move.expirationDate == this.expirationDate || (!move.expirationDate && !this.expirationDate)) &&
            (move.position1 == this.position1 || (!move.position1 && !this.position1)) &&
            (move.position2 == this.position2 || (!move.position2 && !this.position2)) &&
            (move.position3 == this.position3 || (!move.position3 && !this.position3)) &&
            (move.supplier == this.selectedSupplier || (!move.supplier && !this.selectedSupplier)) &&
            (move.purchaseCurrency == this.selectedCurrency || (!move.purchaseCurrency && !this.selectedCurrency)) &&
            (move.purchaseCurrencyDate == this.dateDUZP || (!move.purchaseCurrencyDate && !this.dateDUZP)) &&
            (move.note == this.note || (!move.note && !this.note)) &&
            (Number(move.purchasePrice) == Number(this.purchasePrice) || (!move.purchasePrice && !this.purchasePrice))
        )
        if (sameItemAlreadyInMovesTransfer) {
          sameItemAlreadyInMovesTransfer.amount = sameItemAlreadyInMovesTransfer.amount + this.quantity
        } else if (this.operationToUpdate) {
          this.operationToUpdate.amount = this.quantity
          this.operationToUpdate.depotFrom = this.depotFrom
          this.operationToUpdate.purchasePrice = Number(this.purchasePrice)
          this.operationToUpdate.purchasePriceSum = Number(this.purchasePrice) * Number(this.quantity)
          this.operationToUpdate.purchaseCurrency = this.selectedCurrency
          this.operationToUpdate.batch = this.batch
          this.operationToUpdate.expirationDate = this.expirationDate
          this.operationToUpdate.position1 = this.position1
          this.operationToUpdate.position2 = this.position2
          this.operationToUpdate.position3 = this.position3
          this.operationToUpdate.note = this.note
        } else {
          op = new ProductMove({
            uuid: generateUuidV4(),
            depotFrom: this.depotFrom,
            depotTo: this.depotTo,
            productDepotFrom: this.productDepot,
            amount: Number(this.quantity),
            purchasePrice: Number(this.purchasePrice),
            purchasePriceSum: Number(this.purchasePrice) * Number(this.quantity),
            purchaseCurrency: this.selectedCurrency,
            note: this.note,
            product: this.product,
            batch: this.batch,
            expirationDate: this.expirationDate,
            position1: this.position1,
            position2: this.position2,
            position3: this.position3,
            originPosition: this.productDepot.position,
          })
          this.moves.push(op)
          const movesOriginPosition = { productMoveUuid: op.uuid, originPosition: this.productDepot.position }
          this.movesOriginPositions.push(movesOriginPosition)
        }
        break
      }
    }
    this.result.emit(this.moves)
    this.movesOriginPositionsChange.emit(this.movesOriginPositions)
    if (this.type === 'in') {
      if (
        this.product?.requiredSerialNumbers &&
        (!this.operationToUpdate || (this.operationToUpdate && wasQuantityChanged))
      ) {
        this.openModalSerialNumbers.emit(op)
      }
    } else if (this.type === 'out' && op.product?.requiredSerialNumbers) {
      this.openModalSerialNumbers.emit(op)
    }
    window['selectedSupplier'] = this.selectedSupplier
    this.onHideChildModal()
  }

  async fillNextEan() {
    this.product.ean = await this.core.services.product.getNextEan()
  }

  showProductOnChildModal(product?: Product, isCreatingProduct?: boolean, productEan?: string): void {
    this.isCreatingProduct = !!isCreatingProduct
    if (product && !isCreatingProduct) {
      this.product = product
    }
    this.showChildModal(productEan)
  }

  showProductDepotOnChildModal(productDepot?: ProductDepot): void {
    this.productDepot = productDepot
    this.showChildModal()
  }

  public async showChildModal(productEan?: string) {
    this.loading = true
    if (window['selectedSupplier'] && !this.selectedSupplier) {
      this.selectedSupplier = window['selectedSupplier']
    }
    if (this.product && this.product.supplier && this.product.supplier.id > 0 && !this.selectedSupplier) {
      this.selectedSupplier = this.product.supplier.id
    }
    if (!this.isCreatingProduct) {
      this.keyEventSubscriptions.keyEnter = this.core.services.keyEvent.onKeyEnter.subscribe().then(() => {
        if (
          this.modal.isShown() &&
          this.inputNoteComponent &&
          this.inputNoteComponent.inputEl &&
          this.inputNoteComponent.inputEl.nativeElement &&
          this.inputNoteComponent.inputEl.nativeElement !== document.activeElement
        ) {
          // if textarea is not in focus
          this.createOperation()
        }
      })
      this.keyEventSubscriptions.keyEsc = this.core.services.keyEvent.onKeyEsc.subscribe().then(() => {
        this.onHideChildModal()
      })
      this.keyEventSubscriptions.scannedPositions = this.core.services.keyEvent.onScannedPositions
        .subscribe()
        .then(positions => {
          this.position1 = positions[0]
          this.position2 = positions[1]
          this.position3 = positions[2]
          // this.appRef.tick()
          setTimeout(() => this.createOperation())
        })
    }
    disableBarcodeScanner = true
    if (
      (this.type === 'in' && !this.depotTo) ||
      (this.type === 'out' && !this.depotFrom) ||
      (this.type === 'transfer' && (!this.depotFrom || !this.depotTo))
    ) {
      alert(this.translateService.instant('modal-stock-operation-card.select-warehouse'))
      disableBarcodeScanner = false
      return
    }

    if (this.type !== 'transfer') {
      await this.fetchProductDepots()
    }

    try {
      if (!this.isCreatingProduct && this.product) {
        this.productDepots = Object.assign([], this.getProductDepots())
        if (this.type === 'out' || this.type === 'transfer') {
          this.productDepots = this.productDepots.filter(pDepot => Number(pDepot.depot.id) === Number(this.depotFrom))
        }
        this.isCreatedProduct = true
      } else if (this.isCreatingProduct) {
        this.product = new Product({ enabled: true })
        this.isCreatedProduct = false
        if (productEan) {
          setTimeout(() => {
            this.product.ean = productEan
          }, 50)
        }
      }
      if (this.operationToUpdate) {
        this.selectedSupplier = this.operationToUpdate.supplier
        this.purchasePrice = this.operationToUpdate.purchasePrice
        this.sellPrice = this.operationToUpdate.sellPrice + '' // is not used!
        this.quantity = this.operationToUpdate.amount
        this.note = this.operationToUpdate.note
        this.batch = this.operationToUpdate.batch
        this.expirationDate = this.operationToUpdate.expirationDate
        this.position1 = this.operationToUpdate.position1
        this.position2 = this.operationToUpdate.position2
        this.position3 = this.operationToUpdate.position3
        if (this.operationToUpdate.purchaseCurrency) {
          this.selectedCurrency = this.operationToUpdate.purchaseCurrency
        }
        if (this.operationToUpdate.purchaseCurrencyDate) {
          this.dateDUZP = this.operationToUpdate.purchaseCurrencyDate
        }
      } else if (this.type === 'out') {
        if (this.moves.some(move => move.product.id === this.product.id)) {
          const pushedMovesWithSameProduct = this.moves.filter(move => move.product.id === this.product.id)
          this.quantity = 0
          this.productDepots.forEach(pDepot => {
            pushedMovesWithSameProduct.forEach(pushedMove => {
              if (pushedMove.productDepot === pDepot.id) {
                pDepot.amountToUnload = pushedMove.amount
                this.quantity = this.quantity + pDepot.amountToUnload
              }
            })
          })
        } else {
          this.quantity = 1
          this.productDepots.forEach(pDepot => {
            pDepot.amountToUnload = 0
          })
          if (this.productDepots?.length) {
            this.productDepots[0].amountToUnload = 1
          }
        }
      } else if (this.type === 'in') {
        this.purchasePrice = this.product.purchasePrice
        this.selectedCurrency = this.product.purchaseCurrency?.id
        this.quantity = 1
      } else {
        this.quantity = 1
        this.batch = this.productDepot?.batch
        this.expirationDate = this.productDepot?.expirationDate
        this.purchasePrice = this.productDepot?.purchasePrice
        this.selectedCurrency = this.product.purchaseCurrency?.id
      }
      this.getViewSettings()
      this.getSelectData()
      this.getModalData()
      this.setDateDUZP()
      this.getTotalQuantityStock()
      this.loading = false
      this.modal.showChildModal()
    } catch (e) {
      disableBarcodeScanner = false
      throw e
    }
  }

  getProductDepots() {
    return this.product.productDepots.filter(depot => depot.quantityStock > 0)
  }
  async fetchProductDepots() {
    // this.loading = true
    try {
      const r = await this.core.services.product.getById(this.product.id, {
        id: null,
        purchasePrice: null,
        supplier: { id: null, name: null },
        productDepots: {
          id: null,
          depot: { id: null, name: null, unavailablesUrl: null },
          supplier: { id: null, name: null },
          purchasePrice: null,
          quantityStock: null,
          quantityReservation: null,
          quantityAvailable: null,
          inventoryQuantityStock: null,
          batch: null,
          expirationDate: null,
          position: null,
          position1: null,
          position2: null,
          position3: null,
          created: null,
          updated: null,
        },
      })
      if (r?.productDepots) {
        if (this.product.productDepots) {
          this.product.productDepots = [...r.productDepots]
        } else {
          this.product = new Product({ ...this.product, ...r.productDepots })
        }
      }
    } catch (err) {
      this.loading = false
      console.error(err)
    }
  }

  public onHideChildModal(): void {
    this.modal?.childModal?.hide() // modal closes itself on close-buttons click only
    if (!disableBarcodeScanner && isObjectEmpty(this.keyEventSubscriptions)) return

    disableBarcodeScanner = false

    for (const keyEvent in this.keyEventSubscriptions) {
      this.keyEventSubscriptions[keyEvent]?.unsubscribe()
      delete this.keyEventSubscriptions[keyEvent]
    }

    this.clearOptions()
    this.selectedSupplier = null
    if (!this.isQuantityLocked) {
      this.quantity = 1
    }
    this.note = ''
    this.batch = ''
    this.expirationDate = ''
    this.position1 = ''
    this.position2 = ''
    this.position3 = ''
  }

  setViewSettings(): void {
    setTimeout(() => {
      // this.core.services.storage.set(this.keyService.getStorageKeyForEntity('stockOperationCardSupplier'), this.selectedSupplier)
      // never read
      this.core.services.storage.set(
        this.keyService.getStorageKeyForEntity('stockOperationCardIsLockedQuantity'),
        this.isQuantityLocked
      )
      this.core.services.storage.set(
        this.keyService.getStorageKeyForEntity('stockOperationCardLockedQuantity'),
        this.quantity
      )
    }, 100)
  }

  getViewSettings(): void {
    this.isQuantityLocked =
      this.core.services.storage.getSync(
        this.keyService.getStorageKeyForEntity('stockOperationCardIsLockedQuantity')
      ) || false
    if (this.isQuantityLocked && !this.operationToUpdate) {
      this.quantity =
        this.core.services.storage.getSync(
          this.keyService.getStorageKeyForEntity('stockOperationCardLockedQuantity')
        ) || 1
    }
  }

  getSelectData() {
    if (this.product && !this.product.vat) {
      this.vats.forEach((vat: Vat) => {
        if (vat.default) {
          setTimeout(() => {
            this.product.vat = vat
            /* this.core.services.storage.set(this.keyService.getStorageKeyForEntity('stockOperationCardSelectData'), {
              producers: this.producers,
              vats: this.vats,
              defaultVat: vat,
            })
            never read
            */
          }, 500) // Why setTimeout and why 500ms? Can't we set this.product = {...this.product, vat}?
          return
        }
      })
    }
  }

  selectVat(event): void {
    this.vats.forEach((vat: Vat) => {
      if (vat.id === Number(event)) {
        this.product.vat = vat
        return
      }
    })
  }

  selectProducer(event): void {
    this.producers.forEach((producer: Producer) => {
      if (producer.id === Number(event)) {
        this.product.producer = producer
        return
      }
    })
  }

  setParent(parent: Product): void {
    this.product.parent = parent
    this.product.sellPrice = parent.sellPrice
    this.product.producer = parent.producer
    this.product.vat = parent.vat
  }

  setEan(ean): void {
    // SHAME: this is small hack to ensure translation of the last character, untranslated by directive..
    this.product.ean = BarcodeUtil.translateBarcode(ean)
  }

  fillLastUsedValues(): void {
    if (this.product.productDepots?.length) {
      const depotsSorted = [...this.product.productDepots].filter(d => d.depot.id === Number(this.depotTo))
      depotsSorted.sort((a, b) => {
        if (a.id > b.id) {
          // todo by date and check why is not working??
          return -1
        } else if (a.id < b.id) {
          return 1
        }
        return 0
      })
      if (!this.purchasePrice && depotsSorted[0] && depotsSorted[0].purchasePrice) {
        this.purchasePrice = depotsSorted[0].purchasePrice.toFixed(3)
      }
      if (this.product.purchasePrice !== null && this.product.purchasePrice !== 0) {
        this.purchasePrice = this.product.purchasePrice.toFixed(3)
        if (this.product.purchaseCurrency) {
          this.selectedCurrency = this.product.purchaseCurrency.id
        }
      }
      if (!this.selectedSupplier && depotsSorted[0] && depotsSorted[0].supplier) {
        this.selectedSupplier = depotsSorted[0].supplier.id
      }
      if (!this.quantity && !this.isQuantityLocked && depotsSorted[0] && depotsSorted[0].quantityStock) {
        this.quantity = Number(depotsSorted[0].quantityStock.toFixed(0))
      }
      if (!this.position1 && depotsSorted[0] && depotsSorted[0].position1) {
        this.position1 = depotsSorted[0].position1
      }
      if (!this.position2 && depotsSorted[0] && depotsSorted[0].position2) {
        this.position2 = depotsSorted[0].position2
      }
      if (!this.position3 && depotsSorted[0] && depotsSorted[0].position3) {
        this.position3 = depotsSorted[0].position3
      }
    } else {
      // never used product
      this.purchasePrice = '0.000'
      if (
        !isNaN(this.product.purchasePrice) &&
        this.product.purchasePrice !== undefined &&
        this.product.purchasePrice !== null &&
        this.product.purchasePrice !== 0
      ) {
        this.purchasePrice = this.product.purchasePrice.toFixed(3)
        if (this.product.purchaseCurrency) {
          this.selectedCurrency = this.product.purchaseCurrency.id
        }
      }
      if (this.suppliers.length === 1) {
        this.selectedSupplier = this.suppliers[0].id
      }
    }
    if (this.operationToUpdate) {
      if (this.operationToUpdate.purchaseCurrency) {
        this.selectedCurrency = this.operationToUpdate.purchaseCurrency
      }
      if (this.operationToUpdate.purchaseCurrencyDate) {
        this.dateDUZP = this.operationToUpdate.purchaseCurrencyDate
      }
    }
  }

  toggleLockQuantity(): void {
    this.isQuantityLocked = !this.isQuantityLocked
    this.setViewSettings()
  }

  quantityChanged(event) {
    this.quantity = event
    let remainingQuantity = this.quantity
    this.productDepots.forEach(pDepot => {
      if (remainingQuantity >= pDepot.quantityStock) {
        pDepot.amountToUnload = pDepot.quantityStock
        remainingQuantity -= pDepot.quantityStock
      } else {
        pDepot.amountToUnload = remainingQuantity
        remainingQuantity = 0
      }
    })
  }

  amountToUnloadChanged(event, productDepot) {
    productDepot.amountToUnload = event
    this.quantity = this.productDepots.reduce((total, pd) => {
      return Number(pd.amountToUnload) + Number(total)
    }, 0)
  }

  getThumbUrl(mainImage) {
    return mainImage.thumbnails.filter(t => t.format === 'w135wp')[0].url
  }
}
