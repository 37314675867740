import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core'
import { ListingItem } from 'depoto-core/src/models'
import { CoreService } from '../../../services'
import { StorageKeyService } from '../../../services/storage-key.service'
import { File } from 'depoto-core/src/entities'
type StorageKey = { type: string; key: string }

@Component({
  selector: 'app-base-listing',
  templateUrl: './base-listing.component.html',
  styleUrls: ['./base-listing.component.scss'],
})
export class BaseListingComponent implements OnDestroy, OnChanges {
  @Input() listName: string
  @Input() needsTranslationPrefix: false
  @Input() items: any[] = []
  @Input() columns: ListingItem[] = []
  @Input() isColumnSettingVisible = false
  @Input() totalItems = 0
  @Input() currentPage: number
  @Input() endPage: number
  @Input() filters: any
  @Input() loadStringifiedFilters = false
  @Input() sortProp = 'id'
  @Output() sortPropChange: EventEmitter<any> = new EventEmitter()
  @Input() sortDir = 'desc'
  @Output() sortDirChange: EventEmitter<any> = new EventEmitter()
  @Input() loadingItems = false
  @Input() loadingNextItems = true
  @Input() stringifiedFilters: string
  @Input() storageKey: StorageKey // todo Vasek; test pls
  @Output() reloadList: EventEmitter<any> = new EventEmitter()
  @Output() switchPage: EventEmitter<any> = new EventEmitter()
  @Output() callAction: EventEmitter<any> = new EventEmitter()
  @Output() selectedColumnsChange: EventEmitter<ListingItem[]> = new EventEmitter()
  public selectedColumns: ListingItem[] = []
  public isAllSelected = false
  priorities: any[] = [
    { id: 0, name: 'Nejnižší' },
    { id: 1, name: 'Nízká' },
    { id: 10, name: 'Normální' },
    { id: 20, name: 'Vysoká' },
    { id: 30, name: 'Nejvyšší' },
  ]

  constructor(
    public core: CoreService,
    public keyService: StorageKeyService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.storageKey?.currentValue) {
      this.loadSettings()
    }
  }

  loadDefaultCols() {
    this.selectedColumns = this.columns.filter(c => c.isDefault)
    this.saveSettings()
  }

  saveSettings(): void {
    const savedColumns = this.core.services.storage.getSync(this.storageKey) || {}
    savedColumns[this.listName] = [...this.selectedColumns]
    this.core.services.storage.set(this.storageKey, savedColumns)
  }

  loadSettings(): void {
    const savedColumns = this.core.services.storage.getSync(this.storageKey)
    if (savedColumns?.orders) {
      savedColumns.orders = savedColumns.orders.filter(savedColumn =>
        this.columns.find(column => column.name === savedColumn.name)
      )
    }
    if (this.isColumnSettingVisible) {
      this.selectedColumns = [...this.columns.filter(c => c.isDefault)]
    } else {
      this.selectedColumns = [...this.columns]
    }
    if (savedColumns && savedColumns[this.listName]?.length) {
      this.selectedColumns = savedColumns[this.listName].map(c => this.columns.filter(col => col.name === c.name)[0])
    }
  }

  ngOnDestroy() {
    window.onscroll = null
  }

  paginate(event): void {
    this.switchPage.emit({
      currentPage: event.currentPage,
      sortProp: event.sortProp,
      sortDir: event.sortDir,
      concat: event.concat,
    })
  }

  getStorageKeyForEntity(entity: string): StorageKey {
    return this.keyService.getStorageKeyForEntity(entity, 'baseListing')
  }

  sortBy(property: string): void {
    if (this.sortProp === property) {
      this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc'
      this.sortDirChange.emit(this.sortDir)
    } else {
      this.sortProp = property
      this.sortPropChange.emit(this.sortProp)
    }
    this.currentPage = 1
    const sorting = { sortProp: this.sortProp, sortDir: this.sortDir }
    this.core.services.storage.set(this.getStorageKeyForEntity(this.listName), sorting)
    this.switchPage.emit({
      ...sorting,
      currentPage: this.currentPage,
      concat: false,
    })
  }

  onAction(action: string, item: any) {
    this.callAction.emit({
      action: action,
      item: item,
    })
  }

  onColumnSettingDropdownClick(event: string) {
    const isSelected = this.selectedColumns.filter(c => c.name === event).length
    // console.warn(event, isSelected, [...this.selectedColumns], [...this.columns]); // TODO: remove
    if (isSelected) {
      this.selectedColumns = this.selectedColumns.filter(c => c.name !== event)
    } else {
      this.selectedColumns = [...this.selectedColumns, ...this.columns.filter(c => c.name === event)]
      // sorting by reference arr (includes() breaks if isSelected - different objects..):
      this.selectedColumns = this.columns.filter(c => this.selectedColumns.includes(c))
    }

    this.selectedColumnsChange.emit(this.selectedColumns)
    this.saveSettings()
  }

  public trackByColumnName(index: number, column: ListingItem): string {
    return column.name
  }

  public trackByIndex(index: number) {
    return index
  }

  onCheckboxChange(itemId, value) {
    if (itemId === 0) {
      this.items.forEach((it, i) => {
        this.items[i].isSelected = value
      })
    }
  }

  getPriorityName(priorityId: number): string | undefined {
    return this.priorities.find(p => p.id === priorityId)?.name
  }

  getThumbUrl(image: File): string {
    return image.thumbnails.filter(t => t.format === 'w135wp')[0].url
  }
}
