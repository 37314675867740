<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="consentForm" (ngSubmit)="isCreating ? createConsent() : updateConsent()" *ngIf="consent">
    <page-header
      [icon]="'far-file'"
      [title]="consent.id === 0 || isCreating ? 'consent.new' : 'consent'"
      [appendNoTranslationTitlePart]="consent.id === 0 || isCreating ? '' : consent.name"
    >
      <ng-container class="header-button">
        <btn
          *ngIf="!isCreating"
          [label]="'save'"
          [title]="'save' | translate"
          [classes]="['btn-first', 'float-right']"
          [icon]="'far-check'"
          [isLoading]="loading"
          [btnDisabled]="loading"
          [spinnerClassesString]="'spinner-white'"
          [btnType]="'submit'"
          [dataCyAttribute]="'create-consent'"
        ></btn>
        <btn
          *ngIf="isCreating"
          [label]="'save'"
          [title]="'save' | translate"
          [classes]="['btn-first', 'float-right']"
          [icon]="'far-check'"
          [isLoading]="loading"
          [btnDisabled]="loading"
          [spinnerClassesString]="'spinner-white'"
          [btnType]="'submit'"
          [dataCyAttribute]="'create-consent'"
        ></btn>
        <btn
          *ngIf="!isCreating"
          [label]="'delete'"
          [title]="'delete' | translate"
          [classes]="['btn-first-red', 'float-right', 'me-2']"
          [icon]="'far-trash-alt'"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-red'"
          (onEvent)="deleteConsent()"
          [dataCyAttribute]="'delete-consent'"
        ></btn>
        <btn
          [label]="'print'"
          [title]="'print' | translate"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-print'"
          (onEvent)="printCustomerConsent()"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <section class="content">
      <div class="container-fluid ps-0 pe-0">
        <div class="card">
          <div class="row">
            <div class="col-sm-6">
              <form-row-input
                [formGroup]="consentForm"
                formControlName="name"
                [required]="consentForm.get('name').errors !== null && consentForm.get('name').errors.required"
                [requiredCssClass]="submitted && formControls.name.errors"
                [icon]="null"
                [label]="'name'"
                [dataCyAttribute]="'name'"
              ></form-row-input>
              <form-row-textarea
                [icon]="null"
                [label]="'text'"
                [(model)]="consent.body"
                [dataCyAttribute]="'text'"
              ></form-row-textarea>
              <form-row-input
                [formGroup]="consentForm"
                formControlName="externalId"
                [icon]="null"
                [label]="'externalId'"
                [dataCyAttribute]="'external-id'"
              ></form-row-input>
            </div>
            <div class="col-sm-6" *ngIf="!isCreating">
              <div class="margin-bottom card-subheading" data-cy="date-created">
                {{ 'created' | translate }} : {{ consent.created | fmtDate: 'dd.MM.yyyy HH:mm' }}
              </div>
              <div class="margin-bottom card-subheading" data-cy="date-edited">
                {{ 'updated' | translate }} : {{ consent.updated | fmtDate: 'dd.MM.yyyy HH:mm' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
