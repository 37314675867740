import { Component, OnDestroy, ViewChild, Input } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { Export, ExportType } from 'depoto-core/src/entities'
import { CoreService } from '../../services'
import { ModalContainerComponent } from '../../components/_common/modal-container/modal-container.component'
import { exportsColumns } from '../../utils/definitions.util'

@Component({
  selector: 'app-exports',
  templateUrl: 'exports.component.html',
})
export class ExportsComponent extends BaseListing implements OnDestroy {
  @ViewChild('modal') childModal: ModalContainerComponent
  @Input() entity: string
  headerTitle: string
  listingColumns: ListingItem[] = exportsColumns
  exports: any[] = []
  sortProp = 'created'
  sortDir = 'desc'
  types: ExportType[] = []
  selectedType: string
  totalItems = 0
  currentPage: number
  endPage: number
  refreshInterval: any = null
  loading = true
  storageKey: { type: string; key: string }

  constructor(protected core: CoreService) {
    super(core)
    this.getStorageKey()
  }

  ngOnDestroy() {
    this.stopRefreshing()
  }

  async getTypes() {
    try {
      const exportTypes = await this.core.services.export
        .getTypes(
          {},
          {
            id: null,
            name: null,
            format: null,
            group: null,
          }
        )
        .then(res => res.items || [])
      this.types = exportTypes.filter(t => t.group === this.entity)
      if (this.types?.length === 1) {
        this.selectedType = this.types[0].id
      }
    } catch (e) {
      console.warn(e)
    }
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.currentPage = pageNo
    const filters: any = {
      group: this.entity,
    }
    if (this.selectedType) {
      filters.types = [this.selectedType]
    }
    const result = await this.core.services.export.getList(
      { page: pageNo, sort: sortProp, direction: sortDir, filters },
      {
        id: null,
        generated: null,
        created: null,
        url: null,
        type: {
          id: null,
          name: null,
          format: null,
          group: null,
        },
        filter: null,
        createdBy: { id: null, name: null },
        note: null,
      }
    )
    if (!result?.items) return
    this.saveViewSettings('exports', {
      ...this.loadViewSettings('exports'),
      [this.entity + '_type']: this.selectedType,
    })
    this.loading = false
    this.reloading = false
    if (!concat) {
      this.exports = result.items.map(ex => new Export(ex))
    } else {
      result.items.forEach(ex => {
        this.exports.push(new Export(ex))
      })
    }
    this.totalItems = result.paginator ? result.paginator.totalCount : 0
    this.endPage = result.paginator ? result.paginator.endPage : 0
    this.currentPage++

    if (!this.childModal.childModal.isShown) {
      // the modal can disappear while the user is scrolling,
      // and the hide-handler will never run. This code must stop refreshing
      this.stopRefreshing()
      return
    }
    const nonGeneratedItems = result.items?.filter(item => !item?.generated)
    if (!nonGeneratedItems.length) {
      this.stopRefreshing()
      if (result.paginator?.endPage > result.paginator?.current) {
        this.startRefreshing('filter', result.paginator?.current + 1)
      }
    }
  }

  download(exportObj: Export) {
    this.core.services.download.downloadDocumentAndShowInNewWindow(exportObj.url)
  }

  async showChildModal(): Promise<void> {
    this.loadSorting('exports')
    this.selectedType = this.loadViewSettings('exports')?.[this.entity + '_type'] || ''
    this.headerTitle = `exports.${this.entity}`
    this.childModal.showChildModal()
    await this.getTypes()
    this.startRefreshing('filter')
    await this.filter()
  }

  onHideChildModal(): void {
    this.stopRefreshing()
  }
}
