<section *ngIf="type === categoryTreeTypeEnum.listCRUD" class="content">
  <div class="card">
    <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
    <ul *ngIf="(!loading && !categories) || (!loading && categories && categories.length === 0)" class="list-unstyled">
      <li class="list-group-item">
        {{ 'no.categories' | translate }}
      </li>
    </ul>
    <ul
      *ngIf="categories && categories.length > 0"
      class="list-unstyled"
      dndDropzone
      dndEffectAllowed="move"
      (dndDrop)="onDrop($event, categories)"
    >
      <li dndPlaceholderRef class="categoriesListDndPlaceholder"></li>
      <category-tree-row
        *ngFor="let category of categories"
        [category]="category"
        [type]="categoryTreeTypeEnum.listCRUD"
        [dndDraggable]="category"
        dndEffectAllowed="move"
        (dndMoved)="onDragMove(category, categories)"
      ></category-tree-row>
    </ul>
  </div>
</section>

<ng-container *ngIf="type !== categoryTreeTypeEnum.listCRUD && categories && categories.length > 0">
  <label *ngIf="!isInBulkEdits && !isForFilterCategories && categoryTreeLabel && categoryTreeLabel.length > 0">{{ categoryTreeLabel | translate }}:</label>

  <ul *ngIf="type === categoryTreeTypeEnum.radioParentCategory" class="list-unstyled">
    <category-tree-row
      *ngFor="let category of categories"
      [category]="category"
      [(editedCategory)]="editedCategory"
      [type]="categoryTreeTypeEnum.radioParentCategory"
    ></category-tree-row>
  </ul>

  <ul *ngIf="type === categoryTreeTypeEnum.radioProductMainCategory" class="list-unstyled">
    <category-tree-row
      *ngFor="let category of categories"
      [category]="category"
      [mainCategory]="mainCategory"
      (mainCategoryChange)="mainCategoryValueChanged($event)"
      [type]="categoryTreeTypeEnum.radioProductMainCategory"
    ></category-tree-row>
  </ul>

  <ul *ngIf="type === categoryTreeTypeEnum.checkboxProductCategories" class="list-unstyled">
    <category-tree-row
      *ngFor="let category of categories"
      [category]="category"
      [selectedCategories]="selectedCategories"
      (selectedCategoriesChange)="categoriesChanged($event)"
      [isInBulkEdits]="isInBulkEdits"
      [isForFilterCategories]="isForFilterCategories"
      [type]="categoryTreeTypeEnum.checkboxProductCategories"
    ></category-tree-row>
  </ul>
</ng-container>
