import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { ModalContainerComponent } from '../components/_common/modal-container/modal-container.component'
import { ProductMove } from 'depoto-core/src/entities'
import { SchemaUtil } from '../utils'
import { CoreService } from '../services'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-modal-serial-numbers',
  templateUrl: './modal-serial-numbers.component.html',
  styleUrls: ['./modal-serial-numbers.component.scss'],
})
export class ModalSerialNumbersComponent {
  @ViewChild('modal') public modal: ModalContainerComponent
  @Input() productMove: ProductMove
  @Input() isCreatingProductMovePack: boolean
  loading = true
  serialNumbersInString: string = ''
  alreadyExistingSerialNumbers: string[] = []
  @Output() serialNumbersEdited: EventEmitter<string[]> = new EventEmitter<string[]>()

  constructor(
    private core: CoreService,
    private translateService: TranslateService
  ) {}

  public showChildModal(productMove: ProductMove = this.productMove): void {
    this.loading = true
    this.productMove = productMove
    this.serialNumbersInString = ''
    this.alreadyExistingSerialNumbers = []
    this.loading = false
    this.modal.showChildModal()
  }

  public hideChildModal(): void {
    this.modal.hideChildModal()
  }

  async setSerialNumbers() {
    if (this.productMove.serialNumbers.length !== this.productMove.amount) {
      alert(
        this.translateService.instant('modal-serial-number.wrong.amount.of.pieces.1') +
          this.productMove.serialNumbers.length +
          this.translateService.instant('modal-serial-number.wrong.amount.of.pieces.2') +
          this.productMove.amount +
          this.translateService.instant('modal-serial-number.wrong.amount.of.pieces.3')
      )
      return
    }
    this.loading = true
    if (!this.isCreatingProductMovePack) {
      try {
        const result = await this.core.services.productMove.update(this.productMove, SchemaUtil.productMove)
        //todo
      } catch (err) {
        console.error(err)
      }
    } else {
      this.serialNumbersEdited.emit(this.productMove.serialNumbers)
    }
    this.hideChildModal()
    this.loading = false
  }

  addSerialNumbers() {
    const serialNumbersInArray = this.serialNumbersInString.split('\n')
    for (const newSerialNumber of serialNumbersInArray) {
      if (
        !this.productMove.serialNumbers?.some(serialNumber => serialNumber === newSerialNumber) &&
        newSerialNumber?.length > 0
      ) {
        this.productMove.serialNumbers.push(newSerialNumber)
      }
    }
    this.serialNumbersInString = ''
  }

  keyPressed(event) {
    console.log(event)
  }

  removeSerialNumber(selectedSerialNumber) {
    this.productMove.serialNumbers = this.productMove.serialNumbers.filter(
      serialNumber => serialNumber !== selectedSerialNumber
    )
  }
}
