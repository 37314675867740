<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="userForm" (ngSubmit)="isCreating ? createUser() : updateUser()" *ngIf="user">
    <page-header
      [icon]="'far-user-cog'"
      [title]="isCreating ? 'user.new' : isUserProfile ? 'user.profile' : 'user.edit'"
      [appendNoTranslationTitlePart]="!isCreating && user ? user.email : ''"
    >
      <ng-container class="header-button" *ngIf="user">
        <btn
          [btnType]="'submit'"
          [classes]="['btn-first', 'float-right']"
          [btnDisabled]="loading"
          [icon]="'far-check'"
          [label]="'save'"
          [title]="'save' | translate"
          [isLoading]="loading"
          spinnerClassesString="spinner-white"
          [dataCyAttribute]="'save-user'"
        ></btn>
        <app-modal-change-pass
          *ngIf="!isCreating && (isUserProfile || isUserPermitted(loggedInUser))"
          [userProfile]="user"
        ></app-modal-change-pass>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <section class="content">
      <div class="card">
        <div class="row">
          <div class="col-sm-6">
            <form-row-select
              [label]="'settings.locale'"
              [options]="availableLanguages"
              [formGroup]="userForm"
              formControlName="locale"
              [cypressSelector]="'locale'"
            >
            </form-row-select>
            <form-row-input
              [label]="'firstname'"
              [formGroup]="userForm"
              formControlName="firstName"
              [icon]="'far-user'"
              [required]="userForm.get('firstName').errors !== null && userForm.get('firstName').errors.required"
              [requiredCssClass]="submitted && formControls.firstName.errors"
              [dataCyAttribute]="'first-name'"
            ></form-row-input>
            <form-row-input
              [label]="'lastname'"
              [formGroup]="userForm"
              formControlName="lastName"
              [required]="userForm.get('lastName').errors !== null && userForm.get('lastName').errors.required"
              [requiredCssClass]="submitted && formControls.lastName.errors"
              [icon]="'far-list'"
              [dataCyAttribute]="'last-name'"
            ></form-row-input>
            <form-row-input
              [label]="'phone'"
              [icon]="'far-phone'"
              [formGroup]="userForm"
              formControlName="phone"
              [dataCyAttribute]="'phone'"
            ></form-row-input>
            <form-row-input
              [label]="'email'"
              [icon]="'far-envelope'"
              [formGroup]="userForm"
              [inputType]="'email'"
              formControlName="email"
              [required]="userForm.get('email').errors !== null && userForm.get('email').errors.required"
              [requiredCssClass]="submitted && formControls.email.errors"
              [dataCyAttribute]="'email'"
            ></form-row-input>
            <div class="flex-gap-0-5" *ngIf="isCreating">
              <!--              todo add to cypress tests -> show/ hide password  and pin-->
              <form-row-input
                class="flex-basis-100"
                [label]="'password'"
                [icon]="'far-lock'"
                [formGroup]="userForm"
                formControlName="plainPassword"
                [required]="
                  userForm.get('plainPassword').errors !== null && userForm.get('plainPassword').errors.required
                "
                [requiredCssClass]="submitted && formControls.plainPassword.errors"
                [inputType]="'text'"
                [dataCyAttribute]="'password'"
              >
              </form-row-input>
              <!--                todo add to trans 'user.generate-password-btn'-->
              <btn
                class="margin-top-1-2"
                (onEvent)="generatePassword()"
                [classes]="['btn-first']"
                [label]="'user.password.generate' | translate"
                [dataCyAttribute]="'modal-customer-search-open'"
              >
              </btn>
            </div>
            <form-row-checkbox
              *ngIf="!isCreating && user?.id !== loggedInUser?.id"
              [label]="'user.is.active'"
              [cursorPointer]="true"
              [formGroup]="userForm"
              formControlName="enabled"
            >
            </form-row-checkbox>
            <div
              class="mt-1"
              *ngIf="
                isCreating || (!isCreating && !isUserProfile) || (!isCreating && isUserProfile && isUserPermitted())
              "
            >
              <label class="mb-0">{{ 'checkouts' | translate }}:</label>
              <div class="mb-2" *ngFor="let checkout of checkouts">
                <form-row-checkbox
                  [label]="checkout.name"
                  [cursorPointer]="true"
                  [model]="hasUserCheckout(user, checkout)"
                  (modelChange)="updateCheckout(checkout, $event)"
                ></form-row-checkbox>
              </div>
            </div>
            <form-row-input
              [label]="'pin'"
              [icon]="'far-lock'"
              [formGroup]="userForm"
              formControlName="pin"
              [inputType]="'text'"
              [dataCyAttribute]="'password'"
            ></form-row-input>
          </div>
          <div
            class="col-sm-6"
            *ngIf="isCreating || (!isCreating && !isUserProfile) || (!isCreating && isUserProfile && isUserPermitted())"
          >
            <label class="mb-0">{{ 'groups' | translate }}:</label>
            <div class="mb-2" *ngFor="let group of groups">
              <div class="flex-gap-0-5" *ngIf="group.roles?.length">
                <form-row-checkbox
                  [label]="group.name"
                  [cursorPointer]="true"
                  [model]="isUserInGroup(user, group)"
                  (modelChange)="updateGroup(group, $event)"
                ></form-row-checkbox>
                <div
                  class="rolling-content-btn align-self-center"
                  (click)="rolesVisibilityChange(group)"
                  (keyup)="rolesVisibilityChange(group)"
                  role="button"
                  tabIndex="0"
                  aria-disabled="true"
                >
                  <div class="flex-gap-5 align-items-center">
                    <i
                      [ngClass]="{ rotate: areGroupRolesVisible(group) }"
                      class="fa fa-chevron-down"
                      data-cy="filter"
                    ></i>
                    <div>
                      {{ (areGroupRolesVisible(group) ? 'group.hide.roles' : 'group.show.roles') | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <span *ngIf="group.roles?.length && areGroupRolesVisible(group)">
                <span class="badge bg-success me-2" *ngFor="let role of group.roles">
                  {{ role }}
                </span>
              </span>
            </div>
            <div
              class="mt-1"
              *ngIf="
                isCreating || (!isCreating && !isUserProfile) || (!isCreating && isUserProfile && isUserPermitted())
              "
            >
              <label class="mb-0">{{ 'depots' | translate }}:</label>
              <div class="mb-2" *ngFor="let depot of depots">
                <form-row-checkbox
                  [label]="depot.name"
                  [cursorPointer]="true"
                  [model]="hasUserDepot(user, depot)"
                  (modelChange)="updateDepot(depot, $event)"
                ></form-row-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
