<form>
  <div class="card">
    <div>
      <h3 class="card-title">{{ 'info' | translate }}</h3>
    </div>
    <div class="table-responsive">
      <table class="table m-0">
        <tbody>
          <tr *ngIf="order.reservationNumber">
            <th class="card-subheading">{{ 'reservation' | translate }}</th>
            <td class="text-end no-hover">{{ order.reservationNumber }}</td>
          </tr>
          <tr>
            <th class="card-subheading">{{ 'externalReference' | translate }}</th>
            <td class="text-end no-hover">
              <form-row-input
                [label]="null"
                [icon]="null"
                [noMarginBottom]="true"
                [model]="order.externalReference"
                (modelChange)="selectedExternalReference($event)"
                [dataCyAttribute]="'order-externalReference'"
              >
              </form-row-input>
            </td>
          </tr>
          <tr *ngIf="order.billNumber && order.billNumber > 0">
            <th class="text-nowrap card-subheading">{{ 'bill' | translate }}</th>
            <td class="text-end no-hover" data-cy="'order-billNumber'">{{ order.billNumber }}</td>
          </tr>
          <tr>
            <th class="card-subheading">{{ 'checkout' | translate }}</th>
            <td class="text-end no-hover" data-cy="order-checkout">
              {{ order.checkout ? order.checkout.name : '' }}
            </td>
          </tr>
          <tr>
            <th class="card-subheading">{{ 'currency' | translate }}</th>
            <td class="text-end no-hover flex-gap-5 justify-content-end" *ngIf="canCreateOrEdit">
              <form-row-select
                [label]="null"
                [icon]="null"
                [noMarginBottom]="true"
                [options]="currencies"
                [bindToObject]="true"
                [model]="order.currency"
                (modelChange)="selectedCurrency($event)"
                [dataCyAttribute]="'order-currency'"
              ></form-row-select>
              <btn
                [title]="'recalculate' | translate"
                [icon]="'far-sync'"
                [classes]="['btn-third', 'btn-sm']"
                (onEvent)="onRecalculatedPricesByPriceLevel()"
              >
              </btn>
            </td>
            <td class="text-end no-hover" *ngIf="!canCreateOrEdit">
              {{ order.currency?.name }}
            </td>
          </tr>
          <tr>
            <th class="card-subheading">{{ 'dateCreated' | translate }}</th>
            <td class="text-end no-hover">
              <!--              <form-row-input-->
              <!--                *ngIf="order?.status?.id !== 'bill'"-->
              <!--                [icon]="'fa-clock'"-->
              <!--                [model]="order.dateCreated | fmtDate: 'yyyy-MM-dd'"-->
              <!--                (modelChange)="selectedDateCreated($event)"-->
              <!--                [inputType]="'Date'"-->
              <!--              ></form-row-input>-->
              <div>
                {{ order.dateCreated | strToDate | date: 'dd.MM.yyyy HH:mm' }}
              </div>
            </td>
          </tr>
          <tr *ngIf="order.status.id === 'bill' && (order.dateBill?.length > 0 || canCreateOrEdit)">
            <th class="card-subheading">{{ 'dateBill' | translate }}</th>
            <td *ngIf="!canCreateOrEdit" class="text-end no-hover">
              {{ order.dateBill | strToDate | date: 'dd.MM.yyyy' }}
            </td>
            <td *ngIf="canCreateOrEdit" class="text-end no-hover">
              <form-row-input
                [icon]="'fa-clock'"
                [model]="order.dateBill | fmtDate: 'yyyy-MM-dd'"
                (modelChange)="selectedDateBill($event)"
                [inputType]="'Date'"
              ></form-row-input>
            </td>
          </tr>
          <tr *ngIf="order.vs > 0">
            <th class="card-subheading">{{ 'vs' | translate }}</th>
            <td class="text-end no-hover">{{ order.vs ? order.vs : '' }}</td>
          </tr>
          <tr *ngIf="order.dateTax">
            <th class="card-subheading">
              {{ 'duzp' | translate }}
            </th>
            <td class="text-end no-hover">
              <form-row-input
                [icon]="'fa-clock'"
                [model]="order.dateTax | fmtDate: 'yyyy-MM-dd'"
                (modelChange)="selectedDateTax($event)"
                [inputType]="'Date'"
              ></form-row-input>
            </td>
          </tr>
          <tr *ngIf="order.dateDue">
            <th class="card-subheading">
              {{ 'dateDue' | translate }}
            </th>
            <td class="text-end no-hover">
              <form-row-input
                [icon]="'fa-clock'"
                [model]="order.dateDue | fmtDate: 'yyyy-MM-dd'"
                (modelChange)="selectedDateDue($event)"
                [inputType]="'Date'"
              ></form-row-input>
            </td>
          </tr>
          <tr>
            <th class="card-subheading">{{ 'createdBy' | translate }}</th>
            <td class="text-end no-hover">
              <a class="link" *ngIf="order.createdBy" [routerLink]="['/user', order.createdBy.id]">{{
                order.createdBy && order.createdBy
                  ? order.createdBy.firstName + ' ' + order.createdBy.lastName + ' (' + order.createdBy.email + ')'
                  : ''
              }}</a>
            </td>
          </tr>
          <tr *ngIf="order.externalId">
            <th class="card-subheading">{{ 'externalId' | translate }}</th>
            <td class="text-end no-hover">
              {{ order.externalId }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
