<div class="app-container">
  <page-header [icon]="'far-user-cog'" [title]="'users'">
    <ng-container class="header-button">
      <btn
        [routerLink]="['/user']"
        [classes]="['btn-first', 'float-right']"
        [icon]="'far-plus'"
        [label]="'user.new'"
        [title]="'user.new' | translate"
        [dataCyAttribute]="'create-user'"
      ></btn>
      <btn
        [routerLink]="['/groups']"
        [classes]="['btn-third', 'float-right', 'me-2']"
        [icon]="'far-users-cog'"
        [label]="'groups'"
        [title]="'groups' | translate"
        [dataCyAttribute]="'user-groups'"
      ></btn>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form
      [filters]="filters"
      [entity]="'users'"
      (onSubmit)="filter()"
      (onClearClick)="clearFilters()"
      [isFilterInvisible]="true"
    >
      <div class="row">
        <div class="col-12 col-sm-4">
          <form-row-input
            [icon]="'fa-user'"
            [label]="'email'"
            (keydown.enter)="filter()"
            [inputType]="'email'"
            [(model)]="filters.email"
            [dataCyAttribute]="'user-email-filter'"
          ></form-row-input>
        </div>
        <div class="col-12 col-sm-4">
          <form-row-select
            [label]="'enabled'"
            [options]="enabledTypes"
            [(model)]="filters.enabled"
            [dataCyAttribute]="'user-enabled-filter'"
          ></form-row-select>
        </div>
        <div class="col-12 col-sm-4">
          <form-row-select-multiple
            [label]="'groups'"
            [options]="groups"
            [(selectedValues)]="filters.groups"
            [dataCyAttribute]="'user-roles-filter'"
          ></form-row-select-multiple>
        </div>
      </div>
    </app-filter-form>

    <app-base-listing
      [listName]="'users'"
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="users"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [(sortProp)]="sortProp"
      [(sortDir)]="sortDir"
      (reloadList)="filter(1)"
      (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
      (callAction)="callAction($event)"
    ></app-base-listing>
  </section>
</div>
