import { Pipe, PipeTransform } from '@angular/core'
import { Product } from 'depoto-core/src/entities'

@Pipe({
  name: 'quantityPurchase',
  pure: false,
})
export class QuantityPurchaseAccordingToDepotPipe implements PipeTransform {
  transform(product: Product, depotId: number): number {
    if (product && depotId) {
      return product.quantities.find(item => item.depot.id === depotId)?.quantityPurchase || 0
    } else {
      return 0
    }
  }
}
