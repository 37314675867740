import { Component, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { CoreService } from '../../services'
import { OAuthSession } from 'depoto-core/src/models'
import { NotificationService } from '../../services'
import { Events } from 'depoto-core/src/utils/EventsUtil'
import { TranslateService } from '@ngx-translate/core'
import { AVAILABLE_LANGUAGES } from '../../configs/languages'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  client_type_options = [
    {
      id: 'prod',
      name: 'produkce',
    },
    {
      id: 'stage',
      name: 'stage',
    },
    {
      id: 'custom',
      name: 'vlastní',
    },
  ]
  loginForm = new FormGroup({
    email: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.email]),
    password: new FormControl({ value: '', disabled: false }, [Validators.required]),
    clientType: new FormControl(
      {
        value: window.location.href.includes('local') || window.location.href.includes('stage') ? 'stage' : 'prod',
        disabled: false,
      },
      [Validators.required]
    ),
    customUrl: new FormControl({
      value: 'http://localhost/tomatom/depoto-server/web/app_dev.php/',
      disabled: false,
    }),
    rememberMe: new FormControl({ value: true, disabled: false }),
  })
  loading = false
  isShowingAdvanced = false
  showPassword = false
  submitted = false
  year = `${new Date().getFullYear()}`

  public readonly availableLanguages = AVAILABLE_LANGUAGES
  public selectedLanguage: string

  constructor(
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {
    this.selectedLanguage = this.translateService.getBrowserLang()
    this.translateService.use(this.selectedLanguage)
  }

  ngOnInit() {
    this.reloadTokens()
  }

  async reloadTokens(): Promise<any> {
    this.loading = true
    if (this.core.services.oauth.isAuthenticated()) {
      if (
        !(
          (!this.core.services.oauth.session.isRememberMe && (await this.core.services.oauth.getAccessToken())) ||
          (this.core.services.oauth.session.isRememberMe && (await this.core.services.oauth.getRefreshToken()))
        )
      ) {
        return new Promise<void>(r => r())
      }
      if (!this.core.services.vat.defaultVat) {
        this.core.services.vat.getDefaultVat()
      }
      setTimeout(() => {
        this.loading = false
        Events.dispatch('init:loading')
      }, 200)
    }
    setTimeout(() => (this.loading = false), 250)
  }

  async login() {
    this.submitted = true
    if (this.loginForm.invalid) {
      return
    }
    const session = new OAuthSession({
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      clientType: this.loginForm.value.clientType,
      isRememberMe: this.loginForm.value.rememberMe,
      clientCustomUri: this.loginForm.value.customUrl,
    })
    this.loading = true
    this.core.services.oauth
      .getToken(session)
      .then(async res => {
        if (res) {
          await this.core.services.user.setCurrentUser(session.email)
          this.core.cleanLocalStorage(session.email, 'login')
          if (!this.core.services.vat.defaultVat) {
            this.core.services.vat.getDefaultVat()
          }
          await this.core.loadLists()
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.notificationService.error(this.translateService.instant('login.error'))
      })
      .finally(() => (this.loading = false))
  }

  setCustomServerUrl(): void {
    this.core.services.storage.set(this.core.services.storage.keys.auth.customServerUrl, this.loginForm.value.customUrl)
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.loginForm.controls
  }

  setLanguage(event: any) {
    this.translateService.use(event)
  }
}
