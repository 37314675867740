<div class="app-container">
  <page-header [icon]="'far-money-check'" [title]="'coupons'">
    <ng-container class="header-button">
      <btn
        [routerLink]="['/voucher']"
        [icon]="'far-plus'"
        [label]="'coupon.create'"
        [classes]="['btn-first', 'float-right']"
        [dataCyAttribute]="'add-voucher'"
      ></btn>
      <app-exports #exportsModal [entity]="'vouchers'"></app-exports>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form
      [filters]="queryFilters"
      [loading]="loading || reloading"
      [entity]="'vouchers'"
      [isExportButtonShown]="true"
      [filterVisible]="filtersExpanded"
      (filterVisibleChange)="filtersExpanded = $event; changeDetector.detectChanges()"
      (onSubmit)="filter()"
      (onExportResult)="filter()"
      (onClearClick)="clearFilters()"
      (onCreateModalHidden)="exportsModal.showChildModal()"
    >
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4">
          <form-row-input
            [(model)]="filters.name"
            [icon]="null"
            [label]="'voucher.name'"
            (keydown.enter)="filter()"
            [dataCyAttribute]="'voucher-name-filter'"
          ></form-row-input>
        </div>
        <div class="col-12 col-sm-6 col-md-4">
          <form-row-input
            [(model)]="filters.code"
            [icon]="null"
            [label]="'voucher.code'"
            (keydown.enter)="filter()"
            [dataCyAttribute]="'voucher-code-filter'"
          ></form-row-input>
        </div>
        <div class="col-12 col-sm-6 col-md-4">
          <form-row-select
            [(model)]="filters.discountType"
            [options]="discountTypeOptions"
            [icon]="null"
            [label]="'voucher.discountType'"
            [dataCyAttribute]="'voucher-discount-type-filter'"
          ></form-row-select>
        </div>
      </div>
      <div class="custom-dropdown" [ngClass]="{ open: filtersExpanded }">
        <div class="row">
          <div class="col-12 col-sm-4 col-md-2">
            <form-row-select
              [(model)]="filters.enabled"
              [options]="enabledOptions"
              [icon]="null"
              [label]="'enabled'"
              [dataCyAttribute]="'voucher-is-active-filter'"
            ></form-row-select>
          </div>
          <div class="col-12 col-sm-4 col-md-2">
            <form-row-select
              [(model)]="filters.isValid"
              [options]="isValidOptions"
              [icon]="null"
              [label]="'voucher.isValid'"
              [dataCyAttribute]="'voucher-is-valid-filter'"
            ></form-row-select>
          </div>
          <div class="col-12 col-sm-4 col-md-2">
            <form-row-select
              [(model)]="filters.isUsed"
              [options]="isUsedOptions"
              [icon]="null"
              [label]="'voucher.isUsed'"
              [dataCyAttribute]="'voucher-is-used-filter'"
            ></form-row-select>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <form-row-input
              [label]="'validDate.from'"
              [icon]="null"
              [inputType]="'date'"
              [(model)]="filters.validFrom"
              [dataCyAttribute]="'voucher-valid-from-filter'"
            ></form-row-input>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <form-row-input
              [label]="'validDate.to'"
              [icon]="null"
              [inputType]="'date'"
              [(model)]="filters.validTo"
              [dataCyAttribute]="'voucher-valid-to-filter'"
            ></form-row-input>
          </div>
        </div>
      </div>
    </app-filter-form>

    <app-base-listing
      [listName]="'vouchers'"
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="vouchers"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [(sortProp)]="sortProp"
      [(sortDir)]="sortDir"
      (reloadList)="filter()"
      (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir)"
      (callAction)="callAction($event)"
    ></app-base-listing>
  </section>
</div>
