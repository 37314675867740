import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { ConsentUtil, SchemaUtil } from '../../utils'
import { historyBack } from '../../utils'
import { Consent, Customer, CustomerConsent } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../services'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent implements OnInit, CheckDataChanges {
  consent: Consent
  isCreating = false
  loading = true
  submitted = false

  consentForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, [Validators.required]),
    externalId: new FormControl({ value: '', disabled: false }, []),
  })

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      const id = +params['id']
      if (isNaN(id)) {
        this.isCreating = true
        this.consent = new Consent()
        this.loading = false
      } else {
        this.refreshData(id)
        this.loading = false
      }
    })
  }

  goBack(): void {
    historyBack()
  }

  public fillFormValues() {
    this.consentForm.setValue({ name: this.consent.name, externalId: this.consent.externalId })
  }

  public getFormValues() {
    this.consent.name = this.consentForm.value.name
    this.consent.externalId = this.consentForm.value.externalId
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  async refreshData(id: number) {
    this.loading = true
    this.consent = await this.core.services.consent.getById(id, SchemaUtil.consent)
    this.fillFormValues()
    this.loading = false
  }

  async createConsent() {
    this.submitted = true
    if (this.consentForm.invalid) {
      return
    }
    this.loading = true
    try {
      this.getFormValues()
      await this.core.services.consent.create(this.consent).then(result => {
        this.notificationService.success(this.translateService.instant('alert.consent.created'))
        this.router.navigate(['/consent/', result.id], { replaceUrl: true })
      })
      this.loading = false
    } catch (e) {
      console.warn(e)
    }
  }

  async updateConsent() {
    this.submitted = true
    if (this.consentForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValues()
      await this.core.services.consent.update(this.consent).then(result => {
        this.notificationService.success(this.translateService.instant('alert.consent.updated'))
        this.refreshData(result.id)
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async deleteConsent() {
    if (!this.consent || !this.consent.id) {
      alert(this.translateService.instant('consent.error.contact-admin'))
      return
    }
    if (!confirm(this.translateService.instant('consent.delete.confirmation', { name: this.consent.name }))) {
      return
    }
    try {
      await this.core.services.consent.delete(this.consent).then(() => {
        this.notificationService.success(this.translateService.instant('alert.consent.deleted'))
        historyBack()
      })
    } catch (err) {
      console.error(err)
    }
  }

  printCustomerConsent(): void {
    if (!this.consent) {
      return
    }
    const customerConsent = new CustomerConsent({
      customer: new Customer(),
      consent: this.consent,
      name: this.consent.name,
      body: this.consent.body,
    })
    ConsentUtil.printCustomerConsent(customerConsent)
  }
  get formControls(): { [key: string]: AbstractControl } {
    return this.consentForm.controls
  }
}
