export const printableStyles = () => `
    .barcode-array-container {
        display: flex;
        flex-wrap: wrap;
    }
    .barcode-array-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 33%;
        height: 5vh;
        overflow: hidden;
        outline: 1px dashed #cccccc;
    }
    .barcode-array-item.one-ticket {
        width: 100%;
        height: auto;
        cursor: auto;
    }
    img.barcode-array-item-img {
        max-width: 90%;
        max-height: 50%;
        align-self: flex-start;
        margin-left: 0.3vh;
    }
    .barcode-array-item-title {
        align-self: flex-end;
        margin-right: 0.8vh;
        margin-bottom: -0.5vh;
        font-size: 1vh;
        z-index: 9999;
    }
    .barcode-array-item-title-left {
        align-self: flex-start;
        margin-left: 0.8vh;
        margin-bottom: -0.5vh;
        font-size: 1vh;
        z-index: 9999;
    }
    .barcode-array-item-bottom-line, .barcode-array-item-bottom-line-soutok {
        display: flex;
        align-self: stretch;
        flex-direction: row;
        margin: -0.6vh 0.8vh;
    }
    .barcode-array-item-ean {
        align-self: flex-start;
        font-size: 1vh;
        width: 65%;
    }
    .barcode-array-item-price {
        align-self: flex-end;
        text-align: right;
        font-size: 1.5vh;
        font-weight: 600;
        width: 35%;
    }

    /* Olicon oneTicket mode: */
    .one-ticket-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin: 0;
    }
    .one-ticket-item-size {
        font-weight: 800;
        font-size: 1.8vh;
        margin: 0 1rem 0 0;
    }
    .one-ticket-item-ean {
        font-size: 0.9vh;
        margin: 0 1rem;
    }
    .one-ticket-item-title {
        margin: 0 0 0 1rem;
    }
    .one-ticket-item-price {
        font-size: 1.4vh;
        font-weight: 600;
        margin: 0 1rem 0 0;
    }
    .before-sell-price {
      text-decoration: line-through;
      margin: 0 2mm 0 auto;
      font-weight: 400;
    }

    .soutok-ticket-title {
        max-height: 2.5rem;
        overflow: hidden;
    }

    @media print {
        @page {
            size: auto;
            margin: 0mm 0mm 0mm 0mm;
        }
        html {
            margin: 0;
            /* ugly hack to print just first page, todo: find better solution */
            position: fixed;
        }
        * {
            color: black!important;
            border: 0!important;
            box-shadow: none!important;
        }
        .content-wrapper.print-no-margin { margin-left: 0 !important; min-height: 0 !important; }
        .content { padding: 0 !important; }
        .no-print {
            display: none!important;
        }
        .barcode-array-container {
            border-bottom: 5px solid black;
        }


        .box-default, .box{
            border-top: none;
            /*margin: -5mm;*/
        }
        .barcode-array-container-margin {
            outline: none;
            padding: 0;
        }
        .barcode-array-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 64mm;
            height: 21mm;
            outline: none;
        }
        .barcode-array-item-img {
            margin-left: 4mm;
        }
        .barcode-array-item-title {
            font-size: 3.5mm;
            margin-left: 4mm;
            margin-bottom: -2mm;
        }
        .barcode-array-item-title-left {
            font-size: 3.5mm;
            margin-left: 2mm;
            margin-bottom: -2mm;
        }
        .barcode-array-item-bottom-line {
            margin: -2.5mm 4mm;
        }
        .barcode-array-item-bottom-line-soutok {
            margin: -2.5mm 3.5mm -2.5mm 2mm;
        }
        .barcode-array-item-ean {
            font-size: 3.5mm;
        }
        .barcode-array-item-price {
            font-size: 3.5mm;
            font-weight: 600;
            margin-right: 2mm;
        }

        /* Olicon oneTicket mode: */
        .barcode-array-item.one-ticket {
            width: 50mm;
            height: 30mm;
            margin-top: -3mm;
            margin-left: -2.5mm;

        }
        .one-ticket-item-size {
            font-weight: 800;
            font-size: 6.5mm;
            margin: 0 1.5mm 0 0;
        }
        .one-ticket-item-img {
            width: 37mm;
            height: auto;
            margin: -2mm 0;
        }
        .one-ticket-item-ean {
            font-size: 4mm;
            margin: 0 1.5mm;
        }
        .one-ticket-item-title {
            font-size: 3mm;
            margin: -0.5mm 0 1.5mm 1.5mm;
            z-index: 99999;
        }
        .one-ticket-item-price {
            font-size: 5mm;
            font-weight: 600;
            margin: 0 1.5mm 0 0;
        }
        .before-sell-price {
          text-decoration: line-through;
          margin: 0 2mm 0 auto;
          font-weight: 400;
          font-size: 4mm;
        }

        .soutok-ticket-title {
            max-height: 8mm;
            overflow: hidden;
        }
    }
`

export const ticketPrintableStyles = (forceOnePage: boolean = false) => `
  .print-label-outline {
    box-sizing: content-box;
    outline: none;
    overflow: hidden;
    background-color: #fff;
  }
  .print-label-container {
    //overflow: hidden;
    background-color: #fff;
  }
  .print-label-container-qrcode {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
  }
  .print-label-container-barcode {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
  .print-2nd-col {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: stretch;
    justify-content: space-between;
    z-index: 99;
  }
  .print-2nd-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .bar-code {
    width: 100%;
    height: auto;
    margin-top: -2.5mm;
    margin-bottom: -2.5mm;
    scale: 1.05;
  }
  .qr-code {
    height: 100%;
    width: auto;
    scale: 1.25;
  }
  .font-weight-bold {
    font-weight: 700 !important;
  }
  .text-end {
    text-align: right !important;
  }
  .multiTicketContainer {
    display: flex;
    flex-wrap: wrap;
    width: 210mm;
    //scale: 0.5;
  }
  .multiTicketToLabelContainer {
    display: flex;
    flex-wrap: wrap;
    // width: 210mm;
    //scale: 0.5;
  }
  .page-break {
    page-break-after: always;
    break-after: always;
  }

  @media print {
    @page {
      size: auto;
      margin: 0mm 0mm 0mm 0mm;
    }
    html {
      margin: 0;
      /* ugly hack to print just first page, todo: find better solution */
      ${forceOnePage ? 'position: fixed;' : ''}
    }
    * {
      color: black!important;
      border: 0!important;
      box-shadow: none!important;
      font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 1.5;
    }
    .page-break {
      page-break-after: always;
      break-after: always;
    }

    img {
      image-rendering: pixelated;
    }
    .print-label-container {
      overflow: hidden;
    }
  }
`
