import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core'
import { Package, PackageStatus, OrderProcessStatus } from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'

@Component({
  selector: 'modal-process-status',
  templateUrl: './modal-process-status.component.html',
  styleUrls: ['modal-process-status.component.scss'],
})
export class ModalProcessStatusComponent implements OnInit, OnChanges {
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Input() public processStates: OrderProcessStatus[] = []
  @Input() public packageStates: PackageStatus[] = []
  @Input() public package: Package
  @Output() processStatusChange: EventEmitter<any> = new EventEmitter()
  lastProcessStatus: OrderProcessStatus
  lastPackageStatus: PackageStatus
  headerTitle: string
  translateTitle: boolean
  changeProcessStatus: boolean
  note: string
  returnToExpeditionInProcess: boolean

  ngOnInit() {
    this.setValues()
  }

  setValues() {
    if (this.processStates && this.processStates.length > 0) {
      this.lastProcessStatus = this.processStates[this.processStates.length - 1]
    }
    if (this.packageStates && this.packageStates.length > 0) {
      this.lastPackageStatus = this.packageStates[this.packageStates.length - 1]
    }

    if (this.package) {
      this.headerTitle = `Stavy balíku ${this.package.code} u dopravce ${
        this.package.carrier ? this.package.carrier.name : ''
      }`
      this.translateTitle = false
    } else if (!this.package && !this.lastProcessStatus) {
      this.headerTitle = `Stavy balíku, procesní stavy`
      this.translateTitle = false
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setValues()
  }

  public showChildModal(event?: string): void {
    if (!this.changeProcessStatus) this.note = ''
    if (event === 'returnToExpedition') {
      this.changeProcessStatus = true
      this.headerTitle = 'Přenastavit stav'
    }
    this.childModal.showChildModal()
  }

  public hideChildModal(): void {
    this.childModal.hideChildModal()
  }

  returnToExpedition() {
    this.returnToExpeditionInProcess = true
    this.changeProcessStatus = true
    this.processStatusChange.emit(this.note)
    this.lastProcessStatus.status.id = 'recieved'
    this.lastProcessStatus.status.name = 'Přijatá'
    this.lastProcessStatus.note = this.note
    this.setValues()
    this.hideChildModal()
  }
}
