import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Role, UserGroup } from 'depoto-core/src/entities'
import { SchemaUtil, historyBack } from '../../utils'
import { CoreService, NotificationService } from '../../services'
import { TranslateService } from '@ngx-translate/core'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { FormControl, FormGroup } from '@angular/forms'

@Component({
  selector: 'app-usergroup',
  templateUrl: './usergroup.component.html',
})
export class UserGroupComponent implements OnInit, CheckDataChanges {
  group: UserGroup
  roles: Role[] = []
  isCreating = false
  loading = true
  error = false
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private notificationService: NotificationService,
    private core: CoreService,
    private translateService: TranslateService
  ) {}
  groupForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, []),
    roles: new FormControl({ value: [], disabled: false }, []),
  })

  async ngOnInit() {
    const id = this.route.snapshot.params['id']
    if (isNaN(id)) {
      this.isCreating = true
      this.group = new UserGroup()
      this.loading = false
      this.refreshRolesOnly()
    } else {
      this.refreshData(id)
    }
  }

  fillFormValue() {
    this.groupForm.setValue({
      name: this.group.name || '',
      roles: this.group.roles || [],
    })
  }

  getFormValue() {
    this.group.name = this.groupForm.value.name
    this.group.roles = this.groupForm.value.roles
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  async refreshData(id: number) {
    this.loading = true
    try {
      this.group = await this.core.services.userGroup.getById(id, SchemaUtil.userGroup)
      this.roles = await this.core.services.userRole
        .getList({}, { name: null, role: null })
        .then(roles => roles.map(role => new Role(role)))
      this.fillFormValue()
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async refreshRolesOnly() {
    this.loading = true
    const roles = await this.core.services.userRole.getList({}, { name: null, role: null })
    this.loading = false
    roles.forEach(role => {
      this.roles.push(new Role(role))
    })
  }

  goBack(): void {
    historyBack()
  }

  async createGroup() {
    if (this.groupForm.value.name.length < 3) {
      this.notificationService.warning(this.translateService.instant('user-group.length.warning'))
      return
    } else if (this.groupForm.invalid) return
    this.loading = true
    this.getFormValue()
    const result = await this.core.services.userGroup.create(this.group)
    if (result) {
      this.notificationService.success(this.translateService.instant('alert.usergroup.created'))
      this.router.navigate(['/group/', result.id], { replaceUrl: true })
    }
    this.loading = false
  }

  async updateGroup() {
    if (this.group.name.length < 3) {
      this.notificationService.warning(this.translateService.instant('user-group.length.warning'))
      return
    }
    try {
      this.getFormValue()
      await this.core.services.userGroup.update(this.group).then(result => {
        this.notificationService.success(this.translateService.instant('alert.usergroup.updated'))
        this.refreshData(result.id)
      })
    } catch (e) {
      this.notificationService.error(this.translateService.instant('alert.usergroup.error'))
    }
  }

  async deleteGroup() {
    const confirmation = confirm(
      this.translateService.instant('user-group.delete.confirmation', { name: this.group.name })
    )
    if (confirmation) {
      await this.core.services.userGroup.delete(this.group).then(() => {
        this.notificationService.success(this.translateService.instant('alert.usergroup.deleted'))
        historyBack()
      })
    }
  }

  updateRole(role: string, isEnabled: boolean) {
    if (isEnabled) {
      this.groupForm.value.roles.push(role)
    } else {
      this.groupForm.value.roles.forEach((r: string, i: number) => {
        if (r === role) {
          this.groupForm.value.roles.splice(i, 1)
        }
      })
    }
  }
}
