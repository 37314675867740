import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'

import { CoreService } from '../../../../services'

import { Product, OrderItem, Customer, Checkout, File, Currency, Category } from 'depoto-core/src/entities'

import { DateUtil } from 'depoto-core/src/utils'
import { SchemaUtil } from 'src/app/utils'
import { ModalContainerComponent } from 'src/app/components/_common/modal-container/modal-container.component'
import {
  ModalStockOperationCardPurchaseComponent,
  PurchaseOrderItemPlain as PurchaseOrderItem,
} from '../modal-stock-operation-card-purchase/modal-stock-operation-card-purchase.component'

@Component({
  selector: 'modal-product-choose-purchase',
  templateUrl: 'modal-purchase-choose-product.component.html',
  styleUrls: ['modal-purchase-choose-product.component.scss'],
})
export class ModalPurchaseChooseProductComponent implements OnInit {
  @ViewChild(ModalStockOperationCardPurchaseComponent) modalStockOperation: ModalStockOperationCardPurchaseComponent
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Input() public checkout: Checkout
  @Input() public customer: Customer
  @Input() currency: Currency
  @Input() currencyDate: string
  @Output() public chosenProduct: EventEmitter<PurchaseOrderItem> = new EventEmitter()
  @Output() public chosenOrderItem: EventEmitter<any> = new EventEmitter()
  searchValue = ''
  products: Product[] = []
  availabilityTypes: string[] = ['all', 'available', 'reservation', 'stock']
  selectedAvailability = 'all'
  selectedCategories: Category[] = []
  pageNumber = 1
  maxPageNumber = 999
  loading = false
  hasSpecialAddOrderItemsRole = false
  isOpen = false
  intersectionObserver = null
  scrollDebounceTimeout = null

  constructor(private core: CoreService) {}

  ngOnInit() {
    this.hasSpecialAddOrderItemsRole = this.core.services.user.hasRole('ROLE_ORDER_SELLITEMS')
  }

  public async showModalForProductSelection(product: Product) {
    this.modalStockOperation.showProductOnChildModal(
      (({ purchasePrice, currency }) => ({
        product: product.id,
        quantity: 1,
        purchasePrice,
        currency: product.purchaseCurrency,
        fullName: product.fullName,
        mainImage: product.mainImage,
        code: product.code,
        ean: product.ean,
      }))(product)
    )
  }

  async searchForProduct(addPage?: number) {
    if (addPage) {
      this.pageNumber += addPage
    } else {
      this.pageNumber = 1
    }
    this.loading = true
    const products = await this.core.services.product
      .getList(
        {
          currency: this.currency?.id,
          currencyDate: DateUtil.getDateStringFromUTCString(this.currencyDate),
          filters: {
            fulltext: this.searchValue.trim(),
            listType: 'children_and_parents_without_children',
            availability: this.selectedAvailability,
            categories: this.selectedCategories?.length > 0 ? this.selectedCategories?.map(cat => cat.id) : [],
            enabled: true,
          },
        },
        SchemaUtil.productPurchaseOrder
      )
      .then(result => {
        this.maxPageNumber = result.paginator.endPage
        return result.items.map(p => new Product(p))
      })
    if (this.pageNumber === 1) {
      this.products = products
    } else {
      this.products = [...this.products, ...products]
    }
    this.loading = false
    setTimeout(() => this.initIntersectionObserver(), 300)
  }

  public showChildModal(): void {
    this.searchForProduct()
    this.childModal.showChildModal()
  }

  public chosenProductDepot(orderItem: OrderItem) {
    this.chosenOrderItem.emit(orderItem)
  }

  getThumbUrl(image: File): string {
    return image.thumbnails.filter(t => t.format === 'w135wp')[0].url
  }

  updateQuantityFromPacks(product, pack, quantity) {
    pack['quantityTmp'] = Number(quantity)
    product.orderQuantity = product.packs
      .map(p => (p['quantityTmp'] || 0) * p.quantity)
      .reduce((a, b) => Number(a) + Number(b))
  }

  initIntersectionObserver() {
    const el = document.getElementById('next-page-btn')
    if (el) {
      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(el)
      }
      this.intersectionObserver = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && !this.loading && !this.scrollDebounceTimeout) {
            this.searchForProduct(1)
            this.scrollDebounceTimeout = setTimeout(() => {
              this.scrollDebounceTimeout = null
            }, 700)
          }
        },
        { threshold: [0] }
      )
      this.intersectionObserver.observe(el)
    }
  }

  chooseProduct($event: PurchaseOrderItem) {
    this.chosenProduct.emit($event)
  }
}
