<modal-container
  #modal
  [isExtraLargeModal]="true"
  [headerIconClass]="'far-edit'"
  [headerTitle]="customer ? 'customer.change' : 'customer.add'"
  class="customer-search-modal"
>
  <ng-container class="modal-button-show">
    <form-row-input
      *ngIf="isOnOrdersPage"
      [label]="'customer'"
      [icon]="null"
      [model]="customer ? customer.firstName + ' ' + customer.lastName : ''"
      [inputDisabled]="true"
      [dataCyAttribute]="'customer-input'"
    >
      <button
        *ngIf="isOnOrdersPage && customer"
        (click)="removeCustomer()"
        [title]="'clear' | translate"
        class="btn-clear"
        [attr.data-cy]="'remove-customer-btn'"
      >
        ×
      </button>
      <btn
        *ngIf="isOnOrdersPage"
        (onEvent)="showChildModal()"
        [icon]="'far-pencil'"
        [classes]="['btn-first']"
        [title]="'customer' | translate"
        [dataCyAttribute]="'modal-customer-search-open'"
      >
      </btn>
    </form-row-input>
    <btn
      *ngIf="!isOnOrdersPage"
      (onEvent)="showChildModal()"
      [label]="customer ? 'customer.change' : 'customer.add'"
      [icon]="'fa-edit'"
      [classes]="['btn-second', 'btn-sm', 'float-right']"
      [dataCyAttribute]="'add-customer-modal-open'"
    >
    </btn>
  </ng-container>
  <ng-container class="modal-body-content">
    <customer-search
      #customerSearch
      [isInModal]="true"
      [isOnOrdersPage]="true"
      (customerChange)="onSelectedCustomer($event)"
    ></customer-search>
  </ng-container>
</modal-container>
