<div class="flex-gap-5">
  <ng-template #orderPreviewTemplate>
    <div class="container-fluid popover-max-width" data-cy="order-preview-popover">
      <div class="row">
        <div class="col popover-table">
          <div class="row" *ngIf="orderDetails?.reservationNumber">
            <div class="col">
              <div class="text-nowrap card-title">
                {{ 'reservation' | translate }}: {{ orderDetails.reservationNumber }}
              </div>
            </div>
          </div>
          <table class="table">
            <tbody class="images" [photoGalleryGroup]="{ index: 1 }">
              <ng-template ngFor let-it [ngForOf]="orderDetails?.items">
                <tr>
                  <td class="ps-0 no-hover">
                    <div class="flex-gap-10 align-items-center">
                      <div
                        [photoGallery]="it?.product?.mainImage?.url"
                        [photoGalleryCaption]="
                          it.product
                            ? it.product.fullName +
                              '<br>' +
                              (it.product.code ? ('product.code' | translate) + it.product.code : '') +
                              '<br>' +
                              (it.product.ean ? ' EAN: ' + it.product.ean : '')
                            : ''
                        "
                        *ngIf="it.type === 'product' && it.product && it.product.id > 0"
                      >
                        <div class="images-item">
                          <img
                            class="images-item-image"
                            [src]="getThumbUrl(it.product.mainImage)"
                            [alt]="it.product.fullName"
                          />
                        </div>
                      </div>
                      <div>
                        <a
                          class="link-bold"
                          *ngIf="it.type === 'product' && it.product && it.product.id > 0"
                          [routerLink]="['/product', it.product.id]"
                          data-cy="orderItem-link"
                        >
                          <span *ngIf="it.name">{{ it.name }}</span>
                        </a>
                        <span *ngIf="!(it.type === 'product' && it.product && it.product.id > 0)">
                          <span *ngIf="it.name">{{ it.name }}</span>
                        </span>
                        <div class="product-details flex-gap-5 flex-wrap">
                          <div *ngIf="it.code">{{ 'product.code' | translate }}: {{ it.code }}</div>
                          <div *ngIf="it.ean">{{ 'product.ean' | translate }}: {{ it.ean }}</div>
                          <div
                            *ngIf="it.clearanceItems && it.clearanceItems.length && it.clearanceItems[0].expirationDate"
                          >
                            {{ 'product.expirationDate' | translate }}: {{ it.clearanceItems[0].expirationDate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-end text-nowrap pe-0 no-hover">
                    <div style="display: inline-flex">
                      <div class="unavailable-items" title="{{ 'quantityStock' | translate }}">
                        <span
                          *ngIf="it.quantityUnavailable > 0"
                          title="{{ 'quantityUnavailable' | translate }}"
                          class="badge bg-red"
                          id="unavailable-pieces"
                        >
                          {{ it.quantityUnavailable }}
                        </span>
                      </div>
                      <div class="table-status" title="{{ 'numberOfItems' | translate }}">
                        {{ it.quantity }}
                      </div>
                    </div>
                    <div class="product-price">
                      {{ it.price | formatPrice: orderDetails.currency }}
                    </div>
                  </td>
                </tr></ng-template
              >
              <tr>
                <td class="table-sum ps-0 no-hover">
                  {{ 'price.total' | translate }}
                </td>
                <td class="text-nowrap pe-0 text-end table-sum no-hover">
                  {{ orderDetails?.priceAll | formatPrice: orderDetails?.currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col popover-info max-width-50percent">
          <div class="row">
            <div class="col-12 ps-0 pe-0 d-flex justify-content-end">
              <btn
                [label]="'close'"
                [title]="'close' | translate"
                [icon]="'far-ban'"
                [classes]="['btn-third', 'btn-sm', 'me-2']"
                (click)="closePopover()"
                [dataCyAttribute]="'close-popover'"
              ></btn>
              <a
                class="btn-first btn-sm d-flex align-items-center"
                [routerLink]="['/order', order.id]"
                data-cy="open-order-from-popover"
              >
                <span class="flex-gap-5 align-items-center"><i class="far fa-pen"></i>{{ 'open' | translate }}</span>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1O ps-0 pe-0">
              <div class="order-card">
                <div class="table-responsive">
                  <table class="w-100">
                    <tbody>
                      <tr *ngIf="orderDetails?.processStatus">
                        <td class="td-left no-hover">{{ 'processStatus' | translate }}:</td>
                        <td class="text-end no-hover pe-1">
                          <span
                            class="inline-flex-gap-5 align-items-center"
                            [ngClass]="'process-status-' + orderDetails.processStatus.id"
                          >
                            <i
                              [ngClass]="
                                orderDetails.processStatus.id === 'recieved'
                                  ? 'far fa-cart-arrow-down'
                                  : orderDetails.processStatus.id === 'picking'
                                    ? 'far fa-scanner'
                                    : orderDetails.processStatus.id === 'packing'
                                      ? 'far fa-box-full'
                                      : orderDetails.processStatus.id === 'packed'
                                        ? 'far fa-box-check'
                                        : orderDetails.processStatus.id === 'dispatched'
                                          ? 'far fa-shipping-fast'
                                          : orderDetails.processStatus.id === 'delivered'
                                            ? 'far fa-check-circle'
                                            : orderDetails.processStatus.id === 'returned'
                                              ? 'far fa-shipping-timed'
                                              : orderDetails.processStatus.id === 'picking_error'
                                                ? 'far fa-exclamation-triangle'
                                                : orderDetails.processStatus.id === 'cancelled'
                                                  ? 'far fa-ban'
                                                  : ''
                              "
                            >
                            </i>
                            <span data-cy="orderPreview-processStatus">{{ orderDetails.processStatus?.name }}</span>
                          </span>
                        </td>
                      </tr>
                      <tr *ngIf="orderDetails?.status">
                        <td class="td-left no-hover">{{ 'order.status' | translate }}:</td>
                        <td class="td-right no-hover" data-cy="orderPreview-status">
                          {{ 'order-' + orderDetails.status.id | translate }}
                        </td>
                      </tr>
                      <tr *ngIf="orderDetails?.externalReference">
                        <td class="td-left no-hover">{{ 'externalReference' | translate }}:</td>
                        <td class="td-right no-hover text-wrap" data-cy="orderPreview-externalReference">
                          {{ orderDetails.externalReference }}
                        </td>
                      </tr>
                      <tr *ngIf="orderDetails?.carrier">
                        <td class="td-left no-hover">{{ 'shipping' | translate }}:</td>
                        <td class="td-right no-hover" data-cy="orderPreview-carrier">
                          {{ orderDetails.carrier.name ? orderDetails.carrier.name : '' }}
                        </td>
                      </tr>
                      <tr *ngIf="orderDetails?.paymentItems.length">
                        <td class="td-left no-hover">{{ 'payment.form' | translate }}:</td>
                        <td class="td-right no-hover">
                          <span *ngFor="let paymentItem of orderDetails.paymentItems">
                            <span class="ps-2" *ngIf="paymentItem.payment?.type?.name" data-cy="orderPreview-payment">
                              {{ paymentItem.payment.type.name }}</span
                            >
                          </span>
                        </td>
                      </tr>
                      <tr *ngIf="orderDetails?.checkout?.name">
                        <td class="td-left no-hover">{{ 'cashdesk' | translate }}:</td>
                        <td class="td-right no-hover" data-cy="orderPreview-checkout">
                          {{ orderDetails.checkout.name }}
                        </td>
                      </tr>
                      <tr *ngIf="orderDetails?.dateCreated">
                        <td class="td-left no-hover">{{ 'orders.stock.created' | translate }}:</td>
                        <td class="td-right text-nowrap no-hover" data-cy="orderPreview-dateCreated">
                          {{ orderDetails.dateCreated | fmtDate: 'dd.MM.yyyy' }}
                          {{ orderDetails.dateCreated | fmtDate: 'HH:mm' }}
                        </td>
                      </tr>
                      <tr *ngIf="orderDetails?.createdBy?.lastName">
                        <td class="td-left no-hover">{{ 'createdBy' | translate }}:</td>
                        <td class="td-right no-hover" data-cy="orderPreview-createdBy">
                          {{ orderDetails.createdBy.lastName }}
                        </td>
                      </tr>
                      <tr *ngIf="orderDetails?.externalId">
                        <td class="td-left no-hover">{{ 'externalId' | translate }}:</td>
                        <td class="td-right no-hover">
                          {{ orderDetails.externalId }}
                        </td>
                      </tr>
                      <tr *ngIf="orderDetails?.priority">
                        <td class="td-left no-hover">{{ 'priority' | translate }}:</td>
                        <td class="td-right no-hover" data-cy="orderPreview-priority">
                          {{ orderDetails.priority }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="orderDetails?.note?.length">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1O shipping-card">
              <div class="card-subheading text-nowrap">
                {{ 'note' | translate }}
              </div>
              <div data-cy="orderPreview-note">
                {{ orderDetails.note }}
              </div>
            </div>
          </div>
          <div class="row" *ngIf="orderDetails?.privateNote?.length">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1O shipping-card">
              <div class="card-subheading text-nowrap">
                {{ 'note.private' | translate }}
              </div>
              <div data-cy="orderPreview-privateNote">
                {{ orderDetails.privateNote }}
              </div>
            </div>
          </div>
          <div class="row" *ngIf="orderDetails?.invoiceAddress && orderDetails?.shippingAddress && !areAddressesSame">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1O shipping-card">
              <div class="row">
                <div class="col d-flex justify-content-between">
                  <div class="avatar-wrapper">
                    <div>
                      <div class="circle">
                        <span class="initials">
                          {{
                            orderDetails.invoiceAddress.firstName?.length
                              ? orderDetails.invoiceAddress.firstName[0]
                              : ''
                          }}
                          {{
                            orderDetails.invoiceAddress.lastName?.length ? orderDetails.invoiceAddress.lastName[0] : ''
                          }}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>{{ orderDetails.invoiceAddress.firstName }}</div>
                      <div class="last-name">{{ orderDetails.invoiceAddress.lastName }}</div>
                    </div>
                  </div>
                  <div class="shipping-card-details">
                    {{ 'address.invoice' | translate }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="row" *ngIf="orderDetails.invoiceAddress.email">
                    <div class="col shipping-card-details">
                      <span><i class="far fa-envelope"></i> {{ orderDetails.invoiceAddress.email }}</span>
                    </div>
                  </div>
                  <div class="row" *ngIf="orderDetails.invoiceAddress.phone">
                    <div class="col shipping-card-details">
                      <span><i class="far fa-phone-alt"></i> {{ orderDetails.invoiceAddress.phone }}</span>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      orderDetails.invoiceAddress.street &&
                      orderDetails.invoiceAddress.zip &&
                      orderDetails.invoiceAddress.city
                    "
                  >
                    <div class="col shipping-card-details">
                      <span>
                        <i class="far fa-map-marker"></i>
                        {{ orderDetails.invoiceAddress.street }}, {{ orderDetails.invoiceAddress.zip }},
                        {{ orderDetails.invoiceAddress.city }}
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col shipping-card-details">
                      <span *ngIf="orderDetails?.invoiceAddress?.ic">
                        {{ 'company.ic' | translate }}
                        {{ orderDetails?.invoiceAddress?.ic }}
                      </span>
                      <span *ngIf="orderDetails?.invoiceAddress?.dic">
                        {{ 'company.dic' | translate }}
                        {{ orderDetails?.invoiceAddress?.dic }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="orderDetails?.shippingAddress">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1O shipping-card">
              <div class="row">
                <div
                  class="col"
                  [ngClass]="orderDetails?.invoiceAddress && !areAddressesSame ? 'd-flex justify-content-between' : ''"
                >
                  <div class="avatar-wrapper">
                    <div>
                      <div class="circle">
                        <span class="initials">
                          {{
                            orderDetails.shippingAddress.firstName?.length
                              ? orderDetails.shippingAddress.firstName[0]
                              : ''
                          }}
                          {{
                            orderDetails.shippingAddress.lastName?.length
                              ? orderDetails.shippingAddress.lastName[0]
                              : ''
                          }}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>{{ orderDetails.shippingAddress.firstName }}</div>
                      <div class="last-name">{{ orderDetails.shippingAddress.lastName }}</div>
                    </div>
                  </div>
                  <div class="shipping-card-details" *ngIf="orderDetails.invoiceAddress && !areAddressesSame">
                    {{ 'address.shipping' | translate }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="row" *ngIf="orderDetails.shippingAddress.email">
                    <div class="col shipping-card-details">
                      <span><i class="far fa-envelope"></i> {{ orderDetails.shippingAddress.email }}</span>
                    </div>
                  </div>
                  <div class="row" *ngIf="orderDetails.shippingAddress.phone">
                    <div class="col shipping-card-details">
                      <span><i class="far fa-phone-alt"></i> {{ orderDetails.shippingAddress.phone }}</span>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      orderDetails.shippingAddress.street &&
                      orderDetails.shippingAddress.zip &&
                      orderDetails.shippingAddress.city
                    "
                  >
                    <div class="col shipping-card-details">
                      <span>
                        <i class="far fa-map-marker"></i>
                        {{ orderDetails.shippingAddress.street }}, {{ orderDetails.shippingAddress.zip }},
                        {{ orderDetails.shippingAddress.city }}
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col shipping-card-details">
                      <span *ngIf="orderDetails.shippingAddress.ic"
                        >{{ 'company.ic' | translate }} {{ orderDetails.shippingAddress.ic }}</span
                      >
                      <span *ngIf="orderDetails.shippingAddress.dic">
                        | {{ 'company.dic' | translate }} {{ orderDetails.shippingAddress.dic }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <button
    type="button"
    class="btn border-0 button-preview p-0 btn-outline-primary"
    [ngClass]="isOnProductMovePackPage ? 'btn-outline-primary-white-background' : ''"
    [popover]="isOpen ? orderPreviewTemplate : ''"
    [placement]="'right top'"
    container="div.wrapper"
    [isOpen]="isOpen"
    [adaptivePosition]="!isOnProductMovePackPage"
    #pop="bs-popover"
    [title]="'order.preview' | translate"
    (click)="fetchOrderDetail(order.id)"
    triggers="'focus'"
    [attr.data-cy]="'order-preview'"
  >
    <div class="icons-order-preview">
      <i class="far fa-eye text-center eye-icon" *ngIf="!isFetching"> </i>
      <i class="far fa-comment-lines note-icon" [title]="'note' | translate" *ngIf="order?.note?.length && !isFetching">
      </i>
      <i
        class="fas fa-comment-lines private-note-icon"
        [title]="'note.private' | translate"
        *ngIf="order?.privateNote?.length && !isFetching"
      >
      </i>
    </div>
    <div class="spinner-border spinner-border-sm text-primary" role="status" *ngIf="isFetching">
      <span class="sr-only">Loading...</span>
    </div>
  </button>
  <span *ngIf="!isOnOrdersPage" class="align-self-center">
    <a class="link" [routerLink]="['/order', order.id]">
      {{ order?.reservationNumber ? order.reservationNumber : order?.billNumber }}
    </a>
  </span>
</div>
