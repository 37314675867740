import { Component, ViewChild, Input, Output, EventEmitter, OnInit } from '@angular/core'
import {
  Depot,
  Supplier,
  Product,
  OrderItem,
  Customer,
  SellItem,
  Checkout,
  ProductDepot,
} from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { ProductPriceLevelUtil } from 'depoto-core/src/utils/ProductPriceLevelUtil'
import { CoreService } from '../../../services'
import { SchemaUtil } from '../../../utils'

export type StockItem = {
  depot: Depot
  productDepots: ProductDepot[]
  sumAll: number
  sumReservations: number
  sumAvailable: number
  sumPurchase: number
}
@Component({
  selector: 'modal-stock-overview',
  templateUrl: 'modal-stock-overview.component.html',
  styleUrls: ['modal-stock-overview.component.scss'],
})
export class ModalStockOverviewComponent implements OnInit {
  @ViewChild('modalStock') public childModal: ModalContainerComponent
  @Input() public product: Product
  @Input() public checkout: Checkout
  @Input() public depots: Depot[]
  @Input() public suppliers: Supplier[]
  @Input() public filterDepotIdFrom: number
  @Input() public filterDepots: number[] = []
  @Input() public filterSuppliers: number[] = []
  @Input() public isSmallBtn = false
  @Input() public isSumCalculated = false // only on batch stock load, product list..
  @Input() public isCalculatedOfProductDepots = false
  @Input() public isFullWidth = false
  @Input() public isSpecialAddOrderItemsInstance = false
  @Input() public customer: Customer
  @Output() public onSelectedProductDepot: EventEmitter<any> = new EventEmitter()
  @Output() public totalQuantityStockChange: EventEmitter<any> = new EventEmitter()
  @Input() public showPurchaseQuantity = false
  stock: any[] = []
  productPrice = 0
  sellItems: SellItem[] = []
  totalQuantityStock = 0
  totalQuantityReservation = 0
  totalQuantityPurchase = 0
  totalQuantityAvailable = 0
  isLoading = false

  constructor(private core: CoreService) {}

  // async ngOnChanges(simpleChanges) {
  //   if (this.isSpecialAddOrderItemsInstance && this.childModal && this.childModal.isShown()) {
  //     this.getQuantities()
  //   }
  //   if (simpleChanges?.filterDepots?.currentValue !== simpleChanges?.filterDepots?.previousValue) {
  //     this.getQuantities()
  //   }
  //   if (simpleChanges?.filterDepotIdFrom?.currentValue !== simpleChanges?.filterDepotIdFrom?.previousValue) {
  //     this.getQuantities()
  //   }
  // }

  ngOnInit() {
    this.productPrice = ProductPriceLevelUtil.getProductPriceForCustomerPriceLevel(this.product, this.customer)

    if (this.checkout?.id) {
      this.filterDepots = this.checkout.depots.map(d => d.id)
    }

    this.setQuantities()

    this.totalQuantityPurchase = this.calculateQuantityPurchase(this.filterDepots, this.product)
  }

  setQuantities() {
    const filterDepots: number[] = [...this.filterDepots]
    if (this.filterDepotIdFrom && this.filterDepotIdFrom > 0) {
      filterDepots.push(Number(this.filterDepotIdFrom))
    }
    if (this.product?.quantities?.length) {
      this.product.quantities.forEach(quantity => {
        if (
          !filterDepots ||
          filterDepots.length === 0 ||
          (filterDepots && filterDepots.indexOf(quantity.depot?.id) !== -1)
        ) {
          this.totalQuantityStock = Number(this.totalQuantityStock) + Number(quantity.quantityStock)
          this.totalQuantityReservation = Number(this.totalQuantityReservation) + Number(quantity.quantityReservation)
          this.totalQuantityAvailable =
            Number(this.totalQuantityAvailable) + Number(quantity.quantityStock - quantity.quantityReservation)
        }
      })
    } else {
      this.totalQuantityAvailable = this.product.quantityAvailable
      this.totalQuantityReservation = this.product.quantityReservation
      this.totalQuantityStock = this.product.quantityStock
      this.totalQuantityStock = this.product.quantityPurchase
    }

    if (this.totalQuantityAvailable < 0) {
      this.totalQuantityAvailable = 0
    }
  }

  private calculateQuantityPurchase(depots: Array<number>, product: Product): number {
    let result = 0
    if (depots?.length > 0) {
      const selectedQuantitiesAccordingToDepots = product.quantities.filter(item => depots.includes(item.depot.id))

      result = selectedQuantitiesAccordingToDepots.reduce(
        (accumulator, currentValue) => accumulator + currentValue.quantityPurchase,
        0
      )
    } else {
      result = this.product.quantityPurchase || 0
    }
    return result
  }

  getQuantities(): void {
    this.isCalculatedOfProductDepots ? this.formatStockDetail() : this.getSellItems()
  }

  private async getSellItems() {
    this.isLoading = true
    this.sellItems = await this.core.services.product
      .getProductSellItems(this.product.id, this.checkout ? this.checkout.id : null, null, SchemaUtil.sellItem)
      .then(result => result.items.map(sellItem => new SellItem(sellItem)))
    this.totalQuantityAvailable = Object.values(this.sellItems).reduce(
      (total, { quantityAvailable }) => total + quantityAvailable,
      0
    )
    this.totalQuantityReservation = Object.values(this.sellItems).reduce(
      (total, { quantityReservation }) => total + quantityReservation,
      0
    )
    this.totalQuantityStock = Object.values(this.sellItems).reduce(
      (total, { quantityStock }) => total + quantityStock,
      0
    )
    this.totalQuantityPurchase = Object.values(this.stock).reduce(
      (total, { quantityPurchase }) => total + quantityPurchase,
      0
    )
    this.totalQuantityStockChange.emit(this.totalQuantityStock)
    this.isLoading = false
  }

  public showChildModal(): void {
    this.getQuantities()
    this.childModal.showChildModal()
  }

  public hideChildModal(): void {
    this.childModal.hideChildModal()
  }

  public addOrderItem(sellItem: SellItem) {
    const orderItem = new OrderItem({ ...this.product, ...{ id: null } })
    orderItem.product = this.product
    orderItem.quantity = sellItem.orderQuantity
    orderItem.price = this.productPrice
    orderItem.priceAll = this.productPrice * sellItem.orderQuantity
    orderItem.expirationDate = sellItem.expirationDate
    orderItem.batch = sellItem.batch
    orderItem.name = this.product.fullName
    orderItem.position = sellItem.productDepots[0].position
    this.onSelectedProductDepot.emit(orderItem)
  }

  areJustNullQuantitiesInTable(sellItemOrItems: SellItem[] | ProductDepot[]): boolean {
    return sellItemOrItems.every(
      item =>
        (!item.quantityStock || item.quantityStock <= 0) && (!item.quantityReservation || item.quantityReservation <= 0)
    )
  }

  async formatStockDetail(): Promise<void> {
    if (!this.product.productDepots?.length) {
      this.isLoading = true
      const r = await this.core.services.product.getById(this.product.id, {
        id: null,
        productDepots: {
          id: null,
          depot: { id: null, name: null, unavailablesUrl: null },
          supplier: { id: null, name: null },
          purchasePrice: null,
          quantityStock: null,
          quantityReservation: null,
          quantityAvailable: null,
          inventoryQuantityStock: null,
          batch: null,
          expirationDate: null,
          position: null,
          position1: null,
          position2: null,
          position3: null,
          created: null,
          updated: null,
        },
      })
      if (r?.productDepots) {
        if (this.product.productDepots) {
          this.product.productDepots = [...r.productDepots]
        } else {
          this.product = new Product({ ...this.product, ...r.productDepots })
        }
      }
      this.isLoading = false
    }
    const filterDepots: number[] = [...this.filterDepots]
    if (this.filterDepotIdFrom && this.filterDepotIdFrom > 0) {
      filterDepots.push(Number(this.filterDepotIdFrom))
    }
    const filterSuppliers: number[] = [...this.filterSuppliers]
    const stock: any[] = []
    this.totalQuantityStock = 0
    this.totalQuantityReservation = 0
    this.totalQuantityAvailable = 0
    if (this.depots?.length || this.filterDepots?.length) {
      const depotsForLoop = this.filterDepots?.length ? this.filterDepots : this.core.lists.allDepots
      depotsForLoop.forEach(depot => {
        const quantity = this.product.quantities.find(q => q.depot.id === depot.id)
        stock.push({
          depot: depot,
          productDepots: [],
          sumAll: quantity?.quantityStock || 0,
          sumReservations: quantity?.quantityReservation || 0,
          sumAvailable: quantity?.quantityAvailable || 0,
          sumPurchase: quantity?.quantityPurchase || 0,
          isUsersDepot: this.depots.some(d => d.id === depot.id),
        })
      })
    }
    if (this.product?.productDepots?.length) {
      this.product.productDepots.forEach(pDepot => {
        if (
          (!filterDepots ||
            filterDepots.length === 0 ||
            (filterDepots && filterDepots.indexOf(pDepot.depot?.id) !== -1)) &&
          (!filterSuppliers ||
            filterSuppliers.length === 0 ||
            (filterSuppliers && filterSuppliers.indexOf(pDepot.supplier?.id) !== -1))
        ) {
          this.totalQuantityStock = Number(this.totalQuantityStock) + Number(pDepot.quantityStock)
          this.totalQuantityReservation = Number(this.totalQuantityReservation) + Number(pDepot.quantityReservation)
          this.totalQuantityAvailable =
            Number(this.totalQuantityAvailable) + Number(pDepot.quantityStock - pDepot.quantityReservation)
          stock.forEach((stockItem, i) => {
            if (pDepot.depot.id === stockItem.depot.id) {
              stockItem.productDepots.push(pDepot)
            }
            if (pDepot.depot.id === stockItem.depot.id) {
              stockItem.productDepots.push(pDepot)
              // stock[i].sumAll += Number(pDepot.quantityStock)
              // stock[i].sumReservations += Number(pDepot.quantityReservation)
              // stock[i].sumAvailable =
              //   stock[i].sumAll - stock[i].sumReservations >= 0 ? stock[i].sumAll - stock[i].sumReservations : 0
            }
            // stock[i].sumPurchase =
            //   this.product.quantities.find(item => item.depot.id === stockItem.depot.id)?.quantityPurchase || 0
          })
        }
      })
    } else {
      this.totalQuantityAvailable = this.product.quantityAvailable
      this.totalQuantityReservation = this.product.quantityReservation
      this.totalQuantityStock = this.product.quantityStock
      this.totalQuantityStock = this.product.quantityPurchase
    }
    this.totalQuantityStockChange.emit(this.totalQuantityStock)

    if (this.totalQuantityAvailable < 0) {
      this.totalQuantityAvailable = 0
    }
    this.stock = stock
  }
}
