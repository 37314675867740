<modal-container
  #modalDepotMetric
  [headerIconClass]="'far-building'"
  [headerTitle]="'product.set.quantity.levels'"
  [isLargeModal]="true"
  [isLoading]="isLoading"
  [hasBackdrop]="true"
  [ignoreBackdropClick]="false"
  [isModalInModal]="true"
>
  <ng-container class="modal-button-show" *ngIf="stockItem?.depot?.id === productDepotMetric?.depot?.id">
    <button type="button" class="btn text-nowrap p-0" (click)="showChildModal()">
      <div class="depot-metric-icons">
        <div
          class="flex-gap-5 align-items-center blue"
          [title]="'product.quantity.levels.minimal' | translate"
          data-cy="metrics-btn-minimum"
        >
          <i class="fa-solid fa-arrow-up-from-dotted-line"></i>
          {{
            productDepotMetric?.quantityMinimum
              ? (productDepotMetric.quantityMinimum | number: '1.0-2' | replaceCommaToDot)
              : 0
          }}
        </div>
        <div
          class="flex-gap-5 align-items-center"
          [title]="'product.quantity.levels.optimal' | translate"
          data-cy="metrics-btn-optimum"
        >
          <i class="fa-solid fa-arrows-to-dotted-line"></i>
          {{
            productDepotMetric?.quantityOptimum
              ? (productDepotMetric.quantityOptimum | number: '1.0-2' | replaceCommaToDot)
              : 0
          }}
        </div>
        <div
          class="flex-gap-5 align-items-center"
          [title]="'product.quantity.levels.maximal' | translate"
          data-cy="metrics-btn-maximum"
        >
          <i class="fa-solid fa-arrow-up-to-dotted-line"></i>
          {{
            productDepotMetric?.quantityMaximum
              ? (productDepotMetric.quantityMaximum | number: '1.0-2' | replaceCommaToDot)
              : 0
          }}
        </div>
      </div>
    </button>

    <div class="spinner-border text-primary spinner-border-sm" role="status" *ngIf="isLoading">
      <span class="sr-only">{{ 'modal-product-depot-metric.loading' | translate }}</span>
    </div>
  </ng-container>

  <ng-container class="modal-button-show" *ngIf="stockItem.depot?.id !== productDepotMetric?.depot?.id">
    <btn
      class="btn text-nowrap"
      [classes]="['btn-first', 'me-2']"
      [label]="'product.set.quantity.levels'"
      [title]="'product.set.quantity.levels' | translate"
      (click)="showChildModal()"
      [dataCyAttribute]="'product.set.quantity.levels-btn'"
    ></btn>
  </ng-container>

  <ng-container class="modal-body-content">
    <div class="card" *ngIf="productDepotMetric?.product?.id > 0 || isCreating">
      <div class="table-responsive">
        <table class="table mb-0">
          <tbody>
            <tr>
              <td class="border-0 no-hover p-0">
                <div class="flex-gap-10 align-items-center">
                  <product-image [product]="product"></product-image>
                  <div class="d-flex flex-column align-items-start">
                    <a class="link-bold" [routerLink]="['/product', product]">{{ product.fullName }}</a>
                    <div class="product-details flex-gap-5">
                      <div *ngIf="product.code">{{ 'product.code' | translate }}: {{ product.code }}</div>
                      <div *ngIf="product.ean">{{ 'product.ean' | translate }}: {{ product.ean }}</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card">
      <div class="flex-justify-content-center" *ngIf="stockItem">
        <div class="flex-direction-column">
          <div class="text-align-center">
            {{ stockItem.depot?.name }}
          </div>
          <div class="availability-icons" style="width: 200px">
            <div class="flex-gap-5 align-items-center">
              <i class="far fa-warehouse-alt"></i>
              {{ stockItem.sumAll | number: '1.0-2' | replaceCommaToDot }}
            </div>
            <div class="flex-gap-5 align-items-center">
              <i class="far fa-hourglass"></i>
              {{ stockItem.sumReservations | number: '1.0-2' | replaceCommaToDot }}
            </div>
            <div class="flex-gap-5 align-items-center">
              <i class="far fa-check"></i>
              {{ stockItem.sumAvailable | number: '1.0-2' | replaceCommaToDot }}
            </div>
            <div class="flex-gap-5 align-items-center">
              <div>
                <i class="far fa-truck"></i>
              </div>
              <div>
                {{ product | quantityPurchase: stockItem.depot.id }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="productDepotMetric" class="quantity-inputs-container">
        <form-row-number-with-controls
          [label]="'product.quantity.levels.minimal'"
          [(model)]="productDepotMetric.quantityMinimum"
          [maxWidthPx]="150"
          [noMarginBottom]="true"
          [dataCyAttribute]="'productDepotMetric-quantityMinimum'"
        ></form-row-number-with-controls>
        <form-row-number-with-controls
          [label]="'product.quantity.levels.optimal'"
          [(model)]="productDepotMetric.quantityOptimum"
          [maxWidthPx]="150"
          [noMarginBottom]="true"
          [dataCyAttribute]="'productDepotMetric-quantityOptimum'"
        ></form-row-number-with-controls>
        <form-row-number-with-controls
          [label]="'product.quantity.levels.maximal'"
          [(model)]="productDepotMetric.quantityMaximum"
          [maxWidthPx]="150"
          [noMarginBottom]="true"
          [dataCyAttribute]="'productDepotMetric-quantityMaximum'"
        ></form-row-number-with-controls>
      </div>
    </div>
  </ng-container>

  <ng-container class="modal-footer-content">
    <btn
      *ngIf="productDepotMetric?.id > 0"
      [label]="'delete'"
      [title]="'delete' | translate"
      [icon]="'far-trash-alt'"
      [classes]="['btn-first-red', 'btn-sm', 'float-right']"
      (onEvent)="deleteProductDepotMetric()"
    ></btn>
    <btn
      [label]="'save'"
      [title]="'save' | translate"
      [icon]="'fa-check far me-2'"
      [classes]="['btn-first']"
      (onEvent)="productDepotMetric?.id ? updateProductDepotMetric() : createProductDepotMetric()"
      [dataCyAttribute]="'productDepotMetric-save'"
    ></btn>
  </ng-container>
</modal-container>
