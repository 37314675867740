import { Component, OnInit } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { CoreService } from '../../services'
import { Checkout } from 'depoto-core/src/entities'
import { checkoutsColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  name: '',
  user: null,
}

@Component({
  selector: 'app-checkouts',
  templateUrl: 'checkouts.component.html',
})
export class CheckoutsComponent extends BaseListing implements OnInit {
  listingColumns: ListingItem[] = checkoutsColumns
  checkouts: Checkout[] = []
  totalItems = 0
  currentPage: number
  endPage: number
  filters = { ...DEFAULT_FILTERS }
  canCreate = false
  loading = true
  storageKey: { type: string; key: string }
  sortProp = 'name'
  sortDir = 'asc'

  constructor(protected core: CoreService) {
    super(core)
    this.getStorageKey()
    this.setDefaultFiltersForUsersCheckouts()
  }

  ngOnInit() {
    this.filters = this.loadViewSettings('checkouts') || { ...DEFAULT_FILTERS }
    // todo je dobry napad tohle, kdyz jsem salesman odeberu/pridam si nejakou checkout -> neuvidim/uvidim stare vysledky??
    if (this.core.services.user.user) {
      this.checkPerms()
    }
    this.core.services.user.currentUserEmitter.subscribe().then(() => {
      this.checkPerms()
    })
    this.loadSorting('checkouts')
    this.filter()
  }

  checkPerms() {
    this.canCreate =
      this.core.services.user.hasRole('ROLE_ORDER_CREATE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
  }

  clearFilters(): void {
    this.filters = { ...DEFAULT_FILTERS }
    this.filter(1)
  }

  setDefaultFiltersForUsersCheckouts() {
    // just user's checkouts
    if (!this.core.userHasAllCheckouts && this.core.services.user.user.checkouts?.length) {
      this.filters.user = this.core.services.user.user.id
    }
  }

  filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false): void {
    this.currentPage = pageNo
    this.loading = true
    if (!concat) {
      this.reloading = true
    }
    this.core.services.checkout
      .getList(
        { page: pageNo, sort: sortProp, direction: sortDir, filters: this.filters },
        {
          id: null,
          name: null,
        }
      )
      .then(result => {
        if (!result?.items) return
        if (!concat) {
          this.checkouts = result.items
        } else {
          result.items.forEach(checkout => {
            this.checkouts.push(new Checkout(checkout))
          })
        }
        this.totalItems = result.paginator ? result.paginator.totalCount : 0
        this.endPage = result.paginator ? result.paginator.endPage : 0
        this.currentPage++
        this.saveViewSettings('checkouts', this.filters)
      })
      .finally(() => {
        this.loading = false
        this.reloading = false
      })
  }
}
