import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { Product, ProductPack } from 'depoto-core/src/entities'
import { NotificationService } from '../../../services'
import { CoreService } from '../../../services'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'modal-product-packs',
  templateUrl: 'modal-product-packs.component.html',
})
export class ModalProductPacksComponent {
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Input() pack: ProductPack
  @Input() productId: number
  @Input() isCreating: boolean
  @Input() loading: boolean
  @Input() dataCyAttribute: string
  @Output() packChange: EventEmitter<ProductPack> = new EventEmitter<ProductPack>()
  @Output() reloadProduct: EventEmitter<any> = new EventEmitter<any>()
  @Output() loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>()
  code: string
  quantity = 0
  ean: string
  note: string
  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private core: CoreService
  ) {}

  showChildModal(): void {
    this.childModal.showChildModal()
  }

  hideChildModal(): void {
    this.childModal.hideChildModal()
  }

  async createPack(pack: ProductPack) {
    this.loading = true
    pack.product = new Product({ id: this.productId })
    try {
      await this.core.services.productPack.create(pack).then(() => {
        this.notificationService.success(this.translateService.instant('alert.product.pack.created'))[
          (this.pack.code, this.pack.ean, this.pack.quantity, this.pack.note)
        ] = ['', '', 0, '']
        this.reloadProduct.emit()
        this.hideChildModal()
      })
    } catch (_) {
      console.error(_)
    } finally {
      this.loading = false
    }
  }

  async updatePack(pack: ProductPack) {
    this.loading = true
    try {
      pack.product = new Product({ id: this.productId })
      await this.core.services.productPack.update(pack).then(() => {
        this.notificationService.success(this.translateService.instant('alert.product.pack.updated'))
        this.reloadProduct.emit()
      })
    } catch (_) {
      console.error(_)
    } finally {
      this.loading = false
      this.loadingChange.emit(this.loading)
    }
  }

  async removePack(pack: ProductPack) {
    if (!confirm(this.translateService.instant('delete.confirmation'))) {
      return
    }
    this.loading = true
    try {
      await this.core.services.productPack.delete(pack).then(() => {
        this.notificationService.success(this.translateService.instant('alert.product.pack.deleted'))
        this.reloadProduct.emit()
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
      this.loadingChange.emit(this.loading)
    }
  }
  public async fillNextEan() {
    this.pack.ean = await this.core.services.product.getNextEan()
  }
}
