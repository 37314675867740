import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core'
import { ModalStockOperationCardComponent } from '../../components/modal-components/modal-stock-operation-card/modal-stock-operation-card.component'
import { Depot, File, Producer, Product, ProductMove, ProductMovePack, Supplier, Vat } from 'depoto-core/src/entities'
import { Events } from 'depoto-core/src/utils'
import { FileUtilService } from '../../utils/file-util.service'
import { SchemaUtil, unknownMimeType, getFulfilled, getRejected } from '../../utils'
import { CoreService, NotificationService } from '../../services'
import { ModalSerialNumbersComponent } from '../../modal-serial-numbers/modal-serial-numbers.component'
import { TranslateService } from '@ngx-translate/core'
import { PrintCodeComponent } from '../../components/print-code/print-code.component'
import { BaseListing } from '../_base-listing/base-listing'

type OperationType = 'in' | 'out' | 'transfer'

declare let disableBarcodeScanner: boolean

const productSchema: { [key: string]: any } = SchemaUtil.productRestricted

@Component({
  selector: 'stock-operation-list',
  templateUrl: 'stock-operation-list.component.html',
  styleUrls: ['stock-operation-list.component.scss'],
})
export class StockOperationListComponent extends BaseListing implements OnInit, OnDestroy {
  // @ViewChild modalStatus was not in scope. Has been deleted
  @ViewChild('modalStockOperation') modalStockOperation: ModalStockOperationCardComponent
  @ViewChild('printCodeComponent') printCodeComponent: PrintCodeComponent
  @ViewChild('modalSerialNumbers') set modalSerial(modalSerial: ModalSerialNumbersComponent) {
    if (modalSerial) {
      this.modalSerialNumbers = modalSerial
    }
  }
  modalSerialNumbers: ModalSerialNumbersComponent
  products: Product[] = []
  operations: ProductMove[] = []
  movesOriginPositions: { productMoveUuid: number | string; originPosition: string }[] = []
  depots: Depot[] = []
  operationTypes: string[] = ['in', 'out', 'transfer']
  suppliers: Supplier[] = []
  producers: Producer[] = []
  vats: Vat[] = []
  note = ''
  externalReference = ''
  totalItems = 0
  currentPage: number
  endPage: number
  optionsVisible = false
  selectedOperationType: OperationType
  selectedDepotFrom: number | null
  selectedDepotTo: number | null
  searchFulltext = ''
  dateOperation = ''
  initDateDUZP: string
  sortProp = 'id'
  sortDir = 'asc'
  loading = true
  isPrintingEANs = false
  uploadedFiles: File[] = []
  areFilesUploading = false
  keyEventSubscriptions: any = {}
  storageKey: { type: string; key: string }

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    public core: CoreService,
    private fileUtil: FileUtilService,
    private changeDetector: ChangeDetectorRef
  ) {
    super(core)
    Events.listen('filters:clear', () => {
      if (window['DEBUG'] && window['DEBUG'].events) {
        console.log('events:filters-clear ')
      }
      this.clearOptions()
      this.clearList(true)
    })
  }
  async ngOnInit() {
    this.storageKey = this.getStorageKeyForEntity('stockOperationList')
    this.refreshOptionsData()
    this.loadViewSettings()
    if (this.depots?.length === 1) {
      if (this.selectedOperationType !== 'out') this.selectedDepotTo = this.depots[0].id
      if (this.selectedOperationType !== 'in') this.selectedDepotFrom = this.depots[0].id
    }
    await this.filter()
    // this.fillDuplicatedPMP()
    this.keyEventSubscriptions.scannedBarcode = this.core.services.keyEvent.onScannedBarcode.subscribe().then(code => {
      if (disableBarcodeScanner) {
        return
      }
      if (code?.startsWith('pmp-')) {
        return this.addMovesFromPMP(code)
      }
      this.searchFulltext = code
      this.filter().then((products: Product[]) => {
        if (products?.length === 1) {
          if (this.selectedOperationType === 'transfer') {
            const pDepots = this.getProductDepotsWithQuantity(this.products[0])
            if (pDepots?.length === 1) {
              this.modalStockOperation.showProductDepotOnChildModal(pDepots[0])
              this.products = []
              this.searchFulltext = ''
              //this.changeDetector.markForCheck()
              this.filter()
            }
          } else {
            this.modalStockOperation.showProductOnChildModal(products[0])
            this.products = []
            this.searchFulltext = ''
            //this.changeDetector.markForCheck()
            this.filter()
          }
        }
        if (!products?.length) {
          this.modalStockOperation.showProductOnChildModal(null, true, this.searchFulltext)
          this.products = []
          this.searchFulltext = ''
          //this.changeDetector.markForCheck()
          this.filter()
        }
      })
    })
    this.loading = false
  }

  ngOnDestroy() {
    if (this.keyEventSubscriptions.scannedBarcode) {
      this.keyEventSubscriptions.scannedBarcode.unsubscribe()
      this.keyEventSubscriptions.scannedBarcode = null
    }
  }

  unknownType(s?: string) {
    return unknownMimeType(s)
  }

  loadViewSettings(): void {
    const settings = this.core.services.storage.getSync(this.storageKey) || {}
    this.selectedOperationType = settings.selectedOperationType || 'in'
    const doesSelectedDepotToExist =
      settings.selectedDepotTo && this.depots.map(d => d.id).filter(d => d === Number(settings.selectedDepotTo)).length
    const doesSelectedDepotFromExist =
      settings.selectedDepotFrom &&
      this.depots.map(d => d.id).filter(d => d === Number(settings.selectedDepotFrom)).length

    if (this.selectedOperationType === 'in') {
      this.selectedDepotTo = doesSelectedDepotToExist ? settings.selectedDepotTo : null
      this.selectedDepotFrom = null
    } else if (this.selectedOperationType === 'out') {
      this.selectedDepotFrom = doesSelectedDepotFromExist ? settings.selectedDepotFrom : null
      this.selectedDepotTo = null
    } else {
      this.selectedDepotTo = doesSelectedDepotToExist ? settings.selectedDepotTo : null
      this.selectedDepotFrom = doesSelectedDepotFromExist ? settings.selectedDepotFrom : null
    }
    this.optionsVisible = settings.optionsVisible || false
    this.operations = settings.operations?.length ? settings.operations.map(op => new ProductMove(op)) : []
    this.searchFulltext = settings.searchFulltext || ''
    this.dateOperation = settings.dateOperation || ''
    this.sortProp = settings.sortProp || 'id'
    this.sortDir = settings.sortDir || 'asc'
    this.note = settings.note || ''
    this.externalReference = settings.externalReference || ''
    this.uploadedFiles = settings.uploadedFiles?.length ? settings.uploadedFiles.map(f => new File(f)) : []
  }

  saveViewSettings(): void {
    const settings = {
      optionsVisible: this.optionsVisible,
      selectedOperationType: this.selectedOperationType,
      selectedDepotFrom: this.selectedDepotFrom,
      selectedDepotTo: this.selectedDepotTo,
      operations: this.operations,
      movesOriginPositions: this.movesOriginPositions,
      dateOperation: this.dateOperation,
      searchFulltext: this.searchFulltext,
      sortProp: this.sortProp,
      sortDir: this.sortDir,
      note: this.note,
      externalReference: this.externalReference,
      uploadedFiles: this.uploadedFiles,
    }
    this.core.services.storage.set(this.storageKey, settings)
  }

  refreshOptionsData(): void {
    this.depots = this.core.lists.depots
    this.suppliers = this.core.lists.suppliers
    this.producers = this.core.lists.producers
    this.vats = this.core.lists.vats
    if (this.depots && this.depots.length < 2) {
      this.operationTypes = ['in', 'out', 'transfer']
      if (this.depots.length === 1) {
        if (!this.selectedDepotTo) this.selectedDepotTo = this.depots[0].id
        if (!this.selectedDepotFrom) this.selectedDepotFrom = this.depots[0].id
      }
    }
  }

  /*
  fillDuplicatedPMP() {
    const pmp = window['pmp-clone']
    if (pmp) {
      if (pmp.depotTo?.id > 0) {
        this.selectedDepotTo = pmp.depotTo.id
        this.selectedOperationType = 'in'
      }
      if (pmp.depotFrom?.id > 0) {
        this.selectedDepotFrom = pmp.depotFrom.id
        this.selectedOperationType = 'out'
      }
      this.operations = pmp.moves
      this.note = pmp.note || ''
      this.externalReference = pmp.externalReference || ''
      this.isClonedPmp = true
    }
  }
  */

  async clearList(withoutConfirmation = false): Promise<boolean> {
    if (!withoutConfirmation && !confirm(this.translateService.instant('stock-operation-list.delete.confirm'))) {
      // todo stock-in to stock-op ...
      return false
    }
    if (this.uploadedFiles.length) {
      for (const [index, file] of this.uploadedFiles.entries()) {
        try {
          await this.core.services.file.delete({ id: file.id })
          this.uploadedFiles[index] = undefined
        } catch (e) {
          console.warn(e)
        }
      }
      this.uploadedFiles = this.uploadedFiles.filter(f => f)
      if (this.uploadedFiles.length) {
        this.notificationService.error(
          this.translateService.instant('stock-operation-list.delete.error', {
            filename: this.uploadedFiles.map(f => f.originalFilename).join(', '),
          })
        )
      }
    } // Temporarily 'undeletable' files remain tied to the view
    this.operations = []
    this.externalReference = ''
    this.saveViewSettings()
    return true
  }
  clearOptions() {
    this.selectedDepotFrom = null
    this.selectedDepotTo = null
    this.dateOperation = ''
    this.searchFulltext = ''
    this.products = []
    this.note = ''
    this.externalReference = ''
    this.core.services.storage.clear(this.storageKey)
  }

  async onPointerDown(event: PointerEvent) {
    const select = event.currentTarget as HTMLSelectElement
    if (select.id == 'depotSelectTo' && this.selectedOperationType === 'transfer') {
      return // ? no need to prevent select ?
    }
    if (this.operations?.filter(o => o.product).length && !select.dataset.done) {
      event.preventDefault()
      if (await this.clearList()) {
        if (select.id === 'depotSelectFrom') {
          this.selectedDepotFrom = null
        }
        if (select.id === 'depotSelectTo') {
          this.selectedDepotTo = null
        }
        this.core.services.storage.clear(this.storageKey)
        select.dataset.done = 'true'
        select.focus()
        setTimeout(() => {
          try {
            select.showPicker()
          } catch (e) {
            // the browser may want trusted event
            console.warn(e)
          }
        }, 0) //after view updates
      }
    } else {
      select.removeAttribute('data-done')
    }
  }

  filterKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.filter()
    }
  }

  async filter(
    pageNo = 1,
    sortProp: string = this.sortProp,
    sortDir: string = this.sortDir,
    concat = false
  ): Promise<any> {
    this.loading = true
    this.currentPage = pageNo
    const filters: any = {
      listType: 'children_and_parents_without_children',
      fulltext: this.searchFulltext.trim(),
      enabled: true,
      isBundle: false,
    }
    if (this.selectedOperationType === 'transfer') {
      filters.availability = 'stock'
    }
    if (this.selectedOperationType !== 'in' && this.selectedDepotFrom) {
      filters.depots = [Number(this.selectedDepotFrom)]
      filters.availability = 'available'
    }
    if (this.selectedDepotFrom) {
      filters.depots = [Number(this.selectedDepotFrom)]
    }
    if (this.selectedOperationType === 'transfer') {
      productSchema.productDepots = {
        id: null,
        depot: { id: null, name: null, unavailablesUrl: null },
        supplier: { id: null, name: null },
        purchasePrice: null,
        quantityStock: null,
        quantityReservation: null,
        quantityAvailable: null,
        inventoryQuantityStock: null,
        batch: null,
        expirationDate: null,
        position: null,
        position1: null,
        position2: null,
        position3: null,
        created: null,
        updated: null,
      }
    } else if (productSchema.productDepots) {
      delete productSchema.productDepots
    }
    // todo check
    if (this.core.services.user.user?.depots?.length) {
      this.core.services.user.user?.depots.forEach(depot => {
        if (filters.depots?.length) {
          filters.depots.push[Number(depot.id)]
        } else {
          filters.depots = []
          filters.depots.push[Number(depot.id)]
        }
      })
    }
    const receivedData = await this.core.services.product.getList(
      { page: pageNo, sort: sortProp, direction: sortDir, filters: filters },
      productSchema
    )
    if (receivedData) {
      if (!concat) {
        this.products = receivedData.items
      } else {
        receivedData.items.forEach(prod => {
          this.products.push(new Product(prod))
        })
      }
      this.totalItems = receivedData.paginator ? receivedData.paginator.totalCount : 0
      this.endPage = receivedData.paginator ? receivedData.paginator.endPage : 0
      this.currentPage++
      this.loading = false
      return this.products
    }
    this.loading = false
  }

  sortBy(property: string): void {
    if (this.sortProp === property) {
      if (this.sortDir === 'asc') {
        this.sortDir = 'desc'
      } else {
        this.sortDir = 'asc'
      }
    } else {
      this.sortProp = property
    }
    // TODO: local sorting
    this.saveViewSettings()
  }

  importBatchFromFile(operations: ProductMove[]): void {
    operations.forEach(op => this.addOperationToList(op))
    this.operations = [...this.operations]
    //setTimeout(() => {
    //  this.appRef.tick()
    //}, 50)
  }

  createBatchFromFile(res: any[]): void {
    if (res && res.length > 0) {
      const errorsInResponse = res.filter(r => Array.isArray(r) && typeof r[0] === 'string')
      if (errorsInResponse.length > 0) {
        this.notificationService.error(
          this.translateService.instant('stock-operation-list.create.error', { error: errorsInResponse.join(',') }),
          null,
          true
        )
        console.warn('list::createBatchFromFile errors: ', res)
      } else {
        // this.notificationService.success(this.translateService.instant('stock-operation-list.create.success'))
        this.filter()
      }
    }
  }

  openModalSerialNumbers(productMove: ProductMove): void {
    setTimeout(() => {
      this.modalSerialNumbers?.showChildModal(productMove)
      // just to ensure that call stack is empty and view queries were finished
    }, 0)
  }

  operationListChange(moves: ProductMove[]) {
    this.operations = moves
    this.saveViewSettings()
  }

  getOperationsOriginPosition(operation) {
    const a = this.movesOriginPositions.find(m => m.productMoveUuid === operation.uuid)
    return a?.originPosition
  }

  addOperationToList(operation: ProductMove): void {
    if (!operation || (operation && !operation.product)) {
      if (Array.isArray(operation) && operation.length > 0) {
        operation.forEach(err => {
          this.notificationService.error(
            this.translateService.instant('stock-operation-list.save.error', {
              error: err && err.message ? err.message : err,
            }),
            null,
            true
          )
        })
      } else {
        this.notificationService.error(this.translateService.instant('stock-operation-list.save.error.500'), null, true)
      }
      return
    }

    if (this.selectedOperationType !== 'out' && operation.amount < 1) {
      alert(this.translateService.instant('stock-operation-list.not-possible-to-store-in-minus'))
      return
    }

    operation = new ProductMove(operation)
    if (this.selectedOperationType === 'out') {
      this.handleOperationTypeOut(operation)
    } else {
      let exists = false
      this.operations.forEach((op: ProductMove, i: number) => {
        if (this.isProductInOperationsYet(op, operation)) {
          exists = true
          this.operations[i].amount = Number(op.amount) + Number(operation.amount)
          this.operations[i].purchasePriceSum =
            (Number(op.purchasePriceSum) || 0) + (Number(operation.purchasePriceSum) || 0)
          this.operations[i].supplier = operation.supplier
        }
      })
      if (!exists) {
        operation.purchasePriceSum = (Number(operation.purchasePrice) || 0) * (operation.amount || 1)
        this.operations.push(operation)
      }
      this.saveViewSettings()
    }
  }

  updateOperation(operation: ProductMove): void {
    operation = new ProductMove(operation)
    if (this.selectedOperationType === 'out') {
      this.handleOperationTypeOut(operation)
    } else {
      this.operations.forEach((op: ProductMove, i: number) => {
        // if (op.product.id === operation.product.id && op.supplier === operation.supplier) { // todo: is supplier needed? best solution: uuids
        if (op.product.id === operation.product.id) {
          this.operations[i] = operation
          this.saveViewSettings()
          return
        }
      })
    }
  }

  removeOperation(operation: ProductMove): void {
    this.operations.forEach((op: ProductMove, i: number) => {
      if (op === operation) {
        this.operations.splice(i, 1)
        this.saveViewSettings()
        return
      }
    })
  }

  async handleOperationTypeOut(selectedOperation: ProductMove): Promise<void> {
    this.operations.push(selectedOperation)
    this.saveViewSettings()
  }

  isProductInOperationsYet(operation, selectedOperation): boolean {
    return (
      operation.product.id === selectedOperation.product.id &&
      operation.purchasePrice === selectedOperation.purchasePrice &&
      operation.sellPrice === selectedOperation.sellPrice &&
      operation.batch === selectedOperation.batch &&
      operation.expirationDate === selectedOperation.expirationDate &&
      operation.purchaseCurrency === selectedOperation.purchaseCurrency &&
      operation.purchaseCurrencyDate === selectedOperation.purchaseCurrencyDate &&
      operation.position1 === selectedOperation.position1 &&
      operation.position2 === selectedOperation.position2 &&
      operation.position3 === selectedOperation.position3
    )
  }

  updateNote(note: string): void {
    this.note = note
    this.saveViewSettings()
  }

  updateExternalReference(externalReference: string): void {
    this.externalReference = externalReference
    this.saveViewSettings()
  }

  createStockLoad(): void {
    this.loading = true
    if (this.selectedOperationType === 'out') {
      this.operations.forEach(op => {
        delete op.batch
        delete op.supplier
        delete op.expirationDate
        delete op.purchasePrice
      })
    }
    const movePack: ProductMovePack = new ProductMovePack({
      type: { id: this.selectedOperationType },
      depotFrom: {
        id: this.selectedDepotFrom,
      },
      depotTo: {
        id: this.selectedDepotTo,
      },
      moves: this.operations,
    })
    if (this.note && this.note !== 'null') {
      movePack.note = this.note
    }
    if (this.externalReference && this.externalReference !== 'null') {
      movePack.externalReference = this.externalReference
    }
    // check if depots exists for sure in this company (prevents multiple company in multiple tabs chaos)
    let areDepotsOk = true
    movePack.moves.forEach(mo => {
      const doesSelectedDepotsOnMoveExists =
        this.depots.map(d => d.id).filter(d => d === Number(mo.depotFrom) || d === Number(mo.depotTo)).length > 0
      if (!doesSelectedDepotsOnMoveExists && (mo.depotFrom || mo.depotTo)) {
        areDepotsOk = false
        return
      }
    })
    if (!areDepotsOk) {
      // todo !!!!!!!!!!!!!!!
      alert(this.translateService.instant('stock-operation-list.cannot-be-stocked'))
      return
    }
    this.core.services.productMovePack
      .create(movePack)
      .then(result => {
        if (result && result.id) {
          this.notificationService.success(
            this.translateService.instant(
              `ALERT ${
                this.selectedOperationType === 'in'
                  ? 'LOADING'
                  : this.selectedOperationType === 'out'
                    ? 'UNLOADING'
                    : 'TRANSFER'
              } STOCK`
            )
          )
          this.loading = true
          window['selectedSupplier'] = undefined
          this.updateUploadedFilesWithProductMovePackId(result.id)
            .then(res => {
              this.loading = false
              if (res.length) {
                this.notificationService.success(this.translateService.instant('stock-operation-list.success'))
              }
              this.clearList(true)
              this.filter()
            })
            .catch(e => `createStockLoad err, on fileUpdate: ${e.message}`)
        } else {
          this.notificationService.warning(this.translateService.instant('stock-operation-list.processing-error'))
        }
        this.loading = false
        this.loadViewSettings()
      })
      .catch(error => {
        this.loading = false
        alert(
          this.translateService.instant('stock-operation-list.error') +
            (error && error.message ? error.message : typeof error === 'string' ? error : '')
        )
        console.warn(
          'StockOpList::createStockLoad ERROR',
          error,
          error && error.message ? error.message : typeof error === 'string' ? error : ''
        )
      })
  }

  printingSelect(type: string): void {
    let ops: any[] = []
    switch (type) {
      case 'all':
        ops = this.operations.map(op => {
          op.isEANPrintable = true
          return op
        })
        break
      case 'none':
        ops = this.operations.map(op => {
          op.isEANPrintable = false
          return op
        })
        break
      case 'invert':
        ops = this.operations.map(op => {
          op.isEANPrintable = !op.isEANPrintable
          return op
        })
        break
    }
    this.operations = ops
  }

  refreshOperationsHack(): void {
    // we need just to initiate pipe reload
    this.operations = [...this.operations]
    // .. and refresh tickets for print
    this.printCodeComponent?.hydrate()
  }

  async setOperationType(t: OperationType) {
    this.loading = true
    this.selectedDepotTo = null
    this.selectedDepotFrom = null
    this.selectedOperationType = t
    await this.filter()
    this.core.services.storage.clear(this.storageKey)
  }

  uploadFiles(inputEvent: Event): Promise<Array<File> | any> {
    this.areFilesUploading = true
    const target = inputEvent.target as HTMLInputElement
    return new Promise((resolve, reject) => {
      if (target.files?.length) {
        const promisesBase64s = Array.from(target.files).map(f => this.fileUtil.processUploadedFileToBase64(f))
        Promise.allSettled(promisesBase64s)
          .then(base64s => {
            const fulfilledBase64s = getFulfilled<File>(base64s)
            const promisesUploads = fulfilledBase64s.map(b => {
              return this.core.services.file.create(new File(b), null, null, null, null, null, SchemaUtil.file)
            })
            return Promise.allSettled(promisesUploads).then(res => {
              const fulfilled = getFulfilled<File>(res)
              if (fulfilled.length) {
                this.uploadedFiles.push(...fulfilled)
                this.saveViewSettings()
                this.notificationService.success(this.translateService.instant('alert.file.uploaded'))
              }
              if (fulfilled.length < res.length) {
                const rejected = getRejected<File>(res)
                this.notificationService.error(
                  this.translateService.instant('alert.file.removed') + '\n' + rejected.join('\n')
                )
              }
            })
          })
          .catch(e => console.warn(`StockOperationList: uploadFiles err: ${e.stack}`))
          .finally(() => {
            this.areFilesUploading = false
          })
      } else {
        this.areFilesUploading = false
        reject(this.translateService.instant('no-file'))
      }
    })
  }

  updateUploadedFilesWithProductMovePackId(pmpId: number): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      if (!this.uploadedFiles?.length) {
        return resolve([])
      }
      const promises = this.uploadedFiles.map(f => this.core.services.file.update(f, null, null, null, null, pmpId))
      Promise.allSettled(promises).then(res => {
        const fulfilled = getFulfilled<File>(res)
        if (fulfilled?.length) {
          resolve(fulfilled)
        } else {
          reject(this.translateService.instant('no-file'))
        }
      })
    })
  }

  async removeUploadedFile(file: File) {
    if (!confirm(this.translateService.instant('delete-file.confirmation', { name: file.originalFilename }))) {
      return
    }
    if (file?.id) {
      try {
        await this.core.services.file.delete({ id: file.id }).then(() => {
          this.notificationService.success(this.translateService.instant('alert.file.removed'))
        })
        this.uploadedFiles.forEach((f, i) => {
          if (file.id === f.id) {
            this.uploadedFiles.splice(i, 1)
          }
        })
      } catch (e) {
        this.translateService.instant('stock-operation-list.delete.error', {
          filename: file.originalFilename,
        })
      }
      this.saveViewSettings()
    }
  }

  getProductDepotsWithQuantity(prod) {
    return prod.productDepots.filter(
      pDepot => Number(pDepot.depot.id) === Number(this.selectedDepotFrom) && pDepot?.quantityStock > 0
    )
  }

  printList(): void {
    //TODO: why???????
    const htmlRows = this.operations
      .map(
        op => `
      <tr>
        <td>${op.product ? op.product.fullName : ''}</td>
        <td class="text-end">${op.product ? op.product.ean : ''}</td>
        <td class="text-end">${op.product ? op.product.code : ''}</td>
        <td class="text-end">${op.amount}</td>
        <td class="text-end">${op.purchasePrice !== 0 ? Number(op.purchasePrice).toFixed(2) : '0.00'}</td>
      </tr>`
      )
      .join('')
    const html = `
      <table>
        <thead>
          <tr>
            <th>Název</th>
            <th>EAN</th>
            <th>('product.code' | translate)</th>
            <th>Počet</th>
            <th>Nákupní cena</th>
          </tr>
        </thead>
        <tbody>${htmlRows}</tbody>
      </table>`
    const pWidth = 900
    const pHeight = 720
    // TODO: https://developer.mozilla.org/en-US/docs/Web/API/Window/open#target
    const popupWin = window.open(
      '',
      'Seznam položek k naskladnění',
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${pWidth},height=${pHeight},top=${
        screen.height - (screen.height + pHeight) / 2
      },left=${screen.width - (screen.width + pWidth) / 2}`
    )
    popupWin.document.body.innerHTML = html
    const styles = document.createElement('style')
    styles.innerHTML = `
      table {width: 100%; border: none; border-collapse: collapse;}
      td, th {border: 1px solid #333; padding: 1mm;}
      .text-end {text-align: right;}`
    popupWin.document.head.appendChild(styles)
    setTimeout(() => {
      popupWin.print()
    }, 300)

    setTimeout(() => {
      popupWin.close()
    }, 500)
  }

  getThumbUrl(image: File): string {
    return image.thumbnails.filter(t => t.format === 'w135wp')[0].url
  }

  async addMovesFromPMP(pmpCode: string) {
    this.selectedOperationType = 'in'
    if (!this.selectedDepotTo) {
      alert(this.translateService.instant('modal-stock-operation-card.select-warehouse'))
      return
    }
    const pmp = await this.core.services.productMovePack.getById(
      Number(pmpCode.replace('pmp-', '')),
      SchemaUtil.productMovePack
    )
    this.externalReference = `${pmp.number}`
    for (const m of pmp.moves) {
      m.supplier = m.productDepotFrom?.supplier?.id
      m.depotTo = this.selectedDepotTo
      this.addOperationToList(m)
    }
  }
}
