import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { Product, ProductPrice } from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { NotificationService } from '../../../services'
import { CoreService } from '../../../services'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'modal-product-advanced-prices',
  templateUrl: 'modal-product-advanced-prices.component.html',
})
export class ModalProductAdvancedPricesComponent {
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Input() product: Product
  @Input() productAdvancedPrices: ProductPrice
  @Input() isCreating: boolean
  @Input() dataCyAttribute: string
  @Output() onPriceUpdate: EventEmitter<number> = new EventEmitter()
  public advancedPrice = new ProductPrice()
  loading = false
  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private core: CoreService
  ) {}

  showChildModal(): void {
    this.childModal.showChildModal()
  }

  async createProductPrice() {
    if (this.checkProductPriceValidity(this.advancedPrice)) {
      try {
        const res = await this.core.services.productPrice.create(this.advancedPrice)
        if (res && res.id) {
          this.notificationService.success(this.translateService.instant('alert.product.price.created'))
          this.childModal.hideChildModal()
          this.advancedPrice = new ProductPrice()
        }
        this.onPriceUpdate.emit(this.product.id)
      } catch (err) {
        console.error(err)
      }
    }
  }

  async updateProductPrice(productPrice: ProductPrice) {
    if (this.checkProductPriceValidity(productPrice)) {
      try {
        await this.core.services.productPrice.update(productPrice).then(() => {
          this.notificationService.success(this.translateService.instant('alert.product.price.updated'))
          this.onPriceUpdate.emit(this.product.id)
        })
      } catch (err) {
        console.error(err)
      }
    }
  }

  private hasOverlappingPrice(child, productPrice) {
    const productPriceStart = new Date(productPrice.dateFrom).getTime()
    const productPriceEnd = new Date(productPrice.dateTo).getTime()
    child.advancedPrices.forEach(p => {
      const childPriceStart = new Date(p.dateFrom.substr(0, 10)).getTime()
      const childPriceEnd = new Date(p.dateTo.substr(0, 10)).getTime()
      if (productPriceStart <= childPriceStart && productPriceEnd >= childPriceEnd) {
        this.notificationService.error(
          this.translateService.instant('modal-product-advanced-prices.error', {
            name: child.name,
            dateFrom: p.dateFrom,
            dateTo: p.dateFrom,
          }),
          null,
          true
        )
        return true
      }
    })
    return false
  }

  async updateProductChildrenWithPrice(productPrice: ProductPrice) {
    if (this.checkProductPriceValidity(productPrice)) {
      const promises = this.product.children
        .filter(children => !this.hasOverlappingPrice(children, productPrice))
        .map(children => new ProductPrice({ ...productPrice, product: children }))
        .map(childrenPrice => this.core.services.productPrice.create(childrenPrice))
      await Promise.all(promises)
      this.onPriceUpdate.emit(this.product.id)
    }
  }

  async deleteProductPrice(productPrice: ProductPrice) {
    try {
      await this.core.services.productPrice.delete(productPrice).then(() => {
        this.notificationService.success(this.translateService.instant('alert.product.price.deleted'))
        this.onPriceUpdate.emit(this.product.id)
      })
    } catch (err) {
      console.error(err)
    }
  }

  checkProductPriceValidity(productPrice: ProductPrice): boolean {
    productPrice.product = this.product
    if (!productPrice.sellPrice) {
      alert(this.translateService.instant('modal-product-advanced-prices.enter-price'))
      return false
    }
    productPrice.sellPrice = Number(productPrice.sellPrice)
    if (!this.hasAvailabilityOrDatesFilled(productPrice)) {
      alert(this.translateService.instant('modal-product-advanced-prices.enter-price-or-interval'))
      return false
    }
    productPrice.available = Number(productPrice.available)
    return true
  }

  private hasAvailabilityOrDatesFilled(productPrice: ProductPrice) {
    return (
      (productPrice.available && productPrice.available > 0) ||
      (productPrice.dateFrom &&
        productPrice.dateTo &&
        productPrice.dateFrom.length > 0 &&
        productPrice.dateTo.length > 0)
    )
  }
}
