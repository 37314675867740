<div class="row mt-2">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div [ngClass]="isInBulkEdits ? 'column' : 'row'">
      <div class="col-12 col-sm-12 col-md-12" [ngClass]="isInBulkEdits ? '' : 'col-lg-8 col-xl-8'">
        <div class="row table-product-border">
          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-center">
            <svg
              class="cube align-self-center"
              xmlns="http://www.w3.org/2000/svg"
              width="104"
              height="103.506"
              viewBox="0 0 104 103.506"
            >
              <g transform="translate(-256 -271.64)">
                <path
                  [ngClass]="dimensionY"
                  class="a"
                  d="M258,375.146a2,2,0,0,1-2-2v-68.2a2,2,0,0,1,4,0v68.2A2,2,0,0,1,258,375.146Z"
                  data-cy="y"
                />
                <path
                  [ngClass]="dimensionX"
                  class="a"
                  d="M326.8,375.146H258a2,2,0,0,1,0-4h68.8a2,2,0,0,1,0,4Z"
                  data-cy="x"
                />
                <path
                  [ngClass]="dimensionZ"
                  class="a"
                  d="M326.8,306.946a2,2,0,0,1-1.417-3.413l31.2-31.3a2,2,0,1,1,2.834,2.824l-31.2,31.306A2,2,0,0,1,326.8,306.946Z"
                  data-cy="z"
                />
                <path
                  class="a"
                  d="M359.782,339.037a1.989,1.989,0,0,0,.082-.182,1.836,1.836,0,0,0,.058-.187c.017-.062.033-.123.044-.186a1.9,1.9,0,0,0,.021-.212c0-.042.013-.082.013-.124V273.64a2,2,0,0,0-2-2H292.45c-.044,0-.085.01-.128.013a1.962,1.962,0,0,0-.217.022c-.063.011-.123.028-.184.045a1.9,1.9,0,0,0-.19.059c-.062.024-.121.054-.181.084a1.752,1.752,0,0,0-.165.09,1.7,1.7,0,0,0-.174.129c-.035.027-.073.047-.106.077l-34.45,31.306c-.012.011-.02.024-.031.035a2.062,2.062,0,0,0-.147.163c-.038.045-.078.088-.112.136a2.041,2.041,0,0,0-.1.176c-.029.054-.062.1-.087.161a1.966,1.966,0,0,0-.065.187c-.02.06-.043.12-.057.181s-.018.122-.026.183a2.116,2.116,0,0,0-.021.211c0,.016-.005.031-.005.048,0,.045.01.088.013.133.005.071.01.141.022.211s.028.125.045.187.035.125.059.187a2,2,0,0,0,.084.182c.029.056.056.111.09.165a2.01,2.01,0,0,0,.129.174c.027.035.048.072.078.105.01.012.023.019.033.03a2.074,2.074,0,0,0,.2.176c.037.03.072.065.112.093a2.121,2.121,0,0,0,.251.141c.033.017.064.039.1.054a2,2,0,0,0,.787.162h66.8v66.2c0,.019.005.037.005.056a1.906,1.906,0,0,0,.022.212,1.79,1.79,0,0,0,.028.181,1.843,1.843,0,0,0,.058.188c.021.06.039.121.065.179s.06.114.092.171.063.113.1.165.078.1.119.145a1.854,1.854,0,0,0,.141.153c.014.014.024.03.039.043.037.033.078.056.117.086s.091.072.14.1a2.038,2.038,0,0,0,.189.1c.048.024.094.049.144.069a2.043,2.043,0,0,0,.23.072c.044.011.086.027.131.035a1.935,1.935,0,0,0,.379.039h0a2.021,2.021,0,0,0,.407-.042c.016,0,.031-.011.047-.015a2.03,2.03,0,0,0,.337-.107c.041-.018.078-.044.118-.064a2.223,2.223,0,0,0,.233-.133c.042-.03.08-.067.121-.1a2.055,2.055,0,0,0,.189-.171c.013-.014.028-.023.041-.037l31.2-35c.029-.033.049-.07.075-.1a1.888,1.888,0,0,0,.123-.17C359.725,339.149,359.754,339.093,359.782,339.037ZM328.8,304.946a2,2,0,0,0-2-2H263.175l30.048-27.306H356v61.743L328.8,367.9Z"
                />
              </g>
            </svg>
          </div>
          <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <div class="row">
              <div class="col-6">
                <div class="input-icon">
                  <form-row-input
                    [class]="'input-unit'"
                    [label]="'width'"
                    [icon]="'far-tachometer'"
                    [inputType]="'number'"
                    [model]="isInBulkEdits ? '' : product.dimensionX"
                    (modelChange)="isInBulkEdits ? valueAdded($event, 'dimensionX') : valueChanged($event, 'X')"
                    [appendUnitCm]="true"
                    [dataCyAttribute]="'product-x-width'"
                  >
                  </form-row-input>
                  <i class="far fa-arrows-alt-h"></i>
                </div>
              </div>
              <div class="col-6">
                <div class="input-icon">
                  <form-row-input
                    [class]="'input-unit'"
                    [model]="isInBulkEdits ? '' : product.dimensionY"
                    (modelChange)="isInBulkEdits ? valueAdded($event, 'dimensionY') : valueChanged($event, 'Y')"
                    [label]="'height'"
                    [icon]="'far-tachometer'"
                    [inputType]="'number'"
                    [appendUnitCm]="true"
                    [dataCyAttribute]="'product-y-height'"
                  >
                  </form-row-input>
                  <i class="far fa-arrows-alt-v"></i>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="input-icon">
                  <form-row-input
                    [class]="'input-unit'"
                    [model]="isInBulkEdits ? '' : product.dimensionZ"
                    (modelChange)="isInBulkEdits ? valueAdded($event, 'dimensionZ') : valueChanged($event, 'Z')"
                    [label]="'depth'"
                    [icon]="'far-tachometer'"
                    [inputType]="'number'"
                    [appendUnitCm]="true"
                    [dataCyAttribute]="'product-z-length'"
                  >
                  </form-row-input>
                  <i class="far fa-expand-alt"></i>
                </div>
              </div>
              <div class="col-6">
                <div class="input-icon">
                  <form-row-input
                    [class]="'input-unit'"
                    [label]="'weight'"
                    [inputType]="'number'"
                    [icon]="'far-tachometer'"
                    [model]="isInBulkEdits ? '' : product.weight"
                    (modelChange)="isInBulkEdits ? valueAdded($event, 'weight') : valueChanged($event, 'weight')"
                    [appendUnitKg]="true"
                    [dataCyAttribute]="'product-weight'"
                  >
                  </form-row-input>
                  <i class="far fa-weight-hanging"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12" [ngClass]="isInBulkEdits ? '' : 'col-xl-4 col-lg-4'">
        <div class="row">
          <div class="col-12">
            <form-row-select
              [label]="'product.originCountry'"
              [model]="isInBulkEdits ? countrySelectedOption : product.originCountry"
              (modelChange)="isInBulkEdits ? valueAdded($event, 'originCountry') : originCountryChange($event)"
              [options]="isInBulkEdits ? countriesOptions : countries"
              [optionKey]="'code'"
              [dataCyAttribute]="'product-origin-country-select'"
            >
            </form-row-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div>
              <form-row-input
                [label]="'product.hsCode'"
                [icon]="'far-globe'"
                [replaceComma]="true"
                [model]="isInBulkEdits ? '' : product.hsCode"
                (modelChange)="isInBulkEdits ? valueAdded($event, 'hsCode') : hsCodeChanged($event)"
                [isHsCode]="true"
                [dataCyAttribute]="'product-hs-code'"
              >
              </form-row-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
