import { Component } from '@angular/core'
import { Product, ProductPriceLevel, PriceLevel } from 'depoto-core/src/entities'
import { CoreService } from '../../../services'
import { ProductDetailService } from '../../../product-detail.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'product-price-levels',
  templateUrl: './product-price-levels.component.html',
})
export class ProductPriceLevelsComponent {
  productPriceLevels: ProductPriceLevel[] = []
  productPriceLevelsPercent: ProductPriceLevel[] = []
  productPriceLevelsFixed: ProductPriceLevel[] = []
  priceLevels: PriceLevel[] = []
  loading = true
  isLoaded = false

  constructor(
    public productDetailService: ProductDetailService,
    private core: CoreService,
    private translateService: TranslateService
  ) {
    this.getPriceLevels()
  }

  async getPriceLevels() {
    this.loading = true
    this.priceLevels = await this.core.services.priceLevel
      .getList(
        { page: 1, sort: 'name', direction: 'asc' },
        {
          id: null,
          name: null,
          isPercentage: null,
          percent: null,
          withVat: null,
          currency: {
            id: null,
            name: null,
          },
        }
      )
      .then(result => result?.items.map((pL: any) => new PriceLevel(pL)))
    this.loading = false
    this.isLoaded = true

    for (const priceLevel of this.priceLevels) {
      const priceLevelAlreadyOnProduct = this.productDetailService.product.productPriceLevels.find(
        productPL => productPL.priceLevel.id === priceLevel.id
      )
      if (priceLevel.isPercentage) {
        this.productPriceLevelsPercent.push({
          id: priceLevelAlreadyOnProduct ? priceLevelAlreadyOnProduct.id : null,
          sellPrice: priceLevelAlreadyOnProduct ? priceLevelAlreadyOnProduct.sellPrice : null,
          product: this.productDetailService.product,
          priceLevel: priceLevel,
          externalId: priceLevelAlreadyOnProduct ? priceLevelAlreadyOnProduct.externalId : null,
        })
      } else {
        this.productPriceLevelsFixed.push({
          id: priceLevelAlreadyOnProduct ? priceLevelAlreadyOnProduct.id : null,
          sellPrice: priceLevelAlreadyOnProduct ? priceLevelAlreadyOnProduct.sellPrice : null,
          product: this.productDetailService.product,
          priceLevel: priceLevel,
          externalId: priceLevelAlreadyOnProduct ? priceLevelAlreadyOnProduct.externalId : null,
        })
      }
    }
    setTimeout(() => (this.isLoaded = true), 1000)
  }

  productPriceLevelChanged(sellPrice, productPriceLevel) {
    productPriceLevel.sellPrice = sellPrice
    const isAlreadyInUpdatedPPL = this.productDetailService.updatedProductPriceLevels.find(
      pPL => pPL.priceLevel.id === productPriceLevel.priceLevel.id
    )
    if (isAlreadyInUpdatedPPL) {
      isAlreadyInUpdatedPPL.sellPrice = sellPrice
    } else {
      this.productDetailService.updatedProductPriceLevels.push(productPriceLevel)
    }
  }

  async createProductPriceLevel(productPriceLevel: ProductPriceLevel) {
    if (this.checkProductPriceLevelValidity(productPriceLevel)) {
      this.loading = true
      await this.core.services.productPriceLevel.create(productPriceLevel, { id: null })
      this.productDetailService.refreshProduct()
      setTimeout(() => this.getPriceLevels(), 1000)
      this.loading = false
    }
  }

  private getOverlappingPriceLevel(child: Product, productPriceLevel: ProductPriceLevel): ProductPriceLevel {
    let res = null
    if (child.productPriceLevels && child.productPriceLevels.length) {
      child.productPriceLevels.forEach(p => {
        if (p.priceLevel.id === productPriceLevel.priceLevel.id) {
          // this.notificationService.error(`Chyba při vytváření ceny na variantě! Varianta ${child.fullName} již má nastavenou cenovou skupinu`, null, true);
          res = p
        }
      })
    }
    return res
  }

  async updateProductChildrenWithProductPriceLevel(productPriceLevel: ProductPriceLevel) {
    if (this.checkProductPriceLevelValidity(productPriceLevel)) {
      if (Number(productPriceLevel.sellPrice) !== 0) {
        const promisesCreate = this.productDetailService.product.children
          .filter(child => !this.getOverlappingPriceLevel(child, productPriceLevel))
          .map(child => new ProductPriceLevel({ ...productPriceLevel, product: child }))
          .map(childPrice => this.core.services.productPriceLevel.create(childPrice, { id: null }))
        await Promise.all(promisesCreate)
        const promisesUpdate = this.productDetailService.product.children
          .filter(child => !!this.getOverlappingPriceLevel(child, productPriceLevel))
          .map(
            child =>
              new ProductPriceLevel({
                ...productPriceLevel,
                product: child,
                id: this.getOverlappingPriceLevel(child, productPriceLevel).id,
              })
          )
          .map(childPrice => this.core.services.productPriceLevel.update(childPrice, { id: null }))
        await Promise.all(promisesUpdate)
      }
    }
  }

  async deleteProductPriceLevel(productPriceLevel: ProductPriceLevel) {
    this.loading = true
    try {
      await this.core.services.productPriceLevel.delete(productPriceLevel)
      this.productDetailService.refreshProduct()
      await this.getPriceLevels()
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  checkProductPriceLevelValidity(productPriceLevel: ProductPriceLevel): boolean {
    productPriceLevel.product = this.productDetailService.product
    productPriceLevel.sellPrice = Number(productPriceLevel.sellPrice)
    if (isNaN(productPriceLevel.sellPrice)) {
      alert(this.translateService.instant('product-price-levels.enter-valid-price'))
      return false
    }
    return true
  }
}
