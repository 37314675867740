import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AuthGuardService } from './services'

import { AppLoaderComponent } from './components/_common/app-loader/app-loader.component'
import { AddressesComponent } from './pages/addresses/addresses.component'
import { CategoriesComponent } from './pages/categories/categories.component'
import { CategoryComponent } from './pages/category/category.component'
import { CheckoutComponent } from './pages/checkout/checkout.component'
import { CheckoutsComponent } from './pages/checkouts/checkouts.component'
import { CompanyCarrierComponent } from './pages/company-carrier/company-carrier.component'
import { CompanyCarriersComponent } from './pages/company-carriers/company-carriers.component'
import { CompanySettingsComponent } from './pages/company-settings/company-settings.component'
import { ComponentListComponent } from './pages/component-list/component-list.component'
import { ConsentComponent } from './pages/consent/consent.component'
import { ConsentsComponent } from './pages/consents/consents.component'
import { CustomerComponent } from './pages/customer/customer.component'
import { CustomersComponent } from './pages/customers/customers.component'
import { DepotComponent } from './pages/depot/depot.component'
import { DepotsComponent } from './pages/depots/depots.component'
import { LabelPrintComponent } from './components/label-print/label-print.component'
import { HomeComponent } from './pages/home/home.component'
import { UserComponent } from './pages/user/user.component'
import { UsersComponent } from './pages/users/users.component'
import { UserGroupComponent } from './pages/usergroup/usergroup.component'
import { UserGroupsComponent } from './pages/usergroups/usergroups.component'
import { OrderComponent } from './pages/order/order.component'
import { OrdersComponent } from './pages/orders/orders.component'
import { ParameterComponent } from './pages/parameter/parameter.component'
import { ParametersComponent } from './pages/parameters/parameters.component'
import { PaymentComponent } from './pages/payment/payment.component'
import { PaymentsComponent } from './pages/payments/payments.component'
import { PriceLevelComponent } from './pages/price-level/price-level.component'
import { PriceLevelsComponent } from './pages/price-levels/price-levels.component'
import { PrintInventoryDetailComponent } from './pages/print-inventory-detail/print-inventory-detail.component'
import { PrintInventoryExportsComponent } from './pages/print-inventory-exports/print-inventory-exports.component'
import { PrintOrderExportsComponent } from './pages/print-order-exports/print-order-exports.component'
import { PrintPurchaseExportsComponent } from './pages/print-purchase-exports/print-purchase-exports.component'
import { PrintSaleExportsComponent } from './pages/print-sale-exports/print-sale-exports.component'
import { PrintStockExportsComponent } from './pages/print-stock-exports/print-stock-exports.component'
import { ProducerComponent } from './pages/producer/producer.component'
import { ProducersComponent } from './pages/producers/producers.component'
import { ProductComponent } from './pages/product/product.component'
import { ProductMovePackComponent } from './pages/product-move-pack/product-move-pack.component'
import { ProductMovePacksComponent } from './pages/product-move-packs/product-move-packs.component'
import { ProductsComponent } from './pages/products/products.component'
import { SoldItemsComponent } from './pages/sold-items-component/sold-items.component'
import { StockOperationListComponent } from './pages/stock-operation-list/stock-operation-list.component'
import { SupplierComponent } from './pages/supplier/supplier.component'
import { SuppliersComponent } from './pages/suppliers/suppliers.component'
import { TagComponent } from './pages/tag/tag.component'
import { TagsComponent } from './pages/tags/tags.component'
import { VatComponent } from './pages/vat/vat.component'
import { VatsComponent } from './pages/vats/vats.component'
import { VoucherComponent } from './pages/voucher/voucher.component'
import { VouchersComponent } from './pages/vouchers/vouchers.component'
import { CanDeactivateGuardService } from './services/can-deactivate-guard.service'
import { CustomerSearchComponent } from './components/customer-search/customer-search.component'
import { OrderAddressPageComponent } from './components/order-components/order-address-page/order-address-page.component'
import { PaymentInfoSearchComponent } from './components/payment-info-search/payment-info-search.component'
import { OrderFormComponent } from './components/order-components/order-form/order-form.component'
import { ProductFormComponent } from './components/product-components/product-form/product-form.component'
import { ProductCategoriesComponent } from './components/product-components/product-categories/product-categories.component'
import { ProductPacksComponent } from './components/product-components/product-packs/product-packs.component'
import { ProductDepotsFormComponent } from './components/product-components/product-depots-form/product-depots-form.component'
import { ProductMovesListComponent } from './components/product-components/product-moves-list/product-moves-list.component'
import { ProductImagesComponent } from './components/product-components/product-images/product-images.component'
import { ProductAdvancedPricesFormComponent } from './components/product-components/product-advanced-prices-form/product-advanced-prices-form.component'
import { ProductPriceLevelsComponent } from './components/product-components/product-price-levels/product-price-levels.component'
import { ProductFilesComponent } from './components/product-components/product-files/product-files.component'
import { ProductParametersComponent } from './components/product-components/product-parameters/product-parameters.component'
import { BarcodePrintArrayComponent } from './components/product-components/barcode-print-array/barcode-print-array.component'
import { ProductPurchaseOrderCreationComponent } from './pages/product-purchase-order-creation/product-purchase-order-creation.component'
import { PurchaseOrdersComponent } from './pages/purchase-orders/purchase-orders.component'
import { PrintCodeComponent } from './components/print-code/print-code.component'

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', data: { tempTitle: 'title.home' } },
  { path: 'external-login', canActivate: [AuthGuardService], component: HomeComponent, data: { tempTitle: '' } },
  {
    path: 'addresses',
    canActivate: [AuthGuardService],
    component: AddressesComponent,
    data: { tempTitle: 'title.label-addresses' },
  },
  {
    path: 'atomic-components',
    canActivate: [AuthGuardService],
    component: ComponentListComponent,
    data: { tempTitle: 'Atomic components' },
  },
  {
    path: 'categories',
    canActivate: [AuthGuardService],
    component: CategoriesComponent,
    data: { tempTitle: 'title.categories' },
  },
  {
    path: 'category',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: CategoryComponent,
    data: { tempTitle: 'title.category' },
  },
  {
    path: 'category/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: CategoryComponent,
    data: { tempTitle: 'title.category-id', hasId: true },
  },
  {
    path: 'checkout',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: CheckoutComponent,
    data: { tempTitle: 'title.checkout' },
  },
  {
    path: 'checkout/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: CheckoutComponent,
    data: { tempTitle: 'title.checkout-id', hasId: true },
  },
  {
    path: 'checkouts',
    canActivate: [AuthGuardService],
    component: CheckoutsComponent,
    data: { tempTitle: 'title.checkouts' },
  },
  {
    path: 'company',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: CompanySettingsComponent,
    data: { tempTitle: 'title.company' },
  },
  {
    path: 'company-carrier',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: CompanyCarrierComponent,
    data: { tempTitle: 'title.companyCarrier' },
  },
  {
    path: 'company-carrier/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: CompanyCarrierComponent,
    data: { tempTitle: 'title.companyCarrier-id', hasId: true },
  },
  {
    path: 'company-carriers',
    canActivate: [AuthGuardService],
    component: CompanyCarriersComponent,
    data: { tempTitle: 'title.companyCarriers' },
  },
  {
    path: 'consent',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ConsentComponent,
    data: { tempTitle: 'title.consent' },
  },
  {
    path: 'consent/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ConsentComponent,
    data: { tempTitle: 'title.consent-id', hasId: true },
  },
  {
    path: 'consents',
    canActivate: [AuthGuardService],
    component: ConsentsComponent,
    data: { tempTitle: 'title.consents' },
  },
  {
    path: 'customer',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: CustomerComponent,
    data: { tempTitle: 'title.customer' },
  },
  {
    path: 'customer/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: CustomerComponent,
    data: { tempTitle: 'title.customer-id', hasId: true },
  },
  {
    path: 'customers',
    canActivate: [AuthGuardService],
    component: CustomersComponent,
    data: { tempTitle: 'title.customers' },
  },
  {
    path: 'depot',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: DepotComponent,
    data: { tempTitle: 'title.depot' },
  },
  {
    path: 'depot/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: DepotComponent,
    data: { tempTitle: 'title.depot-id', hasId: true },
  },
  { path: 'depots', canActivate: [AuthGuardService], component: DepotsComponent, data: { tempTitle: 'title.depots' } },
  {
    path: 'group',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: UserGroupComponent,
    data: { tempTitle: 'title.group' },
  },
  {
    path: 'group/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: UserGroupComponent,
    data: { tempTitle: 'title.group-id', hasId: true },
  },
  {
    path: 'groups',
    canActivate: [AuthGuardService],
    component: UserGroupsComponent,
    data: { tempTitle: 'title.groups' },
  },
  { path: 'home', canActivate: [AuthGuardService], component: HomeComponent, data: { tempTitle: 'title.home' } },
  {
    path: 'label-print/:id',
    canActivate: [AuthGuardService],
    component: LabelPrintComponent,
    data: { tempTitle: 'title.label-print', prefix: 'label-print' },
  },
  {
    path: 'label-print',
    redirectTo: 'label-print/box',
    pathMatch: 'full',
    data: { tempTitle: 'title.label-print', prefix: 'label-print' },
  },
  { path: 'loading', component: AppLoaderComponent, data: { tempTitle: 'title.settings' } },
  {
    path: 'my/:profile',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: UserComponent,
    data: { tempTitle: 'title.my-profile' },
  },
  {
    path: 'order',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: OrderComponent,
    data: { tempTitle: 'title.order' },
    children: [
      { path: 'customer', component: CustomerSearchComponent },
      {
        path: 'address',
        component: OrderAddressPageComponent,
      },
      {
        path: 'payment-info',
        component: PaymentInfoSearchComponent,
      },
      {
        path: '',
        component: OrderFormComponent,
      },
      {
        path: '0',
        component: OrderFormComponent,
      },
    ],
  },
  {
    path: 'order/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: OrderComponent,
    data: { tempTitle: 'title.order-id', hasId: true },
    children: [
      {
        path: '',
        component: OrderFormComponent,
      },
    ],
  },
  { path: 'orders', canActivate: [AuthGuardService], component: OrdersComponent, data: { tempTitle: 'title.orders' } },
  {
    path: 'parameter',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ParameterComponent,
    data: { tempTitle: 'title.parameter' },
  },
  {
    path: 'parameter/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ParameterComponent,
    data: { tempTitle: 'title.parameter-id', hasId: true },
  },
  {
    path: 'parameters',
    canActivate: [AuthGuardService],
    component: ParametersComponent,
    data: { tempTitle: 'title.parameters' },
  },
  {
    path: 'payment',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: PaymentComponent,
    data: { tempTitle: 'title.payment' },
  },
  {
    path: 'payment/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: PaymentComponent,
    data: { tempTitle: 'title.payment-id', hasId: true },
  },
  {
    path: 'payments',
    canActivate: [AuthGuardService],
    component: PaymentsComponent,
    data: { tempTitle: 'title.payments' },
  },
  {
    path: 'price-level',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: PriceLevelComponent,
    data: { tempTitle: 'title.price-level' },
  },
  {
    path: 'price-level/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: PriceLevelComponent,
    data: { tempTitle: 'title.price-level-id', hasId: true },
  },
  {
    path: 'price-levels',
    canActivate: [AuthGuardService],
    component: PriceLevelsComponent,
    data: { tempTitle: 'title.price-levels' },
  },
  {
    path: 'print/inventory-detail/:id',
    canActivate: [AuthGuardService],
    component: PrintInventoryDetailComponent,
    data: { tempTitle: 'title.print-inventory-detail-id', hasId: true },
  },
  {
    path: 'print/inventory-exports',
    canActivate: [AuthGuardService],
    component: PrintInventoryExportsComponent,
    data: { tempTitle: 'title.print-inventory-exports' },
  },
  {
    path: 'print/orders-exports',
    canActivate: [AuthGuardService],
    component: PrintOrderExportsComponent,
    data: { tempTitle: 'title.print-orders-exports' },
  },
  {
    path: 'print/purchase-exports',
    canActivate: [AuthGuardService],
    component: PrintPurchaseExportsComponent,
    data: { tempTitle: 'title.print-purchase-exports' },
  },
  {
    path: 'print/sale-exports',
    canActivate: [AuthGuardService],
    component: PrintSaleExportsComponent,
    data: { tempTitle: 'title.print-sale-exports' },
  },
  {
    path: 'print/stock-exports',
    canActivate: [AuthGuardService],
    component: PrintStockExportsComponent,
    data: { tempTitle: 'title.print-stock-exports' },
  },
  {
    path: 'producer',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ProducerComponent,
    data: { tempTitle: 'title.producer' },
  },
  {
    path: 'producer/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ProducerComponent,
    data: { tempTitle: 'title.producer-id', hasId: true },
  },
  {
    path: 'producers',
    canActivate: [AuthGuardService],
    component: ProducersComponent,
    data: { tempTitle: 'title.producers' },
  },
  {
    path: 'product',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ProductComponent,
    data: { tempTitle: 'title.product' },
    children: [
      {
        path: '',
        component: ProductFormComponent,
      },
      {
        path: '0',
        component: ProductFormComponent,
      },
    ],
  },
  {
    path: 'product/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ProductComponent,
    data: { tempTitle: 'title.product-id', hasId: true },
    children: [
      {
        path: '',
        component: ProductFormComponent,
      },
      {
        path: 'stock',
        component: ProductDepotsFormComponent,
      },
      {
        path: 'moves',
        component: ProductMovesListComponent,
      },
      {
        path: 'images',
        component: ProductImagesComponent,
      },
      {
        path: 'files',
        component: ProductFilesComponent,
      },
      {
        path: 'parameters',
        component: ProductParametersComponent,
      },
      {
        path: 'categories',
        component: ProductCategoriesComponent,
      },
      {
        path: 'packing',
        component: ProductPacksComponent,
      },
      {
        path: 'advancedPrices',
        component: ProductAdvancedPricesFormComponent,
      },
      {
        path: 'priceLevels',
        component: ProductPriceLevelsComponent,
      },
      {
        path: 'barcode',
        component: BarcodePrintArrayComponent,
      },
      {
        path: 'properPrint',
        component: PrintCodeComponent,
      },
    ],
  },
  {
    path: 'purchase-order-creation',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ProductPurchaseOrderCreationComponent,
    data: { tempTitle: 'title.purchase-order-creation' },
  },
  {
    path: 'purchase-order-creation/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ProductPurchaseOrderCreationComponent,
    data: { tempTitle: 'title.purchase-order-creation-id' },
  },
  {
    path: 'purchase-orders',
    canActivate: [AuthGuardService],
    component: PurchaseOrdersComponent,
    data: { tempTitle: 'title.purchase-orders' },
  },
  {
    path: 'products',
    canActivate: [AuthGuardService],
    component: ProductsComponent,
    data: { tempTitle: 'title.products' },
  },
  {
    path: 'product-move-pack',
    redirectTo: 'product-move-packs',
    pathMatch: 'full',
    data: { tempTitle: 'title.product-move-packs' },
  },
  {
    path: 'product-move-pack/:id',
    canActivate: [AuthGuardService],
    component: ProductMovePackComponent,
    data: { tempTitle: 'title.product-move-pack-id', hasId: true },
  },
  {
    path: 'product-move-packs',
    canActivate: [AuthGuardService],
    component: ProductMovePacksComponent,
    data: { tempTitle: 'title.product-move-packs' },
  },
  {
    path: 'product-variant-create/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ProductComponent,
    data: { tempTitle: 'title.product-variant-create-id', hasId: true },
  },
  {
    path: 'sold-items',
    canActivate: [AuthGuardService],
    component: SoldItemsComponent,
    data: { tempTitle: 'title.sold-items' },
  },
  {
    path: 'stock-batch',
    canActivate: [AuthGuardService],
    component: StockOperationListComponent,
    data: { tempTitle: 'title.stock-batch' },
  },
  {
    path: 'supplier',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: SupplierComponent,
    data: { tempTitle: 'title.supplier' },
  },
  {
    path: 'supplier/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: SupplierComponent,
    data: { tempTitle: 'title.supplier-id', hasId: true },
  },
  {
    path: 'suppliers',
    canActivate: [AuthGuardService],
    component: SuppliersComponent,
    data: { tempTitle: 'title.suppliers' },
  },
  {
    path: 'tag',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: TagComponent,
    data: { tempTitle: 'title.tag' },
  },
  {
    path: 'tag/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: TagComponent,
    data: { tempTitle: 'title.tag-id', hasId: true },
  },
  { path: 'tags', canActivate: [AuthGuardService], component: TagsComponent, data: { tempTitle: 'title.tags' } },
  {
    path: 'user',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: UserComponent,
    data: { tempTitle: 'title.user' },
  },
  {
    path: 'user/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: UserComponent,
    data: { tempTitle: 'title.user-id', hasId: true },
  },
  { path: 'users', canActivate: [AuthGuardService], component: UsersComponent, data: { tempTitle: 'title.users' } },
  {
    path: 'vat',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: VatComponent,
    data: { tempTitle: 'title.vat' },
  },
  {
    path: 'vat/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: VatComponent,
    data: { tempTitle: 'title.vat-id', hasId: true },
  },
  {
    path: 'vats',
    canActivate: [AuthGuardService],
    component: VatsComponent,
    data: { tempTitle: 'title.vats' },
  },
  {
    path: 'voucher',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: VoucherComponent,
    data: { tempTitle: 'title.voucher' },
  },
  {
    path: 'voucher/:id',
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: VoucherComponent,
    data: { tempTitle: 'title.voucher-id', hasId: true },
  },
  {
    path: 'vouchers',
    canActivate: [AuthGuardService],
    component: VouchersComponent,
    data: { tempTitle: 'title.vouchers' },
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
