import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core'
import * as XLSX from 'xlsx'
import { asyncForEach, fileTemplatesURL } from '../../../utils'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { Product, OrderItem, Supplier, ProductMove } from 'depoto-core/src/entities'
import { CoreService } from '../../../services'
import { Currency } from 'depoto-core/src/entities/Currency'
import { TranslateService } from '@ngx-translate/core'

interface ImportedOrderItem {
  orderItem: OrderItem
  error: string | null
}

const productSchema = {
  id: null,
  name: null,
  fullName: null,
  ean: null,
  code: null,
  purchasePrice: null,
  purchaseCurrency: {
    id: null,
    name: null,
  },
  sellPrice: null,
  actualSellPrice: null,
  enabled: null,
  isFragile: null,
  isOversize: null,
  mainImage: {
    id: null,
    text: null,
    mimeType: null,
    url: null,
    thumbnails: { format: null, mimeType: null, url: null },
  },
  quantityStock: null,
  quantityReservation: null,
  quantityAvailable: null,
}

@Component({
  selector: 'modal-batch-import-from-file',
  templateUrl: 'modal-batch-import-from-file.component.html',
  styles: ['a { cursor: pointer; } .font-size-2rem { font-size: 2rem; }'],
  styleUrls: ['./modal-batch-import-from-file.component.scss'],
})
export class ModalBatchImportFromFileComponent implements OnInit {
  @ViewChild('modal') public modal: ModalContainerComponent
  @Input() public isCreatingProducts = false // imports by default
  @Input() public depotTo: number
  @Input() public suppliers: Supplier[] = []
  @Input() public hideBtn = false
  @Input() public isInProducts = false
  @Output() public resultBatch: EventEmitter<any> = new EventEmitter()
  public loadedFileEv: any
  public fileTypes: string[] = []
  public importedOrderItems: ImportedOrderItem[] = []
  public importedValid: OrderItem[] = []
  public importedInvalid: OrderItem[] = []
  public productsToCreate: Product[]
  public selectedFileType = 'depoto-xls'
  public selectedSupplier: number
  public validityCheckProgress = 0
  public loading = false
  public templatesURL = fileTemplatesURL
  constructor(
    private core: CoreService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.isCreatingProducts) {
      this.fileTypes = ['depoto-xls']
    } else {
      this.fileTypes = ['depoto-xls', 'pohoda-xml', 'money-s3-xml', 'money-s5-xml', 'vfp-xml', 'sap-xls', 'sap2-xls']
    }
    this.selectedFileType = 'depoto-xls'
  }

  showChildModal(): void {
    if (!this.depotTo && !this.isCreatingProducts) {
      alert(this.translateService.instant('modal-batch-import-from-file.select-warehouse'))
      return
    }
    this.modal.showChildModal()
  }

  hideChildModal(): void {
    this.loadedFileEv = null
    this.importedOrderItems = this.importedValid = this.importedInvalid = this.productsToCreate = []
    this.modal.hideChildModal()
  }

  importBatch(): void {
    if (!this.selectedSupplier) {
      alert(this.translateService.instant('modal-batch-import-from-file.select-supplier'))
      return
    }
    if (!this.importedValid || (this.importedValid && this.importedValid.length === 0)) {
      return
    }
    const batch = this.importedValid.map(ioi => {
      return new ProductMove({
        depotTo: this.depotTo,
        supplier: this.selectedSupplier,
        amount: Number(ioi.quantity),
        purchasePrice: Number(ioi.price),
        purchasePriceSum: Number(ioi.price) * Number(ioi.quantity),
        note: null,
        product: ioi.product,
        batch: ioi.batch,
        expirationDate: ioi.expirationDate,
        purchaseCurrency: ioi.purchaseCurrency,
        purchaseCurrencyDate: ioi.purchaseCurrencyDate,
        position1:
          ioi.position && ioi.position.length > 0 && ioi.position.split('-').length > 0 && ioi.position.split('-')[0],
        position2:
          ioi.position && ioi.position.length > 0 && ioi.position.split('-').length > 1 && ioi.position.split('-')[1],
        position3:
          ioi.position && ioi.position.length > 0 && ioi.position.split('-').length > 2 && ioi.position.split('-')[2],
      })
    })
    this.resultBatch.emit(batch)
    this.hideChildModal()
  }

  async createBatch(): Promise<void> {
    if (!this.selectedSupplier) {
      alert(this.translateService.instant('modal-batch-import-from-file.select-supplier'))
      return
    }
    if (!this.productsToCreate || (this.productsToCreate && this.productsToCreate.length === 0)) {
      return
    }
    this.loading = true
    let defaultVat = this.core.getDefaultVat()
    if (!defaultVat) {
      defaultVat = await this.core.services.vat.getDefaultVat()
    }
    this.productsToCreate.forEach(p => {
      p.vat.id = defaultVat.id
      p.enabled = true
    })
    const res = await this.createProductsSequentially()

    this.loading = false
    this.resultBatch.emit(res)
    this.hideChildModal()
  }

  async createProductsSequentially() {
    const results = []
    for (const p of this.productsToCreate) {
      try {
        results.push(await this.core.services.product.create(p))
        //that create-method never returns error messages, it will return undefined instead
      } catch (e) {
        console.warn(e)
      }
    }
    return results.filter(p => p?.id)
  }

  loadFile(event): void {
    this.loadedFileEv = event
  }

  async handleImportFile(): Promise<any> {
    if (!this.loadedFileEv) {
      alert(this.translateService.instant('modal-batch-import-from-file.no-file'))
      return
    }
    this.loading = true
    const fileReaderFn = this.selectedFileType.includes('xml')
      ? 'getTextFromImportedFile'
      : this.selectedFileType.includes('xls')
        ? 'getBinaryFromImportedFile'
        : 'getTextFromImportedFile' // todo add more types ?
    let readFromFile
    try {
      readFromFile = await this[fileReaderFn](this.loadedFileEv)
    } catch (err) {
      console.warn(err)
      alert(
        typeof err === 'string'
          ? err
          : err.message
            ? err.message === "Cannot read property 'childNodes' of undefined"
              ? this.translateService.instant('modal-batch-import-from-file.import.error')
              : err.message
            : JSON.stringify(err)
      )
      this.loading = false
    }
    let parsedItems = []
    if (readFromFile) {
      switch (this.selectedFileType) {
        case 'pohoda-xml':
          parsedItems = this.parsePohodaXml(readFromFile)
          break
        case 'money-s3-xml':
          parsedItems = this.parseMoneyS3dataXml(readFromFile)
          break
        case 'money-s5-xml':
          parsedItems = this.parseMoneyS5dataXml(readFromFile)
          break
        case 'vfp-xml':
          parsedItems = this.parseVfpXml(readFromFile)
          break
        case 'sap-xls':
          parsedItems = this.parseSapXls(readFromFile)
          break
        case 'sap2-xls':
          parsedItems = this.parseSap2Xls(readFromFile)
          break
        case 'depoto-xls':
          if (this.isCreatingProducts) {
            parsedItems = await this.parseDepotoXlsVariants(readFromFile)
            return
          } else {
            parsedItems = this.parseDepotoXls(readFromFile)
          }
          break
      }
    }
    if (!parsedItems.length) {
      throw Error('no parsed data')
    }
    const isQuantityOk = (it: ImportedOrderItem) => it.orderItem.quantity > 0
    this.checkProductsOnServer(parsedItems).then((res: ImportedOrderItem[]) => {
      this.importedOrderItems = res
      this.importedValid = res.filter(it => !it.error && isQuantityOk(it)).map(it => it.orderItem)
      this.importedInvalid = res.filter(it => !!it.error || !isQuantityOk(it)).map(it => it.orderItem)
      this.loading = false
    })
  }

  getTextFromImportedFile(inputEvent: any): Promise<string | any> {
    return new Promise((resolve, reject) => {
      const file = inputEvent.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.readAsText(new Blob([file])) // plaintext
        reader.onload = evt => {
          resolve(evt.target['result'])
        }
        reader.onerror = () => {
          reject(this.translateService.instant('file-processing-error', { name: file.name }))
        }
      } else {
        reject(this.translateService.instant('no-file'))
      }
    })
  }

  getBinaryFromImportedFile(inputEvent: any): Promise<string | any> {
    return new Promise((resolve, reject) => {
      const file = inputEvent.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = evt => {
          resolve(evt.target.result)
        }
        reader.onerror = () => {
          reject(this.translateService.instant('file-processing-error', { name: file.name }))
        }
      } else {
        reject(this.translateService.instant('no-file'))
      }
    })
  }

  protected parsePohodaXml(text: string): Array<OrderItem> {
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(text, 'text/xml')
    const invoiceDetails = xmlDoc.getElementsByTagName('inv:invoiceDetail')
    const invoiceItems = []
    ;[].forEach.call(invoiceDetails, (invoiceDetail: any) => {
      ;[].forEach.call(invoiceDetail.childNodes, (n: any) => {
        if (n?.prefix === 'inv') {
          const orderItem = new OrderItem()
          orderItem.product = new Product()
          orderItem.name =
            n.getElementsByTagName('inv:text').length > 0 ? n.getElementsByTagName('inv:text')[0].innerHTML : null
          orderItem.product.fullName =
            n.getElementsByTagName('inv:text').length > 0 ? n.getElementsByTagName('inv:text')[0].innerHTML : null
          orderItem.code =
            n.getElementsByTagName('inv:code').length > 0 ? n.getElementsByTagName('inv:code')[0].innerHTML : null
          orderItem.product.code =
            n.getElementsByTagName('inv:code').length > 0 ? n.getElementsByTagName('inv:code')[0].innerHTML : null
          orderItem.quantity = Number(
            n.getElementsByTagName('inv:quantity').length > 0 ? n.getElementsByTagName('inv:quantity')[0].innerHTML : 1
          )
          if (n.getElementsByTagName('inv:homeCurrency') && n.getElementsByTagName('inv:homeCurrency').length > 0) {
            orderItem.price =
              Number(
                n.getElementsByTagName('inv:homeCurrency')[0].getElementsByTagName('typ:price')[0]
                  ? n.getElementsByTagName('inv:homeCurrency')[0].getElementsByTagName('typ:price')[0].innerHTML
                  : ''
              ) / orderItem.quantity
            orderItem.priceAll = Number(
              n.getElementsByTagName('inv:homeCurrency')[0].getElementsByTagName('typ:price')[0]
                ? n.getElementsByTagName('inv:homeCurrency')[0].getElementsByTagName('typ:price')[0].innerHTML
                : ''
            )
          }
          orderItem.ean =
            n.getElementsByTagName('typ:stockItem') &&
            n.getElementsByTagName('typ:stockItem').length > 0 &&
            n.getElementsByTagName('typ:stockItem')[0].getElementsByTagName('typ:EAN')[0]
              ? n.getElementsByTagName('typ:stockItem')[0].getElementsByTagName('typ:EAN')[0].innerHTML
              : ''
          invoiceItems.push(orderItem)
        }
      })
    })
    return invoiceItems
  }

  protected parseMoneyS3dataXml(text: string): Array<OrderItem> {
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(text, 'text/xml')
    const invoiceDetail = xmlDoc.getElementsByTagName('Polozky')[0].childNodes
    const invoiceItems = []
    ;[].forEach.call(invoiceDetail, (n: any) => {
      if (n?.nodeName === 'PolozkaFakturyVydane') {
        const orderItem = new OrderItem()
        orderItem.product = new Product()
        orderItem.name = n.getElementsByTagName('Nazev')[0].innerHTML
        orderItem.product.fullName = n.getElementsByTagName('Nazev')[0].innerHTML
        orderItem.code = n.getElementsByTagName('Katalog')[0].innerHTML
        orderItem.product.code = n.getElementsByTagName('Katalog')[0].innerHTML
        orderItem.quantity = Number(n.getElementsByTagName('Mnozstvi')[0].innerHTML)
        orderItem.price = Number(n.getElementsByTagName('JednCenaBezDPH')[0].innerHTML)
        orderItem.priceAll = Number(n.getElementsByTagName('CelkovaCena')[0].innerHTML)
        invoiceItems.push(orderItem)
      }
    })
    return invoiceItems
  }

  protected parseMoneyS5dataXml(text: string): Array<OrderItem> {
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(text, 'text/xml')
    const invoiceDetail = xmlDoc.getElementsByTagName('Polozky')[0].childNodes
    const invoiceItems = []
    ;[].forEach.call(invoiceDetail, (n: any) => {
      if (n?.nodeName === 'PolozkaFakturyVydane') {
        const orderItem = new OrderItem()
        orderItem.product = new Product()
        orderItem.name = n.getElementsByTagName('Nazev')[0].innerHTML
        orderItem.product.fullName = n.getElementsByTagName('Nazev')[0].innerHTML
        orderItem.code = n.getElementsByTagName('Katalog')[0].innerHTML
        orderItem.product.code = n.getElementsByTagName('Katalog')[0].innerHTML
        orderItem.quantity = Number(n.getElementsByTagName('Mnozstvi')[0].innerHTML)
        orderItem.price = Number(n.getElementsByTagName('JednCenaBezDPH')[0].innerHTML)
        orderItem.priceWithoutVat = Number(n.getElementsByTagName('JednCenaBezDPH')[0].innerHTML)
        orderItem.priceAll = Number(n.getElementsByTagName('CelkovaCena')[0].innerHTML)
        invoiceItems.push(orderItem)
      }
    })
    return invoiceItems
  }

  protected parseVfpXml(text: string): Array<OrderItem> {
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(text, 'text/xml')
    const invoiceDetail = xmlDoc.getElementsByTagName('VFPDataSet')[0].childNodes
    const invoiceItems = []
    ;[].forEach.call(invoiceDetail, (n: any) => {
      if (n?.nodeName === 'c_polozky') {
        const orderItem = new OrderItem()
        orderItem.product = new Product()
        orderItem.name = n.getElementsByTagName('text')[0].innerHTML
        orderItem.product.fullName = n.getElementsByTagName('text')[0].innerHTML
        orderItem.code = n.getElementsByTagName('klic')[0].innerHTML
        orderItem.product.code = n.getElementsByTagName('klic')[0].innerHTML
        orderItem.ean = n.getElementsByTagName('ean')[0].innerHTML
        orderItem.product.ean = n.getElementsByTagName('ean')[0].innerHTML
        orderItem.quantity = Number(n.getElementsByTagName('mnozstvi')[0].innerHTML)
        orderItem.price = Number(n.getElementsByTagName('cenaj_edi')[0].innerHTML)
        orderItem.priceWithoutVat = Number(n.getElementsByTagName('cenaj_edi')[0].innerHTML)
        orderItem.priceAll = Number(n.getElementsByTagName('cena')[0].innerHTML)
        invoiceItems.push(orderItem)
      }
    })
    return invoiceItems
  }

  protected parseSapXls(binaryString: string): Array<OrderItem> {
    const wb: XLSX.WorkBook = XLSX.read(binaryString, { type: 'binary' })
    const invoiceItems = []
    wb.SheetNames.forEach(sheetName => {
      const ws: XLSX.WorkSheet = wb.Sheets[sheetName]
      const data: Array<Array<any>> | any = XLSX.utils.sheet_to_json(ws, { header: 1 })
      const castToStr = val => (typeof val === 'string' ? val : typeof val === 'number' ? val.toFixed(0) : val)
      data.forEach((n, i) => {
        if (i > 0) {
          const orderItem = new OrderItem()
          orderItem.product = new Product()
          orderItem.name = castToStr(n[1])
          orderItem.product.fullName = castToStr(n[1])
          orderItem.code = castToStr(n[1])
          orderItem.product.code = castToStr(n[1])
          orderItem.ean = castToStr(n[2])
          orderItem.product.ean = castToStr(n[2])
          orderItem.quantity = Number(n[3])
          orderItem.priceWithoutVat = Number(n[4].replace('CZK ', '').replace(',', '.'))
          orderItem.priceAllWithoutVat = Number(n[7].replace('CZK ', '').replace(',', '.'))
          invoiceItems.push(orderItem)
        }
      })
    })
    return invoiceItems
  }

  protected parseSap2Xls(binaryString: string): Array<OrderItem> {
    const wb: XLSX.WorkBook = XLSX.read(binaryString, { type: 'binary' })
    const invoiceItems = []
    wb.SheetNames.forEach(sheetName => {
      const ws: XLSX.WorkSheet = wb.Sheets[sheetName]
      const data: Array<Array<any>> | any = XLSX.utils.sheet_to_json(ws, { header: 1 })
      const castToStr = val => (typeof val === 'string' ? val : typeof val === 'number' ? val.toFixed(0) : val)
      data.forEach((n, i) => {
        if (i > 0) {
          const orderItem = new OrderItem()
          orderItem.product = new Product()
          orderItem.name = castToStr(n[2])
          orderItem.product.fullName = castToStr(n[2])
          orderItem.code = castToStr(n[1])
          orderItem.product.code = castToStr(n[1])
          orderItem.ean = castToStr(n[0])
          orderItem.product.ean = castToStr(n[0])
          orderItem.quantity = Number(n[4])
          orderItem.price = Number(n[5])
          invoiceItems.push(orderItem)
        }
      })
    })
    return invoiceItems
  }

  protected parseDepotoXls(typedArray: Uint8Array): Array<OrderItem> {
    const wb: XLSX.WorkBook = XLSX.read(typedArray, { cellDates: true })
    const invoiceItems = []
    wb.SheetNames.forEach(sheetName => {
      const ws: XLSX.WorkSheet = wb.Sheets[sheetName]
      const data: Array<Array<any>> | any = XLSX.utils.sheet_to_json(ws, { header: 1 })
      const castToStr = val => (typeof val === 'string' ? val : typeof val === 'number' ? val.toFixed(0) : val)
      data.forEach((n, i) => {
        if (
          i > 0 &&
          ((!!n[0] && castToStr(n[0]).length > 0) ||
            (!!n[1] && castToStr(n[1]).length > 0) ||
            (!!n[7] && castToStr(n[7]).length > 0))
        ) {
          const expDate = n[4] ? new Date(n[4]) : null
          if (expDate) {
            expDate.setHours(12)
          }
          const dateDUZP = n[10] ? new Date(n[10]) : null
          if (dateDUZP) {
            dateDUZP.setHours(12)
          }
          const orderItem = new OrderItem()
          orderItem.product = new Product()
          orderItem.name = castToStr(n[7])
          orderItem.product.fullName = castToStr(n[7])
          orderItem.code = castToStr(n[1])
          orderItem.product.code = castToStr(n[1])
          orderItem.ean = castToStr(n[0])
          orderItem.product.ean = castToStr(n[0])
          orderItem.quantity = Number(n[2])
          orderItem.price = Number(n[5])
          orderItem.priceAll = Number(n[2]) * Number(n[5])
          orderItem.batch = n[3]
          orderItem.purchaseCurrency = castToStr(n[9])
          if (
            orderItem.purchaseCurrency &&
            (orderItem.purchaseCurrency.toLowerCase() === 'czk' || orderItem.purchaseCurrency.toLowerCase() === 'kč')
          ) {
            orderItem.purchaseCurrency = 'CZK'
          } else if (orderItem.purchaseCurrency && orderItem.purchaseCurrency.toLowerCase() === 'eur') {
            orderItem.purchaseCurrency = 'EUR'
          } else if (orderItem.purchaseCurrency && orderItem.purchaseCurrency.toLowerCase() === 'gbp') {
            orderItem.purchaseCurrency = 'GBP'
          } else if (orderItem.purchaseCurrency && orderItem.purchaseCurrency.toLowerCase() === 'usd') {
            orderItem.purchaseCurrency = 'USD'
          }
          orderItem.purchaseCurrencyDate = dateDUZP ? dateDUZP.toISOString().substr(0, 10) : null
          orderItem.expirationDate = expDate ? expDate.toISOString().substr(0, 10) : null
          if (!!n[11] && n[11].length > 0) {
            orderItem.position = n[11]
          }
          invoiceItems.push(orderItem)
        }
      })
    })
    return invoiceItems
  }

  protected async parseDepotoXlsVariants(typedArray: Uint8Array): Promise<Array<Product>> {
    const wb: XLSX.WorkBook = XLSX.read(typedArray, { cellDates: true })
    const variants = []

    await asyncForEach(wb.SheetNames, async sheetName => {
      const ws: XLSX.WorkSheet = wb.Sheets[sheetName]
      const data: Array<Array<any>> | any = XLSX.utils.sheet_to_json(ws, { header: 1 })
      const castToStr = val =>
        typeof val === 'string' ? val : typeof val === 'number' ? val.toFixed(0) : val ? val : ''
      await asyncForEach(data, async (n, i) => {
        if (i > 0) {
          const variant = new Product()
          variant.name = castToStr(n[7])
          variant.code = castToStr(n[1])
          variant.ean = castToStr(n[0])
          variant.purchasePrice = !isNaN(Number(n[5])) ? Number(n[5]) : 0
          variant.sellPrice = !isNaN(Number(n[6])) ? Number(n[6]) : 0
          const parentEan = castToStr(n[8])
          if (parentEan && parentEan.length > 0) {
            const parentRes = await this.core.services.product.getList({ filters: { ean: parentEan } }, productSchema)
            if (parentRes.items && parentRes.items.length > 0) {
              variant.parent = parentRes.items[0]
            }
          }
          let purchaseCurrency = castToStr(n[9])
          if (
            purchaseCurrency &&
            (purchaseCurrency.toLowerCase() === 'czk' || purchaseCurrency.toLowerCase() === 'kč')
          ) {
            purchaseCurrency = 'CZK'
          } else if (purchaseCurrency && purchaseCurrency.toLowerCase() === 'eur') {
            purchaseCurrency = 'EUR'
          } else if (purchaseCurrency && purchaseCurrency.toLowerCase() === 'gbp') {
            purchaseCurrency = 'GBP'
          } else if (purchaseCurrency && purchaseCurrency.toLowerCase() === 'usd') {
            purchaseCurrency = 'USD'
          }
          if (purchaseCurrency) {
            variant.purchaseCurrency = new Currency({ id: purchaseCurrency })
          }
          variants.push(variant)
        }
      })
    })
    this.loading = false
    this.productsToCreate = variants.filter(v => v.name?.length > 0)
    return variants
  }

  protected async checkProductsOnServer(oItems: OrderItem[]): Promise<Array<ImportedOrderItem>> {
    this.validityCheckProgress = 0
    const result: ImportedOrderItem[] = []
    let x = 0
    for (const oi of oItems) {
      let res = null
      if (oi.ean && oi.ean.length > 0) {
        res = await this.core.services.product.getList({ filters: { ean: oi.ean } }, productSchema)
      } else if (oi.code && oi.code.length > 0) {
        res = await this.core.services.product.getList({ filters: { code: oi.code } }, productSchema)
      } else if (oi.name && oi.name.length > 0) {
        res = await this.core.services.product.getList({ filters: { fulltext: oi.name } }, productSchema)
      }
      this.validityCheckProgress = (x + 1) / (oItems.length / 100)
      oItems[x].product = res && res.items && res.items.length > 0 ? res.items[0] : null
      result.push({
        orderItem: oItems[x],
        error: res && res.items && res.items.length > 0 ? null : 'Product does not exists!',
      })
      x++
    }
    return result
  }
}
