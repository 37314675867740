<div class="container-fluid">
  <div class="row" *ngIf="!isInModal">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 h-25 ps-0">
      <div class="card">
        <div class="row">
          <div class="col">
            <form-row-input
              [icon]="'far-user'"
              [label]="'customer.search'"
              [(model)]="searchPhrase"
              (onKeyPress)="$event && $event.key === 'Enter' ? searchForCustomer() : null"
              [appendBtnIcon]="'far-search'"
              [appendBtnTitle]="'customer.search' | translate"
              [appendBtnClasses]="['btn-search']"
              (onAppendBtnEvent)="searchForCustomer()"
              [dataCyAttribute]="'searchForCustomer'"
            ></form-row-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <form-row-toggle
              *ngIf="hasRoleSalesman"
              [label]="'customer-search.all'"
              [model]="searchInAllCustomers"
              (modelChange)="searchInAllCustomers = $event; searchForCustomer()"
              [classesString]="'text-nowrap'"
              [dataCyAttribute]="'toggle-searchInAllCustomers'"
            ></form-row-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <modal-customer
              [isCreating]="true"
              (customerChange)="selectCustomer($event)"
              [dataCyAttribute]="'modal-customer'"
            ></modal-customer>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 pe-0">
      <div
        class="card"
        [ngClass]="{ h150: !customers.length && isLoading, 'pb-0': customers && customers.length === 0 && !isLoading }"
      >
        <loading-spinner [isLoading]="isLoading && pageNumber === 1" [classesString]="'top-25'"></loading-spinner>
        <div class="row h150" *ngIf="customers && customers.length === 0 && !isLoading">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center align-items-center">
            <div class="empty-list">
              <i class="far fa-sad-tear"></i>
              <div>
                {{ 'no.records' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table class="table mb-0">
                <tbody>
                  <tr *ngFor="let customer of customers; trackBy: trackById">
                    <td class="ps-1">
                      <div class="circle">
                        <span class="initials">
                          {{ customer.firstName && customer.firstName.length ? customer.firstName[0] : ''
                          }}{{ customer.lastName && customer.lastName.length ? customer.lastName[0] : '' }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div>{{ customer.companyName?.length > 0 ? customer.companyName : '' }}</div>
                      <div>
                        {{ customer?.firstName?.length > 0 ? customer.firstName + ' ' : '' }}
                        {{ customer?.lastName?.length > 0 ? customer.lastName : '' }}
                      </div>
                    </td>
                    <td>
                      {{ customer.email && customer.email.length > 0 ? customer.email : '' }}
                    </td>
                    <td>
                      {{ customer.tags && customer.tags.length ? getTagNames(customer) : '' }}
                    </td>
                    <td>
                      {{ customer.priceLevel && customer.priceLevel.id > 0 ? customer.priceLevel.name : '' }}
                    </td>
                    <td class="pe-1 text-end fixed-column-width">
                      <btn
                        [icon]="'far-check'"
                        [label]="'select'"
                        [classes]="['btn-second', 'btn-sm']"
                        (onEvent)="selectCustomer(customer)"
                        [dataCyAttribute]="'select-customer'"
                      ></btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="pageNumber < maxPageNumber">
          <div class="col d-flex justify-content-center">
            <button class="btn w-50" (click)="searchForCustomer(1)" id="customer-search-btn-next">
              <i class="spinner-border text-primary spinner-border-sm" role="status" *ngIf="isLoading"></i>
              {{ 'load.next' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--HTML STRUCTURE FOR MODAL CUSTOMER SEARCH ON ORDERS-->
  <div class="container-fluid" *ngIf="isInModal">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 h-25 ps-0 pe-0">
        <div class="card">
          <form-row-input
            [icon]="'fa-user'"
            [label]="'customer.search'"
            [(model)]="searchPhrase"
            (onKeyPress)="$event && $event.key === 'Enter' ? searchForCustomer() : null"
            [appendBtnIcon]="'far-search'"
            [appendBtnTitle]="'customer.search' | translate"
            [appendBtnClasses]="['btn-search']"
            (onAppendBtnEvent)="searchForCustomer()"
            [dataCyAttribute]="'searchForCustomer'"
          ></form-row-input>
          <form-row-toggle
            class="text-nowrap"
            *ngIf="hasRoleSalesman"
            [label]="'customer-search.all'"
            [model]="searchInAllCustomers"
            (modelChange)="searchInAllCustomers = $event; searchForCustomer()"
          ></form-row-toggle>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ps-0 pe-0">
        <div
          class="card"
          [ngClass]="{
            h150: !customers.length && isLoading,
            'pb-0': customers && customers.length === 0 && !isLoading
          }"
        >
          <loading-spinner [isLoading]="isLoading && pageNumber === 1" [classesString]="'top-25'"></loading-spinner>
          <div class="row h150" *ngIf="customers && customers.length === 0 && !isLoading">
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center align-items-center"
            >
              <div class="empty-list">
                <i class="far fa-sad-tear"></i>
                <div>
                  {{ 'no.records' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="table-responsive">
                <table class="table w-100 mb-0">
                  <tbody>
                    <tr *ngFor="let customer of customers; trackBy: trackById">
                      <td class="ps-1">
                        <div class="circle">
                          <span class="initials">
                            {{ customer.firstName && customer.firstName.length ? customer.firstName[0] : ''
                            }}{{ customer.lastName && customer.lastName.length ? customer.lastName[0] : '' }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div>{{ customer.companyName?.length > 0 ? customer.companyName : '' }}</div>
                        <div>
                          {{ customer?.firstName?.length > 0 ? customer.firstName + ' ' : '' }}
                          {{ customer?.lastName?.length > 0 ? customer.lastName : '' }}
                        </div>
                      </td>
                      <td data-cy="customer-search-td-email">
                        {{ customer.email && customer.email.length > 0 ? customer.email : '' }}
                      </td>
                      <td>
                        {{ customer.tags && customer.tags.length ? getTagNames(customer) : '' }}
                      </td>
                      <td>
                        {{ customer.priceLevel && customer.priceLevel.id > 0 ? customer.priceLevel.name : '' }}
                      </td>
                      <td class="pe-1 text-end fixed-column-width">
                        <btn
                          [icon]="'far-check'"
                          [label]="'select'"
                          [title]="'select' | translate"
                          [classes]="['btn-second', 'btn-sm']"
                          (onEvent)="selectCustomer(customer)"
                          [dataCyAttribute]="'modal-customer-search-btn-add'"
                        ></btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="pageNumber < maxPageNumber">
            <div class="col d-flex justify-content-center">
              <button class="btn w-50" (click)="searchForCustomer(1)">
                <i class="spinner-border text-primary spinner-border-sm" role="status" *ngIf="isLoading"></i>
                {{ 'load.next' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
