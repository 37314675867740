<div class="row" style="padding-top: 70px">
  <div class="col-12 col-md-6">
    <div class="ms-3">
      <div class="mb-3">
        <form-row-input
          [(model)]="posA"
          (modelChange)="generateBatch()"
          [inputDisabled]="isPrinting"
          [icon]="'fa-list'"
          [label]="'part1'"
        ></form-row-input>
        <form-row-input
          *ngIf="labelType === 'position'"
          [(model)]="posB"
          (modelChange)="generateBatch()"
          [inputDisabled]="isPrinting"
          [icon]="'fa-list'"
          [label]="'part2'"
        ></form-row-input>
        <form-row-input
          *ngIf="labelType === 'position'"
          [(model)]="posC"
          (modelChange)="generateBatch()"
          [inputDisabled]="isPrinting"
          [icon]="'fa-list'"
          [label]="'part3'"
        ></form-row-input>
        <form-row-select
          *ngIf="labelType === 'position'"
          [options]="[
            { id: 0, name: '1. část' },
            { id: 1, name: '2. část' },
            { id: 2, name: '3. část' }
          ]"
          [(model)]="genPos"
          (modelChange)="generateBatch()"
          [inputDisabled]="isPrinting"
          [icon]="'fa-list'"
          [label]="'part3'"
        ></form-row-select>
        <form-row-input
          [inputType]="'number'"
          [(model)]="genRangeFrom"
          (modelChange)="generateBatch()"
          [inputDisabled]="isPrinting"
          [icon]="'fa-arrow-up'"
          [label]="'date.from'"
        ></form-row-input>
        <form-row-input
          [inputType]="'number'"
          [(model)]="genRangeTo"
          (modelChange)="generateBatch()"
          [inputDisabled]="isPrinting"
          [icon]="'fa-arrow-down'"
          [label]="'date.to'"
        ></form-row-input>
        <form-row-input
          [inputType]="'number'"
          [(model)]="genLength"
          (modelChange)="generateBatch()"
          [inputDisabled]="isPrinting"
          [icon]="'fa-arrow-down'"
          [label]="'length'"
        ></form-row-input>
        <btn
          [classes]="['btn-first']"
          (onEvent)="printBatch()"
          [btnDisabled]="isPrinting"
          [icon]="'fa-print'"
          [label]="'print'"
          [title]="'print' | translate"
        ></btn>
      </div>
    </div>
    <div class="invisible">
      <canvas #barcode id="barcode"></canvas>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <p>{{ 'label-print:labels' | translate: { count: codes.length } }}</p>
    <ul>
      <li *ngFor="let c of codes">
        {{ c.code }}
      </li>
    </ul>
  </div>
</div>
