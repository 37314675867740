<div class="app-container">
  <page-header [icon]="'far-shopping-cart'" [title]="'orders'">
    <ng-container class="header-button">
      <btn
        *ngIf="canCreate"
        [routerLink]="['/order/customer']"
        [label]="'order.create'"
        [title]="'order.create' | translate"
        [classes]="['btn-first', 'float-right']"
        [icon]="'far-plus'"
        [dataCyAttribute]="'create-order'"
      ></btn>
      <app-exports *ngIf="canCreate" #exportsModal [entity]="'orders'"></app-exports>
      <modal-orders-import
        [carriers]="carriers"
        [checkouts]="checkouts"
        [payments]="payments"
        (onOrdersImported)="filter(1)"
        [dataCyAttribute]="'imports'"
      ></modal-orders-import>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form
      [filters]="queryFilters"
      [loading]="loading"
      [entity]="'orders'"
      [isExportButtonShown]="true"
      [totalItems]="totalItems"
      [(filterVisible)]="filtersExpanded"
      (onSubmit)="filter()"
      (onClearClick)="clearFilters()"
      (onExportResult)="filter()"
      (onCreateModalHidden)="exportsModal.showChildModal()"
      (filterVisibleChange)="filterVisibilityChanged($event)"
    >
      <div class="container-fluid ps-0 pe-0">
        <!--      FIRST ROW OF INPUTS-->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <form-row-input
              [label]="'fulltext'"
              [icon]="null"
              [(model)]="filters.fulltext"
              (keyup.enter)="filter()"
              [dataCyAttribute]="'order-name-filter'"
            ></form-row-input>
          </div>
          <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <form-row-input
              [label]="'orders.dateCreated.from'"
              [icon]="null"
              [inputType]="'date'"
              [model]="filters.dateCreated?.left_date"
              (modelChange)="filters.dateCreated.left_date = $event"
              [dataCyAttribute]="'orders-date-created-from-filter'"
            ></form-row-input>
          </div>
          <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <form-row-input
              [label]="'orders.dateCreated.to'"
              [icon]="null"
              [inputType]="'date'"
              [model]="filters.dateCreated?.right_date"
              (modelChange)="filters.dateCreated.right_date = $event"
              [dataCyAttribute]="'orders-date-created-to-filter'"
            ></form-row-input>
          </div>
          <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <form-row-select
              [label]="'processStatus'"
              [icon]="null"
              [(model)]="filters.processStatus"
              [options]="processStatesOptions"
              [dataCyAttribute]="'orders-process-status-filter'"
            ></form-row-select>
          </div>
          <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <modal-customer-search [(customer)]="filters.customer" [isOnOrdersPage]="true"></modal-customer-search>
          </div>
        </div>
        <!--      SECOND ROW OF INPUTS-->
        <div class="custom-dropdown" [ngClass]="{ open: filtersExpanded }">
          <div class="row">
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'orders.reservationNumber'"
                [icon]="null"
                [pattern]="'^[0-9]+$'"
                [(model)]="filters.reservationNumber"
                (keyup.enter)="filter()"
                [dataCyAttribute]="'orders-reservation-number-filter'"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'orders.billNumber'"
                [icon]="null"
                [pattern]="'^[0-9]+$'"
                [(model)]="filters.billNumber"
                (keyup.enter)="filter()"
                [dataCyAttribute]="'orders-bill-number-filter'"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'package'"
                [icon]="null"
                [(model)]="filters.package"
                (keyup.enter)="filter()"
                [dataCyAttribute]="'orders-package-filter'"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'item'"
                [icon]="null"
                [(model)]="filters.item"
                (keyup.enter)="filter()"
                [dataCyAttribute]="'orders-item-filter'"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-select-multiple
                [label]="'tags'"
                [options]="tags"
                [(selectedValues)]="filters.tags"
                [dataCyAttribute]="'orders-tags-filter'"
              ></form-row-select-multiple>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-select
                [label]="'order.status'"
                [icon]="null"
                [(model)]="filters.status"
                [options]="statusTypesOptions"
                [dataCyAttribute]="'orders-status-filter'"
              ></form-row-select>
            </div>
          </div>
          <!--      THIRD ROW OF INPUTS-->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-select
                [label]="'hasAvailableProducts'"
                [icon]="null"
                [(model)]="filters.hasAvailableProducts"
                [options]="hasAvailableProductsOptions"
                [dataCyAttribute]="'orders-hasAvailableProducts-filter'"
              ></form-row-select>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-select
                [label]="'carriers'"
                [icon]="null"
                [(model)]="filters.carrier"
                [options]="carriers"
                [dataCyAttribute]="'orders-carrier-filter'"
              ></form-row-select>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-select-multiple
                [label]="'payments'"
                [options]="payments"
                [(selectedValues)]="filters.payments"
                [dataCyAttribute]="'orders-payments-filter'"
              ></form-row-select-multiple>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-select-multiple
                [label]="'products.checkouts'"
                [options]="checkouts"
                [(selectedValues)]="filters.checkouts"
                [dataCyAttribute]="'orders-checkout-filter'"
              ></form-row-select-multiple>
            </div>
            <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <form-row-select-multiple
                [label]="'products.depots'"
                [options]="depots"
                [(selectedValues)]="filters.depots"
                [dataCyAttribute]="'orders-depots-filter'"
              ></form-row-select-multiple>
            </div>
            <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <form-row-select
                [label]="'isPaid'"
                [options]="isPaidOptions"
                [(model)]="filters.isPaid"
                [dataCyAttribute]="'orders-isPaid-filter'"
              ></form-row-select>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'dateExpedition.from'"
                [icon]="null"
                [inputType]="'date'"
                [model]="filters.dateExpedition?.left_date"
                (modelChange)="filters.dateExpedition.left_date = $event"
                [dataCyAttribute]="'orders-date-expedition-from-filter'"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'dateExpedition.to'"
                [icon]="null"
                [inputType]="'date'"
                [model]="filters.dateExpedition?.right_date"
                (modelChange)="filters.dateExpedition.right_date = $event"
                [dataCyAttribute]="'orders-date-expedition-to-filter'"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <form-row-select
                [label]="'priority'"
                [options]="priorityOptions"
                [(model)]="filters.priority"
                [dataCyAttribute]="'orders-priority-filter'"
              ></form-row-select>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-select-multiple
                [label]="'createdBy'"
                [(selectedValues)]="filters.createdBy"
                [options]="users"
                [dataCyAttribute]="'order-createdBy-filter'"
              ></form-row-select-multiple>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'orders.dateBill.from'"
                [icon]="null"
                [inputType]="'date'"
                [model]="filters.dateBill?.left_date"
                (modelChange)="filters.dateBill.left_date = $event"
                [dataCyAttribute]="'orders-date-bill-from-filter'"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'orders.dateBill.to'"
                [icon]="null"
                [inputType]="'date'"
                [model]="filters.dateBill?.right_date"
                (modelChange)="filters.dateBill.right_date = $event"
                [dataCyAttribute]="'orders-date-bill-to-filter'"
              ></form-row-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'orders.dateTax.from'"
                [icon]="null"
                [inputType]="'date'"
                [model]="filters.dateTax?.left_date"
                (modelChange)="filters.dateTax.left_date = $event"
                [dataCyAttribute]="'orders-date-tax-from-filter'"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'orders.dateTax.to'"
                [icon]="null"
                [inputType]="'date'"
                [model]="filters.dateTax?.right_date"
                (modelChange)="filters.dateTax.right_date = $event"
                [dataCyAttribute]="'orders-date-tax-to-filter'"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'orders.dateDue.from'"
                [icon]="null"
                [inputType]="'date'"
                [model]="filters.dateDue?.left_date"
                (modelChange)="filters.dateDue.left_date = $event"
                [dataCyAttribute]="'orders-date-due-from-filter'"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'orders.dateDue.to'"
                [icon]="null"
                [inputType]="'date'"
                [model]="filters.dateDue?.right_date"
                (modelChange)="filters.dateDue.right_date = $event"
                [dataCyAttribute]="'orders-date-due-to-filter'"
              ></form-row-input>
            </div>
          </div>
        </div>
      </div>
    </app-filter-form>
    <app-base-listing
      #baseListingComponent
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="orders"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [isColumnSettingVisible]="true"
      [(sortProp)]="sortProp"
      [(sortDir)]="sortDir"
      [listName]="'orders'"
      (reloadList)="filter()"
      (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
      (callAction)="callAction($event)"
      (selectedColumnsChange)="onSelectedColumnsChange($event)"
    ></app-base-listing>
  </section>
</div>
