import { Component, OnInit } from '@angular/core'
import { OrderDetailService } from '../../services/order-detail.service'
import { Payment, PaymentItem } from 'depoto-core/src/entities'

@Component({
  selector: 'app-payment-info-search',
  templateUrl: './payment-info-search.component.html',
  styleUrls: ['./payment-info-search.component.scss'],
})
export class PaymentInfoSearchComponent implements OnInit {
  constructor(public orderDetailService: OrderDetailService) {}

  ngOnInit() {
    if (this.orderDetailService.checkouts?.length === 1) {
      this.orderDetailService.order.checkout = this.orderDetailService.checkouts[0]
    }
    if (this.orderDetailService.payments?.length === 1) {
      this.orderDetailService.order.paymentItems.push(new PaymentItem({ payment: this.orderDetailService.payments[0] }))
    }
    if (this.orderDetailService.carriers?.length === 1) {
      this.orderDetailService.order.carrier = this.orderDetailService.carriers[0]
    }
  }

  public paymentSelected(payment: Payment): void {
    this.orderDetailService.order.paymentItems[0] = new PaymentItem({
      payment: new Payment(payment),
      amount: 0,
    })
  }
}
