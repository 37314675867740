<div class="app-container">
  <page-header [icon]="'far-exchange'" [title]="'product.moves'">
    <ng-container class="header-button">
      <app-exports #exportsModal [entity]="'product-move-packs'" data-cy="exports"></app-exports>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form
      [filters]="queryFilters"
      [loading]="loading || reloading"
      (onSubmit)="filter()"
      (onClearClick)="clearFilters()"
      (onCreateModalHidden)="exportsModal.showChildModal()"
      [filterVisible]="filtersExpanded"
      [isExportButtonShown]="true"
      [entity]="'product-move-packs'"
      (filterVisibleChange)="filtersExpanded = $event; changeDetector.detectChanges()"
    >
      <div class="row">
        <div class="col-sm-4 col-md-2">
          <form-row-input
            [label]="'fulltext'"
            (keydown.enter)="filter()"
            [icon]="null"
            [(model)]="filters.fulltext"
            [dataCyAttribute]="'product-move-pack-fulltext-filter'"
          ></form-row-input>
        </div>
        <div class="col-sm-4 col-md-2">
          <form-row-select
            [label]="'stock.load.type' | translate"
            [icon]="null"
            [options]="operationTypes"
            [(model)]="filters.type"
            [dataCyAttribute]="'product-move-pack-type-filter'"
          ></form-row-select>
        </div>
        <div class="col-sm-4 col-md-2">
          <form-row-select
            [label]="'depotFrom'"
            [icon]="null"
            [options]="depots"
            [(model)]="filters.depotFrom"
            [dataCyAttribute]="'depot-from-filter'"
          ></form-row-select>
        </div>
        <div class="col-sm-4 col-md-2">
          <form-row-select
            [label]="'depotTo'"
            [icon]="null"
            [options]="depots"
            [(model)]="filters.depotTo"
            [dataCyAttribute]="'depot-to-filter'"
          ></form-row-select>
        </div>
        <div class="col-sm-4 col-md-2">
          <form-row-input
            [label]="'date.from'"
            [icon]="null"
            [inputType]="'date'"
            [(model)]="filters.dateFrom"
          ></form-row-input>
        </div>
        <div class="col-sm-4 col-md-2">
          <form-row-input
            [label]="'date.to'"
            [icon]="null"
            [inputType]="'date'"
            [(model)]="filters.dateTo"
          ></form-row-input>
        </div>
      </div>
      <div class="custom-dropdown" [ngClass]="{ open: filtersExpanded }">
        <div class="row">
          <div class="col-sm-4 col-md-2">
            <form-row-select-multiple
              [label]="'tags'"
              [options]="tags"
              [(selectedValues)]="filters.tags"
              [dataCyAttribute]="'tags-filter'"
            ></form-row-select-multiple>
          </div>
          <div class="col-sm-4 col-md-2">
            <form-row-select
              [label]="'products.checkouts'"
              [icon]="null"
              [options]="checkouts"
              [(model)]="filters.checkout"
              [inputDisabled]="filters.type === 'transfer'"
              [title]="filters.type === 'transfer' ? ('filters.disabled' | translate) : ''"
            ></form-row-select>
          </div>
          <div class="col-sm-4 col-md-2">
            <form-row-select
              [label]="'withOrder'"
              [icon]="null"
              [options]="hasOrderTypes"
              [(model)]="filters.withOrder"
              [inputDisabled]="filters.type === 'transfer'"
              [title]="filters.type === 'transfer' ? ('filters.disabled' | translate) : ''"
            ></form-row-select>
          </div>
          <div class="col-sm-4 col-md-2">
            <form-row-input
              [label]="'orderFulltext.number'"
              (keydown.enter)="filter()"
              [icon]="null"
              [(model)]="filters.orderFulltext"
            ></form-row-input>
          </div>
          <div class="col-sm-4 col-md-2">
            <form-row-select
              [label]="'createdBy'"
              [icon]="null"
              [options]="users"
              [optionKey]="'id'"
              [optionKeyText]="'name'"
              [(model)]="filters.createdBy"
            ></form-row-select>
          </div>
          <div class="col-sm-4 col-md-2">
            <form-row-input
              [label]="'operationNumber'"
              [pattern]="'^[0-9]+$'"
              (keydown.enter)="filter()"
              [icon]="null"
              [(model)]="filters.number"
            ></form-row-input>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8 col-md-2">
            <form-row-input
              [label]="'productFulltext'"
              (keydown.enter)="filter()"
              [icon]="null"
              [(model)]="filters.productFulltext"
            ></form-row-input>
          </div>
        </div>
      </div>
    </app-filter-form>

    <app-base-listing
      [listName]="'productMoves'"
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="productMovePacks"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [(sortProp)]="sortProp"
      [(sortDir)]="sortDir"
      (reloadList)="filter()"
      (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
      (callAction)="callAction($event)"
    ></app-base-listing>
  </section>
</div>
