import { Component, OnInit } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { Address } from 'depoto-core/src/entities'
import { CoreService } from '../../services'
import { SchemaUtil } from '../../utils'

const DEFAULT_FILTERS = {
  customer: '',
}

@Component({
  selector: 'app-addresses',
  templateUrl: 'addresses.component.html',
})
export class AddressesComponent extends BaseListing implements OnInit {
  listingColumns: ListingItem[] = [
    { name: 'firstName', property: 'firstName', propType: 'string', sortable: true, sortBy: 'firstName' },
    { name: 'lastName', property: 'lastName', propType: 'string', sortable: true, sortBy: 'lastName' },
    { name: 'email', property: 'email', propType: 'string', sortable: true, sortBy: 'email' },
    { name: 'phone', property: 'phone', propType: 'string', sortable: true, sortBy: 'phone' },
    { name: 'city', property: 'city', propType: 'string', sortable: true, sortBy: 'city' },
    { name: 'street', property: 'street', propType: 'string', sortable: true, sortBy: 'street' },
    {
      name: 'edit',
      property: '',
      propType: 'modal-address',
      sortable: false,
      sortBy: '',
      btnStyle: 'second',
      icon: 'chevron-right',
    },
  ]
  addresses: Address[] = []
  filters = { ...DEFAULT_FILTERS }
  sortProp = 'lastName'
  sortDir = 'asc'

  constructor(protected core: CoreService) {
    super(core)
  }

  ngOnInit() {
    this.filters = this.loadViewSettings('addresses') || { ...DEFAULT_FILTERS }
    this.filter()
  }

  clearFilters(): void {
    this.filters = { ...DEFAULT_FILTERS }
    this.filter(1)
  }

  async filter(
    pageNo = 1,
    sortProp: string = this.sortProp,
    sortDir: string = this.sortDir,
    concat = false
  ): Promise<void> {
    this.loading = true
    this.currentPage = pageNo
    if (!concat) {
      this.reloading = true
    }
    try {
      const receivedData = await this.core.services.address.getList(
        { page: pageNo, sort: sortProp, direction: sortDir, filters: this.filters },
        SchemaUtil.address
      )
      if (!receivedData?.items) {
        return
      }
      if (!concat) {
        this.addresses = receivedData.items
      } else {
        receivedData.items.forEach(res => {
          this.addresses.push(res)
        })
      }
      this.totalItems = receivedData.paginator ? receivedData.paginator.totalCount : 0
      this.endPage = receivedData.paginator ? receivedData.paginator.endPage : 0
      this.saveViewSettings('addresses', this.filters)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }
}
