import { Component, ViewChild, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Export, ExportType } from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { CoreService, NotificationService } from '../../../services'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'modal-export-create',
  templateUrl: 'modal-export-create.component.html',
  styleUrls: ['modal-export-create.component.scss'],
})
export class ModalExportCreateComponent implements OnInit {
  @ViewChild('modal') childModal: ModalContainerComponent
  @Input() filters: any
  @Input() entity: string
  @Input() dataCyAttribute: string
  @Output() onResult: EventEmitter<any> = new EventEmitter()
  type = ''
  types: ExportType[] = []
  note = ''
  loading = false
  headerTitle: string
  filtersWithValues = {}
  constructor(
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.headerTitle = `exports.${this.entity}`
  }

  async getTypes() {
    this.loading = true
    this.types = await this.core.services.export
      .getTypes(
        {},
        {
          id: null,
          name: null,
          format: null,
          group: null,
        }
      )
      .then(res => res.items || [])
    try {
      this.types = this.types.filter(t => t.group === this.entity)
      this.type = this.types[0].id
    } catch (e) {
      console.warn(e)
    }
    this.loading = false
  }

  async create(): Promise<void> {
    const options: any = {}
    const entries = Object.entries(this.filters)
    for (const [key, value] of entries) {
      if (
        (value !== '' &&
          value !== null &&
          key !== undefined &&
          value !== 'all' &&
          key !== 'dateFrom' &&
          key !== 'dateTo') ||
        (Array.isArray(value) && value.length)
      ) {
        options[key] = value
      } else if ((key === 'dateFrom' || key === 'dateTo') && value) {
        options.created = {
          ...(this.filters.dateFrom?.length && {
            left_date: this.filters.dateFrom,
          }),
          ...(this.filters.dateTo?.length && {
            right_date: this.filters.dateTo + 'T23:59:59',
          }),
        }
      }
    }
    const exportObject = new Export({
      type: { id: this.type },
      note: this.note,
      filter: JSON.stringify(options),
    })
    try {
      await this.core.services.export.create(exportObject, { id: null }).then(result => {
        this.notificationService.success(this.translateService.instant('alert.export'))
        this.childModal.hideChildModal()
        this.onResult.emit(result)
      })
    } catch (err) {
      console.error(err)
    }
  }

  getFilters() {
    this.filtersWithValues = {}
    for (const [key, value] of Object.entries(this.filters)) {
      // olga's refucktoring: test this shit lol. surprise! not the same result
      // if ((val && !Array.isArray(val)) || typeof val === 'boolean' || (Array.isArray(val) && val.length)) {
      //   this.filtersWithValues[key] = val
      // }
      if (
        value !== undefined &&
        value !== null &&
        value !== 0 &&
        value !== '' &&
        ((Array.isArray(value) && value.length > 0) || !Array.isArray(value))
      ) {
        this.filtersWithValues[key] = value
      }
    }
    return
  }

  showChildModal(): void {
    this.getTypes()
    this.getFilters()
    this.childModal.showChildModal()
  }
}
