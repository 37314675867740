import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute, Params } from '@angular/router'
import { InventoryExport, File, ProductMovePack } from 'depoto-core/src/entities'
import { OptionsConfig } from '../../configs'
import { SchemaUtil } from '../../utils'
import { CoreService, NotificationService } from '../../services'
import { DateUtil } from 'depoto-core/src/utils/DateUtil'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-print-inventory-detail',
  templateUrl: 'print-inventory-detail.component.html',
})
export class PrintInventoryDetailComponent implements OnInit, OnDestroy {
  inventoryExport: InventoryExport
  isCreating = false
  loading = false
  error = false
  refreshInterval: any = null
  constructor(
    private route: ActivatedRoute,
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      const id = +params['id']
      if (isNaN(id)) {
        this.isCreating = true
        this.inventoryExport = new InventoryExport()
        this.refreshData(null)
      } else {
        this.refreshData(id)
      }
    })
  }

  ngOnDestroy() {
    this.stopRefreshing()
  }

  async refreshData(id?: number) {
    if (!id) {
      return
    }
    this.loading = true
    this.inventoryExport = new InventoryExport(
      await this.core.services.inventoryExport.getById(id, SchemaUtil.inventoryExport)
    )
    if (!this.inventoryExport.generated || !this.isGeneratedPmps()) {
      this.startRefreshing()
    } else {
      this.stopRefreshing()
    }
    this.loading = false
  }

  handleFileUpload(event): void {
    if (event.target.files[0] && this.inventoryExport.id > 0) {
      this.loading = true
      this.inventoryExport.generated = null
      const reader = new FileReader()
      reader.readAsDataURL(new Blob([event.target.files[0]]))
      reader.onload = async evt => {
        const filename = event.target.value.split(/(\\|\/)/g).pop()
        const file: File = {
          id: null,
          text: filename,
          originalFilename: filename,
          mimeType: event.target.files[0].type,
          base64Data: String(evt.target['result']).replace('data:;base64,', ''),
          main: null,
          url: null,
          size: null,
          product: null,
          order: null,
          thumbnails: [],
        }
        try {
          await this.core.services.file.create(file, null, null, null, this.inventoryExport.id).then(() => {
            this.notificationService.success(this.translateService.instant('alert.file.uploaded'))
          })
          await this.refreshData(this.inventoryExport.id)
        } catch (err) {
          console.error(err)
        } finally {
          this.loading = false
        }
      }
      reader.onerror = function () {
        // todo
        // alert(processingError)
      }
    }
  }

  async approveInventory() {
    this.loading = true
    try {
      this.inventoryExport.approved = DateUtil.getDateStringFromUTCString(new Date().toUTCString())
      this.inventoryExport = new InventoryExport(
        await this.core.services.inventoryExport.update(this.inventoryExport).then(result => {
          this.notificationService.success(this.translateService.instant('alert.inventory.approved'))
        })
      )
      this.startRefreshing()
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async rejectInventory() {
    this.loading = true
    this.inventoryExport.rejected = DateUtil.getDateStringFromUTCString(new Date().toUTCString())
    this.inventoryExport = new InventoryExport(await this.core.services.inventoryExport.update(this.inventoryExport))
    this.loading = false
  }

  startRefreshing(): void {
    if (!this.refreshInterval) {
      this.refreshInterval = setInterval(() => {
        this.refreshData(this.inventoryExport.id)
      }, OptionsConfig.strings.refreshIntervalTimeMS)
    }
  }

  stopRefreshing(): void {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
  }

  downloadGenerated(): void {
    if (
      this.inventoryExport &&
      this.inventoryExport.generated &&
      this.inventoryExport.generated.length > 0 &&
      this.inventoryExport.url &&
      this.inventoryExport.url.length > 0
    ) {
      window.open(this.inventoryExport.url)
    }
  }

  downloadGeneratedFinal(): void {
    if (
      this.inventoryExport &&
      this.inventoryExport.generated &&
      this.inventoryExport.generated.length > 0 &&
      this.inventoryExport.files &&
      this.inventoryExport.files.length > 0
    ) {
      window.open(this.inventoryExport.files[this.inventoryExport.files.length - 1].url)
    }
  }

  downloadProductMovePack(pmp: ProductMovePack): void {
    if (pmp && pmp.url && pmp.url.length > 0) {
      window.open(pmp.url)
    }
  }

  async refreshExportInventory() {
    const schema = {
      id: null,
      generated: false,
      files: [],
    }
    this.inventoryExport = await this.core.services.inventoryExport.refreshExportInventory(this.inventoryExport, schema)
    this.startRefreshing()
  }

  isGeneratedFinal(): boolean {
    return (
      this.inventoryExport.files &&
      this.inventoryExport.files.length > 0 &&
      this.inventoryExport.generated &&
      this.inventoryExport.generated.length > 0
    )
  }

  isGeneratedPmps(): boolean {
    return !(!!this.inventoryExport.approved && !this.inventoryExport.finished)
  }
}
