import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { ConsentUtil, historyBack } from '../../utils'
import { Customer, CustomerConsent, Consent, User, PriceLevel, Tag, Address } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../services'
import { SchemaUtil } from '../../utils'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { FmtDatePipe } from '../../pipes'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit, CheckDataChanges {
  // @ViewChild('addConsentModal') addConsentModal!: TemplateRef<any>
  customer: Customer
  consents: Consent[] = []
  salesmen: User[]
  priceLevels: PriceLevel[] = []
  tags: Tag[] = []
  newAddress: Address = new Address({ isStored: true, country: 'CZ' })
  isCreating = false
  loading = true
  submitted = false
  isShowingSalesmen = true
  isInCypressTest = true

  customerForm = new FormGroup({
    email: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.email]),
    firstName: new FormControl({ value: '', disabled: false }, [Validators.required]),
    lastName: new FormControl({ value: '', disabled: false }, [Validators.required]),
    companyName: new FormControl({ value: '', disabled: false }, []),
    phone: new FormControl({ value: '', disabled: false }, []),
    addresses: new FormControl({ value: [], disabled: false }, []),
    wholesale: new FormControl({ value: false, disabled: false }, []),
    birthday: new FormControl({ value: '', disabled: false }, []),
    minExpirationDays: new FormControl({ value: 0, disabled: false }, []),
    priceLevel: new FormControl({ value: 0, disabled: false }, []),
  })

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {
    this.isInCypressTest = this.core.services.user.user.email === 'test.cypress.client@tomatom.cz'
  }

  async ngOnInit() {
    const id = this.route.snapshot.params['id']
    if (isNaN(id)) {
      this.isCreating = true
      this.customer = new Customer()
      this.loading = false
    } else {
      await this.refreshData(id)
    }
    if (this.core.services.user.hasRole('ROLE_SALESMAN') && this.isCreating)
      this.salesmen = [this.core.services.user.user]
    else
      this.salesmen = await this.core.services.user
        .getList({}, { id: null, firstName: null, lastName: null, email: null })
        .then(result => result.items)
    this.priceLevels = await this.core.services.priceLevel
      .getList({}, { id: null, name: null })
      .then(result => result.items)
    this.tags = this.core.lists.tags.filter(t => t.type === 'customer')
    this.setIsShowingSalesmen()
  }

  goBack(): void {
    historyBack()
  }

  setIsShowingSalesmen() {
    this.isShowingSalesmen =
      this.salesmen?.length &&
      (!this.core.services.user.hasRole('ROLE_SALESMAN') ||
        (this.core.services.user.hasRole('ROLE_SALESMAN') && this.isCreating))
  }

  public fillFormValue() {
    this.customerForm.setValue({
      email: this.customer.email || '',
      firstName: this.customer.firstName || '',
      lastName: this.customer.lastName || '',
      companyName: this.customer.companyName || '',
      phone: this.customer.phone || '',
      addresses: this.customer.addresses || [],
      wholesale: this.customer.wholesale || false,
      birthday: this.customer.birthday ? this.getTransformedDate(this.customer.birthday) : '',
      minExpirationDays: this.customer.minExpirationDays || 0,
      priceLevel: this.customer?.priceLevel?.id || null,
    })
  }

  getTransformedDate(date: string) {
    const filterPipe = new FmtDatePipe()
    return filterPipe.transform(date, 'yyyy-MM-dd')
  }

  public getFormValue() {
    this.customer.email = this.customerForm.value.email
    this.customer.firstName = this.customerForm.value.firstName
    this.customer.lastName = this.customerForm.value.lastName
    this.customer.companyName = this.customerForm.value.companyName
    this.customer.phone = this.customerForm.value.phone
    this.customer.addresses = this.customerForm.value.addresses
    this.customer.wholesale = this.customerForm.value.wholesale
    this.customer.birthday = this.customerForm.value.birthday
    this.customer.minExpirationDays = this.customerForm.value.minExpirationDays
    this.customer.priceLevel = <any>{ id: this.customerForm.value.priceLevel }
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  async refreshData(id?: number) {
    this.loading = true
    this.customer = new Customer(await this.core.services.customer.getById(id, SchemaUtil.customer))
    if (id && id > 0) {
      this.fillFormValue()
    }
    this.shortenBirthdayDate()
    this.loading = false
  }

  async createCustomer() {
    this.submitted = true
    if (this.customerForm.invalid) {
      return
    }
    this.loading = true
    try {
      this.getFormValue()
      this.shortenBirthdayDate()
      await this.core.services.customer.create(this.customer, { id: null }).then(result => {
        this.notificationService.success(this.translateService.instant('alert.customer.added'))
        this.shortenBirthdayDate()
        this.router.navigate(['/customer/', result.id], { replaceUrl: true })
      })
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
    }
  }

  public async updateCustomer() {
    this.submitted = true
    if (this.customerForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValue()
      this.shortenBirthdayDate()
      await this.core.services.customer.update(this.customer, { id: null }).then(result => {
        this.notificationService.success(this.translateService.instant('alert.customer.updated'))
        this.shortenBirthdayDate()
        this.refreshData(result.id)
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async deleteCustomer() {
    if (!this.customer || !this.customer.id) {
      alert(this.translateService.instant('customer.error.contact-admin'))
      return
    }
    if (
      !confirm(
        this.translateService.instant('customer.delete.confirmation', {
          name: this.customer.firstName + ' ' + this.customer.lastName,
        })
      )
    ) {
      return
    }
    try {
      await this.core.services.customer.delete(this.customer).then(() => {
        this.notificationService.success(this.translateService.instant('alert.customer.deleted'))
        historyBack()
      })
    } catch (err) {
      console.error(err)
    }
  }

  async deleteCustomerConsent(customerConsent: CustomerConsent) {
    if (confirm(this.translateService.instant('customer.delete.confirmation.consent'))) {
      try {
        await this.core.services.consent.deleteCustomerConsent(customerConsent).then(() => {
          this.notificationService.success(this.translateService.instant('alert.customer.consent.deleted'))
          this.refreshData(this.customer.id)
        })
      } catch (err) {
        console.error(err)
      }
    }
  }

  printCustomerConsent(customerConsent: CustomerConsent): void {
    customerConsent.customer = this.customer
    ConsentUtil.printCustomerConsent(customerConsent)
  }

  public isSalesmanSelected(salesman: User): boolean {
    return this.customer.users?.map(user => user.id).includes(salesman.id)
  }

  public toggleSalesmanSelected(salesman: User) {
    if (this.isSalesmanSelected(salesman)) {
      this.customer.users = this.customer.users.filter(user => user.id !== salesman.id)
    } else {
      this.customer.users.push(new User({ id: salesman.id }))
    }
  }

  onTagsChange(event) {
    this.customer.tags = this.tags.filter(t => event.includes(t.id))
  }

  shortenBirthdayDate() {
    if (this.customer.birthday && this.customer.birthday.length > 0) {
      this.customer.birthday = this.customer.birthday.substring(0, 10)
    }
  }

  addAddress(address: Address): void {
    if (!this.customer.addresses || this.customer.addresses?.length === 0) {
      this.customer.addresses = []
    }
    try {
      this.customer.addresses.push(
        new Address({
          ...address,
          ...{
            isStored: true,
          },
        })
      )
      // this.notificationService.success(this.translateService.instant('alert.address.added'))
      this.refreshData(this.customer.id)
    } catch (err) {
      console.error(err)
    }
  }
  get formControls(): { [key: string]: AbstractControl } {
    return this.customerForm.controls
  }
}
