import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Order, File, PurchaseOrder } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../../services'
import { FileUtilService } from '../../../utils/file-util.service'
import { SchemaUtil, unknownMimeType, getFulfilled, getRejected } from '../../../utils'
import { TranslateService } from '@ngx-translate/core'
import { rmSync } from 'fs'
import { includes } from 'lodash'

@Component({
  selector: 'app-order-files',
  templateUrl: './order-files.component.html',
  styleUrls: ['./order-files.component.scss'],
})
export class OrderFilesComponent {
  @Input() order: Order
  @Output() orderChange: EventEmitter<any> = new EventEmitter()
  @Input() purchaseOrder: PurchaseOrder
  @Output() purchaseOrderChange: EventEmitter<any> = new EventEmitter()
  @Input() isEditedFiles = false
  @Input() isOnPurchaseOrder = false
  @Output() onFileDelete: EventEmitter<any> = new EventEmitter()
  @Output() onFileAdded: EventEmitter<File> = new EventEmitter()
  @Output() areFilesUploadingChange: EventEmitter<any> = new EventEmitter()
  public areFilesUploading = false

  constructor(
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private fileUtil: FileUtilService
  ) {}

  unknownType(s?: string) {
    return unknownMimeType(s)
  }

  uploadFiles(inputEvent: Event): Promise<Array<File> | any> {
    this.areFilesUploading = true
    this.areFilesUploadingChange.emit(this.areFilesUploading)
    const target = inputEvent.target as HTMLInputElement
    return new Promise((resolve, reject) => {
      try {
        if (target.files?.length) {
          const promisesBase64s = Array.from(target.files).map(f => this.fileUtil.processUploadedFileToBase64(f))
          Promise.allSettled(promisesBase64s)
            .then(base64s => {
              const fulfilledBase64s = getFulfilled<File>(base64s)
              const promisesUploads = fulfilledBase64s.map(b => {
                return this.core.services.file.create(new File(b), null, null, null, null, null, SchemaUtil.file)
              })
              return Promise.allSettled(promisesUploads).then(res => {
                const fulfilled = getFulfilled<File>(res)
                if (fulfilled.length) {
                  this.notificationService.success(this.translateService.instant('alert.file.uploaded'))
                  this.isEditedFiles = true
                  fulfilled.forEach(r => this.onFileAdded.emit(r))
                }
                if (fulfilled.length < res.length) {
                  const rejected = getRejected<File>(res)
                  this.notificationService.error(
                    this.translateService.instant('alert.file.removed') + '\n' + rejected.join('\n')
                  )
                }
              })
            })
            .then((resolvedUpladedFiles: File[] | void) => {
              this.areFilesUploading = false
              this.areFilesUploadingChange.emit(this.areFilesUploading)
            })
            .catch(e => console.warn(`OrderDetail: uploadFiles err: ${e.stack}`))
        } else {
          this.areFilesUploading = false
          this.areFilesUploadingChange.emit(this.areFilesUploading)
          reject(this.translateService.instant('no-files'))
        }
      } catch (e) {
        alert(this.translateService.instant('file-processing-error') + ' ' + e.message)
        console.warn(e)
      }
    })
  }

  async deleteFile(file: File) {
    if (!confirm(this.translateService.instant('delete-file.confirmation', { name: file.originalFilename }))) {
      return
    }
    try {
      if (this.isOnPurchaseOrder) {
        this.purchaseOrder.files = this.purchaseOrder.files.filter(f => f.id !== file.id)
      } else {
        this.order.files = this.order.files.filter(f => f.id !== file.id)
      }
      await this.core.services.file.delete(file).then(r => {
        this.notificationService.success(this.translateService.instant('alert.file.removed'))
      })
    } catch (err) {
      console.error(err)
    }
  }
}
