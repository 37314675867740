import { Component, Input, Output, EventEmitter, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { generateUuidV4 } from 'depoto-core/src/utils'
import { CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, FormRowBase } from '../form-row-base/form-row-base'

@Component({
  selector: 'form-row-checkbox',
  templateUrl: 'form-row-checkbox.component.html',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR(FormRowCheckboxComponent)],
})
export class FormRowCheckboxComponent extends FormRowBase implements OnInit {
  @ViewChild('formRow') formRow: ElementRef
  @Input() id: string // it uses generateUuidV4 later, let it be as it is in order for labels to be tied to inputs
  @Input() isOnProductsPage = false
  @Input() label: string
  @Input() tooltipLabel: string
  @Input() tooltipIcon: string
  @Input() inputDisabled = false
  @Input() cursorPointer = false
  @Input() noMarginBottom = false
  @Input() noWrap = false
  @Input() model: boolean
  @Output() modelChange: EventEmitter<any> = new EventEmitter()
  @Input() trackCategory = 'event-click-checkbox'
  @Input() trackAction: string
  @Input() formControlName: string
  @Input() formGroup: FormGroup
  @Input() isInBulkEdits = false
  @Input() isInOrderProducts = false
  @Input() dataCySelector: string
  constructor() {
    super()
  }

  ngOnInit() {
    if (!this.trackAction) {
      this.trackAction = this.label
    }
    if (!this.id) {
      this.id = generateUuidV4()
    }
    this.initFormControl()
  }

  onNgModelChange(value: boolean): void {
    this.onChange({ type: 'checkbox', target: { value } })
    this.modelChange.emit(value)
  }
}
