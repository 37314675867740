<div class="app-container">
  <page-header [icon]="'far-tag'" [title]="'parameters'">
    <ng-container class="header-button">
      <btn
        *ngIf="canCreate"
        [routerLink]="['/parameter']"
        [classes]="['btn-first', 'float-right']"
        [icon]="'far-plus'"
        [label]="'parameter.create'"
        [title]="'parameter.create' | translate"
        [dataCyAttribute]="'go-parameter'"
      ></btn>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form
      [filters]="filters"
      [entity]="'parameters'"
      (onSubmit)="filter()"
      (onClearClick)="clearFilters()"
      [isFilterInvisible]="true"
    >
      <form-row-input
        [(model)]="filters.name"
        [icon]="'far-list'"
        [label]="'payment.name'"
        (keydown.enter)="filter()"
        [dataCyAttribute]="'parameter-name-filter'"
      ></form-row-input>
    </app-filter-form>

    <app-base-listing
      [listName]="'parameters'"
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="parameters"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [(sortProp)]="sortProp"
      [(sortDir)]="sortDir"
      (reloadList)="filter(1)"
      (switchPage)="switchPage($event)"
      (callAction)="callAction($event)"
    ></app-base-listing>
  </section>
</div>
