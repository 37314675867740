import { Component, ChangeDetectorRef, EventEmitter, Input, Output, ViewChild, AfterViewChecked } from '@angular/core'
import { CoreService } from '../../../services'
import { TranslateService } from '@ngx-translate/core'
import { ExportsComponent } from '../../../pages/exports/exports.component'
import { kMaxLength } from 'buffer'

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss'],
})
export class FilterFormComponent implements AfterViewChecked {
  @ViewChild('exportsModal') exportsModal: ExportsComponent
  @Input() filters: any
  @Input() loading: boolean
  @Input() entity: string
  @Input() filterVisible = false
  @Input() isFilterInvisible = false
  @Input() areExportsInFilter = false
  @Input() areAllProductsChecked = false
  @Input() checkedProducts = []
  @Input() tags = []
  @Input() isInProducts = false
  @Input() isPurchaseOrderShown = false
  @Input() public isExportButtonShown = false
  @Input() totalItems: number
  @Output() filterVisibleChange: EventEmitter<boolean> = new EventEmitter()
  @Output() onSubmit: EventEmitter<any> = new EventEmitter()
  @Output() onExportResult: EventEmitter<any> = new EventEmitter()
  @Output() onClearClick: EventEmitter<any> = new EventEmitter()
  @Output() setCheckedProductsToFalse: EventEmitter<any> = new EventEmitter()
  @Output() onPurchaseOrderClick: EventEmitter<void> = new EventEmitter()
  @Output() onCreateModalHidden: EventEmitter<void> = new EventEmitter()
  wasChecked = false

  constructor(
    private core: CoreService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewChecked() {
    if (this.loading || this.wasChecked || !this.filters) {
      return
    }
    const filledInFilters = Object.entries(this.filters)
      .map(([k, v]) =>
        (Array.isArray(v) && v.length) ||
        (typeof v === 'boolean' && (this.entity !== 'product-move-packs' || k !== 'withoutCheckout')) ||
        (!Array.isArray(v) &&
          typeof v !== 'boolean' &&
          (v || v === 0) &&
          v !== 'all' &&
          v !== 'children_and_parents_without_children' &&
          (k !== 'checkout' || this.entity !== 'product-move-packs'))
          ? k
          : undefined
      )
      .filter(x => x)
    // filters.checkout in product-move-packs may be '0', 'withoutCheckout', and a number :/
    if ((this.filters.checkout && this.filters.checkout != 0) || this.filters.withoutCheckout) {
      filledInFilters.push('checkouts')
    }
    this.wasChecked = true
    if (
      [...document.querySelectorAll('.dropdown [data-track-action]')]
        ?.map(x => (x as HTMLElement).getAttribute('data-track-action'))
        .some(y => filledInFilters.filter(f => y.includes(f)).length)
    ) {
      // if there exists at least one hidden filled-in filter...
      this.triggerOnVisibilityChange()
    }
  }
  public triggerOnSubmit() {
    this.core.services.http.abortAllRequests()
    this.onSubmit.emit(true)
  }

  public triggerOnClear() {
    this.core.services.http.abortAllRequests()
    this.onClearClick.emit(true)
  }

  public triggerExportResult(result: any) {
    this.onExportResult.emit(result)
  }

  public triggerOnVisibilityChange() {
    this.filterVisible = !this.filterVisible
    this.filterVisibleChange.emit(this.filterVisible)
  }

  refreshProduct() {
    this.onSubmit.emit()
  }

  setCheckedProductsToFalseMethod() {
    this.setCheckedProductsToFalse.emit()
  }
  public checkIfAtLeastOneItemIsSelected(): void {
    if (!this.areAllProductsChecked && this.checkedProducts?.length < 1) {
      alert(this.translateService.instant('filter-form.activate.at.least.one.product'))
      return
    } else {
      this.onPurchaseOrderClick.emit()
    }
  }
}
