<div class="app-container">
  <page-header [title]="'dashboard'" [icon]="'fa-chart-pie'" [fullWidthPageTitle]="true"></page-header>

  <section class="content">
    <div class="row" *ngIf="isChartsPermitted">
      <ng-template [ngIf]="checkouts && checkouts.length > 0">
        <div *ngFor="let checkout of checkouts" class="col-12 col-lg-6">
          <app-charts-stats-checkout [checkout]="checkout"></app-charts-stats-checkout>
        </div>
      </ng-template>
    </div>
  </section>
</div>
