<div class="app-container">
  <page-header [icon]="'far-envelope'" [title]="'companyCarriers'">
    <ng-container class="header-button">
      <btn
        *ngIf="canCreate"
        [routerLink]="['/company-carrier']"
        [classes]="['btn-first', 'float-right']"
        [icon]="'far-plus'"
        [label]="'companyCarrier.create'"
        [title]="'companyCarrier.create' | translate"
        [dataCyAttribute]="'go-carrier'"
      ></btn>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form
      [filters]="filters"
      [entity]="'company-carriers'"
      (onSubmit)="filter()"
      (onClearClick)="clearFilters()"
      [isFilterInvisible]="true"
      [areExportsInFilter]="false"
    >
      <form-row-select
        [label]="'carrier'"
        [icon]="'far-list'"
        [model]="filters.carrier"
        [options]="carriers"
        (modelChange)="updateFilterCarrier($event)"
        [dataCyAttribute]="'carrier-name-filter'"
      ></form-row-select>
    </app-filter-form>

    <app-base-listing
      [listName]="'companyCarriers'"
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="companyCarriers"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [(sortProp)]="sortProp"
      [(sortDir)]="sortDir"
      (reloadList)="filter(1)"
      (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
      (callAction)="callAction($event)"
    ></app-base-listing>
  </section>
</div>
