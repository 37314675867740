import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'colSettings' })
export class ColumnSettingsOptionsPipe implements PipeTransform {
  transform(cols: any[], selectedCols: any[]): any[] {
    return cols
      .filter(c => !!c.name && c.name.length > 0 && c.name !== 'open')
      .map(c => ({
        action: c.name,
        label: c.nameForSettings && c.nameForSettings.length > 0 ? c.nameForSettings : c.name,
        isSelected: selectedCols.filter(s => s.name === c.name).length > 0,
      }))
  }
}
