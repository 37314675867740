import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { historyBack } from '../../utils'
import { NotificationService } from '../../services'
import { Payment, PaymentType } from 'depoto-core/src/entities'
import { CoreService } from '../../services'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { TranslateService } from '@ngx-translate/core'

const paymentSchema = {
  id: null,
  name: null,
  type: {
    id: null,
    name: null,
  },
  dispatchUnpaid: null,
}

@Component({
  selector: 'app-payment',
  templateUrl: 'payment.component.html',
})
export class PaymentComponent implements OnInit, CheckDataChanges {
  payment: Payment
  paymentTypes: PaymentType[] = []
  isCreating = false
  loading = true
  submitted = false
  dispatchUnpaidOptions = ['yes', 'no']

  paymentForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, [Validators.required]),
    type: new FormControl({ value: '', disabled: false }, []),
    dispatchUnpaid: new FormControl({ value: 'no', disabled: false }, []),
  })

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private core: CoreService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
  }

  async ngOnInit() {
    this.loading = true
    const id = this.route.snapshot.params['id']
    this.paymentTypes = await this.core.services.payment
      .getPaymentTypes({}, { id: null, name: null })
      .then(result => result.items)
    if (isNaN(id)) {
      this.isCreating = true
      this.payment = new Payment()
    } else {
      this.isCreating = false
      await this._payment(id)
    }
    this.loading = false
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  goBack(): void {
    historyBack()
  }

  private async _payment(id: number) {
    this.payment = await this.core.services.payment.getById(id, paymentSchema)
    if (id && id > 0) {
      this.fillFormValue()
    }
  }

  public fillFormValue() {
    this.paymentForm.setValue({
      name: this.payment.name || '',
      type: this.payment.type?.id || '',
      dispatchUnpaid: this.payment.dispatchUnpaid ? 'yes' : 'no',
    })
  }

  public getFormValue() {
    this.payment.name = this.paymentForm.value.name
    this.payment.type = <any>{ id: this.paymentForm.value.type }
    this.payment.dispatchUnpaid = this.paymentForm.value.dispatchUnpaid === 'yes'
  }

  async createPayment() {
    this.submitted = true
    if (this.paymentForm.invalid) {
      return
    }
    this.loading = true
    try {
      this.getFormValue()
      await this.core.services.payment.create(this.payment, { id: null }).then(result => {
        this.notificationService.success(this.translateService.instant('alert.payment.created'))
        this.router.navigate(['/payment/', result.id], { replaceUrl: true })
      })
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
    }
  }

  async updatePayment() {
    this.submitted = true
    if (this.paymentForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValue()
      this.payment = await this.core.services.payment.update(this.payment, { id: null }).then(result => {
        this.notificationService.success(this.translateService.instant('ALERT PAYMENT UPDATED'))
        return result
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async deletePayment() {
    const confirmation = confirm(
      this.translateService.instant('payment.delete.confirmation', { name: this.payment.name })
    )
    if (confirmation) {
      try {
        await this.core.services.payment.delete(this.payment).then(() => {
          this.notificationService.success(this.translateService.instant('ALERT PAYMENT DELETED'))
          historyBack()
        })
      } catch (err) {
        console.error(err)
      }
    }
  }
  get formControls(): { [key: string]: AbstractControl } {
    return this.paymentForm.controls
  }
}
