import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Address, Order } from 'depoto-core/src/entities'
import { customersColumns } from '../../../utils/definitions.util'

@Component({
  selector: 'app-order-customer',
  templateUrl: './order-customer.component.html',
  styleUrls: ['./order-customer.component.scss'],
})
export class OrderCustomerComponent {
  @Input() canCreateOrEdit = false
  @Input() isCreating = false
  @Input() order: Order
  @Input() isShippingAddressSameAsInvoice = true
  @Output() isShippingAddressSameAsInvoiceChange: EventEmitter<any> = new EventEmitter()
  @Output() orderChange: EventEmitter<any> = new EventEmitter()
  @Output() recalculatePricesByPriceLevel: EventEmitter<void> = new EventEmitter()
  @Output() editedAddressChange: EventEmitter<boolean> = new EventEmitter()

  public onRecalculatedPricesByPriceLevel() {
    // todo recalculate muze byt jina mena na objednavce a jina mena na cenove skupine -> pokud to neni procentualni ale casdtka v mene, musim prepocitat i na stejhnou menu
    this.recalculatePricesByPriceLevel.emit()
  }

  isEditedAddress() {
    this.editedAddressChange.emit(true)
  }

  onEditedAddress(address: Address, isBilling: boolean) {
    address.isBilling = isBilling
    if (isBilling) {
      this.order.invoiceAddress = { ...address }
    } else {
      this.order.shippingAddress = { ...address }
    }
    this.orderChange.emit(this.order)
    this.editedAddressChange.emit(true)
  }

  public relationInvoiceShippingChanged(value: boolean) {
    this.isShippingAddressSameAsInvoiceChange.emit(value)
    this.editedAddressChange.emit(true)
    if (value) {
      this.order.shippingAddress = this.order.invoiceAddress
    } else {
      this.order.shippingAddress = new Address()
      this.orderChange.emit(this.order)
    }
  }
}
