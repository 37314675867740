<div
  class="form-group mb-0"
  [ngClass]="{ 'mb-0': noMarginBottom }"
  [ngStyle]="{ 'min-width.px': minWidthPx, 'max-width.px': maxWidthPx }"
>
  <label *ngIf="label && label.length > 0">{{ label | translate }}:</label>
  <label class="float-right required" *ngIf="required"> ({{ 'required' | translate }}) </label>
  <div class="input-group border-primary rounded">
    <input
      [ngClass]="{ 'is-invalid': requiredCssClass }"
      class="form-control text-end"
      type="number"
      [ngModel]="model"
      (ngModelChange)="onNgModelChange($event)"
      [attr.data-cy]="dataCyAttribute"
      (keypress)="checkCharacter($event)"
    />
    <div class="input-group-append bg-primary">
      <div>
        <div
          class="d-flex justify-content-center"
          (click)="addOne()"
          (keyup)="addOne()"
          style="width: 35px; height: 22px"
          data-cy="increment"
          role="button"
          tabIndex="0"
          aria-disabled="true"
        >
          <div class="far fa-plus my-auto"></div>
        </div>
        <div
          class="d-flex justify-content-center"
          (click)="deductOne()"
          (keyup)="deductOne()"
          style="width: 35px; height: 16px"
          role="button"
          tabIndex="0"
          aria-disabled="true"
        >
          <div class="far fa-minus my-auto"></div>
        </div>
      </div>
    </div>
    <div
      *ngIf="requiredCssClass"
      [ngClass]="{ 'invalid-feedback': requiredCssClass }"
      style="color: #f0142f"
      data-cy="error_msg"
    >
      {{ errors | formError: formControlName | translate }}
    </div>
  </div>
</div>
