import { Component } from '@angular/core'
import { CoreService, NotificationService } from '../../../services'
import { OrderDetailService } from '../../../services/order-detail.service'
import { File, Tag } from 'depoto-core/src/entities'
import { SchemaUtil } from '../../../utils'
import { ProductPriceLevelUtil } from 'depoto-core/src/utils/ProductPriceLevelUtil'

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss'],
})
export class OrderFormComponent {
  tags: Tag[] = []
  constructor(
    private core: CoreService,
    public orderDetailService: OrderDetailService,
    private notificationService: NotificationService
  ) {
    this.tags = orderDetailService.tags.filter(t => t.type === 'order')
  }

  refreshData(id) {
    if (id) {
      this.orderDetailService.refreshData(id)
    }
  }

  fileAdded(file: File) {
    if (this.order.id) {
      // save with order's id
      try {
        this.core.services.file
          .update(file, null, this.order.id, null, null, null, SchemaUtil.fileExtra)
          .then(createdFile => {
            this.orderDetailService.order.files.push(createdFile)
          })
      } catch (error) {
        console.error('Error in function connectUploadedFilesToOrder. Details: ', error.message)
      }
    } else {
      this.orderDetailService.order.files.push(file)
    }
  }

  public refreshPaymentAmount(): void {
    if (this.order.paymentItems && this.order.paymentItems.length === 1) {
      const itemPrices = this.order.items.map(item => item.priceAll)
      this.orderDetailService.order.paymentItems[0].amount = itemPrices.reduce((a, b) => a + b, 0)
    }
    // todo add to tests
    if (this.orderDetailService.order.customer?.priceLevel?.id) {
      this.recalculatePricesByPriceLevel()
    }
  }

  public recalculatePricesByChangedCurrency() {
    console.log('recalculatePricesByChangedCurrency()¨')
    if (
      this.orderDetailService.newCurrency.id === this.orderDetailService.order.currency.id ||
      !this.orderDetailService.newCurrency.id
    )
      return

    if (this.orderDetailService.order.currency.id === 'CZK') {
      // if from czk use ratio
      for (const orderItem of this.orderDetailService.order.items) {
        orderItem.price = Number((orderItem.price * this.orderDetailService.newCurrency.ratio).toFixed(2))
        orderItem.priceAll = Number((orderItem.price * orderItem.quantity).toFixed(2))
      }
      for (const paymentItem of this.orderDetailService.order.paymentItems) {
        paymentItem.amount = Number((paymentItem.amount * this.orderDetailService.newCurrency.ratio).toFixed(2))
        this.orderDetailService.order.currency = this.orderDetailService.newCurrency
      }
    } else {
      // if not from czk, recalculate to czk and then use ratio
      for (const orderItem of this.orderDetailService.order.items) {
        // to czk
        const priceInCzk = orderItem.price / this.orderDetailService.order.currency.ratio

        orderItem.price = Number((priceInCzk * this.orderDetailService.newCurrency.ratio).toFixed(2))
        orderItem.priceAll = Number((priceInCzk * orderItem.quantity).toFixed(2))
      }
      for (const paymentItem of this.orderDetailService.order.paymentItems) {
        // to czk
        const amountInCzk = paymentItem.amount / this.orderDetailService.order.currency.ratio

        paymentItem.amount = Number((amountInCzk * this.orderDetailService.newCurrency.ratio).toFixed(2))
        this.orderDetailService.order.currency = this.orderDetailService.newCurrency
      }
    }

    this.notificationService.success('Ceny úspěšně přepočítány.')
  } //TODO TADY
  // nastaveni cenove skupiny
  // -> mena euro
  // zakaznik

  // vystavili fakturu zakaznikovi
  // 40.000 kc faktura
  // pdf stahnout
  // 40.000 euro
  public recalculatePricesByPriceLevel() {
    console.log('recalculatePricesByPriceLevel')
    for (const orderItem of this.orderDetailService.order.items) {
      if (orderItem.product) {
        orderItem.price = ProductPriceLevelUtil.getProductPriceForCustomerPriceLevel(
          orderItem.product,
          this.orderDetailService.order.customer
        )
        orderItem.priceAll = orderItem.price * orderItem.quantity
      } else if (this.order.customer.priceLevel?.isPercentage === true) {
        orderItem.price = orderItem.price - orderItem.price * (this.order.customer.priceLevel?.percent / 100)
      }
    }
    this.notificationService.success('Ceny úspěšně přepočítány.')
  }

  async setToStatusReceived(event) {
    this.orderDetailService.order.processStatusRelation.note = event
    this.orderDetailService.updateProcessStatus = 'recieved'
  }
  get order() {
    return this.orderDetailService.order
  }
}
