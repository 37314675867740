<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="row">
  <div class="col-12 col-md-6">
    <div class="my-3" *ngIf="oneTicket || soutokTicket">&nbsp;</div>
    <form-row-input
      *ngIf="!oneTicket && !soutokTicket"
      [inputType]="'number'"
      [label]="'quantity'"
      [icon]="'fa-list'"
      [(model)]="quantity"
      (modelChange)="recalculateArray()"
    ></form-row-input>
    <div class="mb-3">
      <btn
        [classes]="['btn-third', 'me-2']"
        *ngIf="!oneTicket && !soutokTicket"
        (onEvent)="quantity = 1; recalculateArray()"
        [label]="'min'"
        [title]="'min' | translate"
      ></btn>
      <btn
        [classes]="['btn-third', 'me-2']"
        *ngIf="!oneTicket && !soutokTicket"
        (onEvent)="quantity = 39; recalculateArray()"
        [label]="'max'"
        [title]="'max' | translate"
      ></btn>
      <btn
        [classes]="['btn-first']"
        (onEvent)="confirmPreview()"
        [icon]="'fa-print'"
        [label]="'print'"
        [title]="'print' | translate"
      ></btn>
    </div>
    <div>
      <form-row-input
        [inputType]="'number'"
        [(model)]="pageMarginTop"
        (modelChange)="recalculateArray()"
        [icon]="'fa-arrow-up'"
        [label]="'page.margin.top'"
      ></form-row-input>
      <form-row-input
        [inputType]="'number'"
        [(model)]="pageMarginLeft"
        (modelChange)="recalculateArray()"
        [icon]="'far-arrow-left'"
        [label]="'page.margin.left'"
      ></form-row-input>
      <form-row-input
        [inputType]="'number'"
        [(model)]="barcodePaddingHorizontal"
        (modelChange)="recalculateArray()"
        [icon]="'fa-arrows-h'"
        [label]="'barcode.padding.horizontal'"
      ></form-row-input>
      <form-row-input
        [inputType]="'number'"
        [(model)]="barcodePaddingVertical"
        (modelChange)="recalculateArray()"
        [icon]="'fa-arrows-v'"
        [label]="'barcode.padding.vertical'"
      ></form-row-input>
    </div>
    <div class="row mb-2">
      <div class="col">
        <btn
          [classes]="[barcodeTitleVisible ? 'btn-second' : 'btn-third']"
          (onEvent)="toggleBarcodeTitleVisible()"
          [label]="'barcode.title'"
          [title]="'barcode.title' | translate"
        ></btn>
        <btn
          [classes]="[barcodeEanVisible ? 'btn-second' : 'btn-third', 'ms-2']"
          (onEvent)="toggleBarcodeEanVisible()"
          [label]="'barcode.ean'"
          [title]="'barcode.ean' | translate"
        ></btn>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <btn
          [classes]="[barcodePriceVisible ? 'btn-second' : 'btn-third']"
          (onEvent)="toggleBarcodePriceVisible()"
          [label]="'barcode.price'"
          [title]="'barcode.price' | translate"
        ></btn>
        <btn
          [classes]="[barcodeWidth ? 'btn-second' : 'btn-third', 'ms-2']"
          (onEvent)="toggleBarcodeWidth()"
          [label]="'barcode.width'"
          [title]="'barcode.width' | translate"
        ></btn>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <btn
          [classes]="[oneTicket ? 'btn-second' : 'btn-third']"
          (onEvent)="toggleOneTicketMode()"
          [label]="'barcode.one'"
          [title]="'barcode.one' | translate"
        ></btn>
        <btn
          [classes]="[soutokTicket ? 'btn-second' : 'btn-third', 'ms-2']"
          (onEvent)="toggleSoutokTicketMode()"
          [label]="'barcode.soutok'"
          [title]="'barcode.soutok' | translate"
        ></btn>
      </div>
    </div>
    <div class="invisible">
      <canvas #barcode id="barcode"></canvas>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div class="barcode-array-container-margin" #printable id="printable">
      <div
        *ngIf="oneTicket"
        class="barcode-array-container"
        [style.marginTop]="pageMarginTop + 'mm'"
        [style.marginBottom]="-1 * pageMarginTop + 'mm'"
        [style.marginLeft]="pageMarginLeft + 'mm'"
        [style.marginRight]="-1 * pageMarginLeft + 'mm'"
      >
        <div
          class="barcode-array-item one-ticket"
          [style.paddingTop]="barcodePaddingVertical + 'mm'"
          [style.paddingBottom]="barcodePaddingVertical + 'mm'"
          [style.paddingLeft]="barcodePaddingHorizontal + 'mm'"
          [style.paddingRight]="barcodePaddingHorizontal + 'mm'"
        >
          <div class="one-ticket-row">
            <div class="one-ticket-item-title">
              {{ barcodeTitleVisible ? productDetailService.product.fullName : '&nbsp;' }}
            </div>
          </div>
          <div class="one-ticket-row">
            <img
              [src]="printArray[0].val"
              alt=""
              *ngIf="printArray[0].val && printArray[0].val.length > 0"
              class="one-ticket-item-img"
            />
            <div class="one-ticket-item-size">&nbsp;</div>
          </div>
          <div class="one-ticket-row" *ngIf="printArray[0].val && printArray[0].val.length > 0">
            <div class="one-ticket-item-ean">
              {{ barcodeEanVisible ? productDetailService.product.ean : '&nbsp;' }}
              <br />
              {{ barcodeEanVisible ? productDetailService.product.code : '&nbsp;' }}
            </div>
            <div class="d-flex flex-column" style="margin-top: -1mm">
              <div
                class="one-ticket-item-price before-sell-price"
                *ngIf="barcodePriceVisible && productDetailService.product.beforeSellPrice > 0"
              >
                {{ productDetailService.product.beforeSellPrice | formatPrice }}
              </div>
              <div class="one-ticket-item-price">
                {{ barcodePriceVisible ? (productDetailService.product.sellPrice | formatPrice) : '&nbsp;' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="soutokTicket"
        class="barcode-array-container"
        [style.marginTop]="pageMarginTop + 'mm'"
        [style.marginBottom]="-1 * pageMarginTop + 'mm'"
        [style.marginLeft]="pageMarginLeft + 'mm'"
        [style.marginRight]="-1 * pageMarginLeft + 'mm'"
      >
        <div
          class="barcode-array-item"
          *ngFor="let barcode of printArray"
          (click)="updateStartAt(barcode)"
          [style.paddingTop]="barcodePaddingVertical + 'mm'"
          [style.paddingBottom]="barcodePaddingVertical + 'mm'"
          [style.paddingLeft]="barcodePaddingHorizontal + 'mm'"
          [style.paddingRight]="barcodePaddingHorizontal + 'mm'"
        >
          <div class="barcode-array-item-title-left soutok-ticket-title" *ngIf="barcode.val && barcode.val.length > 0">
            {{ barcodeTitleVisible ? productDetailService.product.fullName : '&nbsp;' }}
          </div>
          <img
            [src]="barcode.val"
            alt=""
            *ngIf="barcode.val && barcode.val.length > 0"
            class="barcode-array-item-img"
          />
          <div class="barcode-array-item-bottom-line-soutok" *ngIf="barcode.val && barcode.val.length > 0">
            <div class="barcode-array-item-ean">
              {{ barcodeEanVisible ? productDetailService.product.ean : '&nbsp;' }}
            </div>
            <div class="barcode-array-item-price">
              {{ barcodePriceVisible ? (productDetailService.product.sellPrice | formatPrice) : '&nbsp;' }}
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="!oneTicket && !soutokTicket"
        class="barcode-array-container"
        [style.marginTop]="pageMarginTop + 'mm'"
        [style.marginBottom]="-1 * pageMarginTop + 'mm'"
        [style.marginLeft]="pageMarginLeft + 'mm'"
        [style.marginRight]="-1 * pageMarginLeft + 'mm'"
      >
        <div
          class="barcode-array-item"
          *ngFor="let barcode of printArray"
          (click)="updateStartAt(barcode)"
          [style.paddingTop]="barcodePaddingVertical + 'mm'"
          [style.paddingBottom]="barcodePaddingVertical + 'mm'"
          [style.paddingLeft]="barcodePaddingHorizontal + 'mm'"
          [style.paddingRight]="barcodePaddingHorizontal + 'mm'"
        >
          <div class="barcode-array-item-title" *ngIf="barcode.val && barcode.val.length > 0">
            {{ barcodeTitleVisible ? productDetailService.product.fullName : '&nbsp;' }}
          </div>
          <img
            [src]="barcode.val"
            alt=""
            *ngIf="barcode.val && barcode.val.length > 0"
            class="barcode-array-item-img"
          />
          <div class="barcode-array-item-bottom-line" *ngIf="barcode.val && barcode.val.length > 0">
            <div class="barcode-array-item-ean">
              {{ barcodeEanVisible ? productDetailService.product.ean : '&nbsp;' }}
            </div>
            <div class="barcode-array-item-price">
              {{ barcodePriceVisible ? (productDetailService.product.sellPrice | formatPrice) : '&nbsp;' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
