<div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
    <div class="card">
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <h3 class="card-title text-nowrap">{{ 'general.info' | translate }}</h3>
        </div>
        <div class="col-8 d-flex justify-content-end">
          <div class="flex-gap-5">
            <form-row-checkbox
              [inputDisabled]="!isBundleCanBeSet"
              [label]="'product.isBundle'"
              [(model)]="productDetailService.product.isBundle"
              [dataCySelector]="'product-is-bundle'"
            ></form-row-checkbox>
            <form-row-checkbox
              [label]="'product.enabled'"
              [(model)]="productDetailService.product.enabled"
              [dataCySelector]="'product-enabled'"
            >
            </form-row-checkbox>
          </div>
        </div>
      </div>
      <div class="card-body p-0 row">
        <div class="col-12 mt-2">
          <form-row-input
            [label]="'product.name'"
            [icon]="'far-file-alt'"
            [(model)]="productDetailService.product.name"
            [required]="!productDetailService.product.name"
            [requiredCssClass]="productDetailService.submitted && !productDetailService.product.name"
            [dataCyAttribute]="'product-name'"
          ></form-row-input>
        </div>
        <div class="col-12">
          <form-row-select-multiple
            [label]="'tags'"
            [options]="tags"
            [selectedValues]="productDetailService.product?.tags ? productDetailService.product.tags : []"
            (selectedValuesChange)="onTagsChange($event)"
            [dataCyAttribute]="'product-tags'"
          ></form-row-select-multiple>
        </div>
        <div class="col-6">
          <form-row-input
            [label]="'product.ean'"
            [icon]="'far-barcode'"
            [translateBarcode]="false"
            [(model)]="productDetailService.product.ean"
            [appendBtnIcon]="
              !productDetailService.product.ean || productDetailService.product.ean?.length === 0 ? 'fa-barcode' : ''
            "
            [appendBtnLabel]="
              !productDetailService.product.ean || productDetailService.product.ean?.length === 0
                ? 'product.ean.generate'
                : ''
            "
            [appendBtnClasses]="['btn-ean']"
            (onAppendBtnEvent)="fillNextEan()"
            [dataCyAttribute]="'product-ean'"
          ></form-row-input>
          <form-row-input
            [label]="'product.ean2'"
            [icon]="'fa-barcode'"
            [(model)]="productDetailService.product.ean2"
            *ngIf="!!productDetailService.product.ean2 || !!productDetailService.product.ean"
            [translateBarcode]="true"
            [dataCyAttribute]="'product-ean2'"
          ></form-row-input>
          <form-row-input
            [label]="'product.ean3'"
            [icon]="'fa-barcode'"
            [(model)]="productDetailService.product.ean3"
            *ngIf="!!productDetailService.product.ean3 || !!productDetailService.product.ean2"
            [translateBarcode]="true"
          ></form-row-input>
          <form-row-input
            [label]="'product.ean4'"
            [icon]="'fa-barcode'"
            [(model)]="productDetailService.product.ean4"
            *ngIf="!!productDetailService.product.ean4 || !!productDetailService.product.ean3"
            [translateBarcode]="true"
          ></form-row-input>
          <form-row-input
            [label]="'product.ean5'"
            [icon]="'fa-barcode'"
            [(model)]="productDetailService.product.ean5"
            *ngIf="!!productDetailService.product.ean5 || !!productDetailService.product.ean4"
            [translateBarcode]="true"
          ></form-row-input>
        </div>
        <div class="col-6">
          <form-row-input
            [label]="'product.code'"
            [icon]="'far-tag'"
            [(model)]="productDetailService.product.code"
            [dataCyAttribute]="'product-code'"
          ></form-row-input>
          <form-row-input
            [label]="'product.code2'"
            [icon]="'far-tag'"
            [(model)]="productDetailService.product.code2"
            *ngIf="!!productDetailService.product.code2 || !!productDetailService.product.code"
            [dataCyAttribute]="'product-code2'"
          ></form-row-input>
          <form-row-input
            [label]="'product.code3'"
            [icon]="'far-tag'"
            [(model)]="productDetailService.product.code3"
            *ngIf="!!productDetailService.product.code3 || !!productDetailService.product.code2"
            [dataCyAttribute]="'product-code3'"
          ></form-row-input>
        </div>
        <div class="col-12">
          <form-row-select
            [icon]="'far-industry'"
            [label]="'brand'"
            [model]="productDetailService.product.producer?.id"
            (modelChange)="productDetailService.product.producer = { id: $event, name: '' }"
            [options]="productDetailService.producers"
            [dataCyAttribute]="'product-producer-select'"
          ></form-row-select>
        </div>
        <div class="col-12">
          <div class="form-group">
            <form-row-textarea
              [label]="'note'"
              [icon]="null"
              [(model)]="productDetailService.product.note"
              [dataCyAttribute]="'product-note'"
            ></form-row-textarea>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center">
          <form-row-checkbox
            [label]="'product.isForSubsequentSettlement'"
            [(model)]="productDetailService.product.isForSubsequentSettlement"
            [tooltipLabel]="'isForSubsequentSettlement.info'"
            [dataCySelector]="'product-isForSubsequentSettlement'"
          ></form-row-checkbox>
        </div>
        <div class="col-12" *ngIf="productDetailService.product.externalId">
          <div class="flex-gap-5 align-items-center">
            <div class="card-subheading">{{ 'externalId' | translate }}:</div>
            <div style="font-size: 14px">
              {{ productDetailService.product.externalId }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
        <div class="card">
          <div class="row">
            <div class="col-8">
              <h3 class="card-title text-nowrap">{{ 'sellPrice' | translate }}</h3>
            </div>
            <div class="col-4">
              <btn
                *ngIf="productDetailService.product.children && productDetailService.product.children.length"
                [title]="'insert.values.into.variants'"
                [classes]="['btn-third', 'btn-sm', 'float-right']"
                [icon]="'far-chevron-right'"
                (onEvent)="updateVariants(['sellPrice', 'beforeSellPrice'])"
              >
              </btn>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <form-row-input
                [label]="'product.price.sale'"
                [class]="'input-unit'"
                [icon]="'fa-money-bill'"
                [(model)]="productDetailService.product.sellPrice"
                [required]="!productDetailService.product?.sellPrice"
                [replaceComma]="true"
                [appendUnitCurrency]="true"
                [dataCyAttribute]="'product-price-sale'"
              ></form-row-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <form-row-input
                [label]="'product.price.before'"
                [class]="'input-unit'"
                [icon]="'fa-money-bill-alt'"
                [(model)]="productDetailService.product.beforeSellPrice"
                [replaceComma]="true"
                [appendUnitCurrency]="true"
                [dataCyAttribute]="'product-price-before'"
              ></form-row-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <form-row-select
                [label]="'product.vatrate'"
                [options]="productDetailService.vats"
                [model]="vat"
                (modelChange)="productDetailService.product.vat.id = $event"
                *ngIf="productDetailService.vats?.length > 0 && productDetailService.vats[0].id"
                [dataCyAttribute]="'product-vat'"
              ></form-row-select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
        <div class="card">
          <div class="row">
            <div class="col-8">
              <h3 class="card-title">{{ 'productMoves.load.list' | translate }}</h3>
            </div>
            <div class="col-4">
              <btn
                *ngIf="productDetailService.product?.children?.length"
                [classes]="['btn-third', 'btn-sm', 'float-right']"
                [icon]="'far-chevron-right'"
                (onEvent)="updateVariants(['purchasePrice', 'purchaseCurrency', 'supplier'])"
              >
              </btn>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <form-row-input
                [label]="'purchasePrice.default'"
                [class]="'input-unit'"
                [icon]="'fa-money-bill'"
                [(model)]="productDetailService.product.purchasePrice"
                [replaceComma]="true"
                [dataCyAttribute]="'product-price-default'"
              ></form-row-input>
            </div>
            <div class="col">
              <form-row-select
                [label]="'currency.default'"
                [icon]="'far-coins'"
                [model]="productDetailService.product.purchaseCurrency?.id"
                (modelChange)="productDetailService.product.purchaseCurrency = { id: $event, name: '', ratio: 1 }"
                [options]="productDetailService.currencies"
                [dataCyAttribute]="'product-currency-select'"
              ></form-row-select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <form-row-select
                [model]="productDetailService.product.supplier?.id"
                (modelChange)="setSupplier($event)"
                [label]="'supplier.default'"
                [icon]="'fa-person-carry'"
                [options]="productDetailService.suppliers"
                [dataCyAttribute]="'product-supplier-select'"
              ></form-row-select>
            </div>
          </div>
          <div *ngIf="!productDetailService.product.isBundle && isEnabledModule" class="row pt-2">
            <div class="col">
              <form-row-checkbox
                [(model)]="productDetailService.product.requiredSerialNumbers"
                [label]="'product.requiredSerialNumbers'"
                [dataCySelector]="'product-requiredSerialNumbers'"
              ></form-row-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
    <div class="row">
      <div class="col-12">
        <div class="card" *ngIf="!productDetailService.product.isBundle">
          <div class="row">
            <div class="col-6 align-self-center">
              <label class="card-title text-nowrap">
                {{ 'product.parent' | translate }}
              </label>
            </div>
            <div class="col-6 d-flex flex-row-reverse">
              <modal-product-parent
                [product]="productDetailService.product"
                [depots]="productDetailService.depots"
                [suppliers]="productDetailService.suppliers"
                [btnText]="'product.choose'"
                [dataCySelector]="'product-parent-button'"
                (result)="setParent($event)"
              ></modal-product-parent>
            </div>
          </div>
          <div class="mt-2" *ngIf="!!productDetailService.product?.parent?.id > 0" data-cy="product-parent">
            <table class="table">
              <tbody>
                <div class="table-product-border">
                  <tr>
                    <td class="border-0 td-left-radius w-100">
                      <div class="flex-gap-10 align-items-center">
                        <product-image [product]="productDetailService.product.parent"></product-image>
                        <div>
                          <a class="link-bold" [routerLink]="['/product', productDetailService.product.parent.id]">{{
                            productDetailService.product.parent.fullName
                          }}</a>
                          <div class="product-details flex-gap-5 flex-wrap">
                            <div *ngIf="productDetailService.product.parent.code">
                              {{ 'product.code' | translate }}: {{ productDetailService.product.parent.code }}
                            </div>
                            <div *ngIf="productDetailService.product.parent.ean">
                              {{ 'product.ean' | translate }}: {{ productDetailService.product.parent.ean }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="border-0 td-right-radius">
                      <btn
                        [title]="'delete' | translate"
                        [icon]="'far-trash-alt'"
                        [classes]="['btn-trash', 'btn-sm', 'float-right']"
                        (onEvent)="productDetailService.product.parent = null"
                        [dataCyAttribute]="'remove-product-parent-button'"
                      ></btn>
                    </td>
                  </tr>
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card" *ngIf="!productDetailService.product.isBundle && !productDetailService.isCreating">
          <div>
            <div class="row">
              <div class="col-5 d-flex align-items-center">
                <label class="card-title">{{ 'product.children' | translate }}</label>
              </div>
              <div class="col-7 d-flex flex-row-reverse">
                <btn
                  [routerLink]="['/product-variant-create', productDetailService.product.id]"
                  [classes]="['btn-first']"
                  [label]="'variant.create'"
                  [title]="'variant.create' | translate"
                  [dataCyAttribute]="'variant-create-button'"
                ></btn>
                <modal-product-parent
                  class="me-2"
                  [product]="productDetailService.product"
                  [depots]="productDetailService.depots"
                  [suppliers]="productDetailService.suppliers"
                  [btnText]="'variant.choose'"
                  (result)="addChild($event)"
                  [dataCySelector]="'variant-choose-modal'"
                ></modal-product-parent>
              </div>
            </div>
            <table
              class="table mt-2"
              *ngIf="productDetailService.product.children && productDetailService.product.children.length > 0"
            >
              <tbody class="images" [photoGalleryGroup]="{ index: 1 }">
                <tr *ngFor="let child of productDetailService.product.children">
                  <td>
                    <div class="flex-gap-10">
                      <ng-template [ngIf]="child.mainImage">
                        <div
                          class="align-self-center"
                          [photoGallery]="child.mainImage.url"
                          [photoGalleryCaption]="
                            child.fullName +
                            '<br>' +
                            (child.code ? ('product-form.code' | translate) + child.code : '') +
                            '<br>' +
                            (child.ean ? ('product-form.ean' | translate) + child.ean : '')
                          "
                        >
                          <div class="images-item">
                            <img
                              class="images-item-image"
                              [src]="getThumbUrl(child.mainImage)"
                              [alt]="child.fullName"
                            />
                          </div>
                        </div>
                      </ng-template>
                      <div class="align-self-center">
                        <a
                          class="link-bold"
                          *ngIf="!!child"
                          [routerLink]="['/product', child.id]"
                          data-cy="product-variant-link"
                        >
                          {{ child.name }}
                        </a>
                        <div class="product-details flex-gap-5 flex-wrap">
                          <div *ngIf="child.code">{{ 'product.code' | translate }}: {{ child.code }}</div>
                          <div *ngIf="child.ean">{{ 'product.ean' | translate }}: {{ child.ean }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td title="Skladovost" class="text-center">
                    <modal-stock-overview
                      [isCalculatedOfProductDepots]="true"
                      [isSumCalculated]="true"
                      [product]="child"
                      [depots]="productDetailService.depots"
                      [suppliers]="productDetailService.suppliers"
                    >
                    </modal-stock-overview>
                  </td>
                  <td>
                    <btn
                      [title]="'remove' | translate"
                      [icon]="'far-trash-alt'"
                      [classes]="['btn-trash', 'btn-sm', 'float-right']"
                      (onEvent)="removeChild(child)"
                    ></btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="card"
          *ngIf="productDetailService.product.bundleParents && productDetailService.product.bundleParents.length > 0"
        >
          <div>
            <div class="row">
              <div class="col card-title align-self-center">
                {{ 'products.bundleParents' | translate }}
              </div>
            </div>
            <table class="table mt-2">
              <tbody>
                <tr *ngFor="let bp of productDetailService.product.bundleParents">
                  <td class="ps-1">
                    <div class="flex-gap-10 align-items-center" [photoGalleryGroup]="{ index: 2 }">
                      <div
                        [photoGallery]="bp.parent.mainImage.url"
                        [photoGalleryCaption]="
                          bp.parent.fullName +
                          '<br>' +
                          (bp.parent.code ? ('product.code' | translate) + bp.parent.code : '') +
                          '<br>' +
                          (bp.parent.ean ? ('product.ean' | translate) + bp.parent.ean : '')
                        "
                      >
                        <div class="images-item">
                          <img
                            class="images-item-image"
                            [src]="getThumbUrl(bp.parent.mainImage)"
                            [alt]="bp.parent.fullName"
                          />
                        </div>
                      </div>
                      <a
                        class="link-bold"
                        *ngIf="!!bp.parent && !productDetailService.isCreating"
                        [routerLink]="['/product', bp.parent.id]"
                        >{{ bp.parent.fullName }}</a
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--          // todo bundle parent:D-->

        <div class="card" *ngIf="productDetailService.product.isBundle">
          <div class="row">
            <div class="col card-title align-self-center">
              {{ 'products.bundleChildren' | translate }}
            </div>
            <div class="col d-flex flex-row-reverse">
              <modal-product-parent
                [product]="productDetailService.product"
                [depots]="productDetailService.depots"
                [suppliers]="productDetailService.suppliers"
                [isForBundleChild]="true"
                [dataCySelector]="'product-bundle-child-button'"
                [btnText]="'item.choose'"
                (result)="addBundleChild($event)"
              ></modal-product-parent>
            </div>
          </div>
          <table class="table mt-2">
            <tbody>
              <tr *ngFor="let bc of productDetailService.product.bundleChildren">
                <td class="fixed-column-width" data-cy="bundle-child-quantity">{{ bc.quantity }}x</td>
                <td class="ps-0">
                  <div class="flex-gap-10 align-items-center" [photoGalleryGroup]="{ index: 3 }">
                    <div
                      [photoGallery]="bc.child.mainImage.url"
                      [photoGalleryCaption]="
                        bc.child.fullName +
                        '<br>' +
                        (bc.child.code ? ('product.code' | translate) + bc.child.code : '') +
                        '<br>' +
                        (bc.child.ean ? ('product.ean' | translate) + bc.child.ean : '')
                      "
                    >
                      <div class="images-item">
                        <img
                          class="images-item-image"
                          [src]="getThumbUrl(bc.child.mainImage)"
                          [alt]="bc.child.fullName"
                        />
                      </div>
                    </div>
                    <div>
                      <a class="link-bold" *ngIf="!!bc.child" [routerLink]="['/product', bc.child.id]">{{
                        bc.child.fullName
                      }}</a>
                      <div class="product-details flex-gap-5 flex-wrap">
                        <div *ngIf="bc.child.code">
                          {{ 'product.code' | translate }}: {{ !!bc.child ? bc.child.code : '' }}
                        </div>
                        <div *ngIf="bc.child.ean">
                          {{ 'product.ean' | translate }}: {{ !!bc.child ? bc.child.ean : '' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center text-nowrap" title="Skladovost" *ngIf="!!bc.child">
                  <modal-stock-overview
                    [isCalculatedOfProductDepots]="true"
                    [isSumCalculated]="true"
                    [product]="bc.child"
                    [depots]="productDetailService.depots"
                    [suppliers]="productDetailService.suppliers"
                  >
                  </modal-stock-overview>
                </td>
                <td>
                  <div class="d-flex justify-content-end">
                    <modal-product-parent
                      [productBundle]="bc"
                      [product]="productDetailService.product"
                      [depots]="productDetailService.depots"
                      [suppliers]="productDetailService.suppliers"
                      [dataCySelector]="'product-bundle-child-edit-button'"
                      (result)="updateBundleChild($event)"
                    ></modal-product-parent>
                    <btn
                      [title]="'delete' | translate"
                      [icon]="'far-trash-alt'"
                      [classes]="['btn-trash', 'btn-sm', 'float-right']"
                      [dataCyAttribute]="'product-bundle-child-delete'"
                      (onEvent)="removeBundleChild(bc)"
                    ></btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card" style="padding-left: 25px">
          <div class="row">
            <div class="col-8 ps-0">
              <h3 class="card-title">
                {{ 'logistic.details' | translate }}
              </h3>
            </div>
            <div class="col-4">
              <btn
                *ngIf="productDetailService.product.children && productDetailService.product.children.length"
                [classes]="['btn-third', 'btn-sm', 'float-right']"
                [icon]="'far-chevron-right'"
                (onEvent)="
                  updateVariants([
                    'dimensionX',
                    'dimensionY',
                    'dimensionZ',
                    'weight',
                    'originCountry',
                    'hsCode',
                    'isFragile',
                    'isOversize'
                  ])
                "
              >
              </btn>
            </div>
          </div>

          <app-product-logistic-details
            [product]="productDetailService.product"
            (productChange)="productDetailService.product = $event"
          ></app-product-logistic-details>

          <div class="row mt-2">
            <div class="col-12 d-flex justify-content-start ps-0">
              <div class="flex-gap-10">
                <form-row-checkbox
                  [label]="'fragile'"
                  [(model)]="productDetailService.product.isFragile"
                  [dataCySelector]="'product-is-fragile'"
                >
                </form-row-checkbox>
                <form-row-checkbox
                  [label]="'oversize'"
                  [(model)]="productDetailService.product.isOversize"
                  [dataCySelector]="'product-is-oversize'"
                >
                </form-row-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="row">
            <div class="col-12">
              <h2 class="card-title">{{ 'description' | translate }}</h2>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <ckeditor
                [editor]="ckeditor"
                [(ngModel)]="productDetailService.product.description"
                [ngModelOptions]="{ standalone: true }"
                data-cy="product-description"
              ></ckeditor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
