<div class="images" photoGalleryGroup>
  <div [photoGallery]="imageUrl">
    <div class="images-item image-cropper">
      <img class="images-item-image item-pic" [src]="thumbnailUrl" [alt]="label" />
    </div>
  </div>
  <ng-template [ngIf]="product && product.images && product.images.length > 1">
    <div
      *ngFor="let img of restOfProductImages"
      [photoGallery]="img.url"
      [photoGalleryCaption]="img.product ? (img.product.fullName + '<br>' + (img.product.code ?  ('product.code' | translate) + img.product.code : '') + '<br>' + (img.product.ean ? ' EAN: ' + img.product.ean : '')) : ''"
    ></div>
  </ng-template>
</div>
