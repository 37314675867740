<modal-container
  #modal
  [headerIconClass]="'fa-download'"
  [headerTitle]="'import.do'"
  [ignoreBackdropClick]="true"
  [isLargeModal]="!!skippedOrders?.length"
>
  <ng-container class="modal-button-show">
    <btn
      [label]="'import.do'"
      [title]="'import.do' | translate"
      [icon]="'far-download'"
      [isLoading]="loading"
      [spinnerClassesString]="'spinner-blue'"
      [classes]="['btn-third', 'me-2']"
      (onEvent)="showChildModal()"
      [attr.data-cy]="dataCyAttribute"
    ></btn>
  </ng-container>

  <ng-container class="modal-body-content">
    <div class="card">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
      <div *ngIf="!isImportMapUpdating && !isImportMapSet">
        <div class="row">
          <div class="col-6">
            <form-row-select
              [label]="'batch.file.type'"
              [icon]="'far-list'"
              [(model)]="selectedFileType"
              [options]="fileTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'checkout'"
              [icon]="'far-money-bill'"
              [(model)]="selectedCheckout"
              [options]="checkouts"
            ></form-row-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 ps-0 pe-0" style="font-size: 12px">
            <div class="col-12 flex-gap-5 align-items-center" *ngIf="selectedFileType === 'depoto-orders.xlsx'">
              <span>{{ 'modal-orders-import.template' | translate }}</span>
              <a
                class="inline-flex-gap-5 align-items-center"
                [href]="templatesURL + 'depoto-orders' + ('lang-url-fragment' | translate) + '.tpl.xlsx'"
              >
                <span class="far fa-file-download"></span>
                <span>depoto-orders.tpl.xlsx</span>
              </a>
            </div>
            <div class="col-12 flex-gap-5 align-items-center" *ngIf="selectedFileType === 'go-orders.csv'">
              <span>{{ 'modal-orders-import.template' | translate }}</span>
              <a class="inline-flex-gap-5 align-items-center" [href]="templatesURL + 'go-orders.tpl.csv'">
                <span class="far fa-file-download"></span>
                <span>go-orders.tpl.csv</span>
              </a>
            </div>
            <div class="col-12 flex-gap-5 align-items-center" *ngIf="selectedFileType === 'flexibee-order.xml'">
              <span>{{ 'modal-orders-import.template' | translate }}</span>
              <a
                class="inline-flex-gap-5 align-items-center"
                [href]="'https://www.flexibee.eu/api/doc/priklady-xml/faktura-vydana-full.xml'"
                target="_blank"
              >
                <span class="far fa-file-download"></span>
                <span>flexibee-order.xml</span>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <form-row-input
              [label]="'file'"
              [icon]="'far-upload'"
              [inputType]="'file'"
              [appendBtnLabel]="'load'"
              [appendBtnClasses]="['btn-input']"
              (valueChangeNative)="finalMessage = ''; loadFile($event)"
              (onAppendBtnEvent)="handleImportFile()"
            ></form-row-input>
          </div>
        </div>
      </div>
      <progressbar *ngIf="isImportMapSet" type="success" [value]="progress" [striped]="true" [max]="100"></progressbar>
      <div
        *ngIf="(finalMessage && isImportMapSet) || (finalMessage && selectedFileType === 'go-orders.csv')"
        role="alert"
        class="alert alert-success mt-2"
      >
        <b>{{ finalMessage }}</b>
      </div>
      <div *ngIf="isImportMapUpdating" class="mt-2">
        <h3>{{ 'modal-orders-import.check-column-layout' | translate }}</h3>
        <div class="row">
          <div class="col-6">
            <form-row-select
              [label]="'orderFulltext.number'"
              [(model)]="importMap.reservationNumber"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select [label]="'ean'" [(model)]="importMap.ean" [options]="columnTypes"></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select [label]="'code'" [(model)]="importMap.code" [options]="columnTypes"></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'product.name'"
              [(model)]="importMap.productName"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'quantity'"
              [(model)]="importMap.quantity"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'firstName'"
              [(model)]="importMap.firstName"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'lastName'"
              [(model)]="importMap.lastName"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select [label]="'street'" [(model)]="importMap.street" [options]="columnTypes"></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select [label]="'city'" [(model)]="importMap.city" [options]="columnTypes"></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select [label]="'zip'" [(model)]="importMap.zip" [options]="columnTypes"></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'country'"
              [(model)]="importMap.country"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select [label]="'phone'" [(model)]="importMap.phone" [options]="columnTypes"></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select [label]="'email'" [(model)]="importMap.email" [options]="columnTypes"></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'payment.type'"
              [(model)]="importMap.paymentType"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'payment.amount'"
              [(model)]="importMap.paymentAmount"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'currency'"
              [(model)]="importMap.currency"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select
              [label]="'carrier'"
              [(model)]="importMap.carrier"
              [options]="columnTypes"
            ></form-row-select>
          </div>
          <div class="col-6">
            <form-row-select [label]="'note'" [(model)]="importMap.note" [options]="columnTypes"></form-row-select>
          </div>
        </div>
        <btn
          [btnType]="'button'"
          [label]="'continue'"
          [title]="'continue' | translate"
          (onEvent)="setImportMapAndContinue()"
        ></btn>
      </div>
      <div *ngIf="isImportFileLoaded" class="mt-2">
        <alert type="info">{{ orders.length }} {{ 'modal-orders-import.items-ready' | translate }}</alert>
      </div>
      <div *ngIf="skippedOrders.length > 0" class="mt-2">
        <alert type="warning" *ngFor="let reservationNumber of skippedOrders">
          {{ ('orders.number' | translate) + reservationNumber + ('already.exist' | translate) }}
        </alert>
      </div>
      <div *ngIf="errors && errors.length > 0" class="mt-2">
        {{ 'failed.to.import' | translate }}
        <alert type="warning" *ngFor="let e of errors">{{ e }}</alert>
      </div>
    </div>
  </ng-container>

  <ng-container class="modal-footer-content">
    <btn
      [label]="'import.do'"
      [title]="'import.do' | translate"
      [icon]="'far-download'"
      [classes]="['btn-first']"
      [btnDisabled]="!isImportFileLoaded || loading"
      (onEvent)="import()"
    ></btn>
  </ng-container>
</modal-container>
