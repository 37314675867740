import { Injectable } from '@angular/core'
import { SnotifyPosition, SnotifyService, SnotifyToast, SnotifyToastConfig } from 'ng-alt-snotify'
import { CoreService } from './core.service'
import { NotificationEvent } from 'depoto-core/src/models/NotificationEvent'
import { TranslateService } from '@ngx-translate/core'

const entityList = [
  'Carrier',
  'Category',
  'CompanyCarrier',
  'Currency',
  'Checkout',
  'Depot',
  'EventType',
  'Parameter',
  'Payment',
  'Producer',
  'Supplier',
  'Tag',
  'Vat',
]

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  avoidErrorAlerts = false
  constructor(
    private snotifyService: SnotifyService,
    private core: CoreService,
    private translateService: TranslateService
  ) {
    this.startListeningToNotifications()
  }

  private startListeningToNotifications() {
    this.core.services.baseEvent.subscribe().then((notificationEvent: NotificationEvent) => {
      if (this.avoidErrorAlerts || this.isStartOfMutation(notificationEvent)) {
        return
      }
      if (this.isResolvedMutation(notificationEvent)) {
        if (entityList.includes(notificationEvent.entity)) {
          this.core.loadLists() // reload lists on list entity create / update / delete
        }
      } else if (this.isRejectedMutation(notificationEvent)) {
        this.error(this.getRejectedMutationNotificationText(notificationEvent))
      } else if (this.isRejectedQuery(notificationEvent)) {
        this.error(this.getRejectedQueryNotificationText(notificationEvent))
      }
    })
  }

  private isStartOfMutation(notificationEvent: NotificationEvent): boolean {
    return (
      notificationEvent.type === 'start' &&
      (notificationEvent.operation === 'create' ||
        notificationEvent.operation === 'update' ||
        notificationEvent.operation === 'delete') &&
      notificationEvent.method !== 'createDevLog'
    )
  }

  private isResolvedMutation(notificationEvent: NotificationEvent): boolean {
    return (
      notificationEvent.type === 'resolve' &&
      (notificationEvent.operation === 'create' ||
        notificationEvent.operation === 'update' ||
        notificationEvent.operation === 'delete') &&
      notificationEvent.method !== 'createDevLog'
    )
  }

  private isRejectedMutation(notificationEvent: NotificationEvent): boolean {
    return (
      notificationEvent.type === 'reject' &&
      (notificationEvent.operation === 'create' ||
        notificationEvent.operation === 'update' ||
        notificationEvent.operation === 'delete') &&
      notificationEvent.method !== 'createDevLog'
    )
  }

  private isRejectedQuery(notificationEvent: NotificationEvent): boolean {
    return (
      notificationEvent.type === 'reject' &&
      (notificationEvent.operation === 'list' || notificationEvent.operation === 'detail') &&
      notificationEvent.method !== 'createDevLog'
    )
  }
  /*
  private getMutationNotificationText(notificationEvent: NotificationEvent): string {
    const nameOrNumber =
      notificationEvent.response?.data[notificationEvent.method]?.data?.fullName ||
      notificationEvent.response?.data[notificationEvent.method]?.data?.name ||
      notificationEvent.response?.data[notificationEvent.method]?.data?.billNumber ||
      notificationEvent.response?.data[notificationEvent.method]?.data?.reservationNumber ||
      notificationEvent.response?.data[notificationEvent.method]?.data?.id ||
      ' '
    return 'Úspěšně uloženo.'
    // return `${this.translationPipe.transform(
    //   notificationEvent.entity.toLowerCase(),
    // )} ${nameOrNumber} byl/a úspěšně ${this.translationPipe.transform('notification.' + notificationEvent.operation)}.`
  }
*/
  private getRejectedMutationNotificationText(notificationEvent: NotificationEvent): string {
    const error =
      typeof notificationEvent.errors[0] === 'string'
        ? notificationEvent.errors
        : notificationEvent.errors.map((e: any) => e.message)
    const name = notificationEvent.entity.toLowerCase()
    let result
    switch (notificationEvent.operation) {
      case 'create':
        result = this.translateService.instant('notification.rejected.mutation.creation', { name, error })
        break
      case 'update':
        result = this.translateService.instant('notification.rejected.mutation.update', { name, error })
        break
      case 'delete':
        result = this.translateService.instant('notification.rejected.mutation.deletion', { name, error })
        break
    }
    return result
  }

  private getRejectedQueryNotificationText(notificationEvent: NotificationEvent): string {
    const name = notificationEvent.entity.toLowerCase()
    const errors =
      typeof notificationEvent.errors[0] === 'string'
        ? notificationEvent.errors
        : notificationEvent.errors.map((e: any) => e.message)
    return this.translateService.instant('notification.rejected.query.display', { name, error: errors.join('. ') })
  }

  private getConfig(disableTimeout = false): SnotifyToastConfig {
    this.snotifyService.setDefaults({
      global: {
        newOnTop: false,
        maxAtPosition: 6,
        maxOnScreen: 8,
        filterDuplicates: false,
      },
    })
    return {
      bodyMaxLength: 400,
      titleMaxLength: 40,
      backdrop: -1,
      position: SnotifyPosition.centerTop,
      timeout: disableTimeout ? 0 : 2500,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [{ text: 'x', action: close, bold: true }],
    }
  }

  public warning(text: string, title?: string, disableTimeout = true): SnotifyToast {
    return this.snotifyService.warning(text, title, this.getConfig(disableTimeout))
  }

  public error(text: string, title?: string, disableTimeout = true): SnotifyToast {
    return this.snotifyService.error(text, title, this.getConfig(disableTimeout))
  }

  public success(text: string, title?: string, disableTimeout = false): SnotifyToast {
    return this.snotifyService.success(text, title, this.getConfig(disableTimeout))
  }

  public info(text: string, title?: string, disableTimeout = false): SnotifyToast {
    return this.snotifyService.info(text, title, this.getConfig(disableTimeout))
  }
}
