import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { FormRowInputComponent } from '../../_common/form-row-input/form-row-input.component'
import { OrderItem } from 'depoto-core/src/entities'

@Component({
  selector: 'app-modal-order-items-sale',
  templateUrl: './modal-order-items-sale.component.html',
  styleUrls: ['./modal-order-items-sale.component.scss'],
})
export class ModalOrderItemsSaleComponent {
  @ViewChild('modal') childModal: ModalContainerComponent
  @ViewChild('formRowInput') formRowInput: FormRowInputComponent
  isCreating = true
  @Input() canEditPrice
  @Input() canCreateOrEdit
  @Input() orderItems: OrderItem[]
  @Output() orderItemsEdited: EventEmitter<any> = new EventEmitter()
  @Output() refreshPaymentAmount: EventEmitter<any> = new EventEmitter()
  sale

  public showChildModal() {
    this.childModal.showChildModal()
    setTimeout(() => {
      this.formRowInput.input.nativeElement.focus()
    }, 500)
  }

  private hideChildModal() {
    this.childModal.hideChildModal()
  }

  setSale() {
    for (const orderItem of this.orderItems) {
      orderItem.sale = this.sale
      const priceDiscounted = Number(orderItem.price) - (Number(orderItem.price) / 100) * Number(orderItem.sale)
      orderItem.priceAll = priceDiscounted * Number(orderItem.quantity)
    }
    this.orderItemsEdited.emit(this.orderItems)
    this.hideChildModal()
    this.isCreating = false
  }
}
