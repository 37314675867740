<modal-container
  #modal
  [headerIconClass]="'far-file-export'"
  [headerTitle]="headerTitle"
  [isExtraLargeModal]="true"
  (hide)="onHideChildModal()"
>
  <ng-container class="modal-button-show">
    <btn
      [label]="'exports'"
      [title]="'exports' | translate"
      [classes]="['btn-third', 'float-right', 'me-2']"
      [icon]="'far-list-alt'"
      [btnType]="'button'"
      [dataCyAttribute]="'exports'"
      (click)="showChildModal()"
    ></btn>
  </ng-container>

  <ng-container class="modal-body-content">
    <div class="container-fluid ps-0 pe-0">
      <form-row-select
        [label]="'type'"
        [options]="types"
        [(model)]="selectedType"
        (modelChange)="filter()"
        [dataCyAttribute]="'export-types-select'"
      ></form-row-select>

      <app-base-listing
        [listName]="'exports'"
        [storageKey]="storageKey"
        [loadingNextItems]="loading"
        [loadingItems]="reloading"
        [items]="exports"
        [columns]="listingColumns"
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [endPage]="endPage"
        [(sortProp)]="sortProp"
        [(sortDir)]="sortDir"
        (reloadList)="filter(1)"
        (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
        (callAction)="callAction($event)"
      ></app-base-listing>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content"> </ng-container>
</modal-container>
