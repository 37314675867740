<nav class="main-header navbar navbar-expand navbar-white navbar-light row" [ngClass]="{ hidden: !isShown }">
  <ul class="navbar-nav col-8">
    <li class="nav-item">
      <a
        class="nav-link"
        data-widget="pushmenu"
        href="#"
        (click)="toggleSideMenu($event)"
        data-auto-collapse-size="50000"
        data-cy="sidebar-collapsed"
      >
        <i
          (window:resize)="onResize($event)"
          class="far"
          [ngClass]="{
            'fa-bars': windowWidth < 992,
            'fa-angle-double-right': iconExpand,
            'fa-angle-double-left': !iconExpand
          }"
        ></i>
      </a>
    </li>
    <li class="nav-item">
      <span class="nav-link">
        <span class="nav-user" data-cy="company-name">{{ companyName }}</span>
        <span class="bg-danger ms-1" *ngIf="server !== 'prod'" data-cy="server-info">[{{ server }}]</span>
      </span>
    </li>
  </ul>
  <box-user class="col-4 d-flex justify-content-end"></box-user>
</nav>
