import { Injectable, OnDestroy } from '@angular/core'
import { RouterStateSnapshot, TitleStrategy } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Title } from '@angular/platform-browser'
import { tap } from 'rxjs/operators'
import { Subscription } from 'rxjs'

@Injectable()
export class CustomPageTitleStrategy extends TitleStrategy implements OnDestroy {
  private subscriptions = new Subscription()
  constructor(
    private translateService: TranslateService,
    private readonly title: Title
  ) {
    super()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const route = snapshot.root.firstChild
    let title = route.data?.tempTitle ?? ''

    const getTitle = () => {
      if (title) {
        title = this.translateService.instant(title)
      }
      if (route.data?.hasId && route.params?.id) {
        title += ' ' + route.params.id
      }
      if (route.data?.prefix && route.params?.id) {
        title += ' ' + this.translateService.instant(route.data.prefix + '.' + route.params.id)
      }
      title += title ? ' - Depoto' : 'Depoto'
      if (window['companyName'] && window['companyName'].length) {
        title += ' [' + window['companyName'] + ']'
      }
      return title
    }
    this.title.setTitle(getTitle())

    this.subscriptions.add(
      this.translateService.onLangChange
        .pipe(
          tap(x => {
            this.title.setTitle(getTitle())
          })
        )
        .subscribe()
    )
  }
}
