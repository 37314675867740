import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'
import { Subscription } from 'rxjs'
import { ProductMovesListComponent } from '../../components/product-components/product-moves-list/product-moves-list.component'
import { Stock } from '../../models'
import { CoreService, NotificationService } from '../../services'
import { TranslateService } from '@ngx-translate/core'
import { Product, ProductPrice } from 'depoto-core/src/entities'
import { ProductPriceLevelsComponent } from '../../components/product-components/product-price-levels/product-price-levels.component'
import { SchemaUtil, historyBack, getObjectDiff } from '../../utils'
import { BaseDetail } from '../_base-detail/base-detail'
import { ProductDetailService } from '../../product-detail.service'
import { ProductFormComponent } from '../../components/product-components/product-form/product-form.component'

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['product.component.scss'],
})
export class ProductComponent extends BaseDetail implements OnInit, OnDestroy {
  @ViewChild('productForm') productForm: ProductFormComponent
  @ViewChild('movesList') movesList: ProductMovesListComponent
  @ViewChild('productPriceLevelsEl') productPriceLevelsEl: ProductPriceLevelsComponent
  activeLink = ''
  activeLinks: string[] = [
    'common',
    'stock',
    'moves',
    'images',
    'files',
    'parameters',
    'categories',
    'packing',
    'advancedPrices',
    'priceLevels',
    'barcode', // todo: deprecated, to be removed
    // 'print', // deprecated
    'properPrint', // todo: rename after removing old print components
  ]
  canMoveProducts = false

  private subscription: Subscription
  private refreshSubscription: Subscription
  private resolveStockSubscription: Subscription
  private pristineProduct: Product

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public core: CoreService,
    public productDetailService: ProductDetailService,
    public translateService: TranslateService,
    private notificationService: NotificationService
  ) {
    // todo ??
    super(route, router, core, translateService)
    this.setActiveLink()
    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.setActiveLink()
      }
    })
    this.refreshSubscription = this.productDetailService.refreshDataEvent$.subscribe(data => {
      this.refreshData(this.productDetailService.product.id)
      // this.pristineProduct = structuredClone(this.productDetailService.product)

      // the line above is not needed yet because of the time when .refreshSubscription() is called
      // and also because that call is followed by .resolveStockLoad() call
    })
    this.resolveStockSubscription = this.productDetailService.resolveStockEvent$.subscribe(data => {
      this.resolveStockLoad()
    })
  }
  async ngOnInit() {
    this.subscription = this.route.queryParams.subscribe(params => {
      if (params?.id > 0) {
        this.productDetailService.isCreating = true
        this.productDetailService.isDuplicatedProduct = true
        this.refreshData(params.id)
      }
    })

    this.setActiveLink()
    this.productDetailService.getRoles()
    if (!this.productDetailService.isDuplicatedProduct) await this.reloadViewDataFromRoute()
    this.canMoveProducts = this.hasRoleForMoveProducts()
    this.productDetailService.getCoreEntityLists()

    this.pristineProduct = structuredClone(this.productDetailService.product)
  }

  async ngOnDestroy() {
    this.productDetailService.isDuplicatedProduct = false
    this.productDetailService.product = undefined
    this.subscription.unsubscribe()
    this.refreshSubscription.unsubscribe()
    this.resolveStockSubscription.unsubscribe()
  }
  hasUnsavedDataChanges(): boolean {
    const cloneWithoutDepots = structuredClone(this.productDetailService.product)
    // The user can't change child's productDepots from this page
    if (cloneWithoutDepots) {
      cloneWithoutDepots.bundleChildren?.forEach(b => {
        delete b.child.productDepots
      })
      cloneWithoutDepots.children?.forEach(b => {
        delete b.productDepots
      })
    }
    const diff = getObjectDiff(cloneWithoutDepots, this.pristineProduct)
    // the func above returns different properties' names if the objects aren't equal
    // If there are "tags" among them, take into account that every tag is an object and even if names are the same,
    // the id and other properties may not correspond.
    if (!diff) {
      return
    }
    return !!diff.filter(d => d !== 'files').length
  }

  goBack(): void {
    historyBack()
  }

  async setActiveLink() {
    const urlParts = window.location.href.split('/')
    if (
      !!this.productDetailService.product?.id &&
      urlParts[urlParts.length - 2] === 'product' &&
      Number(this.router.url.split('/')[this.router.url.split('/').length - 1]) !==
        this.productDetailService.product?.id
    ) {
      await this.reloadViewDataFromRoute()
    }

    if (this.productDetailService.isCreating) {
      if (this.router.url.split('/')[2] === undefined) {
        this.activeLink = 'common'
        this.productDetailService.product = new Product({ enabled: true })
      } else {
        this.activeLink = String(this.router.url.split('/')[2])
      }
    } else if (urlParts[urlParts.length - 2] === 'product-variant-create') {
      this.activeLink = 'common'
      this.productDetailService.isCreating = true
      this.productDetailService.product = new Product({ enabled: true })
    } else {
      if (this.router.url.split('/')[3] === undefined) this.activeLink = 'common'
      else this.activeLink = String(this.router.url.split('/')[this.router.url.split('/').length - 1])
    }
  }

  private async reloadViewDataFromRoute() {
    const id = this.route.snapshot.params['id']
    if (isNaN(id)) {
      this.productDetailService.isCreating = true
      this.productDetailService.product = new Product({ enabled: true })
      this.productDetailService.loading = false
      await this.refreshData()
      this.pristineProduct = structuredClone(this.productDetailService.product)
      return
    }
    if (this.route.snapshot.routeConfig.path.includes('product-variant-create') && !isNaN(id)) {
      this.productDetailService.isCreating = true
      this.productDetailService.product = new Product({ enabled: true })
      this.productDetailService.product.vat = this.productDetailService.vats.find(vat => vat.default)
      this.productDetailService.product.parent = await this.core.services.product.getById(id, SchemaUtil.product)
      this.productDetailService.product.parent = await this.core.services.product.getById(id, SchemaUtil.product)
      await this.refreshData()
    } else {
      this.productDetailService.isCreating = false
      await this.refreshData(id)
    }
    this.pristineProduct = structuredClone(this.productDetailService.product)
  }

  public async refreshData(id?: number): Promise<any> {
    this.productDetailService.loading = true
    this.getCoreEntityLists()
    if (
      this.productDetailService.isCreating &&
      !this.productDetailService.product &&
      !this.productDetailService.isDuplicatedProduct
    ) {
      this.productDetailService.product = new Product({ enabled: true })
      this.activeLink = 'common'
    }
    if (this.productDetailService.product && !this.productDetailService.product.vat) {
      this.productDetailService.product.vat = this.productDetailService.vats.find(vat => vat.default)
    }
    if (!this.productDetailService.isCreating || this.productDetailService.isDuplicatedProduct) {
      try {
        const schema = this.productDetailService.isDuplicatedProduct ? SchemaUtil.duplicatedProduct : SchemaUtil.product
        this.productDetailService.product = await this.core.services.product.getById(id, schema)
      } catch (e) {
        console.warn(e)
        if (e && typeof e.includes === 'function' && e.includes('Error 403: Forbidden.')) {
          return this.onLoadError(403)
        }
        return this.onLoadError(404)
      }
      if (!this.productDetailService.product) {
        return this.onLoadError(404)
      }
      this.productDetailService.product.advancedPrices = [...this.productDetailService.product.advancedPrices].map(
        advancedPrice =>
          new ProductPrice({
            ...advancedPrice,
            dateFrom: this.formatDateStringFromDateTime(advancedPrice.dateFrom),
            dateTo: this.formatDateStringFromDateTime(advancedPrice.dateTo),
          })
      )
      if (this.productDetailService.isDuplicatedProduct) {
        delete this.productDetailService.product.id
        if (this.productDetailService.product.bundleChildren?.length) {
          this.productDetailService.product.bundleChildren.forEach((a, i) => {
            delete this.productDetailService.product.bundleChildren[i].id
          })
        }
      } else {
        this.formatStock()
      }
      if (this.movesList) {
        this.movesList.filter()
      }
    }
    this.productDetailService.loading = false
  }

  getCoreEntityLists(): void {
    this.productDetailService.depots = this.core.lists.depots
    this.productDetailService.suppliers = this.core.lists.suppliers
    this.productDetailService.vats = this.core.lists.vats
    this.productDetailService.producers = this.core.lists.producers
    this.productDetailService.currencies = this.core.lists.currencies
  }

  public resolveStockLoad(): void {
    this.refreshData(this.productDetailService.product.id)
    if (this.movesList) {
      this.movesList.filter()
    }
    this.pristineProduct = structuredClone(this.productDetailService.product)
  }

  private formatStock(): void {
    this.productDetailService.stock = []
    this.productDetailService.stockDepotsChartData = []
    this.productDetailService.depots.forEach(depot => {
      const quantity = this.productDetailService.product.quantities.find(q => q.depot.id === depot.id)
      this.productDetailService.stock.push({
        depot: depot,
        productDepots: [],
        sumAll: quantity?.quantityStock || 0,
        sumReservations: quantity?.quantityReservation || 0,
        sumAvailable: quantity?.quantityAvailable || 0,
        sumPurchase: quantity?.quantityPurchase || 0,
      })
    })

    this.productDetailService.product.productDepots.forEach(pDepot => {
      this.productDetailService.stock.forEach((stockItem, index) => {
        if (pDepot.depot.id === stockItem.depot.id) {
          stockItem.productDepots.push(pDepot)
          // this.productDetailService.stock[index].sumAll += pDepot.quantityStock
          // this.productDetailService.stock[index].sumReservations += pDepot.quantityReservation
          // this.productDetailService.stock[index].sumAvailable =
          //   this.productDetailService.stock[index].sumAll - this.productDetailService.stock[index].sumReservations >= 0
          //     ? this.productDetailService.stock[index].sumAll - this.productDetailService.stock[index].sumReservations
          //     : 0
          // this.productDetailService.product.quantities.find(q => q.depot.id === pDepot.depot)
          // this.productDetailService.stock[index].sumPurchase = pDepot.product.quantityPurchase
        }
      })
    })
    try {
      const itemsCount = this.productDetailService.stock.map(d => d.productDepots.length).reduce((a, b) => a + b)
      this.productDetailService.isStockEmptyYet = itemsCount === 0
    } catch (e) {
      // Reduce of empty array with no initial value
      this.productDetailService.isStockEmptyYet = true
    }
    this.getLastStockItemData()
  }

  private getLastStockItemData(): void {
    this.productDetailService.stock.forEach(stock => {
      if (stock.productDepots.length > 0) {
        // @ts-ignore
        stock.productDepots.sort((a, b) => (a.id === b.id ? 0 : a.id < b.id))
      }
    })
    this.productDetailService.stock.sort(this.sortStock)
    if (this.productDetailService.stock[0] && this.productDetailService.stock[0].productDepots[0]) {
      this.core.services.storage.set(
        this.getStorageKeyForEntity('lastStockItemSupplier'),
        this.productDetailService.stock[0].productDepots[0].supplier.id
      )
      this.core.services.storage.set(
        this.getStorageKeyForEntity('lastStockItemDepot'),
        this.productDetailService.stock[0].productDepots[0].depot.id
      )
      /* this.core.services.storage.set(
        this.getStorageKeyForEntity('lastStockItemPurchasePrice'),
        this.productDetailService.stock[0].productDepots[0].purchasePrice
      ) never read */
    }
  }

  private sortStock(a: Stock, b: Stock) {
    if (a.productDepots[0] && b.productDepots[0]) {
      if (a.productDepots[0].id === b.productDepots[0].id) {
        return 0
      } else {
        if (a.productDepots[0].id < b.productDepots[0].id) {
          return 1
        } else {
          return -1
        }
      }
    } else {
      return 0
    }
  }

  public async createProduct(): Promise<void> {
    this.productDetailService.submitted = true
    if (
      this.productDetailService.product?.name?.length < 1 ||
      !this.productDetailService.product?.sellPrice ||
      this.productDetailService.product?.sellPrice <= 0
    ) {
      if (
        this.productDetailService.product?.name?.length < 1 &&
        (this.productDetailService.product?.sellPrice <= 0 || !this.productDetailService.product?.sellPrice)
      ) {
        alert(this.translateService.instant('product.enter.name.and.sellprice'))
      } else if (this.productDetailService.product?.name?.length < 1) {
        alert(this.translateService.instant('product.enter.name'))
      } else if (!this.productDetailService.product?.sellPrice || this.productDetailService.product?.sellPrice <= 0) {
        alert(this.translateService.instant('product.enter.sellprice'))
      }
      this.productDetailService.submitted = false
      return
    }
    try {
      this.productDetailService.loading = true
      if (!this.productDetailService.product?.vat?.id) this.productDetailService.vats.filter(v => v.default)[0]
      const result = await this.core.services.product
        .create(this.productDetailService.product, SchemaUtil.product)
        .then(result => {
          this.notificationService.success(this.translateService.instant('alert.product.added'))
          return result
        })
      if (result?.id && this.productDetailService.product.bundleChildren?.length) {
        for (const bc of this.productDetailService.product.bundleChildren) {
          bc.parent = new Product(result)
          await this.core.services.productBundle.create(bc, SchemaUtil.productBundle)
        }
      }
      this.productDetailService.isCreating = false
      this.pristineProduct = structuredClone(this.productDetailService.product)
      this.router.navigate(['/product', result.id], { replaceUrl: true })
    } catch (e) {
      console.error(e)
    } finally {
      this.productDetailService.loading = false
    }
  }

  public async updateProduct(): Promise<void> {
    this.productDetailService.submitted = true
    if (this.productDetailService.product?.name?.length < 1 || this.productDetailService.product?.sellPrice <= 0) {
      if (this.productDetailService.product?.name?.length < 1 && this.productDetailService.product?.sellPrice <= 0) {
        alert(this.translateService.instant('product.enter.name.and.sellprice'))
      } else if (this.productDetailService.product?.name?.length < 1) {
        alert(this.translateService.instant('product.enter.name'))
      } else if (!this.productDetailService.product?.sellPrice || this.productDetailService.product?.sellPrice <= 0) {
        alert(this.translateService.instant('product.enter.sellprice'))
      }
      this.productDetailService.submitted = false
      return
    }
    this.productDetailService.loading = true
    try {
      if (this.productDetailService.updatedProductPriceLevels?.length) {
        for (const updatedProductPriceLevel of this.productDetailService.updatedProductPriceLevels) {
          const exists = this.productDetailService.product.productPriceLevels.some(
            pPL => pPL.priceLevel.id === updatedProductPriceLevel.priceLevel.id
          )
          if (exists) {
            if (
              updatedProductPriceLevel.sellPrice &&
              Number(updatedProductPriceLevel.sellPrice) !== 0 &&
              !isNaN(Number(updatedProductPriceLevel.sellPrice))
            ) {
              await this.core.services.productPriceLevel.update(updatedProductPriceLevel, { id: null })
            }
          } else {
            await this.core.services.productPriceLevel.create(updatedProductPriceLevel, { id: null })
          }
        }
      }
      this.productDetailService.product = await this.core.services.product
        .update(this.productDetailService.product, SchemaUtil.product)
        .then(result => {
          this.notificationService.success(this.translateService.instant('alert.product.saved'))
          return result
        })
      if (this.productDetailService.parameters) {
        await this.productDetailService.updateParams()
      }
      this.pristineProduct = structuredClone(this.productDetailService.product)
    } catch (e) {
      console.error(e)
    } finally {
      this.productDetailService.loading = false
    }
  }

  public async deleteProduct(): Promise<void> {
    if (!confirm(this.translateService.instant('product.delete.confirmation'))) {
      return new Promise(r => r())
    }
    this.productDetailService.loading = true
    try {
      this.productDetailService.product = await this.core.services.product
        .delete(this.productDetailService.product)
        .then(result => {
          this.notificationService.success(this.translateService.instant('alert.product.deleted'))
          return result
        })
      this.pristineProduct = structuredClone(this.productDetailService.product)
    } catch (e) {
      console.error(e)
    }
    this.router.navigate(['/products'], { replaceUrl: true })
    this.productDetailService.loading = false
  }

  private formatDateStringFromDateTime(dateTime: string): string[] | string {
    return !!dateTime && dateTime.length > 0 ? dateTime.split(' ')[0] : ''
  }

  private hasRoleForMoveProducts(): boolean {
    const rolesForMoveProducts = [
      'ROLE_SUPER_ADMIN',
      'ROLE_ADMIN',
      'ROLE_CERT_LIST',
      'ROLE_SHOP_LIST',
      'ROLE_PRODUCT_MOVE_CREATE',
    ]
    return this.core.services.user.hasOneOfTheRoles(rolesForMoveProducts)
  }
}
