import { CustomerConsent } from 'depoto-core/src/entities'
import { DateUtil } from 'depoto-core/src/utils/DateUtil'

export class ConsentUtil {
  static printCustomerConsent(consent: CustomerConsent): void {
    const pWidth = 900
    const pHeight = 720
    const popupWin = window.open(
      '',
      `Tisk souhlasu ${consent.name}`,
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${pWidth},height=${pHeight},top=${
        screen.height - (screen.height + pHeight) / 2
      },left=${screen.width - (screen.width + pWidth) / 2}`
    )
    popupWin.document.body.innerHTML = `
    Jméno a příjmení: <strong>${consent.customer.firstName} ${consent.customer.lastName}</strong>
    <br>Kontaktní email: <strong>${consent.customer.email}</strong>
    <br><br>${consent.body}
    <br><br>V Praze dne ${DateUtil.formatDate(new Date().toISOString(), true)}.
    <br><br><br>Podpis _______________________
    `
    popupWin.print()
    setTimeout(() => {
      popupWin.close()
    }, 100)
  }
}
