import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import 'jsbarcode'
import { NotificationService } from '../../services'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'

declare const JsBarcode // workaround

@Component({
  selector: 'label-print',
  templateUrl: 'label-print.component.html',
  styleUrls: ['label-print.component.scss'],
})
export class LabelPrintComponent implements OnInit {
  @ViewChild('barcode', { static: true }) barcode: ElementRef
  @ViewChild('printable') printableEl: ElementRef
  @Output() result: EventEmitter<any> = new EventEmitter()
  labels: string[]
  codes: Array<{ code: string; base64: string }> = []
  labelType: 'box' | 'position'
  posA = 'BOX'
  posB = ''
  posC = ''
  genPos = 1 // index in array ... ex: ['BOX', 1234] (genPos: 1); or ['A', 'B', 123] (genPos: 2)
  genRangeFrom = 0
  genRangeTo = 0
  genLength = 4
  isPrinting = false

  constructor(
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.labelType = this.route.snapshot.params['id']
    this.route.url.forEach(r => {
      try {
        this.posA = this.labelType === 'box' ? 'BOX' : ''
        this.genPos = this.labelType === 'box' ? 1 : 2
      } catch (e) {
        console.warn(e)
      }
    })
  }

  generateBatch(): void {
    this.codes = []
    const filler = '0'.repeat(this.genLength)
    const getGenerated = (x: number) => `${(filler + x.toFixed(0)).substr(-this.genLength, this.genLength)}`
    const getCode = (x: number) => {
      if (this.labelType === 'box') {
        return `${this.posA}-${getGenerated(x)}`
      } else if (this.labelType === 'position') {
        const a = this.genPos == 0 ? getGenerated(x) : this.posA
        const b = this.genPos == 1 ? getGenerated(x) : this.posB
        const c = this.genPos == 2 ? getGenerated(x) : this.posC
        return `${a}-${b}-${c}`
      }
    }
    for (let x = Number(this.genRangeFrom); x <= Number(this.genRangeTo); x++) {
      const code = getCode(x)
      JsBarcode(this.barcode.nativeElement, code, {
        lineColor: '#000',
        width: 2,
        height: 30,
        displayValue: true,
      })
      const base64 = this.barcode.nativeElement.toDataURL()
      this.codes.push({ code, base64 })
    }
  }

  printBatch(): void {
    if (window._depoto) {
      this.printBatchDesktop()
    } else {
      this.printBatchBrowser()
    }
  }

  printBatchDesktop(): void {
    this.isPrinting = true
    let x = 0
    for (const c of this.codes) {
      setTimeout(() => {
        window._depoto.node.printLPImage(c.base64, null, window._depoto.paperSize.custom50x30mm)
        if (x === Number(this.genRangeTo) - 1) {
          this.isPrinting = false
          this.notificationService.success(this.translateService.instant('label-print:printing-completed'))
        }
        x++
      }, x * 2500)
    }
  }

  printBatchBrowser(): void {
    const pWidth = 900
    const pHeight = 720
    const popupWin = window.open(
      '',
      'Tisk štítků',
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${pWidth},height=${pHeight},top=${
        screen.height - (screen.height + pHeight) / 2
      },left=${screen.width - (screen.width + pWidth) / 2}`
    )
    let html = ''
    for (const c of this.codes) {
      html += `<img src="${c.base64}"><div class="page-break"></div>`
    }
    popupWin.document.body.innerHTML = html
    const styles = document.createElement('style')
    styles.innerHTML = `
    img {
        width: 100%;
        height: auto;
        image-rendering: pixelated;
    }

    @media print {
        @page {
            size: auto;
            margin: 0mm 0mm 0mm 0mm;
        }
        html {
            margin: 0;
            /* ugly hack to print just first page, todo: find better solution */
            /* position: fixed; */
        }
        * {
            color: black!important;
            border: 0!important;
            box-shadow: none!important;
        }
        .content-wrapper.print-no-margin { margin-left: 0 !important; min-height: 0 !important; }
        .content { padding: 0 !important; }
        .no-print {
            display: none!important;
        }
        .page-break {
            page-break-after: always;
            break-after: always;
        }

        img {
            image-rendering: pixelated;
        }
        /* zebra:
        img {
            width: 50mm;
            height: 30mm;
            margin-top: 3mm;
            margin-left: 2.5mm;

        }
        */
    }`
    popupWin.document.head.appendChild(styles)
    this.isPrinting = true
    popupWin.onbeforeunload = () => {
      this.isPrinting = false
      this.notificationService.success(this.translateService.instant('label-print:printing-completed'))
    }
  }
}
