<modal-container
  #modal
  [headerTitle]="('serialNumbers' | translate) + ': ' + productMove?.product?.name"
  [modalHeaderBgColorClass]="'bg_green'"
  [modalBodyBgColorClass]="'bg_green'"
  [modalFooterBgColorClass]="'bg_green'"
  [isLargeModal]="true"
>
  <ng-container class="modal-button-show">
    <btn (onEvent)="showChildModal()" [icon]="'fa-regular fa-scanner-gun'" [title]="'open' | translate"></btn>
  </ng-container>
  <ng-container class="modal-body-content">
    <div class="container-fluid ps-0 pe-0">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
      <div class="card" style="padding: 26px" *ngIf="!loading">
        <div class="font-weight-bold">
          {{ ('serialNumbers' | translate) + ':' }}
        </div>
        <div class="container-serial-numbers">
          <div *ngFor="let serialNumber of productMove.serialNumbers" class="serial-number-div">
            <span>{{ serialNumber }}</span>
            <span class="ng-value-icon right" (click)="removeSerialNumber(serialNumber)" aria-hidden="true">×</span>
          </div>
        </div>
        <div>
          <div class="d-flex">
            <form-row-textarea
              class="w-50"
              [icon]="'far-note'"
              [label]="'serialNumbers.addNew'"
              [placeholder]="'type.here'"
              [classes]="['height-8rem']"
              (keydown)="keyPressed($event)"
              [(model)]="serialNumbersInString"
            >
            </form-row-textarea>
            <div class="d-flex align-items-end w-50 font-italic">
              <div class="text-padding">
                {{ 'serialNumbers.you.can.add.more.numbers' | translate }}
              </div>
              <div></div>
            </div>
          </div>
          <btn
            class="margin-left-13-5"
            [label]="'add'"
            [title]="'add' | translate"
            (click)="addSerialNumbers()"
            [classes]="['btn-third-grey']"
            [isLoading]="loading"
            [spinnerClassesString]="'spinner-white'"
          >
          </btn>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content" *ngIf="!loading">
    <div class="serialNumberAmountFromQuantity">
      {{
        ('product.qty' | translate) +
          ': ' +
          productMove.serialNumbers.length +
          ' z ' +
          (productMove.amount | number: '1.0-2')
      }}
    </div>
    <btn
      [label]="'serialNumbers.set'"
      [title]="'serialNumbers.set'"
      [btnType]="'submit'"
      (onEvent)="setSerialNumbers()"
      [classes]="['btn-first']"
    >
    </btn>
  </ng-container>
</modal-container>
