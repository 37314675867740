import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Depot, Product, ProductBundle, Supplier } from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { CoreService } from '../../../services'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'modal-product-parent',
  templateUrl: 'modal-product-parent.component.html',
  styleUrls: ['modal-product-parent.component.scss'],
})
export class ModalProductParentComponent implements OnInit {
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Input() public product: Product
  @Input() public productBundle: ProductBundle
  @Input() public isForBundleParent = false
  @Input() public isForBundleChild = false
  @Input() public listType = 'parents'
  @Output() public result: EventEmitter<Product | ProductBundle | any> = new EventEmitter()
  @Input() depots: Depot[]
  @Input() suppliers: Supplier[] = []
  @Input() dataCySelector: string
  productQuantities: any = {}
  searchValue = ''
  quantity = 1
  products: Product[] = []
  loading = false
  headerTitle: string
  @Input() btnText: string
  constructor(
    private core: CoreService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (!this.productBundle) {
      this.listType === 'parent' ? (this.headerTitle = 'product.parent') : (this.headerTitle = 'product.choose')
    } else if (this.productBundle) {
      this.headerTitle = 'productBundle.update'
      setTimeout(() => {
        this.productQuantities[this.product.id] = this.productBundle.quantity
        this.quantity = this.productBundle.quantity
      }, 300)
      setTimeout(() => console.log(this.productQuantities), 350)
    }
  }

  public async search() {
    this.loading = true
    const schema = {
      id: null,
      name: null,
      fullName: null,
      ean: null,
      code: null,
      mainImage: {
        id: null,
        text: null,
        originalFilename: null,
        main: null,
        mimeType: null,
        size: null,
        url: null,
        thumbnails: {
          format: null,
          mimeType: null,
          url: null,
        },
      },
      quantityStock: null,
      quantityReservation: null,
      quantityAvailable: null,
      quantityPurchase: null,
      note: null,
      sellPrice: null,
      producer: {
        id: null,
        name: null,
      },
      supplier: {
        id: null,
        name: null,
      },
      vat: {
        id: null,
        name: null,
      },
      quantities: {
        depot: {
          id: null,
          name: null,
        },
        quantityStock: null,
        quantityReservation: null,
        quantityAvailable: null,
        quantityPurchase: null,
      },
    }
    const productList = await this.core.services.product.getList(
      {
        filters: {
          fulltext: this.searchValue ? this.searchValue.trim() : '',
          listType: this.isForBundleChild ? 'children_and_parents_without_children' : this.listType,
        },
      },
      schema
    )

    if (productList && productList.items) {
      this.products = productList.items.filter(p => p?.id !== this.product.id)
    }
    this.loading = false
  }

  selectProduct(product: Product): void {
    if (this.product.id === product.id) {
      alert(this.translateService.instant('modal-product-parent.select-another'))
      return
    }
    if (this.isForBundleParent) {
      const productBundle = new ProductBundle({
        parent: product,
        quantity: this.productQuantities[product.id] !== undefined ? this.productQuantities[product.id] : 1,
      })
      this.result.emit(productBundle)
      this.hideChildModal()
    } else if (this.isForBundleChild) {
      this.productBundle = new ProductBundle({
        child: product,
        quantity: this.productQuantities[product.id] !== undefined ? this.productQuantities[product.id] : 1,
      })
    } else if (!this.isForBundleParent && !this.isForBundleChild) {
      this.result.emit(product)
      this.hideChildModal()
    }
  }

  updateBundle(): void {
    if (this.quantity < 1) {
      alert(this.translateService.instant('modal-product-parent.invalid.quantity'))
      return
    }
    this.productBundle.quantity = this.quantity
    this.result.emit(this.productBundle)
    if (this.isForBundleChild) {
      this.productBundle = undefined
    }
    this.hideChildModal()
  }

  public showChildModal(): void {
    if (!this.productBundle) {
      this.searchValue = ''
      // this.quantity = 1
      this.search()
    } else {
      // this.quantity = this.productBundle.quantity
    }
    this.childModal.showChildModal()
  }

  public hideChildModal(): void {
    this.childModal.hideChildModal()
  }

  setDefaultValues() {
    this.productBundle = undefined
    this.listType = 'parents'
    this.productQuantities = {}
    this.searchValue = ''
    this.quantity = 1
    this.loading = false
    this.headerTitle = ''
  }
}
