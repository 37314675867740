import { Component, OnInit } from '@angular/core'
import { CategoryTreeType } from '../../models'
import { ActivatedRoute, Router } from '@angular/router'
import { Category } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../services'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { SchemaUtil, historyBack } from '../../utils'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'category',
  templateUrl: 'category.component.html',
})
export class CategoryComponent implements OnInit, CheckDataChanges {
  public isCreating: boolean
  public editedCategory: Category
  public loading = false
  public categoryTreeTypeEnum = CategoryTreeType
  submitted = false

  categoryForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, [Validators.required]),
    // text: new FormControl({value: '', disabled: false}, []), // todo
    externalId: new FormControl({ value: '', disabled: false }, []),
  })

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private core: CoreService,
    private notificatonService: NotificationService,
    private translateService: TranslateService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
  }

  async ngOnInit(): Promise<void> {
    const id = this.activatedRoute.snapshot.params['id']
    this.loading = true
    if (isNaN(id)) {
      this.isCreating = true
      this.editedCategory = new Category()
    } else {
      this.isCreating = false
      await this._loadCategoryFromRoute(id)
      this.fillFormValue()
    }
    this.loading = false
  }

  goBack(): void {
    historyBack()
  }

  public fillFormValue() {
    this.categoryForm.setValue({
      name: this.editedCategory.name,
      externalId: this.editedCategory.externalId,
    })
  }

  public getFormValues() {
    this.editedCategory.name = this.categoryForm.value.name
    this.editedCategory.externalId = this.categoryForm.value.externalId
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  private async _loadCategoryFromRoute(id: number): Promise<void> {
    try {
      const category = await this.core.services.category.getById(id, SchemaUtil.category)
      this.editedCategory = new Category(category)
    } catch (e) {
      alert(this.translateService.instant('category.not-found', { exception: JSON.stringify(e) }))
      await this.router.navigate(['/categories'])
    }
  }

  public async createCategory(): Promise<void> {
    this.submitted = true
    if (this.categoryForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValues()
      const category = await this.core.services.category.create(this.editedCategory, { id: null })
      this.notificatonService.success(this.translateService.instant('alert.category.created')) // todo wtf
      if (category) {
        this.router.navigate(['/category', category['id']], { replaceUrl: true })
      }
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
    }
  }

  public async updateCategory() {
    this.submitted = true
    if (this.categoryForm.invalid) {
      return
    }
    this.loading = true
    this.getFormValues()
    const category = await this.core.services.category.update(this.editedCategory)
    this.notificatonService.success(this.translateService.instant('alert.category.saved')) // todo wtf
    this.loading = false
    await this.router.navigate(['/category', category['id']])
  }

  public async deleteCategory() {
    await this.core.services.category.delete(this.editedCategory)
    this.notificatonService.success(this.translateService.instant('alert.category.deleted')) // todo wtf
    await this.router.navigate(['/categories'], { replaceUrl: true })
  }
  get formControls(): { [key: string]: AbstractControl } {
    return this.categoryForm.controls
  }
}
