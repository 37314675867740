<div class="card">
  <div>
    <h3 class="card-title">{{ 'product.moves' | translate }}</h3>
  </div>
  <div class="table-responsive">
    <table class="table m-0">
      <thead>
        <tr>
          <th class="ps-0">{{ 'dateCreated' | translate }}</th>
          <th>{{ 'number' | translate }}</th>
          <th>{{ 'type' | translate }}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let movePack of order.movePacks">
          <td class="ps-1 no-hover text-nowrap">{{ movePack.dateCreated | date: 'dd.MM.yyyy HH:mm' }}</td>
          <td class="no-hover">{{ movePack.number }}</td>
          <td class="no-hover">
            <div class="flex-gap-5">
              <span class="in-badge" *ngIf="movePack.type.id === 'in'" title="{{ movePack.type.id | translate }}">
                <i class="fas fa-plus-square"></i>
              </span>
              <span class="out-badge" *ngIf="movePack.type.id === 'out'" title="{{ movePack.type.id | translate }}">
                <i class="fas fa-minus-square"></i>
              </span>
              <span
                class="transfer-badge"
                *ngIf="movePack.type.id === 'transfer'"
                title="{{ movePack.type.id | translate }}"
              >
                <i class="fas fa-exchange-alt"></i>
              </span>
              <span
                class="reservation-badge"
                *ngIf="movePack.type.id === 'reservation'"
                title="{{ movePack.type.id | translate }}"
              >
                <i class="fas fa-clock"></i>
              </span>
              <span>
                {{ movePack.type.id | translate }}
              </span>
            </div>
          </td>
          <td class="p-1 no-hover">
            <span>
              <a
                class="btn-second float-right"
                [routerLink]="['/product-move-pack', movePack.id]"
                [title]="'open' | translate"
              >
                {{ 'open' | translate }}
                <i class="far fa-chevron-right my-auto"></i>
              </a>
            </span>
            <!--            <btn-->
            <!--              [iconPosition]="'right'"-->
            <!--              [icon]="'far fa-chevron-right'"-->
            <!--              [label]="'open'"-->
            <!--              [classes]="['btn-second', 'btn-sm', 'text-nowrap',' d-flex', 'flex-row-reverse', 'ms-auto']"-->
            <!--              [routerLink]="['/product-move-pack', movePack.id]"-->
            <!--            >-->
            <!--            </btn>-->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
