import { Component, OnInit, OnChanges, ViewChild, Input } from '@angular/core'
import { Company } from 'depoto-core/src/entities'
import { Events } from 'depoto-core/src/utils'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { CoreService } from '../../../services'

@Component({
  selector: 'modal-overdue-payments',
  templateUrl: './modal-overdue-payments.component.html',
  styleUrls: ['./modal-overdue-payments.component.scss'],
})
export class ModalOverduePaymentsComponent implements OnInit, OnChanges {
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Input() public company: Company
  isOverdue = false
  isShutdown = false
  level = 0
  shutdownTimestamp = 5542672000000
  milisecondsToShutdown = 5542672000000
  shutdownCounterString = ''
  isStripeHidden = false
  invoices: any[] = []
  counterInterval: any = null

  constructor(private core: CoreService) {
    this.core.services.oauth.onLogoutEmitter.subscribe().then(() => {
      this.clearOverdueStatus()
    })
  }

  ngOnInit() {
    this.checkOverduePayments()
  }

  ngOnChanges() {
    this.checkOverduePayments()
  }

  showChildModal(): void {
    this.childModal.showChildModal()
  }

  hideChildModal(): void {
    this.childModal.hideChildModal()
  }

  hideAlertStripe(): void {
    this.isStripeHidden = true
  }

  checkOverduePayments() {
    this.clearOverdueStatus()
    if (!this.company) {
      return
    }
    const ico = this.company.ic
    fetch(`https://data.shopty.cz/api-overduepayments.php?ic=${ico}`)
      .then(res => res.json())
      .then((jsonRes: any) => {
        if (jsonRes && jsonRes.data && jsonRes.data.length > 0) {
          this.invoices = jsonRes.data
          const todayTimestamp = new Date().getTime()
          let overdue = 0 // 0: ok, 1 - 3: alert
          let firstAlert3timestamp = 5542672000000
          jsonRes.data.forEach((invoice: any) => {
            if (new Date(invoice.due_date).getTime() < todayTimestamp) {
              if (new Date(invoice.alert1).getTime() < todayTimestamp) {
                overdue = 1
              }
              if (new Date(invoice.alert2).getTime() < todayTimestamp) {
                overdue = 2
              }
              const invoiceAlert3timestamp = new Date(invoice.alert3).getTime()
              if (invoiceAlert3timestamp < todayTimestamp) {
                overdue = 3
              }
              if (overdue > 0) {
                this.isOverdue = true
                if (this.level <= overdue) {
                  this.level = overdue
                }
                overdue = 0
              }
              if (invoiceAlert3timestamp < firstAlert3timestamp) {
                firstAlert3timestamp = invoiceAlert3timestamp
              }
            }
          })
          this.shutdownTimestamp = firstAlert3timestamp
          this.handleCounter()
        }
      })
      .catch(e => console.warn('checkOverdue err: ', e))
  }

  clearOverdueStatus() {
    this.stopCounter()
    this.isShutdown = false
    this.isOverdue = false
    this.level = 0
    this.shutdownTimestamp = 5542672000000
    this.milisecondsToShutdown = 5542672000000
    this.shutdownCounterString = ''
  }

  handleCounter() {
    if (this.level > 1) {
      this.milisecondsToShutdown = this.shutdownTimestamp - new Date().getTime()
      // this.milisecondsToShutdown = 1546172149000 - new Date().getTime() // todo: test
      if (this.milisecondsToShutdown <= 0) {
        this.isShutdown = true
        this.stopCounter()
        Events.dispatch('overdue:shutdown')
      }
      this.formatCounterString()
      if (!this.counterInterval) {
        this.counterInterval = setInterval(() => {
          this.handleCounter()
        }, 950)
      }
    }
  }

  stopCounter() {
    if (this.counterInterval) {
      clearInterval(this.counterInterval)
      this.counterInterval = null
    }
  }

  formatCounterString() {
    let seconds = Math.floor(this.milisecondsToShutdown / 1000)
    const days = Math.floor(seconds / (60 * 60 * 24))
    seconds -= days * (60 * 60 * 24)
    const hours = Math.floor(seconds / (60 * 60))
    seconds -= hours * (60 * 60)
    const minutes = Math.floor(seconds / 60)
    seconds -= minutes * 60
    this.shutdownCounterString = `${days !== 0 ? days + 'd, ' : ''}${hours > 0 ? ('0' + hours).slice(-2) + ':' : ''}${
      minutes > 0 ? ('0' + minutes).slice(-2) + ':' : '00:'
    }${seconds > 0 ? ('0' + seconds).slice(-2) : '00'}`
  }
}
