import { Component, OnDestroy, OnInit } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { CoreService } from '../../services'
import { User } from 'depoto-core/src/entities/User'
import { Checkout } from 'depoto-core/src/entities/Checkout'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
  public isTTAnalyticsPermitted = false
  public isChartsPermitted = false
  public analyticsUrl =
    'https://matomo.tomatom.cz/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Live&actionToWidgetize=getSimpleLastVisitCount&idSite=1&period=day&date=yesterday&disableLink=1&widget=1&token_auth=1aa4587e94d5630bbc77443b2bf22df3'
  public analyticsUrlSafe: SafeResourceUrl
  public checkouts: Checkout[] = []
  private subscriptions = new Subscription()
  constructor(
    private sanitizer: DomSanitizer,
    private core: CoreService
  ) {}

  async ngOnInit() {
    const userSubscription = this.core.services.user.currentUserEmitter.subscribe()
    this.subscriptions.add(userSubscription)
    userSubscription.then((user: User) => {
      this.getPermissionsInfo()
    })

    if (this.core.services.user.user) {
      this.getPermissionsInfo()
    }
    this.analyticsUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.analyticsUrl)

    this.subscriptions.add(
      this.core.onCoreEntityListsLoaded.subscribe(lists => {
        this.checkouts = lists.checkouts
      })
    )
    // setTimeout(() => {
    this.checkouts = this.core.lists.checkouts
    // }, 500)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  private getPermissionsInfo(): void {
    this.isChartsPermitted = this.core.services.user.hasOneOfTheRoles([
      'ROLE_DASHBOARD_CHECKOUTS',
      'ROLE_SUPER_ADMIN',
      'ROLE_ADMIN',
    ])
    const user = this.core.services.user.user
    if (user && user.id > 0) {
      this.isTTAnalyticsPermitted = user.email.includes('@tomatom.cz')
    } else {
      this.isTTAnalyticsPermitted = false
    }
  }
}
