import { Component, OnInit } from '@angular/core'
import { GeoConfig, GeoListItemType } from '../../configs'
import { Company } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../services'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-company-settings',
  templateUrl: 'company-settings.component.html',
})
export class CompanySettingsComponent implements OnInit, CheckDataChanges {
  company: Company
  countries: GeoListItemType[] = GeoConfig.countries
  loading = true
  submitted = false

  companySettingsForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, []),
    ic: new FormControl({ value: '', disabled: false }, []),
    dic: new FormControl({ value: '', disabled: false }, []),
    phone: new FormControl({ value: '', disabled: false }, []),
    email: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.email]),
    street: new FormControl({ value: '', disabled: false }, []),
    city: new FormControl({ value: '', disabled: false }, []),
    zip: new FormControl({ value: '', disabled: false }, []),
    country: new FormControl({ value: '', disabled: false }, [Validators.required]),
    registrationNote: new FormControl({ value: '', disabled: false }, []),
    nextEan: new FormControl({ value: 0, disabled: false }, []),
    defaultVoucherValidity: new FormControl({ value: 6, disabled: false }, []),
    billLogo: new FormControl({ value: '', disabled: false }, []),
  })

  constructor(
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.loading = true
    const company = this.core.services.storage.getSync(this.core.services.storage.keys.auth.company)
    console.warn(company)
    if (company && company.id > 0) {
      this.company = company
      this.fillFormValue()
      this.loading = false
    }
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  public fillFormValue() {
    this.companySettingsForm.setValue({
      name: this.company.name,
      ic: this.company.ic,
      dic: this.company.dic,
      phone: this.company.phone,
      email: this.company.email,
      street: this.company.street,
      city: this.company.city,
      zip: this.company.zip,
      country: this.company.country,
      registrationNote: this.company.registrationNote,
      nextEan: this.company.nextEan,
      defaultVoucherValidity: this.company.defaultVoucherValidity || 6,
      billLogo: this.company.billLogo || '',
    })
  }

  public getFormValue() {
    this.company.name = this.companySettingsForm.value.name
    this.company.ic = this.companySettingsForm.value.ic
    this.company.dic = this.companySettingsForm.value.dic
    this.company.phone = this.companySettingsForm.value.phone
    this.company.email = this.companySettingsForm.value.email
    this.company.street = this.companySettingsForm.value.street
    this.company.city = this.companySettingsForm.value.city
    this.company.zip = this.companySettingsForm.value.zip
    this.company.country = this.companySettingsForm.value.country
    this.company.registrationNote = this.companySettingsForm.value.registrationNote
    this.company.nextEan = this.companySettingsForm.value.nextEan
    this.company.defaultVoucherValidity = this.companySettingsForm.value.defaultVoucherValidity
    this.company.billLogo = this.companySettingsForm.value.billLogo
  }

  async updateCompany() {
    this.submitted = true
    if (this.companySettingsForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValue()
      await this.core.services.company.update(this.company).then(() => {
        this.notificationService.success(this.translateService.instant('alert.company.updated'))
        this.core.services.storage.set(this.core.services.storage.keys.auth.company, this.company)
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }
  get formControls(): { [key: string]: AbstractControl } {
    return this.companySettingsForm.controls
  }
}
