import { Component, OnInit } from '@angular/core'
import { Role, User, UserGroup } from 'depoto-core/src/entities'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { CoreService } from '../../services'
import { usersColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  email: '',
  enabled: 'all',
  groups: 'all',
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
})
export class UsersComponent extends BaseListing implements OnInit {
  listingColumns: ListingItem[] = usersColumns
  sortProp = 'lastName'
  sortDir = 'asc'
  users: User[] = []
  roles: Role[] = []
  groups: UserGroup[] = []
  enabledTypes: string[] = ['all', 'yes', 'no']
  totalItems = 0
  currentPage: number
  endPage: number
  filters = { ...DEFAULT_FILTERS }
  loading = true
  storageKey: { type: string; key: string }

  constructor(protected core: CoreService) {
    super(core)
    this.getStorageKey()
  }

  async ngOnInit() {
    this.filters = this.loadViewSettings('users') || { ...DEFAULT_FILTERS }
    await this.refreshFilterData()
    this.loadSorting('users')
    this.filter(1)
  }

  async refreshFilterData() {
    const result = await this.core.services.userGroup.getList(
      {},
      {
        id: null,
        name: null,
      }
    )
    this.groups = result.items
  }

  clearFilters(): void {
    this.filters = { ...DEFAULT_FILTERS }
    this.filter(1)
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.currentPage = pageNo
    if (!concat) {
      this.reloading = true
    }
    const options: any = {}
    if (this.filters.email && this.filters.email.length > 0) {
      options.email = this.filters.email
    }
    if (this.filters.enabled !== 'all') {
      options.enabled = this.filters.enabled === 'Ano'
    }
    if (this.filters.groups?.length > 0) {
      options.groups = this.filters.groups
    }
    try {
      const result = await this.core.services.user.getList(
        { filters: options, page: pageNo, sort: sortProp, direction: sortDir },
        {
          id: null,
          email: null,
          enabled: null,
          groups: {
            id: null,
            name: null,
            roles: null,
          },
          firstName: null,
          lastName: null,
        }
      )
      if (!result?.items) return
      if (concat) {
        result.items.forEach(user => this.users.push(new User(user)))
      } else {
        this.users = result.items
      }
      this.totalItems = result.paginator ? result.paginator.totalCount : 0
      this.endPage = result.paginator ? result.paginator.endPage : 0
      this.currentPage++
      this.saveViewSettings('users', this.filters)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }
}
