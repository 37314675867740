<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="customerForm" (ngSubmit)="isCreating ? createCustomer() : updateCustomer()" *ngIf="customer">
    <page-header
      *ngIf="customer"
      [icon]="'far-user'"
      [title]="customer.id === 0 || isCreating ? 'customer.new' : ''"
      [appendNoTranslationTitlePart]="
        customer.id === 0 || isCreating
          ? ''
          : (customer.firstName ? customer.firstName : '') + ' ' + (customer.lastName ? customer.lastName : '')
      "
    >
      <ng-container class="header-button">
        <btn
          [label]="'save'"
          [title]="'save' | translate"
          [icon]="'far-check'"
          [classes]="['float-right', 'btn-first']"
          [btnType]="'submit'"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-white'"
          [btnDisabled]="loading"
          [dataCyAttribute]="'added-customer'"
        ></btn>
        <btn
          *ngIf="isInCypressTest"
          [btnType]="'submit'"
          [icon]="'far-trash-alt'"
          [label]="'delete'"
          [title]="'delete' | translate"
          [classes]="['btn-first-red', 'float-right']"
          (onEvent)="deleteCustomer()"
          [dataCyAttribute]="'delete-customer'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>
    <section class="content">
      <div class="row">
        <div class="col-12 col-xl-4">
          <div class="card">
            <div class="row">
              <div class="col-12">
                <h3 class="card-title">
                  {{ 'customer.info' | translate }}
                </h3>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <div class="row">
                  <div class="col">
                    <form-row-input
                      [icon]="'far-user'"
                      [label]="'firstName'"
                      [formGroup]="customerForm"
                      formControlName="firstName"
                      [required]="
                        customerForm.get('firstName').errors !== null && customerForm.get('firstName').errors.required
                      "
                      [requiredCssClass]="submitted && formControls.firstName.errors"
                      [dataCyAttribute]="'customer-first-name'"
                    ></form-row-input>
                  </div>
                  <div class="col">
                    <form-row-input
                      [icon]="'far-user'"
                      [label]="'lastName'"
                      [formGroup]="customerForm"
                      formControlName="lastName"
                      [required]="
                        customerForm.get('lastName').errors !== null && customerForm.get('lastName').errors.required
                      "
                      [requiredCssClass]="submitted && formControls.lastName.errors"
                      [dataCyAttribute]="'customer-last-name'"
                    ></form-row-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <form-row-input
                      [icon]="'far-envelope'"
                      [label]="'email'"
                      [formGroup]="customerForm"
                      formControlName="email"
                      [required]="
                        customerForm.get('email').errors !== null && customerForm.get('email').errors.required
                      "
                      [requiredCssClass]="submitted && formControls.email.errors"
                      [dataCyAttribute]="'customer-email'"
                    ></form-row-input>
                  </div>
                  <div class="col">
                    <form-row-input
                      [icon]="'far-phone'"
                      [label]="'phone'"
                      [formGroup]="customerForm"
                      formControlName="phone"
                      [dataCyAttribute]="'customer-phone'"
                    ></form-row-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <form-row-input
                      [icon]="'far-user'"
                      [label]="'company.name'"
                      [formGroup]="customerForm"
                      formControlName="companyName"
                      [dataCyAttribute]="'customer-company-name'"
                    ></form-row-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <form-row-input
                      [inputType]="'date'"
                      [icon]="'far-calendar'"
                      [label]="'birthday'"
                      [formGroup]="customerForm"
                      formControlName="birthday"
                      [dataCyAttribute]="'customer-birthday'"
                    ></form-row-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <form-row-select-multiple
                      [label]="'tags'"
                      [options]="tags"
                      [isTags]="true"
                      [selectedValues]="customer && customer.tags ? customer.tags : []"
                      (selectedValuesChange)="onTagsChange($event)"
                      [dataCyAttribute]="'customer-tags'"
                    >
                    </form-row-select-multiple>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <form-row-select
                      [label]="'priceLevel.title'"
                      [formGroup]="customerForm"
                      formControlName="priceLevel"
                      [icon]="'far-tag'"
                      [options]="priceLevels"
                      [dataCyAttribute]="'customer-priceLevels'"
                    >
                    </form-row-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <form-row-textarea
                      [icon]="'far-note'"
                      [label]="'note'"
                      [(ngModel)]="customer.note"
                      [ngModelOptions]="{ standalone: true }"
                      [dataCyAttribute]="'customer-note'"
                    ></form-row-textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <form-row-checkbox
                      [label]="'wholesale'"
                      [cursorPointer]="true"
                      [formGroup]="customerForm"
                      formControlName="wholesale"
                      [dataCySelector]="'wholesale'"
                    ></form-row-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!isCreating" class="col-12" [ngClass]="isShowingSalesmen ? 'col-xl-4' : 'col-xl-8'">
          <div class="card" [ngClass]="{ 'float-left w-49': !isShowingSalesmen }">
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="card-title">
                {{ 'customer.consents' | translate }}
              </h3>
              <modal-consent [customer]="customer" (consentAdded)="refreshData(customer.id)"></modal-consent>
            </div>
            <div
              class="table-responsive mt-2"
              *ngIf="customer.consentRelations && customer.consentRelations.length > 0"
            >
              <table class="table mb-0">
                <tbody>
                  <tr *ngFor="let cc of customer.consentRelations">
                    <td class="ps-1">
                      <a [routerLink]="['/consent/', cc.consent.id]">{{ cc.name }}</a>
                    </td>
                    <td class="text-end pe-1" title="{{ 'dateCreated' | translate }}">
                      {{ cc.created | fmtDate: 'dd.MM.yyyy HH:mm' }}
                      <btn
                        [icon]="'far-trash-alt'"
                        [title]="'delete' | translate"
                        [classes]="['btn-trash', 'margin-left', 'float-right']"
                        (onEvent)="deleteCustomerConsent(cc)"
                        [dataCyAttribute]="'delete-customer'"
                      ></btn>
                      <btn
                        [icon]="'far-print'"
                        [classes]="['btn-second', 'margin-left', 'float-right']"
                        (onEvent)="printCustomerConsent(cc)"
                      ></btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card" [ngClass]="{ 'float-right w-49': !isShowingSalesmen }">
            <div>
              <h3 class="card-title">
                {{ 'address' | translate }}
              </h3>
              <modal-address
                [address]="newAddress"
                [isCreating]="true"
                [customer]="customer"
                (result)="addAddress($event)"
              >
              </modal-address>
            </div>
            <div class="table-responsive">
              <table class="table mb-0">
                <tbody>
                  <tr *ngFor="let address of customer.addresses">
                    <ng-template [ngIf]="address.isStored">
                      <td class="text-start ps-1">{{ address.firstName + ' ' + address.lastName }}</td>
                      <td>
                        <modal-address
                          [ngClass]="'float-right'"
                          [address]="address"
                          [btnLabel]="null"
                          [btnIcon]="'far-pencil'"
                          [customer]="customer"
                          (result)="refreshData(customer.id)"
                        ></modal-address>
                      </td>
                    </ng-template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-4" *ngIf="isShowingSalesmen">
          <div class="card">
            <div>
              <h3 class="card-title">
                {{ 'customers.salesmen' | translate }}
              </h3>
            </div>
            <div class="card-body mt-2 p-0">
              <div class="table-responsive">
                <table class="table mb-0">
                  <tbody>
                    <tr *ngFor="let salesman of salesmen">
                      <td class="ps-1">
                        <div class="d-flex">
                          <form-row-checkbox
                            [cursorPointer]="true"
                            [noMarginBottom]="true"
                            [noWrap]="true"
                            [label]="
                              (salesman.firstName ? salesman.firstName : '') +
                              ' ' +
                              (salesman.lastName ? salesman.lastName : '')
                            "
                            [model]="isSalesmanSelected(salesman)"
                            (modelChange)="toggleSalesmanSelected(salesman)"
                          >
                          </form-row-checkbox>
                        </div>
                      </td>
                      <td class="text-end ps-0">
                        <div>{{ salesman.email ? salesman.email : '' }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
