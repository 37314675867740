<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="tagForm" (ngSubmit)="isCreating ? createTag() : updateTag()" *ngIf="tag">
    <page-header
      *ngIf="tag"
      [icon]="'fa-tag'"
      [title]="isCreating ? 'tag.new' : ''"
      [appendNoTranslationTitlePart]="isCreating ? '' : tag.name"
    >
      <ng-container class="header-button">
        <btn
          [label]="'save'"
          [title]="'save' | translate"
          [icon]="'far-check'"
          [btnType]="'submit'"
          [classes]="['btn-first', 'float-right']"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-white'"
          [btnDisabled]="loading"
          [dataCyAttribute]="'create-tag'"
        ></btn>
        <btn
          *ngIf="!isCreating"
          [label]="'tag.delete'"
          [title]="'tag.delete' | translate"
          [icon]="'far-trash-alt'"
          (onEvent)="deleteTag()"
          [classes]="['btn-first-red', 'float-right', 'me-2']"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-white'"
          [dataCyAttribute]="'delete-tag'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <section class="content">
      <div class="card" [ngClass]="{ h150: !tag && loading }">
        <div class="row">
          <div class="col-12">
            <form-row-input
              [formGroup]="tagForm"
              formControlName="name"
              [required]="tagForm.get('name').errors !== null && tagForm.get('name').errors.required"
              [requiredCssClass]="submitted && formControls.name.errors"
              [icon]="'fa-id-badge'"
              [label]="'tag.name'"
              [dataCyAttribute]="'name'"
            ></form-row-input>
            <form-row-select
              [formGroup]="tagForm"
              formControlName="type"
              [required]="tagForm.get('type').errors !== null && tagForm.get('type').errors.required"
              [requiredCssClass]="submitted && formControls.type.errors"
              [label]="'tag.type'"
              [translationPrefix]="'tags.type.'"
              [options]="tagTypes"
              [dataCyAttribute]="'tag-type-select'"
            ></form-row-select>
            <form-row-input
              [formGroup]="tagForm"
              formControlName="color"
              [icon]="'fa-tint'"
              [label]="'tag.color'"
              [inputType]="'color'"
            ></form-row-input>
            <form-row-input
              [formGroup]="tagForm"
              formControlName="externalId"
              [label]="'externalId'"
              [dataCyAttribute]="'external-id'"
            ></form-row-input>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
