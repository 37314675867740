import { Component, Input, AfterViewChecked } from '@angular/core'
import { Router } from '@angular/router'
import { MenuItems, MenuService } from '../../configs/menu.service'
import { CoreService } from '../../services'
import { TranslateService } from '@ngx-translate/core'
declare let $: any

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements AfterViewChecked {
  @Input() isShown = false
  public menuItems: MenuItems[] = []
  public isMenuReady = false
  menuWasCheckedOnInit = false

  constructor(
    private router: Router,
    private core: CoreService,
    private menuService: MenuService,
    private translateService: TranslateService
  ) {
    this.core.services.user.currentUserEmitter.subscribe().then(user => {
      if (user?.id) {
        this.menuItems = this.menuService.menuItems(this.core.services.user)
        this.isMenuReady = true
        this.isShown = true
        // setTimeout(() => $('[data-widget="treeview"]').Treeview('init'), 0)
      } else {
        this.menuItems = []
        this.isMenuReady = false
        this.isShown = false
      }
    })
    this.translateService.onLangChange.subscribe(ev => {
      this.menuItems = this.menuService.menuItems(this.core.services.user)
    })
  }
  ngAfterViewChecked() {
    if (this.menuWasCheckedOnInit || !this.isMenuReady) {
      return
    }
    const activeSubmenu = document.querySelector('.nav-item .active-submenu')
    if (activeSubmenu) {
      setTimeout(() => {
        const liParent = activeSubmenu.closest('.has-treeview')
        liParent?.classList.add('menu-open')
        liParent?.querySelector('.nav-link:not(.nav-link-sub)')?.classList.add('active')
      }, 0)
      // for the changes to be applied asyncronously, but with zero delay
    }
    this.menuWasCheckedOnInit = true
  }
  public handleSubtree(event: Event) {
    event.preventDefault()
    const a = event.currentTarget as HTMLAnchorElement
    const li = a.closest('.nav-item')
    if (!li) {
      return
    }
    if (li.classList.contains('menu-open')) {
      li.classList.remove('menu-open')
      setTimeout(() => li.classList.remove('menu-is-opening'), 500)
      return
    }
    if (li.classList.contains('has-treeview')) {
      li.classList.add('menu-is-opening')
      setTimeout(() => li.classList.add('menu-open'), 0)
    }
    ;[...li.closest('ul').querySelectorAll(':scope > li')]
      .filter(li => li.classList.contains('menu-open'))[0]
      ?.querySelector('a')
      ?.click()
  }
  public makeParentActive(event: Event) {
    ;(event.currentTarget as HTMLElement).closest('.has-treeview')?.querySelector('.nav-link')?.classList.add('active')
  }
  public isActiveLink(item: MenuItems) {
    if (item.link?.[0]) {
      return item.link[0] === this.router.url
    } else {
      return false
    }
  }
}
