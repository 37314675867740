import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { Checkout, Depot, Producer, Supplier } from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { CoreService } from '../../../services'
import { StorageKeyService } from '../../../services/storage-key.service'

@Component({
  selector: 'modal-print-export',
  templateUrl: 'modal-print-export.component.html',
})
export class ModalPrintExportComponent implements OnInit {
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Input() exportType: string
  @Input() dataCyAttribute: string
  @Output() resultExport: EventEmitter<any> = new EventEmitter()

  exportTypes: any = {
    purchaseExports: 'purchase-exports',
    saleExports: 'sale-exports',
    stockExports: 'stock-exports',
    inventoryExports: 'inventory-exports',
    orderExports: 'order-exports',
  }
  orderStatusTypes: string[] = ['all', 'bill', 'reservation']
  exportFormatTypes: string[] = ['pdf', 'excel']
  stockExportDateTypes: string[] = ['now', 'date']
  selectedExportFormat = 'pdf'
  depots: Depot[] = []
  checkouts: Checkout[] = []
  producers: Producer[] = []
  suppliers: Supplier[] = []
  stockExportDateType = 'now' // `now` | `date`
  dateFrom = ''
  dateTo = ''
  selectedDepots: Depot[] = []
  selectedCheckouts: Checkout[] = []
  selectedProducers: Producer[] = []
  selectedSuppliers: Supplier[] = []
  selectedOrderStatus = 'all'
  loading = false
  isComponentLoaded = false
  private translateService: any

  constructor(
    private core: CoreService,
    private keyService: StorageKeyService
  ) {}

  async ngOnInit() {
    await this.refreshFiltersData()
  }

  showChildModal(): void {
    this.selectedCheckouts = []
    this.selectedDepots = []
    this.selectedSuppliers = []
    this.selectedProducers = []
    this.selectedOrderStatus = 'all'
    this.selectedExportFormat = 'pdf'
    this.stockExportDateType = 'now'
    this.childModal.showChildModal()
  }

  hideChildModal(): void {
    this.childModal.hideChildModal()
  }

  loadViewSettings(): void {
    const settings = this.core.services.storage.getSync(this.keyService.getStorageKeyForEntity('modalPrintExports'))
    if (settings && Object.keys(settings).length > 0) {
      this.selectedDepots = settings.selectedDepots || []
      this.selectedCheckouts = settings.selectedCheckouts || []
      this.selectedProducers = settings.selectedProducers || []
      this.selectedSuppliers = settings.selectedSuppliers || []
      this.dateFrom = settings.dateFrom
      this.dateTo = settings.dateTo
    }
  }

  saveViewSettings(): void {
    const settings = {
      selectedDepots: this.selectedDepots,
      selectedCheckouts: this.selectedCheckouts,
      selectedProducers: this.selectedProducers,
      selectedSuppliers: this.selectedSuppliers,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    }
    this.core.services.storage.set(this.keyService.getStorageKeyForEntity('modalPrintExports'), settings)
  }

  async refreshFiltersData() {
    try {
      this.loading = true
      this.checkouts = this.core.lists.checkouts
      this.depots = this.core.lists.depots
      this.producers = this.core.lists.producers
      this.suppliers = this.core.lists.suppliers
      this.loadViewSettings()
      this.isComponentLoaded = true
      this.loading = false
    } catch (e) {
      console.warn('modal-print-exports::refreshData error', e)
    }
  }

  selectValue(entityIds: Array<number | string>, entityName: string) {
    if (!this.isComponentLoaded) {
      return
    }
    const selectedEntity = `selected${entityName[0].toUpperCase() + entityName.slice(1)}`
    this[selectedEntity] = []

    if (entityIds && entityIds.length > 0) {
      entityIds.forEach(entityId => {
        this[selectedEntity].push(this[entityName].filter(entity => entity.id === Number(entityId))[0])
      })
    }
    this.saveViewSettings()
  }

  createPrintExport() {
    if (
      (this.exportType === this.exportTypes.stockExports ||
        this.exportType === this.exportTypes.inventoryExports ||
        this.exportType === this.exportTypes.purchaseExports) &&
      this.selectedDepots.length < 1
    ) {
      return alert(this.translateService.instant('modal-print-export.select-warehouse'))
    }
    const printExport: any = {}
    switch (this.exportType) {
      case this.exportTypes.purchaseExports:
        printExport.dateFrom = this.dateFrom
        printExport.dateTo = this.dateTo
        printExport.depots = [...this.selectedDepots]
        printExport.checkouts = [...this.selectedCheckouts]
        printExport.suppliers = [...this.selectedSuppliers]
        printExport.producers = [...this.selectedProducers]
        break
      case this.exportTypes.saleExports:
        printExport.dateFrom = this.dateFrom
        printExport.dateTo = this.dateTo
        printExport.checkouts = [...this.selectedCheckouts]
        printExport.suppliers = [...this.selectedSuppliers]
        printExport.producers = [...this.selectedProducers]
        break
      case this.exportTypes.stockExports:
        if (this.stockExportDateType === 'date' && this.dateFrom && this.dateFrom.length > 0) {
          printExport.date = this.dateFrom
        }
        printExport.depots = [...this.selectedDepots]
        printExport.suppliers = [...this.selectedSuppliers]
        printExport.producers = [...this.selectedProducers]
        break
      case this.exportTypes.inventoryExports:
        if (this.dateFrom && this.dateFrom.length > 0) {
          printExport.date = this.dateFrom
        }
        printExport.depots = [...this.selectedDepots]
        printExport.suppliers = [...this.selectedSuppliers]
        printExport.producers = [...this.selectedProducers]
        break
      case this.exportTypes.orderExports:
        if (this.selectedOrderStatus !== 'all') {
          printExport.status = this.selectedOrderStatus
        }
        printExport.dateFrom = this.dateFrom
        printExport.dateTo = this.dateTo
        printExport.checkouts = [...this.selectedCheckouts]
        break
    }
    if (this.exportType !== this.exportTypes.inventoryExports) {
      printExport.format = this.selectedExportFormat
    }
    this.resultExport.emit(printExport)
    this.hideChildModal()
  }
}
