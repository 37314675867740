<div class="card">
  <div>
    <h3 class="card-title">{{ 'packages' | translate }}</h3>
  </div>
  <div class="table-responsive">
    <table class="table m-0">
      <thead>
        <tr>
          <th>{{ 'carrier' | translate }}</th>
          <th>{{ 'code' | translate }}</th>
          <th>{{ 'weight' | translate }}</th>
          <th>&nbsp;</th>
          <th>{{ 'packageStatus' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pack of order.packages">
          <td class="no-hover">{{ pack.carrier.name }}</td>
          <td class="no-hover">{{ pack.code }}</td>
          <td class="no-hover">{{ pack.weight }}</td>
          <td class="no-hover">
            <btn
              *ngIf="pack.code && pack.code.length > 0"
              [label]="'ticket'"
              [icon]="'far-tag'"
              [classes]="['btn-third', 'btn-sm']"
              (onEvent)="downloadFromUrl(pack.ticketUrl)"
            ></btn>
          </td>
          <td class="p-0 no-hover">
            <modal-process-status [package]="pack" [packageStates]="pack.statuses"></modal-process-status>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
