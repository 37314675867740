<modal-container #modal [headerIconClass]="'far-edit'" [headerTitle]="'category.select'" class="customer-search-modal">
  <ng-container class="modal-button-show">
    <form-row-input
      [label]="'category'"
      [model]="selectedCategories?.length > 0 ? getNamesInString() : ''"
      [inputDisabled]="true"
      [dataCyAttribute]="'products-categories-filter'"
    >
      <button
        *ngIf="selectedCategories?.length"
        (click)="removeCategories()"
        [title]="'clear' | translate"
        data-cy="'products-categories-filter-clear'"
        class="btn-clear"
      >
        ×
      </button>
      <btn
        (onEvent)="showChildModal()"
        [icon]="'far-pencil'"
        [classes]="['btn-first']"
        [title]="'customer' | translate"
        [dataCyAttribute]="'modal-category-search-open-btn'"
      ></btn>
    </form-row-input>
  </ng-container>

  <ng-container class="modal-body-content">
    <div class="card">
      <category-tree
        *ngIf="isModalShown"
        [type]="categoryTreeTypeEnum.checkboxProductCategories"
        [categoryTreeLabel]="'product.tab.categories.categories'"
        [isForFilterCategories]="true"
        [categories]="categories"
        [(selectedCategories)]="selectedCategories"
      >
      </category-tree>
    </div>
  </ng-container>

  <ng-container class="modal-footer-content">
    <btn
      [label]="'select'"
      [title]="'select' | translate"
      [classes]="['btn-first']"
      [icon]="'fa-check far me-2'"
      (onEvent)="saveSelection()"
      [dataCyAttribute]="'category-search-submit'"
    >
    </btn>
  </ng-container>
</modal-container>
