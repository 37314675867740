<div class="app-container">
  <page-header [icon]="'fa-hands-usd'" [title]="'priceLevels.title'">
    <ng-container class="header-button">
      <btn
        [routerLink]="['/price-level']"
        [icon]="'far-plus'"
        [label]="'priceLevel.create'"
        [title]="'priceLevel.create' | translate"
        [classes]="['btn-first', 'float-right']"
        [dataCyAttribute]="'create-price-group'"
      ></btn>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form (onSubmit)="filter()" (onClearClick)="clearFilters()" [isFilterInvisible]="true">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3">
          <form-row-input
            [(model)]="filters.name"
            [icon]="null"
            [label]="'priceLevel.name'"
            (keydown.enter)="filter()"
            [dataCyAttribute]="'price-level-name-filter'"
          ></form-row-input>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
          <form-row-select
            [(model)]="filters.isPercentage"
            [icon]="null"
            [label]="'priceLevel.isPercentage'"
            [options]="booleanFilterOptions"
            [dataCyAttribute]="'isPercentage-filter'"
          ></form-row-select>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
          <form-row-select
            [(model)]="filters.withVat"
            [icon]="null"
            [label]="'priceLevel.withVat'"
            [options]="booleanFilterOptions"
            [dataCyAttribute]="'withVat-filter'"
          ></form-row-select>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
          <form-row-select
            [options]="currencies"
            [icon]="null"
            [label]="'priceLevel.currency'"
            [(model)]="filters.currency"
            [dataCyAttribute]="'currency-filter'"
          ></form-row-select>
        </div>
      </div>
    </app-filter-form>

    <app-base-listing
      [listName]="'priceLevels'"
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="priceLevels"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [(sortDir)]="sortDir"
      [(sortProp)]="sortProp"
      (reloadList)="filter()"
      (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
      (callAction)="callAction($event)"
    ></app-base-listing>
  </section>
</div>
