import { Component, OnInit, Input } from '@angular/core'
import { Checkout, StatsBestseller, StatsCarrierUsage, StatsPaymentSale } from 'depoto-core/src/entities'
import { CoreService } from '../../services'
import { SchemaUtil } from '../../utils'

@Component({
  selector: 'app-charts-stats-checkout',
  templateUrl: './charts-stats-checkout.component.html',
})
export class ChartsStatsCheckoutComponent implements OnInit {
  @Input() checkout: Checkout
  bestsellersData: StatsBestseller[] = []
  carrierUsageData: StatsCarrierUsage[] = []
  paymentSalesData: StatsPaymentSale[] = []
  dateFrom: string
  dateTo: string
  timespans: string[] = ['thisDay', 'week', 'thisMonth', 'month', '3months', 'thisYear', 'year']
  selectedTimespan = 'month'
  isNoDataForChart = false

  constructor(private core: CoreService) {}

  ngOnInit() {
    this.setTimespan('month')
    this.reloadCharts()
  }

  setTimespan(timespan: string) {
    this.selectedTimespan = timespan
    const from = new Date()
    const to = new Date()
    switch (this.selectedTimespan) {
      case 'thisDay':
        break
      case 'week':
        from.setDate(from.getDate() - 7)
        break
      case 'thisMonth':
        from.setDate(1)
        break
      case 'month':
        from.setMonth(from.getMonth() - 1)
        break
      case '3months':
        from.setMonth(from.getMonth() - 3)
        break
      case 'thisYear':
        from.setDate(1)
        from.setMonth(0)
        break
      case 'year':
        from.setFullYear(from.getFullYear() - 1)
        break
    }
    this.dateFrom = `${from.toISOString().substring(0, 10)} 00:00:00`
    this.dateTo = `${to.toISOString().substring(0, 10)} 23:59:59`
  }

  async reloadCharts() {
    this.isNoDataForChart = false
    try {
      const bestsellersData = await this.core.services.stats.getStatsBestsellers(
        this.dateFrom,
        this.dateTo,
        this.checkout.id,
        50,
        SchemaUtil.bestseller
      )
      this.bestsellersData = bestsellersData?.items ?? []

      const carrierUsageData = await this.core.services.stats.getStatsCarrierUsage(
        this.dateFrom,
        this.dateTo,
        this.checkout.id,
        SchemaUtil.carrierUsage
      )
      this.carrierUsageData = carrierUsageData?.items ?? []

      const paymentSalesData = await this.core.services.stats.getStatsPaymentSale(
        this.dateFrom,
        this.dateTo,
        this.checkout.id,
        SchemaUtil.paymentSales
      )
      this.paymentSalesData = paymentSalesData?.items ?? []
    } catch (err) {
      if (err.name === 'AbortError') {
        console.warn('The user aborted a request.')
      } else {
        console.error(err)
      }
    } finally {
      this.isNoDataForChart =
        this.bestsellersData &&
        !this.bestsellersData.length &&
        this.carrierUsageData &&
        !this.carrierUsageData.length &&
        this.paymentSalesData &&
        !this.paymentSalesData.length
    }
  }
}
