<modal-container
  [headerIconClass]=""
  [headerTitle]="('add' | translate) + ' ' + order?.fullName"
  [modalHeaderBgColorClass]="'header-class bg_green'"
  [modalBodyBgColorClass]="'bg_green'"
  [modalFooterBgColorClass]="'bg_green'"
  [isLargeModal]="true"
>
  <ng-container class="modal-body-content">
    <div class="container-fluid ps-0 pe-0">
      <div class="stock-operation-card--content">
        <div class="card" style="padding: 26px">
          <div class="row">
            <form-row-input
              class="col-4"
              [label]="'product.code'"
              [icon]="'far-tag'"
              [model]="order?.code"
              [inputDisabled]="true"
              data-cy="code"
            ></form-row-input>
            <form-row-input
              class="col-8"
              [label]="'product.ean'"
              [icon]="'far-barcode'"
              [translateBarcode]="true"
              [model]="order?.ean"
              [appendBtnClasses]="['btn', 'btn-ean']"
              [inputDisabled]="true"
              data-cy="ean"
            >
            </form-row-input>
          </div>
          <div class="row">
            <form-row-input
              class="col"
              [inputType]="'number'"
              [label]="'quantity'"
              [icon]="'far-list'"
              [model]="this.order.quantity"
              (modelChange)="order.quantity = $event"
            ></form-row-input>
            <form-row-input
              class="col align-items-center"
              [label]="'purchasePrice'"
              [icon]="'far-money-bill'"
              [replaceComma]="true"
              [model]="order?.purchasePrice"
              (modelChange)="order.purchasePrice = $event"
            ></form-row-input>
            <form-row-select
              class="col-2"
              [label]="'currency'"
              [icon]="'far-money-bill-alt'"
              [options]="currencies"
              [model]="order?.currency?.id"
              (modelChange)="order.currency = { name: '', ratio: 0, id: $event }"
            >
            </form-row-select>
          </div>
          <div class="row">
            <form-row-textarea
              class="col-12"
              [label]="'note'"
              [icon]="'far-list'"
              [model]="order?.note"
              [rows]="4"
              (modelChange)="order.note = $event"
            ></form-row-textarea>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container class="modal-footer-content">
    <button type="submit" class="btn-first" (click)="addProduct()" [title]="'add'">
      <i class="far fa-pencil"></i>
      {{ 'add' | translate }}
    </button>
  </ng-container>
</modal-container>
