import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core'
import { generateUuidV4 } from 'depoto-core/src/utils'

@Component({
  selector: 'form-row-select-multiple',
  templateUrl: 'form-row-select-multiple.component.html',
  styleUrls: ['form-row-select-multiple.component.scss'],
})
export class FormRowSelectMultipleComponent implements OnChanges {
  @ViewChild('formRow') formRow: ElementRef
  @Input() label: string
  @Input() tooltipLabel: string
  @Input() tooltipIcon: string
  @Input() isTags: boolean
  @Input() options: any[] = []
  @Input() optionKey = 'id' // in case of options object, specify prop
  @Input() optionKeyText = 'name' // in case of options object, specify prop to show on option textValue
  @Input() inputDisabled = false
  @Input() isMultiple = true
  @Input() classes: string[] = []
  @Input() trackCategory = 'event-click-select-multiple'
  @Input() trackAction: string
  @Input() selectedValues: any[] = []
  @Input() dataCyAttribute: string
  @Input() noMarginBottom = false
  @Output() selectedValuesChange: EventEmitter<any> = new EventEmitter()
  @Output() valueAdded: EventEmitter<any> = new EventEmitter()
  @Output() valueRemoved: EventEmitter<any> = new EventEmitter()
  elementId: string
  areOptionsLoaded: boolean

  constructor() {
    this.elementId = generateUuidV4()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.trackAction) {
      this.trackAction = this.label
    }
    const isIterable = this.selectedValues && typeof this.selectedValues.map === 'function'
    if (changes.options?.currentValue?.length > 0) {
      this.selectedValues = isIterable
        ? this.selectedValues?.map(value => {
            const valueWithDetails = changes.options.currentValue.find(option => option.id === value)
            return valueWithDetails ?? value
          }) || []
        : []
    } else if (this.options?.length > 0) {
      this.selectedValues = isIterable
        ? this.selectedValues?.map(value => {
            const valueWithDetails = this.options.find(option => option.id === value)
            return valueWithDetails ?? value
          }) || []
        : []
    }
    if (changes.options?.currentValue?.length > 0) {
      this.areOptionsLoaded = true
    }
  }

  onChange() {
    this.selectedValuesChange.emit(this.selectedValues)
  }

  onAdd() {
    this.valueAdded.emit(this.selectedValues)
  }

  onRemove() {
    this.valueRemoved.emit(this.selectedValues)
  }

  trackById(item: any): number {
    return item.id
  }

  compareWith(optionValue, selectedValue) {
    if (selectedValue === 'object' && selectedValue !== null && this.optionKey !== null) {
      return selectedValue[this.optionKey] === optionValue
    } else {
      return selectedValue === optionValue
    }
  }
}
