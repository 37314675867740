import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { Currency, File, OrderItem, Vat } from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'

@Component({
  selector: 'modal-order-item',
  templateUrl: './modal-order-item.component.html',
  styleUrls: ['./modal-order-item.component.scss'],
})
export class ModalOrderItemComponent {
  @ViewChild('modal') childModal: ModalContainerComponent
  @Input() canCreateOrEdit = false
  @Input() canEditQuantity = true
  @Input() currency: Currency
  @Input() vats: Vat[] = []
  @Input() orderItem: OrderItem = new OrderItem({ name: 'Volná položka', quantity: 1, vat: { id: 1 } })
  @Output() createdOrderItem: EventEmitter<OrderItem> = new EventEmitter()
  @Input() isCreating = false
  @Input() canEditPrice = false
  @Input() dataCyAttribute: string
  @Output() updatedOrderItem: EventEmitter<OrderItem> = new EventEmitter()

  public showChildModal() {
    if (this.isCreating) {
      this.orderItem = new OrderItem({
        name: 'Volná položka',
        quantity: 1,
        vat: this.vats[0],
        purchaseCurrency: this.currency.id,
      })
    }
    this.childModal.showChildModal()
  }

  private hideChildModal() {
    this.childModal.hideChildModal()
  }

  setItemType(itemType: 'product' | 'shipping' | 'payment') {
    switch (itemType) {
      case 'product':
        this.orderItem.name = 'Volná položka'
        this.orderItem.type = 'product'
        break
      case 'shipping':
        this.orderItem.name = 'Doprava'
        this.orderItem.type = 'shipping'
        break
      case 'payment':
        this.orderItem.name = 'Platba'
        this.orderItem.type = 'payment'
        break
    }
  }

  public compareWithFn(a: Vat, b: Vat) {
    return a && b ? a.id === b.id : a === b
  }

  public createOrderItem() {
    this.createdOrderItem.emit(this.orderItem)
    this.orderItem = new OrderItem()
    this.hideChildModal()
  }

  public updateOrderItem() {
    const priceDiscounted =
      Number(this.orderItem.price) - (Number(this.orderItem.price) / 100) * Number(this.orderItem.sale)
    this.orderItem.priceAll = priceDiscounted * Number(this.orderItem.quantity)
    this.updatedOrderItem.emit(this.orderItem)
    this.hideChildModal()
  }

  getThumbUrl(image: File): string {
    return image.thumbnails.filter(t => t.format === 'w135wp')[0].url
  }
}
