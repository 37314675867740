import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Producer } from 'depoto-core/src/entities'
import { SchemaUtil, historyBack } from '../../utils'
import { NotificationService } from '../../services'
import { CoreService } from '../../services'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-producer',
  templateUrl: 'producer.component.html',
})
export class ProducerComponent implements OnInit, CheckDataChanges {
  producer: Producer
  isCreating = false
  loading = true
  submitted = false

  producerForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, [Validators.required]),
  })

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private core: CoreService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
  }

  async ngOnInit() {
    this.loading = true
    const id = this.route.snapshot.params['id']
    if (isNaN(id)) {
      this.isCreating = true
      this.producer = new Producer()
      this.loading = false
    } else {
      this.producer = await this.core.services.producer.getById(id, SchemaUtil.producer)
      this.fillFormValue()
      this.loading = false
    }
  }

  public fillFormValue() {
    this.producerForm.setValue({ name: this.producer.name })
  }

  public getFormValues() {
    this.producer.name = this.producerForm.value.name
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  goBack(): void {
    historyBack()
  }

  async createProducer() {
    this.submitted = true
    if (this.producerForm.invalid) {
      return
    }
    this.loading = true
    try {
      this.getFormValues()
      await this.core.services.producer.create(this.producer).then(result => {
        this.notificationService.success(this.translateService.instant('alert.producer.created'))
        this.router.navigate(['/producer/', result.id], { replaceUrl: true })
      })
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
    }
  }

  async updateProducer() {
    this.submitted = true
    if (this.producerForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValues()
      this.producer = await this.core.services.producer.update(this.producer).then(response => {
        this.notificationService.success(this.translateService.instant('alert.producer.updated'))
        return response
      })
      this.router.navigate(['/producer/', this.producer.id])
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async deleteProducer() {
    const confirmation = confirm(this.translateService.instant('producer.delete.confirmation', this.producer.name))
    if (confirmation) {
      try {
        await this.core.services.producer.delete(this.producer).then(() => {
          this.notificationService.success(this.translateService.instant('alert.producer.deleted'))
          this.router.navigate(['/producers'], { replaceUrl: true })
        })
      } catch (err) {
        console.error(err)
      }
    }
  }
  get formControls(): { [key: string]: AbstractControl } {
    return this.producerForm.controls
  }
}
