import { Component, Input, Output, EventEmitter, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { generateUuidV4 } from 'depoto-core/src/utils'
import { CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, FormRowBase } from '../form-row-base/form-row-base'

@Component({
  selector: 'form-row-toggle',
  templateUrl: 'form-row-toggle.component.html',
  styleUrls: ['form-row-toggle.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR(FormRowToggleComponent)],
})
export class FormRowToggleComponent extends FormRowBase implements OnInit {
  @ViewChild('formRow') formRow: ElementRef
  @Input() id: string // it uses generateUuidV4 later, let it be as it is in order for labels to be tied to inputs
  @Input() label: string
  @Input() tooltipLabel: string
  @Input() tooltipIcon: string
  @Input() inputDisabled = false
  @Input() cursorPointer = false
  @Input() noMarginBottom = false
  @Input() model: boolean
  @Output() modelChange: EventEmitter<boolean> = new EventEmitter()
  @Input() trackCategory = 'event-click-toggle'
  @Input() trackAction: string
  @Input() classesString: string
  @Input() tooltipPosition: string
  @Input() formControlName: string
  @Input() formGroup: FormGroup
  @Input() dataCyAttribute: string
  constructor() {
    super()
  }

  ngOnInit() {
    if (!this.trackAction) {
      this.trackAction = this.label
    }
    if (!this.id) {
      this.id = generateUuidV4()
    }
    this.initFormControl()
  }

  onNgModelChange(value: boolean): void {
    this.onChange({ type: 'toggle', target: { value } })
    this.modelChange.emit(value)
  }
}
