<modal-container #modal [headerIconClass]="'far-file-export'" [headerTitle]="headerTitle" [isLargeModal]="true">
  <ng-container class="modal-button-show">
    <btn
      [btnType]="'button'"
      [icon]="'far-upload'"
      (click)="showChildModal()"
      [label]="'export.create'"
      [title]="'export.create' | translate"
      [classes]="['btn-third']"
      [dataCyAttribute]="'modal-export-create'">
    </btn>
  </ng-container>

  <ng-container class="modal-body-content">
    <div class="card">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
      <div class="product-search-form mb-2">
        <form-row-select [label]="'type'" [options]="types" [(model)]="type" [dataCyAttribute]="'export-types-select'"></form-row-select>
      </div>
      <div class="product-search-form mb-2">
        <form-row-textarea [label]="'note'" [(model)]="note" [dataCyAttribute]="'export-note'"></form-row-textarea>
      </div>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content">
    <btn
      [label]="'create'"
      [title]="'create' | translate"
      [icon]="'far-file-export'"
      [classes]="['btn-first']"
      [btnDisabled]="loading || !type"
      (onEvent)="create()"
      [dataCyAttribute]="'export-create'"
    ></btn>
  </ng-container>
</modal-container>
