import { Component, EventEmitter, Output, ViewChild } from '@angular/core'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { PurchaseOrder } from 'depoto-core/src/entities'
import { CoreService } from '../../../services'
import { TranslateService } from '@ngx-translate/core'
import * as XLSX from 'xlsx'
import { asyncForEach, SchemaUtil, UI_langs, fileTemplatesURL } from '../../../utils'

@Component({
  selector: 'modal-purchase-orders-import',
  templateUrl: './modal-purchase-orders-import.component.html',
  styleUrl: './modal-purchase-orders-import.component.scss',
})
export class ModalPurchaseOrdersImportComponent {
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Output() public onPurchaseOrdersImported: EventEmitter<Array<PurchaseOrder>> = new EventEmitter()
  loadedFileEv: any
  purchaseOrders: PurchaseOrder[] = []
  fileTypes: string[] = ['depoto-purchase-orders.xlsx']
  selectedFileType = 'depoto-purchase-orders.xlsx'
  isImportFileLoaded = false
  templatesURL = fileTemplatesURL
  progress = 0
  loading = false
  defaultColumnTypesCS = {
    supplierReference: 'Reference dodavatele',
    ean: 'EAN produktu',
    code: 'SKU produktu',
    productName: 'Název produktu',
    quantity: 'Množství',
    purchasePriceWithoutVat: 'Nákupní cena bez DPH',
    currency: 'Měna',
    depot: 'Sklad', // Název skladu
    supplier: 'Dodavatel',
    reference: 'Reference',
    dateEstimatedArrival: 'Datum očekávaného příjmu',
    note: 'Poznámka',
    privateNote: 'Privátní poznámka',
  }
  defaultColumnTypesEN = {
    supplierReference: 'Supplier reference',
    ean: 'Product EAN',
    code: 'Product SKU',
    productName: 'Product name',
    quantity: 'Quantity',
    purchasePriceWithoutVat: 'Purchase price without VAT',
    currency: 'Currency',
    depot: 'Depot name',
    supplier: 'Supplier name',
    reference: 'Reference',
    dateEstimatedArrival: 'Date of expected receipt',
    note: 'Note',
    privateNote: 'Private note',
  }
  columnTypes: { id: string; name: string }[] = []
  importMap: any = {
    supplierReference: -1,
    ean: -1,
    code: -1,
    productName: -1,
    quantity: -1,
    purchasePriceWithoutVat: -1,
    currency: -1,
    depot: -1,
    supplier: -1,
    reference: -1,
    dateEstimatedArrival: -1,
    note: -1,
    privateNote: -1,
  }
  isImportMapSet = false
  isImportMapUpdating = false
  uintArray: Uint8Array | null = null
  errors: { id: string; message: string }[] = []
  constructor(
    private core: CoreService,
    private translateService: TranslateService
  ) {}

  showChildModal(): void {
    this.loadedFileEv = null
    this.isImportFileLoaded = false
    this.purchaseOrders = []
    this.errors = []
    this.isImportMapSet = false
    this.isImportMapUpdating = false
    this.childModal.showChildModal()
  }

  hideChildModal(): void {
    this.childModal.hideChildModal()
  }

  loadFile(event): void {
    this.loadedFileEv = event
  }

  async handleImportFile(): Promise<PurchaseOrder[]> {
    if (!this.loadedFileEv) {
      alert(this.translateService.instant('modal-purchase-orders-import.no-file'))
      return
    }
    this.loading = true
    let arrayBuff = null
    const isXls = this.loadedFileEv.target.value.endsWith('.xlsx')
    if (!isXls || this.selectedFileType != 'depoto-purchase-orders.xlsx') {
      alert(this.translateService.instant('modal-orders-import.wrong-format'))
      this.loading = false
      return
    }
    try {
      arrayBuff = await this.getDataFromImportedFile(this.loadedFileEv)
      this.uintArray = new Uint8Array(arrayBuff)
      if (isXls) {
        this.purchaseOrders = await this.parseXlsDepoto(this.uintArray)
      }
    } catch (err) {
      console.warn(err)
      alert(
        typeof err === 'string'
          ? err
          : err.message
            ? err.message === "Cannot read property 'childNodes' of undefined"
              ? this.translateService.instant('modal-purchase-orders-import.import.error')
              : err.message
            : JSON.stringify(err)
      )
    } finally {
      this.loading = false
    }
    if (this.purchaseOrders.length > 0 && !this.purchaseOrders[0].supplierReference) {
      alert(this.translateService.instant('modal-purchase-orders-import.wrong-format'))
    }
  }

  getDataFromImportedFile(inputEvent: Event): Promise<string | any> {
    const target = inputEvent.target as HTMLInputElement
    return new Promise((resolve, reject) => {
      const file = target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = evt => {
          resolve(evt.target.result)
        }
        reader.onerror = () => {
          reject(this.translateService.instant('file-processing-error', { name: file.name }))
        }
      } else {
        reject(this.translateService.instant('no-file'))
      }
    })
  }

  async setImportMapAndContinue() {
    this.loading = true
    this.isImportMapUpdating = false
    this.isImportMapSet = true
    this.purchaseOrders = await this.parseXlsDepoto(this.uintArray)
    this.loading = false
    this.isImportFileLoaded = true
  }

  async import() {
    const results = []
    this.progress = 0
    this.loading = true
    let x = 1
    for (const o of this.purchaseOrders) {
      try {
        const res = await this.core.services.purchase.create(o)
        results.push(res)
      } catch (e) {
        this.errors.push(e.message)
        console.error(e)
      }
      this.progress = x / (this.purchaseOrders.length / 100)
      x++
    }
    this.loading = false
    if (this.errors.length === 0) {
      this.loadedFileEv = null
      this.purchaseOrders = []
      this.isImportMapSet = false
      this.isImportMapUpdating = false
      this.hideChildModal()
    }
    this.onPurchaseOrdersImported.emit(this.purchaseOrders)
    this.isImportFileLoaded = false
  }

  protected async parseXlsDepoto(typedArray: Uint8Array): Promise<Array<PurchaseOrder>> {
    const wb: XLSX.WorkBook = XLSX.read(typedArray, { cellDates: true })
    const purchaseOrders: any = {}
    this.purchaseOrders = []
    this.progress = 0
    await asyncForEach(wb.SheetNames, async sheetName => {
      const ws: XLSX.WorkSheet = wb.Sheets[sheetName]
      const data: Array<Array<any>> | any = XLSX.utils.sheet_to_json(ws, { header: 1 })
      const castToStr = val =>
        typeof val === 'string' ? val : typeof val === 'number' ? val.toFixed(0) : val ? val : ''
      await asyncForEach(data, async (n, i) => {
        if (n?.length > 0) {
          if (i === 0 && !this.isImportMapSet) {
            const headings = n.map(c => castToStr(c))
            this.columnTypes = [{ id: 'none', name: ' ' }, ...headings.map((h, index) => ({ id: index, name: h }))]
            const defaultColumnsByLang = UI_langs.map(lang => this['defaultColumnTypes' + lang])
            let colindex = -1
            defaultColumnsByLang.some(columns => {
              const importMap = {}
              for (const colHeadingKey of Object.keys(columns)) {
                colindex = headings.indexOf(columns[colHeadingKey])
                if (colindex >= 0) {
                  importMap[colHeadingKey] = colindex
                } else {
                  break
                }
                this.importMap = importMap
              }
              return colindex >= 0
            })
            this.isImportMapUpdating = true
            return
          } else if (i > 0 && this.isImportMapSet) {
            const importMap = { ...this.importMap }
            const rowNumber = `${n[importMap.supplierReference]}`

            if (!purchaseOrders[rowNumber]) {
              purchaseOrders[rowNumber] = new PurchaseOrder()
            }

            if (!this.checkDepotIsValid(n[importMap.depot])) {
              this.errors.push({ id: '', message: 'modal-purchase-orders-import.missing-depot' })
              return
            } else if (!this.checkSupplierIsValid(n[importMap.supplier])) {
              this.errors.push({ id: '', message: 'modal-purchase-orders-import.missing-supplier' })
              return
            }

            if (this.core.lists.depots.find(d => d.name === `${n[importMap.depot]}`))
              if (!purchaseOrders[rowNumber].supplierReference) {
                purchaseOrders[rowNumber].supplierReference = n[importMap.supplierReference]
                  ? `${n[importMap.supplierReference]}`
                  : ''
              }
            if (!purchaseOrders[rowNumber].externalReference) {
              purchaseOrders[rowNumber].externalReference = n[importMap.reference] ? `${n[importMap.reference]}` : ''
            }
            if (!purchaseOrders[rowNumber].dateEstimatedArrival) {
              purchaseOrders[rowNumber].dateEstimatedArrival = n[importMap.dateEstimatedArrival]
                ? `${n[importMap.dateEstimatedArrival]}`
                : ''
            }
            if (!purchaseOrders[rowNumber].depot) {
              purchaseOrders[rowNumber].depot = n[importMap.depot]
                ? this.core.lists.depots.find(d => d.name === `${n[importMap.depot]}`)?.id
                : undefined
            }
            if (!purchaseOrders[rowNumber].supplier?.id) {
              purchaseOrders[rowNumber].supplier = n[importMap.supplier]
                ? this.core.lists.suppliers.find(s => s.name === `${n[importMap.supplier]}`)?.id
                : undefined
            }
            if (!purchaseOrders[rowNumber].note) {
              purchaseOrders[rowNumber].note = n[importMap.note] ? `${n[importMap.note]}` : ''
            }
            if (!purchaseOrders[rowNumber].privateNote) {
              purchaseOrders[rowNumber].privateNote = n[importMap.privateNote] ? `${n[importMap.privateNote]}` : ''
            }
            if (!purchaseOrders[rowNumber].createdBy) {
              purchaseOrders[rowNumber].created = new Date()
              purchaseOrders[rowNumber].createdBy = this.core.services.user.user
            }

            const products =
              `${n[importMap.ean]}` !== 'undefined' &&
              `${n[importMap.ean]}` !== 'null' &&
              `${n[importMap.ean]}` !== 'none' &&
              `${n[importMap.ean]}` !== undefined &&
              `${n[importMap.ean]}` !== null
                ? await this.core.services.product.getList(
                    { filters: { ean: `${n[importMap.ean]}` } },
                    SchemaUtil.productImportSearch
                  ) // todo: ean->code->fulltext
                : `${n[importMap.code]}` !== 'undefined' &&
                    `${n[importMap.code]}` !== 'null' &&
                    `${n[importMap.code]}` !== 'none' &&
                    `${n[importMap.code]}` !== undefined &&
                    `${n[importMap.code]}` !== null
                  ? await this.core.services.product.getList(
                      { filters: { code: `${n[importMap.code]}` } },
                      SchemaUtil.productImportSearch
                    )
                  : `${n[importMap.productName]}` !== 'undefined' &&
                      `${n[importMap.productName]}` !== 'null' &&
                      `${n[importMap.productName]}` !== 'none' &&
                      `${n[importMap.productName]}` !== undefined &&
                      `${n[importMap.productName]}` !== null
                    ? await this.core.services.product.getList(
                        { filters: { fulltext: `${n[importMap.productName]}` } },
                        SchemaUtil.productImportSearch
                      )
                    : []
            if (products?.items?.length > 0) {
              const product = products && products.items && products.items.length > 0 ? products.items[0] : null
              const currency = this.core.lists.currencies.find(d => d.id === castToStr(n[importMap.currency]))
              const oItem = {
                quantity: Number(n[importMap.quantity]),
                product: product.id,
                purchasePrice: Number(n[importMap.purchasePriceWithoutVat]),
                currency: currency.id,
              }
              purchaseOrders[rowNumber].items.push(oItem)
            } else {
              this.errors.push({ id: '', message: 'modal-purchase-orders-import.create-error' })
            }
          }
        }
        this.progress = (i + 1) / (data.length / 100)
      })
    })
    Object.keys(purchaseOrders).forEach(k => {
      this.purchaseOrders.push(purchaseOrders[k])
    })
    return this.purchaseOrders
  }

  checkSupplierIsValid(supplier: string): boolean {
    return this.core.lists.suppliers.some(s => s.name === supplier)
  }

  checkDepotIsValid(depot: string): boolean {
    return this.core.lists.depots.some(d => d.name === depot)
  }
}
