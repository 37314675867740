import { OrderItem } from 'depoto-core/src/entities'
import { isEqual as areDeeplyEqual } from 'lodash-es'
import { baseMimeTypes } from './constants.util'

export function sortOrderItems(items): Array<OrderItem> {
  return [
    ...items.filter(item => item.type !== 'shipping' && item.type !== 'payment'),
    ...items.filter(item => item.type === 'payment'),
    ...items.filter(item => item.type === 'shipping'),
  ]
}
export function getObjectDiff(obj1: object, obj2: object) {
  if (!obj1 || !obj2) {
    return
  }
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key)
    } else if (areDeeplyEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, Object.keys(obj2))
}

export function detectUTF8(uint8Array) {
  try {
    // An attempt to decode as UTF-8
    new TextDecoder('UTF-8', { fatal: true }).decode(uint8Array)
    return 'UTF-8'
  } catch (e) {
    return 'non-UTF-8'
  }
}

export function unknownMimeType(fileMimeType?: string) {
  return !baseMimeTypes.some(type => fileMimeType?.includes(type))
}

export function getFulfilled<T>(responses: Array<PromiseFulfilledResult<T> | PromiseRejectedResult>) {
  return responses.filter(r => r.status == 'fulfilled').map(r => (r as PromiseFulfilledResult<T>).value)
}

export function getRejected<T>(responses: Array<PromiseFulfilledResult<T> | PromiseRejectedResult>) {
  return responses.filter(r => r.status == 'rejected').map(r => (r as PromiseRejectedResult).reason)
}

export function isFormInvalid() {
  // todo. find another, angular, way - querySelectorAll gets all inputs from pages, even when they should be destroyed.
  const inputs = [...document.querySelectorAll('.filter-form input[pattern]')]
  // if(inputs.length) {
  //   console.log(inputs.filter(x => !(x as HTMLInputElement)?.validity?.valid).map(x => x['dataset'].cy))
  // }
  return inputs.length && inputs.some(x => !(x as HTMLInputElement)?.validity?.valid)
}

export function historyBack(): void {
  window.history.back()
}

export function isObjectEmpty(obj: object): boolean {
  return obj === null || !Object.keys(obj)?.length
}
