<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<li *ngIf="type === categoryTreeTypeEnum.listCRUD && isShow">
  <div class="border fix-double-border p-2">
    <btn
      [btnType]="'button'"
      [classes]="['btn-first', 'me-3', 'ms-1']"
      (onEvent)="subcategoriesShow()"
      [icon]="'far-plus'"
      [btnDisabled]="!category.hasChildren"
      data-cy="subcategoriesShow-btn"
    ></btn>
    <span>{{ category.name }}</span>
    <btn
      [btnType]="'button'"
      [classes]="['btn-first', 'float-right']"
      [routerLink]="['/category', category.id]"
      [icon]="'far-pencil'"
      [title]="'edit' | translate"
      data-cy="edit-category"
    ></btn>
    <btn
      [btnType]="'button'"
      [classes]="['btn-first-red', 'float-right', 'me-2']"
      (onEvent)="categoryDelete()"
      [icon]="'far-trash-alt'"
      [title]="'delete' | translate"
      [dataCyAttribute]="'delete-row-category'"
    ></btn>
  </div>
  <ul
    class="list-unstyled ps-3"
    dndDropzone
    dndEffectAllowed="move"
    (dndDrop)="onDrop($event, subcategories, category)"
  >
    <li dndPlaceholderRef class="categoriesListDndPlaceholder"></li>
    <ng-container *ngIf="subcategories && subcategories.length > 0 && showSubcategories">
      <category-tree-row
        *ngFor="let subcategory of subcategories"
        [category]="subcategory"
        [type]="categoryTreeTypeEnum.listCRUD"
        [dndDraggable]="subcategory"
        dndEffectAllowed="move"
        (dndMoved)="onDragMove(subcategory, subcategories)"
      ></category-tree-row>
    </ng-container>
  </ul>
</li>

<li *ngIf="type === categoryTreeTypeEnum.radioParentCategory">
  <div class="d-flex align-items-baseline">
    <i
      class="fa fa-times position-relative cursor-pointer category-unset"
      [ngClass]="{
        invisible: !isRadioSelected(radioInput),
        'category-unset-with-subcategories': category.hasChildren,
        'category-unset-without-subcategories': !category.hasChildren
      }"
      (click)="unsetCategory(radioInput)"
      data-cy="unset-parent-category"
    ></i>
    <i
      #arrowIconRadioParentCategory
      class="fa fa-caret-down me-2 cursor-pointer category-unset"
      [ngClass]="{ invisible: !category.hasChildren }"
      (click)="subcategoriesShow()"
      [id]="category.id"
    ></i>
    <input
      #radioInput
      type="radio"
      [ngModel]="checkedCategory"
      [name]="'parentCategory'"
      [value]="category.id"
      (ngModelChange)="onNgModelChange(category)"
      [id]="'parent_category_' + category.id"
      data-cy="category-radioInput"
    />
    <label class="ms-2" [for]="'parent_category_' + category.id">{{ category.name }}</label>
  </div>
  <ul *ngIf="subcategories.length > 0 && showSubcategories" class="list-unstyled ms-3">
    <category-tree-row
      *ngFor="let subcategory of subcategories"
      [category]="subcategory"
      [(editedCategory)]="editedCategory"
      [type]="categoryTreeTypeEnum.radioParentCategory"
    ></category-tree-row>
  </ul>
</li>

<li *ngIf="type === categoryTreeTypeEnum.radioProductMainCategory">
  <div class="d-flex align-items-baseline">
    <i
      class="fa fa-times position-relative cursor-pointer category-unset"
      [ngClass]="{
        invisible: !isRadioSelected(radioInput),
        'category-unset-with-subcategories': category.hasChildren,
        'category-unset-without-subcategories': !category.hasChildren
      }"
      (click)="unsetCategory(radioInput)"
    ></i>
    <i
      #arrowIconRadioProductMainCategory
      class="fa fa-caret-down me-2 cursor-pointer"
      [ngClass]="{ invisible: !category.hasChildren }"
      (click)="subcategoriesShow()"
      [id]="category.id"
    ></i>
    <input
      #radioInput
      type="radio"
      [ngModel]="checkedCategory"
      [name]="'productMainCategory'"
      [value]="category.id"
      (ngModelChange)="onNgModelChange(category)"
      [id]="'main_category_' + category.id"
    />
    <label class="ms-2" [for]="'main_category_' + category.id">{{ category.name }}</label>
  </div>
  <ul *ngIf="subcategories?.length > 0 && showSubcategories" class="list-unstyled ms-3">
    <category-tree-row
      *ngFor="let subcategory of subcategories"
      [category]="subcategory"
      [(selectedCategories)]="selectedCategories"
      (mainCategoryChange)="onNgModelChange($event)"
      [mainCategory]="mainCategory"
      [type]="categoryTreeTypeEnum.radioProductMainCategory"
    ></category-tree-row>
  </ul>
</li>

<li *ngIf="type === categoryTreeTypeEnum.checkboxProductCategories" class="category-tree-row-bulk-edit">
  <div class="d-flex align-items-baseline">
    <i
      #arrowIconCheckboxProductCategories
      class="fa fa-caret-down me-2 cursor-pointer"
      [ngClass]="{ invisible: !category.hasChildren }"
      (click)="subcategoriesShow()"
      [id]="category.id"
    ></i>
    <form-row-checkbox
      [isInBulkEdits]="isInBulkEdits"
      [label]="category?.name"
      [ngModel]="checkedCategory"
      [name]="'productCategories'"
      (ngModelChange)="onNgModelChangeCheckbox($event, category)"
      [id]="'product_categories_' + category.id"
      [dataCySelector]="'category-checkbox'"
    >
    </form-row-checkbox>
    <!--    <label class="ms-2 category-tree-row-bulk-edit-label" [for]="'product_categories_' + category.id">{{ category.name }}</label>-->
  </div>
  <ul *ngIf="subcategories?.length > 0 && showSubcategories" class="list-unstyled ms-3">
    <category-tree-row
      *ngFor="let subcategory of subcategories"
      [category]="subcategory"
      [selectedCategories]="selectedCategories"
      (selectedCategoriesChange)="selectedCategoriesChanged($event)"
      [type]="categoryTreeTypeEnum.checkboxProductCategories"
    ></category-tree-row>
  </ul>
</li>
