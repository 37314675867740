import { Injectable } from '@angular/core'
import {
  Address,
  Carrier,
  Checkout,
  Currency,
  Depot,
  Order,
  Payment,
  PaymentItem,
  Supplier,
  Tag,
  Vat,
} from 'depoto-core/src/entities'
import { FormControl, FormGroup } from '@angular/forms'
import { CoreService } from './core.service'
import { SchemaUtil } from '../utils'
import { BaseDetail } from '../pages/_base-detail/base-detail'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root',
})
export class OrderDetailService extends BaseDetail {
  public order: Order
  public checkouts: Checkout[] = []
  carriers: Carrier[] = []
  currencies: Currency[] = []
  depots: Depot[] = []
  payments: Payment[] = []
  suppliers: Supplier[] = []
  tags: Tag[] = []
  vats: Vat[] = []
  newCurrency: Currency

  public isCreating: boolean
  public loading = true
  public submitted: boolean
  isShippingAddressSameAsInvoice = true

  canEditPrice = false
  canCreateOrEdit = false
  canCreateOrEditNotes = false

  isEditedFiles = false
  isEditedAddress = false
  isEditedTags = false

  updateProcessStatus: string

  activeLink = ''

  orderForm = new FormGroup({
    billNumber: new FormControl({ value: 0, disabled: false }, []),
    vs: new FormControl({ value: 0, disabled: false }, []),
    reservationNumber: new FormControl({ value: 0, disabled: false }, []),
    rounding: new FormControl({ value: 0, disabled: false }, []),
    externalId: new FormControl({ value: '', disabled: false }, []),
  })

  constructor(
    public core: CoreService,
    public route: ActivatedRoute,
    public router: Router,
    public translateService: TranslateService
  ) {
    super(route, router, core, translateService)
  }
  getCoreEntityLists() {
    this.carriers = this.core.lists.carriersFromCompanyCarriers
    this.currencies = this.core.lists.currencies
    this.checkouts = this.core.lists.checkouts
    this.depots = this.core.lists.depots
    this.payments = this.core.lists.payments
    this.suppliers = this.core.lists.suppliers
    this.tags = this.core.lists.tags
    this.vats = this.core.lists.vats
  }
  public fillFormValue() {
    this.orderForm.setValue({
      reservationNumber: this.order.reservationNumber || 0,
      billNumber: this.order.billNumber || 0,
      vs: this.order.vs || 0,
      rounding: this.order.rounding || 0,
      externalId: this.order.externalId || '',
    })
  }

  public getFormValue() {
    this.order.reservationNumber = this.orderForm.value.reservationNumber
    this.order.billNumber = this.orderForm.value.billNumber
    this.order.vs = this.orderForm.value.vs
    this.order.rounding = this.orderForm.value.rounding
    this.order.externalId = this.orderForm.value.externalId
    this.order.currency = this.newCurrency
  }

  public async refreshData(id?: number) {
    this.loading = true
    this.getCoreEntityLists()
    if (this.isCreating) {
      this.order.checkout = this.checkouts && this.checkouts.length === 1 ? this.checkouts[0] : new Checkout()
      if (this.core.services.user.user?.checkouts?.length) {
        this.order.checkout = this.core.services.user.user.checkouts[0]
      }
      this.order.carrier = this.carriers?.length === 1 ? this.carriers[0] : new Carrier()
      this.order.currency = new Currency(this.currencies.find(currency => currency.id.toUpperCase() === 'CZK'))
      this.order.paymentItems =
        this.payments?.length === 1 ? [new PaymentItem({ payment: this.payments[0], amount: 0 })] : [new PaymentItem()]
      this.fillFormValue()
    } else {
      try {
        this.order = new Order(await this.core.services.order.getById(id, SchemaUtil.order))
      } catch (e) {
        console.warn(e)
        if (e && typeof e.includes === 'function' && e.includes('Error 403: Forbidden.')) {
          return this.onLoadError(403)
        }
        return this.onLoadError(404)
      }
      if (!this.order?.id) {
        return this.onLoadError(404)
      }
      if (!this.order.invoiceAddress) {
        this.order.invoiceAddress = new Address()
      }
      if (!this.order.shippingAddress) {
        this.order.shippingAddress = new Address()
      }
      this.canCreateOrEdit =
        this.core.services.user.hasRole('ROLE_ORDER_UPDATE') ||
        this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
        this.core.services.user.hasRole('ROLE_ADMIN')

      this.canCreateOrEditNotes = this.canCreateOrEdit

      // console.log('isEditable=', this.order.isEditable, '; roles are Ok=', this.canCreateOrEdit)

      this.isShippingAddressSameAsInvoice = this.order?.invoiceAddress?.id === this.order?.shippingAddress.id
      this.sortOrderItems()
      this.fillFormValue()
    }
    this.loading = false
  }

  sortOrderItems() {
    this.order.items = [
      ...this.order.items.filter(item => item.type !== 'shipping' && item.type !== 'payment'),
      ...this.order.items.filter(item => item.type === 'payment'),
      ...this.order.items.filter(item => item.type === 'shipping'),
    ]
  }
}
