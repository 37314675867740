<form>
  <div class="card">
    <div class="row">
      <div class="col">
        <h3 class="card-title mb-0">
          {{ 'note' | translate }}
        </h3>
      </div>
    </div>

    <div class="row mt-2" *ngIf="canCreateOrEditNotes">
      <div class="col-12">
        <form-row-textarea
          [label]=""
          [(model)]="order.note"
          [rows]="3"
          [dataCyAttribute]="'note-standard-editable'"
        ></form-row-textarea>
      </div>

      <div class="col-12">
        <form-row-textarea
          [label]="'note.private'"
          [(model)]="order.privateNote"
          [rows]="3"
          [dataCyAttribute]="'note-private-editable'"
        ></form-row-textarea>
      </div>
    </div>

    <div class="row mt-2" *ngIf="!canCreateOrEditNotes">
      <div class="col-12">
        <form-row-textarea
          [inputDisabled]="true"
          [label]=""
          [(model)]="order.note"
          [rows]="3"
          [dataCyAttribute]="'note-standard'"
        ></form-row-textarea>
      </div>

      <div class="col-12">
        <form-row-textarea
          [inputDisabled]="true"
          [label]="'note.private'"
          [(model)]="order.privateNote"
          [rows]="2"
          [dataCyAttribute]="'note-private'"
        ></form-row-textarea>
      </div>
    </div>
  </div>
</form>

<!--  <div class="row" *ngIf="!canCreateOrEditNotes">-->
<!--    <div class="col-4">-->
<!--     {{order.note && order.note.length > 0 ? ('note.standard' | trans) : ('no.note' | trans)}}-->
<!--    </div>-->
<!--    <div class="col-8" [innerHtml]="order.note | nl2br" *ngIf="order.note && order.note.length > 0">-->
<!--    </div>-->
<!--    <div class="col-4">-->
<!--      {{order.privateNote && order.privateNote.length > 0 ? ('note.private' | trans) : ('no.note.private' | trans)}}-->
<!--    </div>-->
<!--    <div class="col-8" *ngIf="order.privateNote && order.privateNote.length > 0" [innerHtml]="order.privateNote | nl2br">-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="card-header pt-0">-->
<!--    <h3 class="card-title ps-0">{{'notes' | trans}}</h3>-->
<!--  </div>-->
<!--    <div class="card-body p-0" *ngIf="canCreateOrEditNotes">-->
<!--      <div class="row">-->
<!--        <div class="col-3">-->
<!--          <form-row-textarea [label]="'note.standard'" [(value)]="order.note" [rows]="4"></form-row-textarea>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-3">-->
<!--          <form-row-textarea [label]="'note.private'" [(value)]="order.privateNote" [rows]="4"></form-row-textarea>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card-body p-0" *ngIf="!canCreateOrEditNotes">-->
<!--      <div><strong>{{order.note && order.note.length > 0 ? ('note.standard' | trans) : ('no.note' | trans)}}</strong></div>-->
<!--      <div *ngIf="order.note && order.note.length > 0" [innerHtml]="order.note | nl2br"></div>-->
<!--      <div><strong>{{order.privateNote && order.privateNote.length > 0 ? ('note.private' | trans) : ('no.note.private' | trans)}}</strong></div>-->
<!--      <div *ngIf="order.privateNote && order.privateNote.length > 0" [innerHtml]="order.privateNote | nl2br"></div>-->
<!--    </div>-->
