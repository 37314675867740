<div class="card" [ngClass]="{ 'pb-0': order.items.length > 0 }">
  <div class="row" *ngIf="canCreateOrEdit && order.status?.id !== 'bill'">
    <div class="col-12 col-md-3 align-self-center">
      <h3 class="card-title">
        {{ 'products' | translate }}
      </h3>
    </div>
    <div class="col-12 col-md-9">
      <modal-product-choose
        [checkout]="order.checkout"
        [currency]="order.currency"
        [currencyDate]="order.dateCreated"
        (chosenProduct)="addProductToOrder($event)"
        (chosenOrderItem)="addOrderItemToOrder($event)"
        [dataCyAttribute]="'product-choose'"
      ></modal-product-choose>
      <modal-order-item
        [isCreating]="true"
        [vats]="vats"
        [currency]="order.currency"
        [canEditPrice]="canEditPrice"
        [canCreateOrEdit]="canCreateOrEdit"
        (createdOrderItem)="createOrderItem($event)"
        [dataCyAttribute]="'product-add'"
      ></modal-order-item>
      <app-modal-order-items-sale
        *ngIf="canCreateOrEdit"
        [orderItems]="order.items"
        [canEditPrice]="canEditPrice"
        [canCreateOrEdit]="canCreateOrEdit"
        (orderItemsEdited)="orderItemsEdited($event)"
      >
      </app-modal-order-items-sale>
    </div>
  </div>
  <div class="expedition-group" *ngIf="canCreateOrEdit && order.status?.id !== 'bill'">
    <div class="btn-group">
      <label
        class="btn btn-primary"
        [ngModel]="!isShowingClearanceItems"
        (ngModelChange)="isShowingClearanceItems = false"
        btnCheckbox
        tabindex="0"
        role="button"
        data-cy="order-items"
        >{{ 'order.items' | translate }}
      </label>
      <label
        *ngIf="!isCreating"
        class="btn btn-primary ms-2"
        [ngModel]="isShowingClearanceItems"
        (ngModelChange)="showClearanceItems()"
        btnCheckbox
        tabindex="0"
        role="button"
        data-cy="items-for-expedition"
        >{{ 'order.expedition' | translate }}</label
      >
    </div>
  </div>
  <div class="table-responsive" *ngIf="!isShowingClearanceItems">
    <table class="table">
      <loading-spinner [isLoading]="loading"></loading-spinner>
      <thead *ngIf="!loading && order.items.length === 0">
        <tr>
          <th class="text-center card-subheading">{{ 'order.stock.add' | translate }}</th>
        </tr>
      </thead>
      <thead *ngIf="!loading && order.items.length > 0">
        <tr>
          <th class="text-start padding-th-checkbox flex-gap-10 align-items-center">
            <form-row-checkbox
              [title]="'select.all' | translate"
              [isInOrderProducts]="true"
              [model]="areAllItemsChecked"
              (modelChange)="allItemsChecked($event)"
              [dataCySelector]="'check-all-order-items'"
            >
            </form-row-checkbox>
            <span class="mleft-40">{{ 'product.name' | translate }}</span>
          </th>
          <th class="text-end" *ngIf="isSomeSale()">{{ 'bill.sale' | translate }}</th>
          <th class="text-end">{{ 'bill.vatrate' | translate }}</th>
          <th class="white-space-no-wrap text-end">{{ 'bill.price.with.vat' | translate }}</th>
          <th
            class="text-end"
            [ngClass]="
              isCreating || (canCreateOrEdit && order.status?.id === 'reservation')
                ? 'quantity-amount-sum-with-margin'
                : ''
            "
          >
            {{ 'bill.qty' | translate }}
          </th>
          <th *ngIf="isShownTagsColumn()" class="text-center">{{ 'product.tag' | translate }}</th>
          <th class="white-space-no-wrap text-end">{{ 'bill.total.with.vat' | translate }}</th>
          <th *ngIf="canCreateOrEdit"></th>
        </tr>
      </thead>
      <tbody *ngIf="!loading" class="images" data-cy="order-products" [photoGalleryGroup]="{ index: 1 }">
        <tr *ngFor="let oItem of order.items">
          <td *ngIf="oItem.product" class="ps-1">
            <div class="flex-direction-column-gap-5">
              <div class="flex-gap-10 align-items-center">
                <form-row-checkbox
                  [isInOrderProducts]="true"
                  [title]="'select' | translate"
                  [model]="isItemRowChecked(oItem.id)"
                  (modelChange)="itemChecked($event, oItem)"
                >
                </form-row-checkbox>
                <div
                  [photoGallery]="oItem.product.mainImage.url"
                  [photoGalleryCaption]="
                    oItem.product
                      ? oItem.product.fullName +
                        '<br>' +
                        (oItem.product.code ? ('product.code' | translate) + oItem.product.code : '') +
                        '<br>' +
                        (oItem.product.ean ? ' EAN: ' + oItem.product.ean : '')
                      : ''
                  "
                >
                  <div class="images-item">
                    <img
                      class="images-item-image"
                      [src]="getThumbUrl(oItem.product.mainImage)"
                      [alt]="oItem.product.fullName"
                    />
                  </div>
                </div>
                <div>
                  <div class="flex-gap-5">
                    <a class="link-bold" [routerLink]="['/product', oItem.product.id]" data-cy="product-link">{{
                      oItem.name
                    }}</a>
                    <!--                    todo-->
                    <span
                      *ngIf="oItem.quantityUnavailable > 0"
                      class="badge bg-red badge-unavail align-self-center"
                      title="Nedostupné položky/produkty"
                    >
                      {{
                        (oItem.quantityUnavailable | number: '1.0-2' | replaceCommaToDot) +
                          (oItem.product && oItem.product.quantityUnavailable > 0
                            ? '/' + (oItem.product.quantityUnavailable | number: '1.0-2' | replaceCommaToDot)
                            : '')
                      }}
                    </span>
                  </div>
                  <div class="product-details flex-gap-5 flex-wrap">
                    <div *ngIf="oItem.code">
                      {{ 'product.code' | translate }}:<span> {{ oItem.code }}</span>
                    </div>
                    <div *ngIf="oItem.ean">
                      {{ 'product.ean' | translate }}:<span data-cy="ean"> {{ oItem.ean }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngFor="let bc of oItem.product.bundleChildren" class="ms-5">
                <div class="flex-gap-5 align-items-center ps-3 virtual-product">
                  <i class="fas fa-angle-right"></i>
                  <div>
                    <a class="link-bold" [routerLink]="['/product', bc.child.id]" data-cy="bundle-product-link">{{
                      bc.child.name
                    }}</a>
                  </div>
                </div>
                <div class="product-details flex-gap-5 flex-wrap ms-4">
                  <div *ngIf="bc.child.code">
                    {{ 'product.code' | translate }}:<span data-cy="bundle-code">{{ bc.child.code }}</span>
                  </div>
                  <div *ngIf="bc.child.ean">
                    {{ 'product.ean' | translate }}:<span data-cy="bundle-ean">{{ bc.child.ean }}</span>
                  </div>
                  <div>
                    • <span data-cy="bundle-quantity">{{ bc.quantity | number: '1.0-2' | replaceCommaToDot }}x</span>
                  </div>
                </div>
              </div>
              <div class="product-details flex-gap-5 flex-wrap">
                <div *ngIf="oItem.serial && oItem.serial.length">
                  <div [innerHtml]="oItem.serial | nl2br"></div>
                </div>
                <div *ngIf="oItem.note && oItem.note.length">
                  <div [innerHtml]="oItem.note | nl2br"></div>
                </div>
                <div
                  *ngIf="
                    (oItem.expirationDate && oItem.expirationDate.length > 0) || (oItem.batch && oItem.batch.length > 0)
                  "
                >
                  <div class="ps-3">
                    <span *ngIf="oItem.expirationDate && oItem.expirationDate.length > 0">
                      {{ ('expiration' | translate) + ':' }} {{ oItem.expirationDate
                      }}<span
                        *ngIf="
                          (oItem.batch && oItem.batch.length > 0) ||
                          (oItem.position && oItem.position.length > 0 && oItem.position !== '--')
                        "
                        >,</span
                      >
                    </span>
                    <span *ngIf="oItem.batch && oItem.batch.length > 0">
                      {{ ('product.batch' | translate) + ':' }}: {{ oItem.batch
                      }}<span *ngIf="oItem.position && oItem.position.length > 0 && oItem.position !== '--'">,</span>
                    </span>
                    <span *ngIf="oItem.position && oItem.position.length > 0 && oItem.position !== '--'">
                      {{ ('position' | translate) + ':' }}: {{ oItem.position }}
                    </span>
                  </div>
                </div>
                <div *ngIf="!isCreating && (hasExpirationsOnUpdate(oItem) || hasBatchesOnUpdate(oItem))">
                  <div *ngFor="let move of oItem.moves">
                    <div
                      [innerHtml]="
                        hasExpirationsOnUpdate(oItem)
                          ? 'Expirace: ' +
                            getProductNameForExpirationDate(move.productDepotFrom.id, oItem) +
                            ' - ' +
                            move.amount +
                            'x ' +
                            (move.productDepotFrom.expirationDate.substring(0, 10) | date: 'dd.MM.yyyy')
                          : hasBatchesOnUpdate(oItem)
                            ? 'Šarže: ' + move.amount + 'x ' + move.productDepotFrom.batch
                            : null
                      "
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td *ngIf="!oItem.product" class="ps-1 top-align ptop-11">
            <div class="d-flex flex-row align-items-center">
              <form-row-checkbox
                [isInOrderProducts]="true"
                [title]="'select' | translate"
                [model]="isItemRowChecked(oItem.id)"
                (modelChange)="itemChecked($event, oItem)"
              >
              </form-row-checkbox>
              <h3 class="card-subheading mb-0 ms-5">{{ oItem.name }}</h3>
            </div>
          </td>
          <td *ngIf="isSomeSale()" class="text-nowrap text-end top-align ptop-11">
            <div>{{ oItem.sale > 0 ? oItem.sale + '%' : '' }}</div>
            <div class="saleInPrice">
              {{ '(' + (getSaleInPrice(oItem.price, oItem.sale) | formatPrice: order.currency) + ')' }}
            </div>
          </td>
          <td class="text-nowrap text-end top-align ptop-11">{{ oItem.vat ? oItem.vat.percent : 0 }}%</td>
          <td class="text-nowrap text-end top-align ptop-11">
            {{ (oItem ? oItem.price : 0) | formatPrice: order?.currency }}
          </td>
          <td class="text-end">
            <form-row-number-with-controls
              class="float-right"
              *ngIf="isCreating || (canCreateOrEdit && order.status?.id === 'reservation')"
              [noMarginBottom]="true"
              [label]="null"
              [model]="oItem.quantity"
              [minWidthPx]="120"
              [maxWidthPx]="120"
              (modelChange)="changeQuantity($event, oItem)"
            ></form-row-number-with-controls>
            <div *ngIf="!isCreating && order.status?.id === 'bill'">
              {{ oItem.quantity }}
            </div>
          </td>
          <td *ngIf="!isCreating && !canCreateOrEdit" class="text-end top-align ptop-11">
            <div>
              {{ oItem.quantity | number: '1.0-2' | replaceCommaToDot }}
            </div>
          </td>
          <td *ngIf="oItem.product && isShownTagsColumn()" class="container-tags flex top-align">
            <ng-container *ngFor="let tag of oItem.product['tags']">
              <span
                class="tag-span"
                [ngStyle]="{ 'background-color': tag.color ? tag.color : '#0058FF', color: 'white' }"
              >
                {{ tag.name }}
              </span>
            </ng-container>
          </td>
          <td *ngIf="!oItem.product && isShownTagsColumn()" class="container-tags flex top-align ptop-11">&nbsp;</td>
          <td class="text-nowrap text-end top-align ptop-11">
            {{ (oItem ? oItem.priceAll : 0) | formatPrice: order?.currency }}
          </td>
          <td *ngIf="canCreateOrEdit" class="top-align ptop-7">
            <div class="flex-gap-5 flex-row-reverse">
              <btn
                *ngIf="order.status?.id !== 'bill'"
                class="align-self-center"
                [title]="'delete' | translate"
                [icon]="'far-trash-alt'"
                (onEvent)="removeOrderItem(oItem)"
                [classes]="['btn-sm', 'btn-trash']"
                [dataCyAttribute]="'deleteOrderItem-button'"
              ></btn>
              <modal-order-item
                [isCreating]="false"
                [vats]="vats"
                [orderItem]="oItem"
                [currency]="order.currency"
                [canEditPrice]="canEditPrice"
                [canEditQuantity]="order.status?.id !== 'bill'"
                [canCreateOrEdit]="canCreateOrEdit"
                (updatedOrderItem)="updateOrderItem($event)"
                [dataCyAttribute]="'updateOrderItem-button'"
              ></modal-order-item>
            </div>
          </td>
        </tr>
        <tr *ngIf="!loading && order && order.items && order.items.length > 0">
          <td colspan="2" class="td-invisible no-hover">
            <app-modal-move-order-items
              [itemsFromParent]="checkedItems"
              [order]="order"
              [selectedOrder]="selectedOrder"
              (changedQuantity)="changeQuantityFromMoving($event)"
              (addOrderItem)="addOrderItemToOrderFromMoving($event)"
              (removeOrderItem)="removeOrderItemFromMoving($event)"
              (createdOrderItem)="createOrderItemFromMoving($event)"
              (orderReload)="orderReloadEmit()"
              (setCheckboxes)="setDefaultValues()"
            >
            </app-modal-move-order-items>
          </td>
          <td *ngIf="isSomeSale()">&nbsp;</td>
          <td class="card-subheading text-nowrap text-end no-hover">{{ 'bill.total' | translate }}</td>
          <td
            class="card-subheading text-end no-hover"
            [ngClass]="
              isCreating || (canCreateOrEdit && order.status?.id === 'reservation')
                ? 'quantity-amount-sum-with-margin'
                : ''
            "
          >
            {{ getQuantityItemsSum() | number: '1.0-2' | replaceCommaToDot }}
          </td>
          <td *ngIf="isShownTagsColumn()">&nbsp;</td>
          <td class="card-subheading text-nowrap text-end no-hover" data-cy="orderItems-priceSum">
            {{ orderItemsPriceSumHack() | formatPrice: order?.currency }}
          </td>
          <td *ngIf="canCreateOrEdit" class="td-invisible no-hover">&nbsp;</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive" *ngIf="isShowingClearanceItems">
    <table class="table">
      <loading-spinner [isLoading]="loading"></loading-spinner>
      <thead *ngIf="!loading && clearanceItems.length === 0">
        <tr>
          <th colspan="5" class="text-center card-subheading">{{ 'no.items' | translate }}</th>
        </tr>
      </thead>
      <thead *ngIf="!loading && !areClearanceItemsInSync">
        <tr>
          <th colspan="5" class="text-center card-subheading bg-red">{{ 'clearanceItems.not.synced' | translate }}</th>
        </tr>
      </thead>
      <thead *ngIf="!loading && clearanceItems.length > 0">
        <tr>
          <th class="text-start">{{ 'product.name' | translate }}</th>
          <th class="text-start">{{ 'depot' | translate }}</th>
          <th class="text-center">{{ 'depot.position' | translate }}</th>
          <th class="text-end">{{ 'bill.qty' | translate }}</th>
          <th *ngIf="canCreateOrEdit"></th>
        </tr>
      </thead>
      <tbody *ngIf="!loading && clearanceItems.length > 0" class="images" [photoGalleryGroup]="{ index: 2 }">
        <tr *ngFor="let cItem of clearanceItems" data-cy="clearanceItems-listing">
          <td *ngIf="cItem.product" class="ps-1">
            <div class="flex-direction-column-gap-5">
              <div class="flex-gap-10 align-items-center">
                <div
                  [photoGallery]="cItem.product.mainImage.url"
                  [photoGalleryCaption]="
                    cItem.product
                      ? cItem.product.fullName +
                        '<br>' +
                        (cItem.product.code ? ('product.code' | translate) + cItem.product.code : '') +
                        '<br>' +
                        (cItem.product.ean ? ' EAN: ' + cItem.product.ean : '')
                      : ''
                  "
                >
                  <div class="images-item">
                    <img
                      class="images-item-image"
                      [src]="getThumbUrl(cItem.product.mainImage)"
                      [alt]="cItem.product.fullName"
                    />
                  </div>
                </div>
                <div>
                  <div class="flex-gap-5">
                    <a class="link-bold" [routerLink]="['/product', cItem.product.id]">{{ cItem.product.fullName }}</a>
                  </div>
                  <div class="product-details flex-gap-5 flex-wrap">
                    <div *ngIf="cItem.product.code">
                      {{ 'product.code' | translate }}:<span> {{ cItem.product.code }}</span>
                    </div>
                    <div *ngIf="cItem.product.ean">
                      {{ 'product.ean' | translate }}:<span> {{ cItem.product.ean }}</span>
                    </div>
                    <div *ngIf="cItem.batch && cItem.batch.length > 0">
                      {{ 'product.batch' | translate }}:<span> {{ cItem.batch }}</span>
                    </div>
                    <div *ngIf="cItem.expirationDate && cItem.expirationDate.length > 0">
                      {{ 'product.expirationDate' | translate }}:
                      <span *ngIf="!cItem.expirationDate.includes('00:00:00')">{{
                        cItem.expirationDate | fmtDate: 'dd.MM.yyyy HH:mm'
                      }}</span>
                      <span *ngIf="cItem.expirationDate.includes('00:00:00')">{{
                        cItem.expirationDate | fmtDate: 'dd.MM.yyyy'
                      }}</span>
                    </div>
                    <div
                      *ngIf="cItem.position1?.length > 0 || cItem.position2?.length > 0 || cItem.position3?.length > 0"
                    >
                      {{ 'position' | translate }}:<span> {{ cItem.position }}</span>
                    </div>
                    <div *ngIf="cItem.locked">
                      <i class="far fa-lock"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td class="text-nowrap text-end pe-1">
            <div class="d-flex align-items-center text-nowrap">
              {{
                cItem && cItem.productDepots && cItem.productDepots.length > 0
                  ? cItem.productDepots[0].depot.name
                  : 'no depot'
              }}
            </div>
          </td>
          <td class="text-center">
            <div class="process-status-recieved text-nowrap" *ngIf="cItem.position && cItem.position !== '--'">
              {{ cItem.position }}
            </div>
          </td>
          <td class="text-end">
            <div>
              {{ cItem.amount | number: '1.0-2' | replaceCommaToDot }}
            </div>
          </td>
          <td *ngIf="canCreateOrEdit" class="pe-1">
            <div class="flex-gap-5 flex-row-reverse">
              <modal-clearance-item
                [clearanceItem]="cItem"
                [depots]="depots"
                [order]="order"
                (updatedClearanceItem)="onClearanceItemsUpdate()"
              ></modal-clearance-item>
            </div>
          </td>
          <td *ngIf="cItem.batch && cItem.batch.length > 0">&nbsp;</td>
        </tr>
        <tr>
          <td colspan="2" class="td-invisible no-hover"></td>
          <td class="card-subheading text-center text-nowrap no-hover">{{ 'bill.total' | translate }}</td>
          <td class="card-subheading text-end no-hover">
            {{ quantityAmountSum | number: '1.0-2' | replaceCommaToDot }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
