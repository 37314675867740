import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { Consent, Customer, CustomerConsent } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../../services'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'modal-consent',
  templateUrl: './modal-consent.component.html',
  styleUrls: ['./modal-consent.component.scss'],
})
export class ModalConsentComponent implements OnInit {
  @ViewChild('modal') childModal: ModalContainerComponent
  @Input() customer: Customer
  @Output() consentAdded: EventEmitter<boolean> = new EventEmitter()
  public consents: Consent[] = []
  public selectedConsent: number

  constructor(
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.consents = await this.core.services.consent.getList({}, { id: null, name: null }).then(result => result.items)
  }

  public showChildModal(): void {
    this.childModal.showChildModal()
  }

  public async createCustomerConsent() {
    this.childModal.hideChildModal()
    const customerConsent = new CustomerConsent()
    customerConsent.consent = new Consent()
    customerConsent.consent.id = this.selectedConsent
    customerConsent.customer = new Customer()
    customerConsent.customer.id = this.customer.id
    try {
      await this.core.services.consent.createCustomerConsent(customerConsent).then(r => {
        this.notificationService.success(this.translateService.instant('alert.customer.consent.created'))
        this.consentAdded.emit(true)
      })
    } catch (err) {
      console.error(err)
    }
  }
}
