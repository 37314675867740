import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Checkout, Depot, Payment } from 'depoto-core/src/entities'
import { NotificationService } from '../../services'
import { CoreService } from '../../services'
import { SchemaUtil, historyBack } from '../../utils'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-checkout',
  templateUrl: 'checkout.component.html',
})
export class CheckoutComponent implements OnInit, CheckDataChanges {
  userHasRoleForCheckoutEdit = true
  checkout: Checkout
  depots: Depot[]
  depotsForReturns: Depot[] = []
  payments: Payment[]
  eventTypes: string[] = []
  isCreating = false
  setNextBillNumber = false
  setNextReservationNumber = false
  loading = true
  submitted = false
  isInCypressTest = false

  checkoutForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, [Validators.required]),
    amount: new FormControl({ value: 0, disabled: false }, []),
    nextReservationNumber: new FormControl({ value: 1, disabled: false }, []),
    nextBillNumber: new FormControl({ value: 1, disabled: false }, []),
    depots: new FormControl({ value: [], disabled: false }, []),
    returnsDepot: new FormControl({ value: 0, disabled: false }, []),
    eventTypes: new FormControl({ value: [], disabled: false }, []),
    eventUrl: new FormControl({ value: '', disabled: false }, []),
    negativeReservation: new FormControl({ value: false, disabled: false }, []),
    autoPicking: new FormControl({ value: false, disabled: false }, []),
    autoPacking: new FormControl({ value: false, disabled: false }, []),
    autoDispatched: new FormControl({ value: false, disabled: false }, []),
  })

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private core: CoreService
  ) {
    this.userHasRoleForCheckoutEdit = this.core.services.user.user.roles.includes('ROLE_CHECKOUT_UPDATE')
  }

  ngOnInit() {
    const id = this.route.snapshot.params['id']
    if (isNaN(id)) {
      this.isCreating = true
      this.checkout = new Checkout()
      this.refreshData(null)
    } else {
      this.refreshData(id)
    }
  }

  goBack(): void {
    historyBack()
  }

  fillFormValues() {
    this.checkoutForm.setValue({
      name: this.checkout.name,
      amount: this.checkout.amount,
      nextReservationNumber: this.checkout.nextReservationNumber,
      nextBillNumber: this.checkout.nextBillNumber,
      depots: this.checkout.depots,
      returnsDepot: this.checkout.returnsDepot?.id || 0,
      eventTypes: this.checkout.eventTypes,
      eventUrl: this.checkout.eventUrl,
      negativeReservation: this.checkout.negativeReservation,
      autoPicking: this.checkout.autoPicking || false,
      autoPacking: this.checkout.autoPacking || false,
      autoDispatched: this.checkout.autoDispatched || false,
    })
  }

  getFormValue() {
    this.checkout.name = this.checkoutForm.value.name
    this.checkout.amount = this.checkoutForm.value.amount
    this.checkout.nextReservationNumber = this.checkoutForm.value.nextReservationNumber
    this.checkout.nextBillNumber = this.checkoutForm.value.nextBillNumber
    this.checkout.depots = this.checkoutForm.value.depots
    this.checkout.eventTypes = this.checkoutForm.value.eventTypes
    this.checkout.returnsDepot = <any>{
      ...this.checkout.returnsDepot,
      id: Number(this.checkoutForm.value.returnsDepot),
    }
    this.checkout.eventUrl = this.checkoutForm.value.eventUrl
    this.checkout.negativeReservation = this.checkoutForm.value.negativeReservation
    this.checkout.autoPicking = this.checkoutForm.value.autoPicking
    this.checkout.autoPacking = this.checkoutForm.value.autoPacking
    this.checkout.autoDispatched = this.checkoutForm.value.autoDispatched
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  async refreshData(id?: number) {
    this.loading = true
    this.core.services.storage.getSync(this.core.services.storage.keys.auth.company)
    this.eventTypes = this.core.lists.eventTypes
    this.depots = this.core.lists.depots
    if (this.depotsForReturns?.length === 0) {
      this.depotsForReturns = [{ id: 0, name: 'Žádný', unavailablesUrl: '', checkouts: [], users: [] }, ...this.depots]
    }
    this.payments = this.core.lists.payments
    if (id && id > 0) {
      this.checkout = await this.core.services.checkout.getById(id, SchemaUtil.checkout)
      this.fillFormValues()
      this.setNextBillNumber = false
      this.setNextReservationNumber = false
      this.isInCypressTest = this.core.services.user.user.email === 'test.cypress.client@tomatom.cz'
    } else {
      this.setNextBillNumber = true
      this.setNextReservationNumber = true
    }
    this.loading = false
  }

  async createCheckout() {
    this.submitted = true
    if (this.checkoutForm.invalid) {
      return
    }
    this.loading = true
    try {
      this.getFormValue()
      await this.core.services.checkout.create(this.checkout, { id: null }).then(result => {
        this.notificationService.success(this.translateService.instant('alert.checkout.created'))
        this.router.navigate(['/checkout/', result.id], { replaceUrl: true })
      })
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
    }
  }

  async updateCheckout() {
    this.submitted = true
    if (this.checkoutForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValue()
      const result = await this.core.services.checkout.update(this.checkout)
      if (result) {
        if (this.setNextBillNumber) {
          await this.core.services.checkout.setNextBillNumber(this.checkout)
        }
        if (this.setNextReservationNumber) {
          await this.core.services.checkout.setNextReservationNumber(this.checkout)
        }
        this.notificationService.success(this.translateService.instant('alert.checkout.updated')) // todo wtf
        return this.refreshData(result.id)
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async deleteCheckout() {
    try {
      await this.core.services.checkout.delete(this.checkout)
      this.router.navigate(['/checkouts'])
    } catch (err) {
      console.error(err)
    }
  }

  onReturnsDepotSelect(depotId: number) {
    let returnsDepot = null
    depotId = Number(depotId)
    if (depotId > 0) {
      returnsDepot = this.depots.filter(d => d.id === depotId)[0]
    }
    this.checkout.returnsDepot = returnsDepot
  }

  toggleDepot(depot: Depot, event: any) {
    if (event.target.checked) {
      this.checkoutForm.value.depots.push(depot)
    } else {
      this.checkoutForm.value.depots.forEach((d: Depot, i: number) => {
        if (depot.id === d.id && depot.name === d.name) {
          this.checkoutForm.value.depots.splice(i, 1)
        }
      })
    }
  }

  isChosenDepot(depot: Depot): boolean {
    let exists = false
    this.checkoutForm.value.depots.forEach((d: Depot) => {
      if (d.id === depot.id && d.name === depot.name) {
        exists = true
        return
      }
    })
    return exists
  }

  togglePayment(payment: Payment, event: any) {
    if (event.target.checked) {
      this.checkout.payments.push(payment)
    } else {
      this.checkout.payments.forEach((p: Payment, i: number) => {
        if (payment.id === p.id && payment.name === p.name) {
          this.checkout.payments.splice(i, 1)
        }
      })
    }
  }

  isChosenPayment(payment: Payment): boolean {
    let exists = false
    this.checkout.payments.forEach((p: Payment) => {
      if (p.id === payment.id && p.name === payment.name) {
        exists = true
        return
      }
    })
    return exists
  }

  isEventTypeSelected(eventType: string): boolean {
    return this.checkoutForm.value.eventTypes.includes(eventType)
  }

  toggleEventType(eventChecked: boolean, eventType: string): void {
    let eventTypes = this.checkoutForm.get('eventTypes').value
    if (eventChecked) {
      eventTypes.push(eventType)
    } else {
      eventTypes = eventTypes.filter(type => type !== eventType)
    }
    this.checkoutForm.get('eventTypes').setValue(eventTypes)
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.checkoutForm.controls
  }
}
