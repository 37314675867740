<modal-container
  #modal
  [headerIconClass]="isCreatingProducts ? 'far-upload' : 'far-download'"
  [headerTitle]="isInProducts ? 'batch.create' : isCreatingProducts ? 'batch.create.products' : 'batch.import'"
  [isLargeModal]="true"
>
  <ng-container class="modal-button-show">
    <btn
      *ngIf="!hideBtn"
      [label]="isInProducts ? 'batch.create' : isCreatingProducts ? 'batch.create.products' : 'batch.import'"
      [title]="
        isInProducts
          ? 'batch.create'
          : isCreatingProducts
            ? ('batch.create.products' | translate)
            : ('batch.import' | translate)
      "
      [icon]="'far-download'"
      [classes]="['btn-third', 'btn-sm']"
      (onEvent)="showChildModal()"
      [dataCyAttribute]="'modal-batch-import-from-file-open'"
    ></btn>
  </ng-container>
  <ng-container class="modal-body-content">
    <div class="card">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
      <div class="form-row">
        <div class="col-sm-6">
          <form-row-select
            [label]="'batch.file.type'"
            [icon]="'far-list'"
            [(model)]="selectedFileType"
            [options]="fileTypes"
            [dataCyAttribute]="'file-type-select'"
          ></form-row-select>
        </div>
        <div class="col-sm-6">
          <form-row-select
            [label]="'supplier'"
            [icon]="'far-truck'"
            [(model)]="selectedSupplier"
            [options]="suppliers"
            [dataCyAttribute]="'supplier-select'"
          ></form-row-select>
        </div>
        <div class="col-12">
          <form-row-input
            [label]="'file'"
            [icon]="'far-upload'"
            [inputType]="'file'"
            [appendBtnLabel]="'load'"
            [appendBtnClasses]="['btn-first']"
            (valueChangeNative)="loadFile($event)"
            (onAppendBtnEvent)="handleImportFile()"
            [dataCyAttribute]="'file-input'"
          ></form-row-input>
        </div>
        <div class="col-12">
          {{ (isCreatingProducts ? 'create.file.template' : 'import.file.template') | translate }}:
          <a
            class="link"
            href="{{ templatesURL + selectedFileType }}.{{
              selectedFileType.includes('xml') ? 'xml' : selectedFileType === 'depoto-xls' ? 'xlsx' : 'xls'
            }}"
          >
            {{ 'download' | translate }}
          </a>
        </div>
      </div>

      <ng-container *ngIf="!isCreatingProducts">
        <hr *ngIf="importedOrderItems && importedOrderItems.length > 0" />
        <progressbar
          *ngIf="validityCheckProgress !== 100 && validityCheckProgress !== 0"
          [value]="validityCheckProgress"
          type="success"
          [max]="100"
          [striped]="true"
          class="mb-2"
        ></progressbar>
        <ng-container *ngIf="importedOrderItems && importedOrderItems.length > 0">
          <div class="container mb-3">
            <div class="row">
              <div class="col bg-success font-size-2rem d-flex justify-content-between">
                <div>{{ 'valid' | translate }}:</div>
                <div>
                  {{ importedValid.length }}
                </div>
              </div>
              <div
                class="col font-size-2rem d-flex justify-content-between"
                [ngClass]="{
                  'bg-danger': importedInvalid.length > 0,
                  'text-white': importedInvalid.length > 0,
                  'text-secondary': importedInvalid.length === 0
                }"
              >
                <div>{{ 'invalid' | translate }}:</div>
                <div>
                  {{ importedInvalid.length }}
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="importedInvalid.length > 0">
            <h4>{{ 'invalid.items' | translate }}:</h4>
            <div class="table-responsive">
              <table class="table table-hover m-0">
                <thead>
                  <tr>
                    <th scope="col">{{ 'name' | translate }}</th>
                    <th scope="col">{{ 'ean' | translate }}</th>
                    <th scope="col">{{ 'code' | translate }}</th>
                    <th scope="col">{{ 'position' | translate }}</th>
                    <th scope="col">{{ 'quantity' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let invalidItem of importedInvalid">
                    <th scope="row">{{ invalidItem.name }}</th>
                    <td>{{ invalidItem.ean }}</td>
                    <td>{{ invalidItem.code }}</td>
                    <td>{{ invalidItem.position }}</td>
                    <td [style.color]="invalidItem.quantity < 1 ? 'red' : '#333'">{{ invalidItem.quantity }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isCreatingProducts && productsToCreate && productsToCreate.length > 0">
        <h4>{{ 'products' | translate }}:</h4>
        <div class="table-responsive">
          <table class="table table-hover m-0">
            <thead>
              <tr>
                <th scope="col">{{ 'name' | translate }}</th>
                <th scope="col">{{ 'ean' | translate }}</th>
                <th scope="col">{{ 'code' | translate }}</th>
                <th scope="col">{{ 'product.parent' | translate }}</th>
                <th scope="col">{{ 'currency' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let prod of productsToCreate">
                <th scope="row">{{ prod.name }}</th>
                <td>{{ prod.ean }}</td>
                <td>{{ prod.code }}</td>
                <td>{{ prod.parent ? prod.parent.name : '-' }}</td>
                <td>{{ prod.purchaseCurrency ? prod.purchaseCurrency.id : '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content">
    <btn
      *ngIf="!isCreatingProducts"
      [label]="'import.do'"
      [title]="'import.do' | translate"
      [icon]="'far-download'"
      [classes]="['btn-first']"
      [btnDisabled]="!importedValid || (importedValid && importedValid.length === 0)"
      [isLoading]="loading"
      (onEvent)="importBatch()"
    ></btn>
    <btn
      *ngIf="isCreatingProducts"
      [label]="'save'"
      [title]="'save' | translate"
      [icon]="'far-upload'"
      [classes]="['btn-first']"
      [btnDisabled]="!productsToCreate || (productsToCreate && productsToCreate.length === 0)"
      [isLoading]="loading"
      [dataCyAttribute]="'create-batch'"
      (onEvent)="createBatch()"
    ></btn>
  </ng-container>
</modal-container>
