<div class="app-container" *ngIf="vat">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="vatForm" (ngSubmit)="isCreating ? createVat() : updateVat()">
    <page-header
      [icon]="'fa-hand-holding-usd'"
      [title]="isCreating ? 'vat.new' : 'vat'"
      [appendNoTranslationTitlePart]="vat ? vat.name : ''"
    >
      <ng-container class="header-button">
        <btn
          [btnType]="'submit'"
          [label]="'save'"
          [title]="'save' | translate"
          [icon]="'far-check'"
          [btnDisabled]="loading"
          [classes]="['btn-first', 'float-right', isCreating ? '' : 'ms-2']"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-white'"
          [dataCyAttribute]="'create-vat'"
        ></btn>
        <btn
          *ngIf="!isCreating"
          [btnType]="'submit'"
          [icon]="'far-trash-alt'"
          [label]="'delete'"
          [title]="'delete' | translate"
          [classes]="['btn-first-red', 'float-right']"
          (onEvent)="deleteVat()"
          [dataCyAttribute]="'delete-vat'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <section class="content">
      <div class="card">
        <div>
          <div class="row">
            <div class="col-sm-6">
              <form-row-input
                [formGroup]="vatForm"
                formControlName="name"
                [required]="vatForm.get('name').errors !== null && vatForm.get('name').errors.required"
                [requiredCssClass]="submitted && formControls.name.errors"
                [label]="'vat.name'"
                [icon]="'fa-list'"
                [inputType]="'text'"
                [dataCyAttribute]="'name'"
              ></form-row-input>
              <form-row-input
                [formGroup]="vatForm"
                formControlName="percent"
                [required]="vatForm.get('percent').errors !== null && vatForm.get('percent').errors.required"
                [requiredCssClass]="submitted && formControls.percent.errors"
                [label]="'vat.percent'"
                [icon]="'fa-percent'"
                [replaceComma]="true"
                [dataCyAttribute]="'percent'"
              ></form-row-input>
              <form-row-checkbox
                [cursorPointer]="true"
                [formGroup]="vatForm"
                formControlName="default"
                [label]="'vat.isDefault'"
                [dataCySelector]="'vat-default'"
              ></form-row-checkbox>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
