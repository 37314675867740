import { Directive, ElementRef, HostListener } from '@angular/core'

@Directive({ selector: '[replaceComma]' })
export class ReplaceCommaDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keyup') onKeyUp() {
    if (this.el.nativeElement && this.el.nativeElement.value) {
      this.el.nativeElement.value = this.el.nativeElement.value.split(',').join('.')
    }
  }
}
