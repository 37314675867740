import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { Currency, Depot, File, Product, ProductDepot } from 'depoto-core/src/entities'
import { FormRowTextareaComponent } from 'src/app/components/_common/form-row-textarea/form-row-textarea.component'

import { ModalContainerComponent } from 'src/app/components/_common/modal-container/modal-container.component'
import { CoreService } from 'src/app/services'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'modal-stock-operation-card-purchase',
  templateUrl: 'modal-stock-operation-card-purchase.component.html',
  styleUrls: ['modal-stock-operation-card-purchase.component.scss'],
})
export class ModalStockOperationCardPurchaseComponent {
  @ViewChild(ModalContainerComponent) public modal: ModalContainerComponent
  @ViewChild(FormRowTextareaComponent) public inputNoteComponent: FormRowTextareaComponent
  public product: (Product | PurchaseOrderItemPlain) & { product: number }
  @Input() public depots: Depot[] = []
  @Input() productDepot: ProductDepot
  @Input() depotQuantity: number
  @Output() public result: EventEmitter<any> = new EventEmitter()
  currencies: string[] = ['CZK', 'EUR', 'GBP', 'USD']
  loading = false
  keyEventSubscriptions: any = {}
  order: PurchaseOrderItemPlain
  headerTitle: string

  constructor(
    private core: CoreService,
    private translateService: TranslateService
  ) {
    this.initOrder()
  }

  public addProduct(): void {
    if (!this.order.purchasePrice) {
      alert(this.translateService.instant('modal-product-purchase-choose.purchasePrice.error'))
      return
    }
    this.result.emit(this.order)
    this.hideChildModal()
  }

  showProductOnChildModal(item: any): void {
    this.order = { ...item }
    this.showChildModal()
  }

  public showChildModal(): void {
    this.keyEventSubscriptions.keyEsc = this.core.services.keyEvent.onKeyEsc.subscribe().then(() => {
      this.modal.hideChildModal()
    })
    this.modal.showChildModal()
  }

  public hideChildModal(): void {
    if (this.keyEventSubscriptions.keyEsc) {
      this.keyEventSubscriptions.keyEsc.unsubscribe()
      this.keyEventSubscriptions.keyEsc = null
    }
    this.product = null
    this.initOrder()
    this.modal.hideChildModal()
  }

  private initOrder(): void {
    this.order = {
      code: '',
      ean: '',
      fullName: '',
      product: 0,
      note: '',
      purchasePrice: 0,
      quantity: 1,
      currency: null,
    }
  }
}

export type PurchaseOrderItemPlain = {
  purchasePrice: number
  currency: Currency
  quantity: number
  note: string
  product: number
  mainImage?: File
  ean: string
  code: string
  fullName: string
}
