<modal-container
  #modal
  [ignoreBackdropClick]="true"
  (hide)="onHideChildModal()"
  [headerIconClass]="modalHeaderIcon"
  [headerTitle]="modalTitle"
  [modalHeaderBgColorClass]="
    type === 'in'
      ? 'header-class bg_green'
      : type === 'out'
        ? 'header-class bg_red'
        : type === 'transfer'
          ? 'header-class bg_orange'
          : ''
  "
  [modalBodyBgColorClass]="
    type === 'in' ? 'bg_green' : type === 'out' ? 'bg_red' : type === 'transfer' ? 'bg_orange' : ''
  "
  [modalFooterBgColorClass]="
    type === 'in' ? 'bg_green' : type === 'out' ? 'bg_red' : type === 'transfer' ? 'bg_orange' : ''
  "
  [isLargeModal]="true"
>
  <ng-container class="modal-button-show">
    <btn
      *ngIf="isCreatingProduct && !hideButton"
      [classes]="['btn-second']"
      (onEvent)="showChildModal()"
      [icon]="'far-plus'"
      [label]="'product.create'"
      [title]="'product.create' | translate"
      [isLoading]="loading"
      [attr.data-cy]="dataCyAttribute"
    ></btn>
    <btn
      *ngIf="!isCreatingProduct && !operationToUpdate && !hideButton"
      [isLoading]="loading"
      [classes]="['btn-second', 'btn-sm']"
      [icon]="'far-check'"
      [label]="'select'"
      (onEvent)="showChildModal()"
      [title]="'select' | translate"
      [attr.data-cy]="dataCyAttribute"
    ></btn>
    <btn
      *ngIf="operationToUpdate"
      [classes]="['btn-edit']"
      [isLoading]="loading"
      (onEvent)="showChildModal()"
      [icon]="'far-pencil'"
      [title]="'open' | translate"
      [attr.data-cy]="dataCyAttribute"
    ></btn>
  </ng-container>
  <ng-container class="modal-body-content" *ngIf="type === 'in' || type === 'transfer'">
    <div class="container-fluid ps-0 pe-0">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
      <!--        TODO: stock overview - by depot!!!-->
      <div class="stock-operation-card--content">
        <div class="card" style="padding: 26px">
          <div *ngIf="product && isCreatingProduct && !isCreatedProduct">
            <div class="row">
              <form-row-input
                class="col-12"
                [label]="'product.name'"
                [icon]="'far-file-alt'"
                [(model)]="product.name"
                [dataCyAttribute]="'name'"
              ></form-row-input>
            </div>
            <div class="row">
              <form-row-input
                class="col-4"
                [label]="'product.code'"
                [icon]="'far-tag'"
                [(model)]="product.code"
                [dataCyAttribute]="'code'"
              ></form-row-input>
              <form-row-input
                class="col-8"
                [label]="'product.ean'"
                [icon]="'far-barcode'"
                [model]="product.ean"
                [translateBarcode]="true"
                (modelChange)="setEan($event)"
                [appendBtnIcon]="!product.ean || (product.ean && product.ean.length < 1) ? 'far-barcode' : null"
                [appendBtnLabel]="
                  !product.ean || (product.ean && product.ean.length < 1) ? 'product.ean.generate' : null
                "
                [appendBtnClasses]="['btn', 'btn-ean']"
                (onAppendBtnEvent)="fillNextEan()"
                [dataCyAttribute]="'ean'"
              >
              </form-row-input>
            </div>
            <div class="row">
              <form-row-input
                class="col-4"
                [label]="'product.price.sale'"
                [icon]="'far-money-bill'"
                [(model)]="product.sellPrice"
                [replaceComma]="true"
                [dataCyAttribute]="'sale-price'"
                [inputRequired]="true"
              ></form-row-input>
              <form-row-input
                class="col-4"
                [label]="'product.price.before'"
                [icon]="'far-money-bill-alt'"
                [(model)]="product.beforeSellPrice"
                [replaceComma]="true"
                [dataCyAttribute]="'price-before'"
              ></form-row-input>
              <div class="form-group col-4">
                <label>{{ 'product.vatrate' | translate }}:</label>
                <div class="input-group">
                  <select
                    name="vatRate"
                    id="vatRate"
                    class="form-control"
                    [ngModel]="product?.vat?.id"
                    (ngModelChange)="selectVat($event)"
                    data-cy="vat"
                  >
                    <option *ngFor="let vat of vats" [value]="vat.id">{{ vat.name }} ({{ vat.percent }}%)</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <form-row-input
                class="col-4"
                [label]="'product.weight'"
                [icon]="'far-tachometer'"
                [(model)]="product.weight"
                [inputType]="'number'"
                [dataCyAttribute]="'weight'"
              ></form-row-input>
              <form-row-select
                class="col-8"
                [label]="'product.producer'"
                [icon]="'far-industry'"
                [model]="product.producer ? product.producer.id : null"
                (modelChange)="selectProducer($event)"
                [optionKey]="'id'"
                [optionKeyText]="'name'"
                [options]="producers"
              ></form-row-select>
            </div>
            <div class="row">
              <div class="form-group product-parent col-8">
                <label>{{ 'product.parent' | translate }}:</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    [ngModel]="
                      product.parent && product.parent.fullName && product.parent.fullName.length > 0
                        ? product.parent.fullName
                        : ''
                    "
                    disabled
                  />
                </div>
              </div>
              <div class="mt-4 mb-3 col-4">
                <modal-product-parent
                  [product]="product"
                  [depots]="depots"
                  [suppliers]="suppliers"
                  (result)="setParent($event)"
                ></modal-product-parent>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <form-row-toggle
                  [(model)]="product.enabled"
                  [label]="'product.enabled'"
                  [classesString]="'text-nowrap'"
                >
                </form-row-toggle>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <form-row-toggle
                  [(model)]="product.isForSubsequentSettlement"
                  [label]="'product.isForSubsequentSettlement'"
                  [tooltipIcon]="'fa-info-circle'"
                  [tooltipLabel]="'isForSubsequentSettlement.info'"
                  [classesString]="'text-nowrap'"
                >
                </form-row-toggle>
              </div>
            </div>
          </div>
          <div class="form-operation-card" *ngIf="isCreatedProduct">
            <div class="row">
              <div class="col-12 ps-0 pe-0" *ngIf="type === 'in'">
                <form-row-select
                  [label]="'product.supplier.colon'"
                  [icon]="'far-truck'"
                  [(model)]="selectedSupplier"
                  (valueChangeNative)="setViewSettings()"
                  [options]="suppliers"
                  [optionKey]="'id'"
                  [optionKeyText]="'name'"
                  [dataCyAttribute]="'product-supplier-select'"
                ></form-row-select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6 ps-0">
                <form-row-input
                  [label]="'product.code'"
                  [icon]="'far-tag'"
                  [model]="product.code"
                  [inputDisabled]="true"
                ></form-row-input>
              </div>
              <div class="col-12 col-sm-6 pe-0">
                <form-row-input
                  [label]="'product.ean'"
                  [icon]="'far-barcode'"
                  [model]="product.ean"
                  [inputDisabled]="true"
                ></form-row-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6 ps-0">
                <form-row-input
                  [label]="'product.batch'"
                  [icon]="'far-cubes'"
                  [(model)]="batch"
                  [inputDisabled]="type === 'transfer'"
                >
                </form-row-input>
              </div>
              <div class="col-12 col-sm-6 pe-0">
                <form-row-input
                  [inputType]="'date'"
                  [label]="'product.expirationDate'"
                  [icon]="'far-clock'"
                  [model]="expirationDate | fmtDate: 'yyyy-MM-dd'"
                  (modelChange)="expirationDate = $event"
                  [inputDisabled]="type === 'transfer'"
                ></form-row-input>
              </div>
            </div>
            <div class="row stock-positions">
              <div class="col-12 col-sm-4 input-dash position-relative">
                <span class="stock-positions-absolute">{{
                  type === 'transfer' ? ('stock.positions.new' | translate) : ('stock.positions' | translate)
                }}</span>
                <form-row-input [label]="'row'" [icon]="'far-box'" [(model)]="position1"></form-row-input>
                <div class="align-self-center mt-2">-</div>
              </div>
              <div class="col-12 col-sm-4 input-dash">
                <form-row-input [label]="'aisle'" [icon]="'far-box'" [(model)]="position2"></form-row-input>
                <div class="align-self-center mt-2">-</div>
              </div>
              <div class="col-12 col-sm-4">
                <form-row-input [label]="'shelf'" [icon]="'far-box'" [(model)]="position3"></form-row-input>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col ps-0" *ngIf="!operationToUpdate">
                <form-row-input
                  [inputType]="'number'"
                  [label]="'quantity'"
                  [icon]="'far-list'"
                  [(model)]="quantity"
                  [inputDisabled]="isQuantityLocked"
                  [appendBtnIcon]="isQuantityLocked ? 'far-lock' : 'far-unlock'"
                  [appendBtnClasses]="['btn-input']"
                  (onAppendBtnEvent)="toggleLockQuantity()"
                  [(appendCheckboxValue)]="isQuantityLocked"
                  [maximumVal]="type === 'transfer' ? productDepot.quantityStock : null"
                ></form-row-input>
              </div>
              <div class="col" *ngIf="operationToUpdate">
                <form-row-input
                  [inputType]="'number'"
                  [label]="'quantity'"
                  [icon]="'far-list'"
                  [(model)]="quantity"
                  [maximumVal]="type === 'transfer' ? operationToUpdate.productDepotFrom.quantityStock : null"
                ></form-row-input>
              </div>
              <div class="col align-items-center">
                <form-row-input
                  [label]="'purchasePrice'"
                  [icon]="'far-money-bill'"
                  [(model)]="purchasePrice"
                  [replaceComma]="true"
                ></form-row-input>
              </div>
              <div class="col-2">
                <form-row-select
                  [label]="'currency'"
                  [icon]="'far-money-bill-alt'"
                  [(model)]="selectedCurrency"
                  [options]="currencies"
                ></form-row-select>
              </div>
              <div class="col-4 pe-0" *ngIf="type === 'in'">
                <form-row-input [label]="'duzp'" [inputType]="'date'" [(model)]="dateDUZP"></form-row-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12 ps-0 pe-0">
                <form-row-textarea
                  #inputNote
                  [label]="'note'"
                  [icon]="'far-list'"
                  [(model)]="note"
                  [rows]="4"
                ></form-row-textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <alert [type]="'info'" *ngIf="resultMessage.length">
        {{ resultMessage }}
      </alert>
    </div>
  </ng-container>

  <ng-container class="modal-body-content" *ngIf="type === 'out'">
    <div class="container-fluid ps-0 pe-0">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
      <!--        TODO: stock overview - by depot!!!-->
      <div class="stock-operation-card--content">
        <div class="card" style="padding: 26px">
          <div *ngIf="product && isCreatingProduct && !isCreatedProduct">
            <div class="row">
              <form-row-input
                class="col-12"
                [label]="'product.name'"
                [icon]="'far-file-alt'"
                [(model)]="product.name"
                [dataCyAttribute]="'name'"
              ></form-row-input>
            </div>
            <div class="row">
              <div class="form-group product-parent col-8">
                <label>{{ 'product.parent' | translate }}:</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    [ngModel]="
                      product.parent && product.parent.name && product.parent.name.length > 0 ? product.parent.name : ''
                    "
                    disabled
                  />
                </div>
              </div>
              <div class="mt-4 mb-3 col-4">
                <modal-product-parent
                  [product]="product"
                  [depots]="depots"
                  [suppliers]="suppliers"
                  (result)="setParent($event)"
                ></modal-product-parent>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <form-row-toggle
                  [(model)]="product.enabled"
                  [label]="'product.enabled'"
                  [classesString]="'text-nowrap'"
                >
                </form-row-toggle>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <form-row-toggle
                  [(model)]="product.isForSubsequentSettlement"
                  [label]="'product.isForSubsequentSettlement'"
                  [tooltipIcon]="'fa-info-circle'"
                  [tooltipLabel]="'isForSubsequentSettlement.info'"
                  [classesString]="'text-nowrap'"
                >
                </form-row-toggle>
              </div>
            </div>
          </div>
          <div class="form-operation-card" *ngIf="isCreatedProduct">
            <div class="row mt-2" style="display: flex; justify-content: space-between">
              <div class="col-6" *ngIf="!operationToUpdate">
                <form-row-input
                  [inputType]="'number'"
                  [label]="'quantity'"
                  [icon]="'far-list'"
                  [model]="quantity"
                  [maximumVal]="depotQuantity"
                  [minimumVal]="1"
                  (modelChange)="quantityChanged($event)"
                  [inputDisabled]="isQuantityLocked"
                  [appendBtnIcon]="isQuantityLocked ? 'far-lock' : 'far-unlock'"
                  [appendBtnClasses]="['btn-input']"
                  (onAppendBtnEvent)="!selectCustomPositions ? toggleLockQuantity() : ''"
                  [appendCheckboxValue]="selectCustomPositions ? null : isQuantityLocked"
                  (appendCheckboxValueChange)="selectCustomPositions ? null : (isQuantityLocked = $event)"
                ></form-row-input>
                <form-row-toggle
                  class="align-self-center"
                  [label]="'selectCustomPositions'"
                  [model]="selectCustomPositions"
                  (modelChange)="selectCustomPositionChange($event)"
                  [classesString]="'text-nowrap'"
                ></form-row-toggle>
              </div>
              <div class="col-4" *ngIf="operationToUpdate">
                <form-row-input
                  [inputType]="'number'"
                  [label]="'quantity'"
                  [icon]="'far-list'"
                  [(model)]="quantity"
                  [maximumVal]="type === 'transfer' ? productDepot.quantityStock : null"
                ></form-row-input>
              </div>
              <div class="ps-4">
                <div
                  class="img-card"
                  [photoGalleryGroup]="{ index: 1 }"
                  *ngFor="let img of product.images"
                  [photoGallery]="img.url"
                  [photoGalleryCaption]="
                    img.product
                      ? img.product.name +
                        '<br>' +
                        (img.product.code ? ('modal-stock-operation-card.code' | translate) + img.product.code : '') +
                        '<br>' +
                        (img.product.ean ? ('modal-stock-operation-card.ean' | translate) + img.product.ean : '')
                      : ''
                  "
                >
                  <div class="img-container">
                    <div>
                      <img class="images-item-image" [src]="getThumbUrl(img)" [alt]="img.text" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 d-flex gap-05" *ngIf="type === 'in' || type === 'transfer'">
                <form-row-input
                  class="w-100"
                  [label]="'purchasePrice'"
                  [icon]="'far-money-bill'"
                  [(model)]="purchasePrice"
                  [replaceComma]="true"
                ></form-row-input>
                <p class="my-auto pt-2 font-size-input">{{ purchasePrice | formatCurrencyWithoutPrice }}</p>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table m-0">
                <thead>
                  <tr>
                    <th class="text-center">{{ 'product.position' | translate }}</th>
                    <th class="text-center">{{ 'product.expirationDate' | translate }}</th>
                    <th class="text-start">{{ 'product.batch' | translate }}</th>
                    <th class="text-end">{{ 'max' | translate }}</th>
                    <th class="text-end">{{ 'product.unload.ks' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let productDepot of productDepots">
                    <td class="text-center no-hover">{{ productDepot.position }}</td>
                    <td class="text-center no-hover">
                      {{ productDepot.expirationDate ? (productDepot.expirationDate | fmtDate: 'dd.MM.yyyy') : '-' }}
                    </td>
                    <td class="text-start no-hover">{{ productDepot.batch ? productDepot.batch : '-' }}</td>
                    <td class="text-end no-hover">{{ productDepot.quantityStock }}</td>
                    <td class="text-end no-hover" *ngIf="!selectCustomPositions">
                      {{ productDepot.amountToUnload | number: '1.0-2' | replaceCommaToDot }}
                    </td>
                    <td class="no-hover" *ngIf="selectCustomPositions">
                      <form-row-input
                        [icon]="'far-minus'"
                        [model]="productDepot.amountToUnload"
                        (modelChange)="amountToUnloadChanged($event, productDepot)"
                        [inputType]="'number'"
                        [maximumVal]="productDepot.quantityStock"
                        [minimumVal]="0"
                      ></form-row-input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container class="modal-footer-content">
    <button
      type="submit"
      class="btn-first"
      (click)="createProduct()"
      [title]="'product.create' | translate"
      *ngIf="isCreatingProduct && !isCreatedProduct"
      data-cy="submit"
    >
      <i class="far fa-check"></i>
      {{ 'product.create' | translate }}
    </button>
    <button
      type="submit"
      class="btn-first"
      (click)="updateProduct()"
      [title]="'product.update' | translate"
      *ngIf="!isCreatedProduct"
    >
      <i class="far fa-pencil"></i>
      {{ 'product.update' | translate }}
    </button>
    <button
      type="submit"
      class="btn-first-green-inverted"
      (click)="createOperation()"
      [title]="'product.load' | translate"
      *ngIf="type === 'in' && isCreatedProduct"
      data-cy="stock-submit"
    >
      <i class="far fa-layer-plus"></i>
      {{ 'product.load' | translate }}
    </button>
    <button
      type="submit"
      class="btn-first-red-inverted"
      (click)="createOperation()"
      [title]="'product.unload' | translate"
      *ngIf="type === 'out' && isCreatedProduct"
      data-cy="stock-submit"
    >
      <i class="far fa-layer-minus"></i>
      {{ 'product.unload' | translate }}
    </button>
    <button
      type="submit"
      class="btn-first-orange-inverted"
      (click)="createOperation()"
      [title]="'product.transfer' | translate"
      *ngIf="type === 'transfer' && isCreatedProduct"
      data-cy="stock-submit"
    >
      <i class="far fa-exchange"></i>
      {{ 'product.transfer' | translate }}
    </button>
  </ng-container>
</modal-container>
