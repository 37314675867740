export async function asyncForEach(
  array: Array<any>,
  callback: (arrayElement: any, index: number, array: Array<any>) => any
): Promise<any> {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export async function wait(ms = 100): Promise<void> {
  return new Promise(resolve => setTimeout(() => resolve(), ms))
}
