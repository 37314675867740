import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { historyBack } from '../../utils'
import { NotificationService } from '../../services'
import { Parameter } from 'depoto-core/src/entities'
import { CoreService } from '../../services'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { SchemaUtil } from '../../utils'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-parameter',
  templateUrl: 'parameter.component.html',
})
export class ParameterComponent implements OnInit, CheckDataChanges {
  parameter: Parameter
  parameterTypes: string[] = ['number', 'boolean', 'text', 'enum', 'enum_multi']
  enumValuesString = ''
  isCreating = false
  loading = true
  submitted = false

  parameterForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, [Validators.required]),
    type: new FormControl({ value: '', disabled: false }, [Validators.required]),
    unit: new FormControl({ value: '', disabled: false }, []),
    externalId: new FormControl({ value: '', disabled: false }, []),
  })

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private core: CoreService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
  }

  async ngOnInit() {
    this.loading = true
    const id = this.route.snapshot.params['id']
    if (isNaN(id)) {
      this.isCreating = true
      this.parameter = new Parameter()
    } else {
      this.parameter = await this.core.services.parameter.getById(id, SchemaUtil.parameter)
      this.fillFormValue()
      this.enumValuesString =
        this.parameter && this.parameter.enumValues && this.parameter.enumValues.length
          ? this.parameter.enumValues.join('\n')
          : ''
    }
    this.loading = false
  }

  public fillFormValue() {
    this.parameterForm.setValue({
      name: this.parameter.name,
      type: this.parameter.type,
      unit: this.parameter.unit,
      externalId: this.parameter.externalId,
    })
  }

  public getFormValues() {
    this.parameter.name = this.parameterForm.value.name
    this.parameter.type = this.parameterForm.value.type
    this.parameter.unit = this.parameterForm.value.unit
    this.parameter.externalId = this.parameterForm.value.externalId
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  goBack(): void {
    historyBack()
  }

  async createParameter() {
    this.submitted = true
    if (this.parameterForm.invalid) {
      return
    }
    this.loading = true
    try {
      this.getFormValues()
      if (this.parameter.type === 'enum' || this.parameter.type === 'enum_multi') {
        this.parameter.enumValues = this.enumValuesString.split('\n')
      }
      await this.core.services.parameter.create(this.parameter).then(result => {
        this.notificationService.success(this.translateService.instant('ALERT PARAMETER CREATED'))
        this.router.navigate(['/parameter/', result.id], { replaceUrl: true })
      })
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
    }
  }

  async updateParameter() {
    this.submitted = true
    if (this.parameterForm.invalid) {
      return
    }
    this.loading = true
    if (this.parameter.type === 'enum' || this.parameter.type === 'enum_multi') {
      this.parameter.enumValues = this.enumValuesString.split('\n')
    }
    try {
      this.getFormValues()
      this.parameter = await this.core.services.parameter.update(this.parameter, SchemaUtil.parameter).then(result => {
        this.notificationService.success(this.translateService.instant('ALERT PARAMETER UPDATED'))
        return result
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async deleteParameter() {
    const confirmation = confirm(
      this.translateService.instant('parameter.delete.confirmation', { name: this.parameter.name })
    )
    if (confirmation) {
      try {
        await this.core.services.parameter.delete(this.parameter).then(() => {
          this.notificationService.success(this.translateService.instant('ALERT PARAMETER DELETED'))
        })
        historyBack()
      } catch (err) {
        console.error(err)
      }
    }
  }
  get formControls(): { [key: string]: AbstractControl } {
    return this.parameterForm.controls
  }
}
