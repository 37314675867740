<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="producerForm" (ngSubmit)="isCreating ? createProducer() : updateProducer()" *ngIf="producer">
    <page-header
      [icon]="'far-truck'"
      [title]="isCreating ? 'brand.new' : 'brand'"
      [appendNoTranslationTitlePart]="producer ? producer.name : ''"
    >
      <ng-container class="header-button">
        <btn
          [btnType]="'submit'"
          [classes]="['btn-first', 'float-right']"
          [icon]="'far-check'"
          [label]="'save'"
          [title]="'save' | translate"
          [isLoading]="loading"
          [btnDisabled]="loading"
          [spinnerClassesString]="'spinner-white'"
          [dataCyAttribute]="'create-producer'"
        ></btn>
        <btn
          *ngIf="!isCreating"
          [btnType]="'button'"
          [classes]="['btn-first-red', 'float-right', 'me-2']"
          (onEvent)="deleteProducer()"
          [icon]="'far-trash-alt'"
          [label]="'brand.btn.delete'"
          [title]="'brand.btn.delete' | translate"
          [dataCyAttribute]="'delete-producer'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <section class="content">
      <div class="card">
        <div class="row">
          <div class="col-sm-6">
            <form-row-input
              [formGroup]="producerForm"
              formControlName="name"
              [required]="producerForm.get('name').errors !== null && producerForm.get('name').errors.required"
              [requiredCssClass]="submitted && formControls.name.errors"
              [label]="'producer.name'"
              [icon]="'far-list'"
              [dataCyAttribute]="'name'"
            ></form-row-input>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
