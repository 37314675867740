import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { Depot } from 'depoto-core/src/entities'
import { historyBack, SchemaUtil } from '../../utils'
import { NotificationService } from '../../services'
import { CoreService } from '../../services'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-depot',
  templateUrl: 'depot.component.html',
})
export class DepotComponent implements OnInit, CheckDataChanges {
  depot: Depot
  isCreating = false
  loading = true
  depotForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, [Validators.required]),
    emailIn: new FormControl({ value: '', disabled: false }, []),
  })
  submitted = false

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private core: CoreService
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.params['id']
    if (isNaN(id)) {
      this.isCreating = true
      this.depot = new Depot()
      this.loading = false
    } else {
      this.refreshData(id)
    }
  }

  goBack(): void {
    historyBack()
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  async refreshData(id: number) {
    this.loading = true
    const result = await this.core.services.depot.getById(id, SchemaUtil.depot)
    this.loading = false
    if (result) {
      this.depot = new Depot(result)
      this.depotForm.setValue({ name: this.depot.name, emailIn: this.depot.emailIn })
    }
  }

  async createDepot(): Promise<void> {
    this.submitted = true
    if (this.depotForm.invalid) {
      return
    }
    this.depot.name = this.depotForm.value.name
    this.depot.emailIn = this.depotForm.value.emailIn
    this.loading = true
    try {
      await this.core.services.depot.create(this.depot).then(result => {
        this.notificationService.success(this.translateService.instant('alert.depot.created'))
        this.router.navigate(['/depot/', result.id], { replaceUrl: true })
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async updateDepot() {
    this.submitted = true
    if (this.depotForm.invalid) {
      return
    }
    this.depot.name = this.depotForm.value.name
    this.depot.emailIn = this.depotForm.value.emailIn
    try {
      await this.core.services.depot.update(this.depot).then(result => {
        this.notificationService.success(this.translateService.instant('alert.depot.updated'))
        this.refreshData(result.id)
      })
    } catch (err) {
      console.error(err)
    }
  }

  async deleteDepot() {
    const confirmation = confirm(this.translateService.instant('depot.delete.confirmation', { name: this.depot.name }))
    if (confirmation) {
      try {
        const errors = await this.core.services.depot.delete(this.depot)
        if (!errors) {
          this.notificationService.success(this.translateService.instant('alert.depot.deleted'))
          historyBack()
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.depotForm.controls
  }
}
