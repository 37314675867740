import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NotificationService } from '../services'
import { threadId } from 'worker_threads'

@Injectable({
  providedIn: 'root',
})
export class FileUtilService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly notificationService: NotificationService
  ) {}
  async processUploadedFileToBase64(file: any): Promise<string | any> {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader()
        reader.readAsDataURL(new Blob([file]))
        reader.onload = evt => {
          if (file.size > 10485760) {
            this.notificationService.error(this.translateService.instant('overweight-file', { name: file.name }))
            reject(this.translateService.instant('overweight-file'))
          } else {
            resolve({
              text: file.name,
              originalFilename: file.name,
              mimeType: file.type,
              base64Data: evt.target.result,
            })
          }
        }
        reader.onerror = () => {
          reject(this.translateService.instant('file-processing-error', { name: file.name }))
        }
      } else {
        reject(this.translateService.instant('no-file'))
      }
    })
  }
}
