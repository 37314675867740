import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Carrier, Checkout, CompanyCarrier } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../services'
import { FormControl, FormGroup } from '@angular/forms'
import { SchemaUtil, historyBack } from '../../utils'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-company-carrier',
  templateUrl: 'company-carrier.component.html',
  styles: [
    `
      .fix-vertical-align {
        margin-top: 2.2rem;
      }
    `,
  ],
})
export class CompanyCarrierComponent implements OnInit, CheckDataChanges {
  companyCarrier: CompanyCarrier
  carriers: Carrier[]
  checkouts: (Checkout | any)[]
  optionsType = ''
  optionsObj: any = {}
  isCreating = false
  loading = true

  companyCarrierForm = new FormGroup({
    externalId: new FormControl({ value: '', disabled: false }, []),
    enable: new FormControl({ value: false, disabled: false }, []),
  })

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
  }

  ngOnInit() {
    const id = this.route.snapshot.params['id']
    if (isNaN(id)) {
      this.isCreating = true
      this.companyCarrier = new CompanyCarrier()
      this.companyCarrier.carrier = new Carrier()
      this.refreshData()
    } else {
      this.refreshData(id)
    }
  }

  goBack(): void {
    historyBack()
  }

  public fillFormValues() {
    this.companyCarrierForm.setValue({
      externalId: this.companyCarrier.externalId,
      enable: this.companyCarrier.enable,
    })
  }

  public getFormValues() {
    this.companyCarrier.externalId = this.companyCarrierForm.value.externalId
    this.companyCarrier.enable = this.companyCarrierForm.value.enable
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  async refreshData(id: number = null) {
    this.loading = true
    this.checkouts = [{ id: 0, name: ' ' }, ...this.core.lists.checkouts] // || []  - never used, the first is always true
    this.carriers = await this.core.services.carrier
      .getList(
        {},
        {
          id: null,
          name: null,
          tariffs: {
            id: null,
            const: null,
            name: null,
            cod: null,
            position: null,
          },
        }
      )
      .then(result => result.items)
    if (id > 0) {
      this.companyCarrier = new CompanyCarrier(
        await this.core.services.companyCarrier.getById(id, SchemaUtil.companyCarrier)
      )
      if (this.companyCarrier.carrier && this.companyCarrier.carrier.id && this.companyCarrier.carrier.id.length > 0) {
        this.updateCarrier(this.companyCarrier.carrier.id)
      }
    } else if (!!this.companyCarrier && this.carriers.length > 0) {
      this.updateCarrier(this.carriers[0].id)
    }
    this.fillFormValues()
    this.loading = false
  }

  updateCarrier(carrierId: string) {
    if (!carrierId) {
      return
    }
    this.companyCarrier.carrier = this.carriers.filter(c => c.id === carrierId)[0]

    if (carrierId === 'cp' || carrierId === 'cp_np' || carrierId === 'cp_nb' || carrierId === 'cp_bn') {
      this.optionsType = carrierId
      this.optionsObj = this.parseOptionsObj(this.companyCarrier.options)
      if (!Object.keys(this.optionsObj).includes('customerID')) {
        this.optionsObj = {
          customerID: '',
          contractNumber: '',
          postCode: '',
          locationNumber: '',
          apiToken: '',
          apiSecret: '',
        }
      }
    } else if (carrierId === 'dhl_express') {
      this.optionsType = 'dhl_express'
      this.optionsObj = this.parseOptionsObj(this.companyCarrier.options)
      if (!Object.keys(this.optionsObj).includes('username')) {
        this.optionsObj = { username: '', password: '', account_number: '', content_description: '' }
      }
    } else if (carrierId === 'dpd') {
      this.optionsType = 'dpd'
      this.optionsObj = this.parseOptionsObj(this.companyCarrier.options)
      if (!Object.keys(this.optionsObj).includes('customerId')) {
        this.optionsObj = { customerId: '', customerAddressId: '', username: '', password: '' }
      }
    } else if (carrierId === 'fedex') {
      this.optionsType = 'fedex'
      this.optionsObj = this.parseOptionsObj(this.companyCarrier.options)
      if (!Object.keys(this.optionsObj).includes('key')) {
        this.optionsObj = { key: '', password: '', account_number: '', meter_number: '', product_desc: '' }
      }
    } else if (carrierId === 'gls') {
      this.optionsType = 'gls'
      this.optionsObj = this.parseOptionsObj(this.companyCarrier.options)
      if (!Object.keys(this.optionsObj).includes('username')) {
        this.optionsObj = { clientNumber: '', username: '', password: '', myGls: false }
      }
    } else if (carrierId === 'ppl') {
      this.optionsType = 'ppl'
      this.optionsObj = this.parseOptionsObj(this.companyCarrier.options, true)
      if (!Object.keys(this.optionsObj).includes('customerId')) {
        this.optionsObj = { username: '', password: '', customerId: '', depo: '', numbers: {}, numbersArr: [] }
        this.addPplNumberRow()
      }
    } else if (carrierId.includes('zasilkovna')) {
      this.optionsType = 'zasilkovna'
      this.optionsObj = this.parseOptionsObj(this.companyCarrier.options)
      if (!Object.keys(this.optionsObj).includes('api_password')) {
        this.optionsObj = { eshop: '', api_password: '' }
      }
    } else if (carrierId.includes('ulozenka')) {
      this.optionsType = 'ulozenka'
      this.optionsObj = this.parseOptionsObj(this.companyCarrier.options)
      if (!Object.keys(this.optionsObj).includes('apiKey')) {
        this.optionsObj = { shopId: '', apiKey: '' }
      }
    } else if (carrierId.includes('shippypro')) {
      this.optionsType = carrierId
      this.optionsObj = this.parseOptionsObj(this.companyCarrier.options)
      if (!Object.keys(this.optionsObj).includes('contentDescription')) {
        this.optionsObj = {
          apikey: '',
          contentDescription: '',
          packageLength: '',
          packageWidth: '',
          packageHeight: '',
          packageWeight: '',
        }
      }
    } else if (carrierId.includes('spring_xbs')) {
      this.optionsType = 'spring_xbs'
      this.optionsObj = this.parseOptionsObj(this.companyCarrier.options)
      if (!Object.keys(this.optionsObj).includes('apikey')) {
        this.optionsObj = { apikey: '' }
      }
    } else if (carrierId.includes('wedo')) {
      this.optionsType = carrierId
      this.optionsObj = this.parseOptionsObj(this.companyCarrier.options)
      if (!Object.keys(this.optionsObj).includes('department')) {
        this.optionsObj = { username: '', password: '', customerId: '', department: '' }
      }
    } else {
      this.optionsType = ''
      this.optionsObj = {}
    }
  }

  updateCheckout(checkoutId: number | string): void {
    const ch = this.checkouts.filter(checkout => checkout.id === Number(checkoutId))
    let c = null
    if (ch && ch.length > 0) {
      c = new Checkout(ch[0])
    }
    this.companyCarrier.checkout = c
  }

  parseOptionsObj(optionsStr: string, isPpl = false): any {
    let result
    try {
      result = JSON.parse(optionsStr)
      if (isPpl) {
        const nums = result.numbers
        const numsArr = []
        Object.keys(nums).forEach(k => {
          numsArr.push({
            tariffId: k,
            from: Number(nums[k].from),
            to: Number(nums[k].to),
          })
        })
        result.numbersArr = numsArr
      }
    } catch (e) {
      result = {}
    }
    return result
  }

  addPplNumberRow() {
    this.optionsObj.numbersArr.push({ tariffId: '', from: '', to: '' })
  }

  removePplNumberRow(row: any) {
    this.optionsObj.numbersArr.forEach((r, i) => {
      if (row.tariffId === r.tariffId) {
        this.optionsObj.numbersArr.splice(i, 1)
      }
    })
  }

  updateCompanyCarrierOptions() {
    if (this.optionsType.length > 0) {
      if (this.optionsType === 'ppl') {
        const numRes = {}
        this.optionsObj.numbersArr.forEach(n => {
          numRes[n.tariffId] = { from: n.from, to: n.to }
        })
        const resObj = {
          username: this.optionsObj.username,
          password: this.optionsObj.password,
          customerId: this.optionsObj.customerId,
          depo: this.optionsObj.depo,
          numbers: numRes,
        }
        this.companyCarrier.options = JSON.stringify(resObj)
      } else {
        this.companyCarrier.options = JSON.stringify(this.optionsObj)
      }
    } else {
      this.companyCarrier.options = ''
    }
  }

  async createCompanyCarrier() {
    if (this.companyCarrierForm.invalid) {
      return
    }
    this.loading = true
    try {
      this.getFormValues()
      this.updateCompanyCarrierOptions()
      await this.core.services.companyCarrier.create(this.companyCarrier).then(result => {
        this.notificationService.success(this.translateService.instant('alert.carrier.created'))
        this.router.navigate(['/company-carrier', result.id], { replaceUrl: true })
      })
      this.loading = false
    } catch (e) {
      console.warn(e)
    }
  }

  async updateCompanyCarrier() {
    if (this.companyCarrierForm.invalid) {
      return
    }
    this.loading = true
    try {
      this.getFormValues()
      this.updateCompanyCarrierOptions()
      this.companyCarrier = await this.core.services.companyCarrier.update(this.companyCarrier).then(result => {
        this.notificationService.success(this.translateService.instant('alert.carrier.updated')) // todo wtf
        return result
      })
      if (this.companyCarrier.carrier && this.companyCarrier.carrier.id && this.companyCarrier.carrier.id.length > 0) {
        this.updateCarrier(this.companyCarrier.carrier.id)
      }
      await this.refreshData(this.companyCarrier.id)
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async deleteCompanyCarrier() {
    const confirmation = confirm(
      this.translateService.instant('company-carrier.delete.confirmation', { name: this.companyCarrier.name })
    )
    if (confirmation) {
      try {
        await this.core.services.companyCarrier.delete(this.companyCarrier).then(() => {
          this.notificationService.success(this.translateService.instant('alert.carrier.deleted'))
          historyBack()
        })
      } catch (err) {
        console.error(err)
      }
    }
  }

  isCarrierSettingsFilled(): boolean {
    const values = Object.values(this.optionsObj)
    return !values.some(value => value === '' || value === undefined || value === null)
  }

  hasOptionsObjAnyKeys(): boolean {
    if (this.optionsObj === null || this.optionsObj === undefined) return false
    const keys = Object.keys(this.optionsObj)
    return keys?.length > 0
  }
}
