<modal-container
  [headerIconClass]=""
  [headerTitle]="'purchase.storage-information'"
  [modalHeaderBgColorClass]="'header-class bg_green'"
  [modalBodyBgColorClass]="'bg_green'"
  [modalFooterBgColorClass]="'bg_green'"
  [isLargeModal]="true"
>
  <ng-container class="modal-body-content">
    <div class="container-fluid ps-0 pe-0">
      <div class="stock-operation-card--content">
        <div class="card" style="padding: 26px">
          <div class="row">
            <form-row-select
              class="col"
              [label]="'purchase.depot-to'"
              [options]="depots"
              [(ngModel)]="selectedDepot"
              [required]="true"
              [dataCyAttribute]="'purchase-depotTo'"
              #selectedDepotField="ngModel"
            ></form-row-select>
            <form-row-select
              class="col"
              [label]="'purchase.goods-availability'"
              [options]="purchaseOrderStrategyOptions"
              [optionKey]="'code'"
              [(ngModel)]="selectedPurchaseOrderStrategy"
              [required]="true"
              [dataCyAttribute]="'strategy'"
              #selectedPurchaseOrderStrategyField="ngModel"
            ></form-row-select>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container class="modal-footer-content">
    <button
      type="submit"
      class="btn-first"
      (click)="addProduct()"
      [title]="'add'"
      [disabled]="!(selectedPurchaseOrderStrategyField.value && selectedDepotField.value)"
      data-cy="modal-product-purchase-submit"
    >
      <i class="far fa-pencil"></i>
      {{ 'add' | translate }}
    </button>
  </ng-container>
</modal-container>
