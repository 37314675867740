<modal-container #modal [headerIconClass]="'far-box'" [headerTitle]="headerTitle" [isLargeModal]="true">
  <ng-container class="modal-button-show">
    <button
      type="button"
      class="btn-second"
      (click)="showChildModal()"
      [title]="btnText | translate"
      [attr.data-cy]="dataCySelector"
      *ngIf="!productBundle"
    >
      <!--      TODO MAKE CONDITIONS FOR PARENT ICON AND VIRTUAL PRODUCT -->
      <i class="far fa-search-plus me-2"></i>
      {{ btnText | translate }}
    </button>
    <button
      type="button"
      class="btn-edit btn-sm float-right me-2"
      [title]="'edit' | translate"
      (click)="showChildModal()"
      [attr.data-cy]="dataCySelector"
      *ngIf="!!productBundle"
    >
      <i class="far fa-pencil"></i>
    </button>
  </ng-container>

  <ng-container class="modal-body-content">
    <div class="card">
      <div class="product-search-form" *ngIf="!!productBundle">
        <form-row-input
          [label]="'quantity'"
          [inputType]="'number'"
          [(model)]="quantity"
          [dataCyAttribute]="'bundle-child-quantity-input'"
        >
        </form-row-input>
      </div>
      <div class="product-search-form" *ngIf="!productBundle">
        <div class="row">
          <!--          <div class="col-{{!isForBundleParent && !isForBundleChild ? '12' : '10'}}">-->
          <div class="col-12">
            <form-row-input
              [label]="'fulltext'"
              [(model)]="searchValue"
              [appendBtnIcon]="'far-search'"
              [appendBtnTitle]="'filter' | translate"
              [appendBtnClasses]="['btn-search']"
              (onAppendBtnEvent)="search()"
              (keyup.enter)="search()"
              [dataCyAttribute]="'product-parent-searchValue'"
            ></form-row-input>
          </div>
        </div>
      </div>
      <div class="mb-2" *ngIf="!productBundle">
        <div class="table-responsive">
          <table class="table" [ngClass]="{ h150: !products.length && loading }">
            <loading-spinner [isLoading]="loading" [classesString]="'pt-11'"></loading-spinner>
            <thead *ngIf="products.length > 0">
              <tr>
                <th class="ps-0">{{ 'product.name' | translate }}</th>
                <th *ngIf="isForBundleParent || isForBundleChild">&nbsp;</th>
                <th>&nbsp;</th>
                <th class="pe-0">&nbsp;</th>
              </tr>
            </thead>
            <tbody *ngIf="products.length > 0">
              <tr *ngFor="let prod of products">
                <td class="ps-1">
                  <div class="flex-gap-10">
                    <product-image class="align-self-center" [product]="prod"></product-image>
                    <div class="align-self-center">
                      <p class="my-auto">{{ prod.fullName }}</p>
                      <div class="product-details flex-gap-5 flex-wrap">
                        <div *ngIf="prod.code">{{ 'product.code' | translate }}: {{ prod.code }}</div>
                        <div *ngIf="prod.ean">{{ 'product.ean' | translate }}: {{ prod.ean }}</div>
                      </div>
                    </div>
                  </div>
                </td>
                <td title="{{ 'modal-product-parent.storage' | translate }}">
                  <div class="d-flex justify-content-center">
                    <modal-stock-overview
                      [isCalculatedOfProductDepots]="false"
                      [isSumCalculated]="false"
                      [product]="prod"
                      [suppliers]="suppliers"
                      [depots]="depots"
                    >
                    </modal-stock-overview>
                  </div>
                </td>
                <td *ngIf="(isForBundleParent || isForBundleChild) && productQuantities[prod.id] !== undefined">
                  <div style="width: 105px">
                    <form-row-number-with-controls
                      [(model)]="productQuantities[prod.id]"
                      [label]="null"
                      [maxWidthPx]="82"
                      [noMarginBottom]="true"
                    ></form-row-number-with-controls>
                  </div>
                </td>
                <td class="pe-1">
                  <btn
                    [icon]="'far-check'"
                    [label]="'select'"
                    [title]="'select' | translate"
                    [classes]="['btn-second', 'float-right', 'text-nowrap']"
                    (click)="selectProduct(prod)"
                    [dataCyAttribute]="'product-parent-select-button'"
                  ></btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content">
    <btn
      [label]="'update'"
      [title]="'update' | translate"
      [icon]="'far-pencil'"
      [classes]="['btn-first']"
      *ngIf="!!productBundle"
      (onEvent)="updateBundle()"
      [dataCyAttribute]="'update-bundle-child-btn'"
    ></btn>
  </ng-container>
</modal-container>
