import { Component, ViewChild } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { SchemaUtil, historyBack, unknownMimeType } from '../../utils'
import { CoreService, NotificationService } from '../../services'
import { ProductMovePack } from 'depoto-core/src/entities/ProductMovePack'
import { File, ProductMove, Tag } from 'depoto-core/src/entities'
import { ModalSerialNumbersComponent } from '../../modal-serial-numbers/modal-serial-numbers.component'
import { TranslateService } from '@ngx-translate/core'
import { PrintCodeComponent } from '../../components/print-code/print-code.component'

@Component({
  selector: 'product-move-pack-page',
  templateUrl: 'product-move-pack.component.html',
  styleUrls: ['product-move-pack.component.scss'],
})
export class ProductMovePackComponent {
  @ViewChild(ModalSerialNumbersComponent) modalSerialNumbers: ModalSerialNumbersComponent
  @ViewChild('printCodeComponent') printCodeComponent: PrintCodeComponent
  productMovePack: ProductMovePack
  currencySymbol = 'Kč'
  canCreateOrEdit = false
  loading = true
  quantityItemsSum: number[] | number = 0
  userCanDeleteProductMoves = false
  isPrintingEANs = false
  isAnyOfMovesDeletable = false
  isRequiredSerialNumbers = false
  hasOrderItems = false
  tags: Tag[]

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {
    this.getTags()
    this.route.params.forEach((params: Params) => {
      const id = +params['id']
      this.refreshData(id)
    })
  }

  getTags() {
    this.tags = this.core.lists.tags.filter(t => t.type === 'product_move_pack')
  }

  unknownType(s?: string) {
    return unknownMimeType(s)
  }
  async refreshData(id: number) {
    this.loading = true
    try {
      const result = await this.core.services.productMovePack.getById(id, SchemaUtil.productMovePack)
      this.userCanDeleteProductMoves =
        this.core.services.user.hasRole('ROLE_PRODUCT_MOVE_PACK_DELETE') ||
        this.core.services.user.hasRole('ROLE_ADMIN') ||
        this.core.services.user.hasRole('ROLE_SUPER_ADMIN')
      if (result) {
        this.productMovePack = new ProductMovePack(result)
        this.productMovePack.moves.map(m => ({ ...m, isEANPrintable: true }))
        this.currencySymbol = this.productMovePack.order?.currency
          ? this.translateService.instant(this.productMovePack.order.currency.name)
          : 'Kč'
        this.quantityItemsSum = this.productMovePack.moves.map(i => i.amount).reduce((a, b) => Number(a) + Number(b))
        this.printCodeComponent?.hydrate()
        // set props for showing th, td
        this.isAnyOfMovesDeletable = this.productMovePack.moves.some(move => move.deletable)
        this.isRequiredSerialNumbers = this.productMovePack.moves.some(move => move.product?.requiredSerialNumbers)
        this.hasOrderItems = this.productMovePack.moves.some(move => move.orderItem)
      }
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
    }
  }

  async updateProductMovePack() {
    this.loading = true
    try {
      this.productMovePack = await this.core.services.productMovePack.update(
        this.productMovePack,
        SchemaUtil.productMovePack
      )
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
      this.modalSerialNumbers.hideChildModal()
    }
  }

  async updateTags() {
    this.loading = true
    try {
      this.productMovePack = await this.core.services.productMovePack.updateTags(this.productMovePack, {
        id: null,
        tags: {
          id: null,
          name: null,
          color: null,
        },
      })
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
      console.log(this.loading)
    }
  }

  getAmountSum() {
    if (this.productMovePack.moves.every(move => move.orderItem?.id)) {
      return this.productMovePack.moves.reduce((total, move) => {
        return total + move.orderItem.priceWithoutVat * move.amount
      }, 0)
    } else if (this.productMovePack.type.id === 'out') {
      return this.productMovePack.moves.reduce((total, move) => {
        return total + move.productDepotFrom.purchasePrice * move.amount
      }, 0)
    } else {
      return this.productMovePack.moves.reduce((total, move) => {
        return total + move.productDepotTo.purchasePrice * move.amount
      }, 0)
    }
  }

  getQuantitySum() {
    return this.productMovePack.moves.reduce((currentTotal, move) => move.amount + currentTotal, 0)
  }

  public async deleteProductMovePack() {
    if (!confirm(this.translateService.instant('delete.confirmation'))) {
      return new Promise<void>(r => r())
    }
    this.loading = true
    try {
      //TODO: why do we rely on name?
      const alert = this.productMovePack.type.name.includes('Výdejka')
        ? 'alert.product.move.pack.out.deleted'
        : this.productMovePack.type.name.includes('Příjemka')
          ? 'alert.product.move.pack.in.deleted'
          : 'alert.product.move.pack.transfer.deleted'
      this.productMovePack = await this.core.services.productMovePack.delete(this.productMovePack).then(result => {
        this.notificationService.success(this.translateService.instant(alert))
        return result
      })
    } catch (e) {
      console.warn(e)
    }
    this.loading = false
    await this.router.navigate(['/product-move-packs'], { replaceUrl: true })
  }

  public async deleteProductMove(move: ProductMove) {
    if (!confirm(this.translateService.instant('productMovePack.delete.confirmation'))) {
      return new Promise<void>(r => r())
    }
    this.loading = true
    try {
      await this.core.services.productMove.delete(move).then(() => {
        this.notificationService.success(this.translateService.instant('alert.product.move.deleted'))
        this.refreshData(this.productMovePack.id)
      })
    } catch (e) {
      console.warn(e)
    }
    this.loading = false
  }

  goBack(): void {
    historyBack()
  }

  downloadPdf(type: 'issueSlip' | 'deliveryNote'): void {
    const urlType = type === 'issueSlip' ? 'url' : 'pdfWOPricesUrl'
    if (!this.productMovePack || !this.productMovePack[urlType]?.length) {
      return
    }
    this.core.services.download.downloadDocumentAndShowInNewWindow(this.productMovePack[urlType])
  }

  getThumbUrl(image: File): string {
    return image.thumbnails.filter(t => t.format === 'w135wp')[0].url
  }
}
