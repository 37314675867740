import { OnInit, OnChanges, Input, Output, EventEmitter, ElementRef, ViewChild, Component } from '@angular/core'

@Component({
  selector: 'btn',
  templateUrl: 'btn.component.html',
  styleUrls: ['btn.component.scss'],
})
export class BtnComponent implements OnInit, OnChanges {
  @ViewChild('btn') btn: ElementRef
  @Input() label: string
  @Input() btnType = 'button'
  @Input() btnDisabled = false
  @Input() classes: string[] = []
  @Input() icon: string
  @Input() iconPosition = ''
  @Input() trackCategory = 'event-click-button'
  @Input() trackAction: string
  @Input() trackValue: string
  @Input() isBackButton: boolean
  @Input() isLoading: boolean
  @Input() spinnerClassesString: string
  @Input() dataCyAttribute: string
  @Output() public onEvent: EventEmitter<any> = new EventEmitter()
  // private classesDefault = 'btn'
  classesString = ''
  iconClassesString = ''

  ngOnInit() {
    this.updateComponent()
  }

  ngOnChanges() {
    this.updateComponent()
  }

  triggerEvent(): void {
    this.onEvent.emit(true)
  }

  private updateComponent(): void {
    let classesAdditional = ''
    if (this.classes && this.classes.length > 0) {
      this.classes.forEach(cls => {
        classesAdditional += ' ' + cls
      })
    }
    this.classesString =
      // this.classesDefault +
      classesAdditional + (classesAdditional.length > 0 ? '' : ' btn-third') + (this.isBackButton ? ' back-button' : '')

    // icon
    let iconClasses = ''
    if (this.icon && this.icon.length > 0) {
      iconClasses += this.icon.split('-')[0]
      iconClasses +=
        ' ' +
        this.icon.replace('far-', 'fa-').replace('fas-', 'fa-') +
        (this.label && this.label.length > 0 ? ' me-2' : '')
      iconClasses += ' ' + this.iconPosition
    }
    this.iconClassesString = iconClasses
    if (!this.trackAction) {
      this.trackAction = this.label
    }
  }
}
