import { EventEmitter, forwardRef } from '@angular/core'
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms'

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = component => ({
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => component),
  multi: true,
})

export class FormRowBase implements ControlValueAccessor {
  model: any
  modelChange: EventEmitter<any> = new EventEmitter()
  formControlName: string // @Input() optional for valueAccessor to work, but required for validations
  formGroup: FormGroup // @Input() optional for valueAccessor to work, may come handy for grouped forms
  control: FormControl = new FormControl()
  errors: Array<string> = ['required']
  showMessageMaxValue = false

  // call in onInit()
  initFormControl() {
    if (this.formGroup && this.formControlName) {
      this.control = <FormControl>this.formGroup.get(this.formControlName)
    }
    if (!this.control) {
      return
    }
    this.control.valueChanges.subscribe(() => {
      // check condition if the form control is RESET
      if (this.control.value === '' || this.control.value === null || this.control.value === undefined) {
        this.value = ''
        // this.inputRef.nativeElement.value = "";
      }
    })
  }

  // call on keyup/change
  onChange(event) {
    this.propagateChange(event.target.value)
    this.errors = []
    if (this.control && this.control.errors) {
      this.errors = Object.keys(this.control.errors)
    }
  }

  get value(): any {
    return this.model
  }

  set value(val: any) {
    if (this.model !== val) {
      this.model = val
    }
  }

  propagateChange = (e: any) => {
    return e
  }

  writeValue(value: any) {
    this.model = value
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn
  }

  registerOnTouched(fn: any) {
    // console.log('onTouched') // TODO: remove
  }

  onNgModelChange(value: string | Date | boolean | unknown, maximumVal?): void {
    if (maximumVal !== null && maximumVal !== undefined && Number(value) > Number(maximumVal)) {
      this.showMessageMaxValue = true
      value = maximumVal
      const input = event.target as HTMLInputElement
      input.value = String(maximumVal)
    }
    this.onChange({ target: { value } })
    this.modelChange.emit(value)
  }
}
