<div class="app-container">
  <page-header [icon]="'far-shopping-cart'" [title]="'purchase.orders'">
    <ng-container class="header-button">
      <btn
        *ngIf="canCreate"
        [routerLink]="['/purchase-order-creation']"
        [label]="'order.create'"
        [title]="'order.create' | translate"
        [classes]="['btn-first', 'float-right']"
        [icon]="'far-plus'"
        data-cy="create-purchase-order"
      ></btn>
      <modal-purchase-orders-import (onPurchaseOrdersImported)="filter(1)"></modal-purchase-orders-import>
    </ng-container>
  </page-header>

  <section *ngIf="!userHavePermissionForThisPage" class="content max-width-80">
    <loading-spinner [isLoading]="loading"></loading-spinner>
    {{ 'purchasePrice.textForUsersWithoutPermission' | translate }}
  </section>

  <section class="content" *ngIf="userHavePermissionForThisPage">
    <app-filter-form
      [isExportButtonShown]="false"
      [filters]="queryFilters"
      [loading]="loading || reloading"
      [entity]="'purchaseOrders'"
      [filterVisible]="filtersExpanded"
      (onSubmit)="filter()"
      (onClearClick)="clearFilters()"
      (onExportResult)="filter()"
      (filterVisibleChange)="filtersExpanded = $event; changeDetector.detectChanges()"
    >
      <div class="container-fluid ps-0 pe-0">
        <!--      FIRST ROW OF INPUTS-->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <form-row-input
              [label]="'fulltext'"
              [icon]="null"
              [(model)]="filters.fulltext"
              (keyup.enter)="filter()"
              data-cy="order-name-filter"
            ></form-row-input>
          </div>
          <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <form-row-input
              [label]="'dateCreated.from'"
              [icon]="null"
              [inputType]="'date'"
              [(model)]="filters.dateCreatedFrom"
            ></form-row-input>
          </div>
          <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <form-row-input
              [label]="'dateCreated.to'"
              [icon]="null"
              [inputType]="'date'"
              [(model)]="filters.dateCreatedTo"
            ></form-row-input>
          </div>
          <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <form-row-select
              [label]="'products.suppliers'"
              [icon]="null"
              [(model)]="filters.supplier"
              [options]="suppliers"
              data-cy="order-process-filter"
            ></form-row-select>
          </div>
          <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
            <form-row-select
              [label]="'purchase.depot-to'"
              [icon]="null"
              [(model)]="filters.depot"
              [options]="depots"
              data-cy="order-process-filter"
            ></form-row-select>
          </div>
        </div>
        <!--      SECOND ROW OF INPUTS-->
        <div class="custom-dropdown" [ngClass]="{ open: filtersExpanded }">
          <div class="row">
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'dateEstimatedArrivalFrom'"
                [icon]="null"
                [inputType]="'date'"
                [(model)]="filters.dateEstimatedArrivalFrom"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-input
                [label]="'dateEstimatedArrivalTo'"
                [icon]="null"
                [inputType]="'date'"
                [(model)]="filters.dateEstimatedArrivalTo"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-select-multiple
                [label]="'tags'"
                [options]="tags"
                [(selectedValues)]="filters.tags"
              ></form-row-select-multiple>
            </div>
            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <form-row-select
                [label]="'status'"
                [options]="statusOptions"
                [(ngModel)]="filters.status"
              ></form-row-select>
            </div>
          </div>
        </div>
      </div>
    </app-filter-form>
    <app-base-listing
      #baseListingComponent
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="purchaseOrders"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [(sortProp)]="sortProp"
      [(sortDir)]="sortDir"
      [listName]="'purchaseOrders'"
      (reloadList)="filter()"
      (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
      (callAction)="callAction($event)"
    ></app-base-listing>
  </section>
</div>
