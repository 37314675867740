<modal-container #modal [headerTitle]="'consent.add'" [headerIconClass]="'far-file'">
  <ng-container class="modal-button-show">
    <btn
      [label]="'consent.add'"
      [title]="'consent.add' | translate"
      [classes]="['btn-second', 'btn-sm', 'float-right']"
      [icon]="'far-plus'"
      (onEvent)="showChildModal()"
    ></btn>
  </ng-container>
  <ng-container class="modal-body-content">
    <div class="card">
      <form-row-select
        [icon]="null"
        [label]="null"
        [options]="consents"
        [(model)]="selectedConsent"
        [btnLabel]="'add'"
        [btnClasses]="['btn-first h-100 ms-2']"
        (onBtnEvent)="createCustomerConsent()"
      ></form-row-select>
    </div>
  </ng-container>
</modal-container>
