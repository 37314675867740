import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Voucher } from 'depoto-core/src/entities'
import { CoreService } from '../../services'
import { SchemaUtil, historyBack } from '../../utils'
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, FormRowBase } from '../../components/_common/form-row-base/form-row-base'
import { NotificationService } from '../../services'
import { TranslateService } from '@ngx-translate/core'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR(VoucherComponent)],
})
export class VoucherComponent extends FormRowBase implements OnInit, CheckDataChanges {
  public loading = true
  public isCreating: boolean
  public voucher: Voucher
  submitted = false
  discountTypeOptions = [
    { id: 'percentage', name: 'voucher.discountType.percentage' },
    { id: 'value', name: 'voucher.discountType.value' },
  ]

  voucherForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, [Validators.required]),
    code: new FormControl({ value: '', disabled: false }, [Validators.required]),
    discountType: new FormControl({ value: '', disabled: false }, [Validators.required]),
    discountPercent: new FormControl({ value: 0, disabled: false }, []),
    discountValue: new FormControl({ value: 0, disabled: false }, [Validators.pattern('^[0-9]*$')]),
    maxUse: new FormControl({ value: 0, disabled: false }, [Validators.required, Validators.pattern('^[0-9]*$')]),
    validFrom: new FormControl({ value: '', disabled: false }, [Validators.required]),
    validTo: new FormControl({ value: '', disabled: false }, [Validators.required]),
    enabled: new FormControl({ value: false, disabled: false }, []),
    isPayment: new FormControl({ value: false, disabled: false }, []),
    externalId: new FormControl({ value: '', disabled: false }, []),
  })

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {
    super()
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
  }

  async ngOnInit() {
    this.loading = true
    const id = this.route.snapshot.params['id']
    if (isNaN(id)) {
      this.isCreating = true
      this.voucher = new Voucher()
    } else {
      this.isCreating = false
      await this._getVoucher(id)
    }
    this.loading = false
  }

  public fillFormValue() {
    this.voucherForm.setValue({
      name: this.voucher.name || '',
      code: this.voucher.code || '',
      discountType: this.voucher.discountType || '',
      discountPercent: this.voucher.discountPercent || 0,
      discountValue: this.voucher.discountValue || 0,
      maxUse: this.voucher.maxUse || 0,
      validFrom: this.voucher.validFrom || '',
      validTo: this.voucher.validTo || '',
      enabled: this.voucher.enabled || false,
      isPayment: this.voucher.isPayment || false,
      externalId: this.voucher.externalId || '',
    })
  }

  public getFormValue() {
    this.voucher.name = this.voucherForm.value.name
    this.voucher.code = this.voucherForm.value.code
    this.voucher.discountType = this.voucherForm.value.discountType
    this.voucher.discountPercent = this.voucherForm.value.discountPercent
    this.voucher.discountValue = this.voucherForm.value.discountValue
    this.voucher.maxUse = this.voucherForm.value.maxUse
    this.voucher.validFrom = this.voucherForm.value.validFrom
    this.voucher.validTo = this.voucherForm.value.validTo
    this.voucher.enabled = this.voucherForm.value.enabled
    this.voucher.isPayment = this.voucherForm.value.isPayment
    this.voucher.externalId = this.voucherForm.value.externalId
  }

  setMinMaxValidation() {
    if (this.voucherForm.value.discountType === 'percent') {
      this.voucherForm.controls['discountPercent'].setValidators([Validators.max(100), Validators.min(1)])
    } else {
      this.voucherForm.controls['discountPercent'].clearValidators()
    }
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  goBack(): void {
    historyBack()
  }

  private async _getVoucher(id: number) {
    this.voucher = new Voucher(await this.core.services.voucher.getById(id, SchemaUtil.voucher))
    if (id && id > 0) {
      this.convertToDatetimeInputFormat()
      await this.fillFormValue()
    }
  }

  public async deleteVoucher() {
    try {
      await this.core.services.voucher.delete(this.voucher).then(() => {
        this.notificationService.success(this.translateService.instant('alert.voucher.deleted'))
        this.router.navigate(['vouchers'], { replaceUrl: true })
      })
    } catch (err) {
      console.error(err)
    }
  }

  public async createVoucher() {
    this.submitted = true
    this.setMinMaxValidation()
    if (this.voucherForm.invalid) {
      return
    }
    this.loading = true
    try {
      this.getFormValue()
      this.convertToServerFormat()
      const voucher = await this.core.services.voucher.create(this.voucher, SchemaUtil.voucher)
      if (voucher) {
        await this.router.navigate(['/voucher', voucher.id], { replaceUrl: true })
        this.notificationService.success(this.translateService.instant('alert.voucher.created'))
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }

  public async updateVoucher() {
    this.submitted = true
    this.setMinMaxValidation()
    if (this.voucherForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValue()
      this.convertToServerFormat()
      await this.core.services.voucher.update(this.voucher, SchemaUtil.voucher).then(result => {
        this.notificationService.success(this.translateService.instant('alert.voucher.saved'))
        this.convertToDatetimeInputFormat()
        this.router.navigate(['/voucher', result.id])
      })
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
    }
  }

  private convertToDatetimeInputFormat() {
    if (this.voucher.validFrom && !this.voucher.validFrom.includes('T')) {
      this.voucher.validFrom = this.voucher.validFrom.replace(' ', 'T')
    }
    if (this.voucher.validTo && !this.voucher.validTo.includes('T')) {
      this.voucher.validTo = this.voucher.validTo.replace(' ', 'T')
    }
  }

  private convertToServerFormat() {
    if (this.voucher.validFrom !== null && this.voucher.validFrom.includes('T')) {
      this.voucher.validFrom = this.voucher.validFrom.replace('T', ' ')
    }
    if (this.voucher.validTo !== null && this.voucher.validTo.includes('T')) {
      this.voucher.validTo = this.voucher.validTo.replace('T', ' ')
    }
  }
  get formControls(): { [key: string]: AbstractControl } {
    return this.voucherForm.controls
  }

  minMaxValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value > 100 || control.value < 0) {
      return { discountPercent: true }
    }
    return null
  }

  get isDiscountPercentInvalid(): boolean {
    return this.voucherForm.controls['discountPercent'].invalid
  }
}
