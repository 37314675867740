import { Component, ViewChild, Input } from '@angular/core'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { NotificationService } from '../../../services'
import { CoreService } from '../../../services'
import { User } from 'depoto-core/src/entities'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-modal-change-pass',
  templateUrl: './modal-change-pass.component.html',
})
export class ModalChangePassComponent {
  @ViewChild('modal') childModal: ModalContainerComponent
  @Input() userProfile: User
  user: User
  plainPass = ''
  plainPass2 = ''
  loading = true

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private core: CoreService
  ) {}

  async setCurrentUser() {
    if (this.userProfile) {
      this.user = this.userProfile
      this.loading = false
      return
    }
    const userProfile = await this.core.services.user.getMyProfile({
      id: null,
      company: null,
      email: null,
      plainPassword: null,
    })
    if (userProfile) {
      this.user = new User(userProfile)
      this.core.services.storage.set(this.core.services.storage.keys.auth.company, this.user.company)
      await this.core.services.user.setCurrentUser(this.user.email)
      this.loading = false
    }
  }

  async changePass() {
    if (this.plainPass === this.plainPass2) {
      if (this.plainPass.length > 3) {
        this.user.plainPassword = this.plainPass
        await this.core.services.user
          .update(this.user)
          .then(res => {
            this.notificationService.success(this.translateService.instant('alert.password.changed'))
            this.hideChildModal()
          })
          .catch(err => console.error(err))
      } else {
        this.notificationService.warning('Heslo musí obsahovat alespoň 4 znaky.')
      }
    } else {
      this.notificationService.warning('Hesla musí být stejná.')
    }
  }

  public async showChildModal() {
    await this.setCurrentUser()
    this.childModal.showChildModal()
  }

  public hideChildModal(): void {
    this.childModal.hideChildModal()
  }

  generatePassword() {
    this.plainPass = this.plainPass2 = this.randomPassword()
  }

  randomPassword() {
    return Math.random().toString(36).slice(2) + Math.random().toString(36).toUpperCase().slice(2)
  }
}
