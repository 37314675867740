import { Pipe, PipeTransform } from '@angular/core'
import { ProductMove } from 'depoto-core/src/entities'

@Pipe({
  name: 'operationsPriceSum',
  pure: false,
})
export class OperationsPriceSumPipe implements PipeTransform {
  transform(value: ProductMove[], args: string[]): any {
    if (!value || (value && value.length < 1)) {
      return '0'
    }
    let resArr: any[] = []
    switch (args[0]) {
      case 'sell':
        resArr = value.map(item => {
          return item.sellPriceSum
        })
        break
      case 'purchase':
        resArr = value.map(item => {
          return item.purchasePriceSum
        })
        break
      case 'quantity':
        return value
          .map(item => {
            return item.amount
          })
          .reduce((sum, amount) => {
            return Number(sum) + Number(amount)
          })
    }
    const sum =
      resArr && resArr.length
        ? Number(
            resArr.reduce((sum, amount) => {
              return Number(sum) + Number(amount)
            })
          ).toFixed(2)
        : '0.00'
    return Number(sum)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }
}
