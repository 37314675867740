<div #formRow class="form-group" [ngClass]="{ 'mb-0': noMarginBottom }">
  <label *ngIf="label && label.length && !isHsCode" class="d-flex justify-content-between">
    {{ label | translate }}
    <info-tooltip
      *ngIf="tooltipLabel && tooltipLabel.length > 0"
      [label]="tooltipLabel"
      [icon]="tooltipIcon"
    ></info-tooltip>
    <label class="float-right required" *ngIf="required"> ({{ 'required' | translate }}) </label>
  </label>

  <label class="d-flex justify-content-between" *ngIf="isHsCode">
    <label *ngIf="label && label.length">
      {{ label | translate }}
    </label>
    <label class="float-right hs-code">
      <a href="https://www.tariffnumber.com/" target="_blank">
        {{ 'number.hsCode' | translate }}
        <i class="far fa-external-link"></i>
      </a>
    </label>
  </label>
  <div class="input-group">
    <input
      [ngClass]="{
        'login-input': isLoginInput,
        'is-invalid': requiredCssClass,
        is_pristine: control && control.pristine,
        is_valid: control && !control.pristine && control.valid,
        is_invalid: control && !control.pristine && !control.valid
      }"
      class="form-control"
      [type]="inputType"
      [pattern]="pattern"
      *ngIf="inputDisabled"
      [class]="classesString"
      [ngModel]="model"
      [attr.data-cy]="dataCyAttribute"
      [autocomplete]="false"
      disabled
    />
    <input
      #formRowInput
      [ngClass]="{
        'login-input': isLoginInput,
        'is-invalid': requiredCssClass,
        is_pristine: control && control.pristine,
        is_valid: control && !control.pristine && control.valid,
        is_invalid: control && !control.pristine && !control.valid
      }"
      class="form-control"
      [type]="inputType"
      [pattern]="pattern"
      *ngIf="!inputDisabled && !translateBarcode && !replaceComma && !inputMultiple"
      [class]="classesString"
      [ngModel]="model"
      (ngModelChange)="onNgModelChange($event, maximumVal)"
      (keyup)="onInputKeyPress($event)"
      (change)="onInputValueChange($event)"
      (focus)="onFocus(true)"
      (blur)="onFocus(false)"
      [attr.data-track-category]="trackCategory"
      [attr.data-track-action]="trackAction"
      [required]="inputRequired"
      [min]="minimumVal"
      [max]="maximumVal"
      [attr.name]="elementName"
      [placeholder]="(placeholder | translate) ?? ''"
      [attr.data-cy]="dataCyAttribute"
      [autocomplete]="false"
    />
    <input
      [ngClass]="{
        'login-input': isLoginInput,
        'is-invalid': requiredCssClass,
        is_pristine: control && control.pristine,
        is_valid: control && !control.pristine && control.valid,
        is_invalid: control && !control.pristine && !control.valid
      }"
      class="form-control"
      [type]="inputType"
      [pattern]="pattern"
      *ngIf="!inputDisabled && translateBarcode && !replaceComma && !inputMultiple"
      translateBarcode
      [class]="classesString"
      [ngModel]="model"
      (ngModelChange)="onNgModelChange($event)"
      (keyup)="onInputKeyPress($event)"
      (focus)="onFocus(true)"
      (blur)="onFocus(false)"
      [attr.data-track-category]="trackCategory"
      [attr.data-track-action]="trackAction"
      [required]="inputRequired"
      [min]="minimumVal"
      [max]="maximumVal"
      [attr.name]="elementName"
      [attr.data-cy]="dataCyAttribute"
      [autocomplete]="false"
    />
    <input
      [ngClass]="{
        'login-input': isLoginInput,
        'is-invalid': requiredCssClass,
        is_pristine: control && control.pristine,
        is_valid: control && !control.pristine && control.valid,
        is_invalid: control && !control.pristine && !control.valid
      }"
      class="form-control"
      [type]="inputType"
      [pattern]="pattern"
      *ngIf="!inputDisabled && !translateBarcode && replaceComma && !inputMultiple"
      replaceComma
      [class]="classesString"
      [ngModel]="model"
      (ngModelChange)="onNgModelChange($event)"
      (keyup)="onInputKeyPress($event)"
      (focus)="onFocus(true)"
      (blur)="onFocus(false)"
      [attr.data-track-category]="trackCategory"
      [attr.data-track-action]="trackAction"
      [required]="inputRequired"
      [min]="minimumVal"
      [max]="maximumVal"
      [attr.name]="elementName"
      [attr.data-cy]="dataCyAttribute"
      [autocomplete]="false"
    />
    <input
      [ngClass]="{
        'login-input': isLoginInput,
        'is-invalid': requiredCssClass,
        is_pristine: control && control.pristine,
        is_valid: control && !control.pristine && control.valid,
        is_invalid: control && !control.pristine && !control.valid
      }"
      class="form-control"
      [type]="inputType"
      [pattern]="pattern"
      *ngIf="!inputDisabled && translateBarcode && replaceComma && !inputMultiple"
      translateBarcode
      replaceComma
      [class]="classesString"
      [ngModel]="model"
      (ngModelChange)="onNgModelChange($event)"
      (keyup)="onInputKeyPress($event)"
      (focus)="onFocus(true)"
      (blur)="onFocus(false)"
      [attr.data-track-category]="trackCategory"
      [attr.data-track-action]="trackAction"
      [required]="inputRequired"
      [min]="minimumVal"
      [max]="maximumVal"
      [attr.name]="elementName"
      [attr.data-cy]="dataCyAttribute"
      [autocomplete]="false"
    />
    <input
      [ngClass]="{
        'login-input': isLoginInput,
        'is-invalid': requiredCssClass,
        is_pristine: control && control.pristine,
        is_valid: control && !control.pristine && control.valid,
        is_invalid: control && !control.pristine && !control.valid
      }"
      class="form-control"
      [type]="inputType"
      [pattern]="pattern"
      *ngIf="!inputDisabled && !translateBarcode && !replaceComma && inputMultiple"
      multiple
      [class]="classesString"
      [ngModel]="model"
      (ngModelChange)="onNgModelChange($event)"
      (keyup)="onInputKeyPress($event)"
      (change)="onInputValueChange($event)"
      (focus)="onFocus(true)"
      (blur)="onFocus(false)"
      [attr.data-track-category]="trackCategory"
      [attr.data-track-action]="trackAction"
      [required]="inputRequired"
      [min]="minimumVal"
      [max]="maximumVal"
      [attr.name]="elementName"
      [attr.data-cy]="dataCyAttribute"
      [autocomplete]="false"
    />
    <ng-content></ng-content>
    <span class="input-group-unit my-auto ms-1 me-1" *ngIf="appendUnitCm"><strong>cm</strong></span>
    <span class="input-group-unit my-auto ms-1 me-1" *ngIf="appendUnitKg"><strong>kg</strong></span>
    <span class="input-group-unit my-auto ms-1 me-1" *ngIf="appendUnitCurrency"
      ><strong>{{ currency | formatCurrencyWithoutPrice }}</strong></span
    >
    <span class="input-group-unit my-auto ms-1 me-1" *ngIf="appendUnitPercent"><strong>%</strong></span>
    <span class="input-group-unit my-auto ms-1 me-1" *ngIf="appendUnitCustom?.length"
      ><strong>{{ appendUnitCustom }}</strong></span
    >
    <div
      class="input-group-append"
      *ngIf="(appendBtnLabel && appendBtnLabel.length > 0) || (appendBtnIcon && appendBtnIcon.length > 0)"
    >
      <btn
        [label]="appendBtnLabel"
        [classes]="appendBtnClasses"
        [icon]="appendBtnIcon"
        [title]="appendBtnTitle"
        [dataCyAttribute]="'append-btn'"
        (onEvent)="onBtnClick($event)"
      ></btn>
    </div>
    <div class="input-group-append" *ngIf="appendCheckboxValue !== null && appendCheckboxShow">
      <div class="input-group-text">
        <label class="mb-0">
          <input
            type="checkbox"
            class="cursor-pointer"
            [ngModel]="appendCheckboxValue"
            (ngModelChange)="onAppendedCheckboxChange($event)"
            [attr.data-cy]="dataCyAttribute"
          />
          {{ appendCheckboxLabel }}
        </label>
      </div>
    </div>
    <div class="input-group-append d-flex align-items-center" *ngIf="appendCheckboxValue !== null && appendToggleShow">
      <div class="custom-control custom-switch flex-gap-5 ms-2">
        <input
          type="checkbox"
          class="custom-control-input"
          [id]="label + '_toggle'"
          tabindex="0"
          [ngModel]="appendCheckboxValue"
          (ngModelChange)="onAppendedCheckboxChange($event)"
          [attr.data-track-category]="trackCategory"
          [attr.data-track-action]="trackAction"
          [attr.data-cy]="dataCyAttributeAppendToggle"
        />
        <label class="custom-control-label" [for]="label + '_toggle'" data-cy="toggle-in-input"> </label>
      </div>
    </div>
    <div
      *ngIf="requiredCssClass"
      [ngClass]="{ 'invalid-feedback': requiredCssClass }"
      style="color: #f0142f"
      data-cy="error_msg"
    >
      {{ errors | formError: formControlName | translate }}
    </div>
  </div>
  <div *ngIf="showMessageMaxValue && label === 'quantity'" class="text-start">
    {{ 'form-row-input.max-value-message' | translate: { quantity: maximumVal } }}
  </div>
</div>
