<div class="dropdown">
  <div
    *ngIf="user && user.email && user.email.length > 0"
    class="nav-settings"
    data-bs-toggle="dropdown"
    data-cy="user-box"
  >
    <div class="flex-gap-10 align-items-center">
      <div>
        <div class="icon-circle">
          <span class="icon">
            <i class="far fa-user"></i>
          </span>
        </div>
      </div>
      <div class="user-email">
        {{ user.email }}
      </div>
      <div style="width: 14px">
        <i [ngClass]="{ hide: isHidden }" class="far fa-chevron-down"></i>
        <i [ngClass]="{ hide: !isHidden }" class="far fa-times"></i>
      </div>
    </div>
  </div>
  <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" *ngIf="user && user.email && user.email.length > 0">
    <div class="dropdown-item">
      <btn
        [icon]="'far-user'"
        [routerLink]="['/my', 'profile']"
        [label]="'user.profile'"
        [title]="'user.profile' | translate"
        [classes]="['btn-third', 'me-3']"
        [dataCyAttribute]="'profile'"
      ></btn>
      <btn
        [icon]="'far-lock'"
        (onEvent)="logout()"
        [label]="'user.logout'"
        [title]="'user.logout' | translate"
        [classes]="['btn-third']"
        [dataCyAttribute]="'sign-out'"
      ></btn>
      <div class="text-center">
        <a class="version-link" href="//depoto.cz/docs/client-changelog.html" target="_blank"
          >{{ 'version' | translate }}: {{ appVersion }}</a
        >
      </div>
    </div>
  </div>
</div>
