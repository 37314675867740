<div class="app-container">
  <page-header [icon]="'far-file'" [title]="'consents'">
    <ng-container class="header-button">
      <btn
        [icon]="'far-plus'"
        [label]="'consent.new'"
        [title]="'consent.new' | translate"
        [classes]="['btn-first', 'float-right']"
        [routerLink]="['/consent']"
        [dataCyAttribute]="'go-consent'"
      ></btn>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form
      [filters]="filters"
      [entity]="'consents'"
      (onSubmit)="filter()"
      (onClearClick)="clearFilters()"
      [isFilterInvisible]="true"
    >
      <form-row-input
        [(model)]="filters.name"
        (keydown.enter)="filter()"
        [label]="'consent.name'"
        [icon]="'far-file'"
        [dataCyAttribute]="'consent-name-filter'"
      ></form-row-input>
    </app-filter-form>

    <app-base-listing
      [listName]="'consents'"
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="consents"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [(sortProp)]="sortProp"
      [(sortDir)]="sortDir"
      (reloadList)="filter(1)"
      (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
      (callAction)="callAction($event)"
    ></app-base-listing>
  </section>
</div>
