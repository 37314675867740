import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Customer } from 'depoto-core/src/entities'
import { CoreService } from '../../services'
import { OrderDetailService } from '../../services/order-detail.service'
import { Router } from '@angular/router'
import { SchemaUtil } from '../../utils'

const schema = {
  id: null,
  companyName: null,
  firstName: null,
  lastName: null,
  name: null,
  email: null,
}

@Component({
  selector: 'customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss'],
})
export class CustomerSearchComponent implements OnInit {
  @Input() isInModal = false
  @Input() isOnOrdersPage = false
  @Output() customerChange: EventEmitter<any> = new EventEmitter()
  public searchPhrase = ''
  public customers: Customer[] = []
  public hasRoleSalesman: boolean
  public searchInAllCustomers = true
  public pageNumber = 1
  public maxPageNumber = 999
  public isLoading = false
  private currentUserId

  constructor(
    private core: CoreService,
    public orderDetailService: OrderDetailService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.hasRoleSalesman = this.core.services.user.hasRole('ROLE_SALESMAN')
    if (!this.isInModal) {
      this.searchForCustomer(1)
    }

    setTimeout(() => {
      const el = document.getElementById('customer-search-btn-next')
      if (!this.isInModal && el) {
        const observer = new IntersectionObserver(
          entries => {
            if (entries[0].isIntersecting === true && this.customers.length > 19) {
              this.searchForCustomer(1)
            }
          },
          { threshold: [0] }
        )
        observer.observe(el)
      }
    }, 300)
  }

  public async searchForCustomer(addPage?: number) {
    if (addPage) {
      this.pageNumber += addPage
    } else {
      this.pageNumber = 1
    }
    this.isLoading = true
    let customers: any[]
    if (this.searchInAllCustomers) {
      customers = await this.core.services.customer
        .getList({ page: this.pageNumber, filters: { fulltext: this.searchPhrase } }, schema)
        .then(result => {
          this.maxPageNumber = result.paginator.endPage
          return result.items
        })
    } else {
      if (!this.currentUserId) {
        this.currentUserId = this.core.services.user.user.id
      }
      customers = await this.core.services.customer
        .getList({ page: this.pageNumber, filters: { fulltext: this.searchPhrase, user: this.currentUserId } }, schema)
        .then(result => {
          this.maxPageNumber = result.paginator.endPage
          return result.items
        })
    }
    if (this.pageNumber === 1) {
      this.customers = customers
    } else {
      this.customers = [...this.customers, ...customers]
    }
    this.isLoading = false
  }

  public async selectCustomer(customer: Customer) {
    if (!this.isOnOrdersPage) {
      try {
        await this.core.services.customer
          .getById(customer.id, { id: null, addresses: SchemaUtil.address })
          .then(result => {
            customer.addresses = result.addresses
            if (customer.addresses.filter(a => !!a.isBilling)?.length === 1) {
              this.orderDetailService.order.invoiceAddress = customer.addresses.find(a => !!a.isBilling)
            }
          })
      } catch (err) {
        console.error(err)
      }
      this.orderDetailService.order.customer = customer
    }
    if (this.isInModal) {
      this.customerChange.emit(customer)
    } else {
      if (
        customer.users &&
        customer.users.length &&
        customer.users[0].checkouts &&
        customer.users[0].checkouts.length
      ) {
        this.orderDetailService.order.checkout = customer.users[0].checkouts[0]
      }
      this.orderDetailService.activeLink = 'address'
      this.router.navigate(['/order/address'])
    }
  }

  public trackById(index: number, value: Customer): number {
    return value.id
  }

  getTagNames(customer: Customer): string {
    return customer.tags && customer.tags.length ? customer.tags.map(t => t.name).join(', ') : ''
  }
}
