import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Carrier, Order, Payment, PaymentItem } from 'depoto-core/src/entities'
import { CoreService } from '../../../services'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-order-payments',
  templateUrl: './order-payments.component.html',
  styleUrls: ['./order-payments.component.scss'],
})
export class OrderPaymentsComponent {
  @Input() canCreateOrEdit = false
  @Input() order: Order
  @Output() orderChange: EventEmitter<any> = new EventEmitter()
  @Input() payments: Payment[] = []
  @Input() carriers: Carrier[] = []

  constructor(
    private core: CoreService,
    private translateService: TranslateService
  ) {}

  public selectedCarrier(carrier: Carrier) {
    this.order.carrier = carrier
    this.orderChange.emit(this.order)
  }

  async deletePaymentItem(paymentItem: PaymentItem) {
    if (!confirm(this.translateService.instant('payment.item.delete.confirmation'))) {
      return
    }
    try {
      if (paymentItem.id) {
        await this.core.services.order.deletePaymentItem(paymentItem)
        this.order.paymentItems = this.order.paymentItems.filter(item => item.id !== paymentItem.id)
      } else {
        this.order.paymentItems = this.order.paymentItems.filter(
          item => item.id && item.amount !== paymentItem.amount && item.payment.id !== paymentItem.payment.id
        )
      }
      this.orderChange.emit(this.order)
    } catch (err) {
      console.error(err)
    }
  }

  addPaymentItem(): void {
    if (!this.order.paymentItems) {
      this.order.paymentItems = []
    }
    this.order.paymentItems.push(
      new PaymentItem({
        amount: this.calculatePayment(),
        payment: new Payment(),
      })
    )
    this.orderChange.emit(this.order)
  }

  public capitalizeFirstLetter(str: string): string {
    if (str && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    } else {
      return ''
    }
  }

  public calculatePayment(): number {
    const prices = this.order.items.map(it => it.priceAll) || []
    const priceAll = prices && prices.length > 0 ? prices.reduce((a, b) => Number(a) + Number(b)) : 0
    const payments = this.order.paymentItems.map(pi => pi.amount) || []
    const paymentsAll = payments && payments.length > 0 ? payments.reduce((a, b) => Number(a) + Number(b)) : 0
    return priceAll - paymentsAll
  }
}
