<div class="container-fluid">
  <div class="row">
    <div class="banner-wrapper d-none d-sm-none d-md-block col-md-4 col-lg-4 col-4-xl" data-cy="left-side">
      <div class="banner slideInLeft">
        <div class="row">
          <div class="col-12 heading-white">Online</div>
          <div class="col-12 heading-black">skladový</div>
          <div class="col-12 heading-black">systém</div>
        </div>
        <div class="d-flex flex-column">
          <img class="depoto-img" src="../../../assets/img/dt-client_login-svg.svg" alt="login image" />

          <div class="flex-direction-column-gap-10">
            <img class="banner-logo" src="../../../assets/img/group567.svg" alt="client logo" />
            <div class="banner-copyright">&copy; {{year}} DEPOTO s.r.o.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="slideInRight col-12 col-md-8 col-lg-8 col-8-xl p-0">
      <!--      todo add languages to tests-->
      <div class="language-select-div">
        <form-row-select
                [model]="selectedLanguage"
                (modelChange)="setLanguage($event)"
                [options]="availableLanguages"
                [dataCyAttribute]="'login.language'"
        ></form-row-select>
      </div>
      <div class="wrapper">
        <div class="card mb-0">
          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="row">
              <div class="col-12 d-flex justify-content-between">
                <div class="login">
                  {{ 'login.msg' | translate }}
                </div>
                <img src="../../../assets/img/group559.svg" alt="depoto logo" width="151.069" height="31.418" />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <form-row-input
                  [isLoginInput]="true"
                  [label]="'login.username'"
                  [inputType]="'email'"
                  [formGroup]="loginForm"
                  formControlName="email"
                  [required]="loginForm.get('email').errors !== null && loginForm.get('email').errors.required"
                  [requiredCssClass]="submitted && formControls.email.errors"
                  [elementName]="'username'"
                  [dataCyAttribute]="'username'"
                ></form-row-input>
                <form-row-input
                  [isLoginInput]="true"
                  [label]="'login.password'"
                  [icon]="null"
                  [formGroup]="loginForm"
                  formControlName="password"
                  [required]="loginForm.get('password').errors !== null && loginForm.get('password').errors.required"
                  [requiredCssClass]="submitted && formControls.password.errors"
                  [elementName]="'password'"
                  [inputType]="showPassword ? 'text' : 'password'"
                  [appendBtnIcon]="showPassword ? 'far-eye-slash' : 'far-eye'"
                  [appendBtnClasses]="['btn-input']"
                  (onAppendBtnEvent)="toggleShowPassword()"
                  [dataCyAttribute]="'password'"
                ></form-row-input>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 d-flex justify-content-between">
                <form-row-toggle [label]="'login.remember'" formControlName="rememberMe"></form-row-toggle>
                <div class="text-settings d-flex align-items-center">
                  <a
                    (click)="isShowingAdvanced = !isShowingAdvanced"
                    class="cursor-pointer"
                    data-cy="advanced-settings"
                  >
                    {{ 'login.advanced' | translate }}
                    <i [ngClass]="{ rotate: isShowingAdvanced }" class="fa fa-chevron-down closed"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="row dropdown mt-2" [ngClass]="{ open: isShowingAdvanced }">
              <div class="col-12">
                <form-row-select
                  [label]="'login.server'"
                  formControlName="clientType"
                  [options]="client_type_options"
                  [dataCyAttribute]="'server-select'"
                ></form-row-select>
                <form-row-input
                  [label]="'Server URL'"
                  formControlName="customUrl"
                  (modelChange)="setCustomServerUrl()"
                  *ngIf="loginForm.value.clientType === 'custom'"
                ></form-row-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <btn
                  [label]="'login.btn'"
                  [title]="'login.btn' | translate"
                  [icon]="'far-sign-in-alt'"
                  [classes]="['btn-first', 'col-12', 'login-btn']"
                  [btnType]="'submit'"
                  [isLoading]="loading"
                  [dataCyAttribute]="'submit'"
                ></btn>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
