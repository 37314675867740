import { Component, OnInit } from '@angular/core'
import { ListingItem } from 'depoto-core/src/models'
import { BaseListing } from '../_base-listing/base-listing'
import { Tag } from 'depoto-core/src/entities'
import { CoreService } from '../../services'
import { tagsColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  name: '',
  type: 'all',
}

const tagSchema = {
  id: null,
  name: null,
  type: null,
  color: null,
}

@Component({
  selector: 'tags',
  templateUrl: './tags.component.html',
})
export class TagsComponent extends BaseListing implements OnInit {
  listingColumns: ListingItem[] = tagsColumns

  public tags: Tag[]
  public filters = { ...DEFAULT_FILTERS }
  public tagTypes: Array<string> = []
  public currentPage = 1
  public totalItems = 0
  public endPage = 1
  public loading = true
  storageKey: { type: string; key: string }
  sortProp = 'name'
  sortDir = 'asc'

  constructor(protected core: CoreService) {
    super(core)
    this.filters = { ...DEFAULT_FILTERS }
    this.getStorageKey()
  }

  async ngOnInit() {
    const types = await this.core.services.tag.getTypes({}, { id: null })
    if (Array.isArray(types?.items)) {
      this.tagTypes = ['all'].concat(types.items.map(item => item.id))
    }
    this.filters = this.loadViewSettings('tags') || { ...DEFAULT_FILTERS }
    this.loadSorting('tags')
    await this.filter()
  }

  public async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.currentPage = pageNo
    try {
      const receivedData = await this.core.services.tag.getList(
        {
          page: this.currentPage,
          sort: sortProp,
          direction: sortDir,
          filters: this.queryFilters,
        },
        tagSchema
      )
      if (!receivedData?.items) {
        return
      }
      if (!concat) {
        this.tags = receivedData.items
      } else {
        receivedData.items.forEach(tag => {
          this.tags.push(new Tag(tag))
        })
      }
      this.totalItems = receivedData.paginator ? receivedData.paginator.totalCount : 0
      this.endPage = receivedData.paginator ? receivedData.paginator.endPage : 0
      this.saveViewSettings('tags', this.filters)
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }

  get queryFilters() {
    const filtersWithValues = {}
    if (this.filters.name?.length) {
      filtersWithValues['name'] = this.filters.name
    }
    if (this.filters.type !== 'all') {
      filtersWithValues['type'] = this.filters.type
    }
    return filtersWithValues
  }

  public clearFilters() {
    this.filters = { ...DEFAULT_FILTERS }
    this.filter()
  }
}
