import { Component, OnInit } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { CoreService } from '../../services'
import { Consent } from 'depoto-core/src/entities'
import { consentsColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  name: '',
}

@Component({
  selector: 'app-consents',
  templateUrl: 'consents.component.html',
})
export class ConsentsComponent extends BaseListing implements OnInit {
  listingColumns: ListingItem[] = consentsColumns
  consents: Consent[] = []
  totalItems = 0
  currentPage: number
  endPage: number
  filters = { ...DEFAULT_FILTERS }
  loading = true
  storageKey: { type: string; key: string }
  sortProp = 'name'
  sortDir = 'asc'

  constructor(protected core: CoreService) {
    super(core)
    this.getStorageKey()
  }

  ngOnInit() {
    this.filters = this.loadViewSettings('consents') || { ...DEFAULT_FILTERS }
    this.loadSorting('consents')
    this.filter()
  }

  clearFilters(): void {
    this.filters = { ...DEFAULT_FILTERS }
    this.filter(1)
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.currentPage = pageNo
    if (!concat) {
      this.reloading = true
    }
    try {
      const result = await this.core.services.consent.getList(
        {
          page: pageNo,
          sort: sortProp,
          direction: sortDir,
          filters: this.filters,
        },
        {
          id: null,
          name: null,
          externalId: null,
          created: null,
          updated: null,
        }
      )
      if (!result?.items) return
      concat
        ? result.items.forEach(consent => this.consents.push(new Consent(consent)))
        : (this.consents = result.items)
      this.totalItems = result.paginator ? result.paginator.totalCount : 0
      this.endPage = result.paginator ? result.paginator.endPage : 0
      this.currentPage++
      this.saveViewSettings('consents', this.filters)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }
}
