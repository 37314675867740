import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { Order } from 'depoto-core/src/entities'
import { CoreService } from '../../../services'
import { SchemaUtil, sortOrderItems } from '../../../utils'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-modal-move-order-items',
  templateUrl: './modal-move-order-items.component.html',
  styleUrls: ['./modal-move-order-items.component.scss'],
})
export class ModalMoveOrderItemsComponent implements OnInit {
  @ViewChild('modal') childModal: ModalContainerComponent
  @Input() order: Order
  @Input() itemsFromParent: any = []
  @Input() selectedOrder: Order
  newOrder: Order
  loading = true
  operationOptions: string[] = ['move', 'copy']
  targetOptions: string[] = ['intoNewOrder', 'intoExistingOrder']
  selectedTargetOption = 'intoNewOrder'
  selectedOperationOption
  orderNumber: number

  searchText: string

  searchedOrders: any[] = []
  isOrderSelected = false

  public searchPhrase = ''

  @Output() createdOrderItem: EventEmitter<any> = new EventEmitter()
  @Output() changedQuantity: EventEmitter<any> = new EventEmitter()
  @Output() removeOrderItem: EventEmitter<any> = new EventEmitter()
  @Output() addOrderItem: EventEmitter<any> = new EventEmitter()
  @Output() orderReload: EventEmitter<any> = new EventEmitter()
  @Output() setCheckboxes: EventEmitter<any> = new EventEmitter()

  newCreatedOrder: Order

  carriers = []
  checkouts = []
  vats = []
  payments = []
  suppliers = []
  currencies = []
  depots = []

  items = []

  itemsMoved = false

  targetOrder: Order

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public core: CoreService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.order.status.id === 'bill') {
      this.targetOptions.push('corrective.tax.document')
    }
  }

  public showChildModal() {
    if (this.itemsFromParent?.length < 1) {
      alert(this.translateService.instant('modal-move-order-items.select.at.least.one.item'))
      return
    }
    this.items = JSON.parse(JSON.stringify(this.itemsFromParent))
    this.setDefaultValues()
    this.loading = false
    this.childModal.showChildModal()
  }
  negateSigns() {
    if (this.selectedTargetOption === 'corrective.tax.document') {
      this.items.forEach(item => {
        item.quantity = +item.quantity > 0 ? -item.quantity : item.quantity
      })
    } else {
      this.items.forEach(item => {
        item.quantity = +item.quantity < 0 ? -item.quantity : item.quantity
      })
    }
  }
  maxValue(item): number {
    const originItemWithQuantity = this.itemsFromParent.find(i => i.id === item.id)
    return originItemWithQuantity?.quantity
  }

  public hideChildModal() {
    // this.setCheckboxes.emit()
    this.childModal.hideChildModal()
  }

  setDefaultValues(): void {
    this.newOrder = this.order
    this.targetOrder = undefined
    this.newCreatedOrder = undefined
    this.selectedTargetOption = 'intoNewOrder'
    this.order.status.id === 'bill' ? (this.selectedOperationOption = 'copy') : (this.selectedOperationOption = 'move')
    this.orderNumber = undefined
    this.searchText = ''
    this.searchedOrders = []
    this.selectedOrder = undefined
    this.isOrderSelected = false
    this.itemsMoved = false
    this.searchPhrase = ''

    this.carriers = []
    this.checkouts = []
    this.vats = []
    this.payments = []
    this.suppliers = []
    this.currencies = []
    this.depots = []
  }

  async save() {
    if (this.selectedTargetOption === 'intoExistingOrder' && !this.selectedOrder) {
      alert(this.translateService.instant('item.choose'))
      return
    }
    this.loading = true

    if (this.selectedOperationOption === 'move') {
      await this.removeMovedItemsFromOriginOrder()

      await this.refreshPaymentAmount(this.order)

      this.updateThisOrder()
    }

    if (this.selectedTargetOption !== 'intoExistingOrder') {
      await this.createOrderWithItems(this.selectedTargetOption === 'corrective.tax.document')
    } else {
      await this.refreshData()

      if (this.selectedOrder?.items?.length > 0) {
        this.compareItemsWithItemsOnSelectedOrder()
      } else {
        this.items.forEach(item => {
          if (this.order.currency.id !== this.selectedOrder.currency.id) {
            item.price = this.recalculatePrice(item.price, this.selectedOrder.currency)
          }
          // if differnet currencies on orders, recalculate price
          if (this.selectedOrder.paymentItems?.length) {
            this.selectedOrder.paymentItems[0].amount = this.selectedOrder.paymentItems[0].amount + item.priceAll
          }
          if (item?.product?.id) this.selectedOrder.items.push(item)
          else this.addOrderItem.emit({ item: item, order: this.selectedOrder })
        })
      }
      await this.updateOrder()
      this.targetOrder = this.selectedOrder
    }
    this.setCheckboxes.emit()
    this.loading = false
    this.itemsMoved = true
  }

  async compareItemsWithItemsOnSelectedOrder() {
    this.items.forEach(item => {
      let breaked = false
      for (const itemOnSelectedOrder of this.selectedOrder.items) {
        if (
          (item?.product?.id > 0 && item?.product?.id === itemOnSelectedOrder?.product?.id) ||
          (!item?.product?.id && !itemOnSelectedOrder?.product?.id && this.areObjectsEqual(item, itemOnSelectedOrder))
        ) {
          const newQuantity = Number(itemOnSelectedOrder.quantity) + Number(item.quantity)
          this.changedQuantity.emit({
            newQuantity: newQuantity,
            itemOnSelectedOrder: itemOnSelectedOrder,
            selectedOrder: this.selectedOrder,
            isFromMoving: true,
          })
          breaked = true
          break
        }
      }
      if (!breaked) {
        if (item?.product?.id) {
          item.product.orderQuantity = item.quantity
          if (this.order.currency.id !== this.selectedOrder.currency.id) {
            item.price = this.recalculatePrice(item.price, this.selectedOrder.currency, this.order.currency)
          }
          if (this.selectedOrder.paymentItems?.length) {
            this.selectedOrder.paymentItems[0].amount = this.selectedOrder.paymentItems[0].amount + item.priceAll
          }
          this.selectedOrder.items.push(item)
          // this.addProductItemToOrder(item, this.selectedOrder)
        } else this.addOrderItem.emit({ item: item, order: this.selectedOrder })
      }
    })
  }

  areObjectsEqual(item, itemOnSelectedOrder): boolean {
    //expiration date ?
    // item.moves === itemOnSelectedOrder.moves &&
    // item.clearanceItems === itemOnSelectedOrder.clearanceItems &&
    // item.quantityUnavailable === itemOnSelectedOrder.quantityUnavailable &&
    // item.uuid === itemOnSelectedOrder.uuid &&
    return (
      item.code === itemOnSelectedOrder.code &&
      item.ean === itemOnSelectedOrder.ean &&
      item.isForSubsequentSettlement === itemOnSelectedOrder.isForSubsequentSettlement &&
      item.name === itemOnSelectedOrder.name &&
      item.note === itemOnSelectedOrder.note &&
      item.packed === itemOnSelectedOrder.packed &&
      item.picked === itemOnSelectedOrder.picked &&
      item.price === itemOnSelectedOrder.price &&
      item.priceWithoutVat === itemOnSelectedOrder.priceWithoutVat &&
      item.sale === itemOnSelectedOrder.sale &&
      item.serial === itemOnSelectedOrder.serial &&
      item.voucher?.id === itemOnSelectedOrder.voucher?.id &&
      item.vat?.id === itemOnSelectedOrder.vat?.id
    )
  }

  async updateThisOrder() {
    try {
      const keepReservation = true
      const result = await this.core.services.order.update(this.order, keepReservation, undefined, undefined, {
        id: null,
      })
      if (result && result.id > 0) {
        await this.orderReload.emit()
        this.targetOrder = this.order
      }
    } catch (err) {
      console.error(err)
    }
  }

  async updateOrder() {
    try {
      const keepReservation = true
      const result = await this.core.services.order.update(
        this.selectedOrder,
        keepReservation,
        undefined,
        undefined,
        SchemaUtil.orderItems
      )
      if (result && result.id > 0) {
        this.refreshData()
      }
    } catch (err) {
      console.error(err)
      return err
    }
  }

  async refreshData() {
    try {
      this.selectedOrder = new Order(
        await this.core.services.order.getById(this.selectedOrder.id, SchemaUtil.orderItems)
      )
    } catch (e) {
      console.warn(e)
    }
    // this.sortOrderItems()
    // this.fillFormValue()
  }

  async searchForOrder() {
    this.loading = true
    const pageNo = 1
    const sortProp = 'id'
    const sortDir = 'desc'
    this.searchedOrders = await this.core.services.order
      .getList(
        {
          page: pageNo,
          sort: sortProp,
          direction: sortDir,
          filters: { fulltext: this.searchPhrase, status: 'reservation' },
        },
        SchemaUtil.orderItems
      )
      .then(result => {
        return result.items
      })
    this.loading = false
  }

  orderSelected(order) {
    this.selectedOrder = order
  }

  orderUnselected() {
    this.selectedOrder = undefined
  }

  async removeMovedItemsFromOriginOrder() {
    this.items.forEach(movedItem => {
      this.order.items.forEach(itemOnOriginOrder => {
        if (movedItem.id === itemOnOriginOrder.id) {
          if (itemOnOriginOrder.quantity === movedItem.quantity) {
            this.removeOrderItem.emit({ movedItem, isFromMoving: true })
          } else {
            const newQuantity = Number(itemOnOriginOrder.quantity) - Number(movedItem.quantity)
            const selectedOrder = this.order
            const itemOnSelectedOrder = itemOnOriginOrder
            this.changedQuantity.emit({ newQuantity, itemOnSelectedOrder, selectedOrder, isFromMoving: false })
          }
        }
      })
    })
  }

  public refreshPaymentAmount(order): void {
    if (order.paymentItems && order.paymentItems.length === 1) {
      const itemPrices = order.items.map(item => item.priceAll)
      order.paymentItems[0].amount = itemPrices.reduce((a, b) => a + b, 0)
    }
  }

  async createOrderWithItems(isODD = false) {
    const newOrder = JSON.parse(JSON.stringify(this.order))

    //set some properties to null and set items
    newOrder.isPaid = false
    newOrder.id = null
    newOrder.dateCreated = null
    newOrder.items = this.items
    newOrder.reservationNumber = null
    newOrder.billNumber = null

    await this.refreshPaymentAmount(newOrder)

    newOrder.items.forEach(it => {
      delete it.id
    })
    if (isODD) {
      newOrder.relatedParent = { id: this.order.id }
      newOrder.status = { id: 'bill' }
    } else {
      newOrder.status = { id: 'reservation' }
    }
    try {
      const result = await this.core.services.order.create(newOrder)
      if (result && result.id > 0) {
        this.newOrder = new Order(await this.core.services.order.getById(result.id, SchemaUtil.orderItems))
        this.targetOrder = this.newOrder
      }
    } catch (err) {
      console.error(err)
    }
  }

  recalculatePrice(price, newCurrency, originalCurrency?) {
    let recalculatedPrice: number
    if (originalCurrency.id === 'CZK') {
      recalculatedPrice = Number((price * newCurrency.ratio).toFixed(2))
    } else if (newCurrency.id === 'CZK') {
      recalculatedPrice = Number((price / originalCurrency.ratio).toFixed(2))
    } else if (originalCurrency.id !== 'CZK' && newCurrency.id !== 'CZK') {
      const priceInCzk = Number(price / newCurrency.ratio)
      recalculatedPrice = Number((priceInCzk * newCurrency.ratio).toFixed(2))
    }
    return recalculatedPrice
  }

  getTitle(): string {
    if (this.order.status.id === 'bill') return 'selected.items.copy'
    else return 'selected.items.move'
  }

  openUpdatedOrder(id) {
    const url = '/order/'.concat(id.toString())
    window.open(url)
  }
}
