import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Order } from 'depoto-core/src/entities'

@Component({
  selector: 'app-order-expedition',
  templateUrl: './order-expedition.component.html',
  styleUrls: ['./order-expedition.component.scss'],
})
export class OrderExpeditionComponent {
  @Input() order: Order
  @Input() canCreateOrEdit = false
  @Output() orderChange: EventEmitter<any> = new EventEmitter<any>()
  @Output() processStatusChange: EventEmitter<any> = new EventEmitter()
  // todo not good solution for translation -> translate priorities
  priorities: any[] = [
    { id: 0, name: 'Nejnižší' },
    { id: 1, name: 'Nízká' },
    { id: 10, name: 'Normální' },
    { id: 20, name: 'Vysoká' },
    { id: 30, name: 'Nejvyšší' },
  ]

  public selectedDateExpedition(value: '') {
    this.order.dateExpedition = value
    this.orderChange.emit(this.order)
  }

  setToStatusReceived(event) {
    this.processStatusChange.emit(event)
  }

  get canCreateOrEditDateExpedition(): boolean {
    return !!(
      this.order.processStatus?.id === 'recieved' ||
      this.order.processStatus?.id === 'received' ||
      this.order.processStatus?.id === 'packing' ||
      this.order.processStatus?.id === 'picking'
    )
  }
}
