<div [ngClass]="tooltipPosition" class="flex-gap-5">
  <div class="form-check form-switch" data-cy="toggle-div">
    <input
      type="checkbox"
      class="form-check-input"
      tabindex="0"
      [id]="id"
      [ngModel]="model"
      (ngModelChange)="onNgModelChange($event)"
      [attr.data-track-category]="trackCategory"
      [attr.data-track-action]="trackAction"
      [attr.data-cy]="dataCyAttribute"
      [disabled]="inputDisabled"
    />
    <label class="form-check-label" [for]="id" [ngClass]="classesString">
      {{ label | translate }}
    </label>
  </div>
  <div>
    <info-tooltip
      class="align-self-center"
      *ngIf="tooltipLabel && tooltipLabel.length > 0"
      [label]="tooltipLabel"
      [icon]="tooltipIcon"
    ></info-tooltip>
    <div *ngIf="!control.pristine && !control.valid" style="color: #f0142f">
      {{ errors | formError: formControlName | translate }}
    </div>
  </div>
</div>
