import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core'
import { GeoConfig, GeoListItemType } from '../../../configs'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { Address, Customer } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../../services'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'modal-customer',
  templateUrl: './modal-customer.component.html',
  styleUrls: ['modal-customer.component.scss'],
})
export class ModalCustomerComponent implements OnChanges {
  @ViewChild('modal') public modal: ModalContainerComponent
  @Input() isCreating = false
  @Input() customer: Customer
  @Output() customerChange: EventEmitter<Customer> = new EventEmitter()
  @Input() btnLabel: string
  @Input() btnIcon: string
  @Input() btnTitle: string
  @Input() dataCyAttribute: string
  address: Address = new Address({ country: 'CZ' })
  btnClasses: string[] = []
  modalTitle: string
  countries: GeoListItemType[] = [null, ...GeoConfig.countries]
  states: GeoListItemType[] = []
  isBillingAddress: boolean

  constructor(
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.address) {
      this.selectCountry(this.address.country)
    }
    this.getVariablesForModal()
  }

  public showChildModal(): void {
    this.modal.showChildModal()
  }

  public hideChildModal(): void {
    this.modal.hideChildModal()
  }

  public async createCustomer(): Promise<any> {
    if (this.validateInput()) {
      this.address.isBilling = this.isBillingAddress
      this.address.isStored = true
      this.customer = new Customer({
        firstName: this.address.firstName,
        lastName: this.address.lastName,
        email: this.address.email,
      })
      try {
        const customer = await this.core.services.customer.create(this.customer)
        const address = await this.core.services.address.create(this.address, customer)
        if (customer && customer.id > 0 && address && address.id > 0) {
          this.notificationService.success(this.translateService.instant('alert.customer.added'))
          this.customer.id = customer.id
          this.customer.addresses.push({ ...this.address, id: address.id })
          this.hideChildModal()
          this.customerChange.emit(this.customer)
        }
      } catch (e) {
        console.warn(e)
      }
    }
  }

  public async updateCustomer(): Promise<any> {
    if (this.validateInput()) {
      this.customer.firstName = this.address.firstName
      this.customer.lastName = this.address.lastName
      this.customer.email = this.address.email
      try {
        const customer = await this.core.services.customer.update(this.customer)
        const address = await this.core.services.address.update(this.address, customer)
        if (customer && customer.id > 0 && address && address.id > 0) {
          this.hideChildModal()
          this.customerChange.emit(this.customer)
        }
      } catch (e) {
        console.warn(e)
      }
    }
  }

  public selectCountry(country: string): void {
    this.address.country = country
    switch (country) {
      case 'US':
        this.states = GeoConfig.statesUS
        break
      case 'CA':
        this.states = GeoConfig.statesCA
        break
      default:
        this.states = []
        this.address.state = ''
        break
    }
  }

  private validateInput(): boolean {
    const fieldsWithErrors: string[] = []
    if (!this.address.email || this.address.email.length < 1) {
      fieldsWithErrors.push(this.translateService.instant('email'))
    }
    if (!this.address.firstName || this.address.firstName.length < 1) {
      fieldsWithErrors.push(this.translateService.instant('firstname'))
    }
    if (!this.address.lastName || this.address.lastName.length < 1) {
      fieldsWithErrors.push(this.translateService.instant('lastname'))
    }

    if (fieldsWithErrors.length === 0) {
      return true
    } else {
      alert(this.translateService.instant('modal-customer.required-fields', { fields: fieldsWithErrors.toString() }))
      return false
    }
  }

  private getVariablesForModal(): void {
    if (!!this.btnLabel || !!this.btnIcon) {
      return
    }
    if (this.isCreating) {
      this.modalTitle = 'customer.create'
      this.btnLabel = 'customer.create'
      this.btnTitle = 'customer.create'
      this.btnIcon = 'far-plus'
      this.btnClasses = ['btn-second', 'btn-sm', 'mt-3']
    } else {
      this.modalTitle = 'customer.update'
      this.btnIcon = 'far-pencil'
      this.btnClasses = ['btn-edit', 'btn-sm', 'float-right']
      this.btnTitle = 'edit'
    }
  }
}
