import { Component } from '@angular/core'
import { Product, Vat, ProductBundle, Tag, File, Supplier } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../../services'
import { GeoConfig, GeoListItemType } from '../../../configs'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { SchemaUtil } from '../../../utils'
import { ProductDetailService } from '../../../product-detail.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['product-form.component.css'],
})
export class ProductFormComponent {
  countries: GeoListItemType[] = GeoConfig.countries
  tags: Tag[] = []
  ckeditor = ClassicEditor
  isEnabledModule = false
  constructor(
    private core: CoreService,
    public productDetailService: ProductDetailService,
    private notificationService: NotificationService,
    public translateService: TranslateService
  ) {
    this.getTags()
    this.isEnabledModule = this.core.services.user.user.company.moduleRelations?.some(
      moduleRelation => moduleRelation.module.id === 'serial-numbers'
    )
  }

  public async fillNextEan() {
    // this.formGroup.patchValue({ ean: await this.core.services.product.getNextEan() })
    this.productDetailService.product.ean = await this.core.services.product.getNextEan()
  }

  get isBundleCanBeSet() {
    // can be set if isBundle && does not have any bundleChildren OR !isBundle && does not have children/parent/hasn't been stock load
    return (
      !this.productDetailService.product.productDepots?.length &&
      !this.productDetailService.product.children?.length &&
      !this.productDetailService.product.parent?.id
    )
  }

  setSupplier(event) {
    this.productDetailService.product.supplier = new Supplier({ id: event })
  }

  public async updateVariants(properties: string[]) {
    this.setLoading(true)
    try {
      const productPart: any = {}
      properties.forEach(p => {
        if (p === 'supplier') {
          productPart.supplier = this.productDetailService.product.supplier
        } else if (p === 'purchaseCurrency') {
          productPart.purchaseCurrency = this.productDetailService.product.purchaseCurrency
        } else {
          productPart[p] = this.productDetailService.product[p]
        }
      })
      await this.core.services.product.updateProductPart(
        { id: this.productDetailService.product.id, ...productPart },
        { id: null }
      )
      const promises = this.productDetailService.product.children.map(c =>
        this.core.services.product.updateProductPart({ id: c.id, ...productPart }, { id: null })
      )
      await Promise.all(promises)
      if (promises) {
        this.notificationService.success(this.translateService.instant('alert.product.saved'))
      }
    } catch (e) {
      console.warn(e)
      this.notificationService.warning(this.translateService.instant('product-form.error', { error: e.message }))
    }
    this.setLoading(false)
  }

  public setParent(parent: Product): void {
    this.productDetailService.product.parent = parent
  }

  public addChild(child: Product): void {
    this.productDetailService.product.children.push(child)
  }

  public removeChild(child: Product): void {
    this.productDetailService.product.children.forEach((c, i) => {
      if (child.id === c.id) {
        this.productDetailService.product.children.splice(i, 1)
      }
    })
  }

  async addBundleChild(productBundle: ProductBundle) {
    if (this.productDetailService.product?.id > 0) {
      this.setLoading(true)
      productBundle.parent = new Product(this.productDetailService.product)
      this.productDetailService.product.bundleChildren.push(
        await this.core.services.productBundle.create(productBundle, SchemaUtil.productBundle)
      )
      this.setLoading(false)
    } else {
      this.productDetailService.product.bundleChildren.push(productBundle)
    }
  }

  async updateBundleChild(productBundle: ProductBundle) {
    if (this.productDetailService.product?.id > 0) {
      this.setLoading(true)
      const result = await this.core.services.productBundle.update(productBundle, SchemaUtil.productBundle)
      if (result && result.id > 0) {
        this.productDetailService.product.bundleChildren.forEach((bundleChildren, index) => {
          if (result.id === bundleChildren.id) {
            this.productDetailService.product.bundleChildren[index] = result
          }
        })
      }
      this.setLoading(false)
    } else {
      this.productDetailService.product.bundleChildren.forEach((bundleChildren, index) => {
        if (productBundle.child.id === bundleChildren.child.id) {
          this.productDetailService.product.bundleChildren[index] = productBundle
        }
      })
    }
  }

  async removeBundleChild(productBundle: ProductBundle) {
    if (this.productDetailService.product?.id > 0) {
      this.setLoading(true)
      this.productDetailService.product.bundleChildren = [...this.productDetailService.product.bundleChildren].filter(
        bc => bc.id !== productBundle.id
      )
      await this.core.services.productBundle.delete(productBundle)
      this.setLoading(false)
    } else {
      this.productDetailService.product.bundleChildren = [...this.productDetailService.product.bundleChildren].filter(
        bc => bc.child.id !== productBundle.child.id
      )
    }
  }

  public compareWithFn(a: Vat, b: Vat) {
    return a && b ? a.id === b.id : a === b
  }

  async getTags() {
    this.tags = this.core.lists.tags.filter(t => t.type === 'product')
  }

  onTagsChange(event) {
    this.productDetailService.product.tags = this.tags.filter(t => event.includes(t.id))
  }

  setLoading(val: boolean) {
    this.productDetailService.loading = val
  }

  getThumbUrl(image: File): string {
    return image.thumbnails.filter(t => t.format === 'w135wp')[0].url
  }

  get vat() {
    if (this.productDetailService.product?.vat?.id) {
      return this.productDetailService.product?.vat?.id
    } else {
      this.productDetailService.product.vat = this.productDetailService.vats.filter(v => v.default)[0]
      return this.productDetailService.product.vat.id
    }
  }
}
