import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild, OnInit } from '@angular/core'

@Component({
  selector: 'app-pagination-btn',
  templateUrl: './pagination-btn.component.html',
  styleUrls: ['./pagination-btn.component.scss'],
})
export class PaginationBtnComponent implements OnInit, OnDestroy {
  @Input() currentPage: number
  @Input() endPage: number
  @Input() loadingPage: boolean
  @Input() minViewportWidth = 0
  @Input() sortProp = 'id'
  @Input() sortDir = 'desc'
  @Output() switchPage: EventEmitter<any> = new EventEmitter<any>()
  @ViewChild('loadNextPageElement') loadNexPageElementRef
  observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) return
      this.scrolled()
    })
  })

  ngOnInit() {
    setTimeout(() => {
      this.observer.observe(this.loadNexPageElementRef.nativeElement)
    }, 300)
  }

  ngOnDestroy() {
    this.observer.unobserve(this.loadNexPageElementRef.nativeElement)
  }

  scrolled() {
    if (this.minViewportWidth > window.innerWidth) {
      return
    }
    this.paginate(true)
  }

  paginate(concat = false): void {
    if (!this.currentPage) {
      this.currentPage = 2
    }
    this.switchPage.emit({
      currentPage: this.currentPage,
      sortProp: this.sortProp,
      sortDir: this.sortDir,
      concat: concat,
    })
  }
}
