import { Component } from '@angular/core'
import { DndDropEvent } from 'ngx-drag-drop'
import { NotificationService } from '../../services'

@Component({
  selector: 'component-list',
  templateUrl: './component-list.component.html',
  styles: [
    `
      .dndPlaceholder {
        min-height: 72px;
        border: 1px dashed green;
        background-color: rgba(0, 0, 0, 0.1);
      }
    `,
  ],
})
export class ComponentListComponent {
  options: string[] = ['foo', 'bar', 'baz']
  selectedOption = 'foo'
  options2: any[] = [
    { id: 1, name: 'foo' },
    { id: 2, name: 'bar' },
    { id: 3, name: 'baz' },
  ]
  selectedOption2 = 1
  console = window.console
  data = ['first', 'second', 'third', 'fourth', 'fifth']
  numberValue = 23

  constructor(private notificationService: NotificationService) {}

  callSomeMethod(args: any): void {
    console.log('test call', args)
  }

  public onValue() {
    console.log(this.numberValue)
  }

  onDrop(event: DndDropEvent, list: any[]) {
    if (list) {
      let index = event.index
      if (typeof index === 'undefined') {
        index = list.length
      }
      list.splice(index, 0, event.data)
    }
  }

  onDragMove(item: any, list: any[]) {
    const index = list.indexOf(item)
    list.splice(index, 1)
  }

  showSuccessNotification() {
    this.notificationService.success('Lorem ipsum', 'title')
  }

  showSuccessNotificationNoTimeout() {
    this.notificationService.success(
      'Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsum',
      null,
      true
    )
  }

  showAllNotificationTypes() {
    this.notificationService.error('error')
    this.notificationService.error('error', 'title')
    this.notificationService.warning('warning', null, true)
    this.notificationService.warning('warning')
    this.notificationService.info('info')
    this.notificationService.info('info', 'title', true)
    this.notificationService.success('success')
    this.notificationService.success('success', 'title', true)
  }
}
