import { Component, OnInit } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { CoreService } from '../../services'
import { CompanyCarrier, Carrier } from 'depoto-core/src/entities'
import { SchemaUtil } from '../../utils'
import { companyCarriersColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  carrier: '',
}

@Component({
  selector: 'app-company-carriers',
  templateUrl: 'company-carriers.component.html',
})
export class CompanyCarriersComponent extends BaseListing implements OnInit {
  listingColumns: ListingItem[] = companyCarriersColumns
  companyCarriers: CompanyCarrier[] = []
  carriers: Carrier[] = []
  totalItems = 0
  currentPage: number
  endPage: number
  filters = { ...DEFAULT_FILTERS }
  canCreate = false
  loading = true
  storageKey: { type: string; key: string }
  sortProp = 'id'
  sortDir = 'asc'

  constructor(protected core: CoreService) {
    super(core)
    this.getStorageKey()
  }

  async ngOnInit() {
    this.filters = this.loadViewSettings('companyCarriers') || { ...DEFAULT_FILTERS }
    if (this.core.services.user.user) {
      this.checkPerms()
    }
    this.core.services.user.currentUserEmitter.subscribe().then(() => {
      this.checkPerms()
    })
    // this.loadSorting('companyCarriers') any sortable column
    await this.filter()
  }

  checkPerms() {
    this.canCreate =
      this.core.services.user.hasRole('ROLE_ORDER_CREATE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
  }

  clearFilters(): void {
    this.filters = { ...DEFAULT_FILTERS }
    this.filter(1)
  }

  updateFilterCarrier(carrierId): void {
    this.filters.carrier = carrierId
    this.filter()
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.currentPage = pageNo
    if (!concat) {
      this.reloading = true
    }
    try {
      this.carriers = this.core.lists.carriers
      const companyCarriers = await this.core.services.companyCarrier.getList(
        { page: pageNo, sort: sortProp, direction: sortDir, filters: this.filters },
        {
          id: null,
          enable: null,
          carrier: SchemaUtil.carrier,
          checkout: {
            id: null,
            name: null,
          },
          externalId: null,
        }
      )
      if (!companyCarriers?.items) return
      if (!concat) {
        this.companyCarriers = companyCarriers.items
      } else {
        companyCarriers.items.forEach(c => {
          this.companyCarriers.push(new CompanyCarrier(c))
        })
      }
      this.totalItems = companyCarriers.paginator ? companyCarriers.paginator.totalCount : 0
      this.endPage = companyCarriers.paginator ? companyCarriers.paginator.endPage : 0
      this.saveViewSettings('companyCarriers', this.filters)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }
}
