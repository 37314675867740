<!--<div class="btn-group">-->
<!--  <button type="button" class="btn dropdown-toggle dropdown-settings btn-{{theme}}" (click)="isOpen = !isOpen">-->
<!--    <i *ngIf="icon && icon.length" [class]="iconClassesString"></i>-->
<!--    {{label | trans}}-->
<!--  </button>-->
<!--  <ul class="dropdown-menu" role="menu" [ngClass]="{'is-open': isOpen}">-->
<!--    <ng-template [ngIf]="items && items.length">-->
<!--      <li class="dropdown-item" [ngClass]="{'selected': item.isSelected}" *ngFor="let item of items" (click)="onItemClick(item.action)">{{item.label | trans}}</li>-->
<!--    </ng-template>-->
<!--  </ul>-->
<!--</div>-->
<div class="btn-group" #dropdown="bs-dropdown" dropdown [autoClose]="false" container=".content-wrapper">
  <button dropdownToggle type="button" class="btn dropdown-toggle dropdown-settings btn-{{ theme }}">
    <i *ngIf="icon && icon.length" [class]="iconClassesString"></i>
    {{ label | translate }}
  </button>
  <ul *dropdownMenu class="dropdown-menu" role="menu">
    <ng-template [ngIf]="items && items.length">
      <li
        class="dropdown-item"
        [ngClass]="{ selected: item.isSelected }"
        *ngFor="let item of items"
        (click)="onItemClick(item.action)"
      >
        {{ item.label | translate }}
      </li>
    </ng-template>
  </ul>
</div>
