import { Component, ChangeDetectorRef, OnInit, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { CoreService } from '../../services'
import { Product, Depot, Producer, Supplier, Tag, File, Category, User, Company, Order } from 'depoto-core/src/entities'
import { BaseListing } from '../_base-listing/base-listing'
import { ExportsComponent } from '../exports/exports.component'
import { ListingItem } from 'depoto-core/src/models'
import { productsColumns } from '../../utils/definitions.util'
import { PurchaseOrderStrategy } from './components/modal-product-purchase/modal-product-purchase.component'
import { TranslateService } from '@ngx-translate/core'

const DEFAULT_FILTERS = {
  fulltext: '',
  depots: [],
  availability: 'all',
  tags: [],
  isBundle: 'all',
  enabled: 'yes',
  code: '',
  ean: '',
  suppliers: [],
  producers: [],
  expirationDateTo: null,
  listType: 'children_and_parents_without_children',
  categories: [],
  metrics: '',
  hasHsCode: 'all',
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent extends BaseListing implements OnInit, OnChanges, OnDestroy {
  @ViewChild('exportsModal') exportsModal: ExportsComponent
  listingColumns: ListingItem[] = productsColumns
  filters = structuredClone(DEFAULT_FILTERS)
  depots: Depot[] = []
  products: Product[] = []
  suppliers: Supplier[] = []
  producers: Producer[] = []
  tags: Tag[] = []
  listTypes: { val: string; name: string }[] = [
    { val: 'parents', name: 'Hlavní produkty' },
    { val: 'parents_with_children', name: 'Hlavní s variantami' },
    { val: 'parents_without_children', name: 'Hlavní bez variant' },
    { val: 'children_and_parents_without_children', name: 'Skladové položky' },
    { val: 'children', name: 'Varianty' },
    { val: 'all', name: 'Vše' },
  ]
  availabilityTypes: string[] = ['all', 'available', 'reservation', 'stock', 'unavailable']
  purchaseOrdersTypes = [
    'all',
    'available_less_than_minimum',
    'available_less_than_optimum',
    'available_more_than_optimum',
    'available_more_than_maximum',
    'unavailable',
  ]
  abilityTypes: string[] = ['all', 'yes', 'no']
  expirations: string[] = ['all', 'monthEnd', 'after1month', 'after3months', 'custom']
  selectedExpiration: string
  totalItems = 0
  currentPage: number
  endPage: number
  categories: Category[] = []
  storageKey: { type: string; key: string }
  sortProp = 'name'
  sortDir = 'asc'
  canCreate = false
  loading = true
  filtersExpanded = false
  keyEventSubscriptions: any = {}
  checkedProducts: Product[] = []
  areAllProductsChecked = false
  reloading = true
  public selectedColumns: ListingItem[]
  isPurchaseOrderAllowed = false

  constructor(
    private router: Router,
    public core: CoreService,
    public translateService: TranslateService,
    protected changeDetector: ChangeDetectorRef
  ) {
    super(core)
    this.getStorageKey()
  }

  async ngOnInit() {
    this.loadView()
    // setTimeout(async () => {
    await this.refreshFiltersData()
    if (this.core.services.user.user) {
      this.isPurchaseOrderAllowed = this.checkIfPurchaseOrderIsAllowedForCompany(this.core.services.user.user.company)
      this.checkPerms()
    }
    this.core.services.user.currentUserEmitter.subscribe().then((user: User) => {
      this.checkPerms()
      if (user) {
        this.isPurchaseOrderAllowed = this.checkIfPurchaseOrderIsAllowedForCompany(user.company)
      }
    })
    this.loadSorting('products')
    await this.filter()
    this.keyEventSubscriptions.scannedBarcode = this.core.services.keyEvent.onScannedBarcode.subscribe().then(code => {
      this.filters.ean = code
      this.filter()
      this.filtersExpanded = true
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.storageKey?.currentValue) {
      this.loadSettings()
    }
  }

  private checkIfPurchaseOrderIsAllowedForCompany(company: Company): boolean {
    return company.moduleRelations?.some(moduleRelation => moduleRelation.module.id === 'purchase-orders')
  }

  ngOnDestroy() {
    if (this.keyEventSubscriptions.scannedBarcode) {
      this.keyEventSubscriptions.scannedBarcode.unsubscribe()
      this.keyEventSubscriptions.scannedBarcode = null
    }
  }

  createBatchFromFile(res: any[]): void {
    if (res?.length) {
      // this.notificationService.success(this.translateService.instant('products.create.success'))
      this.filter()
    }
  }

  checkPerms() {
    this.canCreate =
      this.core.services.user.hasRole('ROLE_ORDER_CREATE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
  }

  onColumnSettingDropdownClick(event: string) {
    const isSelected = this.selectedColumns.find(c => c.name === event)
    if (isSelected) {
      this.selectedColumns = this.selectedColumns.filter(c => c.name !== event)
    } else {
      this.selectedColumns = [...this.listingColumns.filter(c => c.name === event), ...this.selectedColumns]
    }
    const savedColumns = this.core.services.storage.getSync(this.storageKey) || {}
    savedColumns.products = [...this.selectedColumns]
    this.core.services.storage.set(this.storageKey, savedColumns)
  }

  isColumnSelected(property) {
    return this.selectedColumns.some(c => c.property === property)
  }

  isProductRowChecked(productId: number): boolean {
    if (this.areAllProductsChecked) return true
    else {
      return this.checkedProducts.find(checkedProduct => checkedProduct.id === productId) !== undefined
    }
  }

  productChecked(checkboxValue: boolean, product: Product): void {
    if (checkboxValue) {
      this.checkedProducts.push(product)
    } else {
      this.areAllProductsChecked = false
      this.checkedProducts = this.checkedProducts.filter(checkedProduct => checkedProduct.id != product.id)
    }
  }

  allProductsChecked(event: boolean) {
    if (event) {
      this.areAllProductsChecked = true
      this.checkedProducts = this.products
    } else {
      this.areAllProductsChecked = false
      this.checkedProducts = []
    }
  }

  async refreshFiltersData() {
    this.depots = this.core.lists.depots
    this.producers = this.core.lists.producers
    this.suppliers = this.core.lists.suppliers
    this.tags = this.core.lists.tags.filter(t => t.type === 'product')
    this.categories = this.core.lists.categories
  }

  get queryFilters() {
    const filtersWithValues = {}
    for (const [key, value] of Object.entries(this.filters)) {
      if (value !== undefined && value !== null && value !== false && value !== 0 && value !== '') {
        if (value === 'yes' || value === 'no') {
          filtersWithValues[key] = value === 'yes'
        } else if (Array.isArray(value) && value[0]?.id) {
          filtersWithValues[key] = value.map(object => object.id)
        } else {
          filtersWithValues[key] = value
        }
      }
    }
    if (
      !this.core.userHasAllDepots &&
      this.core.services.user.user?.depots?.length &&
      !filtersWithValues['depots']?.length
    ) {
      filtersWithValues['depots'] = [...this.depots.map(d => d.id)]
    }
    return filtersWithValues
  }

  async filter(
    pageNo = 1,
    sortProp: string = this.sortProp,
    sortDir: string = this.sortDir,
    concat = false
  ): Promise<any> {
    this.loading = true
    if (!concat) this.reloading = true
    this.saveView()
    this.currentPage = pageNo

    const schema: Partial<Product> | any = this.setSchema()

    try {
      const result = await this.core.services.product.getList(
        { page: pageNo, sort: sortProp, direction: sortDir, filters: this.queryFilters },
        schema
      )
      if (!result || !Array.isArray(result.items)) {
        return
      }
      if (!concat) {
        this.products = result.items
      } else {
        result.items.forEach(product => {
          this.products.push(new Product(product))
        })
      }
      this.totalItems = result.paginator ? result.paginator.totalCount : 0
      this.endPage = result.paginator ? result.paginator.endPage : 0
      this.currentPage++
    } finally {
      this.loading = false
      this.reloading = false
    }
  }

  public clearFilters() {
    this.selectedExpiration = 'all'
    this.filters = structuredClone(DEFAULT_FILTERS)
    this.filter()
  }

  sortBy(property: string): void {
    if (this.sortProp === property) {
      this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc'
    } else {
      this.sortProp = property
    }
    this.currentPage = 1
    const sorting = { sortProp: this.sortProp, sortDir: this.sortDir }
    this.core.services.storage.set(this.getStorageKeyForEntity('products'), sorting)
    this.filter()
  }

  setExpirationFilter(expStr: string = null, expDate: string = null) {
    if (expStr) {
      this.selectedExpiration = expStr
      let date = new Date()
      switch (this.selectedExpiration) {
        case 'all':
          this.filters.expirationDateTo = null
          return
        case 'monthEnd':
          date = new Date(date.getFullYear(), date.getMonth() + 1, 0)
          break
        case 'after1month':
          date.setMonth(date.getMonth() + 1)
          break
        case 'after3months':
          date.setMonth(date.getMonth() + 3)
          break
        case 'custom':
          return
      }

      const tzOffset = date.getTimezoneOffset()
      date.setTime(date.getTime() + tzOffset * 60 * -1000)

      this.filters.expirationDateTo = `${date.toISOString().substring(0, 10)} 23:59:59`
    } else if (expDate) {
      this.filters.expirationDateTo = `${new Date(expDate).toISOString().substring(0, 10)} 23:59:59`
    }
  }

  saveView(): void {
    this.saveViewSettings('products', {
      filters: this.filters,
      selectedExpiration: this.selectedExpiration,
    })
  }

  loadView(): void {
    const view = this.loadViewSettings('products')
    this.filters = view?.filters || structuredClone(DEFAULT_FILTERS)
    this.selectedExpiration = view?.selectedExpiration || 'all'
    this.loadSettings()
    if (!this.selectedColumns) {
      this.selectedColumns = this.listingColumns.filter(c => c.isDefault)
    }
  }

  loadSettings(): void {
    const savedColumns = this.core.services.storage.getSync(this.storageKey)
    if (savedColumns?.products) {
      savedColumns.products = savedColumns.products.filter(s => this.listingColumns.find(c => c.name === s.name))
      this.selectedColumns = savedColumns.products
    }
  }

  setCheckedProductsToFalse() {
    this.areAllProductsChecked = false
    this.checkedProducts = []
  }

  getThumbUrl(image: File): string {
    return image.thumbnails.filter(t => t.format === 'w135wp')[0].url
  }

  public redirect($event: { selectedDepot: number; selectedPurchaseOrderStrategy: PurchaseOrderStrategy }): void {
    const product = this.checkedProducts.map(product => {
      const calculatedQuantity = this.calculateQuantity(
        product,
        $event.selectedPurchaseOrderStrategy,
        $event.selectedDepot
      )
      return (({ code, ean, fullName, mainImage, note, id, purchaseCurrency, purchasePrice }) => ({
        code,
        ean,
        fullName,
        mainImage,
        note,
        product: id,
        currency: purchaseCurrency,
        purchasePrice,
        quantity: calculatedQuantity,
        purchasePriceAll: calculatedQuantity * purchasePrice,
      }))(product)
    })

    this.router.navigate(['/purchase-order-creation'], {
      state: { selectedProducts: product, depot: $event.selectedDepot },
    })
  }

  private calculateQuantity(
    product: Product,
    purchaseOrderStrategy: PurchaseOrderStrategy,
    selectedDepot: number
  ): number {
    let fieldName
    switch (purchaseOrderStrategy) {
      case 'less_than_minimum':
        fieldName = 'quantityMinimum'
        break
      case 'less_than_optimum':
        fieldName = 'quantityOptimum'
        break
      case 'more_than_optimum':
        fieldName = 'quantityMaximum'
        break
      case 'missing_from_orders':
        fieldName = 'quantityPurchase'
    }
    const metric = product.metrics.shift()
    const quantityMetric = (metric && metric[fieldName]) || 0
    const quantityItem = product.quantities.find(item => item.depot?.id == selectedDepot)
    let tempQuantity
    if (purchaseOrderStrategy === 'missing_from_orders') {
      tempQuantity =
        (quantityItem?.quantityReservation || 0) -
        (quantityItem?.quantityStock || 0) -
        (quantityItem?.quantityPurchase || 0)
    } else {
      tempQuantity = quantityMetric - (quantityItem?.quantityAvailable || 0) - (quantityItem?.quantityPurchase || 0)
    }
    return tempQuantity > 0 ? tempQuantity : 0
  }

  setSchema(): Partial<Product> {
    const schema: Partial<Product | any> = {
      id: null,
      name: null,
      fullName: null,
      code: null,
      ean: null,
      enabled: null,
    }
    if (this.isColumnSelected('name')) {
      schema.mainImage = {
        id: null,
        text: null,
        originalFilename: null,
        main: null,
        mimeType: null,
        size: null,
        url: null,
        thumbnails: {
          format: null,
          mimeType: null,
          url: null,
        },
      }
    }
    if (this.isColumnSelected('availability')) {
      schema.isBundle = null
      schema.quantityAvailable = null
      schema.quantityReservation = null
      schema.quantityStock = null
      schema.quantityPurchase = null
    }
    if (this.isColumnSelected('tags')) {
      schema.tags = {
        id: null,
        name: null,
        color: null,
      }
    }
    if (this.isColumnSelected('sellPrice')) {
      schema.sellPrice = null
      schema.actualSellPrice = null
    }
    if (this.isColumnSelected('created')) {
      schema.created = null
    }
    if (
      this.filters.listType === 'all' ||
      this.filters.listType === 'parents' ||
      this.filters.listType === 'parents_with_children'
    ) {
      schema.children = {
        id: null,
        name: null,
        fullName: null,
        code: null,
        ean: null,
        enabled: null,
        mainImage: {
          url: null,
          thumbnails: { format: null, url: null },
        },
        sellPrice: null,
        actualSellPrice: null,
        quantityAvailable: null,
        quantityReservation: null,
        quantityStock: null,
        quantityPurchase: null,
      }
    }
    return schema
  }
}
