<page-header [icon]="'far-envelope'" [title]="'addresses'">
  <ng-container class="header-button">
    <modal-address (result)="filter(1)" [isCreating]="true" [isHeadingBtn]="true"></modal-address>
  </ng-container>
</page-header>

<section class="content">
  <app-filter-form (onSubmit)="filter()" (onClearClick)="clearFilters()" [isFilterInvisible]="true">
    <form-row-input
      [label]="'customer.id'"
      [icon]="'far-user'"
      [inputType]="'number'"
      [(model)]="filters.customer"
      (keydown.enter)="filter(1)"
    ></form-row-input>
  </app-filter-form>

  <app-base-listing
    [loadingNextItems]="loading"
    [loadingItems]="reloading"
    [items]="addresses"
    [columns]="listingColumns"
    [totalItems]="totalItems"
    [currentPage]="currentPage"
    [endPage]="endPage"
    [(sortProp)]="sortProp"
    [(sortDir)]="sortDir"
    (reloadList)="filter(1)"
    (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
    (callAction)="callAction($event)"
  ></app-base-listing>
</section>
