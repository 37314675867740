import { Directive, ElementRef, HostListener } from '@angular/core'
import { BarcodeUtil } from 'depoto-core/src/utils'

@Directive({ selector: '[translateBarcode]' })
export class TranslateBarcodeDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keyup') onKeyUp() {
    this.el.nativeElement.value = BarcodeUtil.translateBarcode(this.el.nativeElement.value, true)
  }
}
