export const fileTemplatesURL = 'https://www.depoto.cz/import-templates/'
export const UI_langs = ['CS', 'EN']
export const Possible_Encodings = ['windows-1250', 'UTF-8', 'ISO-8859-2']
export const baseMimeTypes = [
  'image/',
  'text/',
  '/msword',
  'wordprocessingml',
  'application/pdf',
  'spreadsheet',
  'ms-excel',
]
