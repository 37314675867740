import { Component } from '@angular/core'
import { File } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../../services'
import { ProductDetailService } from '../../../product-detail.service'
import { TranslateService } from '@ngx-translate/core'
import { unknownMimeType } from '../../../utils'
import { FileUtilService } from '../../../utils/file-util.service'

@Component({
  selector: 'product-files',
  templateUrl: './product-files.component.html',
  styleUrls: ['product-files.component.scss'],
})
export class ProductFilesComponent {
  files: File[] = []
  constructor(
    private core: CoreService,
    public productDetailService: ProductDetailService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private fileUtil: FileUtilService
  ) {}

  async onSelect(event) {
    this.productDetailService.loading = true
    const files = []
    try {
      for (const f of event.addedFiles) {
        try {
          const processedFile = await this.fileUtil.processUploadedFileToBase64(f)
          if (processedFile?.base64Data) {
            files.push(processedFile)
            await this.core.services.file.create(processedFile, this.productDetailService.product.id)
          }
        } catch (err) {
          console.warn(err)
        }
      }
      if (files.length) {
        this.productDetailService.product.files.push(...files)
        this.notificationService.success(this.translateService.instant('alert.file.uploaded'))
        if (files.filter(f => f.mimeType.includes('image/')).length) {
          this.notificationService.success(this.translateService.instant('alert.files.contain.images'))
        }
        this.productDetailService.refreshProduct()
      } else {
        this.notificationService.error(this.translateService.instant('no-files'))
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.productDetailService.loading = false
    }
  }

  showFile(file) {
    this.core.services.download.downloadDocumentAndShowInNewWindow(file.url)
  }

  unknownType(s?: string) {
    return unknownMimeType(s)
  }

  async removeFile(file: File) {
    if (!confirm(this.translateService.instant('delete-file.confirmation', { name: file.originalFilename }))) {
      return
    }
    try {
      await this.core.services.file.delete(file)
      this.notificationService.success(this.translateService.instant('alert.file.removed'))
      this.productDetailService.product.files = this.productDetailService.product.files.filter(f => f.id !== file.id)
    } catch (err) {
      console.error(err)
    }
  }
}
