<ng-template #fileListItem let-file="file">
  <div class="col flex-gap-5">
    <i class="fa fa-file-image" *ngIf="file.mimeType?.includes('image/')" title="Image"></i>
    <i
      class="fa fa-file-text"
      *ngIf="
        file.mimeType?.includes('text/') ||
        file.mimeType?.includes('/msword') ||
        file.mimeType?.includes('wordprocessingml')
      "
      title="Text"
    ></i>
    <i class="fa fa-file-pdf" *ngIf="file.mimeType?.includes('application/pdf')" title="Pdf"></i>
    <i
      class="fa fa-file-excel"
      *ngIf="file.mimeType?.includes('spreadsheet') || file.mimeType?.includes('ms-excel')"
      title="Excel"
    ></i>
    <i class="fa fa-file" *ngIf="unknownType(file.mimeType)" title="File"></i>
    <a class="link" href="{{ file.url }}" target="_blank" data-cy="file-url">
      {{ file.originalFilename || file.text || 'file' }}
    </a>
  </div>
  <btn
    class="align-self-center"
    [title]="'delete' | translate"
    [icon]="'far-trash-alt'"
    (onEvent)="deleteFile(file)"
    [classes]="['btn-sm', 'btn-trash']"
    [dataCyAttribute]="'delete-file'"
  ></btn>
</ng-template>

<div class="card">
  <div class="row">
    <div class="col-12">
      <h3 class="card-title">
        {{ 'files' | translate }}
      </h3>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12 mb-2" title="{{ 'allowed-file-types' | translate }}">
      <form-row-input
        [icon]="'far-upload'"
        [inputType]="'file'"
        [inputMultiple]="true"
        (valueChangeNative)="uploadFiles($event)"
        [noMarginBottom]="true"
        [dataCyAttribute]="'order-upload-file'"
      ></form-row-input>
    </div>
  </div>

  <div class="overlay no-print" *ngIf="areFilesUploading">
    <div class="spinner-border text-primary spinner-border-sm mb-2" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div
    class="row mt-2"
    *ngIf="(!isOnPurchaseOrder && !order?.files?.length) || (isOnPurchaseOrder && !purchaseOrder?.files?.length)"
  >
    <div class="col-12 d-flex justify-content-center align-items-center">
      <div class="card-subheading">{{ 'no-files' | translate }}</div>
    </div>
  </div>

  <div *ngIf="!isOnPurchaseOrder && order?.files?.length">
    <div class="d-flex mt-2 flex-gap-5 padding-left-2 padding-right-2" *ngFor="let file of order.files">
      <ng-container *ngTemplateOutlet="fileListItem; context: { file: file }"></ng-container>
    </div>
  </div>

  <div *ngIf="isOnPurchaseOrder && purchaseOrder?.files?.length">
    <div class="d-flex mt-2 flex-gap-5 padding-left-2 padding-right-2" *ngFor="let file of purchaseOrder.files">
      <ng-container *ngTemplateOutlet="fileListItem; context: { file: file }"></ng-container>
    </div>
  </div>
</div>
