<div class="app-container">
  <page-header [icon]="'far-users'" [title]="'customers'">
    <ng-container class="header-button">
      <btn
        [routerLink]="['/customer']"
        [classes]="['btn-first', 'float-right']"
        [icon]="'far-plus'"
        [label]="'customers.create'"
        [title]="'customers.create' | translate"
        [dataCyAttribute]="'add-customer'"
      ></btn>
      <app-exports #exportsModal [entity]="'customers'" data-cy="exports"></app-exports>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form
      [filters]="queryFilters"
      [entity]="'customers'"
      (onClearClick)="clearFilters()"
      (onExportResult)="filter()"
      (onCreateModalHidden)="exportsModal.showChildModal()"
      [isFilterInvisible]="true"
      (onSubmit)="filter()"
      [isExportButtonShown]="true"
    >
      <div class="container-fluid">
        <div class="row">
          <form-row-input
            class="col-4 ps-0"
            [icon]="'far-user'"
            [(model)]="filters.fulltext"
            (keydown.enter)="filter()"
            [label]="'fulltext'"
            [dataCyAttribute]="'customer-name-filter'"
          ></form-row-input>
          <form-row-select-multiple
            class="col-3"
            [label]="'tags'"
            [options]="tags"
            [(selectedValues)]="filters.tags"
            [dataCyAttribute]="'customer-tags-filter'"
          ></form-row-select-multiple>
          <form-row-select
            class="col-3"
            [label]="'salesman'"
            [options]="salesmen"
            [(model)]="filters.salesmen"
            [dataCyAttribute]="'customer-salesman-filter'"
          ></form-row-select>
          <form-row-select
            class="col-2 pe-0"
            [label]="'priceLevel.title'"
            [options]="priceLevels"
            [(model)]="filters.priceLevel"
            [dataCyAttribute]="'customer-price-level-filter'"
          ></form-row-select>
        </div>
      </div>
    </app-filter-form>

    <app-base-listing
      [listName]="'customers'"
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="customers"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [(sortProp)]="sortProp"
      [(sortDir)]="sortDir"
      (reloadList)="filter(1)"
      (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
      (callAction)="callAction($event)"
    ></app-base-listing>
  </section>
</div>
