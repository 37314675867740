import { Component, ApplicationRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { Depot } from 'depoto-core/src/entities'
import { FormRowTextareaComponent } from 'src/app/components/_common/form-row-textarea/form-row-textarea.component'

import { ModalContainerComponent } from 'src/app/components/_common/modal-container/modal-container.component'
import { CoreService } from 'src/app/services'
export type PurchaseOrderStrategy =
  | 'less_than_minimum'
  | 'less_than_optimum'
  | 'more_than_optimum'
  | 'missing_from_orders'

@Component({
  selector: 'modal-product-purchase',
  templateUrl: 'modal-product-purchase.component.html',
  styleUrls: ['modal-product-purchase.component.scss'],
})
export class ModalProductPurchaseComponent {
  @ViewChild(ModalContainerComponent) public modal: ModalContainerComponent
  @ViewChild(FormRowTextareaComponent) public inputNoteComponent: FormRowTextareaComponent
  @Input() public depots: Depot[] = []
  @Output() public result: EventEmitter<{
    selectedDepot: number
    selectedPurchaseOrderStrategy: PurchaseOrderStrategy
  }> = new EventEmitter()
  keyEventSubscriptions: any = {}

  headerTitle: string
  purchaseOrderStrategyOptions: Array<{ name: string; code: string }> = [
    { code: 'less_than_minimum', name: 'products.purchase-orders.less-than-minimum-available' },
    { code: 'less_than_optimum', name: 'products.purchase-orders.less-than-optimum-available' },
    { code: 'more_than_optimum', name: 'products.purchase-orders.greater-than-optimum-available' },
    { code: 'missing_from_orders', name: 'products.purchase-orders.missing_from_orders' },
  ]

  selectedDepot
  selectedPurchaseOrderStrategy: PurchaseOrderStrategy

  constructor(
    private appRef: ApplicationRef,
    private core: CoreService
  ) {}

  public addProduct(): void {
    this.result.emit({
      selectedDepot: this.selectedDepot,
      selectedPurchaseOrderStrategy: this.selectedPurchaseOrderStrategy,
    })
    this.hideChildModal()
  }

  showProductOnChildModal(): void {
    this.showChildModal()
  }

  public showChildModal(): void {
    this.keyEventSubscriptions.keyEsc = this.core.services.keyEvent.onKeyEsc.subscribe().then(() => {
      this.modal.hideChildModal()
    })
    this.modal.showChildModal()
  }

  public hideChildModal(): void {
    if (this.keyEventSubscriptions.keyEsc) {
      this.keyEventSubscriptions.keyEsc.unsubscribe()
      this.keyEventSubscriptions.keyEsc = null
    }
    this.modal.hideChildModal()
  }
}
