<div class="card">
  <div>
    <h3 class="card-title">{{ 'expedition' | translate }}</h3>
  </div>
  <div class="table-responsive">
    <table class="table m-0">
      <tbody>
        <tr>
          <th class="card-subheading">{{ 'dateExpedition' | translate }}</th>
          <td class="no-hover" *ngIf="canCreateOrEditDateExpedition || canCreateOrEdit">
            <form-row-input
              [icon]="'fa-clock'"
              [model]="order.dateExpedition | fmtDate: 'yyyy-MM-dd'"
              (modelChange)="selectedDateExpedition($event)"
              [inputType]="'date'"
              [dataCyAttribute]="'order-dateExpedition'"
            ></form-row-input>
          </td>
          <td class="text-end no-hover" *ngIf="!canCreateOrEditDateExpedition && !canCreateOrEdit">
            {{ order.dateExpedition | strToDate | date: 'dd.MM.yyyy' }}
          </td>
        </tr>
        <tr>
          <th class="card-subheading">
            <div class="d-flex">
              {{ 'priority' | translate }}
            </div>
          </th>
          <td class="no-hover" *ngIf="canCreateOrEdit">
            <form-row-select
              [noMarginBottom]="true"
              [(model)]="order.priority"
              [options]="priorities"
              [label]="null"
              [icon]="null"
              [dataCyAttribute]="'order-priority'"
            ></form-row-select>
          </td>
          <td class="text-end no-hover" *ngIf="!canCreateOrEdit">
            {{ order.priority }}
          </td>
        </tr>
        <tr *ngIf="order.processStatus">
          <th
            class="text-nowrap card-subheading"
            title="{{
              order.processStatusRelation
                ? (order.processStatusRelation.created | date: 'dd.MM.yyyy HH:mm') +
                  ' | ' +
                  (order.processStatusRelation.createdBy ? order.processStatusRelation.createdBy.email : '') +
                  (order.processStatusRelation.note ? ' | ' + order.processStatusRelation.note : '')
                : ''
            }}"
          >
            {{ 'processStatus' | translate }}
          </th>
          <td class="no-hover">
            <modal-process-status
              (processStatusChange)="setToStatusReceived($event)"
              [processStates]="order.processStatusRelations"
            >
            </modal-process-status>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
