import { Component, OnInit, Input, Renderer2, OnDestroy } from '@angular/core'
import { CoreService } from '../../../services'
import { File, Order } from 'depoto-core/src/entities'
import { Events } from 'depoto-core/src/utils'
import { SchemaUtil } from '../../../utils'

@Component({
  selector: 'app-order-preview',
  templateUrl: './order-preview.component.html',
  styleUrls: ['./order-preview.component.scss'],
})
export class OrderPreviewComponent implements OnInit, OnDestroy {
  @Input() order: Partial<Order> | Order
  @Input() isOrderItemsPriceSum = false
  @Input() isOnOrdersPage = false
  @Input() isOnProductMovePackPage = false
  orderDetails: Order
  isFetching = false
  clickListenerSub = null
  isOpen = false

  constructor(
    public core: CoreService,
    public renderer: Renderer2
  ) {
    Events.listen('popover-on-close', (_, data) => {
      if (this.isOpen && this.order?.id && data.orderId !== this.order.id) {
        this.closePopover()
      }
    })
  }

  ngOnInit(): void {
    this.clickListenerSub = this.renderer.listen('document', 'click', event => {
      if (event['path']) {
        let isOnIcon = false
        let isOnPopover = false
        let isOnGallery = false
        event['path'].forEach(el => {
          if (el.className && el.className.includes('popover')) {
            isOnPopover = true
          }
          if (el.className && (el.className.includes('pswp') || el.className.includes('lightbox'))) {
            isOnGallery = true
          }
          if (el.className && el.className.includes('btn-outline-primary')) {
            isOnIcon = true
          }
        })
        if (!isOnPopover && !isOnGallery && !isOnIcon) {
          this.closePopover()
        }
      }
    })
  }
  ngOnDestroy() {
    this.clickListenerSub()
  }

  closePopover() {
    this.isOpen = false
  }

  getThumbUrl(image: File): string {
    return image.thumbnails.filter(t => t.format === 'w135wp')[0].url
  }
  get areAddressesSame(): boolean {
    const invoiceA = { ...this.order.invoiceAddress, id: 0 }
    const shippingA = { ...this.order.shippingAddress, id: 0 }
    return JSON.stringify(invoiceA) === JSON.stringify(shippingA)
  }

  async fetchOrderDetail(id: number) {
    if (this.isOpen) {
      this.closePopover()
      this.isOpen = false
      return
    }
    this.isFetching = true
    Events.dispatch('popover-on-close', { orderId: this.order.id })
    if (this.orderDetails) {
      this.isOpen = true
      this.isFetching = false
      return
    }
    this.orderDetails = await this.core.services.order.getById(id, SchemaUtil.orderDetails)
    this.isOpen = true
    this.isFetching = false
  }
}
