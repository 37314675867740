<modal-container
  #modal
  [headerTitle]="'orderItem.edit'"
  [headerIconClass]="'far-edit'"
  class="h-75"
  [isLargeModal]="true"
>
  <ng-container class="modal-button-show">
    <btn
      [icon]="'far-pencil'"
      [classes]="['btn-edit', 'btn-sm']"
      (onEvent)="showChildModal()"
      [dataCyAttribute]="'modal-clearance-item-show'"
    ></btn>
  </ng-container>
  <ng-container class="modal-body-content p-0" *ngIf="!loading">
    <div class="card">
      <div class="card-body p-0">
        <div class="row d-flex justify-content-between padding-right-7 padding-bottom">
          <form-row-toggle
            [label]="'isShowingNullPositions'"
            [(model)]="isShowingNullStock"
            [classesString]="'text-nowrap'"
          ></form-row-toggle>
          <p>
            {{ ('quantity.sum' | translate) + ': ' + (clearanceItem.amount | number: '1.0-2' | replaceCommaToDot) }}
          </p>
        </div>
        <div class="row" *ngFor="let location of locations; let i = index; trackBy: trackByFn">
          <div class="col-2">
            <form-row-select
              [label]="'depot'"
              [options]="availableDepots"
              [model]="locations[i].depot?.id"
              (modelChange)="setLocationsDepot(locations[i].uuid, $event)"
              [dataCyAttribute]="'clearanceItem-depot'"
            ></form-row-select>
          </div>
          <div class="col-7">
            <form-row-select
              [label]="'productDepot'"
              [options]="getAvailableProductDepotsInString(location.depot)"
              [model]="locations[i].productDepot?.id"
              (modelChange)="setLocationsProductDepot(locations[i].uuid, $event)"
            ></form-row-select>
            <form-row-checkbox [label]="'isLocked'" [(model)]="location.locked"></form-row-checkbox>
          </div>
          <div class="col-2">
            <form-row-number-with-controls
              [(model)]="location.amount"
              [minWidthPx]="60"
              [label]="'quantity'"
            ></form-row-number-with-controls>
          </div>
          <div
            *ngIf="locations?.length > 1"
            class="col-1 d-flex justify-content-center align-items-center mt-2 margin-bottom-2"
          >
            <btn
              [icon]="'far-trash-alt'"
              [title]="'delete' | translate"
              (onEvent)="removeLocation(location)"
              [classes]="['btn-sm', 'btn-trash']"
            ></btn>
          </div>
        </div>
        <btn [icon]="'far-plus'" (onEvent)="addLocation()" [classes]="['btn-first']"></btn>
      </div>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content">
    <btn [label]="'save'" [icon]="'far-check'" (onEvent)="updateClearanceItem()" [classes]="['btn-first']"></btn>
  </ng-container>
</modal-container>
