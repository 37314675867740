<modal-container
  #modal
  [headerTitle]="isCreating ? 'modal-order-items-sale.sale.add' : 'modal-order-items-sale.sale.edit'"
  [headerIconClass]="''"
  class="h-75"
  [isSmallModal]="true"
>
  <ng-container class="modal-button-show">
    <btn
      [label]="'modal-order-items-sale.sale'"
      [title]="'modal-order-items-sale.sale' | translate"
      [icon]="'far-percent'"
      [classes]="['btn-third', 'float-right', 'me-2']"
      (onEvent)="showChildModal()"
      [dataCyAttribute]="'modal-order-items-sale.show'"
    ></btn>
  </ng-container>
  <ng-container class="modal-body-content p-0">
    <div class="card">
      <div class="card-body p-0">
        <div class="row">
          <form-row-input
            #formRowInput
            [icon]="null"
            [inputType]="'number'"
            [title]="'modal-order-items-sale.percent'"
            [appendUnitPercent]="true"
            [(model)]="sale"
            [dataCyAttribute]="'modal-order-items-sale.value'"
          ></form-row-input>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content">
    <btn
      [label]="'save'"
      [title]="'save' | translate"
      [icon]="'far-plus'"
      (onEvent)="setSale()"
      [classes]="['btn-first']"
      [btnType]="'submit'"
    ></btn>
  </ng-container>
</modal-container>
