import { Pipe, PipeTransform } from '@angular/core'
import { Currency } from 'depoto-core/src/entities'
import { DecimalPipe } from '@angular/common'
import { ReplacePipe } from './replaceCommaWithDot.pipe'

@Pipe({ name: 'formatPrice' })
export class FormatPricePipe implements PipeTransform {
  constructor(
    private decimalPipe: DecimalPipe,
    private replacePipe: ReplacePipe
  ) {}

  transform(value: number | string, currency?: Currency | Partial<Currency>): string {
    let currencySuffix = 'Kč'
    let amount = '0.00'
    if (!currency || (currency && !currency.id)) {
      currency = new Currency({ id: 'CZK' })
    }
    currencySuffix = this.getCurrencySuffix(currency.id)
    if (!value || (value && Number(value) === 0)) {
      if (
        currency.id === 'EUR' ||
        currency.id === 'USD' ||
        currency.id === 'CAD' ||
        currency.id === 'JPY' ||
        currency.id === 'CHF' ||
        currency.id === 'CNY' ||
        currency.id === 'GBP'
      ) {
        return `${currencySuffix} ${amount}`
      }
      return `${amount} ${currencySuffix}`
    }
    if (typeof value === 'string' && value.includes(' ')) {
      // formatted from other pipes - with thousand separator
      value = value.replaceAll(' ', '')
    }
    amount = this.decimalPipe.transform(value, '1.2-2')
    amount = this.replacePipe.transform(amount)
    if (currency.id === 'EUR' || currency.id === 'USD' || currency.id === 'GBP') {
      return `${currencySuffix} ${amount}`
    }
    return `${amount} ${currencySuffix}`
  }

  getCurrencySuffix(currencyId: string): string {
    switch (currencyId) {
      default:
      case 'CZK':
        return 'Kč'
      case 'EUR':
        return '€'
      case 'GBP':
        return '£'
      case 'USD':
        return '$'
      case 'RUB':
        return '₽'
      case 'PLN':
        return 'zł'
      case 'CAD':
        return 'C$'
      case 'JPY':
        return '¥'
      case 'RON':
        return 'L'
      case 'HUF':
        return 'Ft'
      case 'HRK':
        return 'Kn'
      case 'CHF':
        return 'Fr'
      case 'CNY':
        return '¥'
    }
  }
}
