<div class="card">
  <div class="card-header no-print">
    <h3 class="card-title">
      <i class="fa fa-cube margin-right"></i>
      {{ 'component-list.atomic-components' | translate }}
    </h3>
  </div>
  <div class="card-body">
    <div>
      <p>Notifikace - ng-snotify</p>
      <btn (onEvent)="showSuccessNotification()" [label]="'component-list.notification.success'" [title]="'component-list.notification.success' | translate"></btn>
      <btn (onEvent)="showSuccessNotificationNoTimeout()" [label]="'component-list.notification.no-timeout'" [title]="'component-list.notification.no-timeout' | translate"></btn>
      <btn (onEvent)="showAllNotificationTypes()" [label]="'component-list.notification.all'" [title]="'component-list.notification.all' | translate"></btn>
    </div>
    <form-row-number-with-controls
      [model]="numberValue"
      (modelChange)="onValue()"
      [label]="null"
    ></form-row-number-with-controls>
    <div>
      <h3>&lt;btn&gt;</h3>
      <btn [label]="'button'" [title]="'button' | translate" [classes]="['btn-second']" (onEvent)="callSomeMethod($event)"></btn>
      <btn [classes]="['btn-first']" [icon]="'fa-user'" (onEvent)="callSomeMethod($event)"></btn>
      <btn
        [label]="'button.small.icon'"
        [title]="'button.small.icon' | translate "
        [classes]="['btn-primary', 'btn-xs']"
        [icon]="'fa-user'"
        (onEvent)="callSomeMethod($event)"
      ></btn>
      <btn [label]="'button.disabled'" [title]="'button.disabled' | translate" [classes]="['btn-first-red']" [icon]="'fa-user'" [btnDisabled]="true"></btn>
      <btn [label]="'button.submit'" [title]="'button.submit' | translate" [classes]="['btn-first']" [btnType]="'submit'"></btn>
      <!-- TODO: next two lines should be discussed-->

      <h3>&lt;form-row-checkbox&gt;</h3>
      <pre><strong>[value]</strong> can be 2way data-binding <strong>[(value)]</strong></pre>

      <h3>&lt;form-row-input&gt;</h3>
      <pre><strong>[value]</strong> can be 2way data-binding <strong>[(value)]</strong></pre>
      <form class="form">
        <form-row-input [label]="'Form row simple with default icon'" [model]="'value ...'"></form-row-input>
        <form-row-input
          [label]="'Form row simple'"
          [icon]="'fa-money'"
          [inputType]="'password'"
          [model]="'value ...'"
          [tooltipLabel]="'tooltip label'"
        ></form-row-input>
        <form-row-input
          [label]="'Form row disabled'"
          [icon]="'fa-close'"
          [inputType]="'password'"
          [model]="'value ...'"
          [inputDisabled]="true"
        ></form-row-input>
        <form-row-input
          [label]="'Form row'"
          (modelChange)="console.log($event)"
          [icon]="'fa-barcode'"
          [inputType]="'password'"
          [model]="'value ...'"
          [appendBtnLabel]="'product.ean.generate'"
          [appendBtnClasses]="['btn-first']"
          [appendBtnIcon]="'fa-barcode'"
          (onAppendBtnEvent)="callSomeMethod($event)"
        ></form-row-input>
      </form>

      <h3>&lt;form-row-select&gt;</h3>
      <pre><strong>[value]</strong> can be 2way data-binding <strong>[(value)]</strong></pre>
      <form class="form">
        <form-row-select
          [label]="'shop.country'"
          [icon]="'fa-home'"
          [(model)]="selectedOption"
          [options]="options"
        ></form-row-select>
        <form-row-select
          [label]="'shop.cert'"
          [icon]="'fa-certificate'"
          [model]="selectedOption2['id']"
          [options]="options2"
          [optionKey]="'id'"
          [optionKeyText]="'name'"
          (modelChange)="callSomeMethod($event)"
        ></form-row-select>
      </form>

      <h3>&lt;form-row-textarea&gt;</h3>
      <pre><strong>[value]</strong> can be 2way data-binding <strong>[(value)]</strong></pre>
      <form class="form">
        <form-row-textarea [label]="'Form row simple with default icon'" [model]="'value ...'"></form-row-textarea>
        <form-row-textarea
          [label]="'Form row simple'"
          [icon]="'fa-money'"
          [model]="'value ...'"
          [tooltipLabel]="'tooltip label'"
        ></form-row-textarea>
        <form-row-textarea
          [label]="'Form row disabled'"
          [icon]="'fa-close'"
          [model]="'value ...'"
          [inputDisabled]="true"
        ></form-row-textarea>
      </form>

      <h3>&lt;info-tooltip&gt;</h3>
      <div>
        <info-tooltip [label]="'label and default icon'"></info-tooltip>
        <info-tooltip [label]="'label and custom icon'" [icon]="'fa-user'"></info-tooltip>
      </div>

      <h3>Drag and drop</h3>
      <ul [dndDropzone]="[]" dndEffectAllowed="move" class="list-unstyled" (dndDrop)="onDrop($event, data)">
        <li dndPlaceholderRef class="dndPlaceholder"></li>
        <li *ngFor="let item of data" dndEffectAllowed="move" [dndDraggable]="item" (dndMoved)="onDragMove(item, data)">
          {{ item }}
        </li>
      </ul>

      <h3>Modal customer search</h3>
      <modal-customer-search></modal-customer-search>

      <h3>dropdown</h3>
      <div class="d-flex flex-row flex-direction-column-gap-5">
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DROPDOWN: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        <dropdown
          [icon]="'far-cog'"
          [items]="[{ action: 'foo', label: 'foo', isSelected: false }]"
          [isArrowHidden]="true"
          [autoClose]="true"
          (onResult)="callSomeMethod($event)"
        ></dropdown>
      </div>
    </div>
  </div>
</div>
