<div class="card">
  <div class="card-header">
    <h3 class="card-title">
      <i class="fa fa-bar-chart-o"></i>
      {{ 'checkout' | translate }} {{ checkout.name }}
    </h3>
    <div class="card-tools float-right">
      <form-row-select
        [icon]="null"
        [model]="selectedTimespan"
        [options]="timespans"
        (modelChange)="setTimespan($event); reloadCharts()"
      ></form-row-select>
    </div>
  </div>
  <div class="card-body" *ngIf="!isNoDataForChart">
    <div class="row">
      <div class="col-12 col-lg-6">
        <app-chart [type]="'carrierUsage'" [chartData]="carrierUsageData"></app-chart>
      </div>
      <div class="col-12 col-lg-6">
        <app-chart [type]="'paymentSales'" [chartData]="paymentSalesData"></app-chart>
      </div>
      <div class="col-12">
        <app-chart [type]="'bestsellers'" [chartData]="bestsellersData"></app-chart>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="isNoDataForChart">
    {{ 'chart.noDataForTimespan' | translate }}
  </div>
</div>
