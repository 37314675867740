import { filter, map, mergeMap } from 'rxjs/operators'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Events } from 'depoto-core/src/utils'
import { CoreService } from './services'
import { Company } from 'depoto-core/src/entities'
import { NgSelectConfig } from '@ng-select/ng-select'
import * as Sentry from '@sentry/angular'
import { HttpClient } from '@angular/common/http'
import { interval, Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { User } from 'depoto-core/src/entities/User'
import packageJson from '../../package.json'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  company: Company
  isShutdownForOverdue = false
  private subscriptions = new Subscription()
  isLoading = false
  isBaseModelLoaded = false

  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    private core: CoreService,
    private readonly httpClient: HttpClient,
    private ngSelectConfig: NgSelectConfig,
    private translateService: TranslateService
  ) {
    window['DEBUG'] = {
      exception: false,
      logger: false,
      history: false,
    }
    Events.listen('overdue:shutdown', () => {
      this.isShutdownForOverdue = true
    })
    // todo decide when is all loaded in, on every page
    Events.listen('init:loading', () => {
      this.isLoading = false
      this.isBaseModelLoaded = true
    })
    this.core.services.user.currentUserEmitter.subscribe().then((user: User) => {
      if (user) {
        this.translateService.use(user.locale)
      }
    })
    this.ngSelectConfig.addTagText = this.translateService.instant('select-config.add-tag')
    this.ngSelectConfig.clearAllText = this.translateService.instant('select-config.clear-all')
    this.ngSelectConfig.loadingText = this.translateService.instant('select-config.loading')
    this.ngSelectConfig.notFoundText = this.translateService.instant('select-config.not-found')
    this.ngSelectConfig.typeToSearchText = this.translateService.instant('select-config.search')
  }

  async ngOnInit() {
    this.listenForRoutingUpdates()
    this.checkVersion()
    this.loadSideMenuState()
    this.core.services.oauth.onLogoutEmitter.subscribe().then(() => {
      this.core.cleanLocalStorage(this.core.services.oauth.session.email, 'logout')
    })
    this.core.services.user.currentUserEmitter.subscribe().then(async user => {
      this.isBaseModelLoaded = false
      if (user?.id) {
        Events.dispatch('app:user:loaded')
        Sentry.setUser({ id: user.id, username: `${user.name} ${user.lastName}`, email: user.email })
        Sentry.setTag('company', user.company.name)
        // await this.core.loadLists()
      } else {
        Sentry.setUser(null)
        Sentry.setTag('company', 'undefined')
      }
    })
    this.core.onCoreEntityListsLoaded.subscribe(lists => {
      this.isBaseModelLoaded = true
    })
  }

  public isAuthenticated(): boolean {
    return this.core.services.oauth.isAuthenticated()
  }

  listenForRoutingUpdates() {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          if (route.firstChild) {
            route = route.firstChild
          }
          return route
        }),
        filter(route => route.outlet === 'primary')
      )
      .subscribe(route => {
        this.dispatchAnalyticsEvent()
      })
  }

  loadSideMenuState() {
    const sidebarState: string = this.core.services.storage.getSync(this.core.services.storage.keys.app.sideMenuState)
    if (!sidebarState || sidebarState === 'collapsed') {
      const bodyElement = document.getElementsByTagName('body')[0]
      bodyElement.classList.add('sidebar-closed')
      if (!bodyElement.classList.contains('sidebar-collapse')) {
        bodyElement.classList.add('sidebar-collapse')
      }
    }
  }

  toggleLeftMenu() {
    const bodyElement = document.getElementsByTagName('body')[0]
    bodyElement.classList.add('sidebar-closed')
    bodyElement.classList.add('sidebar-collapse')
    bodyElement.classList.remove('sidebar-open')
  }

  dispatchAnalyticsEvent() {
    // must be initialized from index.html
    const _paq = window['_paq'] || []
    _paq.push(['setReferrerUrl', window.location.href])
    const currentUrl = `${window.location.hash.substr(1)}`
    _paq.push(['setCustomUrl', currentUrl])
    _paq.push(['setDocumentTitle', document.title])
    _paq.push(['deleteCustomVariables', 'page'])
    _paq.push(['setGenerationTimeMs', 0])
    _paq.push(['trackPageView'])
    _paq.push(['MediaAnalytics::scanForMedia', document])
    _paq.push(['FormAnalytics::scanForForms', document])
    _paq.push(['trackContentImpressionsWithinNode', document])
    Events.dispatch('app:navigation-end')
  }

  private checkVersion(): void {
    const url = this.getUrlForCheckingAppVersion()
    if (!url) {
      // don't check for latest version on localhost
      return
    }
    this.httpClient.get<{ version: string; date: string }>(url).subscribe(resp => {
      if (packageJson.version !== resp.version) {
        this.onNewAppVersion()
      }
    })
    this.subscriptions.add(
      interval(2 * 60 * 1000)
        .pipe(
          mergeMap(() => {
            if (document.hasFocus()) {
              return this.httpClient.get<{ version: string; date: string }>(url)
            } else {
              return Promise.resolve(null)
            }
          })
        )
        .subscribe((response: any) => {
          if (response && response.version !== packageJson.version) {
            this.onNewAppVersion()
          }
        })
    )
  }

  private getUrlForCheckingAppVersion(): string {
    let result
    switch (location.host) {
      case 'app.depoto.cz.tomatomstage.cz':
        result = 'https://app.depoto.cz.tomatomstage.cz/version.json'
        break
      case 'app.depoto.cz':
        result = 'https://app.depoto.cz/version.json'
        break
    }
    return result
  }

  private onNewAppVersion(): void {
    if (confirm(this.translateService.instant('app.new-version'))) {
      if (window['nw_clear_cache']) {
        window['nw_clear_cache']()
        alert(this.translateService.instant('app.upgrade'))
      }
      window.location.reload()
    }
  }
}
