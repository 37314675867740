<div class="app-container">
  <page-header [title]="'categories.list'" [icon]="'far-cube'">
    <ng-container class="header-button">
      <btn
        *ngIf="canCreate"
        [routerLink]="['/category']"
        [label]="'category.create'"
        [title]="'category.create' | translate"
        [classes]="['btn-first', 'float-right']"
        [icon]="'far-plus'"
        [dataCyAttribute]="'go-category'"
      ></btn>
    </ng-container>
  </page-header>
  <category-tree [type]="categoryTreeTypeEnum.listCRUD"></category-tree>
</div>
