import { ActivatedRoute, Router } from '@angular/router'
import { inject } from '@angular/core'
import { CoreService } from '../../services'
import { TranslateService } from '@ngx-translate/core'
import { StorageKeyService } from 'src/app/services/storage-key.service'
type StorageKey = { type: string; key: string }

export abstract class BaseDetail {
  protected keyService = inject(StorageKeyService)
  protected constructor(
    public route: ActivatedRoute,
    public router: Router,
    public core: CoreService,
    public translateService: TranslateService
  ) {}

  getStorageKeyForEntity(entity: string): StorageKey {
    return this.keyService.getStorageKeyForEntity(entity, '', false)
  }
  /*
  saveViewSettings(viewName: string, data: any): void {
    this.core.services.storage.set(this.core.services.storage.keys.views[viewName], data)
  }
  loadViewSettings(viewName: string): any {
    return this.core.services.storage.getSync(this.core.services.storage.keys.views[viewName])
  }
   -- never used
  */

  onLoadError(errorCode: number) {
    switch (errorCode) {
      case 404:
      default:
        alert(this.translateService.instant('base-detail.not-found'))
        return this.router.navigate([`/${this.router.url.split('/')[1]}s`])
      case 403:
        if (confirm(this.translateService.instant('base-detail.insufficient-permissions'))) {
          window['lastRoute'] = [`/${this.route.snapshot.url[0].path}`, this.route.snapshot.params['id']]
          this.core.services.oauth.logout()
          // return this.router.navigate(['/login'])
        } else {
          return this.router.navigate([`/${this.route.snapshot.url[0].path}`])
        }
    }
  }
}
