import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Order } from 'depoto-core/src/entities'

@Component({
  selector: 'app-order-notes',
  templateUrl: './order-notes.component.html',
  styleUrls: ['./order-notes.component.scss'],
})
export class OrderNotesComponent {
  @Input() canCreateOrEditNotes = false
  @Input() order: Order
  @Output() orderChange: EventEmitter<any> = new EventEmitter()
}
