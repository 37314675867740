<div class="app-container">
  <page-header [icon]="'far-list-alt'" [title]="'stock.load'" [isStockOperation]="true">
    <ng-container class="header-btn">
      <div class="flex-gap-5 align-items-center">
        <modal-stock-operation-card
          #modalStockOperation
          [moves]="operations"
          [hideButton]="true"
          [alwaysLoadSelectData]="true"
          [isCreatingProduct]="false"
          [producers]="producers"
          [vats]="vats"
          [depotFrom]="selectedDepotFrom"
          [depotTo]="selectedDepotTo"
          [type]="selectedOperationType"
          [depots]="depots"
          [suppliers]="suppliers"
          (result)="operationListChange($event)"
        >
        </modal-stock-operation-card>
        <!--        TODO: finalize EAN printing-->
        <btn
          *ngIf="operations?.length"
          [label]="isPrintingEANs ? 'back' : 'product.tab.barcode'"
          [title]="isPrintingEANs ? ('back' | translate) : ('product.tab.barcode' | translate)"
          [icon]="isPrintingEANs ? 'far-list' : 'far-sticky-note'"
          [classes]="['btn-third', 'float-right', 'me-2']"
          (onEvent)="isPrintingEANs = !isPrintingEANs"
        ></btn>
        <btn
          [label]="
            selectedOperationType === 'in'
              ? 'product.load'
              : selectedOperationType === 'out'
                ? 'product.unload'
                : 'product.transfer'
          "
          [title]="
            selectedOperationType === 'in'
              ? ('product.load' | translate)
              : selectedOperationType === 'out'
                ? ('product.unload' | translate)
                : ('product.transfer' | translate)
          "
          [icon]="
            selectedOperationType === 'in'
              ? 'far-layer-plus'
              : selectedOperationType === 'out'
                ? 'far-layer-minus'
                : 'far-layer-minus'
          "
          [classes]="[
            selectedOperationType === 'in'
              ? 'btn-first-green'
              : selectedOperationType === 'out'
                ? 'btn-first-red'
                : 'btn-first-orange',
            'btn-sm'
          ]"
          (onEvent)="createStockLoad()"
          *ngIf="!loading && operations?.length"
          data-cy="submit-stock-form"
        >
        </btn>
      </div>
      <div class="row d-flex flex-gap-10 ml-0">
        <div *ngIf="selectedOperationType !== 'in'">
          <div class="form-group mb-0 flex-gap-5 align-items-center">
            <label class="mb-0 text-nowrap">{{ 'depot.from' | translate }}:</label>
            <select
              name="depotSelectFrom"
              id="depotSelectFrom"
              class="form-control custom"
              [(ngModel)]="selectedDepotFrom"
              (pointerdown)="onPointerDown($event)"
              (ngModelChange)="filter()"
              data-cy="select-depot-from"
            >
              <ng-template ngFor let-depot [ngForOf]="depots">
                <ng-template [ngIf]="selectedOperationType === 'out' || selectedOperationType === 'transfer'">
                  <!--                <ng-template [ngIf]="depot.id != selectedDepotTo">-->
                  <option value="{{ depot.id }}">{{ depot.name }}</option>
                </ng-template>
              </ng-template>
            </select>
          </div>
        </div>
        <div *ngIf="selectedOperationType === 'in' || selectedOperationType === 'transfer'">
          <div class="form-group mb-0 flex-gap-5 align-items-center">
            <label class="mb-0 text-nowrap">{{ 'depot.to' | translate }}:</label>
            <select
              name="depotSelectTo"
              id="depotSelectTo"
              class="form-control custom"
              [(ngModel)]="selectedDepotTo"
              (pointerdown)="onPointerDown($event)"
              data-cy="select-depot"
            >
              <ng-template ngFor let-depot [ngForOf]="depots">
                <ng-template [ngIf]="selectedOperationType === 'in' || selectedOperationType === 'transfer'">
                  <option value="{{ depot.id }}">{{ depot.name }}</option>
                </ng-template>
              </ng-template>
            </select>
            <!--select
              name="depotSelectToDis"
              id="depotSelectToDis"
              class="form-control"
              [(ngModel)]="selectedDepotTo"
              *ngIf="operations?.length"
              disabled
            >
              <ng-template ngFor let-depot [ngForOf]="depots">
                <ng-template [ngIf]="depot.id != selectedDepotFrom">
                  <option value="{{ depot.id }}">{{ depot.name }}</option>
                </ng-template>
              </ng-template>
            </select-->
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container class="stock-operation-buttons">
      <div class="operation-type-wrap">
        <btn
          *ngFor="let type of operationTypes"
          [label]="type"
          data-cy="nav-button"
          [title]="type | translate"
          [btnDisabled]="operations?.length"
          (onEvent)="setOperationType(type)"
          [classes]="[selectedOperationType === type ? 'btn btn-white-selected' : 'btn btn-white']"
        ></btn>
      </div>
    </ng-container>
  </page-header>
  <div class="container-fluid">
    <div class="row cards-responsive">
      <div class="col-12 col-sm-12 col-1100 col-lg-6 col-xl-6 ps-0">
        <div class="card mb-0" *ngIf="!isPrintingEANs">
          <div class="row" *ngIf="!isPrintingEANs && selectedOperationType === 'in'">
            <div class="col d-flex align-self-center">
              <div class="card-title">
                {{ 'product.search' | translate }}
              </div>
            </div>
            <div class="col mt-md-1 d-flex justify-content-end">
              <modal-batch-import-from-file
                class="me-2"
                [depotTo]="selectedDepotTo"
                [suppliers]="suppliers"
                [isCreatingProducts]="true"
                (resultBatch)="createBatchFromFile($event)"
              ></modal-batch-import-from-file>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-12">
              <div class="form-group ps-0 pe-0 ml-0 mr-0 pt-2">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="searchFulltext"
                    (keydown)="filterKeyDown($event)"
                    data-cy="search-product-fulltext"
                  />
                  <div class="input-group-btn">
                    <button type="button" class="btn-search" (click)="filter()" [title]="'filter' | translate">
                      <i class="far fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="row"
            *ngIf="!products || (!products.length && loading)"
            [ngClass]="{ h150: !products?.length && loading }"
          >
            <div class="col-12">
              <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
            </div>
          </div>

          <div class="text-center" *ngIf="selectedOperationType !== 'in' && !loading && !selectedDepotFrom">
            {{ 'depot.select' | translate }}
          </div>

          <div
            *ngIf="selectedOperationType === 'in' && !products?.length && !loading"
            class="margin text-center"
            style="padding: 1.625rem"
          >
            {{ 'no.items' | translate }}
          </div>

          <div
            *ngIf="
              selectedOperationType !== 'transfer' &&
              products?.length &&
              (selectedOperationType === 'in' || selectedDepotFrom)
            "
            class="row prod-list-scrolling"
          >
            <div class="col">
              <div class="tableWrapper">
                <table class="table table-responsive" [ngClass]="{ h150: !products?.length && loading }">
                  <loading-spinner
                    [isLoading]="loading && (searchFulltext?.length || currentPage === 1)"
                    [classesString]="'pt-6'"
                  ></loading-spinner>
                  <div *ngIf="products?.length">
                    <thead>
                      <tr>
                        <th class="ps-0">{{ 'product.name' | translate }}</th>
                        <th *ngIf="selectedOperationType === 'transfer'" class="text-center fixed-column-width">
                          {{ ('product.expirationDate' | translate) + ', ' + ('product.batch' | translate) }}
                        </th>
                        <th class="text-center fixed-column-width">{{ 'products.availability' | translate }}</th>
                        <th class="fixed-column-width"></th>
                      </tr>
                    </thead>
                    <tbody
                      *ngIf="selectedOperationType !== 'transfer'"
                      class="images"
                      [photoGalleryGroup]="{ index: 1 }"
                    >
                      <tr *ngFor="let prod of products">
                        <td class="ps-1 custom-product-td" [ngClass]="{ 'disabled-product': !prod.enabled }">
                          <div class="flex-gap-10 align-items-center">
                            <div
                              [photoGallery]="prod.mainImage.url"
                              [photoGalleryCaption]="
                                prod
                                  ? prod.fullName +
                                    '<br>' +
                                    (prod.code ? ('product.code' | translate) + prod.code : '') +
                                    '<br>' +
                                    (prod.ean ? ' EAN: ' + prod.ean : '')
                                  : ''
                              "
                            >
                              <div class="images-item">
                                <img
                                  class="images-item-image"
                                  [src]="getThumbUrl(prod.mainImage)"
                                  [alt]="prod.fullName"
                                />
                              </div>
                            </div>
                            <div>
                              <div>
                                <a class="link-bold product-name-nowrap-wrapper" [routerLink]="['/product', prod.id]">
                                  {{ prod ? prod.fullName : '' }}
                                </a>
                              </div>
                              <div class="input-group-append flex-wrap product-center">
                                <div class="product-details" *ngIf="prod.code">
                                  {{ 'product.code' | translate }}:<span
                                    [ngClass]="{ 'disabled-product': !prod.enabled }"
                                  >
                                    {{ prod.code }}</span
                                  >
                                </div>
                                <div class="product-details" *ngIf="prod.ean">
                                  {{ 'product.ean' | translate }}:<span
                                    [ngClass]="{ 'disabled-product': !prod.enabled }"
                                  >
                                    {{ prod.ean }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                          <modal-stock-overview
                            class="availability-numbers-wrap"
                            [isSumCalculated]="true"
                            [isCalculatedOfProductDepots]="true"
                            [isSmallBtn]="true"
                            [product]="prod"
                            [depots]="depots"
                            [suppliers]="suppliers"
                            [filterDepotIdFrom]="
                              selectedOperationType === 'out' || selectedOperationType === 'transfer'
                                ? selectedDepotFrom
                                : selectedDepotTo
                            "
                          >
                          </modal-stock-overview>
                        </td>
                        <td class="pe-1">
                          <div class="flex-gap-10 align-items-center">
                            <div>
                              <modal-stock-operation-card
                                [moves]="operations"
                                [(movesOriginPositions)]="movesOriginPositions"
                                [product]="prod"
                                [depotFrom]="selectedDepotFrom"
                                [depotTo]="selectedDepotTo"
                                [type]="selectedOperationType"
                                [producers]="producers"
                                [vats]="vats"
                                [suppliers]="suppliers"
                                [depots]="depots"
                                [initDateDUZP]="initDateDUZP"
                                (openModalSerialNumbers)="openModalSerialNumbers($event)"
                                (result)="operationListChange($event)"
                                [dataCyAttribute]="'operation-card'"
                              >
                              </modal-stock-operation-card>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </div>
                </table>
              </div>
            </div>
          </div>

          <div
            *ngIf="selectedOperationType === 'transfer' && selectedDepotFrom && products?.length"
            class="row prod-list-scrolling"
          >
            <div class="col">
              <div class="tableWrapper">
                <table class="table table-responsive" [ngClass]="{ h150: !products?.length && loading }">
                  <loading-spinner
                    [isLoading]="loading && (searchFulltext?.length || currentPage === 1)"
                    [classesString]="'pt-6'"
                  ></loading-spinner>
                  <div>
                    <thead>
                      <tr>
                        <th class="ps-0">{{ 'product.name' | translate }}</th>
                        <th class="text-center fixed-column-width">{{ 'position' | translate }}</th>
                        <th class="text-center fixed-column-width">
                          {{ ('product.expirationDate' | translate) + ', ' + ('product.batch' | translate) }}
                        </th>
                        <th class="text-center fixed-column-width">{{ 'products.availability' | translate }}</th>
                        <th class="fixed-column-width"></th>
                      </tr>
                    </thead>
                    <tbody class="images" [photoGalleryGroup]="{ index: 1 }">
                      <ng-container *ngFor="let prod of products">
                        <tr *ngFor="let pDepot of getProductDepotsWithQuantity(prod)">
                          <td class="ps-1 custom-product-td" [ngClass]="{ 'disabled-product': !prod.enabled }">
                            <div class="flex-gap-10 align-items-center">
                              <div
                                [photoGallery]="prod.mainImage.url"
                                [photoGalleryCaption]="
                                  prod
                                    ? prod.fullName +
                                      '<br>' +
                                      (prod.code ? ('product.code' | translate) + prod.code : '') +
                                      '<br>' +
                                      (prod.ean ? ' EAN: ' + prod.ean : '')
                                    : ''
                                "
                              >
                                <div class="images-item">
                                  <img
                                    class="images-item-image"
                                    [src]="getThumbUrl(prod.mainImage)"
                                    [alt]="prod.fullName"
                                  />
                                </div>
                              </div>
                              <div>
                                <div>
                                  <a class="link-bold product-name-nowrap-wrapper" [routerLink]="['/product', prod.id]">
                                    {{ prod ? prod.fullName : '' }}
                                  </a>
                                </div>
                                <div class="input-group-append flex-wrap product-center">
                                  <div class="product-details" *ngIf="prod.code">
                                    {{ 'product.code' | translate }}:<span
                                      [ngClass]="{ 'disabled-product': !prod.enabled }"
                                    >
                                      {{ prod.code }}</span
                                    >
                                  </div>
                                  <div class="product-details" *ngIf="prod.ean">
                                    {{ 'product.ean' | translate }}:<span
                                      [ngClass]="{ 'disabled-product': !prod.enabled }"
                                    >
                                      {{ prod.ean }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="text-center text-nowrap fixed-column-width">
                            {{ pDepot.position }}
                          </td>
                          <td class="text-center">
                            <div>
                              {{ pDepot.expirationDate | fmtDate: 'dd.MM.yyyy' }}
                            </div>
                            <div>
                              {{ pDepot.batch }}
                            </div>
                          </td>
                          <td class="text-center">
                            {{ pDepot.quantityStock | number: '1.0-2' | replaceCommaToDot }}
                          </td>
                          <td class="pe-1">
                            <div class="flex-gap-10 align-items-center">
                              <div>
                                <!--                            přidat input že to bude prodDepot a ne product:/-->
                                <modal-stock-operation-card
                                  [product]="prod"
                                  [productDepot]="pDepot"
                                  [depotFrom]="selectedDepotFrom"
                                  [depotTo]="selectedDepotTo"
                                  [type]="'transfer'"
                                  [producers]="producers"
                                  [vats]="vats"
                                  [suppliers]="suppliers"
                                  [depots]="depots"
                                  [initDateDUZP]="initDateDUZP"
                                  [isCreatingProduct]="false"
                                  [moves]="operations"
                                  [(movesOriginPositions)]="movesOriginPositions"
                                  [depotQuantity]="pDepot.quantityStock"
                                  (result)="operationListChange($event)"
                                  [dataCyAttribute]="'operation-card'"
                                >
                                </modal-stock-operation-card>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </div>
                </table>
              </div>
            </div>
          </div>
          <app-pagination-btn
            *ngIf="
              (!this.currentPage || this.currentPage <= this.endPage) &&
              this.totalItems > this.products?.length &&
              (selectedOperationType === 'in' || selectedDepotFrom)
            "
            (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
            [minViewportWidth]="1100"
            [endPage]="endPage"
            [sortDir]="sortDir"
            [sortProp]="sortProp"
            [loadingPage]="loading"
            [currentPage]="currentPage"
          >
          </app-pagination-btn>
        </div>
        <div class="row" *ngIf="isPrintingEANs">
          <div class="col-12">
            <!--            <barcode-list-print-array [operations]="operations"></barcode-list-print-array>-->
            <print-code
              #printCodeComponent
              [productMovePack]="{ moves: operations }"
              [doRecalculateArray]="false"
            ></print-code>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-sm-12 col-1100 pe-0"
        [ngClass]="{ 'col-lg-6': !isPrintingEANs, 'col-xl-6': !isPrintingEANs }"
      >
        <div class="card mb-0">
          <div class="flex-row">
            <btn
              *ngIf="isPrintingEANs"
              [label]="'select.invert'"
              [title]="'select.invert' | translate"
              [icon]="'far-exchange'"
              [classes]="['btn-third', 'float-right', 'me-2']"
              (onEvent)="printingSelect('invert')"
            ></btn>
            <btn
              *ngIf="isPrintingEANs"
              [label]="'select.none'"
              [title]="'select.none' | translate"
              [icon]="'far-square'"
              [classes]="['btn-third', 'float-right', 'me-2']"
              (onEvent)="printingSelect('none')"
            ></btn>
            <btn
              *ngIf="isPrintingEANs"
              [label]="'select.all'"
              [title]="'select.all' | translate"
              [icon]="'far-check-square'"
              [classes]="['btn-third', 'float-right', 'me-2']"
              (onEvent)="printingSelect('all')"
            ></btn>
          </div>
          <div class="row" *ngIf="!isPrintingEANs">
            <div class="col d-flex align-self-center">
              <div class="card-title text-nowrap" *ngIf="selectedOperationType === 'in'" data-cy="batch-title">
                {{ 'stock.load.list' | translate }}
              </div>
              <div class="card-title" *ngIf="selectedOperationType === 'out'" data-cy="batch-title">
                {{ 'stock.unload.list' | translate }}
              </div>
              <div class="card-title" *ngIf="selectedOperationType === 'transfer'" data-cy="batch-title">
                {{ 'stock.transfer.list' | translate }}
              </div>
            </div>
            <div class="col mt-md-1 d-flex justify-content-end">
              <btn
                [label]="'clear.options'"
                [title]="'clear.options' | translate"
                [icon]="'far-trash-alt'"
                [classes]="['btn-first-red', 'btn-sm', 'me-2']"
                (onEvent)="clearList()"
                *ngIf="!isPrintingEANs && operations?.length"
                data-cy="cancel-stock"
              ></btn>
              <btn
                *ngIf="operations?.length"
                [label]="'print'"
                [title]="'print' | translate"
                [icon]="'far-print'"
                [classes]="['btn-third', 'btn-sm', 'me-2']"
                (onEvent)="printList()"
                data-cy="print-products"
              ></btn>
              <modal-batch-import-from-file
                *ngIf="!isPrintingEANs && selectedOperationType === 'in'"
                [depotTo]="selectedDepotTo"
                [suppliers]="suppliers"
                (resultBatch)="importBatchFromFile($event)"
              ></modal-batch-import-from-file>
            </div>
          </div>
          <form-row-input [label]="'duzp'" [inputType]="'date'" [(model)]="initDateDUZP"></form-row-input>
          <div *ngIf="!operations?.length" style="text-align: center; padding: 1.625rem">
            <div class="empty-list">
              <i class="far fa-sad-tear"></i>
              <div>
                {{ 'empty.list' | translate }}
              </div>
            </div>
          </div>
          <div
            class="table-responsive no-print"
            *ngIf="operations?.length"
            [ngClass]="{ 'scrollable-list-short': isPrintingEANs }"
          >
            <table class="width-fill-available m-0">
              <thead>
                <tr>
                  <th *ngIf="isPrintingEANs" class="text-align-center padding-right-05">
                    {{ 'product.printEAN' | translate }}
                  </th>
                  <th>{{ 'product.name' | translate }}</th>
                  <th class="text-center fixed-column-width">
                    {{ ('product.expirationDate' | translate) + ', ' + ('product.batch' | translate) }}
                  </th>
                  <th class="padding-right-05 text-align-center">{{ 'position' | translate }}</th>
                  <th class="padding-right-05 text-align-center text-center">{{ 'quantity' | translate }}</th>
                  <th>
                    {{ 'purchasePrice' | translate }}
                  </th>
                  <th *ngIf="!isPrintingEANs">&nbsp;</th>
                  <th *ngIf="!isPrintingEANs">&nbsp;</th>
                </tr>
              </thead>
              <tbody *ngIf="selectedOperationType !== 'out'">
                <tr *ngFor="let operation of operations">
                  <td *ngIf="isPrintingEANs" class="text-align-center">
                    <input type="checkbox" [(ngModel)]="operation.isEANPrintable" (change)="refreshOperationsHack()" />
                  </td>
                  <td *ngIf="operation.product">
                    <div class="flex-gap-10 align-items-center">
                      <product-image [product]="operation.product"></product-image>
                      <div>
                        <a class="link-bold align-self-center" [routerLink]="['/product', operation.product.id]">
                          {{ operation.product ? operation.product.fullName : '' }}
                        </a>
                        <div class="flex-column-gap-10 flex-wrap flex-direction-row">
                          <div
                            class="flex-gap-5 flex-wrap product-details"
                            *ngIf="selectedOperationType === 'out' && operation.product.supplier"
                          >
                            {{ ('supplier' | translate) + ': ' + operation.product.supplier.name }}
                          </div>
                          <div class="flex-gap-5 flex-wrap product-details" *ngIf="operation.product.code">
                            {{ ('product.code' | translate) + ': ' + operation.product.code }}
                          </div>
                          <div class="flex-gap-5 flex-wrap product-details" *ngIf="operation.product.ean">
                            {{ ('product.ean' | translate) + ': ' + operation.product.ean }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                      {{ operation.expirationDate | fmtDate: 'dd.MM.yyyy' }}
                    </div>
                    <div class="text-center">
                      {{ operation.batch }}
                    </div>
                  </td>
                  <td class="width-max-content text-nowrap text-center">
                    <div *ngIf="selectedOperationType === 'transfer'" class="strikethrough">
                      {{ getOperationsOriginPosition(operation) }}
                    </div>
                    <div>
                      {{
                        operation.position1 || operation.position2 || operation.position3
                          ? (operation.position1 ? operation.position1 : '') +
                            '-' +
                            (operation.position2 ? operation.position2 : '') +
                            '-' +
                            (operation.position3 ? operation.position3 : '')
                          : ''
                      }}
                    </div>
                  </td>
                  <td class="width-max-content text-nowrap text-end">
                    {{ (operation.amount | number: '1.0-2' | replaceCommaToDot) || 0 }}
                  </td>
                  <td class="width-max-content text-nowrap text-end">
                    {{ operation.purchasePrice | formatPrice: { id: operation.purchaseCurrency } }}
                  </td>
                  <td *ngIf="!isPrintingEANs" class="pe-1 width-max-content">
                    <div class="flex-gap-5 align-self-center float-right">
                      <btn
                        class="d-flex align-items-center"
                        [title]="'delete' | translate"
                        [icon]="'far-trash-alt'"
                        (onEvent)="removeOperation(operation)"
                        [classes]="['btn-sm', 'btn-trash']"
                      >
                      </btn>
                      <modal-stock-operation-card
                        class="d-flex align-items-center"
                        [operationToUpdate]="operation"
                        [product]="operation.product"
                        [depotFrom]="selectedDepotFrom"
                        [depotTo]="selectedDepotTo"
                        [type]="selectedOperationType"
                        [producers]="producers"
                        [vats]="vats"
                        [suppliers]="suppliers"
                        [depots]="depots"
                        (openModalSerialNumbers)="openModalSerialNumbers($event)"
                        (result)="updateOperation($event)"
                      >
                      </modal-stock-operation-card>
                    </div>
                  </td>
                  <td *ngIf="!isPrintingEANs">
                    <app-modal-serial-numbers
                      #modalSerialNumbers
                      *ngIf="operation.product?.requiredSerialNumbers && selectedOperationType !== 'transfer'"
                      [productMove]="operation"
                      [isCreatingProductMovePack]="true"
                      (serialNumbersEdited)="operation.serialNumbers = $event; updateOperation(operation)"
                    >
                    </app-modal-serial-numbers>
                  </td>
                </tr>
                <tr *ngIf="operations?.length && !isPrintingEANs">
                  <td class="no-hover">
                    <strong>{{ 'sum' | translate }}</strong>
                  </td>
                  <td class="no-hover">&nbsp;</td>
                  <td class="no-hover text-start">&nbsp;</td>
                  <td class="text-end text-nowrap no-hover">
                    <div class="flex-gap-10 justify-content-end">
                      <strong>{{ 'bill.quantity' | translate }}: {{ operations | listLength }}</strong>
                      <strong>/</strong>
                      <strong
                        >{{ 'bill.amount' | translate }}: {{ operations | operationsPriceSum: ['quantity'] }}</strong
                      >
                    </div>
                  </td>
                  <td class="text-end text-nowrap no-hover">
                    <strong>{{ operations | operationsPriceSum: ['purchase'] | formatPrice }}</strong>
                  </td>
                  <td class="no-hover">&nbsp;</td>
                </tr>
              </tbody>

              <tbody *ngIf="selectedOperationType === 'out'">
                <tr *ngFor="let operation of operations">
                  <td *ngIf="isPrintingEANs" class="text-align-center">
                    <input type="checkbox" [(ngModel)]="operation.isEANPrintable" (change)="refreshOperationsHack()" />
                  </td>
                  <td class="flex-gap-10 align-items-center" *ngIf="operation.product">
                    <product-image [product]="operation.product"></product-image>
                    <div>
                      <a class="link-bold align-self-center" [routerLink]="['/product', operation.product.id]">
                        {{ operation.product ? operation.product.fullName : '' }}
                      </a>
                      <div class="flex-column-gap-10 flex-wrap flex-direction-row">
                        <div class="flex-gap-5 flex-wrap product-details" *ngIf="operation.product.code">
                          {{ ('product.code' | translate) + ': ' + operation.product.code }}
                        </div>
                        <div class="flex-gap-5 flex-wrap product-details" *ngIf="operation.product.ean">
                          {{ ('product.ean' | translate) + ': ' + operation.product.ean }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                      {{ operation.expirationDate | fmtDate: 'dd.MM.yyyy' }}
                    </div>
                    <div class="text-center">
                      {{ operation.batch }}
                    </div>
                  </td>
                  <td class="width-max-content text-nowrap text-center">
                    {{
                      operation.position1 || operation.position2 || operation.position3
                        ? (operation.position1 ? operation.position1 : '') +
                          '-' +
                          (operation.position2 ? operation.position2 : '') +
                          '-' +
                          (operation.position3 ? operation.position3 : '')
                        : ''
                    }}
                  </td>
                  <td class="width-max-content text-nowrap text-end">
                    {{ (operation.amount | number: '1.0-2' | replaceCommaToDot) || 0 }}
                  </td>
                  <td class="width-max-content text-nowrap text-end">
                    {{ operation.purchasePrice | formatPrice }}
                  </td>
                  <td *ngIf="!isPrintingEANs" class="pe-1 width-max-content">
                    <div class="flex-gap-5 align-self-center float-right">
                      <btn
                        class="d-flex align-items-center"
                        [title]="'delete' | translate"
                        [icon]="'far-trash-alt'"
                        (onEvent)="removeOperation(operation)"
                        [classes]="['btn-sm', 'btn-trash']"
                      >
                      </btn>
                      <app-modal-serial-numbers
                        #modalSerialNumbers
                        *ngIf="operation.product?.requiredSerialNumbers && selectedOperationType !== 'transfer'"
                        [productMove]="operation"
                        [isCreatingProductMovePack]="true"
                        (serialNumbersEdited)="operation.serialNumbers = $event"
                      >
                      </app-modal-serial-numbers>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="operations?.length && !isPrintingEANs">
                  <td class="no-hover">
                    <strong>{{ 'sum' | translate }}</strong>
                  </td>
                  <td class="no-hover">&nbsp;</td>
                  <td class="no-hover">&nbsp;</td>
                  <td class="text-nowrap no-hover text-end">
                    <div class="flex-gap-10 justify-content-end">
                      <strong>{{ 'bill.quantity' | translate }}: {{ operations | listLength }}</strong>
                      <strong>/</strong>
                      <strong
                        >{{ 'bill.amount' | translate }}: {{ operations | operationsPriceSum: ['quantity'] }}</strong
                      >
                    </div>
                  </td>
                  <td class="text-end text-nowrap no-hover">
                    <strong>{{ operations | operationsPriceSum: ['purchase'] | formatPrice }}</strong>
                  </td>
                  <td class="no-hover">&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-2" *ngIf="!isPrintingEANs">
            <div class="col-12 ps-0 pe-0">
              <div class="operation-list mt-2 advanced">
                <div class="row">
                  <div class="col-12">
                    <div class="operation-card">
                      <form-row-input
                        [label]="'externalReference'"
                        [icon]="null"
                        [model]="externalReference"
                        (modelChange)="updateExternalReference($event)"
                        [dataCyAttribute]="'stock-external reference'"
                      ></form-row-input>
                      <!--                      todo to cypress test -> input external reference-->
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="operation-card">
                      <form-row-textarea
                        [label]="'note'"
                        [icon]="null"
                        [model]="note"
                        (modelChange)="updateNote($event)"
                        [dataCyAttribute]="'stock-note'"
                      ></form-row-textarea>
                    </div>
                  </div>
                </div>
                <div class="operation-card mt-2">
                  <div class="row" *ngIf="uploadedFiles?.length">
                    <div class="col-12 pe-0">
                      <div class="table-responsive">
                        <table class="w-100">
                          <tbody>
                            <tr *ngFor="let f of uploadedFiles">
                              <td class="ps-1">
                                <div class="flex-gap-10">
                                  <i class="fa fa-file-image" *ngIf="f.mimeType?.includes('image/')" title="Image"></i>
                                  <i
                                    class="fa fa-file-text"
                                    *ngIf="
                                      f.mimeType?.includes('text/') ||
                                      f.mimeType?.includes('/msword') ||
                                      f.mimeType?.includes('wordprocessingml')
                                    "
                                    title="Text"
                                  ></i>
                                  <i
                                    class="fa fa-file-pdf"
                                    *ngIf="f.mimeType?.includes('application/pdf')"
                                    title="Pdf"
                                  ></i>
                                  <i
                                    class="fa fa-file-excel"
                                    *ngIf="f.mimeType?.includes('spreadsheet') || f.mimeType?.includes('ms-excel')"
                                    title="Excel"
                                  ></i>
                                  <i class="fa fa-file" *ngIf="unknownType(f.mimeType)" title="File"></i>
                                  <a class="link" href="{{ f.url }}" target="_blank">
                                    {{ f.originalFilename || f.text || 'file' }}
                                  </a>
                                </div>
                              </td>
                              <td class="pe-1">
                                <btn
                                  class="d-flex align-items-center"
                                  [title]="'delete' | translate"
                                  [icon]="'far-trash-alt'"
                                  [classes]="['btn-trash', 'btn-sm', 'float-right']"
                                  (onEvent)="removeUploadedFile(f)"
                                ></btn>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <loading-spinner [isLoading]="areFilesUploading" [classesString]="'top-25'"></loading-spinner>
                      <form-row-input
                        [label]="'files'"
                        [icon]="'far-upload'"
                        [inputType]="'file'"
                        [inputMultiple]="true"
                        (valueChangeNative)="uploadFiles($event)"
                      ></form-row-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
