<div class="row">
  <loading-spinner [isLoading]="orderDetailService.loading" [classesString]="'top-50'"></loading-spinner>
  <div *ngIf="!orderDetailService.loading" class="col-12 col-md-6 mx-auto">
    <div class="card">
      <modal-address-choose
        *ngIf="invoiceAddresses?.length > 1"
        [addresses]="invoiceAddresses"
        (onSelectedAddress)="selectAddress($event, 'invoice')"
      ></modal-address-choose>
      <div class="card-title mb-3">
        <h3>{{ 'address.invoice' | translate }}</h3>
      </div>
      <div class="card-text">
        <address-form [(address)]="orderDetailService.order.invoiceAddress"></address-form>
      </div>
    </div>
  </div>
  <div *ngIf="!orderDetailService.loading" class="col-12 col-md-6">
    <div class="card">
      <div class="row">
        <div class="col-12 d-flex justify-content-between align-items-center">
          <div class="card-title">
            <h3>{{ 'address.shipping' | translate }}</h3>
          </div>
          <modal-address-choose
            *ngIf="shippingAddresses?.length && !orderDetailService.isShippingAddressSameAsInvoice"
            [addresses]="shippingAddresses"
            (onSelectedAddress)="selectAddress($event, 'shipping')"
          ></modal-address-choose>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <form-row-toggle
            [label]="'address.same'"
            [model]="orderDetailService.isShippingAddressSameAsInvoice"
            (modelChange)="invoiceShippingRelationChange($event)"
            [cursorPointer]="true"
            [classesString]="'text-nowrap'"
            [dataCyAttribute]="'toggle'"
          ></form-row-toggle>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <address-form
            *ngIf="!orderDetailService.isShippingAddressSameAsInvoice"
            [(address)]="orderDetailService.order.shippingAddress"
          ></address-form>
        </div>
      </div>
    </div>
  </div>
</div>
