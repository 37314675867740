import { Component, OnInit } from '@angular/core'
import { ProductParameter } from 'depoto-core/src/entities'
import { CoreService } from '../../../services'
import { SchemaUtil } from '../../../utils'
import { ProductDetailService } from '../../../product-detail.service'

@Component({
  selector: 'product-parameters',
  templateUrl: './product-parameters.component.html',
})
export class ProductParametersComponent implements OnInit {
  isTrueFalseOptions: string[] = ['all', 'true', 'false']
  loading = true

  constructor(
    private core: CoreService,
    public productDetailService: ProductDetailService
  ) {}

  async ngOnInit() {
    await this.getParameters()
  }

  async getParameters() {
    this.productDetailService.freshProductParameters = [...this.productDetailService.product.productParameters]
    const allParams = await this.core.services.parameter.getList({}, SchemaUtil.parameter).then(res => res.items)
    const allProductParams = [
      ...allParams.map(p => new ProductParameter({ product: this.productDetailService.product, parameter: p })),
    ]
    allProductParams.forEach((param, i) => {
      if (this.isStoredOnProduct(param)) {
        allProductParams[i] = this.getStoredOnProduct(param)[0]
      }
    })
    this.productDetailService.parameters = allProductParams
    this.loading = false
  }

  getStoredOnProduct(param): ProductParameter[] {
    return this.productDetailService.freshProductParameters.filter(p => p.parameter.id === param.parameter.id)
  }

  isStoredOnProduct(param) {
    return this.getStoredOnProduct(param).length > 0
  }

  getOptionsForEnum(param: ProductParameter) {
    return [null, ...param.parameter.enumValues]
  }
}
