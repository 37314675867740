import { Component, ChangeDetectorRef, OnInit, ViewChild } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { CoreService } from '../../services'
import { ExportsComponent } from '../exports/exports.component'
import { Carrier, Checkout, Depot, Payment, Producer, SoldItem, Supplier, Tag } from 'depoto-core/src/entities'
import { SchemaUtil } from '../../utils'
import * as moment from 'moment'
import { soldItemsColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  productFulltext: '',
  customerFulltext: '',
  dateFrom: moment().clone().startOf('month').format('YYYY-MM-DD'),
  dateTo: '',
  dateBillFrom: '',
  dateBillTo: '',
  status: 'all',
  processStatus: 'all',
  carriers: [],
  depots: [],
  checkouts: [],
  payments: [],
  producers: [],
  suppliers: [],
  orderTags: [],
  productTags: [],
  shippingCountries: [],
  product: 0,
}

@Component({
  selector: 'app-sold-items',
  templateUrl: 'sold-items.component.html',
})
export class SoldItemsComponent extends BaseListing implements OnInit {
  @ViewChild('exportsModal') exportsModal: ExportsComponent
  listingColumns: ListingItem[] = soldItemsColumns
  soldItems: SoldItem[] = []
  depots: Depot[] = []
  checkouts: Checkout[] = []
  payments: Payment[] = []
  producers: Producer[] = []
  suppliers: Supplier[] = []
  carriers: Carrier[] = []
  orderTags: Tag[] = []
  productTags: Tag[] = []
  sortProp = 'quantity'
  sortDir = 'desc'
  filters = structuredClone(DEFAULT_FILTERS)
  filtersExpanded = false
  statusTypesOptions: string[] = ['all', 'bill', 'reservation']
  processStatesOptions: string[] = [
    'all',
    'received',
    'picking',
    'packing',
    'packed',
    'dispatched',
    'delivered',
    'returned',
    'picking_error',
    'cancelled',
  ]
  totalItems = 0
  currentPage: number
  endPage: number
  canCreate = false
  loading = true
  stringifiedFilters: string
  storageKey: { type: string; key: string }

  constructor(
    protected core: CoreService,
    protected changeDetector: ChangeDetectorRef
  ) {
    super(core)
    this.getStorageKey()
  }

  async ngOnInit() {
    this.filters = this.loadViewSettings('soldItems') || structuredClone(DEFAULT_FILTERS)
    await this.refreshFiltersData()
    this.setStringifiedFilters()
    // this.loadSorting('soldItems') any sortable column
    this.filter()
  }

  setStringifiedFilters() {
    // todo test
    const filtersFromSoldItemsWithoutFulltext = JSON.parse(JSON.stringify(this.queryFilters)) // todo
    filtersFromSoldItemsWithoutFulltext.productFulltext = ''
    filtersFromSoldItemsWithoutFulltext.producers = []
    filtersFromSoldItemsWithoutFulltext.suppliers = []
    this.stringifiedFilters = JSON.stringify(filtersFromSoldItemsWithoutFulltext)
  }

  async refreshFiltersData(): Promise<any> {
    this.carriers = this.core.lists.carriersFromCompanyCarriers
    this.checkouts = this.core.lists.checkouts
    this.depots = this.core.lists.depots
    this.payments = this.core.lists.payments
    this.producers = this.core.lists.producers
    this.suppliers = this.core.lists.suppliers
    this.orderTags = this.core.lists.tags.filter(t => t.type === 'order')
    this.productTags = this.core.lists.tags.filter(t => t.type === 'product')
  }

  get queryFilters() {
    const f: any = {
      productFulltext:
        this.filters.productFulltext && this.filters.productFulltext.length
          ? this.filters.productFulltext.trim()
          : undefined,
      customerFulltext:
        this.filters.customerFulltext && this.filters.customerFulltext.length
          ? this.filters.customerFulltext.trim()
          : undefined,
      status: this.filters.status !== 'all' && this.filters.status.length ? this.filters.status : undefined,
      processStatus:
        this.filters.processStatus !== 'all' && this.filters.processStatus.length
          ? this.filters.processStatus
          : undefined,
      depots: this.filters.depots?.length ? this.filters.depots : undefined,
      carriers: this.filters.carriers?.length ? this.filters.carriers : undefined,
      checkouts: this.filters.checkouts?.length ? this.filters.checkouts : undefined,
      payments: this.filters.payments?.length ? this.filters.payments : undefined,
      producers: this.filters.producers?.length ? this.filters.producers : undefined,
      suppliers: this.filters.suppliers?.length ? this.filters.suppliers : undefined,
      productTags: this.filters.productTags?.length ? this.filters.productTags : undefined,
      orderTags: this.filters.orderTags?.length ? this.filters.orderTags : undefined,
    }
    if (!this.core.userHasAllCheckouts && this.core.services.user.user?.checkouts?.length && !f['checkouts']?.length) {
      f['checkouts'] = [...this.checkouts.map(ch => ch.id)]
    }
    if (!this.core.userHasAllDepots && this.core.services.user.user?.depots?.length && !f['depots']?.length) {
      f['depots'] = [...this.depots.map(d => d.id)]
    }

    Object.keys(f).forEach(k => {
      if (f[k] === undefined) {
        delete f[k]
      }
    })
    if (this.filters.dateFrom?.length > 0 || this.filters.dateTo.length > 0) {
      f.dateCreated = {
        ...(this.filters.dateFrom?.length && {
          left_date: this.filters.dateFrom,
        }),
        ...(this.filters.dateTo?.length && {
          right_date: this.filters.dateTo + 'T23:59:59',
        }),
      }
    }
    if (this.filters.dateBillFrom?.length > 0 || this.filters.dateBillTo.length > 0) {
      f.dateBill = {
        ...(this.filters.dateBillFrom?.length && {
          left_date: this.filters.dateBillFrom,
        }),
        ...(this.filters.dateBillTo?.length && {
          right_date: this.filters.dateBillTo + 'T23:59:59',
        }),
      }
    }
    return f
  }

  clearFilters() {
    this.filters = structuredClone(DEFAULT_FILTERS)
    this.filter(1)
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.currentPage = pageNo
    if (!concat) {
      this.reloading = true
    }
    try {
      const result = await this.core.services.soldItem.getList(
        { page: pageNo, sort: sortProp, direction: sortDir, filters: this.queryFilters },
        SchemaUtil.soldItem
      )
      if (!result?.items) {
        return
      }
      if (!concat) {
        this.soldItems = result.items
      } else {
        result.items.forEach(soldItem => {
          this.soldItems.push(new SoldItem(soldItem))
        })
      }
      this.totalItems = result.paginator ? result.paginator.totalCount : 0
      this.endPage = result.paginator ? result.paginator.endPage : 0
      this.currentPage++
      this.saveViewSettings('soldItems', this.filters)
      this.setStringifiedFilters()
    } finally {
      this.loading = false
      this.reloading = false
    }
  }
}
