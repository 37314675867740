import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Product, ProductDepotMetric } from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { CoreService, NotificationService } from '../../../services'
import { SchemaUtil } from '../../../utils'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-modal-product-depot-metric',
  templateUrl: './modal-product-depot-metric.component.html',
  styleUrls: ['./modal-product-depot-metric.component.scss'],
})
export class ModalProductDepotMetricComponent implements OnInit {
  @ViewChild('modalDepotMetric') public childModal: ModalContainerComponent
  @Input() public product: Product
  @Input() public stockItem: any
  productDepotMetric: ProductDepotMetric
  isLoading = false
  submitted = false
  filters
  isCreating = false

  constructor(
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.getDepotMetricList()
  }

  async getDepotMetricList() {
    let result
    this.filters = { product: this.product.id }
    try {
      result = await this.core.services.productDepotMetric.getList(
        { page: 1, sort: 'id', direction: null, filters: this.filters },
        SchemaUtil.productDepotMetric
      )
    } catch (err) {
      console.error(err)
      return
    }
    if (result.items?.length > 0) {
      const productDepotMetric = result.items.find(object => {
        return object.depot.id === this.stockItem.depot.id
      })
      if (productDepotMetric?.id) {
        this.isCreating = false
        this.productDepotMetric = new ProductDepotMetric(productDepotMetric)
      } else {
        this.isCreating = true
        this.assignDefaultProductDepotMetric()
      }
    } else {
      this.assignDefaultProductDepotMetric()
    }
  }

  assignDefaultProductDepotMetric() {
    this.isCreating = true
    this.productDepotMetric = new ProductDepotMetric()
  }

  public async showChildModal() {
    this.childModal.showChildModal()
  }

  public hideChildModal(): void {
    this.childModal.hideChildModal()
  }

  async createProductDepotMetric() {
    this.submitted = true
    this.isLoading = true
    try {
      this.productDepotMetric.depot = this.stockItem.depot
      this.productDepotMetric.product = this.product
      await this.core.services.productDepotMetric.create(this.productDepotMetric).then(result => {
        this.notificationService.success(this.translateService.instant('alert.product.depot.metric.created'))
        this.refreshData(result.id)
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.isLoading = false
      this.hideChildModal()
    }
  }

  async updateProductDepotMetric() {
    this.submitted = true
    this.isLoading = true
    try {
      await this.core.services.productDepotMetric.update(this.productDepotMetric).then(result => {
        this.notificationService.success(this.translateService.instant('alert.product.depot.metric.updated'))
        this.refreshData(result.id)
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.isLoading = false
      this.hideChildModal()
    }
  }

  async refreshData(id: number) {
    this.isLoading = true
    this.productDepotMetric = await this.core.services.productDepotMetric.getById(id, SchemaUtil.productDepotMetric)
    this.isLoading = false
  }

  async deleteProductDepotMetric() {
    const confirmation = confirm(this.translateService.instant('modal-product-depot-metric.delete.confirmation'))
    if (confirmation) {
      try {
        await this.core.services.productDepotMetric.delete(this.productDepotMetric).then(() => {
          this.notificationService.success(this.translateService.instant('alert.product.depot.metric.deleted'))
          this.isCreating = true
          this.assignDefaultProductDepotMetric()
          this.hideChildModal()
        })
      } catch (err) {
        console.error(err)
      }
    }
  }
}
