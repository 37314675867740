<modal-container #modal [headerTitle]="'address.select'" [headerIconClass]="'far-edit'" [isExtraLargeModal]="true">
  <ng-container class="modal-button-show">
    <btn
      [icon]="'far-edit'"
      [label]="'address.select'"
      [title]="'address.select' | translate"
      [classes]="['btn-first', 'float-right']"
      (onEvent)="showChildModal()"
    ></btn>
  </ng-container>
  <ng-container class="modal-body-content">
    <div class="card" *ngIf="addresses?.length > 0">
      <form-row-input
        [label]="'filter'"
        [(model)]="searchPhrase"
        (modelChange)="searchForAddress($event)"
        [appendBtnIcon]="'fa-times'"
        [appendBtnClasses]="['btn-first h-100']"
        (onAppendBtnEvent)="searchPhrase = '';searchForAddress('')"
      ></form-row-input>
    </div>
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{'modal-address-choose.name' | translate}}</th>
              <th scope="col">{{'modal-address-choose.email' | translate}}</th>
              <th scope="col">{{'modal-address-choose.company' | translate}}</th>
              <th scope="col">{{'modal-address-choose.street' | translate}}</th>
              <th scope="col">{{'modal-address-choose.city' | translate}}</th>
              <th scope="col">{{'modal-address-choose.zip' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let address of filteredAddresses | slice:0:1;">
              <ng-template [ngIf]="address.isStored === false">
                <td colspan="7" class="text-center p-5 border-bottom-0 no-hover">
                  <div class="empty-list">
                    <i class="far fa-sad-tear" data-cy="empty"></i>
                    <div>
                      {{ 'no.record.found' | translate }}
                    </div>
                  </div>
                </td>
              </ng-template>
            </tr>
            <tr *ngFor="let address of filteredAddresses">
              <ng-template [ngIf]="address.isStored">
                <td>{{ address.firstName }} {{ address.lastName }}</td>
                <td>{{ address.email }}</td>
                <td [ngClass]="{ 'text-center': !address.companyName }">
                  {{ address.companyName ? address.companyName : '-' }}
                </td>
                <td [ngClass]="{ 'text-center': !address.street }">{{ address.street ? address.street : '-' }}</td>
                <td [ngClass]="{ 'text-center': !address.city }">{{ address.city ? address.city : '-' }}</td>
                <td [ngClass]="{ 'text-center': !address.zip }">{{ address.zip ? address.zip : '-' }}</td>
                <td class="text-center">
                  <btn
                    (onEvent)="selectAddress(address)"
                    [label]="'address.select'"
                    [title]="'address.select' | translate"
                    [icon]="null"
                    [classes]="['btn-first', 'btn-sm']"
                  ></btn>
                </td>
              </ng-template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</modal-container>
