<div class="app-container">
  <form *ngIf="group" [formGroup]="groupForm" (ngSubmit)="isCreating ? createGroup() : updateGroup()">
    <page-header
      [icon]="'fa-users-cog'"
      [title]="isCreating ? 'group.new' : 'group.edit'"
      [appendNoTranslationTitlePart]="isCreating ? '' : group ? group.name : ''"
    >
      <ng-container class="header-button" *ngIf="group">
        <btn
          *ngIf="!isCreating"
          [btnType]="'submit'"
          [classes]="['btn-first', 'float-right']"
          [label]="'save'"
          [title]="'save' | translate"
          [icon]="'far-check'"
          [isLoading]="loading"
          [dataCyAttribute]="'update-group'"
        ></btn>
        <btn
          *ngIf="isCreating"
          [label]="'save'"
          [title]="'save' | translate"
          [icon]="'far-check'"
          [btnType]="'submit'"
          [classes]="['btn-first', 'float-right']"
          [btnDisabled]="!groupForm.value.roles.length || loading"
          [isLoading]="loading"
          [dataCyAttribute]="'create-group'"
        ></btn>
        <btn
          *ngIf="!isCreating"
          [icon]="'far-trash-alt'"
          [label]="'delete'"
          [title]="'delete' | translate"
          (onEvent)="deleteGroup()"
          [classes]="['btn-first-red', 'float-right', 'me-2']"
          [dataCyAttribute]="'delete-group'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <section class="content">
      <div class="card" *ngIf="group">
        <div class="card-body">
          <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
          <div class="row">
            <div class="col-sm-6">
              <form-row-input
                [formGroup]="groupForm"
                formControlName="name"
                [label]="'name'"
                [icon]="'fa-list'"
                [dataCyAttribute]="'usergroup-name'"
              >
              </form-row-input>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label>{{ 'roles' | translate }}:</label>
                <div *ngFor="let role of roles">
                  <form-row-checkbox
                    [label]="role.name"
                    [cursorPointer]="true"
                    [model]="groupForm.value.roles.includes(role.role)"
                    (modelChange)="updateRole(role.role, $event)"
                  ></form-row-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
