import { Injectable, inject } from '@angular/core'
import { CoreService } from './core.service'

@Injectable({
  providedIn: 'root',
})
export class StorageKeyService {
  s = inject(CoreService).services
  getStorageKey() {
    const email: string = this.s.user.user?.email || this.s.oauth.session.email
    return email
      ? {
          key: this.s.storage.keys.views.baseListing.key + '.' + email, // todo view key param, again..
          type: 'Object',
        }
      : null
  }

  getStorageKeyForEntity(entity: string, pageType = '', userSpecific = true): { type: string; key: string } {
    const email: string = this.s.storage.getSync(this.s.storage.keys.auth.email) || this.s.oauth.session.email

    return {
      key:
        (pageType ? this.s.storage.keys.views[pageType]?.key + '.' : '') +
        this.s.storage.keys.views[entity]?.key +
        (userSpecific ? '.' + email : ''),
      type: 'Object',
    }
  }
}
