<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="depotForm" (ngSubmit)="isCreating ? createDepot() : updateDepot()" *ngIf="depot">
    <page-header
      [icon]="'far-building'"
      [title]="isCreating ? 'depot.new' : 'depot'"
      [appendNoTranslationTitlePart]="depot ? depot.name : ''"
    >
      <ng-container class="header-button">
        <btn
          [label]="'save'"
          [title]="'save' | translate"
          [classes]="['btn-first', 'float-right']"
          [icon]="'far-check'"
          [btnType]="'submit'"
          [btnDisabled]="loading"
          [isLoading]="loading"
          [dataCyAttribute]="'create-depot'"
        ></btn>
        <btn
          *ngIf="!isCreating"
          [btnType]="'button'"
          [label]="'delete'"
          [title]="'delete' | translate"
          [classes]="['btn-first-red', 'float-right', 'me-2']"
          [icon]="'far-trash-alt'"
          (onEvent)="deleteDepot()"
          [dataCyAttribute]="'delete-depot'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <section class="content">
      <div class="card">
        <div class="row">
          <div class="col-sm-3">
            <form-row-input
              [label]="'depot.name'"
              [formGroup]="depotForm"
              formControlName="name"
              [required]="depotForm.get('name').errors !== null && depotForm.get('name').errors.required"
              [requiredCssClass]="submitted && formControls.name.errors"
              [dataCyAttribute]="'name'"
            ></form-row-input>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <form-row-input
              [label]="'email'"
              [formGroup]="depotForm"
              formControlName="emailIn"
              [dataCyAttribute]="'emailIn'"
            ></form-row-input>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
