import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild } from '@angular/core'

declare let $

@Component({
  selector: 'info-tooltip',
  templateUrl: 'info-tooltip.component.html',
  styleUrls: ['info-tooltip.component.scss'],
})
export class InfoTooltipComponent implements OnInit, OnChanges {
  @ViewChild('infoTooltip') btn: ElementRef
  @Input() label: string
  @Input() icon: string
  iconClassesDefault = 'fa fa-info-circle'
  iconClassesString = ''

  ngOnInit() {
    this.updateComponent()
  }

  ngOnChanges() {
    this.updateComponent()
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  }

  private updateComponent(): void {
    let iconClasses = ''
    if (this.icon && this.icon.length > 0) {
      iconClasses += this.icon.split('-')[0]
      iconClasses += ' ' + this.icon.replace('far-', 'fa-').replace('fas-', 'fa-')
    } else {
      iconClasses = this.iconClassesDefault
    }
    this.iconClassesString = iconClasses
  }
}
