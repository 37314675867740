<div class="card">
  <modal-product-advanced-prices
    [product]="productDetailService.product"
    [isCreating]="true"
    (onPriceUpdate)="productDetailService.refreshProduct()"
    [dataCyAttribute]="'modal-product-advanced-prices-create'">
  </modal-product-advanced-prices>
</div>

<div class="card" *ngIf="productDetailService.product.advancedPrices?.length > 0">
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>
            {{ 'price' | translate }}
          </th>
          <th>
            {{ 'product.qty' | translate }}
          </th>
          <th>
            {{ 'date.from' | translate }}
          </th>
          <th>
            {{ 'date.to' | translate }}
          </th>
          <th>
            {{ 'note' | translate }}
          </th>
          <th>
            &nbsp;
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ap of productDetailService.product.advancedPrices">
          <td>
            {{ap.sellPrice | formatPrice}}
          </td>
          <td>
            {{ap.available}}
          </td>
          <td>
            {{ap.dateFrom | fmtDate: 'dd.MM.yyyy'}}
          </td>
          <td>
            {{ap.dateTo | fmtDate: 'dd.MM.yyyy'}}
          </td>
          <td>
            {{ap.note}}
          </td>
          <td>
            <modal-product-advanced-prices
              [product]="productDetailService.product"
              [productAdvancedPrices]="ap"
              [isCreating]="false"
              (onPriceUpdate)="this.productDetailService.refreshProduct()"
              [dataCyAttribute]="'modal-product-advanced-prices-update'">
            </modal-product-advanced-prices>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
