import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, FormRowBase } from '../form-row-base/form-row-base'

@Component({
  selector: 'form-row-textarea',
  templateUrl: 'form-row-textarea.component.html',
  styleUrls: ['form-row-textarea.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR(FormRowTextareaComponent)],
})
export class FormRowTextareaComponent extends FormRowBase implements OnInit, OnChanges {
  @ViewChild('formRow') formRow: ElementRef
  @ViewChild('inputArea') inputEl: ElementRef
  @Input() label: string
  @Input() tooltipLabel: string
  @Input() tooltipIcon: string
  @Input() model: string
  @Input() cols = 26
  @Input() rows = 2
  @Output() modelChange: EventEmitter<any> = new EventEmitter()
  @Output() onKeyPress: EventEmitter<any> = new EventEmitter()
  @Input() inputDisabled = false
  @Input() noMarginBottom = false
  @Input() classes: string[] = []
  @Input() icon = 'null'
  @Input() btnLabel: string
  @Input() btnClasses: string[] = []
  @Input() btnIcon: string
  @Input() trackCategory = 'event-click-textarea'
  @Input() trackAction: string
  @Input() dataCyAttribute: string
  @Input() formControlName: string
  @Input() formGroup: FormGroup
  @Input() placeholder: string
  @Output() onBtnEvent: EventEmitter<any> = new EventEmitter()
  private classesDefault = 'form-control'
  classesString = ''
  iconClassesString = ''

  constructor() {
    super()
  }

  ngOnInit() {
    this.updateComponent()
    this.initFormControl()
  }

  ngOnChanges() {
    this.updateComponent()
  }

  onBtnClick(event: any): void {
    this.onBtnEvent.emit(event)
  }

  onNgModelChange(value: string): void {
    this.modelChange.emit(value)
  }

  onInputKeyPress(value: string): void {
    this.onChange(value)
    this.onKeyPress.emit(value)
  }

  private updateComponent(): void {
    let classesAdditional = ''
    if (this.classes && this.classes.length > 0) {
      this.classes.forEach(cls => {
        classesAdditional += ' ' + cls
      })
    }
    this.classesString = this.classesDefault + classesAdditional

    // icon
    let iconClasses = ''
    if (this.icon && this.icon.length > 0) {
      iconClasses += this.icon.split('-')[0]
      iconClasses += ' ' + this.icon.replace('far-', 'fa-').replace('fas-', 'fa-')
    }
    this.iconClassesString = iconClasses
    if (!this.trackAction) {
      this.trackAction = this.label
    }
  }
}
