import { Component, OnInit, OnChanges, ViewChild, Input } from '@angular/core'
import { Currency, ProductMove, StatsBestseller, StatsCarrierUsage, StatsPaymentSale } from 'depoto-core/src/entities'
import Chart from '../../../assets/plugins/chartjs/Chart.min.js'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnChanges {
  @ViewChild('chartElement') chartElement: any
  @Input() type: string
  @Input() chartData: Array<StatsBestseller | StatsCarrierUsage | StatsPaymentSale | ProductMove | any> = []
  chartId: string
  chart: any
  chartCanvasCtx: any
  devicePixelRatio: number = window.devicePixelRatio > 1.5 ? 1.5 : window.devicePixelRatio
  chartCanvasWidth: number = 450 / this.devicePixelRatio
  chartCanvasHeight: number = 450 / this.devicePixelRatio
  loading = true
  isNoDataForChart = false
  defaultChartColors = [
    'rgba(130, 220, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(24, 180, 60, 1)',
    'rgba(55, 250, 250, 1)',
    'rgba(230, 0, 250, 1)',
    'rgba(222, 70, 70, 1)',
  ]

  constructor(private readonly translateService: TranslateService) {}

  static get types() {
    return {
      bestsellers: 'bestsellers',
      carrierUsage: 'carrierUsage',
      paymentSales: 'paymentSales',
      stockDepots: 'stockDepots', // calculated from Product.depots && ProductDepots (ProductComponent), not available like stats query
    }
  }

  ngOnInit() {
    this.chartId = `chart_${this.type}_${new Date().getTime().toFixed(0).substr(-5)}_${Math.floor(Math.random() * 20)}`
    if (this.type === ChartComponent.types.bestsellers || this.type === ChartComponent.types.stockDepots) {
      this.chartCanvasWidth = 900 / this.devicePixelRatio
      this.chartCanvasHeight = 450 / this.devicePixelRatio
    }
  }

  ngOnChanges(changes) {
    if (changes && changes.chartData && changes.chartData.currentValue && changes.chartData.currentValue.length > 0) {
      this.isNoDataForChart = false
      if (this.chartId && this.chartId.length > 0 && !this.chart) {
        this.initChart()
      } else if (this.chartId && this.chartId.length > 0 && this.chart) {
        this.clearChart().then(() => {
          this.updateChart()
        })
      }
    } else if (
      changes &&
      changes.chartData &&
      changes.chartData.currentValue &&
      changes.chartData.currentValue.length === 0
    ) {
      this.clearChart(true).catch(() => null)
      if (!changes.chartData.firstChange || this.type === ChartComponent.types.stockDepots) {
        this.loading = false
        this.isNoDataForChart = true
      }
    }
  }

  initChart(): void {
    if (!this.chartData) {
      console.warn('no data for chart init!')
      return
    }
    if (!this.type) {
      console.warn('no type for chart init!')
      return
    }
    this.loading = true
    this.chartCanvasCtx = this.chartElement.nativeElement.getContext('2d')
    switch (this.type) {
      case ChartComponent.types.bestsellers:
        this.initChartBestsellers()
        break
      case ChartComponent.types.carrierUsage:
        this.setDoughnutChartCentralLabel()
        this.initChartCarrierUsage()
        break
      case ChartComponent.types.paymentSales:
        this.setDoughnutChartCentralLabel()
        this.initChartPaymentSales()
        break
      case ChartComponent.types.stockDepots:
        this.initChartStockDepots()
        break
    }
    this.loading = false
  }

  updateChart(): void {
    if (!this.chartData) {
      console.warn('no data for chart update!')
      return
    }
    if (!this.type) {
      console.warn('no type for chart update!')
      return
    }
    this.loading = true
    switch (this.type) {
      case ChartComponent.types.bestsellers:
        this.updateChartBestsellers()
        break
      case ChartComponent.types.carrierUsage:
        this.updateChartCarrierUsage()
        break
      case ChartComponent.types.paymentSales:
        this.updateChartPaymentSales()
        break
      case ChartComponent.types.stockDepots:
        this.updateChartStockDepots()
        break
    }
    this.loading = false
  }

  initChartBestsellers() {
    this.chartCanvasWidth = 900 / this.devicePixelRatio
    this.chartCanvasHeight = 450 / this.devicePixelRatio
    if (this.chart) {
      return
    }
    setTimeout(() => {
      const datasets = []
      if (this.chartData && this.chartData.length > 0) {
        this.chartData[0].sales.forEach((s, i) => {
          const currency = s.currency
          datasets.push({
            label: this.getCurrencyForLabel(currency),
            fill: false,
            borderColor: `rgba(130, 220, ${i * 10 * (Math.random() * 30)}, 1)`,
            data: this.chartData.map(d => {
              const filtered = d.sales.filter(sa => sa.currency && sa.currency.id === currency.id)
              if (filtered && filtered.length > 0) {
                return filtered[0].value
              }
              return 0
            }),
            type: 'line',
            lineTension: 0,
            yAxisID: 'y-axis-1',
          })
        })
      }
      datasets.push({
        label: this.translateService.instant('chart.pieces'),
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgb(0,0,40)',
        data: this.chartData.map(d => d.amount),
        yAxisID: 'y-axis-2',
      })
      const t = this
      this.chart = new Chart(this.chartCanvasCtx, {
        type: 'bar',
        data: {
          labels: this.chartData.map(d => d.product.fullName),
          datasets: datasets,
        },
        options: {
          display: true,
          responsive: false,
          legend: {
            display: false,
            // position: 'top',
          },
          elements: {
            rectangle: {
              borderWidth: 0,
            },
          },
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
              },
              {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              title: function (tooltipItem, data) {
                return data['labels'][tooltipItem[0]['index']]
              },
              label: function (tooltipItem, data) {
                const postfix =
                  data &&
                  data.datasets &&
                  data.datasets.length > tooltipItem.datasetIndex &&
                  data.datasets[tooltipItem.datasetIndex].label
                    ? data.datasets[tooltipItem.datasetIndex].label
                    : ''
                return (
                  ' ' +
                  t.formatCurrencyAmountForTooltip(
                    data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']]
                  ) +
                  postfix
                )
              },
            },
          },
        },
      })
    }, 200)
  }

  updateChartBestsellers() {
    if (!this.chart) {
      return
    }
    this.chart.data.labels = this.chartData.map(d => d.product.fullName)
    const datasets = []
    if (this.chartData && this.chartData.length > 0) {
      this.chartData[0].sales.forEach((s, i) => {
        const currency = s.currency
        datasets.push({
          label: this.getCurrencyForLabel(currency),
          fill: false,
          borderColor: `rgba(130, 220, ${i * 10 * (Math.random() * 30)}, 1)`,
          data: this.chartData.map(d => {
            const filtered = d.sales.filter(sa => sa.currency && sa.currency.id === currency.id)
            if (filtered && filtered.length > 0) {
              return filtered[0].value
            }
            return 0
          }),
          type: 'line',
          lineTension: 0,
          yAxisID: 'y-axis-1',
        })
      })
    }
    datasets.push({
      label: this.translateService.instant('chart.pieces'),
      backgroundColor: 'rgb(54, 162, 235)',
      borderColor: 'rgb(0,0,40)',
      data: this.chartData.map(d => d.amount),
      yAxisID: 'y-axis-2',
    })
    this.chart.data.datasets = datasets
    this.chart.update()
  }

  initChartCarrierUsage() {
    const t = this
    this.chart = new Chart(this.chartCanvasCtx, {
      type: 'doughnut',
      data: {
        labels: this.chartData.map(d => d.carrier.name),
        datasets: [
          {
            label: this.translateService.instant('chart.packages'),
            data: this.chartData.map(d => d.amount),
            backgroundColor: this.chartData.map(d => d.carrier.color),
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: false,
        display: true,
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']]
            },
            label: function (tooltipItem, data) {
              return (
                ' ' +
                t.formatCurrencyAmountForTooltip(data['datasets'][0]['data'][tooltipItem['index']]) +
                ' objednávek'
              )
            },
          },
        },
        elements: {
          center: {
            text: `${this.formatCurrencyAmountForTooltip(
              this.chartData.map(d => d.amount).reduce((a, b) => a + b)
            )} obj.`,
            color: '#2a88bd',
            fontStyle: 'Helvetica',
            sidePadding: 15,
          },
        },
      },
    })
  }

  updateChartCarrierUsage() {
    if (!this.chart) {
      return
    }
    this.chart.data.labels = this.chartData.map(d => d.carrier.name)
    this.chart.data.datasets[0].data = this.chartData.map(d => d.amount)
    this.chart.data.datasets[0].backgroundColor = this.chartData.map(d => d.carrier.color)
    this.chart.options.elements.center.text = `${this.formatCurrencyAmountForTooltip(
      this.chartData.map(d => d.amount).reduce((a, b) => a + b)
    )} obj.`
    this.chart.update()
  }

  initChartPaymentSales() {
    const datasets = []
    let priceSum = 0
    if (this.chartData && this.chartData.length > 0) {
      this.chartData[0].sales.forEach(s => {
        const currency = s.currency
        datasets.push({
          label: this.getCurrencyForLabel(currency),
          backgroundColor: this.defaultChartColors,
          borderWidth: 1,
          data: this.chartData.map(d => {
            const filtered = d.sales.filter(sa => sa.currency && sa.currency.id === currency.id)
            if (filtered && filtered.length > 0) {
              priceSum += filtered[0].value
              return filtered[0].value
            }
            return 0
          }),
        })
      })
    }
    datasets.push({
      label: this.translateService.instant('chart.orders'),
      backgroundColor: this.defaultChartColors,
      borderWidth: 1,
      data: this.chartData.map(d => d.amount),
    })
    const t = this
    this.chart = new Chart(this.chartCanvasCtx, {
      type: 'doughnut',
      data: {
        labels: this.chartData.map(d => d.payment.name),
        datasets: datasets,
      },
      options: {
        responsive: false,
        display: true,
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']]
            },
            label: function (tooltipItem, data) {
              const postfix =
                data &&
                data.datasets &&
                data.datasets.length > tooltipItem.datasetIndex &&
                data.datasets[tooltipItem.datasetIndex].label
                  ? data.datasets[tooltipItem.datasetIndex].label
                  : ''
              return (
                ' ' +
                t.formatCurrencyAmountForTooltip(
                  data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']]
                ) +
                postfix
              )
            },
            // todo: leave for reference
            // afterLabel: function(tooltipItem, data) {
            //   // var dataset = data['datasets'][0];
            //   // var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
            //   // return '(' + 123 + '%)';
            //   return data && data.datasets && data.datasets.length > tooltipItem.datasetIndex && data.datasets[tooltipItem.datasetIndex].label ? data.datasets[tooltipItem.datasetIndex].label : '';
            // }
          },
        },
        elements: {
          center: {
            text:
              this.chartData[0].sales[0].currency.id === 'CZK'
                ? `${this.formatCurrencyAmountForTooltip(priceSum)}${this.getCurrencyForLabel(
                    this.chartData[0].sales[0].currency
                  )}`
                : `${this.getCurrencyForLabel(
                    this.chartData[0].sales[0].currency
                  )} ${this.formatCurrencyAmountForTooltip(priceSum, 2)}`,
            color: '#333',
            fontStyle: 'Helvetica',
            sidePadding: 15,
          },
        },
      },
    })
  }

  updateChartPaymentSales() {
    if (!this.chart) {
      return
    }
    const datasets = []
    let priceSum = 0
    if (this.chartData && this.chartData.length > 0) {
      this.chartData[0].sales.forEach(s => {
        const currency = s.currency
        datasets.push({
          label: this.getCurrencyForLabel(currency),
          backgroundColor: this.defaultChartColors,
          borderWidth: 1,
          data: this.chartData.map(d => {
            const filtered = d.sales.filter(sa => sa.currency && sa.currency.id === currency.id)
            if (filtered && filtered.length > 0) {
              priceSum += filtered[0].value
              return filtered[0].value
            }
            return 0
          }),
        })
      })
    }
    datasets.push({
      label: this.translateService.instant('chart.pieces'),
      backgroundColor: this.defaultChartColors,
      borderWidth: 1,
      data: this.chartData.map(d => d.amount),
    })
    this.chart.data.labels = this.chartData.map(d => d.payment.name)
    this.chart.data.datasets = datasets
    if (this.chartData[0].sales[0].currency.id === 'CZK') {
      this.chart.options.elements.center.text = `${this.formatCurrencyAmountForTooltip(
        priceSum
      )}${this.getCurrencyForLabel(this.chartData[0].sales[0].currency)}`
    } else {
      this.chart.options.elements.center.text = `${this.getCurrencyForLabel(
        this.chartData[0].sales[0].currency
      )} ${this.formatCurrencyAmountForTooltip(priceSum, 2)}`
    }
    this.chart.update()
  }

  initChartStockDepots() {
    this.chartCanvasWidth = 1600 / this.devicePixelRatio
    this.chartCanvasHeight = 350 / this.devicePixelRatio
    if (this.chart) {
      return
    }
    const sortedChartData = [...this.chartData]
    sortedChartData.sort((a, b) => (a.created === b.created ? 0 : a.created > b.created ? 1 : -1))
    const states = []
    sortedChartData.forEach((d, i) => {
      if (d.depotFromObj && d.depotFromObj.id > 0) {
        states.push({
          date: d.created,
          depotName: d.depotFromObj.name,
          amount: !isNaN(d.productDepotFromQuantityStock) ? d.productDepotFromQuantityStock : 0,
        })
      }
      if (d.depotToObj && d.depotToObj.id > 0) {
        states.push({
          date: d.created,
          depotName: d.depotToObj.name,
          amount: !isNaN(d.productDepotToQuantityStock) ? d.productDepotToQuantityStock : 0,
        })
      }
    })
    if (states.length === 0) {
      return
    }
    const uniqueDepots = Array.from(new Set([...states.map(m => m.depotName)]))
    const data = uniqueDepots.map(name => {
      const depotData = {
        depotName: name,
        states: [],
      }
      states.forEach(m => {
        if (m.depotName === name) {
          depotData.states.push({
            date: m.date,
            amount: m.amount,
          })
        }
      })
      return depotData
    })

    // we have chart data filled into array nicely, now normalize starting and ending state
    const firstStateDate = states[0].date
    const lastStateDate = new Date().toISOString().substr(0, 19).replace('T', ' ')
    data.forEach(d => {
      if (d.states[0].date > firstStateDate) {
        d.states.unshift({
          date: firstStateDate,
          amount: d.states[0].amount,
        })
      }
      d.states.push({
        date: lastStateDate,
        amount: d.states[d.states.length - 1].amount,
      })
    })

    setTimeout(() => {
      let colorIndex = 0
      const datasets = data.map(d => {
        return {
          label: d.depotName,
          fill: false,
          borderColor: this.defaultChartColors[colorIndex++],
          data: d.states.map(s => {
            return { x: s.date, y: s.amount }
          }),
          lineTension: 0,
          steppedLine: true,
          yAxisID: 'y-axis-1',
        }
      })

      // make the sum dataset
      // TODO: this? is causing NaN bug in chart - it is rendered ok, but failing to display correct values in tooltips etc
      if (datasets.length > 1) {
        const sumSet = []
        datasets.forEach(d => {
          d.data.forEach((v, i) => {
            if (sumSet[i] === undefined) {
              sumSet[i] = { x: v.x, y: v.y }
            } else {
              sumSet[i] = { x: v.x, y: v.y + sumSet[i].y }
            }
          })
        })
        datasets.push({
          label: this.translateService.instant('chart.overall'),
          fill: false,
          borderColor: 'rgb(0,0,40)',
          data: sumSet,
          lineTension: 0,
          steppedLine: true,
          yAxisID: 'y-axis-1',
        })
      }
      this.chart = new Chart(this.chartCanvasCtx, {
        type: 'line',
        data: {
          datasets: datasets,
        },
        options: {
          display: true,
          responsive: false,
          legend: {
            display: true,
          },
          elements: {
            rectangle: {
              borderWidth: 0,
            },
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  displayFormats: {
                    millisecond: 'MMM DD',
                    second: 'MMM DD',
                    minute: 'MMM DD',
                    hour: 'MMM DD',
                    day: 'MMM DD',
                    week: 'MMM DD',
                    month: 'MMM DD',
                    quarter: 'MMM DD',
                    year: 'MMM DD',
                  },
                },
              },
            ],
            yAxes: [
              {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
              },
              {
                type: 'linear',
                display: false,
                position: 'right',
                id: 'y-axis-2',
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
          },
        },
      })
    }, 200)
  }

  updateChartStockDepots() {
    if (!this.chart) {
      return
    }
  }

  clearChart(andUpdate = false): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.chart) {
        reject(null)
      }
      this.chart.data.labels = []
      this.chart.data.datasets.forEach(dataset => {
        dataset.data = []
      })
      if (andUpdate) {
        this.chart.update()
      }
      resolve(this.chart)
    })
  }

  getCurrencyForLabel(currency: Currency): string {
    return currency && currency.id === 'CZK' ? ' Kč' : currency.id === 'EUR' ? ' €' : ''
  }

  formatCurrencyAmountForTooltip(amount: number | string, fractionDigits = 0): string {
    return Number(amount)
      .toFixed(fractionDigits)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  setDoughnutChartCentralLabel(): void {
    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          // Get ctx from string
          const ctx = chart.chart.ctx

          // Get options from the center object in options
          const centerConfig = chart.config.options.elements.center
          const fontStyle = centerConfig.fontStyle || 'Arial'
          const txt = centerConfig.text
          const color = centerConfig.color || '#000'
          const sidePadding = centerConfig.sidePadding || 20
          const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
          // Start with a base font of 30px
          ctx.font = '30px ' + fontStyle

          // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          const stringWidth = ctx.measureText(txt).width
          const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated

          // Find out how much the font can grow in width.
          const widthRatio = elementWidth / stringWidth
          const newFontSize = Math.floor(30 * widthRatio)
          const elementHeight = chart.innerRadius * 2

          // Pick a new font size so it will not be larger than the height of label.
          const fontSizeToUse = Math.min(newFontSize, elementHeight)

          // Set font settings to draw it correctly.
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          const centerX = (chart.chartArea.left + chart.chartArea.right) / 2
          const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
          ctx.font = fontSizeToUse + 'px ' + fontStyle
          ctx.fillStyle = color

          // Draw text in center
          ctx.fillText(txt, centerX, centerY)
        }
      },
    })
  }
}
