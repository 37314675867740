import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { Address } from 'depoto-core/src/entities'

@Component({
  selector: 'modal-address-choose',
  templateUrl: './modal-address-choose.component.html',
  styleUrls: ['./modal-address-choose.component.scss'],
})
export class ModalAddressChooseComponent {
  @ViewChild('modal') childModal: ModalContainerComponent
  @Input() addresses: Address[] = []
  @Output() onSelectedAddress: EventEmitter<Address> = new EventEmitter()
  searchPhrase: string
  filteredAddresses: Address[] = []

  searchForAddress(phrase: string) {
    const containsPhrase = (p: string, a: Address) =>
      a.firstName?.toLowerCase().includes(p.toLowerCase()) ||
      a.lastName?.toLowerCase().includes(p.toLowerCase()) ||
      a.email?.toLowerCase().includes(p.toLowerCase()) ||
      a.phone?.toLowerCase().includes(p.toLowerCase()) ||
      a.companyName?.toLowerCase().includes(p.toLowerCase()) ||
      a.street?.toLowerCase().includes(p.toLowerCase()) ||
      a.city?.toLowerCase().includes(p.toLowerCase()) ||
      a.state?.toLowerCase().includes(p.toLowerCase()) ||
      a.note?.toLowerCase().includes(p.toLowerCase())
    this.filteredAddresses = this.addresses.filter(a => a.isStored && containsPhrase(phrase, a))
  }

  public selectAddress(address: Address) {
    this.onSelectedAddress.emit(address)
    this.searchPhrase = ''
    this.filteredAddresses = this.addresses.filter(a => a.isStored)
    this.childModal.hideChildModal()
  }

  public showChildModal() {
    this.filteredAddresses = this.addresses.filter(a => a.isStored)
    this.childModal.showChildModal()
  }
}
