import { Pipe, PipeTransform } from '@angular/core'
import { Currency } from 'depoto-core/src/entities'

@Pipe({ name: 'formatCurrencyWithoutPrice' })
export class FormatCurrencyWithoutPricePipe implements PipeTransform {
  transform(currency: Currency): string {
    const currencySuffix = this.getCurrencySuffix(currency?.id)
    return `${currencySuffix}`
  }

  getCurrencySuffix(currencyId: string): string {
    switch (currencyId) {
      default:
      case 'CZK':
        return 'Kč'
      case 'EUR':
        return '€'
      case 'USD':
        return '$'
      case 'GBP':
        return '£'
      case 'RUB':
        return '₽'
      case 'PLN':
        return 'zł'
      case 'CAD':
        return 'C$'
      case 'JPY':
        return '¥'
      case 'RON':
        return 'L'
      case 'HUF':
        return 'Ft'
      case 'HRK':
        return 'Kn'
      case 'CHF':
        return 'fr'
      case 'CNY':
        return '¥'
    }
  }
}
