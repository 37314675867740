<!--<form (ngSubmit)="execute()" [formGroup]="modalStockLoadForm">-->
<modal-container
  #modal
  [isLargeModal]="true"
  [headerIconClass]="'fa-truck'"
  [headerTitle]="headerTitle"
  [modalHeaderBgColorClass]="headerBgClass"
  [modalBodyBgColorClass]="bodyBgClass"
  [modalFooterBgColorClass]="footerBgClass"
  (hide)="onHideChildModal()"
  [ignoreBackdropClick]="true"
>
  <ng-container class="modal-button-show">
    <btn
      [classes]="['float-right', isUnloadingStock ? 'btn-first-red' : 'btn-first-green', 'me-2']"
      (onEvent)="showChildModal()"
      [icon]="isUnloadingStock ? 'far-layer-minus' : 'far-layer-plus'"
      [label]="isUnloadingStock ? 'out' : 'in'"
      [title]="isUnloadingStock ? ('out' | translate) : ('in' | translate)"
      [dataCyAttribute]="'modal-stock-load-open'"
    ></btn>
  </ng-container>

  <ng-container class="modal-body-content">
    <div class="card" style="padding: 26px" *ngIf="!!childModal?.childModal?.isShown">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
      <div class="form-stock-load">
        <div class="col-12 ps-0 pe-0">
          <div class="row">
            <div class="col-12 col-sm-6 ps-0">
              <form-row-select
                [label]="'depot'"
                [icon]="'far-industry'"
                [isSelectDisabled]="!!currentStockItem"
                [options]="depots"
                [(model)]="productMove.depotTo"
                [required]="!this.isUnloadingStock && (!this.productMove.depotTo || this.productMove.depotTo < 1)"
                [requiredCssClass]="
                  submitted && !this.isUnloadingStock && (!this.productMove.depotTo || this.productMove.depotTo < 1)
                "
                [dataCyAttribute]="'product-move-depot-to'"
              >
              </form-row-select>
            </div>
            <div class="col-12 col-sm-6 pe-0">
              <form-row-select
                [label]="'supplier'"
                [icon]="'far-truck'"
                [(model)]="productMove.supplier"
                [isSelectDisabled]="!!currentStockItem"
                [options]="suppliers"
                [required]="!this.isUnloadingStock && !this.productMove.supplier"
                [requiredCssClass]="submitted && !this.isUnloadingStock && !this.productMove.supplier"
                [dataCyAttribute]="'product-move-supplier'"
              >
              </form-row-select>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6 ps-0">
              <form-row-input
                [label]="'product.batch'"
                [icon]="'far-cubes'"
                [(model)]="productMove.batch"
                [inputDisabled]="!!currentStockItem"
                [dataCyAttribute]="'product-move-batch'"
              ></form-row-input>
            </div>
            <div class="col-12 col-sm-6 pe-0">
              <form-row-input
                [label]="'product.expirationDate'"
                [icon]="'far-clock'"
                [inputType]="'date'"
                [(model)]="productMove.expirationDate"
                [inputDisabled]="!!currentStockItem"
                [dataCyAttribute]="'product-move-expiration-date'"
              ></form-row-input>
            </div>
          </div>
          <div class="row stock-positions">
            <div class="col-12 col-sm-4 input-dash position-relative">
              <span class="stock-positions-absolute">{{ 'stock.positions' | translate }}</span>
              <form-row-input
                [label]="'row'"
                [icon]="'far-box'"
                [(model)]="productMove.position1"
                [inputDisabled]="!!currentStockItem"
                [dataCyAttribute]="'product-move-position1'"
              ></form-row-input>
              <div class="align-self-center mt-2">-</div>
            </div>
            <div class="col-12 col-sm-4 input-dash">
              <form-row-input
                [label]="'aisle'"
                [icon]="'far-box'"
                [(model)]="productMove.position2"
                [inputDisabled]="!!currentStockItem"
                [dataCyAttribute]="'product-move-position2'"
              ></form-row-input>
              <div class="align-self-center mt-2">-</div>
            </div>
            <div class="col-12 col-sm-4">
              <form-row-input
                [label]="'shelf'"
                [icon]="'far-box'"
                [(model)]="productMove.position3"
                [inputDisabled]="!!currentStockItem"
                [dataCyAttribute]="'product-move-position3'"
              ></form-row-input>
            </div>
          </div>
          <div class="row mt-2">
            <div [ngClass]="isUnloadingStock ? 'col-sm-6 ps-0' : 'col-sm-3 ps-0'" class="col-12">
              <form-row-input
                [label]="'numberOfItems'"
                [icon]="isUnloadingStock ? 'far-minus' : 'far-plus'"
                [(model)]="productMove.amount"
                [inputType]="'number'"
                [maximumVal]="
                  isUnloadingStock && currentStockItem && currentStockItem.quantityStock
                    ? currentStockItem.quantityStock
                    : undefined
                "
                [minimumVal]="1"
                [required]="!this.isUnloadingStock && number(this.productMove.amount) < 1"
                [requiredCssClass]="submitted && !this.isUnloadingStock && number(this.productMove.amount) < 1"
                [dataCyAttribute]="'product-move-amount'"
              ></form-row-input>
            </div>
            <div [ngClass]="isUnloadingStock ? 'col-sm-6 flex-gap-5 align-items-center' : 'col-sm-3'" class="col-12">
              <form-row-input
                [label]="'purchasePrice'"
                [icon]="'far-money-bill'"
                [(model)]="productMove.purchasePrice"
                [inputDisabled]="!!currentStockItem"
                [replaceComma]="true"
                [dataCyAttribute]="'product-move-purchase-price'"
              ></form-row-input>
              <div [ngClass]="isUnloadingStock ? 'd-block' : 'd-none'" style="margin-top: 15px">
                {{ getCurrency(productMove.purchaseCurrency) | formatCurrencyWithoutPrice }}
              </div>
            </div>
            <div class="col-12 col-sm-3" *ngIf="!isUnloadingStock">
              <form-row-select
                [label]="'currency'"
                [(model)]="productMove.purchaseCurrency"
                [options]="currencies"
                [inputDisabled]="!!currentStockItem"
                [dataCyAttribute]="'product-move-currency'"
              ></form-row-select>
            </div>
            <div class="col-12 col-sm-3 pe-0" *ngIf="!isUnloadingStock">
              <form-row-input
                [label]="'duzp'"
                [(model)]="productMove.purchaseCurrencyDate"
                [inputType]="'date'"
                [dataCyAttribute]="'product-move-duzp'"
              ></form-row-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 ps-0 pe-0">
              <form-row-textarea
                [label]="'note'"
                [icon]="'far-list'"
                [(model)]="productMove.note"
                [dataCyAttribute]="'product-move-note'"
              ></form-row-textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container class="modal-footer-content">
    <btn
      [btnType]="'submit'"
      [classes]="isUnloadingStock ? ['btn-first-red-inverted'] : ['btn-first-green-inverted']"
      (onEvent)="execute()"
      [icon]="isUnloadingStock ? 'far-minus' : 'far-plus'"
      [label]="isUnloadingStock ? 'out' : 'in'"
      [title]="isUnloadingStock ? ('out' | translate) : ('in' | translate)"
      [isLoading]="loading"
      [spinnerClassesString]="isUnloadingStock ? 'spinner-red' : 'spinner-green'"
      [dataCyAttribute]="'modal-stock-load-submit'"
    ></btn>
  </ng-container>
</modal-container>
<!--</form>-->
