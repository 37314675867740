<div class="app-container">
  <loading-spinner [isLoading]="productDetailService.loading" [classesString]="'top-25'"></loading-spinner>
  <page-header
    *ngIf="productDetailService.isCreating || productDetailService.product"
    [icon]="'far-cube'"
    [title]="productDetailService.isCreating ? 'product.new' : ''"
    [appendNoTranslationTitlePart]="productDetailService.isCreating ? '' : productDetailService.product.fullName"
    [isOnProductWithAllBtns]="
      productDetailService.product?.id > 0 &&
      (!productDetailService.product.children || productDetailService.product.children?.length === 0) &&
      !productDetailService.isCreating &&
      productDetailService.product.isDeletable
    "
  >
    <ng-container class="header-button">
      <btn
        [btnType]="'submit'"
        [classes]="['btn-first', 'float-right']"
        [label]="'save'"
        [title]="'save' | translate"
        [icon]="'far-check'"
        (onEvent)="productDetailService.isCreating ? createProduct() : updateProduct()"
        [isLoading]="productDetailService.loading"
        [spinnerClassesString]="'spinner-white'"
        [dataCyAttribute]="'added-product'"
      ></btn>
      <modal-stock-load
        *ngIf="
          productDetailService.product?.id > 0 &&
          (!productDetailService.product.children || productDetailService.product.children?.length === 0) &&
          !productDetailService.product.isBundle
        "
        [product]="productDetailService.product"
        [depots]="productDetailService.depots"
        [suppliers]="productDetailService.suppliers"
        (result)="resolveStockLoad()"
        [currencies]="productDetailService.currencies"
      ></modal-stock-load>
      <btn
        *ngIf="!productDetailService.isCreating"
        [btnType]="'submit'"
        [classes]="['btn-third', 'float-right', 'me-2']"
        [label]="'duplicate'"
        [title]="'duplicate' | translate"
        [icon]="'fa-solid fa-clone'"
        [routerLink]="['/product']"
        [queryParams]="{ id: productDetailService.product.id }"
        [isLoading]="productDetailService.loading"
        [spinnerClassesString]="'spinner-white'"
        [dataCyAttribute]="'duplicate-product'"
        [replaceUrl]="true"
      ></btn>
      <btn
        *ngIf="!productDetailService.isCreating && productDetailService.product.isDeletable"
        [btnType]="'button'"
        [classes]="[
          productDetailService.product.children?.length > 0 || productDetailService.product.isBundle ? 'd-none' : '',
          'btn-first-red',
          'float-right',
          'me-2'
        ]"
        [btnDisabled]="productDetailService.loading"
        [label]="'delete'"
        [icon]="'far-trash-alt'"
        [dataCyAttribute]="'delete-product'"
        [title]="'delete' | translate"
        (onEvent)="deleteProduct()"
      ></btn>
    </ng-container>
    <ng-container class="back-button">
      <btn
        [label]="'back'"
        [title]="'back' | translate"
        [isBackButton]="true"
        [classes]="['btn-third', 'float-right', 'me-2']"
        [icon]="'far-arrow-left'"
        (onEvent)="goBack()"
      ></btn>
    </ng-container>
  </page-header>

  <section
    class="content"
    *ngIf="!productDetailService.isCreating && !productDetailService.loading && !!productDetailService.product"
  >
    <nav class="tab-container" type="pills">
      <ul role="tablist" class="nav nav-pills">
        <li *ngFor="let link of activeLinks" class="nav-item product-tab-heading">
          <a
            (click)="activeLink = link"
            [ngClass]="activeLink === link ? 'active' : ''"
            class="nav-link"
            data-cy="product-nav-link"
            [routerLink]="link !== 'common' ? link : '/product/' + productDetailService.product.id"
            >{{ 'product.tab.' + link | translate }}</a
          >
        </li>
      </ul>
    </nav>
    <router-outlet></router-outlet>
  </section>

  <section class="content" *ngIf="!productDetailService.loading && productDetailService.isCreating">
    <product-form></product-form>
  </section>
</div>
