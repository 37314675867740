<div class="card">
  <div class="row mb-2">
    <div class="col align-self-center">
      <h3 class="card-title">
        {{ 'customer' | translate }}
      </h3>
    </div>
    <div class="col d-flex flex-row-reverse" [ngClass]="{ invisible: !canCreateOrEdit }">
      <btn
        [label]="'recalculate'"
        [title]="'recalculate' | translate"
        [icon]="'far-sync'"
        [classes]="['btn-third', 'btn-sm']"
        (onEvent)="onRecalculatedPricesByPriceLevel()"
      ></btn>
    </div>
  </div>
  <div class="box-border">
    <div class="d-flex justify-content-between align-items-center">
      <div
        class="align-self-center"
        [ngClass]="{
          'flex-gap-10':
            order.customer &&
            order.customer.firstName &&
            order.customer.firstName.length &&
            order.customer.lastName &&
            order.customer.lastName.length
        }"
      >
        <div>
          <div
            class="circle"
            *ngIf="
              order.customer &&
              order.customer.firstName &&
              order.customer.firstName.length &&
              order.customer.lastName &&
              order.customer.lastName.length
            "
          >
            <span class="initials"> {{ order.customer.firstName[0] }}{{ order.customer.lastName[0] }} </span>
          </div>
        </div>
        <div class="my-auto" data-cy="customer-details">
          <div class="card-subheading">
            <a
              class="link"
              *ngIf="order.customer?.name"
              [routerLink]="['/customer', order.customer.id]"
              data-cy="customer-link"
            >
              {{ order.customer.name }}
            </a>
          </div>
          <div class="card-subheading">
            <a
              class="link"
              *ngIf="order.customer"
              [routerLink]="['/customer', order.customer.id]"
              data-cy="customer-link"
            >
              {{ order.customer.firstName + ' ' + (order.customer.lastName !== null ? order.customer.lastName : '') }}
            </a>
            {{ !order.customer ? 'Nevybraný zákazník' : '' }}
          </div>
          <div class="product-details" *ngIf="order.customer?.email || 0" data-cy="customer-email">
            {{ order.customer.email }}
          </div>
          <div class="product-details" *ngIf="order.customer?.phone || 0">
            {{ order.customer.phone }}
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <modal-customer-search
          *ngIf="canCreateOrEdit"
          [(customer)]="order.customer"
          [btnTitle]="'customer.add' | translate"
          [dataCyAttribute]="'modal-customer-search'"
        >
        </modal-customer-search>
        <btn
          [title]="'delete' | translate"
          [icon]="'far-trash-alt'"
          [classes]="['ms-1 btn-trash', 'btn-sm']"
          (onEvent)="order.customer = null"
          *ngIf="order.customer"
          [dataCyAttribute]="'order-customer-delete'"
        ></btn>
      </div>
    </div>
  </div>
  <div class="box-border mt-2">
    <div class="d-flex justify-content-between align-items-center">
      <div class="card-title w-auto">
        {{ 'address.invoice' | translate }}
      </div>
      <div class="align-self-center w-auto" *ngIf="canCreateOrEdit">
        <modal-address
          [isModalOnOrderPage]="true"
          [(address)]="order.invoiceAddress"
          [customer]="order.customer"
          [isCreating]="!order.invoiceAddress?.country"
          [dataCyAttribute]="'modal-address-invoice'"
          (editedAddressChange)="isEditedAddress()"
          (onEditedAddressChange)="onEditedAddress($event, true)"
        >
        </modal-address>
      </div>
    </div>
    <div class="" *ngIf="order.invoiceAddress">
      <div data-cy="order-invoice-address">
        <div
          class="d-flex justify-content-center align-items-center card-subheading"
          *ngIf="!order.invoiceAddress?.country"
        >
          {{ 'no.address' | translate }}
        </div>
        <div *ngIf="order.invoiceAddress?.companyName">
          {{ order.invoiceAddress.companyName }}
        </div>
        <div *ngIf="order.invoiceAddress?.firstName">
          {{ order.invoiceAddress.firstName + ' ' + order.invoiceAddress.lastName }}
        </div>
        <div class="flex-gap-5">
          <div *ngIf="order.invoiceAddress?.ic" data-cy="invoiceAddress-ic">
            {{ 'company.ic' | translate }}: {{ order.invoiceAddress.ic
            }}<span *ngIf="order.invoiceAddress && order.invoiceAddress.dic">,</span>
          </div>
          <div *ngIf="order.invoiceAddress?.dic" data-cy="invoiceAddress-dic">
            {{ 'company.dic' | translate }}: {{ order.invoiceAddress.dic }}
          </div>
        </div>
        <div *ngIf="order.invoiceAddress?.phone">
          {{ order.invoiceAddress.phone }}
        </div>
        <div *ngIf="order.invoiceAddress?.email">
          {{ order.invoiceAddress.email }}
        </div>
        <div *ngIf="order.invoiceAddress?.street">
          {{ order.invoiceAddress.street }}
        </div>
        <div class="flex-gap-5">
          <div *ngIf="order.invoiceAddress?.city">
            {{ order.invoiceAddress.city }}
          </div>
          <div *ngIf="order.invoiceAddress?.zip">
            {{ order.invoiceAddress.zip }}
            <span *ngIf="order.invoiceAddress?.city">,</span>
          </div>
          <div *ngIf="order.invoiceAddress?.country">
            {{ order.invoiceAddress.country }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box-border mt-2">
    <div class="d-flex justify-content-between align-items-center">
      <div class="card-title">
        {{ 'address.shipping' | translate }}
      </div>
      <div *ngIf="canCreateOrEdit && !isShippingAddressSameAsInvoice">
        <modal-address
          [isModalOnOrderPage]="true"
          [(address)]="order.shippingAddress"
          [customer]="order.customer"
          [isCreating]="!order.shippingAddress?.country"
          (editedAddressChange)="isEditedAddress()"
          (onEditedAddressChange)="onEditedAddress($event, false)"
          [dataCyAttribute]="'modal-address-shipping'"
        >
        </modal-address>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <form-row-toggle
          [noMarginBottom]="true"
          [cursorPointer]="true"
          [label]="'address.same'"
          [model]="isShippingAddressSameAsInvoice"
          (modelChange)="relationInvoiceShippingChanged($event)"
          [classesString]="'text-nowrap'"
          [inputDisabled]="!canCreateOrEdit"
          [dataCyAttribute]="'toggle-isShippingAddressSameAsInvoice'"
        ></form-row-toggle>
      </div>
    </div>
    <div class="row mt-2" *ngIf="order.shippingAddress">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <div
            class="d-flex justify-content-center align-items-center card-subheading"
            *ngIf="!order.shippingAddress?.country"
          >
            {{ 'no.address' | translate }}
          </div>
          <div *ngIf="!isShippingAddressSameAsInvoice" data-cy="order-shipping-address">
            <div *ngIf="order.shippingAddress && order.shippingAddress.companyName">
              {{ order.shippingAddress.companyName }}
            </div>
            <div *ngIf="order.shippingAddress && order.shippingAddress.firstName">
              {{ order.shippingAddress.firstName + ' ' + order.shippingAddress.lastName }}
            </div>
            <div *ngIf="order.shippingAddress && order.shippingAddress.phone">
              {{ order.shippingAddress.phone }}
            </div>
            <div *ngIf="order.shippingAddress && order.shippingAddress.email">
              {{ order.shippingAddress.email }}
            </div>
            <div *ngIf="order.shippingAddress.street">
              {{ order.shippingAddress.street }}
            </div>
            <div class="flex-gap-5">
              <div *ngIf="order.invoiceAddress?.city">
                {{ order.shippingAddress.city }}
              </div>
              <div *ngIf="order.invoiceAddress?.zip">
                {{ order.shippingAddress.zip }}
                <span *ngIf="order.shippingAddress?.city">,</span>
              </div>
              <div *ngIf="order.invoiceAddress?.country">
                {{ order.shippingAddress.country }}
              </div>
            </div>
            <div *ngIf="order.shippingAddress?.branchId" class="flex-gap-5">
              <div>{{ 'branch' | translate }}:</div>
              <div>
                {{ order.shippingAddress?.branchId }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
