<!--<div class="row mb-5">-->
<!--  <div class="mb-5"></div>-->
<!--  <div>{{'title.print-code' | translate}}</div>-->
<!--</div>-->
<div class="row">
  <div class="col-4">
    <div class="card">
      <form-row-toggle
        *ngIf="!productMovePack?.moves?.length && !isGS1"
        [label]="'code.printType'"
        [model]="printType === 'multi'"
        (modelChange)="$event ? (printType = 'multi') : (printType = 'single'); onSettingChange()"
      ></form-row-toggle>
      <form-row-toggle
        *ngIf="productMovePack?.moves?.length"
        [label]="'code.printType.multiToLabelPrinter'"
        [model]="printTypeMultiToLabelPrinter"
        (modelChange)="printTypeMultiToLabelPrinter = $event; onSettingChange()"
      ></form-row-toggle>
      <form-row-input
        *ngIf="printType === 'multi' && !productMovePack?.moves?.length"
        [label]="'code.quantity'"
        [inputType]="'number'"
        [(model)]="quantity"
        [minimumVal]="1"
        [maximumVal]="39"
        [class]="'mt-3'"
        (modelChange)="onSettingChange()"
      ></form-row-input>
      <form-row-toggle
        *ngIf="!isGS1"
        [label]="'code.type'"
        [model]="codeType === 'qr'"
        (modelChange)="$event ? (codeType = 'qr') : (codeType = 'barcode'); onSettingChange()"
      ></form-row-toggle>
      <form-row-toggle
        *ngIf="!!productMovePack?.moves?.length"
        [label]="'code.print.serials'"
        [(model)]="isPrintingSerialNumbers"
        (modelChange)="onSettingChange()"
      ></form-row-toggle>
      <form-row-toggle
        *ngIf="!productMovePack?.moves?.length"
        [label]="'code.print.gs1'"
        [(model)]="isGS1"
        (modelChange)="onSettingChange()"
      ></form-row-toggle>
      <form-row-toggle
        [label]="'code.title'"
        [(model)]="isBarcodeTitleVisible"
        (modelChange)="onSettingChange()"
      ></form-row-toggle>
      <form-row-toggle
        [label]="'code.ean'"
        [(model)]="isBarcodeEanVisible"
        (modelChange)="onSettingChange()"
      ></form-row-toggle>
      <form-row-toggle
        [label]="'code.price'"
        [(model)]="isBarcodePriceVisible"
        (modelChange)="onSettingChange()"
      ></form-row-toggle>
      <!--    <form-row-toggle-->
      <!--      [label]="'code.wide'"-->
      <!--      [(model)]="isBarcodeWide"-->
      <!--      (modelChange)="generateCode()"-->
      <!--    ></form-row-toggle>-->
    </div>
    <div class="card" *ngIf="!productMovePack?.moves?.length">
      <form-row-toggle
        [label]="'code.custom.toggle'"
        [(model)]="isCustomData"
        (modelChange)="onSettingChange()"
      ></form-row-toggle>
      <div *ngIf="isCustomData" class="mt-3">
        <form-row-input
          [label]="'code.custom.label'"
          [inputType]="'string'"
          [(model)]="customTitle"
          (modelChange)="onSettingChange()"
        ></form-row-input>
        <form-row-input
          [label]="'code.custom.ean'"
          [inputType]="'string'"
          [(model)]="customEan"
          (modelChange)="onSettingChange()"
        ></form-row-input>
        <form-row-input
          *ngIf="isGS1"
          [label]="'code.custom.batch'"
          [inputType]="'string'"
          [(model)]="customBatch"
          (modelChange)="onSettingChange()"
        ></form-row-input>
        <form-row-input
          *ngIf="isGS1"
          [label]="'code.custom.expirationDate'"
          [inputType]="'date'"
          [(model)]="customExpirationDate"
          (modelChange)="onSettingChange()"
        ></form-row-input>
        <form-row-input
          [label]="'code.custom.price'"
          [inputType]="'string'"
          [(model)]="customPrice"
          (modelChange)="onSettingChange()"
        ></form-row-input>
      </div>
    </div>
    <div class="card">
      <form-row-toggle
        [label]="'code.print.custom.dimensions'"
        [(model)]="isCustomDimensions"
        [class]="'mb-3'"
        (modelChange)="onSettingChange()"
      ></form-row-toggle>
      <div *ngIf="isCustomDimensions" class="mb-3">
        <form-row-input
          *ngIf="printType === 'multi' && !printTypeMultiToLabelPrinter"
          [label]="'code.ticketArrayWidth'"
          [inputType]="'number'"
          [appendUnitCustom]="'mm'"
          [(model)]="ticketArrayWidthMm"
          (modelChange)="onSettingChange()"
        ></form-row-input>
        <form-row-input
          [label]="'code.width'"
          [inputType]="'number'"
          [appendUnitCustom]="'mm'"
          [(model)]="ticketWidthMm"
          (modelChange)="onSettingChange()"
        ></form-row-input>
        <form-row-input
          [label]="'code.height'"
          [inputType]="'number'"
          [appendUnitCustom]="'mm'"
          [(model)]="ticketHeightMm"
          (modelChange)="onSettingChange()"
        ></form-row-input>
        <form-row-input
          [label]="'code.paddingHorizontal'"
          [inputType]="'number'"
          [appendUnitCustom]="'mm'"
          [(model)]="barcodePaddingHorizontalMm"
          (modelChange)="onSettingChange()"
        ></form-row-input>
        <form-row-input
          [label]="'code.paddingVertical'"
          [inputType]="'number'"
          [appendUnitCustom]="'mm'"
          [(model)]="barcodePaddingVerticalMm"
          (modelChange)="onSettingChange()"
        ></form-row-input>
        <form-row-input
          [label]="'code.marginTop'"
          [inputType]="'number'"
          [appendUnitCustom]="'mm'"
          [(model)]="pageMarginTopMm"
          (modelChange)="onSettingChange()"
        ></form-row-input>
        <form-row-input
          [label]="'code.marginLeft'"
          [inputType]="'number'"
          [appendUnitCustom]="'mm'"
          [(model)]="pageMarginLeftMm"
          (modelChange)="onSettingChange()"
        ></form-row-input>
        <form-row-input
          *ngIf="codeType === 'qr'"
          [label]="'code.qrHeightMm'"
          [inputType]="'number'"
          [appendUnitCustom]="'mm'"
          [(model)]="qrHeightMm"
          (modelChange)="onSettingChange()"
        ></form-row-input>
        <form-row-input
          [label]="'code.textSizeMultiplier'"
          [inputType]="'number'"
          [(model)]="textSizeMultiplier"
          (modelChange)="onSettingChange()"
        ></form-row-input>
      </div>
      <btn [label]="'print'" [classes]="['btn-block', 'btn', 'btn-primary']" (onEvent)="confirmPreview()"></btn>
    </div>
  </div>
  <div class="col-8 ps-2 pe-5">
    <div>
      <ng-template #ticketTemplate let-ticketData="ticketData">
        <div
          class="print-label-outline"
          #printable
          [style.width.mm]="ticketWidthMm"
          [style.height.mm]="ticketHeightMm"
          [style.padding-left.mm]="barcodePaddingHorizontalMm"
          [style.padding-right.mm]="barcodePaddingHorizontalMm"
          [style.padding-top.mm]="barcodePaddingVerticalMm"
          [style.padding-bottom.mm]="barcodePaddingVerticalMm"
        >
          <div *ngIf="codeType === 'qr'">
            <div
              class="print-label-container print-label-container-qrcode"
              [style.width.mm]="ticketWidthMm"
              [style.height.mm]="ticketHeightMm"
              [style.margin-top.mm]="pageMarginTopMm"
              [style.margin-left.mm]="pageMarginLeftMm"
            >
              <img
                [src]="ticketData.base64"
                alt=""
                *ngIf="ticketData.base64 && ticketData.base64.length > 0"
                class="qr-code"
                [style.height]="qrHeightMm > 0 ? qrHeightMm + 'mm' : '100%'"
              />
              <div
                class="print-2nd-col"
                [style.padding-top.mm]="ticketHeightMm / 20"
                [style.padding-bottom.mm]="ticketHeightMm / 20"
                [style.margin-left.mm]="ticketWidthMm / 30"
              >
                <div class="font-weight-bold" [style.font-size.mm]="(ticketHeightMm / 5) * textSizeMultiplier">
                  {{ isBarcodeTitleVisible ? ticketData.labelTitle : '&nbsp;' }}
                </div>
                <div [style.font-size.mm]="(ticketHeightMm / 6) * textSizeMultiplier">
                  {{ isBarcodeEanVisible ? ticketData.labelEan : '&nbsp;' }}
                </div>
                <div
                  class="text-end"
                  [style.padding-top.mm]="ticketHeightMm / 10"
                  [style.font-size.mm]="(ticketHeightMm / 6) * textSizeMultiplier"
                >
                  {{ isBarcodePriceVisible ? ticketData.labelPrice : '&nbsp;' }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="codeType === 'barcode'">
            <div
              class="print-label-container print-label-container-barcode"
              [style.width.mm]="ticketWidthMm"
              [style.height.mm]="ticketHeightMm"
              [style.margin-top.mm]="pageMarginTopMm"
              [style.margin-left.mm]="pageMarginLeftMm"
            >
              <img
                [src]="ticketData.base64"
                alt=""
                *ngIf="ticketData.base64 && ticketData.base64.length > 0"
                class="bar-code"
              />
              <div
                class="print-2nd-col"
                [style.padding-left.mm]="ticketWidthMm / 25"
                [style.padding-right.mm]="ticketWidthMm / 25"
              >
                <div class="font-weight-bold" [style.font-size.mm]="(ticketHeightMm / 5) * textSizeMultiplier">
                  {{ isBarcodeTitleVisible ? ticketData.labelTitle : '&nbsp;' }}
                </div>
                <div class="print-2nd-row">
                  <div [style.font-size.mm]="(ticketHeightMm / 6) * textSizeMultiplier">
                    {{ isBarcodeEanVisible ? ticketData.labelEan : '&nbsp;' }}
                  </div>
                  <div class="text-end" [style.font-size.mm]="(ticketHeightMm / 6) * textSizeMultiplier">
                    {{ isBarcodePriceVisible ? ticketData.labelPrice : '&nbsp;' }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--    <img-->
          <!--      [src]="barcode.val"-->
          <!--      alt=""-->
          <!--      *ngIf="barcode.val && barcode.val.length > 0"-->
          <!--      class="barcode-array-item-img"-->
          <!--    />-->
        </div>
      </ng-template>

      <div
        *ngIf="printType === 'single'"
        class="pageCard"
        [style.width]="'calc(' + (ticketWidthMm + barcodePaddingHorizontalMm * 2) + 'mm + 40px)'"
      >
        <ng-container [ngTemplateOutlet]="ticketTemplate" [ngTemplateOutletContext]="{ ticketData: singleTicketData }">
        </ng-container>
      </div>
      <div
        *ngIf="printType === 'multi' && !printTypeMultiToLabelPrinter"
        #printableMulti
        class="pageCard"
        [style.width]="'calc(' + ticketArrayWidthMm + 'mm + 40px)'"
      >
        <div class="multiTicketContainer" [style.width.mm]="ticketArrayWidthMm">
          <ng-template ngFor let-ticketData [ngForOf]="multiTicketData">
            <div class="multiTicketItemContainer">
              <ng-container [ngTemplateOutlet]="ticketTemplate" [ngTemplateOutletContext]="{ ticketData: ticketData }">
              </ng-container>
            </div>
          </ng-template>
        </div>
      </div>
      <div
        *ngIf="printType === 'multi' && printTypeMultiToLabelPrinter"
        #printableMultiToLabelPrinter
        class="pageCard"
        [style.width]="'calc(' + ticketWidthMm + 'mm + 40px)'"
      >
        <div class="multiTicketToLabelContainer" [style.width.mm]="ticketWidthMm">
          <ng-template ngFor let-ticketData [ngForOf]="multiTicketData">
            <div class="multiTicketToLabelItemContainer page-break">
              <ng-container [ngTemplateOutlet]="ticketTemplate" [ngTemplateOutletContext]="{ ticketData: ticketData }">
              </ng-container>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="invisible">
      <canvas #barcode id="barcode"></canvas>
    </div>
  </div>
</div>
