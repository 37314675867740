import { Component, EventEmitter, Input, Output } from '@angular/core'
import { GeoConfig, GeoListItemType } from '../../configs'
import { Address } from 'depoto-core/src/entities'

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent {
  @Input() address: Address
  @Output() addressChange: EventEmitter<any> = new EventEmitter()
  public states: GeoListItemType[] = []
  public countries: GeoListItemType[] = GeoConfig.countries
  public countriesWithNullOption: GeoListItemType[] = [{ name: 'all', code: null }, ...GeoConfig.countries]

  public selectCountry(country: string): void {
    this.address.country = country
    switch (country) {
      case 'US':
        this.states = GeoConfig.statesUS
        break
      case 'CA':
        this.states = GeoConfig.statesCA
        break
      default:
        this.states = []
        this.address.state = ''
        break
    }
  }
}
