<div class="d-flex">
  <div class="container card card-body ml-0">
    <div class="row">
      <div class="col-6">
        <form-row-select
          [label]="'checkout'"
          [optionKey]="'id'"
          [required]="true"
          [icon]="'far-cash-register'"
          [(model)]="orderDetailService.order.checkout"
          [bindToObject]="true"
          [options]="orderDetailService.checkouts"
          [dataCyAttribute]="'order-checkout'"
        >
        </form-row-select>
      </div>
      <div class="col-6">
        <form-row-select
          [label]="'carrier'"
          [icon]="'far-shipping-fast'"
          [required]="true"
          [(model)]="orderDetailService.order.carrier"
          [bindToObject]="true"
          [options]="orderDetailService.carriers"
          [dataCyAttribute]="'order-carrier'"
        >
        </form-row-select>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <form-row-select
          [label]="'currency'"
          [icon]="'far-coins'"
          [(model)]="orderDetailService.order.currency"
          [required]="true"
          [bindToObject]="true"
          [options]="orderDetailService.currencies"
          [dataCyAttribute]="'order-currencies'"
        >
        </form-row-select>
      </div>
      <div class="col-6">
        <form-row-select
          [label]="'payment.type'"
          [icon]="'far-money-check-alt'"
          [options]="orderDetailService.payments"
          [model]="orderDetailService.order.paymentItems[0]?.payment"
          (modelChange)="paymentSelected($event)"
          [bindToObject]="true"
          [dataCyAttribute]="'order-payment'"
        >
        </form-row-select>
      </div>
    </div>
  </div>
</div>
