interface OptionsConfigStrings {
  app_prefix: string
  scrollOffset: number
  refreshIntervalTimeMS: number
}

export class OptionsConfig {
  public static get strings(): OptionsConfigStrings {
    return {
      app_prefix: 'depoto_client_v2_',
      scrollOffset: 5,
      refreshIntervalTimeMS: 20 * 1000,
    }
  }
}
