import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CoreService } from '../../../services'
import { Order, Tag } from 'depoto-core/src/entities'
import { NotificationService } from '../../../services'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-order-tags',
  templateUrl: './order-tags.component.html',
  styleUrls: ['order-tags.component.scss'],
})
export class OrderTagsComponent {
  @Input() order: Order
  @Input() tags: Tag[] = []
  @Output() orderChange: EventEmitter<Order> = new EventEmitter<Order>()
  selectedTags: Tag[] = []
  constructor(
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  onChange(event) {
    this.order.tags = this.tags.filter(t => event.includes(t.id))
    this.selectedTags = this.order.tags
    this.orderChange.emit(this.order)
  }

  async removeTag(tag) {
    try {
      this.order.tags = await this.order.tags.filter(t => t.id !== tag.id)
      // this.notificationService.success(this.translateService.instant('ALERT TAG REMOVED'))
    } catch (err) {
      console.error(err)
    }
  }
}
