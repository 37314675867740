import { Component, OnInit } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { CoreService } from '../../services'
import { Supplier } from 'depoto-core/src/entities'
import { suppliersColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  name: '',
}

@Component({
  selector: 'app-suppliers',
  templateUrl: 'suppliers.component.html',
})
export class SuppliersComponent extends BaseListing implements OnInit {
  listingColumns: ListingItem[] = suppliersColumns
  suppliers: Supplier[] = []
  sortProp = 'name'
  sortDir = 'asc'
  totalItems = 0
  currentPage: number
  endPage: number
  filters = { ...DEFAULT_FILTERS }
  canCreate = false
  loading = true
  storageKey: { type: string; key: string }

  constructor(protected core: CoreService) {
    super(core)
    this.getStorageKey()
  }

  async ngOnInit() {
    this.filters = this.loadViewSettings('suppliers') || { ...DEFAULT_FILTERS }
    if (this.core.services.user.user) {
      this.checkPerms()
    }
    this.core.services.user.currentUserEmitter.subscribe().then(() => {
      this.checkPerms()
    })
    this.loadSorting('suppliers')
    await this.filter(1)
  }

  checkPerms() {
    this.canCreate =
      this.core.services.user.hasRole('ROLE_ORDER_CREATE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
  }

  async clearFilters() {
    this.filters = { ...DEFAULT_FILTERS }
    await this.filter(1)
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.currentPage = pageNo
    if (!concat) {
      this.reloading = true
    }
    try {
      const result = await this.core.services.supplier.getList(
        {
          page: pageNo,
          sort: sortProp,
          direction: sortDir,
          filters: { name: this.filters.name },
        },
        {
          id: null,
          name: null,
        }
      )
      if (!result?.items) return
      if (!concat) {
        this.suppliers = result.items
      } else {
        result.items.forEach(supplier => {
          this.suppliers.push(new Supplier(supplier))
        })
      }
      this.totalItems = result.paginator ? result.paginator.totalCount : 0
      this.endPage = result.paginator ? result.paginator.endPage : 0
      this.currentPage++
      this.saveViewSettings('suppliers', this.filters)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }
}
