<form [formGroup]="addressForm">
  <modal-container #modal [headerTitle]="modalTitle" [isLargeModal]="true">
    <ng-container class="modal-button-show">
      <btn
        [label]="btnLabel"
        [title]="modalTitle | translate"
        [icon]="btnIcon"
        [classes]="btnClasses"
        (onEvent)="showChildModal()"
        [dataCyAttribute]="dataCyAttribute"
      >
      </btn>
    </ng-container>
    <ng-container class="modal-body-content" *ngIf="isFormShown">
      <div class="card">
        <div *ngIf="address" class="operation-form">
          <ng-container *ngIf="areAddresses()">
            <modal-address-choose
              [addresses]="customer.addresses"
              (onSelectedAddress)="triggerOnSelectedAddress($event)"
            >
            </modal-address-choose>
            <div class="clearfix mb-3"></div>
          </ng-container>
          <div class="form-row row">
            <div class="col-sm-6">
              <form-row-input
                [label]="'firstName'"
                [icon]="'fa-user'"
                [formGroup]="addressForm"
                formControlName="firstName"
                data-cy="address-firstName"
              >
              </form-row-input>
            </div>
            <div class="col-sm-6">
              <form-row-input
                [label]="'lastName'"
                [icon]="'fa-user'"
                [formGroup]="addressForm"
                formControlName="lastName"
                data-cy="address-lastName"
              >
              </form-row-input>
            </div>
          </div>
          <div class="form-row row">
            <div class="col-sm-6">
              <form-row-input
                [label]="'email'"
                [icon]="'fa-envelope'"
                [formGroup]="addressForm"
                [requiredCssClass]="submitted && formControls.email.errors"
                formControlName="email"
                data-cy="address-email"
              >
              </form-row-input>
            </div>
            <div class="col-sm-6">
              <form-row-input
                [label]="'phone'"
                [icon]="'fa-phone'"
                [formGroup]="addressForm"
                formControlName="phone"
                data-cy="address-phone"
              >
              </form-row-input>
            </div>
          </div>
          <form-row-input
            [label]="'company.name'"
            [icon]="'fa-industry'"
            [(model)]="address.companyName"
            [formGroup]="addressForm"
            formControlName="companyName"
            data-cy="address-companyName"
          >
          </form-row-input>
          <div class="form-row row">
            <div class="col-sm-6">
              <form-row-input
                [label]="'company.ic'"
                [icon]="'fa-list'"
                [formGroup]="addressForm"
                formControlName="ic"
                data-cy="address-ic"
              >
              </form-row-input>
            </div>
            <div class="col-sm-6">
              <form-row-input
                [label]="'company.dic'"
                [icon]="'fa-list'"
                [formGroup]="addressForm"
                formControlName="dic"
                data-cy="address-dic"
              >
              </form-row-input>
            </div>
          </div>
          <form-row-input
            [label]="'street'"
            [icon]="'fa-map'"
            [formGroup]="addressForm"
            formControlName="street"
            data-cy="address-street"
          >
          </form-row-input>
          <div class="form-row row">
            <div class="col-sm-6">
              <form-row-input
                [label]="'city'"
                [icon]="'fa-map-marker'"
                [formGroup]="addressForm"
                formControlName="city"
                data-cy="address-city"
              >
              </form-row-input>
            </div>
            <div class="col-sm-6">
              <form-row-input
                [label]="'zip'"
                [icon]="'fa-map-signs'"
                [formGroup]="addressForm"
                formControlName="zip"
                data-cy="address-zip"
              >
              </form-row-input>
            </div>
          </div>
          <div *ngIf="states && states.length > 0" class="form-row row">
            <div class="col-sm-6">
              <form-row-select
                [label]="'country'"
                (modelChange)="selectCountry($event)"
                [options]="countries"
                [optionKey]="'code'"
                [formGroup]="addressForm"
                formControlName="country"
                [required]="addressForm.get('country').errors !== null && addressForm.get('country').errors.required"
                [requiredCssClass]="submitted && formControls.country.errors"
                [dataCyAttribute]="'address-country'"
              >
              </form-row-select>
            </div>
            <div class="col-sm-6">
              <form-row-select
                [label]="'state'"
                [options]="states"
                [optionKey]="'code'"
                [formGroup]="addressForm"
                formControlName="state"
                [dataCyAttribute]="'address.state'"
              >
              </form-row-select>
            </div>
          </div>
          <div class="row">
            <div [ngClass]="address.isStored ? 'col-9' : 'col-12'" *ngIf="!states || (states && states.length === 0)">
              <form-row-select
                [label]="'country'"
                (modelChange)="selectCountry($event)"
                [options]="countries"
                [optionKey]="'code'"
                [formGroup]="addressForm"
                formControlName="country"
                [required]="addressForm.get('country').errors !== null && addressForm.get('country').errors.required"
                [requiredCssClass]="submitted && formControls.country.errors"
                [dataCyAttribute]="'address-country'"
              >
              </form-row-select>
            </div>
          </div>
          <form-row-input
            [label]="'shopAddressId'"
            [icon]="'far-hashtag'"
            [(model)]="address.branchId"
          ></form-row-input>
          <form-row-input
            [label]="'modal-address.external-id'"
            [icon]="'far-hashtag'"
            [(model)]="address.externalId"
          ></form-row-input>
          <form-row-textarea [icon]="'far-note'" [label]="'note'" [(model)]="address.note"></form-row-textarea>
          <div class="row align-items-center padding-right flex-row-reverse mt-3" *ngIf="address.isStored">
            <form-row-checkbox
              [label]="'address.invoice'"
              [cursorPointer]="true"
              [formGroup]="addressForm"
              formControlName="isBilling"
            >
            </form-row-checkbox>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container class="modal-footer-content">
      <btn
        *ngIf="!isModalOnOrderPage"
        [label]="isCreating ? 'create' : 'save'"
        [title]="isCreating ? ('create' | translate) : ('save' | translate)"
        [icon]="'far-check'"
        [btnType]="'submit'"
        (onEvent)="isCreating ? createAddress() : updateAddress()"
        [classes]="['btn-first']"
      >
      </btn>
      <btn
        *ngIf="isModalOnOrderPage"
        [label]="isCreating ? 'create' : 'save'"
        [title]="isCreating ? ('create' | translate) : ('save' | translate)"
        [icon]="'far-check'"
        [btnType]="'submit'"
        (onEvent)="hideChildModalWithOutput()"
        [classes]="['btn-first']"
      >
      </btn>
    </ng-container>
  </modal-container>
</form>
