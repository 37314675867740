import { Component, Input, Output, EventEmitter } from '@angular/core'
import { Product } from 'depoto-core/src/entities'
import { GeoConfig, GeoListItemType } from '../../../configs'

@Component({
  selector: 'app-product-logistic-details',
  templateUrl: './product-logistic-details.component.html',
  styleUrls: ['./product-logistic-details.component.scss'],
})
export class ProductLogisticDetailsComponent {
  @Input() product: Product
  @Input() isInBulkEdits = false
  @Output() productChange: EventEmitter<Product> = new EventEmitter<Product>()
  @Output() valueAddedChange: EventEmitter<any> = new EventEmitter<any>()
  countries: GeoListItemType[] = GeoConfig.countries
  countriesOptions: any = ['keep.current', ...this.countries]
  countrySelectedOption = 'keep.current'
  dimensionX: string
  dimensionY: string
  dimensionZ: string

  valueChanged(event, dimension: string) {
    if (dimension === 'X') this.product.dimensionX = event
    else if (dimension === 'Y') this.product.dimensionY = event
    else if (dimension === 'Z') this.product.dimensionZ = event
    else if (dimension === 'weight') this.product.weight = event
  }

  valueAdded(value, property) {
    const event = [value, property]
    this.valueAddedChange.emit(event)
  }

  originCountryChange(event) {
    this.product.originCountry = event
    this.productChange.emit(this.product)
  }

  hsCodeChanged(event) {
    this.product.hsCode = event
    this.productChange.emit(this.product)
  }
}
