<app-filter-form
  #filterForm
  [filters]="queryFilters"
  [entity]="'product-moves'"
  (onExportResult)="filter()"
  (onClearClick)="clearFilters()"
  (onSubmit)="filter()"
  (onCreateModalHidden)="filter_form?.exportsModal.showChildModal()"
  [isFilterInvisible]="true"
  [areExportsInFilter]="true"
  [isExportButtonShown]="true"
>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 col-xl-2">
      <form-row-select
        [label]="'stock.load.type'"
        [(model)]="selectedOperationType"
        (modelChange)="clearFilters('depots')"
        [options]="operationTypes"
        [btnLabel]="
          selectedOperationType === 'transfer' && selectedDepotFrom > 0 && selectedDepotTo > 0 ? 'depots.switch' : ''
        "
        [btnClasses]="['btn-third']"
        (onBtnEvent)="switchDepots()"
        [icon]="null"
        [dataCyAttribute]="'product-moves-operation-type-filter'"
      ></form-row-select>
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-xl-1">
      <form-row-input
        [label]="'date.from'"
        [icon]="null"
        [inputType]="'date'"
        [(model)]="filterFrom"
        (keydown.enter)="filter()"
        [dataCyAttribute]="'product-moves-date-from-filter'"
      ></form-row-input>
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-xl-1">
      <form-row-input
        [label]="'date.to'"
        [icon]="null"
        [inputType]="'date'"
        [(model)]="filterTo"
        (keydown.enter)="filter()"
        [dataCyAttribute]="'product-moves-date-to-filter'"
      ></form-row-input>
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-xl-2">
      <form-row-select
        [label]="'product.batch'"
        [(model)]="selectedBatch"
        [options]="batches"
        [icon]="null"
        [dataCyAttribute]="'product-moves-batch-filter'"
      ></form-row-select>
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-xl-2">
      <form-row-select
        [label]="'expiration'"
        [icon]="null"
        [model]="selectedExpiration"
        (modelChange)="setExpirationFilter($event)"
        [options]="expirations"
        [dataCyAttribute]="'product-moves-selected-expiration-filter'"
      ></form-row-select>
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-xl-2">
      <form-row-select
        [label]="'createdBy'"
        [(model)]="filterUser"
        [options]="users"
        [optionKeyText]="'email'"
        [icon]="null"
        [dataCyAttribute]="'product-moves-email-filter'"
      ></form-row-select>
    </div>
    <!--    todo pridat do filteru-->
    <div class="col-12 col-sm-6 col-md-3 col-xl-2">
      <form-row-select-multiple
        [label]="'tags'"
        [(selectedValues)]="filterTags"
        [options]="tags"
        [dataCyAttribute]="'product-moves-tags-filter'"
      ></form-row-select-multiple>
    </div>

    <div class="col-12 col-sm-6 col-md-3 col-xl-1" *ngIf="selectedOperationType !== 'in'">
      <form-row-select
        [label]="'depot.from'"
        [(model)]="selectedDepotFrom"
        [options]="productDetailService.depots"
        [icon]="null"
        [dataCyAttribute]="'product-moves-depot-from-filter'"
      ></form-row-select>
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-xl-1" *ngIf="selectedOperationType !== 'out'">
      <form-row-select
        [label]="'depot.to'"
        [(model)]="selectedDepotTo"
        [options]="productDetailService.depots"
        [icon]="null"
        [dataCyAttribute]="'product-moves-depot-to-filter'"
      ></form-row-select>
    </div>
  </div>
</app-filter-form>

<div class="card mb-0">
  <div class="card-body p-0" [ngClass]="{ h150: !productMoves || (productMoves && !productMoves.length && loading) }">
    <loading-spinner [isLoading]="loading" [classesString]="'pt-6'"></loading-spinner>
    <div class="empty-list text-center p-5" *ngIf="!productMoves || (productMoves && !productMoves.length && !loading)">
      <i class="far fa-sad-tear" data-cy="empty"></i>
      <div>
        {{ 'no.record.found' | translate }}
      </div>
    </div>
    <div class="table-wrap table_responsive">
      <table class="table mb-0" *ngIf="productMoves.length">
        <thead>
          <tr>
            <th scope="col" class="cursor-pointer text-nowrap fixed-column-width ps-0" (click)="sortBy('created')">
              {{ 'dateCreated' | translate }}
              <i
                class="fa"
                [ngClass]="{
                  'fa-caret-up': sortProp === 'created' && sortDir === 'asc',
                  'fa-caret-down': sortProp === 'created' && sortDir === 'desc'
                }"
              ></i>
            </th>
            <th class="text-nowrap" scope="col">
              {{ 'number' | translate }}
            </th>
            <th class="text-nowrap" scope="col">
              {{ 'externalReference' | translate }}
            </th>
            <th class="text-nowrap" scope="col">
              <span *ngIf="selectedOperationType !== 'in'">
                {{ 'depotFrom' | translate }}
              </span>
              <span *ngIf="selectedOperationType !== 'in' && selectedOperationType !== 'out'">/</span>
              <span *ngIf="selectedOperationType !== 'out'">
                {{ 'depotTo' | translate }}
              </span>
            </th>
            <th class="text-start" scope="col">
              {{ 'position' | translate }}
            </th>
            <th class="text-nowrap" scope="col">
              <span>
                {{ 'product.expirationDate' | translate }}
              </span>
              /
              <span>
                {{ 'product.batch' | translate }}
              </span>
            </th>
            <th class="text-center" scope="col">
              {{ 'type' | translate }}
            </th>
            <th scope="col" class="text-center">
              {{ 'operation.amount' | translate }}
            </th>
            <th scope="col">
              {{ 'order' | translate }}
            </th>
            <th scope="col">
              {{ 'createdBy' | translate }}
            </th>
            <th class="pe-0 fixed-column-width" scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let move of productMoves">
            <td class="text-nowrap ps-1">
              {{ move.created ? (move.created | strToDate | date: 'dd.MM.yyyy HH:mm') : '' }}
            </td>
            <td class="text-start text-nowrap">
              {{ move && move.pack && move.pack.number ? move.pack.number : '' }}
            </td>
            <td class="text-start text-nowrap">
              {{ move && move.pack && move.pack.externalReference ? move.pack.externalReference : '' }}
            </td>
            <td class="text-nowrap">
              <span *ngIf="selectedOperationType !== 'in' && move.depotFromObj && move.depotFromObj.id > 0">
                <a>
                  {{ move.depotFromObj.name }}
                </a>
              </span>
              <span
                *ngIf="
                  selectedOperationType !== 'in' &&
                  move.depotFromObj &&
                  move.depotFromObj.id > 0 &&
                  selectedOperationType !== 'out' &&
                  move.depotToObj &&
                  move.depotToObj.id > 0
                "
                >/</span
              >
              <span *ngIf="selectedOperationType !== 'out' && move.depotToObj && move.depotToObj.id > 0">
                <!--              class="product-link" [routerLink]="['/depot', move.depotToObj.id]"-->
                <a>
                  {{ move.depotToObj.name }}
                </a>
              </span>
              <span *ngIf="selectedOperationType === 'transfer'">
                <span *ngIf="move.depotFromObj && move.depotFromObj.id > 0">
                  {{ move.depotFromObj.name }}
                </span>
                <span *ngIf="move.depotFromObj && move.depotFromObj.id > 0 && move.depotToObj && move.depotToObj.id > 0"
                  >/</span
                >
                <span *ngIf="move.depotToObj && move.depotToObj.id > 0">
                  {{ move.depotToObj.name }}
                </span>
              </span>
            </td>
            <td class="text-start text-nowrap">
              {{ !!move.position && move.position.length > 2 ? move.position : '' }}
            </td>
            <td class="text-start text-nowrap">
              <span *ngIf="move.expirationDate && !move.expirationDate.includes('00:00:00')">
                {{ move.expirationDate | fmtDate: 'dd.MM.yyyy HH:mm' }}
              </span>
              <span *ngIf="move.expirationDate && move.expirationDate.includes('00:00:00')">
                {{ move.expirationDate | fmtDate: 'dd.MM.yyyy' }}
              </span>
              <span *ngIf="move.expirationDate && move.batch">/</span>
              <span *ngIf="move.batch">
                {{ move.batch }}
              </span>
            </td>
            <td class="text-nowrap text-center">
              <span class="in-badge" *ngIf="move.pack.type.id === 'in'" title="{{ move.pack.type.id | translate }}">
                <!--              {{move.pack.type.name | trans}}-->
                <i class="fas fa-plus-square"></i>
              </span>
              <span class="out-badge" *ngIf="move.pack.type.id === 'out'" title="{{ move.pack.type.id | translate }}">
                <!--              {{move.pack.type.name | trans}}-->
                <i class="fas fa-minus-square"></i>
              </span>
              <span
                class="transfer-badge"
                *ngIf="move.pack.type.id === 'transfer'"
                title="{{ move.pack.type.id | translate }}"
              >
                <!--              {{move.pack.type.name | trans}}-->
                <i class="fas fa-exchange-alt"></i>
              </span>
              <span
                class="reservation-badge"
                *ngIf="move.pack.type.id === 'reservation'"
                title="{{ move.pack.type.id | translate }}"
              >
                <!--              {{move.pack.type.name | trans}}-->
                <i class="fas fa-clock"></i>
              </span>
            </td>
            <td class="text-center text-nowrap">
              <strong>
                {{
                  move.amount === 0 || move.amount === null ? '0' : (move.amount | number: '1.0-2' | replaceCommaToDot)
                }}
              </strong>
            </td>
            <td class="text-start text-nowrap">
              <div class="flex-gap-5 flex-row-reverse flex justify-content-md-end">
                <!--              <h1>{{move.pack.order.items | log}}</h1>-->
                <app-order-preview *ngIf="move?.pack?.order?.id" [order]="move?.pack?.order"> </app-order-preview>
              </div>
            </td>
            <td>
              <span class="text-nowrap" *ngIf="move.createdBy && move.createdBy.id > 0">
                <a class="link" [routerLink]="['/user', move.createdBy.id]" [title]="move.createdBy.email">
                  {{
                    move.createdBy.firstName && move.createdBy.firstName.length
                      ? move.createdBy.firstName + ' ' + move.createdBy.lastName
                      : move.createdBy.email
                  }}
                </a>
              </span>
            </td>
            <td class="pe-1">
              <a class="btn-second" [routerLink]="['/product-move-pack', move.pack.id]">
                {{ 'open' | translate }}
                <i class="far fa-chevron-right align-self-center"></i>
              </a>
            </td>
          </tr>
          <tr *ngIf="totalItems > productMoves.length">
            <td colspan="12" class="p-0 border-0 no-hover text-center">
              <app-pagination-btn
                *ngIf="
                  (!this.currentPage || this.currentPage <= this.endPage) && this.totalItems > this.productMoves.length
                "
                (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
                [endPage]="endPage"
                [loadingPage]="loading"
                [currentPage]="currentPage"
              >
              </app-pagination-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
