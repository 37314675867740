<div class="card">
  <modal-product-packs
    [pack]="newPack"
    [productId]="productDetailService.product?.id"
    [(loading)]="productDetailService.loading"
    (reloadProduct)="productDetailService.refreshProduct()"
    [isCreating]="true"
    [dataCyAttribute]="'modal-product-packs-create'"
  >
  </modal-product-packs>
  <div class="table-responsive" *ngIf="productDetailService.product.packs?.length > 0">
    <table class="table">
      <thead>
        <tr>
          <th>
            {{ 'code' | translate }}
          </th>
          <th>
            {{ 'ean' | translate }}
          </th>
          <th>
            {{ 'quantity' | translate }}
          </th>
          <th>
            {{ 'note' | translate }}
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pack of productDetailService.product.packs">
          <td>
            {{ pack?.code }}
          </td>
          <td>
            {{ pack?.ean }}
          </td>
          <td>
            {{ pack?.quantity }}
          </td>
          <td>
            {{ pack?.note }}
          </td>
          <td>
            <modal-product-packs
              [pack]="pack"
              [productId]="productDetailService.product?.id"
              [isCreating]="false"
              [(loading)]="productDetailService.loading"
              (reloadProduct)="productDetailService.refreshProduct()"
              [dataCyAttribute]="'modal-product-packs-edit'"
            >
            </modal-product-packs>
            <btn [icon]="'fa-qrcode'" (onEvent)="printPackQR(pack)"></btn>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
