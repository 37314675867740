<div class="app-container">
  <page-header [icon]="'far-tags'" [title]="'tags'">
    <ng-container class="header-button">
      <btn
        [icon]="'far-plus'"
        [label]="'tag.create'"
        [title]="'tag.create' | translate"
        [classes]="['btn-first', 'float-right']"
        [routerLink]="['/tag']"
        [dataCyAttribute]="'go-tag'"
      ></btn>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form
      [filters]="queryFilters"
      [entity]="'tags'"
      (onSubmit)="filter()"
      (onClearClick)="clearFilters()"
      [isFilterInvisible]="true"
    >
      <div class="row">
        <div class="col-12 col-sm-6">
          <form-row-input
            [label]="'tag.name'"
            [(model)]="filters.name"
            (keydown.enter)="filter()"
            [dataCyAttribute]="'tag-name-filter'"
          ></form-row-input>
        </div>
        <div class="col-12 col-sm-6">
          <form-row-select
            [label]="'tag.type'"
            [translationPrefix]="'tags.type.'"
            [model]="filters.type"
            (modelChange)="filters.type = $event; filter()"
            [options]="tagTypes"
            [dataCyAttribute]="'tag-type-filter'"
          ></form-row-select>
        </div>
      </div>
    </app-filter-form>

    <app-base-listing
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="tags"
      [listName]="'tags'"
      [needsTranslationPrefix]="true"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [(sortProp)]="sortProp"
      [(sortDir)]="sortDir"
      (reloadList)="filter()"
      (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
      (callAction)="callAction($event)"
    ></app-base-listing>
  </section>
</div>
