export const purchaseOrdersColumns = [
  {
    name: 'purchaseOrders.orderNumber',
    isDefault: true,
    property: 'number',
    propType: 'string',
    size: 1,
    sortable: true,
    sortBy: 'number',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
    cssClass: 'text-nowrap',
  },
  {
    name: 'purchaseOrders.reference',
    isDefault: true,
    property: 'externalReference',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
    cssClass: 'left text-nowrap',
  },
  {
    name: 'purchaseOrders.reference-supplier',
    isDefault: true,
    property: 'supplierReference',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'left text-nowrap',
  },
  {
    name: 'dateCreated',
    isDefault: true,
    property: 'created',
    propType: 'datetime',
    size: 1,
    sortable: true,
    sortBy: 'created',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'left text-nowrap',
  },
  {
    name: 'purchaseOrders.dateOfExpectedIncome',
    isDefault: true,
    property: 'dateEstimatedArrival',
    propType: 'date',
    size: 1,
    sortable: true,
    sortBy: 'dateEstimatedArrival',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'left text-nowrap',
  },
  {
    name: 'purchaseOrders.status',
    isDefault: true,
    property: 'status',
    propType: 'status',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'left text-nowrap',
  },
  {
    name: 'purchaseOrders.supplier',
    isDefault: true,
    property: 'supplier',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'left max-width-15 text-nowrap',
  },
  {
    name: 'purchaseOrders.depot',
    isDefault: true,
    property: 'depot',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'left text-nowrap',
  },
  {
    name: 'purchaseOrders.tags',
    isDefault: true,
    property: 'tags',
    propType: 'stringArray',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'left text-nowrap',
  },
  {
    name: 'purchaseOrders.createdBy',
    isDefault: true,
    property: 'createdBy',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'left text-nowrap',
  },
  {
    name: 'purchaseOrders.price',
    isDefault: true,
    property: 'purchasePriceAll',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'right',
    cssClass: 'text-nowrap',
  },
  {
    name: 'products.open',
    isDefault: true,
    property: '',
    propType: 'btn-right',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/purchase-order-creation/',
    align: '',
    cssClass: 'fixed-column-width pr-1 text-nowrap',
  },
]
export const ordersColumns = [
  {
    name: 'preview',
    isDefault: true,
    property: '',
    propType: 'order-preview',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
    cssClass: 'pl-1 pr-0 fixed-column-width ',
  },
  {
    name: 'externalReference',
    isDefault: false,
    property: 'externalReference',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'left text-nowrap',
  },
  {
    name: 'quantityUnavailable',
    isDefault: false,
    property: 'quantityUnavailable',
    propType: 'unavailable-number',
    size: 1,
    sortable: true,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: '',
  },
  {
    name: 'reservation.short',
    isDefault: true,
    property: 'reservationNumber',
    propType: 'reservation-number',
    size: 1,
    sortable: true,
    sortBy: 'reservationNumber',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
    cssClass: '',
  },
  {
    name: 'bill.short',
    property: 'billNumber',
    propType: 'integer',
    size: 1,
    sortable: true,
    sortBy: 'billNumber',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
    cssClass: '',
  },
  {
    name: 'status',
    isDefault: true,
    property: 'status',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
    cssClass: '',
  },
  {
    name: 'dateCreated',
    isDefault: true,
    property: 'dateCreated',
    propType: 'datetime',
    size: 1,
    sortable: true,
    sortBy: 'dateCreated',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'fixed-column-width text-nowrap',
  },
  {
    name: 'orders.createdBy',
    isDefault: false,
    property: 'createdBy',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'orders.dateExpedition',
    isDefault: false,
    property: 'dateExpedition',
    propType: 'date',
    size: 1,
    sortable: true,
    sortBy: 'dateExpedition',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    // cssClass: 'fixed-column-width text-nowrap',
    cssClass: 'text-nowrap',
  },
  {
    name: 'priority',
    isDefault: false,
    property: 'priority',
    propType: 'priority',
    size: 1,
    sortable: true,
    sortBy: 'priority',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    // cssClass: 'fixed-column-width text-nowrap',
    cssClass: 'text-nowrap',
  },
  {
    name: 'checkout',
    isDefault: true,
    property: 'checkout',
    propType: 'checkout-name',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: '',
  },
  {
    name: 'notes',
    isDefault: false,
    cssClass: 'text-nowrap',
    property: 'notes',
    propType: 'notes',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
  },
  {
    name: 'orders.customerName',
    isDefault: true,
    property: 'customer',
    propType: 'customer-name',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: '',
  },
  {
    name: 'shipping',
    isDefault: true,
    property: 'carrier',
    propType: 'carrier-name',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: '',
  },
  {
    name: 'country-invoice',
    isDefault: true,
    nameForSettings: 'country-billing',
    title: 'title ...',
    cssClass: 'text-nowrap',
    property: 'invoiceAddress',
    propType: 'address-country',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
  },
  {
    name: 'country-shipping',
    isDefault: false,
    property: 'shippingAddress',
    propType: 'address-country',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: '',
  },
  {
    name: 'processStatus',
    property: 'processStatus',
    propType: 'process-status',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'packageInfo',
    property: 'package',
    propType: 'order-packages',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: '',
  },
  {
    name: 'icons',
    isDefault: false,
    property: 'icons',
    propType: 'icons',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
  },
  {
    name: 'payments.method',
    isDefault: true,
    property: 'paymentItems',
    propType: 'order-payment-items',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
  },
  {
    name: 'tags',
    property: 'tags',
    propType: 'stringArray',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
    cssClass: '',
  },
  {
    name: 'price',
    isDefault: true,
    property: 'priceAll',
    propType: 'price',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'right',
    cssClass: 'text-nowrap',
  },
  {
    name: 'open',
    isDefault: true,
    property: '',
    propType: 'btn-right',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/order/',
    align: '',
    cssClass: 'fixed-column-width pr-1',
  },
]
export const priceLevelsColumns = [
  { name: 'name', property: 'name', propType: 'string', size: 3, sortable: true, sortBy: 'name', cssClass: 'pl-1' },
  {
    name: 'priceLevel.isPercentage',
    property: 'isPercentage',
    propType: 'boolean',
    size: 1,
    sortable: false,
    sortBy: '',
    cssClass: 'text-center',
  },
  {
    name: 'priceLevel.percent',
    property: 'percent',
    propType: 'percent',
    size: 2,
    sortable: true,
    sortBy: 'percent',
    cssClass: 'text-end',
  },
  {
    name: 'priceLevel.withVat',
    property: 'withVat',
    propType: 'boolean',
    size: 1,
    sortable: false,
    sortBy: '',
    cssClass: 'text-center',
  },
  {
    name: 'priceLevel.currency',
    property: 'currency',
    propType: 'item-id',
    size: 1,
    sortable: false,
    sortBy: '',
    cssClass: 'text-center',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/price-level',
    align: '',
    cssClass: 'pr-1',
  },
]

export const productsColumns = [
  {
    name: 'products.name',
    isDefault: true,
    property: 'name',
    propType: 'string',
    size: 1,
    sortable: true,
    sortBy: 'name',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'products.availability',
    isDefault: true,
    property: 'availability',
    propType: 'availability',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: '',
  },
  {
    name: 'products.code',
    isDefault: true,
    property: 'code',
    propType: 'string',
    size: 1,
    sortable: true,
    sortBy: 'code',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'products.ean',
    isDefault: true,
    property: 'ean',
    propType: 'string',
    size: 1,
    sortable: true,
    sortBy: 'ean',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'products.tags',
    isDefault: true,
    property: 'tags',
    propType: 'stringArray',
    size: 12,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
    cssClass: '',
  },
  {
    name: 'products.price',
    isDefault: true,
    property: 'sellPrice',
    propType: 'price',
    size: 1,
    sortable: true,
    sortBy: 'price',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: '',
  },
  {
    name: 'products.created',
    isDefault: true,
    property: 'created',
    propType: 'datetime',
    size: 2,
    sortable: true,
    sortBy: 'created',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
]

export const productMovePacksColumns = [
  {
    name: 'dateCreated',
    property: 'dateCreated',
    propType: 'datetime',
    size: 1,
    sortable: true,
    sortBy: 'created',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1',
  },
  {
    name: 'type',
    property: 'type',
    propType: 'operation-type',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'center',
  },
  {
    name: 'number',
    property: 'number',
    propType: 'integer',
    size: 1,
    sortable: true,
    sortBy: 'number',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
  },
  {
    name: 'externalReference',
    property: '',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'depot.from.to',
    property: '',
    propType: 'depot-from-to',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'order',
    property: '',
    propType: 'order-preview-move-packs',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: '',
  },
  {
    name: '',
    property: '',
    propType: 'order-bill-number',
    size: 1,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
  },
  {
    name: 'notes',
    cssClass: 'max-width-30',
    property: 'notes',
    propType: 'notes',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
  },
  {
    name: 'tags',
    isDefault: true,
    property: 'tags',
    propType: 'stringArray',
    size: 12,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
    cssClass: '',
  },
  {
    name: 'createdBy',
    property: 'createdBy',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/product-move-pack/',
    align: '',
    cssClass: 'pr-1',
  },
]

export const productMovePacksPurchaseOrderColumns = [
  {
    name: 'dateCreated',
    property: 'dateCreated',
    propType: 'datetime',
    size: 1,
    sortable: true,
    sortBy: 'created',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1',
  },
  {
    name: 'type',
    property: 'type',
    propType: 'operation-type',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'center',
  },
  {
    name: 'number',
    property: 'number',
    propType: 'integer',
    size: 1,
    sortable: true,
    sortBy: 'number',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
  },
  {
    name: 'externalReference',
    property: 'externalReference',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'depot.to',
    property: 'depotTo',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'notes',
    cssClass: 'max-width-30',
    property: 'note',
    propType: 'string',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
  },
  {
    name: 'createdBy',
    property: 'createdBy',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/product-move-pack/',
    // target: '_blank',
    align: '',
    cssClass: 'pr-1',
  },
]

export const customersColumns = [
  {
    name: 'company.name',
    property: 'companyName',
    propType: 'string',
    size: 3,
    sortable: true,
    sortBy: 'companyName',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'firstName',
    property: 'firstName',
    propType: 'string',
    size: 3,
    sortable: true,
    sortBy: 'firstName',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'lastName',
    property: 'lastName',
    propType: 'string',
    size: 3,
    sortable: true,
    sortBy: 'lastName',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'email',
    property: 'email',
    propType: 'string',
    size: 3,
    sortable: true,
    sortBy: 'email',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: '',
  },
  {
    name: 'phone',
    property: 'phone',
    propType: 'string',
    size: 3,
    sortable: true,
    sortBy: 'phone',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'tags',
    property: 'tags',
    propType: 'stringArray',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
    cssClass: 'text-nowrap',
  },
  {
    name: 'salesmen',
    property: 'users',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
    cssClass: 'text-nowrap',
  },
  {
    name: 'priceLevel.title',
    property: 'priceLevel',
    propType: 'relation-name',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'left',
    cssClass: 'text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/customer/',
    align: '',
    cssClass: 'pr-1',
  },
]

export const vouchersColumns = [
  {
    name: 'name',
    property: 'name',
    propType: 'string',
    size: 1,
    sortable: true,
    sortBy: 'name',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'code',
    property: 'code',
    propType: 'string',
    size: 1,
    sortable: true,
    sortBy: 'code',
    cssClass: 'text-nowrap',
  },
  {
    name: 'voucher.discountValue',
    property: '',
    propType: 'types-with-values',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'voucher.used.maxUse',
    property: '',
    propType: 'used-maxUse',
    size: 1,
    sortable: true,
    sortBy: 'used',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap text-end',
  },
  {
    name: 'voucher.validFrom',
    property: 'validFrom',
    propType: 'datetime',
    size: 1,
    sortable: true,
    sortBy: 'validFrom',
    cssClass: 'text-nowrap',
  },
  {
    name: 'voucher.validTo',
    property: 'validTo',
    propType: 'datetime',
    size: 1,
    sortable: true,
    sortBy: 'validTo',
    cssClass: 'text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/voucher',
    align: '',
    cssClass: 'pr-1',
  },
]

export const soldItemsColumns = [
  {
    name: '',
    property: 'product',
    propType: 'img',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'title',
    property: 'name',
    propType: 'product-link',
    size: 1,
    sortable: false,
    sortBy: 'name',
    btnStyle: '',
    icon: '',
    action: '',
    route: '/product/',
    align: '',
    // cssClass: 'pl-1 text-nowrap',
    cssClass: '',
  },
  {
    name: 'ean',
    property: 'ean',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: 'code',
    cssClass: 'text-nowrap',
  },
  {
    name: 'code',
    property: 'code',
    propType: 'string',
    size: 1,
    sortable: false,
    sortBy: 'code',
    cssClass: 'text-nowrap',
  },
  {
    name: 'quantity',
    property: 'quantity',
    propType: 'integer',
    size: 1,
    sortable: false,
    sortBy: '',
    cssClass: 'text-end',
  },
  {
    name: 'priceLevel.withoutVat',
    property: 'priceAllWithoutVat',
    propType: 'price',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'right',
    cssClass: 'text-nowrap',
  },
  {
    name: 'profit',
    property: 'profit',
    propType: 'price',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'right',
    cssClass: 'text-nowrap',
  },
  {
    name: 'orders',
    isDefault: true,
    property: '',
    propType: 'btn-orders',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: '',
    action: '',
    route: '/orders/',
    align: '',
    cssClass: 'fixed-column-width pr-1',
  },
]
export const printOrderExportsColumns = [
  {
    name: 'dateCreated',
    property: 'created',
    propType: 'datetime',
    size: 3,
    sortable: true,
    sortBy: 'created',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'date.from',
    property: 'dateFrom',
    propType: 'date',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'date.to',
    property: 'dateTo',
    propType: 'date',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'reports.checkouts',
    property: 'checkouts',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'download',
    property: '',
    propType: 'btn-gen',
    size: 1,
    sortable: false,
    sortBy: null,
    btnStyle: 'second',
    icon: '',
    action: 'downloadReport',
    route: '',
    align: '',
    cssClass: 'pr-1',
  },
]

export const printPurchaseExportsColumns = [
  {
    name: 'dateCreated',
    property: 'created',
    propType: 'datetime',
    size: 2,
    sortable: true,
    sortBy: 'created',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'date.from',
    property: 'dateFrom',
    propType: 'date',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'date.to',
    property: 'dateTo',
    propType: 'date',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'reports.depots',
    property: 'depots',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'reports.checkouts',
    property: 'checkouts',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'reports.suppliers',
    property: 'suppliers',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'brands',
    property: 'producers',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'download',
    property: '',
    propType: 'btn-gen',
    size: 1,
    sortable: false,
    sortBy: null,
    btnStyle: 'second',
    icon: '',
    action: 'downloadReport',
    route: '',
    align: '',
    cssClass: 'pr-1',
  },
]

export const printSaleExportsColumns = [
  {
    name: 'dateCreated',
    property: 'created',
    propType: 'datetime',
    size: 3,
    sortable: true,
    sortBy: 'created',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'date.from',
    property: 'dateFrom',
    propType: 'date',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'date.to',
    property: 'dateTo',
    propType: 'date',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'reports.checkouts',
    property: 'checkouts',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'reports.suppliers',
    property: 'suppliers',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'brands',
    property: 'producers',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'download',
    property: '',
    propType: 'btn-gen',
    size: 1,
    sortable: false,
    sortBy: null,
    btnStyle: 'second',
    icon: '',
    action: 'downloadReport',
    route: '',
    align: '',
    cssClass: 'pr-1',
  },
]

export const printStockExportsColumns = [
  {
    name: 'dateCreated',
    property: 'created',
    propType: 'datetime',
    size: 3,
    sortable: true,
    sortBy: 'created',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'reports.date.state',
    property: 'date',
    propType: 'date',
    size: 2,
    sortable: true,
    sortBy: 'date',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'tex-nowrap',
  },
  {
    name: 'reports.depots',
    property: 'depots',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'tex-nowrap',
  },
  {
    name: 'reports.suppliers',
    property: 'suppliers',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'tex-nowrap',
  },
  {
    name: 'brands',
    property: 'producers',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'tex-nowrap',
  },
  {
    name: 'download',
    property: '',
    propType: 'btn-gen',
    size: 1,
    sortable: false,
    sortBy: null,
    btnStyle: 'second',
    icon: '',
    action: 'downloadReport',
    route: '',
    align: '',
    cssClass: 'tex-nowrap pr-1',
  },
]

export const printInventoryExportsColumns = [
  {
    name: 'dateCreated',
    property: 'created',
    propType: 'datetime',
    size: 3,
    sortable: true,
    sortBy: 'created',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1',
  },
  {
    name: 'reports.date.state',
    property: 'date',
    propType: 'date',
    size: 2,
    sortable: true,
    sortBy: 'date',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'reports.depots',
    property: 'depots',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'reports.suppliers',
    property: 'suppliers',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'brands',
    property: 'producers',
    propType: 'array',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'inventory.approved',
    property: '',
    propType: 'approved',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 1,
    sortable: false,
    sortBy: null,
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/print/inventory-detail',
    align: '',
    cssClass: 'pr-1',
  },
]

export const usersColumns = [
  {
    name: 'name',
    property: '',
    propType: 'fullName',
    size: 4,
    sortable: true,
    sortBy: 'lastName',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'email',
    property: 'email',
    propType: 'string',
    size: 2,
    sortable: true,
    sortBy: 'email',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'enabled',
    property: 'enabled',
    propType: 'boolean',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'groups',
    property: 'groups',
    propType: 'stringArray',
    size: 4,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: '',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 4,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/user',
    align: '',
    cssClass: 'pr-1',
  },
]

export const tagsColumns = [
  {
    name: 'name',
    property: 'name',
    propType: 'string',
    size: 3,
    sortable: true,
    sortBy: 'name',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'type',
    property: 'type',
    propType: 'string',
    size: 3,
    sortable: false,
    sortBy: 'type',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'color',
    property: 'color',
    propType: 'string',
    size: 3,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/tag/',
    align: '',
    cssClass: 'pr-1',
  },
]

export const depotsColumns = [
  {
    name: 'depots.name',
    property: 'name',
    propType: 'string',
    size: 10,
    sortable: true,
    sortBy: 'name',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-wrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/depot/',
    align: '',
    cssClass: 'pr-1',
  },
]

export const suppliersColumns = [
  {
    name: 'suppliers.name',
    property: 'name',
    propType: 'string',
    size: 10,
    sortable: true,
    sortBy: 'name',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/supplier/',
    align: '',
    cssClass: 'pr-1',
  },
]

export const producersColumns = [
  {
    name: 'producers.name',
    property: 'name',
    propType: 'string',
    size: 10,
    sortable: true,
    sortBy: 'name',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/producer/',
    align: '',
    cssClass: 'pr-1',
  },
]

export const companyCarriersColumns = [
  {
    name: 'name',
    property: 'carrier',
    propType: 'string',
    size: 6,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'checkout',
    property: 'checkout',
    propType: 'checkout-name',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'center',
    cssClass: 'text-nowrap',
  },
  {
    name: 'enable',
    property: 'enable',
    propType: 'boolean',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: 'center',
    cssClass: 'text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/company-carrier/',
    align: '',
    cssClass: 'pr-1',
  },
]

export const groupsColumns = [
  {
    name: 'groups.group',
    property: 'name',
    propType: 'string',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1',
  },
  {
    name: 'groups.role',
    property: 'roles',
    propType: 'array-vertical',
    size: 8,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/group',
    align: '',
    cssClass: 'pr-1',
  },
]
export const parametersColumns = [
  {
    name: 'name',
    property: 'name',
    propType: 'string',
    size: 4,
    sortable: true,
    sortBy: 'name',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'type',
    property: 'type',
    propType: 'string',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/parameter/',
    align: '',
    cssClass: 'pr-1',
  },
]

export const paymentsColumns = [
  {
    name: 'payment.name',
    property: 'name',
    propType: 'string',
    size: 4,
    sortable: true,
    sortBy: 'name',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'payment.type',
    property: 'type',
    propType: 'string',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'payment.dispatchUnpaid',
    property: 'dispatchUnpaid',
    propType: 'boolean',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/payment/',
    align: '',
    cssClass: 'pr-1',
  },
]

export const vatsColumns = [
  {
    name: 'vats.name',
    property: 'name',
    propType: 'string',
    size: 9,
    sortable: true,
    sortBy: 'name',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'vats.percent',
    property: 'percent',
    propType: 'number',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'ban',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'vats.isDefault',
    property: 'default',
    propType: 'boolean',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 1,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/vat/',
    align: '',
    cssClass: 'pr-1',
  },
]

export const consentsColumns = [
  {
    name: 'name',
    property: 'name',
    propType: 'string',
    size: 4,
    sortable: true,
    sortBy: 'name',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'externalId',
    property: 'externalId',
    propType: 'string',
    size: 2,
    sortable: true,
    sortBy: 'externalId',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'created',
    property: 'created',
    propType: 'datetime',
    size: 2,
    sortable: true,
    sortBy: 'created',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'updated',
    property: 'updated',
    propType: 'datetime',
    size: 2,
    sortable: true,
    sortBy: 'updated',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/consent/',
    align: '',
    cssClass: 'pr-1',
  },
]

export const checkoutsColumns = [
  {
    name: 'checkouts.name',
    property: 'name',
    propType: 'string',
    size: 10,
    sortable: true,
    sortBy: 'name',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1 text-nowrap',
  },
  {
    name: 'open',
    property: '',
    propType: 'btn-right',
    size: 2,
    sortable: false,
    sortBy: '',
    btnStyle: 'second',
    icon: 'chevron-right',
    action: '',
    route: '/checkout/',
    align: '',
    cssClass: 'pr-1',
  },
]

export const exportsColumns = [
  {
    name: 'dateCreated',
    property: 'created',
    propType: 'datetime',
    size: 2,
    sortable: true,
    sortBy: 'created',
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
    cssClass: 'pl-1',
  },
  {
    name: 'type',
    property: 'type',
    propType: 'relation-name',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
  },
  // { name: 'format', property: 'format', propType: 'string', size: 2, sortable: false, sortBy: null, btnStyle: '', icon: '', action: '', route: '', align: '' },
  {
    name: 'createdBy',
    property: 'createdBy',
    propType: 'relation-name',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
  },
  {
    name: 'note',
    property: 'note',
    propType: 'string',
    size: 2,
    sortable: false,
    sortBy: null,
    btnStyle: '',
    icon: '',
    action: '',
    route: '',
    align: '',
  },
  {
    name: 'download',
    property: '',
    propType: 'btn-gen',
    size: 1,
    sortable: false,
    sortBy: null,
    btnStyle: 'second',
    icon: '',
    action: 'downloadReport',
    route: '',
    align: '',
    cssClass: 'pr-1',
  },
]
