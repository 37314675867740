<modal-container #modal [headerTitle]="headerTitle" [translateTitle]="translateTitle" [isLargeModal]="true">
  <ng-container class="modal-button-show">
    <span *ngIf="!lastPackageStatus && !lastProcessStatus">
      <span *ngIf="!!package">Od dopravce nemáme žádné informace</span>
    </span>
    <span
      *ngIf="!!lastPackageStatus && !lastProcessStatus"
      class="d-flex flex-row align-items-center justify-content-between ps-2"
    >
      {{ (lastPackageStatus.created | date: 'dd.MM.yyyy HH:mm') + ' - ' + lastPackageStatus.text }}
      <button
        class="btn-third btn-sm me-2"
        (click)="showChildModal()"
        title="Historie"
        data-cy="modal-process-status-modal-open"
      >
        <i class="far fa-history"></i>
      </button>
    </span>
    <div *ngIf="!lastPackageStatus && !!lastProcessStatus" class="flex-gap-5 justify-content-start align-items-center">
      <span
        class="inline-flex-gap-5 justify-content-center process-status-label"
        [ngClass]="'process-status-' + lastProcessStatus.status.id"
      >
        <i
          [ngClass]="
            lastProcessStatus.status.id === 'recieved'
              ? 'far fa-cart-arrow-down'
              : lastProcessStatus.status.id === 'picking'
                ? 'far fa-scanner'
                : lastProcessStatus.status.id === 'packing'
                  ? 'far fa-box-full'
                  : lastProcessStatus.status.id === 'packed'
                    ? 'far fa-box-check'
                    : lastProcessStatus.status.id === 'dispatched'
                      ? 'far fa-shipping-fast'
                      : lastProcessStatus.status.id === 'delivered'
                        ? 'far fa-check-circle'
                        : lastProcessStatus.status.id === 'returned'
                          ? 'far fa-shipping-timed'
                          : lastProcessStatus.status.id === 'picking_error'
                            ? 'far fa-exclamation-triangle'
                            : lastProcessStatus.status.id === 'cancelled'
                              ? 'far fa-ban'
                              : ''
          "
        >
        </i>
        <span>
          {{ (lastProcessStatus.status ? lastProcessStatus.status.id : '') | translate }}
        </span>
      </span>
      <btn
        *ngIf="lastProcessStatus.status.id === 'picking_error' || returnToExpeditionInProcess"
        [title]="'order.return.to.expedition' | translate"
        [classes]="['float-right', 'btn-first']"
        [icon]="'far fa-cart-arrow-down'"
        [spinnerClassesString]="'spinner-white'"
        (onEvent)="showChildModal('returnToExpedition'); changeProcessStatus = true"
        data-cy="order-return-to-expedition"
      >
      </btn>
      <btn
        [classes]="['btn-third', 'btn-sm']"
        (click)="showChildModal(); changeProcessStatus = false"
        [icon]="'far-history'"
        title="Historie"
      >
      </btn>
    </div>
    <div *ngIf="!lastPackageStatus && !!lastProcessStatus" class="text-start mt-1">
      <small>{{ lastProcessStatus.note.replace('.', '') }}</small>
    </div>
  </ng-container>
  <ng-container class="modal-body-content" *ngIf="!changeProcessStatus">
    <div class="card">
      <div class="table-responsive">
        <table class="table m-0">
          <tbody *ngIf="packageStates && packageStates.length">
            <tr *ngFor="let status of packageStates">
              <td>{{ status.created | date: 'dd.MM.yyyy HH:mm' }}</td>
              <td>{{ status.text }}</td>
            </tr>
          </tbody>
          <thead *ngIf="processStates && processStates.length">
            <tr>
              <th>{{ 'dateCreated' | translate }}</th>
              <th>{{ 'createdBy' | translate }}</th>
              <th>{{ 'status' | translate }}</th>
              <th>{{ 'note' | translate }}</th>
            </tr>
          </thead>
          <tbody *ngIf="processStates && processStates.length">
            <tr *ngFor="let status of processStates">
              <td class="no-hover" *ngIf="status?.created?.length > 0">
                {{ status.created | date: 'dd.MM.yyyy HH:mm' }}
              </td>
              <td class="no-hover" *ngIf="status?.created?.length < 1">{{ 'Neuloženo' }}</td>
              <td class="no-hover">
                <a class="link" *ngIf="status.createdBy" [routerLink]="['/user', status.createdBy.id]">
                  {{ status.createdBy ? status.createdBy.firstName + ' ' + status.createdBy.lastName : '' }}
                </a>
              </td>
              <td class="no-hover">
                <span
                  class="inline-flex-gap-5 justify-content-center align-items-center process-status-label"
                  [ngClass]="'process-status-' + status.status.id"
                >
                  <i
                    [ngClass]="
                      status.status.id === 'recieved'
                        ? 'far fa-cart-arrow-down'
                        : status.status.id === 'picking'
                          ? 'far fa-scanner'
                          : status.status.id === 'packing'
                            ? 'far fa-box-full'
                            : status.status.id === 'packed'
                              ? 'far fa-box-check'
                              : status.status.id === 'dispatched'
                                ? 'far fa-shipping-fast'
                                : status.status.id === 'delivered'
                                  ? 'far fa-check-circle'
                                  : status.status.id === 'returned'
                                    ? 'far fa-shipping-timed'
                                    : status.status.id === 'picking_error'
                                      ? 'far fa-exclamation-triangle'
                                      : status.status.id === 'cancelled'
                                        ? 'far fa-ban'
                                        : ''
                    "
                  ></i>
                  <span>
                    {{ (status.status.id ? status.status.id : '') | translate }}
                  </span>
                </span>
              </td>
              <td class="no-hover">{{ status.note }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
  <ng-container class="modal-body-content" *ngIf="changeProcessStatus">
    <div class="card">
      <form-row-textarea [icon]="'far-note'" [label]="'note'" [(model)]="note"></form-row-textarea>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content" *ngIf="changeProcessStatus">
    <btn
      [label]="'save'"
      [title]="'save' | translate"
      [icon]="'far fa-cart-arrow-down'"
      [classes]="['btn-first']"
      (onEvent)="returnToExpedition()"
      data-cy="order-return-to-expedition"
    ></btn>
  </ng-container>
</modal-container>
