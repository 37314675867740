import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { ClearanceItem, ClearanceItemLocation, Depot, Order } from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { CoreService } from '../../../services'
import { generateUuidV4 } from 'depoto-core/src/utils'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'modal-clearance-item',
  templateUrl: './modal-clearance-item.component.html',
  styleUrls: ['./modal-clearance-item.component.scss'],
})
export class ModalClearanceItemComponent {
  @ViewChild('modal') childModal: ModalContainerComponent
  @Input() depots: Depot[] = []
  availableDepots: Depot[] = []
  availableProductDepotsInString: any[] = []
  locations: (ClearanceItemLocation | any)[] = []
  isShowingNullStock = false
  loading = false
  @Input() order: Order
  @Input() clearanceItem: ClearanceItem = new ClearanceItem()
  @Output() updatedClearanceItem: EventEmitter<ClearanceItem> = new EventEmitter()

  constructor(
    public core: CoreService,
    public translateService: TranslateService
  ) {}

  getAvailableProductDepotsInString(depot) {
    const availableProductDepots = this.availableProductDepotsInString.filter(
      pDepot => Number(pDepot.depot) === Number(depot.id)
    )
    if (this.isShowingNullStock) {
      return availableProductDepots
    } else {
      return availableProductDepots.filter(
        pDepot => pDepot.quantity > 0 || this.locations.some(location => location.productDepot?.id === pDepot.id)
      )
    }
  }

  setLocationsProductDepot(locationUuid, productDepotId?) {
    const location = this.locations.find(l => l.uuid === locationUuid)
    if (productDepotId) {
      location.productDepot = this.clearanceItem.product.productDepots.find(
        pDepot => pDepot.id === Number(productDepotId)
      )
    } else {
      location.productDepot = this.getAvailableProductDepotsInString(location.depot)[0]
    }
  }

  setLocationsDepot(locationUuid, depotId) {
    const location = this.locations.find(l => l.uuid === locationUuid)
    location.depot = this.availableDepots.find(depot => depot.id === Number(depotId))
    this.setLocationsProductDepot(locationUuid)
  }

  setProductDepots() {
    this.availableProductDepotsInString = []
    this.clearanceItem.product.productDepots.forEach(pDepot =>
      this.availableProductDepotsInString.push({
        id: pDepot.id,
        depot: pDepot.depot.id,
        quantity: pDepot.quantityStock,
        name: String(
          (pDepot.supplier?.name ? 'D: ' + pDepot.supplier.name : '') +
            (pDepot.position !== '--' ? ' | P: ' + pDepot.position : '') +
            (pDepot.batch?.length ? ' | Š: ' + pDepot.batch : '') +
            (pDepot.expirationDate?.length ? ' | E: ' + pDepot.expirationDate?.split(' ')[0] : '') +
            ' (' +
            pDepot.quantityStock +
            ' / ' +
            pDepot.quantityReservation +
            ' / ' +
            pDepot.quantityAvailable +
            'ks)'
        ),
      })
    )
    this.setLocations()
  }

  setDepots() {
    const depots = this.clearanceItem.product.productDepots.map(pDepot => pDepot.depot)
    const uniqueDepots: Depot[] = []
    for (const depot of depots) {
      if (!uniqueDepots.some(d => d.id === depot.id)) {
        uniqueDepots.push(depot)
      }
    }
    this.availableDepots = uniqueDepots.filter(depot =>
      this.order.checkout.depots.some(checkoutDepot => checkoutDepot.id === depot.id)
    )
  }

  setLocations() {
    for (const pDepot of this.clearanceItem.productDepots) {
      const amount = this.clearanceItem.productMoves.reduce((total, productMove) => {
        if (productMove.productDepotFrom?.id === pDepot?.id) return total + productMove.amount
        else return total
      }, 0)
      this.locations.push({
        depot: pDepot.depot,
        productDepot: pDepot,
        amount: amount,
        locked: this.clearanceItem.locked,
        uuid: generateUuidV4(),
      })
    }
    this.loading = false
    this.childModal.showChildModal()
  }

  public showChildModal() {
    this.loading = true
    this.availableDepots = []
    this.availableProductDepotsInString = []
    this.locations = []
    this.setDepots()
    this.setProductDepots()
    // this.setLocations()
    // this.childModal.showChildModal()
  }

  private hideChildModal() {
    this.childModal.hideChildModal()
  }
  addLocation() {
    this.locations.push({
      depot: this.availableDepots[0],
      productDepot: null,
      amount: 0,
      locked: false,
      uuid: generateUuidV4(),
    })
  }

  removeLocation(location: ClearanceItemLocation) {
    this.locations = this.locations.filter(l => l.uuid !== location.uuid)
  }

  public async updateClearanceItem() {
    // todo FORM  a checkovat vyplnění selectů
    if (this.locations.some(l => !l.depot)) {
      alert(`modal-clearance-item.fill-depot.error`)
      return
    }
    if (this.locations.some(l => !l.productDepot)) {
      alert(`modal-clearance-item.fill-position.error`)
      return
    }
    if (this.locations.some(l => l.amount < 1)) {
      alert(`modal-clearance-item.fill-quantity.error`)
      return
    }
    if (this.clearanceItem.amount !== this.locations.reduce((total, location) => location.amount + total, 0)) {
      alert(
        this.translateService.instant('modal-clearance-item.wrong-quantities.error', {
          fields: this.clearanceItem.amount.toString(),
        })
      )
      return
    }
    const locations = []
    this.locations.forEach(location => {
      const pDepot = this.clearanceItem.product.productDepots.find(
        pDepot => pDepot.id === Number(location.productDepot.id)
      )
      locations.push({
        amount: location.amount,
        productDepot: pDepot.id,
        locked: location.locked,
      })
    })

    const clearanceItem = {
      id: this.clearanceItem.id,
      order: this.order.id,
      locations: locations,
    }
    await this.core.services.order.updateClearanceItemLocations(clearanceItem)
    this.updatedClearanceItem.emit(this.clearanceItem)
    this.hideChildModal()
  }

  trackByFn(index: any) {
    return index
  }
}
