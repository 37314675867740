import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ProductDepot, Supplier } from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { CoreService, NotificationService } from '../../../services'
import { TranslateService } from '@ngx-translate/core'
import { isObjectEmpty } from '../../../utils/misc.util'

@Component({
  selector: 'modal-stock-positions',
  templateUrl: 'modal-stock-positions.component.html',
  styleUrls: ['modal-stock-positions.component.scss'],
})
export class ModalStockPositionsComponent {
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Input() productDepot: ProductDepot
  @Input() public suppliers: Supplier[] = []
  @Output() productDepotChange: EventEmitter<any> = new EventEmitter()
  @Output() result: EventEmitter<any> = new EventEmitter()
  productDepotEdited: ProductDepot
  loading = false
  keyEventSubscriptions: any = {}
  constructor(
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  async updatePosition(): Promise<void> {
    this.loading = true
    try {
      // set position
      this.productDepotEdited.position = ['1', '2', '3']
        .map(x => this.productDepotEdited[`position${x}`] ?? '')
        .join('-')
      this.productDepot = this.productDepotEdited
      const updated = await this.core.services.product.updateProductDepotPart({
        id: this.productDepot.id,
        position: this.productDepot.position,
        position1: this.productDepot.position1,
        position2: this.productDepot.position2,
        position3: this.productDepot.position3,
        purchasePrice: this.productDepot.purchasePrice,
        batch: this.productDepot.batch,
        supplier: this.productDepot.supplier,
        expirationDate: this.productDepot.expirationDate,
      })
      if (updated) {
        this.productDepot = new ProductDepot(updated)
        this.notificationService.success(this.translateService.instant('alert.position.update'))
        this.productDepotChange.emit(this.productDepot)
        this.result.emit(true)
        this.onHideChildModal()
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  showChildModal(): void {
    this.keyEventSubscriptions.keyEsc = this.core.services.keyEvent.onKeyEsc
      .subscribe()
      .then(() => this.onHideChildModal())
    this.keyEventSubscriptions.scannedPositions = this.core.services.keyEvent.onScannedPositions
      .subscribe()
      .then(positions => {
        this.productDepotEdited = {
          ...this.productDepotEdited,
          position1: positions[0],
          position2: positions[1],
          position3: positions[2],
        }
        // this.appRef.tick()
      })
    this.productDepotEdited = JSON.parse(JSON.stringify(this.productDepot))
    this.childModal?.showChildModal()
  }

  onHideChildModal(): void {
    this.childModal?.childModal?.hide() // modal closes itself on close-buttons click only
    if (isObjectEmpty(this.keyEventSubscriptions)) return
    for (const keyEvent in this.keyEventSubscriptions) {
      this.keyEventSubscriptions[keyEvent]?.unsubscribe()
      delete this.keyEventSubscriptions[keyEvent]
    }
    // console.log('modal-stock-positions: all subscriptions are cancelled')
  }
}
