<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<app-header [isShown]="isAuthenticated() && isBaseModelLoaded"></app-header>

<app-side-menu [isShown]="isAuthenticated() && isBaseModelLoaded"></app-side-menu>

<div class="print-no-margin" [ngClass]="{ 'content-wrapper': isAuthenticated() && isBaseModelLoaded }">
  <ng-snotify></ng-snotify>
  <ng-template [ngIf]="isBaseModelLoaded && isAuthenticated()">
    <router-outlet></router-outlet>
  </ng-template>
  <ng-template [ngIf]="!isAuthenticated()">
    <app-login></app-login>
  </ng-template>
  <ng-template [ngIf]="!isBaseModelLoaded">
    <loading-spinner [isLoading]="true"></loading-spinner>
  </ng-template>

  <ng-template [ngIf]="isShutdownForOverdue">
    <h1 class="text-center">:(</h1>
  </ng-template>
  <ng-template [ngIf]="isLoading">
    <!--    todo -->
    <!--    <loading-spinner [isLoading]="true"></loading-spinner>-->
    <!--    // todo kolecko z loginu na app component, na loginu cekat a tocit nez se nacte menu items a user detail, filtry sipku dle xd,-->
  </ng-template>
</div>

<modal-overdue-payments *ngIf="isAuthenticated()" [company]="company"></modal-overdue-payments>

<!--TODO: fullscreen loader from AppLoader-->
<!--<div *ngIf="!this.isBaseModelLoaded" style="position: fixed; top: 0; left: 0; bottom: 0; right: 0; z-index: 9999; background-color: 'red'">-->
<!--  <loading-spinner [isLoading]="true"></loading-spinner>-->
<!--</div>-->

<div
  class="loader-wrap"
  *ngIf="!this.isBaseModelLoaded"
  style="position: fixed; top: 0; left: 0; bottom: 0; right: 0; z-index: 999; background-color: #f4f7fc"
>
  <div class="background slide-top"></div>
  <div class="empty"></div>
  <div class="spinner d-flex flex-column justify-content-center slide-bottom">
    <div class="spinner-border text-primary align-self-end" role="status"></div>
  </div>
</div>

<div id="sidebar-overlay" (click)="toggleLeftMenu()"></div>

<style>
  .loader-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
  }

  .background {
    background: transparent url('../assets/img/group559.svg') 0% 0% no-repeat padding-box;
    width: 151px;
    height: 30px;
  }

  .empty {
    width: 151px;
    height: 30px;
  }

  /*ANIMATIONS*/

  .slide-top {
    -webkit-animation: slide-top 1.1s ease-in 300ms both;
    animation: slide-top 1.1s ease-in 300ms both;
  }
  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
    }
  }

  .slide-bottom {
    -webkit-animation: slide-bottom 1.1s ease-in 300ms both;
    animation: slide-bottom 1.1s ease-in 300ms both;
  }

  @-webkit-keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
    }
  }
  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
    }
  }
</style>
