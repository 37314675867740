import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { Checkout, Depot, User, UserGroup } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../services'
import { TranslateService } from '@ngx-translate/core'
import { SchemaUtil, historyBack } from '../../utils'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { AVAILABLE_LANGUAGES } from '../../configs/languages'

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styles: [
    `
      .form-check-input.fix-checkbox-align {
        margin-top: 0.45rem;
      }
    `,
  ],
})
export class UserComponent implements OnInit, CheckDataChanges {
  user: User
  loggedInUser: User
  checkouts: Checkout[] = []
  depots: Depot[] = []
  groups: UserGroup[] = []
  visibleGroupes: string[] = []
  isCreating = false
  isUserProfile = false
  loading = true
  submitted = false

  userForm = new FormGroup({
    email: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.email]),
    plainPassword: new FormControl({ value: '', disabled: false }, []),
    firstName: new FormControl({ value: '', disabled: false }, [Validators.required]),
    lastName: new FormControl({ value: '', disabled: false }, [Validators.required]),
    phone: new FormControl({ value: '', disabled: false }, []),
    enabled: new FormControl({ value: false, disabled: false }, []),
    // groups: new FormControl({value: [], disabled: false}, []),
    // roles: new FormControl({value: [], disabled: false}, []),
    pin: new FormControl({ value: '', disabled: false }, []),
    // checkouts: new FormControl({value: [], disabled: false}, []),
    locale: new FormControl({ value: '', disabled: false }, []),
  })

  public readonly availableLanguages = AVAILABLE_LANGUAGES

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.route.params.forEach(async (params: Params) => {
      const id: number = +params['id']
      const profile: string = params['profile']
      if (!id) {
        if (!profile) {
          this.isCreating = true
          this.user = new User()
          this.userForm.controls.plainPassword.setValidators([Validators.required])
          this.userForm.controls.plainPassword.updateValueAndValidity()
          this.loading = false
          await this.getData()
        } else if (profile === 'profile') {
          this.loading = true
          this.isUserProfile = true
          this.userForm.controls.plainPassword.clearValidators()
          this.userForm.controls.plainPassword.updateValueAndValidity()
          this.user = new User(this.core.services.user.user)
          this.fillFormValue()
          await this.getData()
          this.loggedInUser = this.core.services.user.user
          this.loading = false
        }
      } else {
        this.userForm.controls.plainPassword.clearValidators()
        this.userForm.controls.plainPassword.updateValueAndValidity()
        await this.refreshData(id)
        this.fillFormValue()
      }
      this.loggedInUser = this.core.services.user.user
    })
  }

  fillFormValue() {
    this.userForm.setValue({
      email: this.user.email,
      plainPassword: this.user.plainPassword,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      phone: this.user.phone,
      enabled: this.user.enabled,
      // groups: this.user.groups,
      // roles: this.user.roles,
      pin: this.user.pin,
      locale: this.user.locale,
      // checkouts: this.user.checkouts
    })
  }

  getFormValue() {
    this.user.email = this.userForm.value.email
    this.user.plainPassword = this.userForm.value.plainPassword
    this.user.firstName = this.userForm.value.firstName
    this.user.lastName = this.userForm.value.lastName
    this.user.phone = this.userForm.value.phone
    this.user.enabled = this.userForm.value.enabled
    // this.user.groups = this.userForm.value.groups || []
    // this.user.roles = this.userForm.value.roles || []
    this.user.pin = this.userForm.value.pin
    this.user.locale = this.userForm.value.locale
    // this.user.checkouts = this.userForm.value.checkouts || []
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  goBack(): void {
    historyBack()
  }

  async refreshData(id: number) {
    this.loading = true
    this.user = new User(
      await this.core.services.user.getById(id, {
        id: null,
        locale: null,
        email: null,
        firstName: null,
        lastName: null,
        name: null,
        phone: null,
        enabled: null,
        groups: {
          id: null,
          name: null,
          roles: null,
        },
        roles: null,
        pin: null,
        company: {
          id: null,
          name: null,
          carrierRelations: {
            id: null,
          },
        },
        customers: {
          id: null,
          email: null,
          firstName: null,
          lastName: null,
          name: null,
          phone: null,
          note: null,
          wholesale: null,
        },
        checkouts: {
          id: null,
          name: null,
        },
        depots: {
          id: null,
          name: null,
        },
      })
    )
    await this.getData()
    this.loggedInUser = this.core.services.user.user
    this.loading = false
  }

  async getUserGroups() {
    this.loading = true
    this.groups = []
    const receivedData = await this.core.services.userGroup
      .getList(
        {},
        {
          id: null,
          name: null,
          roles: null,
        }
      )
      .then(result => result.items)
    receivedData.forEach(group => {
      this.groups.push(new UserGroup(group))
    })
    this.loading = false
  }

  async getCheckouts() {
    this.loading = true
    this.checkouts = []
    const res = await this.core.services.checkout
      .getList(
        {},
        {
          id: null,
          name: null,
        }
      )
      .then(result => result.items)
    res.forEach(checkout => {
      this.checkouts.push(new Checkout(checkout))
    })
    this.loading = false
  }

  async getDepots() {
    this.loading = true
    this.depots = []
    const res = await this.core.services.depot
      .getList(
        {},
        {
          id: null,
          name: null,
        }
      )
      .then(result => result.items)
    res.forEach(depot => {
      this.depots.push(new Depot(depot))
    })
    this.loading = false
  }

  async createUser() {
    this.submitted = true
    if (this.userForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValue()
      const user: User = await this.core.services.user.create(this.user)
      if (user && user.id > 0) {
        this.notificationService.success(this.translateService.instant('alert.user.created'))
        this.isCreating = false
        this.user = user
        this.router.navigate(['/user', user.id], { replaceUrl: true })
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async updateUser() {
    this.submitted = true
    if (this.userForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValue()
      const user: User = await this.core.services.user.update(this.user)
      if (user && user.id > 0) {
        this.notificationService.success(this.translateService.instant('alert.user.updated'))
        if (this.isUserProfile) {
          await this.setCurrentUser()
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  updateGroup(group: UserGroup, value: boolean) {
    if (value) {
      this.user.groups.push(group)
    } else {
      this.user.groups.forEach((g: UserGroup, i: number) => {
        if (g.id === group.id) {
          this.user.groups.splice(i, 1)
        }
      })
    }
  }

  updateCheckout(checkout: Checkout, value: boolean) {
    if (value) {
      this.user.checkouts.push(checkout)
    } else {
      this.user.checkouts.forEach((c: Checkout, i: number) => {
        if (c.id === checkout.id) {
          this.user.checkouts.splice(i, 1)
        }
      })
    }
  }

  updateDepot(depot: Depot, value: boolean) {
    if (value) {
      this.user.depots.push(depot)
    } else {
      this.user.depots.forEach((d: Depot, i: number) => {
        if (d.id === depot.id) {
          this.user.depots.splice(i, 1)
        }
      })
    }
  }

  isUserInGroup(user: User, group: UserGroup) {
    let result = false
    if (user?.groups?.length > 0) {
      user.groups.forEach((g: UserGroup) => {
        if (g.id === group.id) {
          result = true
        }
      })
    }
    return result
  }

  hasUserCheckout(user: User, checkout: Checkout) {
    let result = false
    if (user?.checkouts?.length > 0) {
      user.checkouts.forEach((c: Checkout) => {
        if (c.id === checkout.id) {
          result = true
        }
      })
    }
    return result
  }

  hasUserDepot(user: User, depot: Depot) {
    let result = false
    if (user?.depots?.length > 0) {
      user.depots.forEach((d: Depot) => {
        if (d.id === depot.id) {
          result = true
        }
      })
    }
    return result
  }

  isUserPermitted(user?: User) {
    let result = false
    const u = user ? user : this.user
    u.groups.forEach((g: UserGroup) => {
      g.roles?.forEach((r: string) => {
        if (r === 'ROLE_ADMIN' || r === 'ROLE_SUPER_ADMIN') {
          result = true
        }
      })
    })
    return result
  }

  async setCurrentUser() {
    this.loading = true
    const userProfile = await this.core.services.user.getMyProfile(SchemaUtil.userProfile)
    if (userProfile) {
      this.user = new User(userProfile)
      // await this.core.services.user.setCurrentUser(this.user.email)
      if (this.isUserPermitted()) {
        await this.getData()
      }
    }
    this.translateService.use(this.user.locale)
    this.fillFormValue()
    this.loading = false
  }

  async getData() {
    await this.getUserGroups()
    await this.getCheckouts()
    await this.getDepots()
  }

  rolesVisibilityChange(group) {
    if (this.areGroupRolesVisible(group))
      this.visibleGroupes = this.visibleGroupes.filter(groupName => groupName !== group.name)
    else this.visibleGroupes.push(group.name)
  }

  areGroupRolesVisible(group) {
    return this.visibleGroupes.some(groupName => groupName === group.name)
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.userForm.controls
  }

  generatePassword() {
    const newGeneratedPassword = this.randomPassword()
    this.userForm.controls.plainPassword.setValue(newGeneratedPassword)
  }

  randomPassword() {
    return Math.random().toString(36).slice(2) + Math.random().toString(36).toUpperCase().slice(2)
  }
}
