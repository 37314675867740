<div class="card mb-0 pt-3">
  <div
    class="card-body p-0"
    [ngClass]="{
      h150: (!items && loadingItems) || (items && !items.length)
    }"
  >
    <loading-spinner [isLoading]="loadingItems" [classesString]="'pt-88'"></loading-spinner>
    <div *ngIf="selectedColumns.length <= 1">
      {{ 'list.no-cols' | translate }}
      <btn [label]="'list.default-cols'" (onEvent)="loadDefaultCols()"></btn>
    </div>
    <div class="horizontal-scroll">
      <div class="table-wrap table_responsive" *ngIf="selectedColumns?.length > 1 && items">
        <table class="table m-0">
          <thead>
            <tr>
              <ng-container *ngFor="let column of selectedColumns; trackBy: trackByColumnName">
                <ng-template [ngIf]="column.sortable && column.propType !== 'checkbox'">
                  <th
                    class="text-nowrap {{ column.align ? ' text-' + column.align : '' }} cursor-pointer {{
                      column.cssClass || ''
                    }}"
                    (click)="sortBy(column.sortBy)"
                  >
                    <span>{{ column.name | translate }}</span>
                    <i
                      [ngClass]="
                        sortProp !== column.sortBy
                          ? 'far fa-caret-up ps-1'
                          : sortDir === 'asc'
                            ? 'fas fa-caret-up ps-1'
                            : sortDir === 'desc'
                              ? 'fas fa-caret-down ps-1'
                              : ''
                      "
                    >
                    </i>
                  </th>
                </ng-template>
                <ng-template
                  [ngIf]="
                    !column.sortable &&
                    column.propType !== 'btn' &&
                    column.propType !== 'btn-right' &&
                    column.propType !== 'checkbox' &&
                    column.propType !== 'order-preview'
                  "
                >
                  <th
                    class="text-nowrap {{ column.align ? ' text-' + column.align : '' }} {{ column.cssClass || '' }}"
                    height="50px"
                  >
                    {{ column.name | translate }}
                  </th>
                </ng-template>
                <ng-template
                  [ngIf]="
                    !column.sortable &&
                    (column.propType === 'btn' ||
                      column.propType === 'btn-right' ||
                      column.propType === 'order-preview')
                  "
                >
                  <th class="pe-0 fixed-column-width">&nbsp;</th>
                </ng-template>
                <ng-template [ngIf]="column.propType === 'checkbox'">
                  <th>
                    <input type="checkbox" [ngModel]="isAllSelected" (ngModelChange)="onCheckboxChange(0, $event)" />
                  </th>
                </ng-template>
              </ng-container>
              <dropdown
                *ngIf="isColumnSettingVisible"
                [icon]="'far-cog'"
                [items]="columns | colSettings: selectedColumns"
                [isArrowHidden]="true"
                [autoClose]="true"
                (onResult)="onColumnSettingDropdownClick($event)"
              ></dropdown>
            </tr>
          </thead>
          <tbody *ngIf="items?.length === 0 && !loadingItems">
            <tr>
              <td [colSpan]="selectedColumns.length + 1" class="text-center p-5 border-bottom-0 no-hover">
                <div class="empty-list">
                  <i class="far fa-sad-tear" data-cy="empty"></i>
                  <div>
                    {{ 'no.record.found' | translate }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="items.length">
            <tr *ngFor="let item of items; trackBy: trackByIndex">
              <td
                class="{{ column.align ? ' text-' + column.align : '' }}  {{ column.cssClass || '' }}"
                *ngFor="let column of selectedColumns; trackBy: trackByColumnName"
                [ngClass]="{
                  'p-0':
                    column.propType === 'btn' ||
                    column.propType === 'btn-gen' ||
                    column.propType === 'modal-eet-receipt' ||
                    column.propType === 'modal-address' ||
                    column.propType === 'btn-resend-eet-receipt'
                }"
                [ngStyle]="{ 'word-break': column.propType === 'product-link' ? 'break-all' : null }"
              >
                <!--SIMPLE TYPES-->
                <span
                  *ngIf="column.propType === 'string' && item[column.property]?.length"
                  title="{{ column.title | translate }}"
                  data-cy="first-column"
                >
                  <i
                    *ngIf="column.name === 'color'; else stringValue"
                    class="color-box"
                    [ngStyle]="{ backgroundColor: item[column.property] }"
                  ></i>
                  <ng-template #stringValue>{{
                    (needsTranslationPrefix && column.name === 'type'
                      ? listName + '.' + column.name + '.' + item[column.property]
                      : item[column.property]
                    ) | translate
                  }}</ng-template>
                </span>
                <span *ngIf="column.propType === 'product-link' && item[column.property]?.length">
                  <a [routerLink]="item.product?.id ? [column.route, item.product.id] : null">
                    {{ item[column.property] | translate }}
                  </a>
                </span>
                <span
                  *ngIf="column.propType === 'string' && item[column.property]?.name && !item[column.property].length"
                  title="{{ column.title | translate }}"
                >
                  {{ item[column.property].name | translate }}
                </span>
                <span
                  *ngIf="column.propType === 'link' && item[column.property]?.id"
                  title="{{ column.title | translate }}"
                >
                  <a [routerLink]="[column.route, item[column.property].id]">
                    {{
                      (item[column.property].name?.length > 2
                        ? item[column.property].name
                        : item[column.property].email
                      ) | translate
                    }}
                  </a>
                </span>
                <span *ngIf="column.propType === 'priority'" title="{{ column.title | translate }}">
                  {{ getPriorityName(item[column.property]) | translate }}
                </span>
                <span *ngIf="column.propType === 'integer'" title="{{ column.title | translate }}">
                  {{ item[column.property] }}
                </span>
                <span *ngIf="column.propType === 'number'" title="{{ column.title | translate }}">
                  {{ item[column.property]?.toFixed ? item[column.property].toFixed(2) : item[column.property] }}
                </span>
                <span *ngIf="column.propType === 'percent'" title="{{ column.title | translate }}">
                  {{
                    item[column.property] !== 0 && item[column.property] !== null && item[column.property] !== undefined
                      ? item[column.property]
                      : item[column.property]
                  }}
                </span>
                <span *ngIf="column.propType === 'price'" title="{{ column.title | translate }}">
                  {{ item[column.property] | formatPrice: item.currency }}
                </span>
                <span *ngIf="column.propType === 'price-cents'" title="{{ column.title | translate }}">
                  {{
                    item[column.property]?.toFixed ? (item[column.property] / 100).toFixed(2) : item[column.property]
                  }}&nbsp;{{ item.currency && item.currency.id ? (item.currency.id | translate) : 'Kč' }}
                </span>
                <span *ngIf="column.propType === 'date'" title="{{ column.title | translate }}">
                  {{ item[column.property] | fmtDate: 'dd.MM.yyyy' }}
                </span>
                <span *ngIf="column.propType === 'datetime'" title="{{ column.title | translate }}">
                  {{ item[column.property] | fmtDate: 'dd.MM.yyyy' }}
                  {{ item[column.property] | fmtDate: 'HH:mm' }}
                </span>
                <span *ngIf="column.propType === 'array'" title="{{ column.title | translate }}">
                  <span class="badge bg-light" *ngFor="let subItem of item[column.property]">
                    {{ subItem.name ? subItem.name : subItem ? subItem : ' ' }}
                  </span>
                </span>
                <span *ngIf="column.propType === 'label'" title="{{ column.title | translate }}">
                  <span class="badge bg-light" *ngIf="item[column.property]?.length">
                    {{ item[column.property] }}
                  </span>
                </span>
                <span *ngIf="column.propType === 'boolean'" title="{{ column.title | translate }}">
                  <span class="badge bg-success" *ngIf="item[column.property]">
                    {{ 'yes' | translate }}
                  </span>
                  <span class="badge bg-danger" *ngIf="!item[column.property]" title="{{ column.title | translate }}">
                    {{ 'no' | translate }}
                  </span>
                </span>
                <span *ngIf="column.propType === 'approved'" title="{{ column.title | translate }}">
                  <span class="badge bg-light" *ngIf="!item.approved && !item.rejected">
                    {{ 'waiting' | translate }}
                  </span>
                  <span class="badge bg-success" *ngIf="item.approved">
                    {{ 'inventory.approved' | translate }}
                  </span>
                  <span class="badge bg-danger" *ngIf="item.rejected">
                    {{ 'inventory.rejected' | translate }}
                  </span>
                </span>
                <!--CUSTOM FIELDS-->
                <span *ngIf="column.propType === 'checkbox'">
                  <input
                    type="checkbox"
                    [ngModel]="item.isSelected"
                    (ngModelChange)="onCheckboxChange(item.id, $event)"
                  />
                </span>

                <span *ngIf="column.propType === 'order-preview'">
                  <app-order-preview [isOnOrdersPage]="true" [order]="item"></app-order-preview>
                </span>

                <div
                  *ngIf="column.propType === 'unavailable-number' && item['quantityUnavailable']"
                  title="{{ 'products.unavailable' | translate }}"
                  class="text-center"
                >
                  <span
                    *ngIf="item['quantityUnavailable'] > 0"
                    title="{{ 'quantityUnavailable' | translate }}"
                    class="badge bg-red"
                    style="padding-top: 0.5em"
                  >
                    {{ item['quantityUnavailable'] | number: '1.0-2' | replaceCommaToDot }}
                  </span>
                </div>

                <span *ngIf="column.propType === 'order-preview-move-packs'">
                  <app-order-preview
                    *ngIf="item.order?.id"
                    class="display-flex-gap-03"
                    [order]="item?.order"
                    [isOrderItemsPriceSum]="true"
                  >
                  </app-order-preview>
                </span>
                <div *ngIf="column.propType === 'img'" [photoGalleryGroup]="{ index: 1 }" class="images">
                  <div
                    *ngIf="item.product?.id"
                    [photoGallery]="item.product.mainImage.url"
                    [photoGalleryCaption]="
                      item.product
                        ? item.product.fullName +
                          '<br>' +
                          (item.product.code ? ('product.code' | translate) + item.product.code : '') +
                          '<br>' +
                          (item.product.ean ? ('product.ean' | translate) + item.product.ean : '')
                        : ''
                    "
                  >
                    <div class="images-item">
                      <img
                        class="images-item-image"
                        [src]="getThumbUrl(item.product.mainImage)"
                        [alt]="item.product.fullName"
                      />
                    </div>
                  </div>
                </div>
                <span
                  *ngIf="column.propType === 'customer-name'"
                  title="{{ column.title | translate }}"
                  data-cy="customer-name"
                >
                  <ng-template [ngIf]="item['customer']">
                    <div>
                      {{
                        (item['customer'].firstName ? item['customer'].firstName : '') +
                          ' ' +
                          (item['customer'].lastName ? item['customer'].lastName : '')
                      }}
                    </div>
                    <div>{{ item['customer'].companyName ? item['customer'].companyName : '' }}</div>
                  </ng-template>
                  <ng-template [ngIf]="!item['customer'] && item['invoiceAddress']">
                    <div>
                      {{
                        (item['invoiceAddress'].firstName ? item['invoiceAddress'].firstName : '') +
                          ' ' +
                          (item['invoiceAddress'].lastName ? item['invoiceAddress'].lastName : '')
                      }}
                    </div>
                    <div>{{ item['invoiceAddress'].companyName ? item['invoiceAddress'].companyName : '' }}</div>
                  </ng-template>
                  <ng-template [ngIf]="!item['customer'] && !item['invoiceAddress'] && item['shippingAddress']">
                    <div>
                      {{
                        (item['shippingAddress'].firstName ? item['shippingAddress'].firstName : '') +
                          ' ' +
                          (item['shippingAddress'].lastName ? item['shippingAddress'].lastName : '')
                      }}
                    </div>
                    <div>{{ item['shippingAddress'].companyName ? item['shippingAddress'].companyName : '' }}</div>
                  </ng-template>
                </span>
                <span
                  *ngIf="column.propType === 'checkout-name' && item['checkout']"
                  title="{{ column.title | translate }}"
                >
                  {{ item['checkout'].name }}
                </span>
                <span
                  *ngIf="column.propType === 'carrier-name' && item['carrier']"
                  title="{{ column.title | translate }}"
                >
                  <!--              <span [ngClass]="'carrier-label-' + (item.carrier.name ? item.carrier.name : '')">-->
                  {{ item.carrier.name ? item.carrier.name : '' }}
                  <!--              </span>-->
                </span>
                <span
                  *ngIf="column.propType === 'relation-name' && item[column.property] && item[column.property].name"
                  title="{{ column.title | translate }}"
                  data-cy="relation-name"
                >
                  {{ item[column.property].name }}
                </span>
                <span
                  *ngIf="column.propType === 'item-id' && item[column.property] && item[column.property].id"
                  title="{{ column.title | translate }}"
                >
                  {{ item[column.property].id }}
                </span>
                <span
                  *ngIf="column.propType === 'process-status' && item['processStatus']"
                  title="{{ column.title | translate }}"
                  [ngClass]="'process-status-' + item.processStatus.id"
                >
                  <i
                    [ngClass]="
                      item.processStatus.id === 'recieved'
                        ? 'far fa-cart-arrow-down'
                        : item.processStatus.id === 'picking'
                          ? 'far fa-scanner'
                          : item.processStatus.id === 'packing'
                            ? 'far fa-box-full'
                            : item.processStatus.id === 'packed'
                              ? 'far fa-box-check'
                              : item.processStatus.id === 'dispatched'
                                ? 'far fa-shipping-fast'
                                : item.processStatus.id === 'delivered'
                                  ? 'far fa-check-circle'
                                  : item.processStatus.id === 'returned'
                                    ? 'far fa-shipping-timed'
                                    : item.processStatus.id === 'picking_error'
                                      ? 'far fa-exclamation-triangle'
                                      : item.processStatus.id === 'cancelled'
                                        ? 'far fa-ban'
                                        : ''
                    "
                  >
                  </i>
                  {{ item['processStatus'] ? (item['processStatus'].id | translate) : '' }}
                </span>
                <div *ngIf="column.propType === 'icons'" title="{{ column.title | translate }}" class="d-flex">
                  <div style="vertical-align: middle; margin-right: 3px">
                    <span *ngIf="item?.quantityUnavailable < 1">
                      <i
                        class="fas fa-person-dolly"
                        style="color: #21d59b"
                        [title]="'quantityUnavailable' | translate"
                      ></i>
                    </span>
                    <span *ngIf="item?.quantityUnavailable > 0">
                      <i
                        class="fas fa-person-dolly"
                        style="color: #f0142f"
                        [title]="'quantityUnavailable' | translate"
                      ></i>
                    </span>
                  </div>
                  <div style="vertical-align: middle; margin-right: 3px">
                    <span *ngIf="item?.depots?.length === 1">
                      <i class="fas fa-box" style="color: #21d59b" [title]="'order.on.single.depot' | translate"></i>
                    </span>
                    <span *ngIf="item?.depots?.length > 1">
                      <i class="fas fa-boxes" style="color: #f0142f" [title]="'more.depots' | translate"></i>
                    </span>
                  </div>
                </div>
                <div *ngIf="column.propType === 'order-payment-items'" title="{{ column.title | translate }}">
                  <div *ngIf="item['paymentItems']?.length">
                    <div *ngFor="let pItem of item['paymentItems']">
                      <span style="vertical-align: middle; margin-right: 3px">
                        <span *ngIf="pItem?.isPaid">
                          <i class="fas fa-usd-circle" style="color: #21d59b" [title]="'isPaid' | translate"></i>
                        </span>
                        <span *ngIf="!pItem?.isPaid">
                          <i class="fas fa-usd-circle" style="color: #f0142f" [title]="'isNotPaid' | translate"></i>
                        </span>
                      </span>
                      {{ pItem?.payment?.name ? pItem.payment.name : '' }}
                    </div>
                  </div>
                </div>
                <div *ngIf="column.propType === 'order-packages'" title="{{ column.title | translate }}">
                  <div *ngIf="item['packages']?.length">
                    <div *ngFor="let pack of item['packages']" class="d-flex flex-column flex-wrap">
                      <div>
                        {{ pack.code }}
                      </div>
                      <div class="note">
                        {{ pack.statuses && pack.statuses.length ? pack.statuses[pack.statuses.length - 1].text : '' }}
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  *ngIf="column.propType === 'address-country' || column.propType === 'address-shipping'"
                  title="{{ column.title | translate }}"
                >
                  <span *ngIf="!!item[column.property]">
                    {{ item[column.property].country }}
                  </span>
                </span>

                <span *ngIf="column.propType === 'reservation-number'" class="text-center">
                  <span data-cy="reservation-number">
                    {{ item['reservationNumber'] }}
                  </span>
                </span>

                <span
                  *ngIf="column.propType === 'status'"
                  class="status-div"
                  [ngClass]="'status-' + item.status?.id"
                  [title]="item?.status?.name | translate"
                >
                  {{ item.status?.id | translate }}
                </span>

                <div *ngIf="column.propType === 'stringArray'" class="container-tags">
                  <ng-container *ngFor="let item of item[column.property]">
                    <span
                      class="tag-span"
                      [ngStyle]="{ 'background-color': item.color ? item.color : '#0058FF', color: 'white' }"
                    >
                      {{ item.name }}
                    </span>
                  </ng-container>
                </div>

                <span
                  *ngIf="column.propType === 'order-reservation-actions' && item['status'] === 'reservation'"
                  title="{{ column.title | translate }}"
                >
                  <btn
                    [title]="'delete' | translate"
                    [classes]="['btn-first-red', 'btn-sm', 'float-right']"
                    [icon]="'far-trash-alt'"
                    (onEvent)="onAction('deleteReservation', item)"
                  ></btn>
                  <btn
                    [classes]="['btn-primary', 'btn-sm', 'float-right', 'me-2']"
                    [icon]="'far-shopping-cart'"
                    (onEvent)="onAction('confirmOrder', item)"
                  ></btn>
                </span>
                <span *ngIf="column.propType === 'array-vertical'" class="text-center" data-cy="array-vertical">
                  <div
                    *ngIf="item[column.property]?.length"
                    class="badge bg-blue unavail-badge"
                    style="position: absolute"
                  >
                    <i class="far fa-list"></i>
                    <div class="unavail-list-right">
                      <div *ngFor="let it of item[column.property]">{{ it | translate }}</div>
                    </div>
                  </div>
                  &nbsp;
                </span>
                <span *ngIf="column.propType === 'operation-type'" class="text-center">
                  <i
                    *ngIf="item[column.property].id === 'in'"
                    class="in-badge fas fa-plus-square"
                    title="{{ item[column.property].name | translate }}"
                  ></i>
                  <i
                    *ngIf="item[column.property].id === 'out'"
                    class="out-badge fas fa-minus-square"
                    title="{{ item[column.property].name | translate }}"
                  ></i>
                  <i
                    *ngIf="item[column.property].id === 'transfer'"
                    class="transfer-badge fas fa-exchange-alt"
                    title="{{ item[column.property].name | translate }}"
                  ></i>
                  <i
                    *ngIf="item[column.property].id === 'reservation'"
                    class="reservation-badge fas fa-clock"
                    title="{{ item[column.property].name | translate }}"
                  ></i>
                  <!--                {{item[column.property].name | trans}}-->
                </span>
                <span *ngIf="column.propType === 'depot-from-to'">
                  <span *ngIf="item.depotFrom?.id">
                    {{ (item.depotFrom.name?.length > 2 ? item.depotFrom.name : '') | translate }}
                  </span>
                  <span *ngIf="item.depotFrom?.id && item.depotTo?.id">/</span>
                  <span *ngIf="item.depotTo?.id">
                    {{ (item.depotTo.name?.length > 2 ? item.depotTo.name : '') | translate }}
                  </span>
                </span>
                <span *ngIf="column.propType === 'types-with-values'">
                  <span *ngIf="item.discountType">
                    {{ item.discountType ?? '' | translate }}
                  </span>
                  <span *ngIf="item.discountPercent">
                    {{ item.discountPercent + '%' }}
                  </span>
                  <span *ngIf="item.discountValue">
                    {{ item.discountValue | formatPrice }}
                  </span>
                </span>
                <span *ngIf="column.propType === 'used-maxUse'">
                  <span>
                    {{ item.used ? item.used : 0 }}
                  </span>
                  <span>/</span>
                  <span *ngIf="item.maxUse">
                    {{ item.maxUse }}
                  </span>
                </span>
                <span *ngIf="column.propType === 'batch-and-expiration' && item['move']">
                  <span *ngIf="item['move'].expirationDate"
                    >customer
                    {{ item['move'].expirationDate | fmtDate: 'dd.MM.yyyy' }}
                  </span>
                  <span *ngIf="item['move'].expirationDate && item['move'].batch">/</span>
                  <span *ngIf="item['move'].batch">
                    {{ item['move'].batch }}
                  </span>
                </span>
                <span *ngIf="column.propType === 'fullName'">
                  <span *ngIf="item.firstName">
                    {{ item.firstName }}
                  </span>
                  <span *ngIf="item.lastName">
                    {{ item.lastName }}
                  </span>
                </span>
                <span *ngIf="column.propType === 'notes'" class="d-flex flex-column">
                  <span *ngIf="item.note?.length">
                    <!-- HTML to write -->
                    <span data-toggle="tooltip" title="{{ 'note' | translate }}:  {{ item.note }}">{{
                      item.note
                    }}</span>
                    <!-- Generated markup by the plugin -->
                    <div class="tooltip bs-tooltip-top" role="tooltip">
                      <div class="arrow"></div>
                    </div>
                  </span>
                  <span *ngIf="item.privateNote?.length">
                    <!-- HTML to write -->
                    <span data-toggle="tooltip" title="{{ 'note.private' | translate }}:  {{ item.privateNote }}">{{
                      item.privateNote
                    }}</span>
                    <!-- Generated markup by the plugin -->
                    <div class="tooltip bs-tooltip-top" role="tooltip">
                      <div class="arrow"></div>
                    </div>
                  </span>
                </span>
                <!--PIPES-->
                <span *ngIf="column.propType === 'pipe-order-items-price'" title="{{ column.title | translate }}">
                  {{ item | orderItemsPriceSum }}&nbsp;{{
                    item.currency && item.currency.id ? (item.currency.id | translate) : 'Kč'
                  }}
                </span>
                <!--BUTTONS-->
                <span *ngIf="column.propType === 'btn' && !column.action && column.route">
                  <a
                    [routerLink]="[column.route, item.id]"
                    class="btn-{{ column.btnStyle }} float-right ms-2"
                    [title]="column.name | translate"
                    data-cy="download-report"
                  >
                    <i class="far fa-{{ column.icon ? column.icon : 'file-o' }} me-2"></i>
                    {{ column.name | translate }}
                  </a>
                </span>
                <span *ngIf="column.propType === 'btn' && column.action && !column.route">
                  <!--Don't know why the linter wants to make a-tag "focusable", it always is-->
                  <a
                    tabindex="0"
                    class="btn-{{ column.btnStyle }} float-right ms-2"
                    [title]="column.name | translate"
                    (click)="onAction(column.action, item)"
                    (keydown)="onAction(column.action, item)"
                    data-cy="dowload-report"
                  >
                    <i class="far fa-{{ column.icon ? column.icon : 'file-o' }} me-2"></i>
                    {{ column.name | translate }}
                  </a>
                </span>
                <span *ngIf="column.propType === 'btn-right' && !column.action && column.route">
                  <a
                    [routerLink]="[column.route, item.id]"
                    [title]="column.name | translate"
                    [target]="column.target"
                    class="btn-{{ column.btnStyle }} float-right ms-2"
                    data-cy="open-row"
                  >
                    {{ column.name | translate }}
                    <i class="far fa-{{ column.icon ? column.icon : 'file-o' }} ms-2"></i>
                  </a>
                </span>

                <span *ngIf="item.product?.id && column.propType === 'btn-orders'">
                  <a
                    [routerLink]="[column.route]"
                    [queryParams]="{
                      item: item?.code || item?.ean,
                      stringifiedFilters: stringifiedFilters
                    }"
                    [title]="column.name | translate"
                    class="btn-{{ column.btnStyle }} float-right ms-2"
                    data-cy="open-row"
                  >
                    <i class="far fa-shopping-cart"></i>
                  </a>
                </span>

                <span *ngIf="column.propType === 'btn-right' && column.action && !column.route">
                  <a
                    tabindex="0"
                    class="btn-{{ column.btnStyle }} float-right ms-2"
                    (click)="onAction(column.action, item)"
                    (keydown)="onAction(column.action, item)"
                    [title]="column.name | translate"
                  >
                    {{ column.name | translate }}
                    <i class="far fa-{{ column.icon ? column.icon : 'chevron-right' }} ms-2"></i>
                  </a>
                </span>
                <span *ngIf="column.propType === 'btn-gen' && column.action && !column.route">
                  <a
                    class="btn-second btn-sm float-right"
                    *ngIf="!item.generated"
                    [title]="'reports.generating' | translate"
                    disabled
                  >
                    <i class="fa fa-spinner fa-spin me-2"></i>
                    {{ 'reports.generating' | translate }}
                  </a>
                  <a
                    class="btn-{{ column.btnStyle }} float-right ms-2"
                    tabindex="0"
                    (click)="onAction(column.action, item)"
                    (keydown)="onAction(column.action, item)"
                    [title]="column.name | translate"
                    *ngIf="item.generated"
                    data-cy="'btn-gen'"
                  >
                    <i
                      class="far me-2"
                      [ngClass]="{
                        'fa-eye': !item.format,
                        'fa-file-pdf': item.format === 'pdf',
                        'fa-file-excel': item.format === 'excel'
                      }"
                    ></i>
                    {{ column.name | translate }}
                  </a>
                </span>
                <span *ngIf="column.propType === 'btn-resend-eet-receipt'">
                  <button
                    class="btn btn-warning btn-sm float-right"
                    (click)="onAction('resendReceipt', item)"
                    *ngIf="!item.fik"
                  >
                    <i class="far fa-upload me-2"></i>
                    {{ 'receipt.resend' | translate }}
                  </button>
                </span>
                <!--MODALS-->
                <span *ngIf="column.propType === 'modal-address'">
                  <modal-address
                    [address]="item"
                    [customer]="item['customer']"
                    [isListingBtn]="true"
                    (result)="onAction('showNotificationFromModalAddress', $event)"
                  ></modal-address>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <app-pagination-btn
          *ngIf="!!((!this.currentPage || this.currentPage <= this.endPage) && this.totalItems > this.items.length)"
          (switchPage)="paginate($event)"
          [currentPage]="currentPage"
          [endPage]="endPage"
          [sortDir]="sortDir"
          [sortProp]="sortProp"
          [loadingPage]="loadingItems || loadingNextItems"
        >
        </app-pagination-btn>
      </div>
    </div>
  </div>
</div>
