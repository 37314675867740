import { Component, Input, OnInit } from '@angular/core'
import { CoreService } from '../../../services'
import { Events } from 'depoto-core/src/utils/EventsUtil'

@Component({
  selector: 'app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss'],
})
export class AppLoaderComponent implements OnInit {
  @Input() label: string
  loaderInterval: number | any
  loaderIntervalMs = 500
  loginThresholdTryMax = 5
  loginThresholdTryCount = 0
  isAppReady = false
  constructor(private core: CoreService) {}

  ngOnInit() {
    this.loaderInterval = setInterval(() => this.checkProgress(), this.loaderIntervalMs)
    Events.listen('app:user:loaded', () => setTimeout(() => (this.isAppReady = true), 250))
  }

  checkProgress() {
    this.loginThresholdTryCount++
    if (!this.core.services.oauth.isAuthenticated() && this.loginThresholdTryCount > this.loginThresholdTryMax) {
      clearInterval(this.loaderInterval)
      this.loginThresholdTryCount = 1
    } else if (this.core.services.oauth.isAuthenticated() && this.isAppReady) {
      clearInterval(this.loaderInterval)
      this.loginThresholdTryCount = 1
      this.isAppReady = false
    }
  }
}
