import { Component, ChangeDetectorRef, OnInit, ViewChild } from '@angular/core'
import { ListingItem } from 'depoto-core/src/models'
import { BaseListing } from '../_base-listing/base-listing'
import { CoreService } from '../../services'
import { Depot, User, Checkout, ProductMovePack, Tag } from 'depoto-core/src/entities'
import { ExportsComponent } from '../exports/exports.component'
import { productMovePacksColumns } from '../../utils/definitions.util'
import { SchemaUtil, isFormInvalid } from '../../utils'

const DEFAULT_FILTERS = {
  type: 'all',
  depotFrom: null,
  depotTo: null,
  checkout: null,
  tags: [],
  withoutCheckout: false,
  fulltext: '',
  orderFulltext: '',
  externalReferenceFulltext: '',
  dateFrom: '',
  dateTo: '',
  withOrder: 'all',
  createdBy: '',
  productFulltext: '',
  number: '',
}

@Component({
  selector: 'product-move-packs-page',
  templateUrl: 'product-move-packs.component.html',
  styleUrls: ['product-move-packs.component.scss'],
})
export class ProductMovePacksComponent extends BaseListing implements OnInit {
  @ViewChild('exportsModal') exportsModal: ExportsComponent
  productMovePacks: ProductMovePack[] = []
  listingColumns: ListingItem[] = productMovePacksColumns
  operationTypes: string[] = ['all', 'in', 'out', 'transfer', 'reservation'] //TODO: should we also translate or not?
  hasOrderTypes: string[] = ['all', 'yes', 'no']
  filters = structuredClone(DEFAULT_FILTERS)
  depots: Depot[] = []
  tags: Tag[] = []
  users: User[] = []
  checkouts: Checkout[] = []
  sortProp = 'created'
  sortDir = 'desc'
  totalItems = 0
  currentPage: number
  endPage: number
  loading = true
  filtersExpanded = false
  storageKey: { type: string; key: string }

  constructor(
    protected core: CoreService,
    protected changeDetector: ChangeDetectorRef
  ) {
    if (window.location.pathname === '/product-move-pack') {
      window.history.replaceState(null, document.title, '/product-move-packs')
    }
    super(core)
    this.getStorageKey()
  }

  async ngOnInit() {
    this.filters = this.loadViewSettings('productMoves') || structuredClone(DEFAULT_FILTERS)
    this.filters.createdBy = this.filters.createdBy !== '0' ? this.filters.createdBy : ''
    await this.refreshFiltersData()
    this.loadSorting('productMoves')
    this.filter(1)
  }

  clearFilters(type?: string): void {
    switch (type) {
      case 'depots':
        this.filters.depotFrom = null
        this.filters.depotTo = null
        break
      default:
        this.filters = structuredClone(DEFAULT_FILTERS)
    }
    if (type !== 'depots') {
      this.filter()
    }
  }

  async refreshFiltersData(): Promise<any> {
    this.loading = true
    try {
      this.checkouts = this.core.lists.checkouts
      this.depots = this.core.lists.depots
      this.tags = this.core.lists.tags.filter(t => t.type === 'product_move_pack')
      this.users = await this.core.services.user
        .getList({}, { id: null, name: null, email: null })
        .then(result => result.items.map(user => new User(user)))
      this.depots.unshift(new Depot({ id: 'none', name: 'all' }))
      this.checkouts.unshift(new Checkout({ id: 'withoutCheckout', name: 'withoutCheckout' }))
      this.checkouts.unshift(new Checkout({ id: 0, name: 'all' }))
      this.users.unshift(new User({ id: 0, name: 'all', email: 'all' }))
      this.loading = false
    } catch (error) {
      console.warn('depots::refreshData error', error)
    } finally {
      this.loading = false
    }
  }

  filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false): void {
    if (isFormInvalid()) return
    const options: any = {}
    this.currentPage = pageNo
    if (this.filters.type !== 'all' && this.filters.type) options.type = this.filters.type
    if (this.filters.depotFrom > 0) options.depotFrom = this.filters.depotFrom
    if (this.filters.depotTo > 0) options.depotTo = this.filters.depotTo
    if (this.filters.tags?.length > 0) options.tags = this.filters.tags
    if (this.filters.fulltext && this.filters.fulltext.length > 0) options.fulltext = this.filters.fulltext
    if (this.filters.type !== 'transfer') {
      if (this.filters.checkout === 'withoutCheckout') {
        options.withoutCheckout = true
      } else if (
        this.filters.checkout !== 'all' &&
        this.filters.checkout !== 'withoutCheckout' &&
        !isNaN(+this.filters.checkout) &&
        +this.filters.checkout > 0
      ) {
        options.checkouts = [this.filters.checkout]
      }
    }
    if (this.filters.number?.length > 0) options.number = this.filters.number
    if (this.filters.orderFulltext?.length > 0) options.orderFulltext = this.filters.orderFulltext
    if (this.filters.externalReferenceFulltext?.length > 0)
      options.orderFulltext = this.filters.externalReferenceFulltext
    if (this.filters.withOrder && this.filters.withOrder !== 'all') options.withOrder = this.filters.withOrder === 'yes'
    if (this.filters.productFulltext?.length > 0) options.productFulltext = this.filters.productFulltext
    if (this.filters.createdBy) options.createdBy = this.filters.createdBy
    if (this.filters.dateFrom || this.filters.dateTo) {
      options.created = {
        ...(this.filters.dateFrom?.length && {
          left_date: this.filters.dateFrom,
        }),
        ...(this.filters.dateTo?.length && {
          right_date: this.filters.dateTo + 'T23:59:59',
        }),
      }
    }
    // todo nebepeczne checkovat jestli nejsou uz filters vybrane, kvuli tomu, ze se loaduji ze storage ?
    if (
      !this.core.userHasAllCheckouts &&
      this.core.services.user.user?.checkouts?.length &&
      !options.checkouts?.length
    ) {
      options.checkouts = this.checkouts.map(ch => ch.id)
    }
    if (!this.core.userHasAllDepots && this.core.services.user.user?.depots?.length && !options.depots?.length) {
      options.depots = this.depots.map(d => d.id)
    }

    this.loading = true
    if (!concat) this.reloading = true
    this.core.services.productMovePack
      .getList({ page: pageNo, sort: sortProp, direction: sortDir, filters: options }, SchemaUtil.productMovePackList)
      .then(result => {
        if (!result?.items) return
        if (!concat) {
          this.productMovePacks = result.items
        } else {
          result.items.forEach(movePack => {
            this.productMovePacks.push(new ProductMovePack(movePack))
          })
        }
        this.totalItems = result.paginator ? result.paginator.totalCount : 0
        this.endPage = result.paginator ? result.paginator.endPage : 0
        this.currentPage = pageNo + 1
        this.saveViewSettings('productMoves', this.filters)
      })
      .finally(() => {
        this.loading = false
        this.reloading = false
      })
  }

  get queryFilters() {
    const f = {
      type: this.filters.type !== 'all' && this.filters.type?.length ? this.filters.type : undefined,
      depotFrom: this.filters.depotFrom?.length ? this.filters.depotFrom : null,
      depotTo: this.filters.depotTo?.length ? this.filters.depotTo : null,
      checkout: this.filters.checkout ? this.filters.checkout : null,
      withoutCheckout: this.filters.checkout === 'withoutCheckout',
      fulltext: this.filters.fulltext?.length ? this.filters.fulltext : '',
      orderFulltext: this.filters.orderFulltext?.length ? this.filters.orderFulltext : '',
      dateFrom: this.filters.dateFrom?.length ? this.filters.dateFrom : '',
      dateTo: this.filters.dateTo?.length ? this.filters.dateTo : '',
      withOrder: this.filters.withOrder === 'yes' ? true : this.filters.withOrder === 'no' ? false : undefined,
      createdBy: this.filters.createdBy || '',
      productFulltext: this.filters.productFulltext?.length ? this.filters.productFulltext : '',
      operationNumber: this.filters.number ? this.filters.number : '',
      tags: this.filters.tags || [],
    }
    Object.keys(f).forEach(k => {
      if (f[k] === undefined) {
        delete f[k]
      }
    })
    return f
  }

  downloadReport(report: any) {
    this.core.services.download.downloadDocumentAndShowInNewWindow(report.url)
  }
}
