import { Component, OnInit, ViewChild } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { CoreService } from '../../services'
import { Customer, PriceLevel, Tag, User } from 'depoto-core/src/entities'
import { ExportsComponent } from '../exports/exports.component'
import { customersColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  fulltext: '',
  priceLevel: null,
  tags: [],
  salesmen: null, // obchodnici (User)
}

@Component({
  selector: 'app-customers',
  templateUrl: 'customers.component.html',
})
export class CustomersComponent extends BaseListing implements OnInit {
  @ViewChild('exportsModal') exportsModal: ExportsComponent
  listingColumns: ListingItem[] = customersColumns
  sortProp = 'lastName'
  sortDir = 'asc'
  customers: Customer[] = []
  tags: Tag[] = []
  salesmen: User[] = []
  priceLevels: PriceLevel[] = []
  totalItems = 0
  currentPage: number
  endPage: number
  filters = structuredClone(DEFAULT_FILTERS)
  loading = true
  storageKey: { type: string; key: string }

  constructor(protected core: CoreService) {
    super(core)
    this.getStorageKey()
  }

  ngOnInit() {
    this.loadSorting('customers')
    this.init()
  }

  async init() {
    await this.loadFilterOptions()
    this.filters = this.loadViewSettings('customers') || structuredClone(DEFAULT_FILTERS)
    await this.filter()
  }

  clearFilters(): void {
    this.filters = structuredClone(DEFAULT_FILTERS)
    this.filter(1)
  }

  async loadFilterOptions() {
    this.tags = this.core.lists.tags.filter(t => t.type === 'customer')
    if (this.core.services.user.hasRole('ROLE_SALESMAN')) {
      this.salesmen.push(this.core.services.user.user)
    } else {
      this.salesmen = await this.core.services.user.getList({}, { id: null, name: null }).then(result => result?.items)
      this.salesmen.unshift(new User({ id: 0, name: 'all' }))
    }
    this.priceLevels = await this.core.services.priceLevel
      .getList(
        {
          page: 1,
          sort: 'id',
          direction: 'asc',
        },
        {
          id: null,
          name: null,
        }
      )
      .then(res => res.items)
    this.priceLevels.unshift(new PriceLevel({ id: 'none', name: 'all' }))
  }

  get queryFilters() {
    const f: any = {
      fulltext: this.filters.fulltext?.length ? this.filters.fulltext : undefined,
      tags: this.filters.tags?.length ? this.filters.tags : undefined,
      user: +this.filters.salesmen > 0 ? +this.filters.salesmen : undefined,
      priceLevel: this.filters.priceLevel > 0 ? this.filters.priceLevel : undefined,
    }
    Object.keys(f).forEach(k => {
      if (f[k] === undefined) {
        delete f[k]
      }
    })
    return f
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.currentPage = pageNo
    if (!concat) {
      this.reloading = true
    }
    try {
      const result = await this.core.services.customer.getList(
        { page: pageNo, sort: sortProp, direction: sortDir, filters: this.queryFilters },
        {
          id: null,
          firstName: null,
          lastName: null,
          companyName: null,
          name: null,
          email: null,
          phone: null,
          tags: {
            id: null,
            name: null,
          },
          users: {
            id: null,
            name: null,
          },
          priceLevel: {
            id: null,
            name: null,
          },
        }
      )
      if (!result?.items) return
      if (!concat) {
        this.customers = []
      }
      result.items.forEach(customer => this.customers.push(new Customer(customer)))
      this.totalItems = result.paginator ? result.paginator.totalCount : 0
      this.endPage = result.paginator ? result.paginator.endPage : 0
      this.currentPage++
      this.saveViewSettings('customers', this.filters)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }
}
