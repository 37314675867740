<modal-container #modal [headerTitle]="'password.change'">
  <ng-container class="modal-button-show">
    <btn
      (onEvent)="showChildModal()"
      [classes]="['btn-first-red', 'float-right', 'me-2']"
      [icon]="'far-lock'"
      [label]="'password.change'"
      [title]="'password.change' | translate"
      [dataCyAttribute]="'password-change'"
    ></btn>
  </ng-container>
  <ng-container class="modal-body-content">
    <div class="card">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
      <btn
        class="align-self-end"
        (onEvent)="generatePassword()"
        [classes]="['btn-first']"
        [label]="'user.password.generate' | translate"
        [dataCyAttribute]="'modal-customer-search-open'"
      >
      </btn>
      <form-row-input
        [icon]="'far-lock'"
        [label]="'password.new'"
        [inputType]="'text'"
        [(model)]="plainPass"
        data-cy="new-password"
      ></form-row-input>
      <form-row-input
        [label]="'password.repeat'"
        [icon]="'far-lock'"
        [inputType]="'text'"
        [(model)]="plainPass2"
        data-cy="repeat-password"
      ></form-row-input>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content">
    <btn
      [label]="'password.change.btn'"
      [title]="'password.change.btn' | translate"
      (onEvent)="changePass()"
      [classes]="['btn', 'btn-first']"
      [icon]="'far-unlock'"
      data-cy="submit-password-change"
    ></btn>
  </ng-container>
</modal-container>
