<div class="card">
  <div class="row">
    <div class="col-12">
      <div class="card-title">
        {{ 'product.image.gallery' | translate }}
      </div>
    </div>
  </div>
  <div class="row">
    <div
      class="img-card"
      [photoGalleryGroup]="{ index: 1 }"
      *ngFor="let img of productDetailService.product.images"
      [photoGallery]="img.url"
      [photoGalleryCaption]="
        img.product
          ? img.product.name +
            '<br>' +
            (img.product.code ? ('product-images.code' | translate) + img.product.code : '') +
            '<br>' +
            (img.product.ean ? ('product-images.ean' | translate) + img.product.ean : '')
          : ''
      "
    >
      <div class="img-container">
        <div>
          <img class="images-item-image" [src]="getThumbUrl(img)" [alt]="img.text" />
        </div>
        <div class="images-item-name mt-3">
          <a href="{{ img.url }}" target="_blank" title="{{ 'open' | translate }}">{{ img.text }}</a>
        </div>
      </div>
      <div>
        <btn
          [title]="'delete' | translate"
          [icon]="'far-trash-alt'"
          [classes]="['btn-trash', 'btn-sm', 'float-right']"
          (click)="removeImage(img)"
          [dataCyAttribute]="'product-image-delete'"
        ></btn>
      </div>
    </div>
    <div class="new-img-dropzone ms-2 mt-2" ngx-dropzone (change)="onSelect($event)" [accept]="acceptedFileTypes">
      <div class="d-flex flex-column">
        <ngx-dropzone-label *ngIf="!files || (files && !files.length)"
          ><i class="far fa-file-import"></i
        ></ngx-dropzone-label>
        <ngx-dropzone-label *ngIf="!files || (files && !files.length)">{{
          'upload.images' | translate
        }}</ngx-dropzone-label>
      </div>
      <div *ngIf="files && files.length">
        <div>
          <div>{{ progressFiles }}/{{ files.length }}</div>
          <div>
            <i>{{ progress }}%</i>
          </div>
        </div>
        <progressbar [max]="100" [value]="progress" type="success" [striped]="false" [animate]="true"></progressbar>
      </div>
    </div>
  </div>
</div>
