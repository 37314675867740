import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'strToDate' })
export class StrToDatePipe implements PipeTransform {
  transform(value: string, args?: string[]): Date {
    let result: Date = null
    try {
      if (!value) {
        result = new Date()
      } else {
        result = new Date(value)
        if (!this.isValidDate(result)) {
          result = new Date()
        }
      }
    } catch (e) {
      result = new Date()
    }
    return result
  }

  isValidDate(date) {
    return date instanceof Date && !isNaN(date.getTime())
  }
}
