import { Component } from '@angular/core'
import { Category } from 'depoto-core/src/entities'
import { CategoryTreeType } from '../../../models'
import { CoreService } from '../../../services'
import { SchemaUtil } from '../../../utils'
import { ProductDetailService } from '../../../product-detail.service'

@Component({
  selector: 'product-categories',
  templateUrl: './product-categories.component.html',
})
export class ProductCategoriesComponent {
  public categoryTreeTypeEnum = CategoryTreeType
  categories: Category[] = []
  loading = true

  constructor(
    private core: CoreService,
    public productDetailService: ProductDetailService
  ) {
    this.getCategories()
  }

  async getCategories() {
    this.categories = await this.core.services.category
      .getList({ direction: 'ASC', filters: { hasParent: false } }, SchemaUtil.category)
      .then(result => {
        this.loading = false
        return result.items
      })
  }
}
