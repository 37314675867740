<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="voucherForm" (ngSubmit)="isCreating ? createVoucher() : updateVoucher()">
    <page-header
      *ngIf="isCreating || (voucher && voucher.name)"
      [icon]="'far-money-check'"
      [title]="isCreating ? 'coupon.new' : ''"
      [appendNoTranslationTitlePart]="isCreating ? '' : voucher.name"
    >
      <ng-container class="header-button">
        <btn
          [icon]="'far-check'"
          [label]="'save'"
          [title]="'save' | translate"
          [classes]="['btn-first', 'float-right']"
          [btnType]="'submit'"
          [isLoading]="loading"
          [btnDisabled]="loading"
          [spinnerClassesString]="'spinner-white'"
          [dataCyAttribute]="'added-voucher'"
        ></btn>
        <btn
          *ngIf="!isCreating"
          [icon]="'far-trash-alt'"
          [label]="'delete'"
          [title]="'delete' | translate"
          [classes]="['btn-first-red', 'float-right', 'me-2']"
          (onEvent)="deleteVoucher()"
          [dataCyAttribute]="'delete-voucher'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <section class="container-fluid ps-0 pe-0" *ngIf="isCreating || (voucher && voucher.name)">
      <div class="card">
        <ng-container>
          <div class="row">
            <div class="col-12">
              <form-row-input
                [label]="'coupon.name'"
                [icon]="'far-signature'"
                [formGroup]="voucherForm"
                formControlName="name"
                [required]="voucherForm.get('name').errors !== null && voucherForm.get('name').errors.required"
                [requiredCssClass]="submitted && formControls.name.errors"
                [dataCyAttribute]="'voucher-name'"
              >
              </form-row-input>
            </div>
            <div class="col-12">
              <form-row-input
                [label]="'coupon.code'"
                [icon]="'far-id-badge'"
                [formGroup]="voucherForm"
                formControlName="code"
                [required]="voucherForm.get('code').errors !== null && voucherForm.get('code').errors.required"
                [requiredCssClass]="submitted && formControls.code.errors"
                [dataCyAttribute]="'voucher-code'"
              >
              </form-row-input>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <form-row-select
                [label]="'voucher.discountType'"
                [icon]="'far-money-check-alt'"
                [formGroup]="voucherForm"
                formControlName="discountType"
                [dataCyAttribute]="'voucher-discount-select'"
                [required]="
                  voucherForm.get('discountType').errors !== null && voucherForm.get('discountType').errors.required
                "
                [requiredCssClass]="submitted && formControls.discountType.errors"
                [options]="discountTypeOptions"
              >
              </form-row-select>
            </div>
            <div class="col-4 is-invalid" *ngIf="voucherForm.value.discountType === 'percentage'">
              <form-row-input
                class="ng-touched is-invalid ng-valid ng-dirty is_invalid"
                [inputType]="'number'"
                [icon]="'far-percent'"
                [label]="'voucher.discountPercent'"
                [formGroup]="voucherForm"
                [appendUnitPercent]="true"
                [minimumVal]="'0'"
                [maximumVal]="'100'"
                formControlName="discountPercent"
                [dataCyAttribute]="'percentage'"
              >
              </form-row-input>
              <div *ngIf="isDiscountPercentInvalid" class="invalid-feedback">
                {{ 'voucher.discountPercent.invalid' | translate }}
              </div>
            </div>
            <div class="col-4" *ngIf="voucherForm.value.discountType === 'value'">
              <form-row-input
                [inputType]="'number'"
                [icon]="'far-dollar-sign'"
                [label]="'voucher.discountValue'"
                [formGroup]="voucherForm"
                [appendUnitCurrency]="true"
                formControlName="discountValue"
                [dataCyAttribute]="'cash'"
              >
              </form-row-input>
            </div>
            <div class="col-4 d-flex justify-content-center align-items-center mt-3">
              <form-row-checkbox
                [label]="'coupon.isPayment'"
                [inputDisabled]="voucherForm.value.discountType === 'percentage'"
                [formGroup]="voucherForm"
                formControlName="isPayment"
                [dataCySelector]="'is-payment'"
              >
              </form-row-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <form-row-input
                [inputType]="'datetime-local'"
                [icon]="'far-calendar-edit'"
                [formGroup]="voucherForm"
                formControlName="validFrom"
                [label]="'voucher.validFrom'"
                [required]="
                  voucherForm.get('validFrom').errors !== null && voucherForm.get('validFrom').errors.required
                "
                [requiredCssClass]="submitted && formControls.validFrom.errors"
                [dataCyAttribute]="'valid-from'"
              >
              </form-row-input>
            </div>
            <div class="col-6">
              <form-row-input
                [inputType]="'datetime-local'"
                [icon]="'far-calendar-edit'"
                [formGroup]="voucherForm"
                formControlName="validTo"
                [required]="voucherForm.get('validTo').errors !== null && voucherForm.get('validTo').errors.required"
                [requiredCssClass]="submitted && formControls.validTo.errors"
                [label]="'voucher.validTo'"
                [dataCyAttribute]="'valid-to'"
              >
              </form-row-input>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <form-row-input
                [inputType]="'number'"
                [icon]="'far-list-alt'"
                [formGroup]="voucherForm"
                formControlName="maxUse"
                [required]="voucherForm.get('maxUse').errors !== null && voucherForm.get('maxUse').errors.required"
                [requiredCssClass]="submitted && formControls.maxUse.errors"
                [label]="'voucher.maxUse'"
                [dataCyAttribute]="'max-usage'"
              >
              </form-row-input>
            </div>
            <div class="col-3 d-flex justify-content-center align-items-center mt-3">
              <form-row-checkbox
                [label]="'enabled'"
                [formGroup]="voucherForm"
                formControlName="enabled"
                [dataCySelector]="'is-active'"
              >
              </form-row-checkbox>
            </div>
          </div>
        </ng-container>
      </div>
    </section>
  </form>
</div>
