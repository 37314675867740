<div class="app-container">
  <form
    [formGroup]="priceLevelForm"
    (ngSubmit)="isCreating ? createPriceLevel() : updatePriceLevel()"
    *ngIf="priceLevel"
  >
    <page-header
      *ngIf="isCreating || (priceLevel && priceLevel.name)"
      [icon]="'fa-hands-usd'"
      [title]="isCreating ? 'priceLevel.new' : 'priceLevel.title'"
      [appendNoTranslationTitlePart]="isCreating ? '' : priceLevel.name"
    >
      <ng-container class="header-button">
        <btn
          [icon]="'far-check'"
          [btnType]="'submit'"
          [label]="isCreating ? 'priceLevel.create' : 'save'"
          [title]="isCreating ? ('priceLevel.create' | translate) : ('save' | translate)"
          [classes]="['btn-first', 'float-right', isCreating ? '' : 'me-2']"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-white'"
          [dataCyAttribute]="'added-price-group'"
        ></btn>
        <btn
          *ngIf="!isCreating"
          [icon]="'far-trash-alt'"
          [label]="'priceLevel.delete'"
          [title]="'priceLevel.delete' | translate"
          [classes]="['btn-first-red', 'float-right', 'me-2']"
          [btnType]="'button'"
          (click)="deletePriceLevel()"
          [dataCyAttribute]="'delete-price-group'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <div class="card">
      <section class="content">
        <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
        <ng-container *ngIf="isCreating || (priceLevel && priceLevel.name)">
          <form-row-input
            [formGroup]="priceLevelForm"
            formControlName="name"
            [icon]="'far-signature'"
            [label]="'priceLevel.name'"
            [dataCyAttribute]="'price-group-name'"
          ></form-row-input>
          <div class="flex-gap-10 align-items-center">
            <form-row-checkbox
              [formGroup]="priceLevelForm"
              formControlName="isPercentage"
              [label]="'priceLevel.isPercentage'"
              [cursorPointer]="true"
              [dataCySelector]="'price-group-is-percentage'"
            ></form-row-checkbox>
            <div class="col-2">
              <form-row-input
                *ngIf="priceLevelForm.value.isPercentage"
                [formGroup]="priceLevelForm"
                formControlName="percent"
                [inputType]="'number'"
                [label]="'priceLevel.percent'"
                [icon]="'far-percent'"
                [appendUnitPercent]="true"
                [dataCyAttribute]="'price-group-percent'"
              ></form-row-input>
            </div>
          </div>
          <form-row-checkbox
            [formGroup]="priceLevelForm"
            formControlName="withVat"
            [label]="'priceLevel.withVat'"
            [cursorPointer]="true"
            [dataCySelector]="'price-group-is-vat'"
          >
          </form-row-checkbox>
          <div class="col-3 ps-0">
            <form-row-select
              [formGroup]="priceLevelForm"
              formControlName="currency"
              [label]="'priceLevel.currency'"
              [icon]="'far-coins'"
              [options]="currencyOptions"
              [dataCyAttribute]="'price-group-currency'"
            ></form-row-select>
          </div>
        </ng-container>
      </section>
    </div>
  </form>
</div>
