import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Currency, Order, Tag } from 'depoto-core/src/entities'
import { OrderDetailService } from '../../../services/order-detail.service'

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['order-info.component.scss'],
})
export class OrderInfoComponent {
  @Input() canCreateOrEdit = false
  @Input() currencies: Currency[] = []
  @Input() order: Order
  // @Input() tags: Tag[] = [] // todo
  @Output() orderChange: EventEmitter<any> = new EventEmitter()
  @Output() recalculatePricesByPriceLevel: EventEmitter<void> = new EventEmitter()

  constructor(private orderDetailService: OrderDetailService) {}
  public selectedCurrency(value: Currency) {
    this.orderDetailService.newCurrency = value
  }

  public onRecalculatedPricesByPriceLevel() {
    console.log('onRecalculatedPricesByPriceLevel()')
    this.recalculatePricesByPriceLevel.emit()
  }

  public selectedExternalReference(value: string) {
    this.order.externalReference = value
    this.orderChange.emit(this.order)
  }

  public selectedDateCreated(value) {
    this.order.dateCreated = value
    this.orderChange.emit(this.order)
  }

  public selectedDateTax(value) {
    this.order.dateTax = value
    this.orderChange.emit(this.order)
  }

  public selectedDateBill(value) {
    this.order.dateBill = value
    this.orderChange.emit(this.order)
  }

  public selectedDateDue(value) {
    this.order.dateDue = value
    this.orderChange.emit(this.order)
  }
}
