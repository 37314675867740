import { Component, Input } from '@angular/core'
import { Order } from 'depoto-core/src/entities'

@Component({
  selector: 'app-order-bill-vat',
  templateUrl: './order-bill-vat.component.html',
  styleUrls: ['order-bill-vat.component.scss'],
})
export class OrderBillVatComponent {
  @Input() order: Order
}
