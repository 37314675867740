<aside class="main-sidebar elevation-4" *ngIf="isShown && isMenuReady">
  <a href="/" class="brand-link logo-switch" [title]="'DEPOTO'">
    <img src="assets/img/dt-icon.svg" alt="Depoto" class="brand-image-xl logo-xs" data-cy="small-logo" />
    <img src="assets/img/group559.svg" alt="Depoto" class="brand-image-xs logo-xl" data-cy="big-logo" />
  </a>

  <div class="sidebar">
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column nav-collapse-hide-child"
        data-widget="treeview"
        role="menu"
        data-accordion="true"
      >
        <ng-container *ngFor="let item of menuItems">
          <ng-template [ngIf]="item && !item.sublinks && item.show">
            <li class="nav-item">
              <a
                [routerLink]="item.link"
                class="nav-link nav-link-main"
                [ngClass]="{ active: isActiveLink(item) }"
                [title]="item.title"
                (click)="handleSubtree($event)"
              >
                <i class="nav-icon far fa-{{ item.icon }}"></i>
                <p class="nav-link-label" data-cy="nav-items">{{ item.title }}</p>
              </a>
            </li>
          </ng-template>
          <ng-template [ngIf]="item && item.sublinks && item.show">
            <li class="nav-item has-treeview">
              <a href="#" class="nav-link submenu-main" [title]="item.title" (click)="handleSubtree($event)">
                <i class="nav-icon far fa-{{ item.icon }}"></i>
                <p class="nav-link-label" data-cy="more-nav-items">{{ item.title }}</p>
                <i class="right fa fa-angle-left"></i>
              </a>
              <ul class="nav nav-treeview submenu">
                <ng-container *ngFor="let subitem of item.sublinks">
                  <ng-template [ngIf]="subitem.show">
                    <li class="nav-item" [ngClass]="{ 'active-border': isActiveLink(subitem) }">
                      <a
                        [routerLink]="subitem.link"
                        class="nav-link nav-link-sub"
                        [ngClass]="{ 'active-submenu': isActiveLink(subitem) }"
                        (click)="makeParentActive($event)"
                        [title]="subitem.title"
                      >
                        <i class="nav-icon far fa-{{ subitem.icon }}"></i>
                        <p data-cy="sub-nav-items">{{ subitem.title }}</p>
                      </a>
                    </li>
                  </ng-template>
                </ng-container>
              </ul>
            </li>
          </ng-template>
        </ng-container>
      </ul>
    </nav>
  </div>
</aside>
