<form>
  <div class="card">
    <div class="row">
      <div class="col-8 align-self-center">
        <div class="d-flex flex-direction-column-gap-5">
          <h3 class="card-title text-nowrap">
            {{ 'payment' | translate }}
          </h3>
        </div>
      </div>
      <div class="col-4 align-self-center">
        <btn
          [label]="'add'"
          [title]="'add' | translate"
          [icon]="'far-plus'"
          [classes]="['btn-third', 'float-right']"
          (onEvent)="addPaymentItem()"
        ></btn>
      </div>
    </div>

    <div class="mt-2">
      <div class="mb-2" *ngFor="let pItem of order.paymentItems">
        <div class="row">
          <div class="col">
            <form-row-select
              [icon]="null"
              [options]="payments"
              [noMarginBottom]="true"
              [bindToObject]="true"
              [(model)]="pItem.payment"
              [dataCyAttribute]="'order-payment-payment'"
            ></form-row-select>
          </div>
          <div class="col flex-gap-5">
            <form-row-input
              [icon]="null"
              [noMarginBottom]="true"
              [(model)]="pItem.amount"
              [classes]="['text-end']"
              [dataCyAttribute]="'order-payment-amount'"
            ></form-row-input>
            <p class="my-auto">{{ order?.currency | formatCurrencyWithoutPrice }}</p>
            <btn
              class="align-self-center"
              [title]="'delete' | translate"
              [icon]="'far-trash-alt'"
              (onEvent)="deletePaymentItem(pItem)"
              [classes]="['btn-sm', 'btn-trash']"
            ></btn>
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col">
            <form-row-checkbox
              class="align-self-center"
              [label]="'isPaid'"
              [(model)]="pItem.isPaid"
              [cursorPointer]="true"
              [dataCySelector]="'order-payment-isPaid'"
            ></form-row-checkbox>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 pe-0 ps-0" style="border-top: 1px solid #e6e9f4"></div>
      </div>

      <div class="row mt-2" *ngIf="!canCreateOrEdit">
        <div class="col-5 align-self-center">
          <h3 class="card-title mb-0">
            {{ 'carrier' | translate }}
          </h3>
        </div>
        <div class="col-7 align-self-center d-flex justify-content-end">
          <span [ngClass]="'carrier-label-' + (order?.carrier?.name ? order.carrier.name : '')">
            {{ order?.carrier?.name ? order.carrier.name : '' }}
          </span>
        </div>
      </div>

      <div class="row mt-2" *ngIf="canCreateOrEdit">
        <div class="col-5 align-self-center">
          <h3 class="card-title mb-0">
            {{ 'carrier' | translate }}
          </h3>
        </div>
        <div class="col-7 align-self-center justify-content-end">
          <form-row-select
            [label]="null"
            [icon]="null"
            [noMarginBottom]="true"
            [bindToObject]="true"
            [options]="carriers"
            [model]="order.carrier"
            (modelChange)="selectedCarrier($event)"
            [dataCyAttribute]="'order-payment-carrier'"
          ></form-row-select>
        </div>
      </div>
    </div>
  </div>
</form>
