import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'listLength',
  pure: false,
})
export class ListLengthPipe implements PipeTransform {
  transform(list: any[]): string {
    return list && list.length !== 0 ? `${list.length}` : '0'
  }
}
