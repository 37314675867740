import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { CoreService } from '../../services'
import { User } from 'depoto-core/src/entities'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() isShown = false
  companyName = ''
  server = 'prod'
  iconExpand = true
  windowWidth = window.innerWidth

  constructor(
    private core: CoreService,
    private router: Router
  ) {
    this.core.services.user.currentUserEmitter.subscribe().then((user: User) => {
      if (user && user.company && user.company.name) {
        this.companyName = window['companyName'] = user.company.name
        // document.title = `${this.companyName} - Depoto`
      } else {
        this.companyName = window['companyName'] = ''
        document.title = 'Depoto'
      }
      this.server = this.core.services.oauth.session.clientType
    })
  }

  toggleSideMenu(event: Event): void {
    event.preventDefault() // Prevents page refresh
    this.iconExpand = !this.iconExpand

    const bodyElement = document.getElementsByTagName('body')[0]
    bodyElement.classList.toggle('sidebar-collapse')
    bodyElement.classList.toggle('sidebar-open')
    bodyElement.classList.toggle('sidebar-closed')

    setTimeout(() => {
      if (bodyElement.className.includes('collapse')) {
        this.core.services.storage.set(this.core.services.storage.keys.app.sideMenuState, 'collapsed')
      } else {
        this.core.services.storage.set(this.core.services.storage.keys.app.sideMenuState, 'open')
      }
      // Events.dispatch('menu:update-width')
    }, 500)
  }

  onResize(event) {
    this.windowWidth = event.target.innerWidth
  }
}
