<div>
  <loading-spinner [isLoading]="orderDetailService.loading" [classesString]="'top-25'"></loading-spinner>
  <div class="row" *ngIf="!orderDetailService.loading && order">
    <div class="col-12 col-md-12 col-lg-12 col-xl-8">
      <div class="row">
        <div class="col-12">
          <app-order-products
            [vats]="orderDetailService.vats"
            [isCreating]="orderDetailService.isCreating"
            [canCreateOrEdit]="orderDetailService.canCreateOrEdit"
            [canEditPrice]="orderDetailService.canEditPrice"
            [order]="orderDetailService.order"
            [depots]="orderDetailService.depots"
            (orderChange)="orderDetailService.order = $event"
            (refreshPaymentAmount)="refreshPaymentAmount()"
            (orderReload)="refreshData(order.id)"
          >
            <!--                  TODO:-->
            <!--                  (fetchClearanceItems)="fetchClearanceItem($event)"-->
          </app-order-products>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <div class="row">
            <div class="col-12">
              <app-order-payments
                [(order)]="orderDetailService.order"
                [canCreateOrEdit]="orderDetailService.canCreateOrEdit"
                [payments]="orderDetailService.payments"
                [carriers]="orderDetailService.carriers"
              >
              </app-order-payments>
            </div>
          </div>
          <div class="row" *ngIf="order?.movePacks?.length > 0">
            <div class="col-12">
              <app-order-product-moves [order]="order"></app-order-product-moves>
            </div>
          </div>
          <div class="row" *ngIf="!orderDetailService.isCreating && order">
            <div class="col-12">
              <app-order-bill-vat [order]="order"></app-order-bill-vat>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <app-order-packages
                [order]="order"
                *ngIf="order.packages && order.packages.length > 0"
              ></app-order-packages>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <app-order-tags
                [order]="orderDetailService.order"
                [tags]="tags"
                (orderChange)="orderDetailService.order = $event; orderDetailService.isEditedTags = true"
              ></app-order-tags>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <app-order-info
            [order]="orderDetailService.order"
            (orderChange)="orderDetailService.order = $event; orderDetailService.fillFormValue()"
            [currencies]="orderDetailService.currencies"
            [canCreateOrEdit]="orderDetailService.canCreateOrEdit"
            (recalculatePricesByPriceLevel)="recalculatePricesByChangedCurrency()"
          >
          </app-order-info>
          <app-order-expedition
            [canCreateOrEdit]="orderDetailService.canCreateOrEdit"
            [order]="order"
            (orderChange)="orderDetailService.order = $event; orderDetailService.fillFormValue()"
            (processStatusChange)="setToStatusReceived($event)"
          >
          </app-order-expedition>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-12 col-lg-12 col-xl-4">
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-6 col-xl-12">
          <app-order-customer
            [isCreating]="orderDetailService.isCreating"
            [canCreateOrEdit]="orderDetailService.canCreateOrEdit"
            [(order)]="orderDetailService.order"
            (editedAddressChange)="orderDetailService.isEditedAddress = $event"
            [(isShippingAddressSameAsInvoice)]="orderDetailService.isShippingAddressSameAsInvoice"
            (recalculatePricesByPriceLevel)="recalculatePricesByPriceLevel()"
          >
          </app-order-customer>
        </div>
        <div class="col-12 col-sm-6 col-lg-6 col-xl-12">
          <app-order-notes
            [(order)]="orderDetailService.order"
            [canCreateOrEditNotes]="orderDetailService.canCreateOrEditNotes"
          ></app-order-notes>
        </div>

        <div class="col-12 col-sm-6 col-lg-6 col-xl-12">
          <app-order-files
            [(order)]="orderDetailService.order"
            [(isEditedFiles)]="orderDetailService.isEditedFiles"
            (onFileAdded)="fileAdded($event)"
          >
          </app-order-files>
        </div>
      </div>
    </div>
  </div>
</div>
