<div class="card">
  <div>
    <h3 class="card-title">
      {{ 'tags' | translate }}
    </h3>
  </div>
  <div class="mt-2">
    <form-row-select-multiple
      [options]="tags"
      [(selectedValues)]="this.order.tags"
      (selectedValuesChange)="onChange($event)"
      [dataCyAttribute]="'order-tags'"
    ></form-row-select-multiple>
  </div>
</div>
