export class SchemaUtil {
  static basic = {
    id: null,
    name: null,
  }

  static currency = {
    id: null,
    name: null,
    ratio: null,
  }

  static userGroup = {
    id: null,
    name: null,
    roles: [null],
    company: {
      id: null,
      name: null,
    },
  }

  static purchase = {
    id: null,
    dateFrom: null,
    dateTo: null,
    generated: null,
    created: null,
    depots: {
      id: null,
      name: null,
    },
    checkouts: {
      id: null,
      name: null,
    },
    suppliers: {
      id: null,
      name: null,
    },
    producers: {
      id: null,
      name: null,
    },
    url: null,
    format: null,
  }

  static sale = {
    id: null,
    dateFrom: null,
    dateTo: null,
    generated: null,
    created: null,
    checkouts: {
      id: null,
      name: null,
    },
    suppliers: {
      id: null,
      name: null,
    },
    producers: {
      id: null,
      name: null,
    },
    url: null,
    format: null,
  }

  static stock = {
    id: null,
    date: null,
    generated: null,
    created: null,
    depots: {
      id: null,
      name: null,
    },
    suppliers: {
      id: null,
      name: null,
    },
    producers: {
      id: null,
      name: null,
    },
    url: null,
    format: null,
  }

  static exportOrders = {
    id: null,
    dateFrom: null,
    dateTo: null,
    generated: null,
    created: null,
    status: {
      id: null,
      name: null,
    },
    checkouts: {
      id: null,
      name: null,
    },
    url: null,
    format: null,
  }
  static vat = {
    id: null,
    name: null,
    percent: null,
    default: null,
    coefficient: null,
    externalId: null,
    company: {
      id: null,
      name: null,
    },
    created: null,
    updated: null,
  }

  static address = {
    city: null,
    companyName: null,
    country: null,
    dic: null,
    email: null,
    externalId: null,
    firstName: null,
    ic: null,
    id: null,
    isBilling: null,
    isStored: null,
    lastName: null,
    note: null,
    phone: null,
    state: null,
    street: null,
    branchId: null,
    zip: null,
  }

  static producer = {
    id: null,
    name: null,
    externalId: null,
    company: {
      id: null,
      name: null,
    },
  }

  static productDepot = {
    id: null,
    depot: {
      id: null,
      name: null,
    },
    supplier: {
      id: null,
      name: null,
    },
    product: {
      quantityPurchase: null,
    },
    quantityStock: null,
    quantityReservation: null,
    quantityAvailable: null,
    position: null,
    position1: null,
    position2: null,
    position3: null,
    purchasePrice: null,
    expirationDate: null,
    batch: null,
    clearanceItemId: null,
  }

  static productDepotMetric = {
    id: null,
    depot: {
      id: null,
    },
    product: {
      id: null,
    },
    quantityMinimum: null,
    quantityOptimum: null,
    quantityMaximum: null,
    quantityPurchase: null,
  }

  static quantity = {
    depot: {
      id: null,
      name: null,
    },
    quantityStock: null,
    quantityReservation: null,
    quantityAvailable: null,
    quantityPurchase: null,
  }

  static category = {
    id: null,
    externalId: null,
    name: null,
    text: null,
    parent: {
      id: null,
    },
    children: {
      id: null,
      name: null,
    },
    hasChildren: null,
    position: null,
    company: {
      id: null,
      name: null,
    },
    treePath: null,
    tags: {
      id: null,
      name: null,
    },
  }

  static consent = {
    id: null,
    name: null,
    body: null,
    externalId: null,
    created: null,
    updated: null,
    company: {
      id: null,
      name: null,
    },
  }

  static checkout = {
    id: null,
    name: null,
    amount: null,
    nextBillNumber: null,
    nextReservationNumber: null,
    billFooter: null,
    depots: {
      id: null,
      name: null,
      unavailablesUrl: null,
    },
    returnsDepot: {
      id: null,
      name: null,
      unavailablesUrl: null,
    },
    payments: {
      id: null,
      name: null,
      type: {
        id: null,
        name: null,
      },
    },
    currentClosing: {
      id: null,
      userOpen: {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        name: null,
        phone: null,
        groups: {
          id: null,
          name: null,
          roles: null,
        },
        roles: null,
        pin: null,
        enabled: null,
        checkouts: {
          id: null,
          name: null,
          depots: {
            id: null,
          },
        },
      },
      dateOpen: null,
      noteOpen: null,
      amountOpen: null,
      amountRealOpen: null,
      userClosed: {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        name: null,
        phone: null,
        groups: {
          id: null,
          name: null,
          roles: null,
        },
        roles: null,
        pin: null,
        enabled: null,
        checkouts: {
          id: null,
          name: null,
          depots: {
            id: null,
          },
        },
      },
      dateClosed: null,
      noteClosed: null,
      amountClosed: null,
      amountRealClosed: null,
      // orders: { // todo never ever
      vatAllocations: {
        vat: {
          id: null,
          name: null,
          percent: null,
          coefficient: null,
          default: null,
        },
        priceWithoutVat: null,
        vatPrice: null,
        price: null,
      },
      billUrl: null,
    },
    negativeReservation: null,
    autoPacking: null,
    autoPicking: null,
    autoDispatched: null,
    eventUrl: null,
    eventTypes: null,
  }

  static carrier = {
    id: null,
    name: null,
    color: null,
    position: null,
    tariffs: {
      id: null,
      name: null,
      const: null,
      position: null,
      cod: null,
    },
  }

  static companyCarrier = {
    id: null,
    enable: null,
    options: null,
    carrier: SchemaUtil.carrier,
    checkout: {
      id: null,
      name: null,
    },
    externalId: null,
  }

  static customer = {
    id: null,
    addresses: SchemaUtil.address,
    birthday: null,
    consentRelations: { id: null, consent: { id: null }, name: null, body: null },
    email: null,
    firstName: null,
    lastName: null,
    companyName: null,
    minExpirationDays: null,
    name: null,
    note: null,
    phone: null,
    priceLevel: { id: null, name: null, isPercentage: null, percent: null, withVat: null, currency: { id: null } },
    tags: { id: null, name: null, type: null, color: null, externalId: null },
    users: { id: null, email: null, firstName: null, lastName: null, name: null },
    wholesale: null,
  }

  static priceLevel = {
    id: null,
    name: null,
    isPercentage: null,
    percent: null,
    withVat: null,
    currency: {
      id: null,
      name: null,
      ratio: null,
    },
    externalId: null,
    company: {
      id: null,
      name: null,
    },
  }

  static file = {
    id: null,
    text: null,
    originalFilename: null,
    main: null,
    mimeType: null,
    size: null,
    url: null,
    thumbnails: {
      format: null,
      mimeType: null,
      url: null,
    },
  }

  static userProfile = {
    id: null,
    locale: null,
    email: null,
    firstName: null,
    lastName: null,
    name: null,
    phone: null,
    groups: {
      id: null,
      name: null,
      roles: null,
    },
    roles: null,
    pin: null,
    company: {
      id: null,
      name: null,
      ic: null,
      dic: null,
      email: null,
      phone: null,
      street: null,
      city: null,
      zip: null,
      country: null,
      registrationNote: null,
      nextEan: null,
      billLogo: null,
      defaultVoucherValidity: null,
      parent: {
        id: null,
        name: null,
        ic: null,
        dic: null,
        email: null,
        phone: null,
        street: null,
        city: null,
        zip: null,
        country: null,
        registrationNote: null,
        nextEan: null,
        billLogo: null,
        parent: {
          id: null,
          name: null,
        },
        children: {
          id: null,
          name: null,
        },
        carrierRelations: {
          id: null,
        },
      },
      carrierRelations: {
        id: null,
        enable: null,
        options: null,
        carrier: {
          id: null,
          name: null,
        },
        checkout: {
          id: null,
          name: null,
        },
        externalId: null,
      },
      moduleRelations: {
        id: null,
        module: {
          id: null,
          name: null,
        },
      },
    },
    checkouts: {
      id: null,
      name: null,
    },
    depots: {
      id: null,
      name: null,
    },
    enabled: null,
  }

  static sellItem = {
    id: null,
    quantityStock: null,
    quantityReservation: null,
    quantityAvailable: null,
    expirationDate: null,
    batch: null,
    productDepots: {
      id: null,
      depot: {
        id: null,
        name: null,
      },
      supplier: {
        id: null,
        name: null,
      },
      purchasePrice: null,
      quantityStock: null,
      quantityReservation: null,
      quantityAvailable: null,
      quantityUnavailable: null,
      inventoryQuantityStock: null,
      batch: null,
      expirationDate: null,
      position: null,
      position1: null,
      position2: null,
      position3: null,
      created: null,
      updated: null,
    },
  }

  static soldItem = {
    name: null,
    type: null,
    ean: null,
    code: null,
    quantity: null,
    priceAll: null,
    priceAllWithoutVat: null,
    product: {
      id: null,
      fullName: null,
      mainImage: {
        id: null,
        text: null,
        mimeType: null,
        url: null,
        thumbnails: { format: null, mimeType: null, url: null },
      },
    },
    profit: null,
  }

  static parameter = {
    id: null,
    enumValues: null,
    externalId: null,
    name: null,
    text: null,
    type: null,
    unit: null,
    company: {
      id: null,
    },
  }

  static payment = {
    id: null,
    name: null,
    type: {
      id: null,
      name: null,
    },
    dispatchUnpaid: null,
  }

  static productImportSearch = {
    id: null,
    name: null,
    fullName: null,
    ean: null,
    code: null,
  }

  static productOrderAdd = {
    id: null,
    fullName: null,
    ean: null,
    code: null,
    // purchasePrice: null,
    actualSellPrice: null,
    mainImage: SchemaUtil.file,
    packs: {
      id: null,
      ean: null,
      code: null,
      quantity: null,
      externalId: null,
      note: null,
    },
    quantityStock: null,
    quantityReservation: null,
    quantityAvailable: null,
    quantityPurchase: null,
    tags: {
      id: null,
      name: null,
      type: null,
      color: null,
      externalId: null,
    },
    quantities: SchemaUtil.quantity,
    vat: {
      id: null,
      name: null,
      default: null,
      percent: null,
      coefficient: null,
    },
  }

  static productPurchaseOrder = {
    purchaseCurrency: {
      id: null,
      name: null,
    },
    purchasePrice: null,
    id: null,
    fullName: null,
    ean: null,
    code: null,
    actualSellPrice: null,
    mainImage: SchemaUtil.file,
    packs: {
      id: null,
      ean: null,
      code: null,
      quantity: null,
      externalId: null,
      note: null,
    },
    quantityStock: null,
    quantityReservation: null,
    quantityAvailable: null,
    quantityPurchase: null,
    tags: {
      id: null,
      name: null,
      type: null,
      color: null,
      externalId: null,
    },
    quantities: SchemaUtil.quantity,
    vat: {
      id: null,
      name: null,
      default: null,
      percent: null,
      coefficient: null,
    },
  }

  static fileExtra = {
    id: null,
    originalFilename: null,
    text: null,
    main: null,
    mimeType: null,
    size: null,
    url: null,
    product: { id: null },
    order: { id: null },
    thumbnails: {
      format: null,
      mimeType: null,
      url: null,
    },
  }

  static purchaseOrder = {
    id: null,
    status: {
      id: null,
      name: null,
    },
    supplier: { id: null },
    depot: { id: null },
    tags: { id: null },
    externalId: null,
    externalReference: null,
    supplierReference: null,
    note: null,
    privateNote: null,
    created: null,
    updated: null,
    number: null,
    dateEstimatedArrival: null,
    createdBy: { name: null, firstName: null, lastName: null },
    items: {
      id: null,
      quantity: null,
      quantityIn: null,
      purchasePrice: null,
      purchasePriceAll: null,
      currency: {
        id: null,
      },
      note: null,
      product: {
        id: null,
        fullName: null,
        mainImage: {
          url: null,
          thumbnails: {
            format: null,
            url: null,
          },
        },
        code: null,
        ean: null,
      },
    },
    files: SchemaUtil.fileExtra,
  }

  static productList = {
    id: null,
    name: null,
    fullName: null,
    ean: null,
    code: null,
    sellPrice: null,
    actualSellPrice: null,
    enabled: null,
    created: null,
    isFragile: null,
    isOversize: null,
    mainImage: SchemaUtil.file,
    quantityStock: null,
    quantityReservation: null,
    quantityAvailable: null,
    quantityPurchase: null,
    metrics: {
      quantityMinimum: null,
      quantityOptimum: null,
      quantityMaximum: null,
      quantityStock: null,
    },
    children: {
      id: null,
      name: null,
      fullName: null,
      ean: null,
      code: null,
      sellPrice: null,
      actualSellPrice: null,
      enabled: null,
      isFragile: null,
      isOversize: null,
      mainImage: SchemaUtil.file,
      quantityStock: null,
      quantityReservation: null,
      quantityAvailable: null,
      quantityPurchase: null,
      quantities: SchemaUtil.quantity,
    },
    tags: {
      id: null,
      name: null,
      type: null,
      color: null,
      externalId: null,
    },
    quantities: SchemaUtil.quantity,
  }

  static product = {
    id: null,
    requiredSerialNumbers: null,
    productDepots: {
      id: null,
      depot: {
        id: null,
        name: null,
        unavailablesUrl: null,
      },
      supplier: {
        id: null,
        name: null,
      },
      product: {
        quantityPurchase: null,
      },
      purchasePrice: null,
      quantityStock: null,
      quantityReservation: null,
      quantityAvailable: null,
      quantityUnavailable: null,
      inventoryQuantityStock: null,
      batch: null,
      expirationDate: null,
      position: null,
      position1: null,
      position2: null,
      position3: null,
      created: null,
      updated: null,
    },
    name: null,
    fullName: null,
    ean: null,
    ean2: null,
    ean3: null,
    ean4: null,
    ean5: null,
    code: null,
    code2: null,
    code3: null,
    externalId: null,
    purchaseCurrency: {
      id: null,
      name: null,
      ratio: null,
    },
    purchasePrice: null,
    sellPrice: null,
    sellPriceWithoutVat: null,
    beforeSellPrice: null,
    actualSellPrice: null,
    actualSellPriceWithoutVat: null,
    actualBeforeSellPrice: null,
    weight: null,
    enabled: null,
    parent: {
      id: null,
      name: null,
      fullName: null,
      ean: null,
      ean2: null,
      ean3: null,
      ean4: null,
      ean5: null,
      code: null,
      externalId: null,
      purchaseCurrency: {
        id: null,
        name: null,
        ratio: null,
      },
      purchasePrice: null,
      sellPrice: null,
      sellPriceWithoutVat: null,
      beforeSellPrice: null,
      actualSellPrice: null,
      actualSellPriceWithoutVat: null,
      actualBeforeSellPrice: null,
      weight: null,
      enabled: null,
      isBundle: null,
      isFragile: null,
      isOversize: null,
      producer: {
        id: null,
        name: null,
      },
      isForSubsequentSettlement: null,
      mainImage: SchemaUtil.file,
      children: {
        id: null,
      },
    },
    children: {
      id: null,
      name: null,
      fullName: null,
      ean: null,
      ean2: null,
      ean3: null,
      ean4: null,
      ean5: null,
      code: null,
      externalId: null,
      purchaseCurrency: {
        id: null,
        name: null,
        ratio: null,
      },
      purchasePrice: null,
      sellPrice: null,
      sellPriceWithoutVat: null,
      beforeSellPrice: null,
      actualSellPrice: null,
      actualSellPriceWithoutVat: null,
      actualBeforeSellPrice: null,
      weight: null,
      enabled: null,
      isBundle: null,
      isFragile: null,
      isOversize: null,
      note: null,
      hsCode: null,
      isDeletable: null,
      dimensionX: null,
      dimensionY: null,
      dimensionZ: null,
      originCountry: null,
      producer: {
        id: null,
        name: null,
      },
      vat: {
        id: null,
      },
      isForSubsequentSettlement: null,
      mainImage: SchemaUtil.file,
      productPriceLevels: {
        id: null,
        sellPrice: null,
        externalId: null,
        priceLevel: {
          id: null,
        },
      },
      parent: {
        id: null,
      },
      quantityAvailable: null,
      quantityReservation: null,
      quantityStock: null,
    },
    isBundle: null,
    isFragile: null,
    isOversize: null,
    bundleParents: {
      id: null,
      quantity: null,
      parent: {
        id: null,
        fullName: null,
        ean: null,
        code: null,
        mainImage: SchemaUtil.file,
      },
    },
    bundleChildren: {
      id: null,
      quantity: null,
      child: {
        id: null,
        name: null,
        fullName: null,
        code: null,
        ean: null,
        quantities: SchemaUtil.quantity,
        quantityAvailable: null,
        quantityStock: null,
        quantityReservation: null,
        quantityPurchase: null,
        mainImage: SchemaUtil.file,
      },
    },
    producer: {
      id: null,
      name: null,
    },
    vat: {
      id: null,
      name: null,
      percent: null,
      coefficient: null,
      default: null,
    },
    supplier: {
      id: null,
      name: null,
    },
    isForSubsequentSettlement: null,
    mainImage: SchemaUtil.file,
    images: SchemaUtil.file,
    files: SchemaUtil.file,
    advancedPrices: {
      id: null,
      product: {
        id: null,
        name: null,
        productDepots: {
          id: null,
        },
      },
      sellPrice: null,
      available: null,
      dateFrom: null,
      dateTo: null,
      isActual: null,
      note: null,
    },
    packs: {
      id: null,
      ean: null,
      code: null,
      quantity: null,
      externalId: null,
      note: null,
    },
    productParameters: {
      id: null,
      value: null,
      externalId: null,
      parameter: SchemaUtil.parameter,
      product: {
        id: null,
      },
    },
    quantityUnavailable: null,
    quantityStock: null,
    quantityReservation: null,
    quantityAvailable: null,
    quantityPurchase: null,
    note: null,
    description: null,
    customData: null,
    categories: {
      id: null,
      externalId: null,
      name: null,
      text: null,
      parent: {
        id: null,
        externalId: null,
        name: null,
        text: null,
        parent: {
          id: null,
          name: null,
        },
        children: {
          id: null,
          name: null,
        },
        hasChildren: null,
        position: null,
        treePath: null,
        tags: {
          id: null,
        },
      },
      children: {
        id: null,
        externalId: null,
        name: null,
        text: null,
        parent: {
          id: null,
          name: null,
        },
        children: {
          id: null,
          name: null,
        },
        hasChildren: null,
        position: null,
        treePath: null,
        tags: {
          id: null,
        },
      },
      hasChildren: null,
      position: null,
      treePath: null,
      tags: {
        id: null,
      },
    },
    mainCategory: {
      id: null,
      externalId: null,
      name: null,
      text: null,
      parent: {
        id: null,
        externalId: null,
        name: null,
        text: null,
        parent: {
          id: null,
          name: null,
        },
        children: {
          id: null,
          name: null,
        },
        hasChildren: null,
        position: null,
        treePath: null,
        tags: {
          id: null,
        },
      },
      children: {
        id: null,
        externalId: null,
        name: null,
        text: null,
        parent: {
          id: null,
          name: null,
        },
        children: {
          id: null,
          name: null,
        },
        hasChildren: null,
        position: null,
        treePath: null,
        tags: {
          id: null,
        },
      },
      hasChildren: null,
      position: null,
      treePath: null,
      tags: {
        id: null,
      },
    },
    productPriceLevels: {
      id: null,
      sellPrice: null,
      externalId: null,
      priceLevel: {
        id: null,
        name: null,
        isPercentage: null,
        percent: null,
        withVat: null,
        currency: {
          id: null,
          name: null,
        },
        externalId: null,
      },
    },
    tags: {
      id: null,
      name: null,
      type: null,
      color: null,
      externalId: null,
    },
    quantities: SchemaUtil.quantity,
    sellItems: SchemaUtil.sellItem,
    isDeletable: null,
    dimensionX: null,
    dimensionY: null,
    dimensionZ: null,
    originCountry: null,
    hsCode: null,
  }

  static productRestricted = {
    id: null,
    name: null,
    fullName: null,
    code: null,
    ean: null,
    enabled: null,
    quantityStock: null,
    quantityAvailable: null,
    quantityReservation: null,
    quantityPurchase: null,
    quantities: SchemaUtil.quantity,
    mainImage: {
      id: null,
      thumbnails: {
        format: null,
        mimeType: null,
        url: null,
      },
    },
    vat: {
      id: null,
      name: null,
    },
    requiredSerialNumbers: null,
    supplier: {
      id: null,
    },
    purchasePrice: null,
    purchaseCurrency: {
      id: null,
    },
    producer: {
      id: null,
      name: null,
    },
    parent: {
      id: null,
      name: null,
    },
    sellPrice: null,
  }

  static depot = {
    id: null,
    name: null,
    emailIn: null,
    unavailablesUrl: null,
    externalId: null,
    company: {
      id: null,
    },
    companyCarriers: {
      id: null,
      name: null,
    },
    checkouts: {
      id: null,
      name: null,
    },
    created: null,
    updated: null,
  }

  static duplicatedProduct = {
    id: null,
    name: null,
    fullName: null,
    requiredSerialNumbers: null,
    purchaseCurrency: {
      id: null,
      name: null,
      ratio: null,
    },
    purchasePrice: null,
    sellPrice: null,
    sellPriceWithoutVat: null,
    beforeSellPrice: null,
    actualSellPrice: null,
    actualSellPriceWithoutVat: null,
    actualBeforeSellPrice: null,
    weight: null,
    enabled: null,
    parent: {
      id: null,
      name: null,
      fullName: null,
      ean: null,
      ean2: null,
      ean3: null,
      ean4: null,
      ean5: null,
      code: null,
      code2: null,
      externalId: null,
      purchaseCurrency: {
        id: null,
        name: null,
        ratio: null,
      },
      purchasePrice: null,
      sellPrice: null,
      sellPriceWithoutVat: null,
      beforeSellPrice: null,
      actualSellPrice: null,
      actualSellPriceWithoutVat: null,
      actualBeforeSellPrice: null,
      weight: null,
      enabled: null,
      isBundle: null,
      isFragile: null,
      isOversize: null,
      producer: {
        id: null,
        name: null,
      },
      isForSubsequentSettlement: null,
      mainImage: SchemaUtil.file,
      children: {
        id: null,
      },
    },
    isBundle: null,
    isFragile: null,
    isOversize: null,
    bundleChildren: {
      id: null,
      quantity: null,
      child: {
        id: null,
        fullName: null,
        ean: null,
        code: null,
        mainImage: SchemaUtil.file,
        quantities: SchemaUtil.quantity,
        quantityAvailable: null,
        quantityReservation: null,
        quantityStock: null,
      },
    },
    bundleParents: {
      id: null,
      quantity: null,
      parent: {
        id: null,
        fullName: null,
        ean: null,
        code: null,
        mainImage: SchemaUtil.file,
      },
    },
    producer: {
      id: null,
      name: null,
    },
    vat: {
      id: null,
      name: null,
      percent: null,
      coefficient: null,
      default: null,
    },
    supplier: {
      id: null,
      name: null,
    },
    isForSubsequentSettlement: null,
    mainImage: SchemaUtil.file,
    images: SchemaUtil.file,
    files: SchemaUtil.file,
    advancedPrices: {
      id: null,
      product: {
        id: null,
        name: null,
        productDepots: {
          id: null,
        },
      },
      sellPrice: null,
      available: null,
      dateFrom: null,
      dateTo: null,
      isActual: null,
      note: null,
    },
    packs: {
      id: null,
      ean: null,
      code: null,
      quantity: null,
      externalId: null,
      note: null,
    },
    quantityUnavailable: null,
    quantityStock: null,
    quantityReservation: null,
    quantityAvailable: null,
    note: null,
    description: null,
    customData: null,
    categories: {
      id: null,
      externalId: null,
      name: null,
      text: null,
      parent: {
        id: null,
        externalId: null,
        name: null,
        text: null,
        parent: {
          id: null,
          name: null,
        },
        children: {
          id: null,
          name: null,
        },
        hasChildren: null,
        position: null,
        treePath: null,
        tags: null,
      },
      children: {
        id: null,
        externalId: null,
        name: null,
        text: null,
        parent: {
          id: null,
          name: null,
        },
        children: {
          id: null,
          name: null,
        },
        hasChildren: null,
        position: null,
        treePath: null,
        tags: null,
      },
      hasChildren: null,
      position: null,
      treePath: null,
      tags: null,
    },
    mainCategory: {
      id: null,
      externalId: null,
      name: null,
      text: null,
      parent: {
        id: null,
        externalId: null,
        name: null,
        text: null,
        parent: {
          id: null,
          name: null,
        },
        children: {
          id: null,
          name: null,
        },
        hasChildren: null,
        position: null,
        treePath: null,
        tags: null,
      },
      children: {
        id: null,
        externalId: null,
        name: null,
        text: null,
        parent: {
          id: null,
          name: null,
        },
        children: {
          id: null,
          name: null,
        },
        hasChildren: null,
        position: null,
        treePath: null,
        tags: null,
      },
      hasChildren: null,
      position: null,
      treePath: null,
      tags: null,
    },
    productPriceLevels: {
      id: null,
      sellPrice: null,
      externalId: null,
      priceLevel: {
        id: null,
        name: null,
        isPercentage: null,
        percent: null,
        withVat: null,
        currency: {
          id: null,
          name: null,
        },
        externalId: null,
      },
    },
    tags: {
      id: null,
      name: null,
      type: null,
      color: null,
      externalId: null,
    },
    quantities: SchemaUtil.quantity,
    sellItems: SchemaUtil.sellItem,
    isDeletable: null,
    dimensionX: null,
    dimensionY: null,
    dimensionZ: null,
    originCountry: null,
    hsCode: null,
  }

  static mainImageSchema = {
    id: null,
    text: null,
    originalFilename: null,
    main: null,
    mimeType: null,
    size: null,
    url: null,
    thumbnails: {
      format: null,
      mimeType: null,
      url: null,
    },
  }

  static productMove = {
    id: null,
    deletable: null,
    productDepotFrom: {
      id: null,
      depot: { id: null, name: null }, // todo: optimize on server (+ ~350ms)
      purchasePrice: null,
      batch: null,
      expirationDate: null,
      position: null,
    },
    productDepotTo: {
      id: null,
      depot: { id: null, name: null }, // todo: optimize on server (+ ~350ms)
      purchasePrice: null,
      batch: null,
      expirationDate: null,
      position: null,
    },
    productDepotFromQuantityStock: null,
    productDepotToQuantityStock: null,
    amount: null,
    note: null,
    pack: {
      id: null,
      dateCreated: null,
      url: null,
      number: null,
      type: { id: null, name: null },
      order: {
        id: null,
        billNumber: null,
        reservationNumber: null,
        note: null,
        privateNote: null,
      },
      purchasePrice: null,
    },
    created: null,
    updated: null,
    deleted: null,
    createdBy: {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      name: null,
    },
    orderItem: {
      id: null,
      name: null,
      ean: null,
      code: null,
      quantity: null,
      price: null,
      priceAll: null,
      product: { id: null, name: null },
      type: null,
    },
  }

  static productForProductMovePack = {
    id: null,
    name: null,
    fullName: null,
    ean: null,
    code: null,
    purchaseCurrency: {
      id: null,
      name: null,
    },
    purchasePrice: null,
    sellPrice: null,
    sellPriceWithoutVat: null,
    beforeSellPrice: null,
    actualSellPrice: null,
    actualSellPriceWithoutVat: null,
    actualBeforeSellPrice: null,
    weight: null,
    isBundle: null,
    isFragile: null,
    isOversize: null,
    isForSubsequentSettlement: null,
    mainImage: SchemaUtil.mainImageSchema,
    images: {
      id: null,
    },
    files: {
      id: null,
    },
    vat: {
      id: null,
      name: null,
      percent: null,
    },
  }

  static productMovePack = {
    id: null,
    externalId: null,
    externalReference: null,
    dateCreated: null,
    deletable: null,
    url: null,
    pdfWOPricesUrl: null,
    tags: {
      id: null,
      name: null,
      color: null,
    },
    type: {
      id: null,
      name: null,
    },
    purpose: {
      id: null,
      name: null,
    },
    note: null,
    number: null,
    depotFrom: {
      id: null,
      name: null,
      unavailablesUrl: null,
    },
    depotTo: {
      id: null,
      name: null,
      unavailablesUrl: null,
    },
    moves: {
      id: null,
      amount: null,
      note: null,
      deletable: null,
      serialNumbers: null,
      pack: {
        id: null,
      },
      created: null,
      updated: null,
      deleted: null,
      product: {
        id: null,
        name: null,
        fullName: null,
        ean: null,
        requiredSerialNumbers: null,
        sellPrice: null,
        purchaseCurrency: {
          id: null,
          name: null,
        },
        mainImage: SchemaUtil.mainImageSchema,
      },
      createdBy: {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        name: null,
      },
      orderItem: {
        id: null,
        name: null,
        ean: null,
        code: null,
        quantity: null,
        price: null,
        priceWithoutVat: null,
        priceAll: null,
        priceAllWithoutVat: null,
        note: null,
        product: { ...SchemaUtil.productForProductMovePack },
        vat: {
          id: null,
          name: null,
          percent: null,
          coefficient: null,
          default: null,
        },
        isForSubsequentSettlement: null,
        type: null,
        quantityUnavailable: null,
      },
      productDepotFrom: {
        id: null,
        batch: null,
        created: null,
        depot: { id: null, name: null, unavailablesUrl: null },
        expirationDate: null,
        inventoryQuantityStock: null,
        position: null,
        position1: null,
        position2: null,
        position3: null,
        product: {
          id: null,
          productDepots: { id: null },
          name: null,
          fullName: null,
          ean: null,
          code: null,
          mainImage: SchemaUtil.mainImageSchema,
          vat: {
            id: null,
            name: null,
            percent: null,
          },
        },
        purchasePrice: null,
        quantityAvailable: null,
        quantityReservation: null,
        quantityStock: null,
        quantityUnavailable: null,
        supplier: { id: null, name: null },
        updated: null,
      },
      productDepotTo: {
        id: null,
        batch: null,
        created: null,
        depot: { id: null, name: null, unavailablesUrl: null },
        expirationDate: null,
        inventoryQuantityStock: null,
        position: null,
        position1: null,
        position2: null,
        position3: null,
        product: {
          id: null,
          productDepots: { id: null },
          name: null,
          fullName: null,
          ean: null,
          code: null,
          mainImage: SchemaUtil.mainImageSchema,
          vat: {
            id: null,
            name: null,
            percent: null,
          },
        },
        purchasePrice: null,
        quantityAvailable: null,
        quantityReservation: null,
        quantityStock: null,
        quantityUnavailable: null,
        supplier: { id: null, name: null },
        updated: null,
      },
    },
    order: {
      id: null,
      status: {
        id: null,
        name: null,
      },
      reservationNumber: null,
      billNumber: null,
      dateCreated: null,
      processStatus: {
        id: null,
        name: null,
        position: null,
      },
      processStatusUpdated: null,
      processStatusRelation: {
        status: {
          id: null,
          name: null,
          position: null,
        },
        note: null,
        created: null,
        createdBy: {
          id: null,
          email: null,
          firstName: null,
          lastName: null,
          name: null,
        },
      },
      processStatusRelations: {
        status: {
          id: null,
          name: null,
          position: null,
        },
        note: null,
        created: null,
        createdBy: {
          id: null,
          email: null,
          firstName: null,
          lastName: null,
          name: null,
        },
      },
      company: { id: null, name: null },
      carrier: {
        id: null,
        name: null,
      },
      vs: null,
      createdBy: {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        name: null,
      },
      priceAll: null,
    },
    purchasePrice: null,
    createdBy: {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      name: null,
    },
    files: SchemaUtil.file,
  }

  static productMovePackList = {
    id: null,
    dateCreated: null,
    url: null,
    externalReference: null,
    tags: {
      id: null,
      name: null,
      color: null,
    },
    type: {
      id: null,
      name: null,
    },
    note: null,
    number: null,
    depotFrom: {
      id: null,
      name: null,
    },
    depotTo: {
      id: null,
      name: null,
    },
    order: {
      id: null,
      note: null,
      privateNote: null,
      reservationNumber: null,
      billNumber: null,
    },
    purchasePrice: null,
    createdBy: {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      name: null,
      enabled: null,
    },
  }

  static orderItemProducts = {
    id: null,
    name: null,
    ean: null,
    code: null,
    quantity: null,
    price: null,
    priceWithoutVat: null,
    priceAll: null,
    priceAllWithoutVat: null,
    serial: null,
    note: null,
    product: {
      id: null,
      name: null,
      fullName: null,
      purchaseCurrency: {
        id: null,
        name: null,
      },
      purchasePrice: null,
      actualSellPrice: null,
      weight: null,
      enabled: null,
      isBundle: null,
      isFragile: null,
      isOversize: null,
      producer: {
        id: null,
        name: null,
      },
      supplier: {
        id: null,
        name: null,
      },
      productDepots: {
        id: null,
        expirationDate: null,
        quantityAvailable: null,
      },
      isForSubsequentSettlement: null,
      mainImage: SchemaUtil.file,
      note: null,
      bundleChildren: {
        id: null,
        child: {
          id: null,
          name: null,
          ean: null,
          code: null,
        },
        quantity: null,
      },
    },
    vat: {
      id: null,
      name: null,
      percent: null,
      coefficient: null,
      default: null,
    },
    isForSubsequentSettlement: null,
    type: null,
    quantityUnavailable: null,
  }

  static orderItems = {
    id: null,
    reservationNumber: null,
    billNumber: null,
    dateCreated: null,
    customer: {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      name: null,
    },
    currency: {
      id: null,
      name: null,
      ratio: null,
    },
    paymentItems: {
      id: null,
      payment: SchemaUtil.payment,
      amount: null,
      isPaid: null,
    },
    items: SchemaUtil.orderItemProducts,
  }

  static clearanceItem = {
    id: null,
    amount: null,
    batch: null,
    expirationDate: null,
    packed: null,
    picked: null,
    position: null,
    position1: null,
    position2: null,
    position3: null,
    locked: null,
    product: {
      id: null,
      name: null,
      fullName: null,
      code: null,
      ean: null,
      productDepots: SchemaUtil.productDepot,
      mainImage: SchemaUtil.file,
    },
    quantityStock: null,
    quantityAvailable: null,
    quantityReservation: null,
    productDepots: SchemaUtil.productDepot,
    productMoves: {
      id: null,
      productDepotFrom: {
        id: null,
      },
      amount: null,
    },
  }

  static order = {
    id: null,
    status: {
      id: null,
      name: null,
    },
    reservationNumber: null,
    billNumber: null,
    dateCreated: null,
    dateExpedition: null,
    dateTax: null,
    dateDue: null,
    dateBill: null,
    items: {
      id: null,
      name: null,
      ean: null,
      code: null,
      quantity: null,
      price: null,
      priceWithoutVat: null,
      priceAll: null,
      priceAllWithoutVat: null,
      sale: null,
      serial: null,
      note: null,
      product: {
        id: null,
        name: null,
        fullName: null,
        purchaseCurrency: {
          id: null,
          name: null,
        },
        purchasePrice: null,
        actualSellPrice: null,
        weight: null,
        enabled: null,
        isBundle: null,
        isFragile: null,
        isOversize: null,
        producer: {
          id: null,
          name: null,
        },
        supplier: {
          id: null,
          name: null,
        },
        productDepots: {
          id: null,
          expirationDate: null,
          quantityAvailable: null,
        },
        isForSubsequentSettlement: null,
        mainImage: SchemaUtil.file,
        note: null,
        bundleChildren: {
          id: null,
          child: {
            id: null,
            name: null,
            ean: null,
            code: null,
          },
          quantity: null,
        },
      },
      vat: {
        id: null,
        name: null,
        percent: null,
        coefficient: null,
        default: null,
      },
      isForSubsequentSettlement: null,
      type: null,
      quantityUnavailable: null,
    },
    paymentItems: {
      id: null,
      datePaid: null,
      checkout: {
        id: null,
        name: null,
      },
      payment: {
        id: null,
        name: null,
        type: {
          id: null,
          name: null,
        },
      },
      voucher: {
        id: null,
        name: null,
        code: null,
      },
      dateCreated: null,
      dateCancelled: null,
      amount: null,
      isPaid: null,
    },
    checkout: {
      id: null,
      name: null,
      depots: SchemaUtil.basic,
    },
    customer: {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      name: null,
    },
    currency: {
      id: null,
      name: null,
      ratio: null,
    },
    note: null,
    privateNote: null,
    invoiceAddress: SchemaUtil.address,
    shippingAddress: SchemaUtil.address,
    relatedParent: { id: null, reservationNumber: null, billNumber: null },
    rounding: null,
    invoiceUrl: null,
    reservationUrl: null,
    invoiceReceiptUrl: null,
    reservationReceiptUrl: null,
    vatAllocations: {
      vat: {
        id: null,
        name: null,
        percent: null,
        coefficient: null,
        default: null,
      },
      priceWithoutVat: null,
      vatPrice: null,
      price: null,
    },
    processStatus: {
      id: null,
      name: null,
      position: null,
    },
    processStatusUpdated: null,
    processStatusRelation: {
      status: {
        id: null,
        name: null,
        position: null,
      },
      note: null,
      created: null,
      createdBy: {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        name: null,
      },
    },
    processStatusRelations: {
      status: {
        id: null,
        name: null,
        position: null,
      },
      note: null,
      created: null,
      createdBy: {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        name: null,
      },
    },
    company: {
      id: null,
      name: null,
      ic: null,
      dic: null,
      email: null,
      phone: null,
      street: null,
      city: null,
      zip: null,
      country: null,
      registrationNote: null,
      nextEan: null,
      billLogo: null,
      parent: { id: null, name: null },
    },
    carrier: {
      id: null,
      name: null,
      color: null,
      position: null,
      tariffs: {
        id: null,
        name: null,
        const: null,
        position: null,
        cod: null,
      },
    },
    packages: {
      id: null,
      code: null,
      weight: null,
      carrier: {
        id: null,
        name: null,
        color: null,
        position: null,
        tariffs: {
          id: null,
          name: null,
        },
      },
      items: {
        id: null,
        name: null,
        ean: null,
        code: null,
        quantity: null,
        sale: null,
        price: null,
        priceWithoutVat: null,
        priceAll: null,
        priceAllWithoutVat: null,
        picked: null,
        packed: null,
        serial: null,
        note: null,
        product: {
          id: null,
          name: null,
          productDepots: {
            id: null,
          },
        },
        vat: {
          id: null,
          name: null,
        },
        isForSubsequentSettlement: null,
        type: null,
        quantityUnavailable: null,
        moves: {
          id: null,
        },
        clearanceItems: {
          id: null,
        },
      },
      statuses: {
        id: null,
        code: null,
        text: null,
        created: null,
      },
      order: {
        id: null,
      },
      disposal: {
        id: null,
        code: null,
        carrier: {
          id: null,
          name: null,
        },
        packages: {
          id: null,
        },
        ticketUrl: null,
        sent: null,
      },
      ticketUrl: null,
      sent: null,
    },
    quantityUnavailable: null,
    isPaid: null,
    files: {
      ...SchemaUtil.file,
      product: { id: null },
      order: { id: null },
    },
    vs: null,
    createdBy: {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      name: null,
      enabled: null,
    },
    movePacks: {
      id: null,
      dateCreated: null,
      url: null,
      type: {
        id: null,
        name: null,
      },
      purpose: {
        id: null,
        name: null,
      },
      note: null,
      number: null,
      depotFrom: {
        id: null,
        name: null,
        unavailablesUrl: null,
      },
      depotTo: {
        id: null,
        name: null,
        unavailablesUrl: null,
      },
      purchasePrice: null,
      createdBy: {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        name: null,
      },
    },
    isEditable: null,
    externalId: null,
    group: {
      id: null,
      name: null,
      orders: {
        id: null,
      },
      user: { id: null },
      userPosition: null,
    },
    priceAll: null,
    tags: {
      id: null,
      name: null,
      type: null,
      color: null,
      externalId: null,
    },
    priority: null,
    externalReference: null,
  }

  static orderClearanceItems = {
    id: null,
    clearanceItems: SchemaUtil.clearanceItem,
  }

  static orderDetails = {
    id: null,
    externalId: null,
    reservationNumber: null,
    currency: {
      id: null,
      name: null,
    },
    priority: null,
    priceAll: null,
    note: null,
    privateNote: null,
    processStatus: {
      id: null,
      name: null,
    },
    status: {
      id: null,
    },
    externalReference: null,
    carrier: {
      name: null,
    },
    paymentItems: {
      payment: {
        type: {
          name: null,
        },
      },
    },
    checkout: {
      name: null,
    },
    dateCreated: null,
    createdBy: {
      lastName: null,
    },
    invoiceAddress: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      street: null,
      zip: null,
      city: null,
      ic: null,
      dic: null,
    },
    shippingAddress: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      street: null,
      zip: null,
      city: null,
      ic: null,
      dic: null,
    },
    items: {
      id: null,
      name: null,
      code: null,
      ean: null,
      type: null,
      price: null,
      quantity: null,
      quantityUnavailable: null,
      product: {
        id: null,
        fullName: null,
        code: null,
        ean: null,
        mainImage: {
          id: null,
          thumbnails: {
            format: null,
            mimeType: null,
            url: null,
          },
        },
      },
      clearanceItems: {
        expirationDate: null,
      },
    },
  }

  static productFilesAndImages = {
    id: null,
    code: null,
    ean: null,
    name: null,
    fullName: null,
    mainImage: SchemaUtil.file,
    images: SchemaUtil.file,
    files: SchemaUtil.file,
    quantityAvailable: null,
    quantityReservation: null,
    quantityStock: null,
  }

  static productBundle = {
    id: null,
    quantity: null,
    child: SchemaUtil.productFilesAndImages,
    parent: SchemaUtil.productFilesAndImages,
  }

  static voucher = {
    id: null,
    name: null,
    code: null,
    externalId: null,
    discountType: null,
    discountPercent: null,
    discountValue: null,
    maxUse: null,
    validFrom: null,
    validTo: null,
    isValid: null,
    isPayment: null,
    enabled: null,
    used: null,
  }

  static inventoryExport = {
    id: null,
    date: null,
    generated: null,
    created: null,
    depots: SchemaUtil.basic,
    suppliers: SchemaUtil.basic,
    producers: SchemaUtil.basic,
    url: null,
    files: SchemaUtil.file,
    approved: null,
    rejected: null,
    finished: null,
  }

  static bestseller = {
    amount: null,
    sales: {
      value: null,
      currency: {
        id: null,
      },
    },
    product: {
      id: null,
      name: null,
      fullName: null,
    },
  }

  static CarrierTariff = {
    id: null,
    const: null,
    name: null,
    cod: null,
    position: null,
  }

  static carrierUsage = {
    amount: null,
    carrier: {
      id: null,
      name: null,
      color: null,
      position: null,
      tariffs: SchemaUtil.CarrierTariff,
    },
  }

  static paymentSales = {
    amount: null,
    sales: {
      value: null,
      currency: SchemaUtil.currency,
    },
    payment: SchemaUtil.payment,
  }
}
