<div #formRow class="form-group" [ngClass]="{ 'mb-0': noMarginBottom }">
  <label *ngIf="label && label.length">
    {{ label | translate }}
    <info-tooltip
      *ngIf="tooltipLabel && tooltipLabel.length > 0"
      [label]="tooltipLabel"
      [icon]="tooltipIcon"
    ></info-tooltip>
  </label>
  <div class="input-group">
    <div class="input-group-prepend" *ngIf="icon && icon.length">
    </div>
    <textarea
      class="form-control"
      [cols]="cols"
      [rows]="rows"
      [placeholder]="(placeholder | translate) ?? ''"
      *ngIf="inputDisabled"
      disabled
      [class]="classesString"
      [ngModel]="model"
    ></textarea>
    <textarea
      class="form-control"
      #inputArea
      [cols]="cols"
      [rows]="rows"
      *ngIf="!inputDisabled"
      [class]="classesString"
      [ngModel]="model"
      [placeholder]="(placeholder | translate) ?? ''"
      (ngModelChange)="onNgModelChange($event)"
      (keyup)="onInputKeyPress($event)"
      [attr.data-track-category]="trackCategory"
      [attr.data-track-action]="trackAction"
      [attr.data-cy]="dataCyAttribute"
    ></textarea>
    <div class="input-group-btn" *ngIf="(btnLabel && btnLabel.length) || (btnIcon && btnIcon.length)">
      <btn [label]="btnLabel" [title]="btnLabel | translate" [classes]="btnClasses" [icon]="btnIcon" (onEvent)="onBtnClick($event)"></btn>
    </div>
  </div>
</div>
