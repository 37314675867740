import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { Depot, Supplier, Product, Currency, ProductMovePack, ProductMove } from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { NotificationService, CoreService } from '../../../services'
import { TranslateService } from '@ngx-translate/core'
import { StorageKeyService } from '../../../services/storage-key.service'
import { isObjectEmpty } from '../../../utils/misc.util'

@Component({
  selector: 'modal-stock-load',
  templateUrl: 'modal-stock-load.component.html',
  styleUrls: ['modal-stock-load.component.scss'],
})
export class ModalStockLoadComponent implements OnInit {
  @ViewChild('modal') public childModal: ModalContainerComponent
  @Input() public product: Product
  @Input() public suppliers: Supplier[] = []
  @Input() public depots: Depot[] = []
  @Input() public currencies: Currency[] = []
  @Input() public isUnloadingStock = false
  @Input() public currentStockItem: any
  @Input() public btnXs = false
  @Output() public result: EventEmitter<void> = new EventEmitter()
  public productMove: ProductMove = new ProductMove()
  loading = true
  keyEventSubscriptions: any = {}
  headerTitle: string
  headerBgClass: string
  bodyBgClass: string
  footerBgClass: string
  submitted = false

  constructor(
    private core: CoreService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private keyService: StorageKeyService
  ) {}

  ngOnInit() {
    if (this.isUnloadingStock) {
      this.headerTitle = 'out'
      this.headerBgClass = 'header-class bg_red'
      this.bodyBgClass = 'bg_red'
      this.footerBgClass = 'bg_red'
    } else if (!this.isUnloadingStock) {
      this.headerTitle = 'in'
      this.headerBgClass = 'header-class bg_green'
      this.bodyBgClass = 'bg_green'
      this.footerBgClass = 'bg_green'
    }
  }

  loadDepotsAndSuppliers(): void {
    // todo nacitat on show
    this.loading = true
    this.productMove.batch = ''
    this.productMove.expirationDate = ''
    if (
      (!this.productMove.supplier || this.productMove.supplier < 1) &&
      this.suppliers &&
      this.suppliers.length === 1
    ) {
      this.productMove.supplier = this.suppliers[0].id
    }
    const lastSupplier =
      this.core.services.storage.getSync(this.keyService.getStorageKeyForEntity('lastStockItemSupplier')) || null
    const doesLastSupplierExists = this.suppliers.map(s => s.id).filter(s => s === lastSupplier).length
    if (lastSupplier && doesLastSupplierExists) {
      this.productMove.supplier = lastSupplier
    }
    const lastDepot =
      this.core.services.storage.getSync(this.keyService.getStorageKeyForEntity('lastStockItemDepot')) || null
    const doesLastDepotExists = this.depots.map(d => d.id).filter(d => d === lastDepot).length
    if (lastDepot && doesLastDepotExists) {
      this.productMove.depotTo = lastDepot
      this.productMove.purchasePrice = 0
    }
    if ((!this.productMove.depotTo || this.productMove.depotTo < 1) && this.depots?.length === 1) {
      this.productMove.depotTo = this.depots[0].id
    }
    this.loading = false
    this.fillLastUsedValues()
  }

  private fillDepotValue() {
    const doesLastDepotExists = this.depots.map(d => d.id).filter(d => d === this.currentStockItem.depot.id).length
    if (doesLastDepotExists) {
      this.productMove.depotTo = this.currentStockItem.depot.id
    } else if ((!this.productMove.depotTo || this.productMove.depotTo < 1) && this.depots?.length === 1) {
      this.productMove.depotTo = this.depots[0].id
    }
  }

  private fillSupplierValue() {
    const doesLastSupplierExists = this.suppliers
      .map(s => s.id)
      .filter(s => s === this.currentStockItem.supplier.id).length
    if (doesLastSupplierExists) {
      this.productMove.supplier = this.currentStockItem.supplier.id
    } else if (
      (!this.productMove.supplier || this.productMove.supplier < 1) &&
      this.suppliers &&
      this.suppliers.length === 1
    ) {
      this.productMove.supplier = this.suppliers[0].id
    }
  }

  fillLastUsedValues(): void {
    if (this.currentStockItem) {
      if (this.currentStockItem.depot && this.currentStockItem.depot.id > 0) {
        this.fillDepotValue()
      }
      if (this.currentStockItem.supplier && this.currentStockItem.supplier.id > 0) {
        this.fillSupplierValue()
      }
      this.productMove.purchasePrice = this.currentStockItem.purchasePrice
      if (this.product.purchaseCurrency) {
        this.productMove.purchaseCurrency = this.product.purchaseCurrency.id
      }
      this.productMove.amount = 1
      this.productMove.batch = this.currentStockItem.batch
      if (this.currentStockItem.expirationDate?.length) {
        this.productMove.expirationDate = this.currentStockItem.expirationDate.substring(0, 10)
      }
      if (this.currentStockItem.position1?.length) {
        this.productMove.position1 = this.currentStockItem.position1
      }
      if (this.currentStockItem.position2?.length) {
        this.productMove.position2 = this.currentStockItem.position2
      }
      if (this.currentStockItem.position3?.length) {
        this.productMove.position3 = this.currentStockItem.position3
      }
    } else {
      this.productMove.amount = 1
      this.productMove.purchasePrice = 0
    }
    if (this.product.purchasePrice !== 0 && this.product.purchasePrice !== null && !this.currentStockItem) {
      this.productMove.purchasePrice = Number(this.product.purchasePrice.toFixed(3))
      if (this.product.purchaseCurrency) {
        this.productMove.purchaseCurrency = this.product.purchaseCurrency.id
      }
    }
    if (this.product && this.product.supplier && this.product.supplier.id > 0 && !this.productMove.supplier) {
      this.productMove.supplier = this.product.supplier.id
    }
  }

  async execute() {
    this.submitted = true
    if (!this.isUnloadingStock && (!this.productMove.depotTo || this.productMove.depotTo < 1)) {
      // alert('Vyberte sklad!')
      return
    }
    if (isNaN(Number(this.productMove.amount))) {
      alert(this.translateService.instant('modal-stock-load.wrong-quantity'))
      return
    }
    if (!this.isUnloadingStock && isNaN(Number(this.productMove.purchasePrice))) {
      alert(this.translateService.instant('modal-stock-load.wrong-price'))
      return
    }
    if (!this.isUnloadingStock && Number(this.productMove.amount) < 1) {
      alert(this.translateService.instant('modal-stock-load.no-store-in-minus'))
      return
    }
    if (
      this.isUnloadingStock &&
      this.currentStockItem &&
      this.currentStockItem.quantityStock < Number(this.productMove.amount)
    ) {
      alert(this.translateService.instant('modal-stock-load.out', { quantity: this.currentStockItem.quantityStock }))
      this.productMove.amount = this.currentStockItem.quantityStock
      return
    }
    if (!this.isUnloadingStock && !this.productMove.supplier) {
      // alert('Vyberte dodavatele!')
      return
    }
    this.loading = true
    const movePack: ProductMovePack = new ProductMovePack({
      type: { id: this.isUnloadingStock ? 'out' : 'in' },
      note: this.productMove.note,
      moves: [this.createProductMove()],
    })
    const result = await this.core.services.productMovePack.create(movePack)
    this.loading = false
    if (result) {
      this.result.emit()
      const result = this.isUnloadingStock ? 'alert.unloading.stock' : 'alert.loading.stock'
      this.notificationService.success(this.translateService.instant(result))
      this.onHideChildModal()
    } else {
      this.notificationService.error(this.translateService.instant('modal-stock-load.error'))
    }
  }

  createProductMove(): ProductMove {
    if (isNaN(Number(this.productMove.amount))) {
      alert(this.translateService.instant('modal-stock-load.wrong-quantity'))
      return
    }
    const productMove = new ProductMove({
      ...this.productMove,
      purchasePriceSum: Number(this.productMove.purchasePrice) * Number(this.productMove.amount),
      product: this.product,
    })
    if (this.currentStockItem) {
      // ;['product', 'depotFrom', 'depotTo', 'supplier', 'purchaseCurrency', 'purchaseCurrencyDate'].forEach(
      ;['purchaseCurrency', 'purchaseCurrencyDate'].forEach(
        // api not ok without product/depots ids..
        prop => delete productMove[prop]
      )
      if (this.isUnloadingStock) {
        productMove.depotFrom = this.currentStockItem.depot.id
      }
      productMove.productDepot = this.currentStockItem.id
    }
    return productMove
  }

  showChildModal(): void {
    this.keyEventSubscriptions.keyEsc = this.core.services.keyEvent.onKeyEsc
      .subscribe()
      .then(() => this.onHideChildModal())
    this.keyEventSubscriptions.scannedPositions = this.core.services.keyEvent.onScannedPositions
      .subscribe()
      .then(positions => {
        this.productMove.position1 = positions[0]
        this.productMove.position2 = positions[1]
        this.productMove.position3 = positions[2]
      })
    this.loadDepotsAndSuppliers()
    this.childModal?.showChildModal()
  }

  onHideChildModal(): void {
    this.childModal?.childModal?.hide() // modal closes itself on close-buttons click only
    if (isObjectEmpty(this.keyEventSubscriptions)) return

    for (const keyEvent in this.keyEventSubscriptions) {
      this.keyEventSubscriptions[keyEvent]?.unsubscribe()
      delete this.keyEventSubscriptions[keyEvent]
    }
    // console.log('modal-stock-load: subscription is cancelled')
  }

  getCurrency(currencyId) {
    return this.currencies.find(c => c.id === currencyId)
  }

  number(number: number) {
    return number
  }
  // public fillFormValue() {
  //   this.modalStockLoadForm.setValue({
  //     depotTo: this.stockOperation.depotTo || 0,
  //     supplier: this.stockOperation.supplier || 0,
  //     batch: this.stockOperation.batch || '',
  //     expirationDate: this.stockOperation.expirationDate || '',
  //     position1: this.stockOperation.position1 || '',
  //     position2: this.stockOperation.position2 || '',
  //     position3: this.stockOperation.position3 || '',
  //     amount: this.stockOperation.amount || 0,
  //     purchasePrice: this.stockOperation.purchasePrice || 0,
  //     purchaseCurrency: this.stockOperation.purchaseCurrency || '',
  //     purchaseCurrencyDate: this.stockOperation.purchaseCurrencyDate || '',
  //   })
  // }

  // public getFormValue() {
  //   this.stockOperation.depotTo = this.modalStockLoadForm.value.depotTo
  //   this.stockOperation.supplier = this.modalStockLoadForm.value.supplier
  //   this.stockOperation.batch = this.modalStockLoadForm.value.batch
  //   this.stockOperation.expirationDate = this.modalStockLoadForm.value.expirationDate
  //   this.stockOperation.position1 = this.modalStockLoadForm.value.position1
  //   this.stockOperation.position2 = this.modalStockLoadForm.value.position2
  //   this.stockOperation.position3 = this.modalStockLoadForm.value.position3
  //   this.stockOperation.amount = this.modalStockLoadForm.value.amount
  //   this.stockOperation.purchasePrice = this.modalStockLoadForm.value.purchasePrice
  //   this.stockOperation.purchaseCurrency = this.modalStockLoadForm.value.purchaseCurrency
  //   this.stockOperation.purchaseCurrencyDate = this.modalStockLoadForm.value.purchaseCurrencyDate
  // }

  // get formControls(): { [key: string]: AbstractControl } {
  //   return this.modalStockLoadForm.controls
  // }
}
