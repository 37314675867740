import { Component } from '@angular/core'
import { ProductPrice } from 'depoto-core/src/entities'
import { NotificationService } from '../../../services'
import { CoreService } from '../../../services'
import { ProductDetailService } from '../../../product-detail.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'product-advanced-prices-form',
  templateUrl: './product-advanced-prices-form.component.html',
})
export class ProductAdvancedPricesFormComponent {
  public advancedPrice = new ProductPrice()

  constructor(
    private notificationService: NotificationService,
    public productDetailService: ProductDetailService,
    private core: CoreService,
    private translateService: TranslateService
  ) {}

  async createProductPrice() {
    if (this.checkProductPriceValidity(this.advancedPrice)) {
      const res = await this.core.services.productPrice.create(this.advancedPrice)
      if (res && res.id) {
        this.advancedPrice = new ProductPrice()
      }
      this.productDetailService.refreshProduct()
    }
  }

  async updateProductPrice(productPrice: ProductPrice) {
    if (this.checkProductPriceValidity(productPrice)) {
      await this.core.services.productPrice.update(productPrice)
      this.productDetailService.refreshProduct()
    }
  }

  private hasOverlappingPrice(child, productPrice) {
    const productPriceStart = new Date(productPrice.dateFrom).getTime()
    const productPriceEnd = new Date(productPrice.dateTo).getTime()
    child.advancedPrices.forEach(p => {
      const childPriceStart = new Date(p.dateFrom.substr(0, 10)).getTime()
      const childPriceEnd = new Date(p.dateTo.substr(0, 10)).getTime()
      if (productPriceStart <= childPriceStart && productPriceEnd >= childPriceEnd) {
        this.notificationService.error(
          // `Chyba při vytváření ceny na variantě! Varianta ${child.fullName} již má nastavenou cenovou akci, zahrnující společný časový úsek (${p.dateFrom} - ${p.dateTo}).`,
          this.translateService.instant('product-advanced-prices.overlapping-price', {
            // TODO !!!!!!!
            name: child.name,
            dateFrom: p.dateFrom,
            dateTo: p.dateTo,
          }),
          null,
          true
        )
        return true
      }
    })
    return false
  }

  async updateProductChildrenWithPrice(productPrice: ProductPrice) {
    if (this.checkProductPriceValidity(productPrice)) {
      const promises = this.productDetailService.product.children
        .filter(children => !this.hasOverlappingPrice(children, productPrice))
        .map(children => new ProductPrice({ ...productPrice, product: children }))
        .map(childrenPrice => this.core.services.productPrice.create(childrenPrice))
      await Promise.all(promises)
      this.productDetailService.refreshProduct()
    }
  }

  async deleteProductPrice(productPrice: ProductPrice) {
    await this.core.services.productPrice.delete(productPrice)
    this.productDetailService.refreshProduct()
  }

  checkProductPriceValidity(productPrice: ProductPrice): boolean {
    productPrice.product = this.productDetailService.product
    if (!productPrice.sellPrice) {
      alert(this.translateService.instant('product-advanced-prices.enter-price'))
      return false
    }
    productPrice.sellPrice = Number(productPrice.sellPrice)
    if (!this.hasAvailabilityOrDatesFilled(productPrice)) {
      alert(this.translateService.instant('product-advanced-prices.enter-quantity-or-interval'))
      return false
    }
    productPrice.available = Number(productPrice.available)
    return true
  }

  private hasAvailabilityOrDatesFilled(productPrice: ProductPrice) {
    return (
      (productPrice.available && productPrice.available > 0) ||
      (productPrice.dateFrom &&
        productPrice.dateTo &&
        productPrice.dateFrom.length > 0 &&
        productPrice.dateTo.length > 0)
    )
  }
}
