<modal-container
  #modal
  [headerTitle]="exportType + '-create'"
  [headerIconClass]="'far-file-' + selectedExportFormat === 'pdf' ? 'pdf' : 'excel'"
>
  <ng-container class="modal-button-show">
    <btn
      [label]="'create'"
      [title]="'create' | translate"
      [icon]="'far-plus'"
      [classes]="['btn-first', 'float-right']"
      [isLoading]="loading"
      [spinnerClassesString]="'spinner-white'"
      (onEvent)="showChildModal()"
      [attr.data-cy]="dataCyAttribute">
    </btn>
  </ng-container>
  <ng-container class="modal-body-content">
    <div class="card">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
      <div class="row">
        <div class="col-sm-6" *ngIf="exportType === 'stock-exports'">
          <form-row-select
            [label]="'stateTypeToDate'"
            [icon]="null"
            [options]="stockExportDateTypes"
            [(model)]="stockExportDateType"
            [dataCyAttribute]="'date-from'"
          ></form-row-select>
        </div>
        <div class="col-sm-{{ exportType !== 'inventory-exports' ? '6' : '12' }}">
          <form-row-input
            *ngIf="exportType !== 'stock-exports' || (exportType === 'stock-exports' && stockExportDateType === 'date')"
            [label]="exportType !== 'inventory-exports' && exportType !== 'stock-exports' ? 'date.from' : 'date'"
            [inputType]="'date'"
            [icon]="null"
            [(model)]="dateFrom"
            data-cy="date-from"
          ></form-row-input>
        </div>
        <div class="col-sm-6" *ngIf="exportType !== 'inventory-exports' && exportType !== 'stock-exports'">
          <form-row-input [label]="'date.to'" [inputType]="'date'" [icon]="null" [(model)]="dateTo"></form-row-input>
        </div>
        <div class="col-sm-6" *ngIf="exportType === 'order-exports'">
          <form-row-select
            [label]="'order.status'"
            [icon]="null"
            [options]="orderStatusTypes"
            [(model)]="selectedOrderStatus"
            [dataCyAttribute]="'export-order-status-select'"
          ></form-row-select>
        </div>
        <div class="col-sm-6" *ngIf="exportType !== 'sale-exports' && exportType !== 'order-exports'">
          <form-row-select-multiple
            [selectedValues]="selectedDepots"
            [label]="'reports.depots'"
            [options]="depots"
            (selectedValuesChange)="selectValue($event, 'depots')"
            [dataCyAttribute]="'depots'"
          ></form-row-select-multiple>
        </div>
        <div class="col-sm-6" *ngIf="exportType !== 'inventory-exports' && exportType !== 'stock-exports'">
          <form-row-select-multiple
            [selectedValues]="selectedCheckouts"
            [label]="'checkouts.select'"
            [options]="checkouts"
            (selectedValuesChange)="selectValue($event, 'checkouts')"
            [dataCyAttribute]="'checkouts'"
          ></form-row-select-multiple>
        </div>
        <div class="col-sm-6" *ngIf="exportType !== 'order-exports'">
          <form-row-select-multiple
            [selectedValues]="selectedSuppliers"
            [label]="'products.suppliers'"
            [options]="suppliers"
            (selectedValuesChange)="selectValue($event, 'suppliers')"
            data-cy="suppliers"
          ></form-row-select-multiple>
        </div>
        <div class="col-sm-6" *ngIf="exportType !== 'order-exports'">
          <form-row-select-multiple
            [selectedValues]="selectedProducers"
            [label]="'brands'"
            [options]="producers"
            (selectedValuesChange)="selectValue($event, 'producers')"
            data-cy="producers"
          ></form-row-select-multiple>
        </div>
        <div class="col-sm-6" *ngIf="exportType !== 'inventory-exports'">
          <form-row-select
            [label]="'format.type'"
            [icon]="null"
            [options]="exportFormatTypes"
            [(model)]="selectedExportFormat"
            [dataCyAttribute]="'export-format-type-select'"
          ></form-row-select>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content">
    <btn
      [label]="'save'"
      [title]="'save' | translate"
      [icon]="selectedExportFormat === 'pdf' ? 'far-file-pdf' : 'far-file-excel'"
      [classes]="['btn-first']"
      [btnDisabled]="!dateFrom && exportType !== 'stock-exports'"
      [isLoading]="loading"
      (onEvent)="createPrintExport()"
      data-cy="added-inventory"
    ></btn>
  </ng-container>
</modal-container>
