import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { NotificationService } from '../../services'
import { CoreService } from '../../services'
import { Order, Address } from 'depoto-core/src/entities'
import { SchemaUtil, historyBack, getObjectDiff } from '../../utils'
import { BaseDetail } from '../_base-detail/base-detail'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { OrderDetailService } from '../../services/order-detail.service'
import { TranslateService } from '@ngx-translate/core'
import { FmtDatePipe } from '../../pipes'

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['order.component.scss'],
  providers: [FmtDatePipe],
})
export class OrderComponent extends BaseDetail implements OnInit, CheckDataChanges {
  address: Address
  pristineOrder: Order

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public notificationService: NotificationService,
    public translateService: TranslateService,
    public core: CoreService,
    public orderDetailService: OrderDetailService,
    private readonly fmtDatePipe: FmtDatePipe
  ) {
    super(route, router, core, translateService)
  }

  async ngOnInit() {
    this.orderDetailService.loading = true

    this.orderDetailService.getCoreEntityLists()
    await this.reloadViewDataFromRoute()

    const id = this.route.snapshot.params['id']
    if (id === 0 || isNaN(id)) {
      this.orderDetailService.canCreateOrEdit = this.orderDetailService.canCreateOrEditNotes =
        this.core.services.user.hasRole('ROLE_ORDER_CREATE') ||
        this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
        this.core.services.user.hasRole('ROLE_ADMIN')

      this.orderDetailService.activeLink = 'customer'
      await this.router.navigate(['/order/customer'])
    } else {
      this.orderDetailService.activeLink = ''
    }
    this.orderDetailService.canEditPrice =
      this.core.services.user.hasRole('ROLE_ORDER_ITEM_PRICE_UPDATE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')

    // todo check
    // let's correct pollibly wrong address data
    // console.log(this.order.shippingAddress.country)
    // console.log(this.order.shippingAddress.state)
    if (this.order.shippingAddress?.country !== 'US' && this.order.shippingAddress?.country !== 'CA') {
      this.order.shippingAddress.state = ''
    }
    // let's correct long dates data (forms are unable to save seconds)
    // console.log(this.order.dateExpedition)
    // console.log(this.order.dateTax)
    // console.log(this.order.dateDue)
    this.order.dateExpedition = this.fmtDatePipe.transform(this.order.dateExpedition, 'yyyy-MM-ddTHH:mm')
    this.order.dateTax = this.fmtDatePipe.transform(this.order.dateTax, 'yyyy-MM-dd')
    this.order.dateDue = this.fmtDatePipe.transform(this.order.dateDue, 'yyyy-MM-dd')

    this.pristineOrder = structuredClone(this.orderDetailService.order)
  }

  hasUnsavedDataChanges(): boolean {
    const diff = getObjectDiff(structuredClone(this.orderDetailService.order), this.pristineOrder)
    if (!diff) {
      return
    }
    return !!diff.filter(d => d !== 'files').length
  }

  private async reloadViewDataFromRoute() {
    const id = Number(this.router.url.split('/')[2])
    if (!id || isNaN(id)) {
      this.orderDetailService.isCreating = true
      this.orderDetailService.activeLink = 'customer'
      this.orderDetailService.order = new Order({
        enabled: true,
        invoiceAddress: new Address(),
        shippingAddress: new Address(),
        currency: this.orderDetailService.currencies.find(c => c.id === 'CZK'),
      })
      this.orderDetailService.loading = false
      return
    }
    if (this.route.snapshot.routeConfig.path.includes('product-variant-create') && !isNaN(id) && id) {
      this.orderDetailService.isCreating = true
      this.orderDetailService.activeLink = ''
      this.orderDetailService.order = new Order({ enabled: true })
      await this.orderDetailService.refreshData()
      this.pristineOrder = structuredClone(this.orderDetailService.order)
    } else {
      this.orderDetailService.isCreating = false
      this.orderDetailService.activeLink = ''
      await this.orderDetailService.refreshData(id)
      this.pristineOrder = structuredClone(this.orderDetailService.order)
    }
  }

  public async confirmOrder() {
    if (this.hasUnsavedDataChanges()) {
      if (!confirm(this.translateService.instant('unsaved-changes.confirmation'))) return
    }
    if (!this.order || (this.order && !this.order.checkout)) {
      alert(this.translateService.instant('order.is.not.assigned.to.checkout'))
      return
    }
    if (
      !confirm(this.translateService.instant('order.reservation.confirmation', { id: this.order.reservationNumber }))
    ) {
      return
    }
    try {
      const result = await this.core.services.order.updateOrderPart({
        id: this.orderDetailService.order.id,
        status: {
          id: 'bill',
          name: 'Doklad',
        },
      })
      if (+result?.id) {
        this.notificationService.success(this.translateService.instant('alert.order.closed'))
        this.orderDetailService.isEditedFiles = false
        this.orderDetailService.isEditedAddress = false
        this.orderDetailService.canCreateOrEdit = false
        await this.orderDetailService.refreshData(this.order.id)
        this.pristineOrder = structuredClone(this.orderDetailService.order)
        return this.router.navigate(['/order/', this.order.id])
      }
    } catch (err) {
      console.error(err)
    }
  }

  public async deleteReservation() {
    if (
      !confirm(
        this.translateService.instant('order.reservation.delete-confirmation', { id: this.order.reservationNumber })
      )
    ) {
      return
    }
    try {
      await this.core.services.order.deleteReservation(this.order).then(() => {
        this.notificationService.success(this.translateService.instant('alert.reservation.deleted'))
        this.router.navigate(['/orders'], { replaceUrl: true })
      })
    } catch (err) {
      console.error(err)
    }
  }
  public downloadInvoiceUrl(): void {
    this.core.services.download.downloadDocumentAndShowInNewWindow(this.order.invoiceUrl)
  }

  public downloadReservationUrl(): void {
    this.core.services.download.downloadDocumentAndShowInNewWindow(this.order.reservationUrl)
  }

  private hasPaymentMethod(): boolean {
    return this.order.paymentItems && this.order.paymentItems.length > 0
  }

  private hasIncorrectPaymentMethod(): boolean {
    return (
      this.order.paymentItems &&
      this.order.paymentItems.length > 0 &&
      (!this.order.paymentItems[0].payment ||
        (this.order.paymentItems[0].payment && !this.order.paymentItems[0].payment.id))
    )
  }

  private hasAllNecessaryProps(): boolean {
    if (
      !this.orderDetailService.carriers ||
      (this.orderDetailService.carriers && this.orderDetailService.carriers.length === 0)
    ) {
      alert(this.translateService.instant('order.activate.at.least.one.carrier'))
      return false
    }
    if (!this.order.checkout.id) {
      alert(this.translateService.instant('order.activate.at.least.one.carrier'))

      return false
    }
    let overridePaymentItemsCheck = false
    if (!this.hasPaymentMethod()) {
      overridePaymentItemsCheck = confirm(this.translateService.instant('order.without.payments.confirmation'))
      if (!overridePaymentItemsCheck) {
        return false
      }
    }
    if (!overridePaymentItemsCheck && this.hasIncorrectPaymentMethod()) {
      alert(this.translateService.instant('order.select.payment-method'))
      return false
    }
    if (!this.order.carrier.id) {
      alert(this.translateService.instant('order.select.carrier'))
      return false
    }
    return true
  }

  public async createOrder() {
    if (this.orderDetailService.orderForm.invalid) {
      return
    }
    if (
      this.orderDetailService.order.paymentItems?.length &&
      this.orderDetailService.order.paymentItems.some(pI => !pI.amount && pI.amount !== 0)
    ) {
      alert(this.translateService.instant('order.select.price'))
      return
    }
    this.orderDetailService.loading = true
    if (!this.hasAllNecessaryProps()) {
      this.orderDetailService.loading = false
      return
    }
    if (this.order.invoiceAddress?.country) {
      this.orderDetailService.order.invoiceAddress.id = await this.core.services.address
        .create(
          { ...this.order.invoiceAddress, isBilling: true, isStored: false },
          this.order.customer ? this.order.customer : null
        )
        .then(a => a.id)
      if (this.orderDetailService.isShippingAddressSameAsInvoice) {
        this.orderDetailService.order.shippingAddress = this.order.invoiceAddress
      }
    }
    if (this.order.shippingAddress?.country && !this.orderDetailService.isShippingAddressSameAsInvoice) {
      this.orderDetailService.order.shippingAddress.id = await this.core.services.address
        .create(
          { ...this.order.shippingAddress, isBilling: false, isStored: false },
          this.order.customer ? this.order.customer : null
        )
        .then(a => a.id)
    }
    try {
      this.orderDetailService.getFormValue()
      // todo: this is hotfix to prevent duplicate reservationNumbers. resolve if we need to post this in core.order.create() ...
      if (this.order.reservationNumber > 0) {
        console.log('ERROR order.reservationNumber present on create: ', this.order.reservationNumber)
        this.order.reservationNumber = 0
      }
      const result = await this.core.services.order.create(this.order)
      if (+result?.id) {
        this.notificationService.success(this.translateService.instant('alert.order.created'))
        this.orderDetailService.order.id = result.id
        try {
          if (this.order.files?.length) {
            await this.connectUploadedFilesToOrder()
            await this.reloadViewDataFromRoute()
          }
        } catch (e) {
          this.notificationService.error(
            this.translateService.instant('alert.order.files-not-connected') + '\n ' + e.message
          )
        }
        this.orderDetailService.order = new Order(result)
      }
      this.pristineOrder = structuredClone(this.orderDetailService.order)
    } catch (err) {
      console.error(err)
    } finally {
      this.orderDetailService.loading = false
      this.orderDetailService.activeLink = ''
      await this.router.navigate(['/order', this.order.id], { replaceUrl: true })
    }
  }

  public async updateOrder() {
    if (this.orderDetailService.orderForm.invalid) {
      return
    }
    if (
      this.orderDetailService.order.paymentItems?.length &&
      this.orderDetailService.order.paymentItems.some(pI => !pI.amount && pI.amount !== 0)
    ) {
      alert(this.translateService.instant('order.select.price'))
      return
    }
    if (this.orderDetailService.isEditedFiles) {
      await this.connectUploadedFilesToOrder()
    }
    if (this.orderDetailService.isEditedAddress) {
      await this.updateAddress()
    }
    try {
      const keepReservation = this.orderDetailService.order.status.id === 'reservation'
      this.orderDetailService.getFormValue()
      console.log(this.order.priority)
      const result = await this.core.services.order.update(
        this.order,
        keepReservation,
        undefined,
        this.orderDetailService.updateProcessStatus,
        SchemaUtil.order
      )
      this.orderDetailService.canCreateOrEdit = !!keepReservation
      this.orderDetailService.isEditedFiles = false
      this.orderDetailService.isEditedTags = false
      this.orderDetailService.isEditedAddress = false
      if (+result?.id) {
        await this.orderDetailService.refreshData(this.order.id)
        this.orderDetailService.sortOrderItems()
        this.pristineOrder = structuredClone(this.orderDetailService.order)
        this.notificationService.success(this.translateService.instant('alert.order.saved'))
      }
    } catch (err) {
      console.error(err)
    }
  }

  private async updateAddress() {
    if (this.order.invoiceAddress?.id > 0) {
      await this.core.services.address.update(
        this.order.invoiceAddress,
        this.order.customer ? this.order.customer : null
      )
    } else if (this.order.invoiceAddress?.country) {
      this.order.invoiceAddress.id = await this.core.services.address
        .create(this.order.invoiceAddress, this.order.customer ? this.order.customer : null)
        .then(a => a.id)
    }
    if (!this.orderDetailService.isShippingAddressSameAsInvoice) {
      if (this.order.shippingAddress?.country) {
        if (this.order.shippingAddress?.id === null) {
          this.orderDetailService.order.shippingAddress.id = await this.core.services.address
            .create(this.order.shippingAddress, this.order.customer ? this.order.customer : null)
            .then(a => a.id)
        } else {
          await this.core.services.address.update(
            this.order.shippingAddress,
            this.order.customer ? this.order.customer : null
          )
        }
      } else {
        this.orderDetailService.order.shippingAddress = null
      }
    }
  }

  private async connectUploadedFilesToOrder() {
    if (!this.order?.files?.length) {
      return
    }
    try {
      const promises = this.order.files.map(file => this.core.services.file.update(file, null, this.order.id))
      await Promise.all(promises)
      this.orderDetailService.isEditedFiles = false
    } catch (error) {
      console.error('Error in function connectUploadedFilesToOrder. Details: ', error.message)
    }
  }

  public triggerBackBtn() {
    if (this.orderDetailService.isCreating) {
      if (this.orderDetailService.activeLink === 'customer') {
        this.orderDetailService.activeLink = ''
        this.router.navigate(['/orders'])
      } else if (this.orderDetailService.activeLink === 'address') {
        this.orderDetailService.activeLink = 'customer'
        this.router.navigate(['/order/customer'])
      } else if (this.orderDetailService.activeLink === 'payment-info') {
        this.orderDetailService.activeLink = 'address'
        this.router.navigate(['/order/address'])
      } else if (this.orderDetailService.activeLink === '') {
        this.orderDetailService.activeLink = 'payment-info'
        this.router.navigate(['/order/payment-info'])
      }
    } else return historyBack()
  }

  public async triggerNextStep() {
    if (this.orderDetailService.activeLink === 'customer') {
      this.orderDetailService.activeLink = 'address'
      await this.router.navigate(['/order/address'], { replaceUrl: true })
    } else if (this.orderDetailService.activeLink === 'address') {
      // Check if any address is filled and correctly(has country)
      if (
        (!this.orderDetailService.order.invoiceAddress?.country?.length &&
          this.isAddressFilled(this.orderDetailService.order.invoiceAddress)) ||
        (!this.orderDetailService.order.shippingAddress?.country?.length &&
          this.isAddressFilled(this.orderDetailService.order.shippingAddress))
      ) {
        alert(this.translateService.instant('order-address-form.null-country.error'))
        return
      }
      if (!this.orderDetailService.isShippingAddressSameAsInvoice && !this.orderDetailService.order.shippingAddress) {
        this.orderDetailService.isShippingAddressSameAsInvoice = true
      }
      // route if ok
      this.orderDetailService.activeLink = 'payment-info'
      await this.router.navigate(['/order/payment-info'], { replaceUrl: true })
    } else if (this.orderDetailService.activeLink === 'payment-info') {
      this.orderDetailService.activeLink = ''
      await this.router.navigate(['/order'], { replaceUrl: true })
    }
  }

  isAddressFilled(address) {
    return Object.values(address).some(value => !!value)
  }

  get order() {
    return this.orderDetailService.order
  }

  set order(value) {
    this.orderDetailService.order = value
  }

  get canCreateOrEdit() {
    return this.orderDetailService.canCreateOrEdit
  }

  set canCreateOrEdit(value) {
    this.orderDetailService.canCreateOrEdit = value
  }
}
