import { Component, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CoreService, NotificationService } from '../../services'
import { TranslateService } from '@ngx-translate/core'
import { ListingItem } from 'depoto-core/src/models'
import {
  Currency,
  Depot,
  File,
  ProductMovePack,
  PurchaseOrder,
  PurchaseOrderItem,
  Supplier,
  Tag,
} from 'depoto-core/src/entities'
import {
  PurchaseOrderItemPlain,
  ModalStockOperationCardPurchaseComponent,
} from './components/modal-stock-operation-card-purchase/modal-stock-operation-card-purchase.component'
import { BaseListing } from '../_base-listing/base-listing'
import { FmtDatePipe } from '../../pipes'
import { SchemaUtil, historyBack, getObjectDiff } from '../../utils'
import { productMovePacksPurchaseOrderColumns } from '../../utils/definitions.util'
import { isEqual as areDeeplyEqual } from 'lodash-es'

type ProductListItemType = Omit<PurchaseOrderItemPlain, 'currency'> & {
  id: number
  purchasePriceAll: number
  externalId?: string
  currency: string | Currency
}
@Component({
  selector: 'app-product-purchase-order-creation',
  templateUrl: './product-purchase-order-creation.component.html',
  styleUrls: ['./product-purchase-order-creation.component.scss'],
  providers: [FmtDatePipe],
})
export class ProductPurchaseOrderCreationComponent extends BaseListing {
  @ViewChild(ModalStockOperationCardPurchaseComponent) modalStockOperation: ModalStockOperationCardPurchaseComponent
  depots: Depot[] = []
  suppliers: Supplier[] = []
  tags: Tag[] = []
  loading = true
  reloading = false
  currentPage: number
  endPage: number
  totalItems = 0
  items: Array<PurchaseOrderItem>
  isCreationModeEnabled = true
  isEditedFiles = false
  purchaseOrder: PurchaseOrder
  purchaseOrderInfo: Partial<PurchaseOrderInfo> = {}
  productList: Array<ProductListItemType> = []
  productMovePacks: ProductMovePack[] = []
  listingColumns: ListingItem[] = productMovePacksPurchaseOrderColumns
  storageKey: { type: string; key: string }
  sortProp = 'created'
  sortDir = 'desc'
  protected areFilesUploading = false
  private readonly purchaseOrderId: number | string = ''
  private pristinePurchase: any

  constructor(
    protected core: CoreService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly fmtDatePipe: FmtDatePipe,
    private readonly translateService: TranslateService,
    private readonly notificationService: NotificationService
  ) {
    super(core)
    this.purchaseOrderId = +this.route.snapshot.params.id
    const selectedProducts = this.router.getCurrentNavigation()?.extras?.state?.selectedProducts
    this.depots = this.core.lists.depots
    this.suppliers = this.core.lists.suppliers
    this.tags = this.core.lists.tags.filter(tag => tag.type === 'purchase_order')
    if (this.purchaseOrderId) {
      this.fetch(this.purchaseOrderId)
      this.isCreationModeEnabled = false
      this.getStorageKey()
      this.loadSorting('productMovePacksPurchaseOrder')
      if (!this.isCreationModeEnabled) this.filter(1)
    } else if (selectedProducts) {
      this.purchaseOrder = new PurchaseOrder()
      this.productList.push(...selectedProducts)
      this.purchaseOrderInfo.depot = this.router.getCurrentNavigation().extras.state.depot
      this.pristinePurchase = this.takePurchaseSnapshot()
      this.loading = false
    } else {
      this.purchaseOrder = new PurchaseOrder()
      this.pristinePurchase = this.takePurchaseSnapshot()
      this.loading = false
    }
  }
  hasUnsavedDataChanges(): boolean {
    // console.log('diff', getObjectDiff(this.takePurchaseSnapshot(), this.pristinePurchase))
    // the line above returns the different properties' names if the objects aren't equal
    return !areDeeplyEqual(this.takePurchaseSnapshot(), this.pristinePurchase)
  }

  goBack(): void {
    historyBack()
  }

  public async fetch(id: number) {
    this.loading = true
    this.purchaseOrder = await this.core.services.purchase.getById(id, SchemaUtil.purchaseOrder)
    this.purchaseOrderInfo = {
      ...this.purchaseOrder,
      supplier: this.purchaseOrder.supplier.id,
      depot: this.purchaseOrder.depot.id,
      createdBy: this.purchaseOrder.createdBy.name,
      created: this.fmtDatePipe.transform(this.purchaseOrder.created, 'yyyy-MM-dd'),
      dateEstimatedArrival: this.fmtDatePipe.transform(this.purchaseOrder.dateEstimatedArrival, 'yyyy-MM-dd'),
      tags: this.purchaseOrder.tags?.map(tag => tag.id),
    }
    this.productList = this.purchaseOrder.items.map((value: PurchaseOrderItem) => {
      return {
        id: value.id,
        purchasePrice: value.purchasePrice,
        currency: value.currency.id,
        quantity: value.quantity,
        quantityIn: value.quantityIn,
        note: value.note,
        purchasePriceAll: value.quantity * value.purchasePrice,
        // destruction is not used because we can't send extra properties to the server
        product: value.product.id,
        fullName: value.product.fullName,
        mainImage: value.product.mainImage,
        code: value.product.code,
        ean: value.product.ean,
      }
    })
    this.pristinePurchase = this.takePurchaseSnapshot()
    this.loading = false
  }

  getThumbUrl(image: File): string {
    return image?.thumbnails.filter(t => t.format === 'w135wp')[0]?.url
  }
  public addProduct(item: ProductListItemType): void {
    this.productList.push({ ...item, purchasePriceAll: item.quantity * item.purchasePrice })
  }

  public async create(): Promise<void> {
    this.loading = true
    if (this.isCreationModeEnabled) {
      const result = await this.core.services.purchase.create(this.takePurchaseSnapshot(), { id: null })
      this.notificationService.success(this.translateService.instant('purchase-order.create-success'))
      if (result && result.id > 0) {
        if (this.purchaseOrder.files?.length) {
          this.purchaseOrder.id = result.id
          await this.connectUploadedFilesToOrder()
        } else {
          this.purchaseOrder = new PurchaseOrder(result)
        }
        this.pristinePurchase = this.takePurchaseSnapshot()
        await this.router.navigate(['/purchase-order-creation', this.purchaseOrder.id], { replaceUrl: true })
      }
    } else {
      if (this.isEditedFiles) {
        await this.connectUploadedFilesToOrder()
      }
      const result = await this.core.services.purchase.update(this.takePurchaseSnapshot())
      this.isEditedFiles = false
      if (result && result.id > 0) {
        this.notificationService.success(this.translateService.instant('purchase-order.update-success'))
        await this.fetch(this.purchaseOrder.id)
        this.pristinePurchase = this.takePurchaseSnapshot()
        this.isEditedFiles = false
      }
    }
    this.loading = false
  }

  fileAdded(file: File) {
    if (this.purchaseOrder.id) {
      // save with order's id
      try {
        this.core.services.file
          .update(file, null, null, this.purchaseOrder.id, null, null, SchemaUtil.fileExtra)
          .then(createdFile => {
            this.purchaseOrder.files.push(createdFile)
          })
        this.isEditedFiles = false
      } catch (error) {
        console.error('Error in function connectUploadedFilesToOrder. Details: ', error.message)
      }
    } else {
      // add file to not created order
      this.purchaseOrder.files.push(file)
    }
  }

  private takePurchaseSnapshot() {
    const common = {
      ...this.purchaseOrderInfo,
      // productList may not be flat, let's flatten it
      items: this.productList.map(item => {
        return {
          product: item.product,
          quantity: item.quantity,
          purchasePrice: item.purchasePrice,
          currency: typeof item.currency === 'string' ? item.currency : item.currency.id,
          note: item.note || '',
          externalId: item.externalId || '',
        }
      }),
    }
    return this.isCreationModeEnabled ? { ...common } : { ...common, id: this.purchaseOrderId }
  }

  public edit(item: ProductListItemType) {
    const index = this.productList.findIndex(value => value.product == item.product)
    this.productList[index] = { ...item, purchasePriceAll: item.quantity * item.purchasePrice }
  }

  public async remove(item: ProductListItemType) {
    if (!confirm(this.translateService.instant('purchase-order.product.delete-confirm', { id: item.id }))) {
      return
    }
    if (item.id) {
      await this.core.services.purchase
        .deletePurchaseOrderItem(item.id)
        .then(() => {
          this.removeProductFromList(item)
          this.notificationService.success(this.translateService.instant('purchase-order.product.delete-success'))
        })
        .catch(err => {
          console.error(err)
          this.notificationService.error(this.translateService.instant('purchase-order.product.delete-error'))
        })
    } else {
      this.removeProductFromList(item)
    }
  }

  private removeProductFromList(item: ProductListItemType): void {
    this.productList = this.productList.filter(value => value.product !== item.product)
  }

  public async deletePurchaseOrder() {
    if (!confirm(this.translateService.instant('purchase-order.delete-confirm', { id: this.purchaseOrder.number }))) {
      return
    }
    if (this.purchaseOrder.files.length) {
      for (const file of this.purchaseOrder.files) {
        await this.core.services.file.delete({ id: file.id })
      }
    }
    await this.core.services.purchase
      .deletePurchaseOrder(this.purchaseOrderId)
      .then(() => {
        this.pristinePurchase = this.takePurchaseSnapshot()
        this.notificationService.success(this.translateService.instant('purchase-order.delete-success'))
      })
      .catch(() => this.notificationService.error(this.translateService.instant('purchase-order.delete-error')))
    this.router.navigate(['/purchase-orders'], { replaceUrl: true })
  }

  filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false): void {
    this.currentPage = pageNo

    this.loading = true
    if (!concat) this.reloading = true

    this.core.services.productMovePack
      .getList(
        {
          page: pageNo,
          sort: sortProp,
          direction: sortDir,
          filters: {
            type: 'in',
            purchaseOrder: this.purchaseOrderId,
          },
        },
        SchemaUtil.productMovePackList
      )
      .then(result => {
        this.loading = false
        this.reloading = false
        if (!concat) {
          this.productMovePacks = result.items
        } else {
          result.items.forEach(movePack => {
            this.productMovePacks.push(new ProductMovePack(movePack))
          })
        }
        this.totalItems = result.paginator ? result.paginator.totalCount : 0
        this.endPage = result.paginator ? result.paginator.endPage : 0
        this.currentPage = pageNo + 1
      })
  }

  callAction(event: { action: string; item: any }): void {
    this[event.action](event.item)
  }

  private async connectUploadedFilesToOrder() {
    if (!this.purchaseOrder.files || (this.purchaseOrder.files && this.purchaseOrder.files.length === 0)) {
      return
    }
    try {
      const promises = this.purchaseOrder.files.map(file => {
        this.core.services.file
          .update(file, null, null, this.purchaseOrder.id, null, null, SchemaUtil.fileExtra)
          .then(r => {
            this.purchaseOrder.files.push(r)
          })
      })
      await Promise.all(promises)
      this.isEditedFiles = false
    } catch (error) {
      console.error('Error in function connectUploadedFilesToOrder. Details: ', error.message)
    }
  }
}

type PurchaseOrderInfo = {
  supplier: number
  depot: number
  note: string
  privateNote: string
  dateEstimatedArrival: string
  tags: Array<number>
  reference: string
  externalId: string
  externalReference: string
  supplierReference: string
  createdBy: string
  created: string
}
