<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="paymentForm" (ngSubmit)="isCreating ? createPayment() : updatePayment()" *ngIf="payment">
    <page-header
      [icon]="'far-credit-card'"
      [title]="isCreating ? 'payment.new' : 'payment'"
      [appendNoTranslationTitlePart]="payment ? payment.name : ''"
    >
      <ng-container class="header-button">
        <btn
          [label]="'save'"
          [title]="'save' | translate"
          [classes]="['btn-first', 'float-right', isCreating ? '' : 'ms-2']"
          [btnDisabled]="loading"
          [icon]="'far-check'"
          [btnType]="'submit'"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-white'"
          [dataCyAttribute]="'create-payment'"
        ></btn>
        <btn
          *ngIf="!isCreating"
          [label]="'delete'"
          [title]="'delete' | translate"
          [classes]="['btn-first-red', 'float-right']"
          [icon]="'far-trash-alt'"
          (onEvent)="deletePayment()"
          [dataCyAttribute]="'delete-payment'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <section class="content">
      <div class="card">
        <div class="row">
          <div class="col-sm-6">
            <form-row-input
              [formGroup]="paymentForm"
              formControlName="name"
              [required]="paymentForm.get('name').errors !== null && paymentForm.get('name').errors.required"
              [requiredCssClass]="submitted && formControls.name.errors"
              [label]="'payment.name'"
              [dataCyAttribute]="'name'"
            ></form-row-input>
            <form-row-select
              [label]="'payment.type'"
              [formGroup]="paymentForm"
              formControlName="type"
              [options]="paymentTypes"
              [dataCyAttribute]="'payment-type-select'"
              *ngIf="payment && paymentTypes.length > 0"
            ></form-row-select>
            <form-row-select
              [label]="'payment.dispatchUnpaid'"
              [formGroup]="paymentForm"
              formControlName="dispatchUnpaid"
              [options]="dispatchUnpaidOptions"
              [dataCyAttribute]="'payment-dispatchUnpaid'"
            ></form-row-select>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
