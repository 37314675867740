<modal-container
  #modal
  [headerTitle]="'stock.positions.update'"
  [headerIconClass]="'fa-box'"
  [ignoreBackdropClick]="true"
  (hide)="onHideChildModal()"
>
  <ng-container class="modal-button-show">
    <btn
      [icon]="'far-pencil'"
      [title]="'edit' | translate"
      [classes]="['btn-edit', 'btn-sm']"
      (onEvent)="showChildModal()"
      [dataCyAttribute]="'modal-stock-positions-open'"
    >
    </btn>
  </ng-container>
  <ng-container class="modal-body-content">
    <div class="card" style="padding: 26px" *ngIf="!!childModal?.childModal?.isShown">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
      <div class="form-stock-load">
        <div class="row stock-positions">
          <div class="d-flex">
            <div class="col-12 col-sm-4 input-dash position-relative">
              <span class="stock-positions-absolute">{{ 'stock.positions' | translate }}</span>
              <form-row-input
                [label]="'row'"
                [icon]="'far-box'"
                [(model)]="productDepotEdited.position1"
                [dataCyAttribute]="'stock-positions-position1'"
              >
              </form-row-input>
              <div class="align-self-center mt-2">-</div>
            </div>
            <div class="col-12 col-sm-4 input-dash">
              <form-row-input
                [label]="'aisle'"
                [icon]="'far-box'"
                [(model)]="productDepotEdited.position2"
                [dataCyAttribute]="'stock-positions-position2'"
              >
              </form-row-input>
              <div class="align-self-center mt-2">-</div>
            </div>
            <div class="col-12 col-sm-4">
              <form-row-input
                [label]="'shelf'"
                [icon]="'far-box'"
                [(model)]="productDepotEdited.position3"
                [dataCyAttribute]="'stock-positions-position3'"
              >
              </form-row-input>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 col-sm-6 ps-0">
            <form-row-input
              [label]="'purchasePrice'"
              [icon]="'fa-money-bill'"
              [(model)]="productDepotEdited.purchasePrice"
              [replaceComma]="true"
              [appendUnitCurrency]="true"
              [dataCyAttribute]="'stock-positions-purchasePrice'"
            ></form-row-input>
          </div>
          <div class="col-12 col-sm-6 pe-0">
            <form-row-select
              [label]="'supplier'"
              [icon]="'fa-truck'"
              [(model)]="productDepotEdited.supplier.id"
              [options]="suppliers"
              [dataCyAttribute]="'stock-positions-supplier'"
            ></form-row-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 ps-0">
            <form-row-input
              [label]="'product.batch'"
              [icon]="'fa-cubes'"
              [(model)]="productDepotEdited.batch"
              [inputType]="'text'"
              [dataCyAttribute]="'stock-positions-batch'"
            ></form-row-input>
          </div>
          <div class="col-12 col-sm-6 pe-0">
            <form-row-input
              [label]="'product.expirationDate'"
              [icon]="'fa-clock'"
              [model]="productDepotEdited.expirationDate | fmtDate: 'yyyy-MM-dd'"
              (modelChange)="productDepotEdited.expirationDate = $event"
              [inputType]="'date'"
              [dataCyAttribute]="'stock-positions-expirationDate'"
            ></form-row-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 ps-0">
            <form-row-input
              [label]="'depot'"
              [icon]="'fa-industry'"
              [model]="productDepotEdited.depot.name"
              [inputDisabled]="true"
              [dataCyAttribute]="'stock-positions-depot'"
            ></form-row-input>
          </div>
          <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center mt-2 pe-0">
            <div class="availability-icons ms-auto">
              <div class="flex-gap-5 align-items-center">
                <div>
                  <i class="far fa-warehouse-alt"></i>
                </div>
                <div data-cy="stock-sumAll">
                  {{ productDepot.quantityStock | number: '1.0-2' | replaceCommaToDot }}
                </div>
              </div>

              <div class="flex-gap-5 align-items-center">
                <div>
                  <i class="far fa-hourglass"></i>
                </div>
                <div data-cy="stock-sumReservations">
                  {{ productDepot.quantityReservation | number: '1.0-2' | replaceCommaToDot }}
                </div>
              </div>

              <div class="flex-gap-5 align-items-center">
                <div>
                  <i class="far fa-check"></i>
                </div>
                <div data-cy="stock-sumAvailable">
                  {{ productDepot.quantityAvailable | number: '1.0-2' | replaceCommaToDot }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container class="modal-footer-content">
    <btn
      [label]="'save'"
      [icon]="'far-check'"
      [classes]="['btn-first']"
      (onEvent)="updatePosition()"
      [btnDisabled]="loading"
      [dataCyAttribute]="'updatePosition'"
    ></btn>
  </ng-container>
</modal-container>
