import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, FormRowBase } from '../form-row-base/form-row-base'
import { Currency } from 'depoto-core/src/entities'

@Component({
  selector: 'form-row-input',
  templateUrl: 'form-row-input.component.html',
  styles: [
    `
      .form-group .input-group .input-group-text {
        padding-top: 10px;
      }
    `,
  ],
  styleUrls: ['./form-row-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR(FormRowInputComponent)],
})
export class FormRowInputComponent extends FormRowBase implements OnInit, OnChanges {
  @ViewChild('formRow') formRow: ElementRef
  @ViewChild('formRowInput') input: ElementRef
  @Input() label: string
  @Input() elementName: string
  @Input() tooltipLabel: string
  @Input() tooltipIcon: string
  @Input() model: string | number
  @Input() modelToggle: boolean
  @Output() modelChange: EventEmitter<any> = new EventEmitter()
  @Output() valueChangeNative: EventEmitter<any> = new EventEmitter()
  @Output() onKeyPress: EventEmitter<KeyboardEvent> = new EventEmitter()
  @Input() inputType = 'text'
  @Input() pattern = '.*'
  @Input() inputMultiple = false
  @Input() inputDisabled = false
  @Input() inputRequired = false
  @Input() minimumVal = null // may be Date or number
  @Input() maximumVal = null // may be Date or number
  @Input() noMarginBottom = false
  @Input() classes: string[] = []
  @Input() icon = null
  @Input() translateBarcode = false
  @Input() replaceComma = false
  @Input() appendBtnLabel: string
  @Input() appendBtnClasses: string[] = []
  @Input() appendBtnIcon: string
  @Input() appendBtnTitle = ''
  @Output() onAppendBtnEvent: EventEmitter<any> = new EventEmitter()
  @Input() trackCategory = 'event-click-input'
  @Input() trackAction: string
  @Input() appendCheckboxLabel: string
  @Input() appendCheckboxValue: boolean
  @Output() appendCheckboxValueChange: EventEmitter<boolean> = new EventEmitter()
  @Input() appendUnitCm: boolean
  @Input() appendUnitKg: boolean
  @Input() appendUnitCurrency: boolean
  @Input() currency: Currency
  @Input() appendUnitPercent: boolean
  @Input() appendUnitCustom: string
  @Input() isHsCode: boolean
  @Input() isLoginInput: boolean
  @Input() appendCheckboxShow = false
  @Input() appendToggleShow = false
  @Input() formControlName: string
  @Input() formGroup: FormGroup
  @Input() required: boolean
  @Input() requiredCssClass: any
  @Input() placeholder: string
  @Input() dataCyAttribute: string
  @Input() dataCyAttributeAppendToggle: string
  private classesDefault = 'form-control'
  classesString = ''
  iconClassesString = ''
  isFocused: boolean
  isErrorShown: boolean
  constructor() {
    super()
  }

  ngOnInit() {
    if (this.appendUnitCurrency && !this.currency?.id) this.currency = new Currency({ id: 'Kc' })
    this.updateComponent()
    this.initFormControl()
  }

  ngOnChanges() {
    this.updateComponent()
  }

  onBtnClick(event: any): void {
    this.onAppendBtnEvent.emit(event)
  }

  onInputValueChange(event: any): void {
    this.onChange(event)
    this.valueChangeNative.emit(event)
  }

  onInputKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onChange(event)
      this.isFocused = false
    }
    this.onKeyPress.emit(event)
  }

  onFocus(isFocused: boolean) {
    this.isFocused = isFocused
    if (!isFocused && !this.control.valid) {
      this.isErrorShown = true
    }
  }

  onAppendedCheckboxChange(value: boolean): void {
    this.appendCheckboxValueChange.emit(value)
  }

  private updateComponent(): void {
    let classesAdditional = ''
    if (this.classes && this.classes.length > 0) {
      this.classes.forEach(cls => {
        classesAdditional += ' ' + cls
      })
    }
    this.classesString = this.classesDefault + classesAdditional

    // icon
    let iconClasses = ''
    if (this.icon && this.icon.length > 0) {
      iconClasses += this.icon.split('-')[0]
      iconClasses += ' ' + this.icon.replace('far-', 'fa-').replace('fas-', 'fa-')
    }
    this.iconClassesString = iconClasses
    if (!this.trackAction) {
      this.trackAction = this.label
    }
  }
}
