<section class="content-header" [ngClass]="{ 'mb-0 content-header-stockOperation': isStockOperation }">
  <div
    [ngClass]="isOnProductWithAllBtns ? 'd-flex margin-div-row' : 'row'"
    *ngIf="!isStockOperation"
    class="justify-content-between"
  >
    <div
      class="d-flex align-items-center align-self-center ps-0"
      [ngClass]="{
        'col-12': !fullWidthPageTitle && !isOnProductWithAllBtns,
        'col-md-6': !isOnProductWithAllBtns,
        'flex-wrap-672': isFlexWrap
      }"
    >
      <div>
        <ng-content select=".back-button"> </ng-content>
      </div>
      <h1 class="d-flex ps-2" [ngClass]="{ 'text-nowrap': isHeadingNoWrap }">
        {{ title | translate }}
        <span
          *ngIf="title && title.length > 0 && appendNoTranslationTitlePart && appendNoTranslationTitlePart.length > 0"
        ></span
        >&nbsp;{{ appendNoTranslationTitlePart }}
        <ng-content select=".custom-title"> </ng-content>
      </h1>
      <div>
        <ng-content select=".stock-operation-btn"> </ng-content>
      </div>
    </div>
    <div
      *ngIf="!fullWidthPageTitle"
      class="d-flex align-items-center flex-row-reverse pe-0"
      [ngClass]="{
        'col-12': !fullWidthPageTitle && !isOnProductWithAllBtns,
        'col-md-6': !isOnProductWithAllBtns
      }"
    >
      <ng-content select=".header-button"> </ng-content>
    </div>
  </div>
  <div class="row mb-2" *ngIf="isStockOperation">
    <div class="col-md-6 d-flex align-items-center align-self-center ps-0">
      <div>
        <ng-content select=".back-button"> </ng-content>
      </div>
      <h1 [ngClass]="{ 'text-nowrap': isHeadingNoWrap }">
        {{ title | translate }}
        <span
          *ngIf="title && title.length > 0 && appendNoTranslationTitlePart && appendNoTranslationTitlePart.length > 0"
        ></span
        >{{ appendNoTranslationTitlePart }}
        <ng-content select=".custom-title"> </ng-content>
      </h1>
      <div>
        <ng-content select=".stock-operation-buttons"> </ng-content>
      </div>
    </div>
    <div class="col-md-6 d-flex justify-content-between align-items-center flex-row-reverse pe-0">
      <ng-content select=".header-btn"> </ng-content>
    </div>
  </div>
</section>
