import { Component, OnInit } from '@angular/core'
import { ListingItem } from 'depoto-core/src/models'
import { BaseListing } from '../_base-listing/base-listing'
import { Currency, PriceLevel } from 'depoto-core/src/entities'
import { CoreService } from '../../services'
import { priceLevelsColumns } from '../../utils/definitions.util'

const DEFAULT_FILTERS = {
  name: '',
  isPercentage: 'all',
  withVat: 'all',
  currency: 'all',
}

@Component({
  selector: 'app-price-levels',
  templateUrl: './price-levels.component.html',
})
export class PriceLevelsComponent extends BaseListing implements OnInit {
  public listingColumns: ListingItem[] = priceLevelsColumns
  public booleanFilterOptions = ['all', 'yes', 'no']
  public filters = { ...DEFAULT_FILTERS }
  public currencies: Currency[] = []
  public priceLevels: PriceLevel[] = []
  public totalItems = 0
  public currentPage = 1
  storageKey: { type: string; key: string }
  sortProp = 'name'
  sortDir = 'asc'

  constructor(protected core: CoreService) {
    super(core)
    this.getStorageKey()
  }

  async ngOnInit() {
    this.currencies = [{ id: 'all', name: '', ratio: null }, ...this.core.lists.currencies]
    this.filters = this.loadViewSettings('priceLevels') || { ...DEFAULT_FILTERS }
    this.loadSorting('priceLevels')
    await this.filter()
  }

  public async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    this.loading = true
    this.reloading = true
    this.currentPage = pageNo

    const options: any = {}
    if (this.filters.name.length > 0) {
      options.name = this.filters.name
    }
    if (this.filters.isPercentage !== 'all') {
      this.filters.isPercentage === 'yes' ? (options.isPercentage = true) : (options.isPercentage = false)
    }
    if (this.filters.withVat !== 'all') {
      this.filters.withVat === 'yes' ? (options.withVat = true) : (options.withVat = false)
    }
    if (this.filters.currency !== 'all') {
      options.currency = this.filters.currency
    }

    try {
      const result = await this.core.services.priceLevel.getList(
        { page: pageNo, sort: sortProp, direction: sortDir, filters: options },
        {
          id: null,
          name: null,
          isPercentage: null,
          percent: null,
          withVat: null,
          currency: {
            id: null,
            name: null,
          },
        }
      )
      if (!result?.items) return
      this.priceLevels = result.items
      this.totalItems = result.paginator.totalCount
      this.endPage = result.paginator.endPage
      this.saveViewSettings('priceLevels', this.filters)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }

  public clearFilters() {
    this.filters = { ...DEFAULT_FILTERS }
    this.filter(1)
  }
}
