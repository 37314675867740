import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Currency, PriceLevel } from 'depoto-core/src/entities'
import { CoreService, NotificationService } from '../../services'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'
import { FormControl, FormGroup } from '@angular/forms'
import { SchemaUtil, historyBack } from '../../utils'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-price-level',
  templateUrl: './price-level.component.html',
})
export class PriceLevelComponent implements OnInit, CheckDataChanges {
  public isCreating = false
  public loading = true
  public priceLevel: PriceLevel
  public currencyOptions: Currency[] = []
  priceLevelForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, []),
    isPercentage: new FormControl({ value: false, disabled: false }, []),
    percent: new FormControl({ value: 0, disabled: false }, []),
    withVat: new FormControl({ value: false, disabled: false }, []),
    currency: new FormControl({ value: null, disabled: false }, []),
  })

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    const id = this.route.snapshot.params['id']
    this.loading = true
    if (isNaN(id)) {
      this.isCreating = true
      this.priceLevel = new PriceLevel()
    } else {
      this.isCreating = false
      await this.refreshData(id)
    }
    this.currencyOptions = [{ id: undefined, name: ' ', ratio: undefined }, ...this.core.lists.currencies]
    this.loading = false
  }

  public fillFormValue() {
    this.priceLevelForm.setValue({
      name: this.priceLevel.name || '',
      isPercentage: this.priceLevel.isPercentage || false,
      percent: this.priceLevel.percent || 0,
      withVat: this.priceLevel.withVat || false,
      currency: this.priceLevel?.currency?.id || null,
    })
  }

  public getFormValue() {
    this.priceLevel.name = this.priceLevelForm.value.name
    this.priceLevel.isPercentage = this.priceLevelForm.value.isPercentage
    this.priceLevel.percent = this.priceLevelForm.value.percent
    this.priceLevel.withVat = this.priceLevelForm.value.withVat
    this.priceLevel.currency = <any>{ id: this.priceLevelForm.value.currency }
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  goBack(): void {
    historyBack()
  }

  async refreshData(id: number) {
    this.loading = true
    const result = await this.core.services.priceLevel.getById(id, SchemaUtil.priceLevel)
    this.loading = false
    if (result) {
      this.priceLevel = new PriceLevel(result)
      this.fillFormValue()
    }
  }

  public async deletePriceLevel() {
    try {
      await this.core.services.priceLevel.delete(this.priceLevel).then(() => {
        this.notificationService.success(this.translateService.instant('ALERT PRICE-LEVEL DELETED'))
        this.router.navigate(['/price-levels'], { replaceUrl: true })
      })
    } catch (err) {
      console.error(err)
    }
  }

  public async updatePriceLevel() {
    if (!this.priceLevel.name || (this.priceLevel.name && this.priceLevel.name.length === 0)) {
      alert(this.translateService.instant('priceLevel.name-required'))
      return
    } else if (this.priceLevelForm.invalid) return
    this.loading = true
    try {
      this.getFormValue()
      await this.core.services.priceLevel.update(this.priceLevel).then(result => {
        this.notificationService.success(this.translateService.instant('ALERT PRICE-LEVEL UPDATED'))
        this.refreshData(result.id)
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  public async createPriceLevel() {
    if (
      !this.priceLevelForm.value.name ||
      (this.priceLevelForm.value.name && this.priceLevelForm.value.name.length === 0)
    ) {
      alert(this.translateService.instant('priceLevel.name-required'))
      return
    }
    this.loading = true
    try {
      this.getFormValue()
      const result = await this.core.services.priceLevel.create(this.priceLevel)
      if (result) {
        this.notificationService.success(this.translateService.instant('ALERT PRICE-LEVEL CREATED'))
        await this.router.navigate(['/price-level', result.id], { replaceUrl: true })
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }
}
