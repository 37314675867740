import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { BaseListing } from '../_base-listing/base-listing'
import { ListingItem } from 'depoto-core/src/models'
import { BaseListingComponent } from '../../components/_common/base-listing/base-listing.component'
import { ExportsComponent } from '../exports/exports.component'
import { CoreService } from '../../services'
import { Carrier, Checkout, Depot, Order, Payment, Tag } from 'depoto-core/src/entities'
import { ActivatedRoute } from '@angular/router'
import { ordersColumns } from '../../utils/definitions.util'

type StorageKey = { type: string; key: string }

const DEFAULT_FILTERS = {
  fulltext: '',
  dateCreated: {
    right_date: '',
    left_date: '',
  },
  processStatus: 'all',
  // todo customer for salesman check
  customer: undefined,
  reservationNumber: null,
  billNumber: null,
  package: '',
  item: '',
  tags: null,
  status: 'all',
  hasAvailableProducts: 'all',
  carrier: 'all',
  payments: null,
  checkouts: [], // all user's checkouts, setting in constructor
  depots: null,
  isPaid: 'all',
  priority: null,
  dateExpedition: {
    right_date: '',
    left_date: '',
  },
  createdBy: null,
  dateBill: {
    right_date: '',
    left_date: '',
  },
  dateTax: {
    right_date: '',
    left_date: '',
  },
  dateDue: {
    right_date: '',
    left_date: '',
  },
  // visible: false,
  // expanded: false,
  // customerForSalesman: null,
  // sortProp: 'dateCreated',
  // sortDir: 'desc',
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent extends BaseListing implements OnInit, OnDestroy {
  @ViewChild('baseListingComponent', { static: true }) baseListingComponent: BaseListingComponent
  @ViewChild('exportsModal') exportsModal: ExportsComponent
  listingColumns: ListingItem[] = ordersColumns
  orders: Order[] = []
  depots: Depot[] = []
  checkouts: Checkout[] = []
  carriers: Carrier[] = [{ id: 'all', name: 'all', position: 0, color: 'blue', tariffs: [] }]
  payments: Payment[] = []
  tags: Tag[] = []
  isPaidOptions: string[] = ['all', 'true', 'false']
  priorityOptions: { id: number | string; name: string }[] = [
    { id: '', name: '' },
    { id: 0, name: 'Nejnižší' },
    { id: 1, name: 'Nízká' },
    { id: 10, name: 'Normální' },
    { id: 20, name: 'Vysoká' },
    { id: 30, name: 'Nejvyšší' },
  ] // todo declare only on one place
  totalItems = 0
  currentPage: number
  endPage: number
  filters
  statusTypesOptions: string[] = ['all', 'bill', 'reservation']
  processStatesOptions: string[] = [
    'all',
    'received',
    'picking',
    'packing',
    'packed',
    'dispatched',
    'delivered',
    'returned',
    'picking_error',
    'cancelled',
  ]
  canCreate = true
  loading = true
  public hasRoleSalesman = false
  public hasAvailableProductsOptions = ['', 'yes', 'no']
  public isShowingAddressBillingColumn = true
  public isShowingAddressShippingColumn = true
  public isShowingCreatedBy = true
  public isShowingCustomer = true
  public isShowingExternalReference = false
  public isShowingDateExpedition = false
  public isShowingNotes = false
  public isShowingPopoverColumn = true
  public isShowingPackagesColumn = true
  public isShowingPrice = true
  public isShowingPaymentItems = true
  public isShowingCarrier = true
  public isShowingStatus = true
  public isShowingCheckout = true
  public isShowingReservationNumber = true
  public isShowingBillNumber = true
  public isShowingProcessStatus = true
  public isShowingTags = true
  public isShowingQuantityUnavailable = true
  public isShowingIcons = true
  public isShowingPriority = true
  users: any[] = []
  filtersFromSellItem = false
  subscription
  storageKey: StorageKey

  filtersExpanded = false
  sortProp = 'dateCreated'
  sortDir = 'desc'

  public selectedColumns: ListingItem[] = []

  constructor(
    protected core: CoreService,
    private route: ActivatedRoute,
    protected changeDetector: ChangeDetectorRef
  ) {
    super(core)
    this.getStorageKey()
  }

  async ngOnInit() {
    this.subscription = this.route.queryParams.subscribe(params => {
      if (params?.item?.length > 0) {
        this.filters = JSON.parse(params.stringifiedFilters)
        this.filters.item = params.item
        this.filtersFromSellItem = true
      }
    })
    this.hasRoleSalesman = this.core.services.user.hasRole('ROLE_SALESMAN')
    if (!this.filtersFromSellItem) {
      this.filters = this.loadViewSettings('orders') || structuredClone(DEFAULT_FILTERS)
    }
    this.canCreate =
      this.core.services.user.hasRole('ROLE_ORDER_CREATE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')

    this.loadSorting('orders')
    await this.refreshFilterData()
    this.loadSettings()
  }

  loadSettings(): void {
    const savedColumns = this.core.services.storage.getSync(this.storageKey)
    if (savedColumns?.orders) {
      savedColumns.orders = savedColumns.orders.filter(savedColumn =>
        this.listingColumns.find(column => column.name === savedColumn.name)
      )
    }
    this.selectedColumns = [...this.listingColumns]
    if (savedColumns && savedColumns['orders']?.length) {
      this.selectedColumns = savedColumns['orders'].map(c => this.listingColumns.filter(col => col.name === c.name)[0])
    }
    // todo should we call this
    this.onSelectedColumnsChange(this.selectedColumns)
  }

  async ngOnDestroy() {
    if (this.filtersFromSellItem) this.filtersFromSellItem = false
    this.subscription.unsubscribe()
  }

  async refreshFilterData() {
    try {
      this.checkouts = this.core.lists.checkouts
      this.depots = this.core.lists.depots
      this.payments = this.core.lists.payments
      this.tags = this.core.lists.tags.filter(t => t.type === 'order')
      this.carriers = [
        { id: 'all', name: 'all', position: 0, color: '#333', tariffs: [] },
        ...this.core.lists.carriersFromCompanyCarriers,
      ]
      this.loading = true
      // todo why
    } finally {
      this.loading = false
    }
  }

  async fetchUsers() {
    try {
      const result = await this.core.services.user.getList(
        { page: 1, sort: 'lastName', direction: 'asc' },
        {
          id: null,
          name: null,
        }
      )
      this.users = result.items
      if (result.paginator.endPage > 1) {
        let i = 2
        for (i; i <= result.paginator.endPage; i++) {
          const users = await this.core.services.user
            .getList(
              { page: i, sort: 'lastName', direction: 'asc' },
              {
                id: null,
                name: null,
              }
            )
            .then(r => r.items)
          this.users = [...this.users, ...users]
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  get queryFilters() {
    const filtersWithValues = {}
    for (const [key, value] of Object.entries(this.filters)) {
      // if it has value
      if (
        value !== undefined &&
        value !== null &&
        value !== false &&
        value !== 0 &&
        value !== '' &&
        value !== 'all' &&
        (!Array.isArray(value) || (Array.isArray(value) && value?.length))
      ) {
        if (value === 'yes' || value === 'no') {
          // boolean
          filtersWithValues[key] = value === 'yes'
        } else if (Array.isArray(value) && value[0]?.id) {
          // array
          filtersWithValues[key] = value.map(object => object.id)
        } else if ((key === 'billNumber' || key === 'reservationNumber') && !isNaN(+value)) {
          // array
          filtersWithValues[key] = Number(value)
        } else if (key === 'customer') {
          // object with id
          if (value && (value as any).id) {
            filtersWithValues[key] = (value as any).id
          }
        } else if (
          key === 'dateCreated' ||
          key === 'dateExpedition' ||
          key === 'dateBill' ||
          key === 'dateDue' ||
          key === 'dateTax'
        ) {
          // date object
          if ((value && (value as any).left_date?.length) || (value as any).right_date?.length) {
            if ((value as any).right_date?.length) {
              filtersWithValues[key] = {
                left_date: (value as any).left_date,
                right_date: (value as any).right_date + 'T23:59:59',
              }
            } else {
              filtersWithValues[key] = value
            }
          }
          // can't ever happen 'cause of: ... (!Array.isArray(value) || (Array.isArray(value) && value?.length)) // todo remove this block
          // } else if (this.hasRoleSalesman && key === 'checkouts' && Array.isArray(value) && !value?.length) {
          //   filtersWithValues[key] = this.checkouts
        } else {
          // other
          filtersWithValues[key] = value
        }
      }
    }
    if (
      !this.core.userHasAllCheckouts &&
      this.core.services.user.user?.checkouts?.length &&
      !filtersWithValues['checkouts']?.length
    ) {
      filtersWithValues['checkouts'] = [...this.checkouts.map(c => c.id)]
    }
    if (
      !this.core.userHasAllDepots &&
      this.core.services.user.user?.depots?.length &&
      !filtersWithValues['depots']?.length
    ) {
      filtersWithValues['depots'] = [...this.depots.map(d => d.id)]
    }
    return filtersWithValues
  }

  async filter(pageNo = 1, sortProp: string = this.sortProp, sortDir: string = this.sortDir, concat = false) {
    // todo. disable for now - querySelectorAll gets all inputs from pages, even when they should be destroyed. (looking at you, Products form fields!)
    // if (isFormInvalid()) return
    this.loading = true
    this.currentPage = pageNo
    if (!concat) {
      this.reloading = true
    }
    const schema: Partial<Order> | any = this.setSchema()
    try {
      const result = await this.core.services.order.getList(
        {
          page: pageNo,
          sort: sortProp,
          direction: sortDir,
          filters: this.queryFilters,
        },
        schema
      )
      if (!result || !Array.isArray(result.items)) {
        return
      }
      if (!concat) {
        this.orders = result.items
      } else {
        result.items.forEach(order => {
          this.orders.push(new Order(order))
        })
      }
      this.totalItems = result.paginator ? result.paginator.totalCount : 0
      this.endPage = result.paginator ? result.paginator.endPage : 0
      this.currentPage++

      if (!this.filtersFromSellItem) this.saveViewSettings('orders', this.filters)
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
      this.reloading = false
    }
  }

  public clearFilters() {
    this.filters = structuredClone(DEFAULT_FILTERS)
    this.filter(1)
  }

  onSelectedColumnsChange(event) {
    this.isShowingAddressBillingColumn = event.filter(c => c.name === 'country-invoice').length > 0
    this.isShowingAddressShippingColumn = event.filter(c => c.name === 'country-shipping').length > 0
    this.isShowingCreatedBy = event.filter(c => c.name === 'orders.createdBy').length > 0
    this.isShowingCustomer = event.filter(c => c.name === 'orders.customerName').length > 0
    this.isShowingDateExpedition = event.filter(c => c.name === 'orders.dateExpedition').length > 0
    this.isShowingExternalReference = event.filter(c => c.name === 'externalReference').length > 0
    this.isShowingNotes = event.filter(c => c.name === 'notes').length > 0
    this.isShowingPopoverColumn = event.filter(c => c.name === 'preview').length > 0
    this.isShowingPackagesColumn = event.filter(c => c.name === 'packageInfo').length > 0
    this.isShowingCheckout = event.filter(c => c.name === 'checkout').length > 0
    this.isShowingStatus = event.filter(c => c.name === 'status').length > 0
    this.isShowingReservationNumber = event.filter(c => c.name === 'reservation.short').length > 0
    this.isShowingBillNumber = event.filter(c => c.name === 'bill.short').length > 0
    this.isShowingPrice = event.filter(c => c.name === 'price').length > 0
    this.isShowingPaymentItems = event.filter(c => c.name === 'paymentItems').length > 0
    this.isShowingCarrier = event.filter(c => c.name === 'shipping').length > 0
    this.isShowingProcessStatus = event.filter(c => c.name === 'processStatus').length > 0
    this.isShowingPriority = event.filter(c => c.name === 'priority').length > 0
    this.isShowingTags = event.filter(c => c.name === 'tags').length > 0
    this.isShowingQuantityUnavailable = event.filter(c => c.name === 'quantityUnavailable').length > 0
    this.isShowingIcons = event.filter(c => c.name === 'icons').length > 0
    this.filter()
  }

  setSchema(): Partial<Order> | any {
    const schema: Partial<Order> | any = {
      id: null,
    }

    if (this.isShowingStatus) {
      schema.status = {
        id: null,
        name: null,
      }
    }

    if (this.isShowingPriority) {
      schema.priority = null
    }

    if (this.isShowingReservationNumber) {
      schema.reservationNumber = null
    }

    if (this.isShowingBillNumber) {
      schema.billNumber = null
    }

    if (this.isShowingCarrier) {
      schema.carrier = {
        id: null,
        name: null,
        color: null,
      }
    }

    if (this.isShowingCheckout) {
      schema.checkout = {
        id: null,
        name: null,
      }
    }

    if (this.isShowingPrice) {
      schema.priceAll = null
      schema.currency = {
        id: null,
        name: null,
        ratio: null,
      }
    }

    if (this.isShowingPaymentItems) {
      schema.paymentItems = {
        id: null,
        payment: { id: null, name: null, type: { id: null, name: null } },
        amount: null,
        isPaid: null,
      }
    }

    if (this.isShowingExternalReference) {
      schema.externalReference = null
    }

    if (this.isShowingDateExpedition) {
      schema.dateExpedition = null
    }

    if (this.isShowingNotes) {
      schema.note = null
      schema.privateNote = null
    }

    if (this.isShowingCreatedBy) {
      schema.createdBy = {
        id: null,
        name: null,
        firstName: null,
        lastName: null,
      }
    }

    if (this.isShowingCustomer) {
      schema.customer = {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        name: null,
        companyName: null,
      }
    }

    if (this.isShowingPackagesColumn) {
      schema.packages = {
        id: null,
        code: null,
        carrier: { id: null, name: null, color: null, position: null, tariffs: { id: null, name: null } },
        statuses: { id: null, code: null, text: null, created: null },
        disposal: {
          id: null,
          code: null,
          carrier: { id: null, name: null },
          packages: { id: null },
          ticketUrl: null,
          sent: null,
        },
        ticketUrl: null,
        sent: null,
      }
    }
    if (this.isShowingQuantityUnavailable || this.isShowingIcons) {
      schema.quantityUnavailable = null
    }
    if (this.isShowingIcons) {
      schema.depots = {
        id: null,
        name: null,
      }
    }
    if (this.isShowingProcessStatus) {
      schema.processStatus = { id: null, name: null, position: null }
      schema.processStatusUpdated = null
    }
    if (this.isShowingAddressBillingColumn) {
      schema.invoiceAddress = {
        id: null,
        country: null,
        state: null,
      }
    }
    if (this.isShowingAddressShippingColumn) {
      schema.shippingAddress = {
        id: null,
        country: null,
        state: null,
      }
    }
    // todo stahovat az p otevreni okna ?
    if (this.isShowingPopoverColumn) {
      schema.note = null
      schema.privateNote = null
    }
    if (this.isShowingTags) {
      schema.tags = { id: null, name: null, type: null, color: null, externalId: null }
    }
    return schema
  }

  async filterVisibilityChanged(isExpanded: boolean) {
    // load unfetched data for expanded filter users
    this.loading = true
    if (isExpanded && !this.users?.length) {
      await this.fetchUsers()
    }
    this.filtersExpanded = isExpanded
    this.changeDetector.detectChanges()
    if (!this.filtersFromSellItem) this.saveViewSettings('orders', this.filters)
  }
}
