import { Component } from '@angular/core'
import { File } from 'depoto-core/src/entities'
import { CategoryTreeType } from '../../../models'
import { CoreService, NotificationService } from '../../../services'
import { SchemaUtil } from '../../../utils'
import { ProductDetailService } from '../../../product-detail.service'
import { TranslateService } from '@ngx-translate/core'
import { FileUtilService } from '../../../utils/file-util.service' // todo add ???

@Component({
  selector: 'product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['product-images.component.scss'],
})
export class ProductImagesComponent {
  files: File[] = []
  progress = 0
  progressFiles = 0
  filesContainImages = false
  public categoryTreeTypeEnum = CategoryTreeType
  acceptedFileTypes = 'image/jpeg,image/jpg,image/png,image/gif,image/webp'

  constructor(
    private core: CoreService,
    public productDetailService: ProductDetailService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private fileUtilService: FileUtilService
  ) {
    // setTimeout(() => console.warn('PF', this.product.images), 1500);
  }

  async onSelect(event) {
    const enabledMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp']
    const filteredImages = event.addedFiles.filter(f => enabledMimeTypes.includes(f.type))

    this.productDetailService.loading = true
    const files = []
    this.progress = 0
    this.progressFiles = 0
    try {
      for (const f of filteredImages) {
        try {
          const processedFile = await this.fileUtilService.processUploadedFileToBase64(f)
          if (processedFile?.base64Data) {
            files.push(processedFile)
            await this.core.services.file.create(processedFile, this.productDetailService.product.id)
            this.progressFiles += 1
            this.progress = (100 / filteredImages.length) * this.progressFiles
          }
        } catch (err) {
          console.warn(err)
        }
      }
      if (files.length) {
        this.notificationService.success(this.translateService.instant('alert.image.added'))
        const p = await this.core.services.product.getById(
          this.productDetailService.product.id,
          SchemaUtil.productFilesAndImages
        )
        this.productDetailService.product.images = [...p.images]
        this.productDetailService.product.mainImage = p.mainImage
        this.progress = 0
        this.progressFiles = 0
      } else {
        this.notificationService.error(this.translateService.instant('no-files'))
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.productDetailService.loading = false
    }
  }

  async removeImage(image: File) {
    if (!confirm(this.translateService.instant('delete.confirmation'))) {
      return
    }
    try {
      await this.core.services.file.delete(image).then(res => {
        this.notificationService.success(this.translateService.instant('alert.image.deleted'))
      })
      this.productDetailService.product.images = this.productDetailService.product.images.filter(
        img => img.id !== image.id
      )
      if (image.main) {
        this.productDetailService.product.mainImage = null
      }
    } catch (err) {
      console.error(err)
    }
  }

  getThumbUrl(image: File): string {
    return image.thumbnails.filter(t => t.format === 'w135wp')[0].url
    // this.imageUrl = this.image.thumbnails.filter(t => t.format === 'w2048wp')[0].url
    // this.label = this.image.text
  }
}
