import { Component, OnInit } from '@angular/core'
import { CategoryTreeType } from '../../models'
import { CoreService } from '../../services'
import { Tag } from 'depoto-core/src/entities'

@Component({
  selector: 'categories',
  templateUrl: 'categories.component.html',
})
export class CategoriesComponent implements OnInit {
  public categoryTreeTypeEnum = CategoryTreeType
  public canCreate: boolean
  public tags: Tag[] = []

  constructor(private core: CoreService) {}

  ngOnInit(): void {
    if (this.core.services.user.user) {
      this.checkPerms()
    }
    this.core.services.user.currentUserEmitter.subscribe().then(() => {
      this.checkPerms()
    })
    this.tags = this.core.lists.tags.filter(t => t.type === 'category')
  }

  checkPerms(): boolean {
    this.canCreate =
      this.core.services.user.hasRole('ROLE_CATEGORY_CREATE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
    return this.canCreate
  }
}
