import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Vat } from 'depoto-core/src/entities'
import { NotificationService } from '../../services'
import { TranslateService } from '@ngx-translate/core'
import { CoreService } from '../../services'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { SchemaUtil, wait, historyBack } from '../../utils'
import { CheckDataChanges } from '../../services/can-deactivate-guard.service'

@Component({
  selector: 'app-vat',
  templateUrl: 'vat.component.html',
})
export class VatComponent implements OnInit, CheckDataChanges {
  vat: Vat
  isCreating = false
  loading = true
  submitted = false

  vatForm = new FormGroup({
    name: new FormControl({ value: '', disabled: false }, [Validators.required]),
    percent: new FormControl({ value: 0, disabled: false }, [Validators.required]),
    default: new FormControl({ value: false, disabled: false }, []),
  })

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private core: CoreService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
  }

  async ngOnInit() {
    this.loading = true
    const id = this.route.snapshot.params['id']
    if (isNaN(id)) {
      this.isCreating = true
      this.vat = new Vat()
    } else {
      this.vat = await this.core.services.vat.getById(id, SchemaUtil.vat)
      this.fillFormValue()
    }
    this.loading = false
  }

  public fillFormValue() {
    this.vatForm.setValue({ name: this.vat.name, percent: this.vat.percent, default: this.vat.default })
  }

  public getFormValue() {
    this.vat.name = this.vatForm.value.name
    this.vat.percent = this.vatForm.value.percent
    this.vat.default = this.vatForm.value.default
  }

  hasUnsavedDataChanges(): boolean {
    return false
  }

  goBack(): void {
    historyBack()
  }

  async createVat() {
    this.submitted = true
    await wait()
    if (this.vatForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValue()
      await this.core.services.vat.create(this.vat).then(result => {
        this.notificationService.success(this.translateService.instant('alert.vat.created'))
        this.router.navigate(['/vat/', result.id], { replaceUrl: true })
      })
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
    }
  }

  async updateVat() {
    this.submitted = true
    if (this.vatForm.invalid) {
      return
    }
    try {
      this.loading = true
      this.getFormValue()
      this.vat = await this.core.services.vat
        .update(this.vat, {
          id: null,
          name: null,
          percent: null,
          coefficient: null,
          default: null,
        })
        .then(result => {
          this.notificationService.success(this.translateService.instant('alert.vat.updated'))
          return result
        })
      this.router.navigate(['/vat/', this.vat.id])
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  async deleteVat() {
    const confirmation = confirm(this.translateService.instant('vat.delete-confirm', { name: this.vat.name }))
    if (confirmation) {
      try {
        await this.core.services.vat.delete(this.vat).then(() => {
          this.notificationService.success(this.translateService.instant('alert.vat.deleted'))
        })
        this.router.navigate(['/vats'], { replaceUrl: true })
      } catch (err) {
        console.error(err)
      }
    }
  }
  get formControls(): { [key: string]: AbstractControl } {
    return this.vatForm.controls
  }
}
