<div #formRow class="flex-gap-5" [ngClass]="{ 'justify-content-center': isOnProductsPage }">
  <div
    class="form-check"
    [ngClass]="{ 'checkbox-bulk-edits': isInBulkEdits, 'checkbox-in-table': isOnProductsPage || isInOrderProducts }"
  >
    <input
      type="checkbox"
      class="form-check-input position-relative"
      [id]="id"
      [ngModel]="model"
      (ngModelChange)="onNgModelChange($event)"
      [attr.data-track-category]="trackCategory"
      [attr.data-track-action]="trackAction"
      [disabled]="inputDisabled"
      [attr.data-cy]="dataCySelector"
    />
    <label
      class="form-check-label"
      [ngClass]="{
        'cursor-pointer': cursorPointer,
        'mb-0': noMarginBottom,
        'text-nowrap': noWrap,
        'label-checkbox-in-table': isOnProductsPage || isInOrderProducts
      }"
      [for]="id"
    >
      {{ !(label | translate) ? label : (label | translate) }}
    </label>
  </div>
  <info-tooltip
    *ngIf="tooltipLabel && tooltipLabel.length > 0"
    [label]="tooltipLabel"
    [icon]="tooltipIcon"
  ></info-tooltip>
  <div *ngIf="control && !control.pristine && !control.valid" style="color: red">
    {{ errors | formError: formControlName | translate }}
  </div>
</div>
