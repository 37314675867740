<ng-content select=".modal-button-show"> </ng-content>

<div
  bsModal
  #childModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  aria-modal="true"
  role="dialog"
  [config]="{ backdrop: hasBackdrop, ignoreBackdropClick: ignoreBackdropClick }"
>
  <div
    class="modal-dialog"
    [ngClass]="{
      'modal-sm': isSmallModal,
      'modal-lg': isLargeModal,
      'modal-xl': isExtraLargeModal,
      'modal-product-choose': isModalForProductChoose,
      'w-100': isFullWidth,
      'modal-center': isModalInModal
    }"
  >
    <div class="modal-content">
      <div class="modal-header" [class]="modalHeaderBgColorClass">
        <h4 class="modal-title">
          <span *ngIf="translateTitle">
            {{ headerTitle | translate }}
            <ng-container *ngIf="headerTitleAfterTranslatedTitle && headerTitleAfterTranslatedTitle.length > 0">
              {{ headerTitleAfterTranslatedTitle }}
            </ng-container>
          </span>
          <span *ngIf="!translateTitle">{{ headerTitle }}</span>
        </h4>
        <button
          data-cy="close"
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="hideChildModal()"
          [title]="'close' | translate"
        ></button>
      </div>
      <div class="modal-body" [ngClass]="{ 'p-0': noPaddingModalBody }" [class]="modalBodyBgColorClass">
        <loading-spinner [isLoading]="isLoading" [classesString]="'top-50'"></loading-spinner>
        <ng-content select=".modal-body-content"> </ng-content>
      </div>
      <div class="modal-footer" [class]="modalFooterBgColorClass">
        <btn
          [ngClass]="isHideBtnHidden ? 'd-none' : ''"
          [btnType]="'button'"
          [classes]="['btn-third']"
          (click)="hideChildModal()"
          [label]="'close'"
          [title]="'close' | translate"
          [icon]="'far-ban'"
          [dataCyAttribute]="'modal-container-close-btn'"
        ></btn>
        <ng-content select=".modal-footer-content"> </ng-content>
      </div>
    </div>
  </div>
</div>
