<modal-container #modal [headerIconClass]="'far-box'" [headerTitle]="'create.package'" [isLargeModal]="true">
  <ng-container class="modal-button-show">
    <btn
      [classes]="['btn-first', 'float-right']"
      [icon]="'far-plus'"
      [label]="isCreating ? 'create.package' : 'update'"
      [title]="isCreating ? ('create.package' | translate) : ('update' | translate)"
      (onEvent)="showChildModal()"
      [dataCyAttribute]="dataCyAttribute"
    ></btn>
  </ng-container>

  <ng-container class="modal-body-content">
    <div class="card">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>

      <div class="row">
        <div class="col-4">
          <form-row-input
            [label]="'code'"
            [inputType]="'number'"
            [model]="pack?.code"
            (modelChange)="pack.code = $event"
            [dataCyAttribute]="'pack-code-input'"
          >
          </form-row-input>
        </div>
        <div class="col-4">
          <form-row-input
            [label]="'ean'"
            [model]="pack?.ean"
            (modelChange)="pack.ean = $event"
            [icon]="'far-barcode'"
            [translateBarcode]="false"
            [appendBtnIcon]="!pack.ean || pack.ean?.length === 0 ? 'fa-barcode' : ''"
            [appendBtnLabel]="!pack.ean || pack.ean?.length === 0 ? 'product.ean.generate' : ''"
            [appendBtnClasses]="['btn-ean']"
            (onAppendBtnEvent)="fillNextEan()"
            [dataCyAttribute]="'pack-ean-input'"
          >
          </form-row-input>
        </div>
        <div class="col-4">
          <form-row-input
            [label]="'quantity'"
            [inputType]="'number'"
            [model]="pack?.quantity"
            (modelChange)="pack.quantity = $event"
            [dataCyAttribute]="'pack-quantity-input'"
          >
          </form-row-input>
        </div>
        <div class="col-12">
          <form-row-textarea
            [label]="'note'"
            [icon]="'text'"
            [model]="pack?.note"
            (modelChange)="pack.note = $event"
            [dataCyAttribute]="'pack-note'"
          >
          </form-row-textarea>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container class="modal-footer-content">
    <btn
      *ngIf="!isCreating"
      [label]="'delete'"
      [title]="'delete' | translate"
      [icon]="'far-trash-alt'"
      [classes]="['btn-first-red']"
      (onEvent)="removePack(pack)"
    ></btn>
    <btn
      [label]="'save'"
      [title]="'save' | translate"
      [icon]="'far-check'"
      [classes]="['btn-first']"
      [dataCyAttribute]="dataCyAttribute"
      (onEvent)="isCreating ? createPack(pack) : updatePack(pack)"
    ></btn>
  </ng-container>
</modal-container>
