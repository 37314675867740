import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { BaseListing } from '../../../pages/_base-listing/base-listing'
import { CoreService } from '../../../services'
import { Category, Currency, Product, ProductPrice, Supplier, Tag, Vat } from 'depoto-core/src/entities'
import { GeoConfig, GeoListItemType } from '../../../configs'
import { NotificationService } from '../../../services'
import { CategoryTreeType } from '../../../models'
import { TranslateService } from '@ngx-translate/core'

const optionKeepCurrent = 'keep.current'
const optionKeepCurrentSetSelected = 'keep.current.set.selected'

class PercentageDiscount {
  percent: null
  available: 1000
  dateFrom: null
  dateTo: null
}
interface Schema {
  id: null
  name?: null
  sellPrice?: null
  tags?: {
    id: null
  }
  categories?: {
    id: null
  }
  mainCategory?: {
    id: null
  }
  vat?: {
    id: null
  }
  dimensionX?: null
  dimensionY?: null
  dimensionZ?: null
  weight?: null
  originCountry?: null
  hsCode?: null
  enabled?: null
  isFragile?: null
  isOversize?: null
  purchasePrice?: null
  purchaseCurrency?: {
    id: null
  }
  supplier?: {
    id: null
  }
}
@Component({
  selector: 'modal-bulk-edits',
  templateUrl: './modal-bulk-edits.component.html',
  styleUrls: ['./modal-bulk-edits.component.scss'],
})
export class ModalBulkEditsComponent extends BaseListing {
  public categoryTreeTypeEnum = CategoryTreeType
  public editedCategory: Category
  @ViewChild('modal') childModal: ModalContainerComponent
  @Output() refreshProductsPage: EventEmitter<any> = new EventEmitter<any>()
  @Output() setCheckedProductsToFalse: EventEmitter<any> = new EventEmitter<any>()
  @Input() filters: any = {}
  @Input() checkedProducts = []
  @Input() allProductsSelected = false

  @Input() tags: Tag[] = []
  @Input() dataCyAttribute: string

  tagSelectedOption = optionKeepCurrentSetSelected

  categories: Category[] = []

  categorySelectedOption = optionKeepCurrentSetSelected
  mainCategorySelectedOption = optionKeepCurrent
  mainCategorySelectOptions = [optionKeepCurrent, 'set.selected', 'remove.current']

  selectOptions = ['keep.current.set.selected', 'keep.current.remove.selected', 'remove.current.add.selected']

  booleanSelectOptions = [optionKeepCurrent, 'yes', 'no']
  isFragileSelectedOption = optionKeepCurrent
  isOversizeSelectedOption = optionKeepCurrent
  isEnabledOption = optionKeepCurrent

  vats: Array<Vat | string> = [optionKeepCurrent]
  countries: Array<GeoListItemType | string> = [optionKeepCurrent, ...GeoConfig.countries]
  currencies: Array<Currency | string> = [optionKeepCurrent]
  suppliers: Array<Supplier | string> = [optionKeepCurrent]

  purchasePrice: any

  productChanges: Partial<Product> | any = {
    tags: [],
    categories: [],
    mainCategory: null,
    dimensionX: null,
    dimensionY: null,
    dimensionZ: null,
    weight: null,
    originCountry: optionKeepCurrent,
    hsCode: null,
    isOversize: null,
    isFragile: null,
    purchaseCurrency: optionKeepCurrent,
    purchasePrice: null,
    supplier: optionKeepCurrent,
    vat: optionKeepCurrent,
    enabled: null,
  }

  public advancedPrice = new ProductPrice()
  percentageDiscount = new PercentageDiscount()
  productsFetched: Partial<Product>[] | any = []

  loading = false

  numberOfAllProducts: number
  numberOfUpdatedProducts: number
  isShowingProgress = false
  isShowingCloseBtn = false
  updateErrors = []
  wasKilled = false
  updating = false
  productsWithErrors = []
  isProgressFinish = false

  constructor(
    protected core: CoreService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {
    super(core)
  }

  async showChildModal() {
    this.loading = true
    if (!this.allProductsSelected && this.checkedProducts?.length < 1) {
      alert(this.translateService.instant('modal-bulk-edits.select-product'))
      return
    }
    if (this.vats?.length === 0 || (this.vats?.length === 1 && this.vats[0] === optionKeepCurrent))
      await this.getOptions()
    // if (!this.productChanges.vat?.length) this.setDefaultValues(true)
    this.setDefaultValues(true)
    this.loading = false
    this.childModal.showChildModal()
  }

  hideChildModal() {
    this.childModal.hideChildModal()
  }

  get isModalShown(): boolean {
    return this.childModal?.isShown() || false
  }

  async getOptions() {
    this.categories = this.core.lists.categories
    this.currencies = [...this.currencies, ...this.core.lists.currencies]
    this.suppliers = [...this.suppliers, ...this.core.lists.suppliers]
    this.vats = [...this.vats, ...this.core.lists.vats]
  }

  setDefaultProductChanges() {
    this.productChanges = {
      tags: [],
      categories: [],
      mainCategory: null,
      dimensionX: null,
      dimensionY: null,
      dimensionZ: null,
      enabled: null,
      weight: null,
      originCountry: optionKeepCurrent,
      hsCode: null,
      isOversize: null,
      isFragile: null,
      purchaseCurrency: optionKeepCurrent,
      purchasePrice: null,
      supplier: optionKeepCurrent,
      vat: optionKeepCurrent,
    }
  }

  close() {
    if (!this.isProgressFinish) {
      if (confirm(this.translateService.instant('modal-bulk-edits.terminate-action'))) {
        this.wasKilled = true
      } else return
    }
    this.setDefaultValues()
    this.hideChildModal()
  }

  setDefaultValues(isShowingModal = false): void {
    this.setDefaultProductChanges()
    this.advancedPrice = new ProductPrice()
    this.tagSelectedOption = optionKeepCurrentSetSelected
    this.categorySelectedOption = optionKeepCurrentSetSelected
    this.mainCategorySelectedOption = optionKeepCurrent
    this.isEnabledOption = optionKeepCurrent
    this.isFragileSelectedOption = optionKeepCurrent
    this.isOversizeSelectedOption = optionKeepCurrent
    this.productsFetched = []
    this.numberOfAllProducts = undefined
    this.numberOfUpdatedProducts = undefined
    this.updateErrors = []
    this.productsWithErrors = []

    this.isShowingProgress = false
    this.isShowingCloseBtn = false
    this.updating = false
    this.isProgressFinish = false
    this.wasKilled = false
    if (!isShowingModal) {
      this.refreshProductsPage.emit()
      this.setCheckedProductsToFalse.emit()
    }
  }

  suspend() {
    this.wasKilled = true
  }

  getTagValue(tag): boolean {
    return this.productChanges.tags.some(t => t.id === tag.id)
  }

  updateTags(tag: Tag, value: boolean) {
    if (value) {
      this.productChanges.tags.push(tag)
    } else {
      this.productChanges.tags.forEach((t: Tag, i: number) => {
        if (t.id === tag.id) {
          this.productChanges.tags.splice(i, 1)
        }
      })
    }
  }

  booleanChange(event, property: string) {
    if (property === 'isFragile') this.isFragileSelectedOption = event
    else if (property === 'isOversize') this.isOversizeSelectedOption = event
    else if (property === 'enabled') this.isEnabledOption = event
  }

  valueAdded(event) {
    if (event[1] === 'originCountry') this.productChanges.originCountry = event[0]
    else if (event[1] === 'weight') this.productChanges.weight = event[0]
    else if (event[1] === 'dimensionX') this.productChanges.dimensionX = event[0]
    else if (event[1] === 'dimensionY') this.productChanges.dimensionY = event[0]
    else if (event[1] === 'dimensionZ') this.productChanges.dimensionZ = event[0]
    else if (event[1] === 'hsCode') this.productChanges.hsCode = event[0]
  }

  mainCategoryChanged(event) {
    this.productChanges.mainCategory = event.id
  }

  async updateProduct(product, changedProperties, schemaForUpdate) {
    if (this.wasKilled) {
      this.setDefaultValues()
      this.hideChildModal()
      return
    }

    product = { id: product.id, sellPrice: product.sellPrice, ...changedProperties }

    if (this.productChanges.tags?.length) {
      await this.setTagsOnProductToUpdate(product)
    }

    if (this.productChanges.categories?.length) {
      await this.fetchCategoriesOnProduct(product)
      await this.setCategoriesOnProductToUpdate(product)
    }

    if (this.percentageDiscount.percent) {
      await this.createAdvancedPriceFromPercent(product)
    }

    try {
      await this.update(product, schemaForUpdate)
      if (this.advancedPrice.sellPrice) {
        this.advancedPrice.product = product
        await this.core.services.productPrice.create(this.advancedPrice)
      }
      if (this.percentageDiscount.percent) {
        this.createAdvancedPriceFromPercent(product)
      }
    } catch (err) {
      let errMessage
      if (Object.prototype.toString.call(err[0]) === '[object Array]') {
        for (const object of err) {
          for (const key in object) {
            if (object.hasOwnProperty(key)) {
              errMessage = object[key]
            }
          }
        }
      } else errMessage = err[0]

      this.updateErrors.push({ productId: product.id, productName: product?.name, errMessage: errMessage })
    } finally {
      this.numberOfUpdatedProducts = this.numberOfUpdatedProducts + 1
      await this.core.services.product.getById(product.id, schemaForUpdate)
    }
  }

  async createAdvancedPriceFromPercent(product) {
    const advancedPrice = new ProductPrice({
      available: this.percentageDiscount.available,
      product: product,
      dateFrom: this.percentageDiscount.dateFrom,
      dateTo: this.percentageDiscount.dateTo,
    })
    advancedPrice.sellPrice = product.sellPrice * (1 - Number(this.percentageDiscount.percent) / 100)
    await this.core.services.productPrice.create(advancedPrice)
  }

  async save() {
    this.loading = true
    this.numberOfUpdatedProducts = 0
    let page = 1
    let endPage: number
    const schema: Schema = { id: null, name: null }
    const schemaForUpdate: Schema = { id: null }
    if (this.productChanges.tags?.length) schema.tags = { id: null }
    if (this.productChanges.categories?.length) schema.categories = { id: null }
    if (this.percentageDiscount) schema.sellPrice = null

    if (this.isEnabledOption !== optionKeepCurrent)
      this.isEnabledOption === 'yes' ? (this.productChanges.enabled = true) : (this.productChanges.enabled = false)
    if (this.isFragileSelectedOption !== optionKeepCurrent)
      this.isFragileSelectedOption === 'yes'
        ? (this.productChanges.isFragile = true)
        : (this.productChanges.isFragile = false)
    if (this.isOversizeSelectedOption !== optionKeepCurrent)
      this.isOversizeSelectedOption === 'yes'
        ? (this.productChanges.isOversize = true)
        : (this.productChanges.isOversize = false)

    //object with changed properties from productChanges
    const changedProperties = {}
    if (this.mainCategorySelectedOption === 'remove.current') {
      this.productChanges.mainCategory = null
      changedProperties['mainCategory'] = null
      schemaForUpdate['mainCategory'] = { id: null }
    }
    const entries = Object.entries(this.productChanges)
    for (const [key, value] of entries) {
      if (value !== 'keep.current' && value !== null && key !== 'tags' && key !== 'categories') {
        if (['vat', 'purchaseCurrency', 'supplier', 'mainCategory'].includes(key)) {
          schemaForUpdate[key] = { id: null }
          changedProperties[key] = { id: value }
        } else {
          changedProperties[key] = value
        }
      }
    }

    //for avoid error alerts while update
    this.notificationService.avoidErrorAlerts = true

    if (this.allProductsSelected) {
      do {
        if (this.wasKilled) {
          this.setDefaultValues()
          this.hideChildModal()
          return
        }
        //prepare products to update
        const { items, paginator } = await this.core.services.product.getList(
          { page: page, sort: 'name', direction: 'asc', filters: this.filters },
          schema
        )
        this.numberOfAllProducts = paginator.totalCount
        this.loading = false
        this.isShowingProgress = true
        this.updating = true
        endPage = paginator.endPage
        page = page + 1

        //loop and update every product
        await Promise.all(items.map(product => this.updateProduct(product, changedProperties, schemaForUpdate)))
      } while (page <= endPage)
    } else {
      this.numberOfAllProducts = this.checkedProducts?.length
      this.loading = false
      this.isShowingProgress = true
      this.updating = true
      await Promise.all(
        this.checkedProducts.map(product => this.updateProduct(product, changedProperties, schemaForUpdate))
      )
    }

    this.isProgressFinish = true
    //allow err alerts
    this.notificationService.avoidErrorAlerts = false

    // hide suspend button, show close btn
    this.updating = false
    this.isShowingCloseBtn = true
  }

  async update(product, schema) {
    await this.core.services.product.updateProductPart(product, schema)
  }

  setTagsOnProductToUpdate(product) {
    product.tags = []
    if (this.tagSelectedOption === 'keep.current.set.selected') {
      this.productChanges.tags.filter(tag => {
        if (!product.tags.find(t => t.id === tag.id)) product.tags.push(tag)
      })
    } else if (this.tagSelectedOption === 'keep.current.remove.selected') {
      for (const changedTag of this.productChanges.tags) {
        if (product.tags.some(tag => tag.id === changedTag.id))
          product.tags = product.tags.filter(tag => tag.id !== changedTag.id)
      }
    } else if (this.tagSelectedOption === 'remove.current.add.selected') {
      product.tags = this.productChanges.tags
    }
    // TODO check jestli nejsou arays puvodni a na upravu stejne => neposilat tags
  }

  async fetchCategoriesOnProduct(product) {
    const productWithCategories = await this.core.services.product.getById(product.id, {
      categories: {
        id: null,
        externalId: null,
        name: null,
      },
    })
    product.categories = productWithCategories.categories
  }

  setCategoriesOnProductToUpdate(product) {
    product.categories = []
    if (this.categorySelectedOption === 'keep.current.set.selected') {
      if (product.categories?.length > 0) {
        this.productChanges.categories.filter(cat => {
          if (!product.categories.find(c => c.id === cat.id)) product.categories.push(cat)
        })
      } else product.categories = this.productChanges.categories
    } else if (this.categorySelectedOption === 'keep.current.remove.selected') {
      for (const changedCategory of this.productChanges.categories) {
        if (product.categories.some(cat => cat.id === changedCategory.id))
          product.categories = product.categories.filter(category => category.id !== changedCategory.id)
      }
    } else if (this.categorySelectedOption === 'remove.current.add.selected') {
      product.categories = this.productChanges.categories
    }
    // TODO check jestli nejsou arays puvodni a na upravu stejne => neposilat categories
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  filter(pageNo: number, sortProp: string, sortDir: string, concat: boolean): void {}
}
