<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="checkoutForm" (ngSubmit)="isCreating ? createCheckout() : updateCheckout()" *ngIf="checkout">
    <page-header
      [icon]="'fa-money-bill'"
      [title]="isCreating ? 'checkout.new' : 'checkout'"
      [appendNoTranslationTitlePart]="checkout ? checkout.name : ''"
    >
      <ng-container class="header-button" *ngIf="this.userHasRoleForCheckoutEdit">
        <btn
          [label]="'save'"
          [title]="'save' | translate"
          [classes]="['btn-first', 'float-right']"
          [icon]="'far-check'"
          [btnType]="'submit'"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-white'"
          [btnDisabled]="loading"
          [dataCyAttribute]="'create-checkout'"
        ></btn>
        <btn
          *ngIf="isInCypressTest"
          [btnType]="'submit'"
          [icon]="'far-trash-alt'"
          [label]="'delete'"
          [title]="'delete' | translate"
          [classes]="['btn-first-red', 'float-right']"
          (onEvent)="deleteCheckout()"
          [dataCyAttribute]="'delete-checkout'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <section class="content" *ngIf="!this.userHasRoleForCheckoutEdit">
      <div class="card">
        {{ 'user.does.not.have.role.checkout.edit' | translate }}
      </div>
    </section>
    <section class="content" *ngIf="this.userHasRoleForCheckoutEdit">
      <div class="card">
        <div class="row" *ngIf="this.userHasRoleForCheckoutEdit">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-12">
                <form-row-input
                  [label]="'checkout.name'"
                  [formGroup]="checkoutForm"
                  formControlName="name"
                  [required]="checkoutForm.get('name').errors !== null && checkoutForm.get('name').errors.required"
                  [requiredCssClass]="submitted && formControls.name.errors"
                  [noMarginBottom]="true"
                  [dataCyAttribute]="'name'"
                ></form-row-input>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <form-row-input
                  [noMarginBottom]="true"
                  [inputType]="'number'"
                  [(appendCheckboxValue)]="setNextBillNumber"
                  [appendToggleShow]="true"
                  [formGroup]="checkoutForm"
                  formControlName="nextBillNumber"
                  [inputDisabled]="!setNextBillNumber"
                  [label]="'checkout.nextBillNumber'"
                  [dataCyAttribute]="'nextBillNumber'"
                  [dataCyAttributeAppendToggle]="'nextBillNumber-appendToggle'"
                >
                </form-row-input>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <form-row-input
                  [noMarginBottom]="true"
                  [inputType]="'number'"
                  [(appendCheckboxValue)]="setNextReservationNumber"
                  [appendToggleShow]="true"
                  [formGroup]="checkoutForm"
                  formControlName="nextReservationNumber"
                  [inputDisabled]="!setNextReservationNumber"
                  [label]="'checkout.nextReservationNumber'"
                  [dataCyAttribute]="'nextReservationNumber'"
                  [dataCyAttributeAppendToggle]="'nextReservationNumber-appendToggle'"
                >
                </form-row-input>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <form-row-textarea
                  [label]="'checkout.billFooter'"
                  [(model)]="checkout.billFooter"
                  [noMarginBottom]="true"
                  [dataCyAttribute]="'text'"
                ></form-row-textarea>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <form-row-input
                  [label]="'checkout.amount'"
                  [icon]="'fa-money-bill'"
                  [formGroup]="checkoutForm"
                  formControlName="amount"
                  [noMarginBottom]="true"
                  [inputDisabled]="true"
                  [dataCyAttribute]="'checkout-amount'"
                >
                </form-row-input>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <h3 class="card-title">Zasílání událostí na vzdálenou URL</h3>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <form-row-input
                  [label]="'checkout.eventUrl'"
                  [formGroup]="checkoutForm"
                  formControlName="eventUrl"
                  [noMarginBottom]="true"
                ></form-row-input>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <label>Typy událostí zasílané na URL</label>
                <ng-container *ngFor="let eventType of eventTypes">
                  <div class="mb-2">
                    <!--                    TODO: TOMAS B. -->
                    <form-row-checkbox
                      [label]="eventType + '  '"
                      [cursorPointer]="true"
                      [model]="isEventTypeSelected(eventType)"
                      (modelChange)="toggleEventType($event, eventType)"
                      [dataCySelector]="'checkbox'"
                    >
                    </form-row-checkbox>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-12">
                <form-row-select
                  [label]="'returnsDepot'"
                  [icon]="'fa-undo'"
                  [options]="depotsForReturns"
                  formControlName="returnsDepot"
                  [dataCyAttribute]="'checkout-returnsDepot'"
                ></form-row-select>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <form-row-checkbox
                  [label]="'negativeReservations'"
                  [cursorPointer]="true"
                  [formGroup]="checkoutForm"
                  [dataCySelector]="'checkbox'"
                  formControlName="negativeReservation"
                >
                </form-row-checkbox>
              </div>
            </div>
            <div>
              <form-row-checkbox
                [label]="'checkout.autoPicking'"
                [cursorPointer]="true"
                [formGroup]="checkoutForm"
                formControlName="autoPicking"
                [dataCySelector]="'checkbox'"
              >
              </form-row-checkbox>
              <form-row-checkbox
                [label]="'checkout.autoPacking'"
                [cursorPointer]="true"
                [formGroup]="checkoutForm"
                formControlName="autoPacking"
                [dataCySelector]="'checkbox'"
              >
              </form-row-checkbox>
              <div class="padding-left-3 label-bold">{{ 'checkout.autoPacking.bracket' | translate }}</div>
              <form-row-checkbox
                [label]="'checkout.autoDispatched'"
                [cursorPointer]="true"
                [formGroup]="checkoutForm"
                formControlName="autoDispatched"
                [dataCySelector]="'checkbox'"
              >
              </form-row-checkbox>
              <div class="padding-left-3 label-bold">{{ 'checkout.autoDispatched.bracket' | translate }}</div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>{{ 'depots' | translate }}:</label>
                  <div class="mt-2" *ngFor="let depot of depots">
                    <form-row-checkbox
                      [label]="depot.name"
                      [cursorPointer]="true"
                      (change)="toggleDepot(depot, $event)"
                      [model]="isChosenDepot(depot)"
                      [dataCySelector]="'checkbox'"
                    >
                    </form-row-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>{{ 'payments' | translate }}:</label>
                  <div class="mb-2" *ngFor="let payment of payments">
                    <form-row-checkbox
                      [label]="payment.name"
                      [cursorPointer]="true"
                      (change)="togglePayment(payment, $event)"
                      [model]="isChosenPayment(payment)"
                      [dataCySelector]="'checkbox'"
                    >
                    </form-row-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
