<modal-container #modal [headerIconClass]="'far-box'" [headerTitle]="'price.new'" [isLargeModal]="true">

  <ng-container class="modal-button-show">
    <btn
      [classes]="['btn-first', 'float-right']"
      [icon]="'far-plus'"
      [label]="isCreating? 'priceNew.create' : 'edit'"
      [title]="isCreating? ('priceNew.create' | translate ) : ('edit' | translate )"
      (onEvent)="showChildModal()"
      [dataCyAttribute]="dataCyAttribute"
    ></btn>
  </ng-container>

  <ng-container class="modal-body-content" *ngIf="isCreating">
    <div class="card">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>

      <div class="row">
        <div class="col-6">
          <form-row-input
            [label]="'price'"
            [icon]="'fa-money-bill'"
            [(model)]="advancedPrice.sellPrice"
            [replaceComma]="true"
            [dataCyAttribute]="'product-price-sell-price'"
          ></form-row-input>
        </div>
        <div class="col-6">
          <form-row-input
            [label]="'quantity'"
            [icon]="'far-tachometer'"
            [(model)]="advancedPrice.available"
            [inputType]="'number'"
            [dataCyAttribute]="'product-price-available'"
          ></form-row-input>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <form-row-input
            [label]="'date.from'"
            [icon]="'fa-calendar'"
            [inputType]="'date'"
            [(model)]="advancedPrice.dateFrom"
            [inputRequired]="true"
            [dataCyAttribute]="'product-price-date-from'"
          ></form-row-input>
        </div>
        <div class="col-6">
          <form-row-input
            [label]="'date.to'"
            [icon]="'fa-calendar'"
            [inputType]="'date'"
            [(model)]="advancedPrice.dateTo"
            [inputRequired]="true"
            [dataCyAttribute]="'product-price-date-to'"
          ></form-row-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <form-row-textarea
            [label]="'note'"
            [icon]="'text'"
            [(model)]="advancedPrice.note"
            [dataCyAttribute]="'product-price-note'"
          ></form-row-textarea>
        </div>
      </div>

    </div>

  </ng-container>

  <ng-container class="modal-body-content" *ngIf="!isCreating">
    <div class="card">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>

      <div class="row">
        <div class="col-6">
          <form-row-input
            [label]="'price'"
            [icon]="'fa-money-bill'"
            [(model)]="productAdvancedPrices.sellPrice"
            [replaceComma]="true"
          ></form-row-input>
        </div>

        <div class="col-6">
          <form-row-input
            [label]="'quantity'"
            [icon]="'far-tachometer'"
            [(model)]="productAdvancedPrices.available"
            [inputType]="'number'"
          ></form-row-input>
        </div>

        <div class="col-6">
          <form-row-input
            [label]="'date.from'"
            [icon]="'fa-calendar'"
            [inputType]="'date'"
            [(model)]="productAdvancedPrices.dateFrom"
            [inputRequired]="true"
          ></form-row-input>
        </div>
        <div class="col-6">
          <form-row-input
            [label]="'date.to'"
            [icon]="'fa-calendar'"
            [inputType]="'date'"
            [(model)]="productAdvancedPrices.dateTo"
            [inputRequired]="true"
          ></form-row-input>
        </div>
        <div class="col-12">
          <form-row-textarea
            [label]="'note'"
            [icon]="'text'"
            [(model)]="productAdvancedPrices.note"
          ></form-row-textarea>
        </div>
        </div>
      </div>

  </ng-container>

  <ng-container class="modal-footer-content">
    <btn
      *ngIf="!isCreating"
      [label]="'delete'"
      [title]="'delete' | translate"
      [icon]="'far-trash-alt'"
      [classes]="['btn-first-red']"
      (onEvent)="deleteProductPrice(productAdvancedPrices)"
    ></btn>
    <btn
      [label]="isCreating ? 'save' : product.children && product.children.length > 0 ? 'create.on.variants' : 'update'"
      [title]="isCreating ?( 'save' | translate ) : product.children && product.children.length > 0 ? ('create.on.variants' | translate) : ('update' | translate)"
      [icon]="isCreating ? 'far-check' : 'far-dollar-sign'"
      [classes]="['btn-first']"
      [dataCyAttribute]="'product-price-create'"
      (onEvent)="isCreating ? createProductPrice() : product.children && product.children.length > 0 ? updateProductChildrenWithPrice(productAdvancedPrices) : updateProductPrice(productAdvancedPrices)"
    ></btn>
  </ng-container>

</modal-container>
