<modal-container #modal [headerTitle]="'bulk.edit'" [headerIconClass]="'far-edit'" [isExtraLargeModal]="true">
  <ng-container class="modal-button-show">
    <btn
      [label]="'bulk.edit'"
      [title]="'bulk.edit' | translate"
      [classes]="['btn-third', 'float-right', 'me-2']"
      [icon]="'far-edit'"
      [btnType]="'button'"
      (click)="showChildModal()"
      [attr.data-cy]="dataCyAttribute"
    >
    </btn>
  </ng-container>

  <ng-container class="modal-body-content">
    <div class="flex-column">
      <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>

      <div class="flex-gap-10 flex-row">
        <div class="flex-column w-33">
          <div class="card">
            <div class="font-weight">{{ 'productMoves.load.list' | translate }}</div>

            <div class="row mt-2">
              <div class="flex-gap-5 flex-row">
                <div class="col">
                  <form-row-input
                    [label]="'purchasePrice.default'"
                    [class]="'input-unit'"
                    [icon]="'fa-money-bill'"
                    [(model)]="productChanges.purchasePrice"
                    [replaceComma]="true"
                    [dataCyAttribute]="'product-price-default'"
                  ></form-row-input>
                </div>
                <div class="col">
                  <form-row-select
                    [label]="'currency.default'"
                    [icon]="'far-coins'"
                    [(model)]="productChanges.purchaseCurrency"
                    [options]="currencies"
                    [dataCyAttribute]="'product-currency-select'"
                  ></form-row-select>
                </div>
              </div>
              <div class="col">
                <form-row-select
                  [(model)]="productChanges.supplier"
                  [label]="'supplier.default'"
                  [icon]="'fa-person-carry'"
                  [options]="suppliers"
                  [dataCyAttribute]="'product-supplier-select'"
                ></form-row-select>
              </div>
            </div>
            <div>
              <form-row-select
                [label]="'product.vatrate'"
                [(model)]="productChanges.vat"
                [options]="vats"
                [dataCyAttribute]="'product-vat-select'"
                class="margin-bottom"
              >
              </form-row-select>
            </div>
          </div>

          <div class="card">
            <div class="font-weight">
              {{ 'logistic.details' | translate }}
            </div>
            <app-product-logistic-details
              class="margin-bottom"
              [isInBulkEdits]="true"
              [product]="productChanges"
              (valueAddedChange)="valueAdded($event)"
            >
            </app-product-logistic-details>
            <div>
              <div>
                <form-row-select
                  [label]="'fragile'"
                  [model]="isFragileSelectedOption"
                  (modelChange)="booleanChange($event, 'isFragile')"
                  [options]="booleanSelectOptions"
                  [dataCyAttribute]="'product-is-fragile'"
                >
                </form-row-select>
              </div>
              <div>
                <form-row-select
                  [label]="'oversize'"
                  [model]="isOversizeSelectedOption"
                  (modelChange)="booleanChange($event, 'isOversize')"
                  [options]="booleanSelectOptions"
                  [dataCyAttribute]="'product-is-fragile'"
                >
                </form-row-select>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="font-weight">
              {{ 'enable' | translate }}
            </div>
            <form-row-select
              [(model)]="isEnabledOption"
              [options]="booleanSelectOptions"
              [dataCyAttribute]="'modal-bulk-edits.product-enabled'"
            >
            </form-row-select>
          </div>
        </div>

        <div class="w-33">
          <div class="card">
            <div class="font-weight">
              {{ 'priceAction.create.fixed-price' | translate }}
            </div>

            <div class="row mt-2">
              <div class="flex-gap-5 flex-row">
                <div class="col">
                  <form-row-input
                    [label]="'price'"
                    [icon]="'fa-money-bill'"
                    [(model)]="advancedPrice.sellPrice"
                    [replaceComma]="true"
                    [dataCyAttribute]="'advancedPrice-sellPrice-input'"
                  ></form-row-input>
                </div>
                <div class="col">
                  <form-row-input
                    [label]="'product.qty'"
                    [icon]="'fa-money-bill'"
                    [(model)]="advancedPrice.available"
                    [replaceComma]="true"
                    [dataCyAttribute]="'advancedPrice-available-input'"
                  ></form-row-input>
                </div>
              </div>
              <div class="flex-gap-5 flex-row">
                <div class="col">
                  <form-row-input
                    [label]="'date.from'"
                    [icon]="'fa-calendar'"
                    [inputType]="'date'"
                    [(model)]="advancedPrice.dateFrom"
                    [inputRequired]="true"
                    [dataCyAttribute]="'advancedPrice-dateFrom-input'"
                  ></form-row-input>
                </div>
                <div class="col">
                  <form-row-input
                    [label]="'date.to'"
                    [icon]="'fa-calendar'"
                    [inputType]="'date'"
                    [(model)]="advancedPrice.dateTo"
                    [inputRequired]="true"
                    [dataCyAttribute]="'advancedPrice-dateTo-input'"
                  ></form-row-input>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="font-weight">
              {{ 'priceAction.create.percentage-sale' | translate }}
            </div>

            <div class="row mt-2">
              <div class="flex-gap-5 flex-row">
                <div class="col">
                  <form-row-input
                    [label]="'productPriceLevel.percent.sale'"
                    [icon]="'fa-money-bill'"
                    [(model)]="percentageDiscount.percent"
                    [replaceComma]="true"
                    [dataCyAttribute]="'percentageDiscount-percent-input'"
                  ></form-row-input>
                </div>
                <div class="col">
                  <form-row-input
                    [label]="'product.qty'"
                    [icon]="'fa-money-bill'"
                    [(model)]="percentageDiscount.available"
                    [replaceComma]="true"
                    [dataCyAttribute]="'percentageDiscount-available-input'"
                  ></form-row-input>
                </div>
              </div>
              <div class="flex-gap-5 flex-row">
                <div class="col">
                  <form-row-input
                    [label]="'date.from'"
                    [icon]="'fa-calendar'"
                    [inputType]="'date'"
                    [(model)]="percentageDiscount.dateFrom"
                    [inputRequired]="true"
                    [dataCyAttribute]="'percentageDiscount-dateFrom-input'"
                  ></form-row-input>
                </div>
                <div class="col">
                  <form-row-input
                    [label]="'date.to'"
                    [icon]="'fa-calendar'"
                    [inputType]="'date'"
                    [(model)]="percentageDiscount.dateTo"
                    [inputRequired]="true"
                    [dataCyAttribute]="'percentageDiscount-dateTo-input'"
                  ></form-row-input>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="font-weight">
              {{ 'tags' | translate }}
            </div>

            <div>
              <form-row-select [title]="'tags'" [(model)]="tagSelectedOption" [options]="selectOptions">
              </form-row-select>
            </div>

            <div *ngFor="let tag of tags">
              <form-row-checkbox
                [isInBulkEdits]="true"
                [label]="tag?.name"
                [model]="getTagValue(tag)"
                (modelChange)="updateTags(tag, $event)"
                [cursorPointer]="true"
                [dataCySelector]="'tag-checkbox'"
              >
              </form-row-checkbox>
            </div>
          </div>
        </div>

        <div class="flex-column w-33">
          <div class="card">
            <div class="font-weight">
              {{ 'mainCategory' | translate }}
            </div>

            <div>
              <form-row-select
                [title]="'mainCategory'"
                [(model)]="mainCategorySelectedOption"
                [options]="mainCategorySelectOptions"
              >
              </form-row-select>
            </div>

            <category-tree
              *ngIf="mainCategorySelectedOption === 'set.selected' && isModalShown"
              [isInBulkEdits]="true"
              [categoryTreeLabel]="'product.tab.categories.mainCategory'"
              [type]="categoryTreeTypeEnum.radioProductMainCategory"
              [categories]="categories"
              (mainCategoryChange)="mainCategoryChanged($event)"
            ></category-tree>
          </div>

          <div class="card">
            <div class="font-weight">
              {{ 'categories' | translate }}
            </div>

            <div>
              <form-row-select [title]="'categories'" [(model)]="categorySelectedOption" [options]="selectOptions">
              </form-row-select>
            </div>

            <category-tree
              *ngIf="isModalShown"
              [isInBulkEdits]="true"
              [categoryTreeLabel]="'product.tab.categories.categories'"
              [type]="categoryTreeTypeEnum.checkboxProductCategories"
              [categories]="categories"
              [(selectedCategories)]="productChanges.categories"
            >
            </category-tree>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container class="progress-overlay modal-body-content" *ngIf="isShowingProgress">
    <div class="progressbar-wrapper">
      <div class="card header-div">
        <button
          data-cy="close"
          type="button"
          class="close close-btn"
          aria-label="Close"
          (click)="close()"
          [title]="'close' | translate"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="progress-header">
          <div class="progressbar-div">
            <progress
              [ngClass]="isProgressFinish ? 'progress-finish' : 'progress-loading'"
              class="progress-bar-2"
              role="progressbar"
              [max]="numberOfAllProducts"
              [value]="numberOfUpdatedProducts"
            ></progress>
            <div *ngIf="updating">{{ numberOfUpdatedProducts + '/' + numberOfAllProducts }}</div>
            <div *ngIf="isProgressFinish" class="font-weight done">
              {{ 'done' | translate }}
              <i class="fa fa-check"></i>
            </div>
          </div>

          <btn
            *ngIf="!isProgressFinish"
            [label]="'suspend'"
            [title]="'suspend' | translate"
            [icon]="'far fa-ban'"
            [classes]="['btn-first']"
            (onEvent)="suspend()"
          >
          </btn>
        </div>

        <div class="error-list-div" *ngIf="updateErrors?.length > 0">
          <hr />
          <div class="font-weight text-center margin-bottom-10">
            {{ 'failed.to.edit' | translate }}
          </div>

          <div *ngFor="let err of updateErrors" class="error-message-div">
            <a class="link product-name" [routerLink]="['/product', err.productId]">
              {{ err.productName }}
            </a>
            <div class="error-message">{{ err.errMessage }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container class="modal-footer-content">
    <btn
      [label]="'edit'"
      [title]="'edit' | translate"
      [icon]="'far-check'"
      [classes]="['btn-first']"
      (onEvent)="save()"
      [dataCyAttribute]="'bulk-edits-submit'"
    ></btn>
  </ng-container>
</modal-container>
