<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form
    [formGroup]="companyCarrierForm"
    (ngSubmit)="isCreating ? createCompanyCarrier() : updateCompanyCarrier()"
    *ngIf="companyCarrier"
  >
    <page-header
      [title]="isCreating ? 'companyCarrier.new' : ''"
      [appendNoTranslationTitlePart]="companyCarrier ? companyCarrier.name : ''"
      [icon]="'far-envelope'"
    >
      <ng-container class="header-button">
        <btn
          [label]="'save'"
          [title]="'save' | translate"
          [classes]="['btn-first', 'float-right']"
          [icon]="'far-check'"
          [btnType]="'submit'"
          [btnDisabled]="companyCarrierForm.invalid || loading"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-white'"
          [dataCyAttribute]="'create-carrier'"
        ></btn>
        <btn
          [label]="'delete'"
          [title]="'delete' | translate"
          [classes]="['btn-first-red', 'float-right', isCreating ? '' : 'me-2']"
          [icon]="'far-trash-alt'"
          (onEvent)="deleteCompanyCarrier()"
          *ngIf="!isCreating"
          [dataCyAttribute]="'delete-carrier'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <section class="content">
      <div class="card" [ngClass]="{ h150: loading }">
        <div class="row">
          <div class="col-sm-6" *ngIf="!!companyCarrier">
            <form-row-select
              [label]="'carrier'"
              [icon]="null"
              [model]="companyCarrier.carrier ? companyCarrier.carrier.id : 0"
              [options]="carriers"
              (modelChange)="updateCarrier($event)"
              [dataCyAttribute]="'companyCarrier-carrier'"
            ></form-row-select>
            <form-row-select
              [label]="'checkout'"
              [icon]="null"
              [model]="companyCarrier.checkout ? companyCarrier.checkout.id : 0"
              [options]="checkouts"
              (modelChange)="updateCheckout($event)"
              [dataCyAttribute]="'companyCarrier-checkout'"
            ></form-row-select>
            <form-row-checkbox
              [formGroup]="companyCarrierForm"
              [label]="'enable'"
              formControlName="enable"
              [(model)]="companyCarrier.enable"
            ></form-row-checkbox>
          </div>

          <div class="flex-gap-10 flex-column col-sm-6" *ngIf="hasOptionsObjAnyKeys()">
            <h3 class="card-title">{{ 'carrier.api.settings' | translate }}</h3>

            <div
              *ngIf="
                optionsType === 'cp' || optionsType === 'cp_np' || optionsType === 'cp_nb' || optionsType === 'cp_bn'
              "
            >
              <form-row-input
                [label]="'carrier.customerId'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.customerID"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.contractNumber'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.contractNumber"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.postCode'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.postCode"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.locationNumber'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.locationNumber"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.apiToken'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.apiToken"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.apiSecret'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.apiSecret"
              ></form-row-input>
            </div>

            <div *ngIf="optionsType === 'dhl_express'">
              <form-row-input
                [label]="'username'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.username"
              ></form-row-input>
              <form-row-input
                [label]="'password'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.password"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.accountNumber'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.account_number"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.contentDescription'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.content_description"
              ></form-row-input>
            </div>

            <div *ngIf="optionsType === 'dpd'">
              <form-row-input
                [label]="'carrier.customerId'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.customerId"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.customerAddressId'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.customerAddressId"
              ></form-row-input>
              <form-row-input
                [label]="'username'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.username"
              ></form-row-input>
              <form-row-input
                [label]="'password'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.password"
              ></form-row-input>
            </div>

            <div *ngIf="optionsType === 'fedex'">
              <form-row-input
                [label]="'carrier.key'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.key"
                [dataCyAttribute]="'companyCarrier-key'"
              ></form-row-input>
              <form-row-input
                [label]="'password'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.password"
                [dataCyAttribute]="'companyCarrier-password'"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.accountNumber'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.account_number"
                [dataCyAttribute]="'companyCarrier-accountNumber'"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.meterNumber'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.meter_number"
                [dataCyAttribute]="'companyCarrier-meterNumber'"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.product.desc'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.product_desc"
                [dataCyAttribute]="'companyCarrier-productDesc'"
              ></form-row-input>
            </div>

            <div *ngIf="optionsType === 'gls'">
              <form-row-input
                [label]="'username'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.username"
              ></form-row-input>
              <form-row-input
                [label]="'password'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.password"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.gls.clientNumber'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.clientNumber"
              ></form-row-input>
              <form-row-checkbox [label]="'myGls'" [(model)]="optionsObj.myGls"></form-row-checkbox>
            </div>

            <div *ngIf="optionsType === 'ppl'">
              <form-row-input
                [label]="'username'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.username"
                [dataCyAttribute]="'companyCarrier-username'"
              ></form-row-input>
              <form-row-input
                [label]="'password'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.password"
                [dataCyAttribute]="'companyCarrier-password'"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.customerId'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.customerId"
                [dataCyAttribute]="'companyCarrier-customerId'"
              ></form-row-input>
              <form-row-input
                [label]="'depo'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.depo"
                [dataCyAttribute]="'companyCarrier-depo'"
              ></form-row-input>

              <div>
                <div class="display-flex justify-content-between align-items-baseline">
                  <h3 class="card-title">{{ 'carrier.numberSequences' | translate }}</h3>
                  <btn
                    [label]="'add.row'"
                    [title]="'add.row' | translate"
                    [classes]="['btn-first']"
                    (onEvent)="addPplNumberRow()"
                  ></btn>
                </div>
                <div class="row" *ngFor="let n of optionsObj.numbersArr">
                  <div class="col-5">
                    <form-row-select
                      [label]="'tariff'"
                      [required]="true"
                      [icon]="null"
                      [(model)]="n.tariffId"
                      [options]="companyCarrier.carrier.tariffs"
                      [optionKey]="'const'"
                      [dataCyAttribute]="'companyCarrier-tariffId'"
                    ></form-row-select>
                  </div>
                  <div class="col-3">
                    <form-row-input
                      [label]="'date.from'"
                      [required]="true"
                      [icon]="null"
                      [inputType]="'number'"
                      [(model)]="n.from"
                      [dataCyAttribute]="'companyCarrier-from'"
                    ></form-row-input>
                  </div>
                  <div class="col-3">
                    <form-row-input
                      [label]="'date.to'"
                      [required]="true"
                      [icon]="null"
                      [inputType]="'number'"
                      [(model)]="n.to"
                      [dataCyAttribute]="'companyCarrier-to'"
                    ></form-row-input>
                  </div>
                  <div class="col-1 fix-vertical-align">
                    <btn
                      [icon]="'far-trash-alt'"
                      [title]="'delete' | translate"
                      [classes]="['btn-first-red', 'btn-sm']"
                      (onEvent)="removePplNumberRow(n)"
                    ></btn>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="optionsType === 'spring_xbs'">
              <form-row-input
                [label]="'apiKey'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.apikey"
              ></form-row-input>
            </div>

            <div *ngIf="optionsType === 'zasilkovna'">
              <div class="row">
                <div class="col-12">
                  <form-row-input
                    [label]="'carrier.eshop'"
                    [required]="true"
                    [icon]="null"
                    [(model)]="optionsObj.eshop"
                    data-cy="eshop"
                    [dataCyAttribute]="'eshop'"
                  ></form-row-input>
                </div>
                <div class="col-12">
                  <form-row-input
                    [label]="'carrier.apiPassword'"
                    [required]="true"
                    [icon]="null"
                    [(model)]="optionsObj.api_password"
                    data-cy="api-password"
                    [dataCyAttribute]="'api-password'"
                  ></form-row-input>
                </div>
              </div>
            </div>

            <div *ngIf="optionsType === 'ulozenka'">
              <div class="row">
                <div class="col-12">
                  <form-row-input
                    [label]="'carrier.shopId'"
                    [required]="true"
                    [icon]="null"
                    [(model)]="optionsObj.shopId"
                  ></form-row-input>
                </div>
                <div class="col-12">
                  <form-row-input
                    [label]="'apiKey'"
                    [required]="true"
                    [icon]="null"
                    [(model)]="optionsObj.apiKey"
                  ></form-row-input>
                </div>
              </div>
            </div>

            <div *ngIf="optionsType === 'shippypro'">
              <div class="row">
                <div class="col-12">
                  <form-row-input
                    [label]="'apiKey'"
                    [required]="true"
                    [icon]="null"
                    [(model)]="optionsObj.apikey"
                  ></form-row-input>
                </div>
                <div class="col-12">
                  <form-row-input
                    [label]="'carrier.contentDescription'"
                    [required]="true"
                    [icon]="null"
                    [(model)]="optionsObj.contentDescription"
                  ></form-row-input>
                </div>
                <div class="col-12">
                  <form-row-input
                    [label]="'packageLength'"
                    [required]="true"
                    [icon]="null"
                    [inputType]="'number'"
                    [tooltipLabel]="'v centimetrech'"
                    [(model)]="optionsObj.packageLength"
                  ></form-row-input>
                </div>
                <div class="col-12">
                  <form-row-input
                    [label]="'packageWidth'"
                    [required]="true"
                    [icon]="null"
                    [inputType]="'number'"
                    [tooltipLabel]="'v centimetrech'"
                    [(model)]="optionsObj.packageWidth"
                  ></form-row-input>
                </div>
                <div class="col-12">
                  <form-row-input
                    [label]="'packageHeight'"
                    [required]="true"
                    [icon]="null"
                    [inputType]="'number'"
                    [tooltipLabel]="'v centimetrech'"
                    [(model)]="optionsObj.packageHeight"
                  ></form-row-input>
                </div>
                <div class="col-12">
                  <form-row-input
                    [label]="'packageWeight'"
                    [required]="true"
                    [icon]="null"
                    [inputType]="'number'"
                    [tooltipLabel]="'v kilogramech'"
                    [(model)]="optionsObj.packageWeight"
                  ></form-row-input>
                </div>
              </div>
            </div>

            <div *ngIf="optionsType === 'wedo_home' || optionsType === 'wedo_point' || optionsType === 'wedo_box'">
              <form-row-input
                [label]="'username'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.username"
              ></form-row-input>
              <form-row-input
                [label]="'password'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.password"
              ></form-row-input>
              <form-row-input
                [label]="'carrier.customerId'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.customerId"
              ></form-row-input>
              <form-row-input
                [label]="'department'"
                [required]="true"
                [icon]="null"
                [(model)]="optionsObj.department"
              ></form-row-input>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
