<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-3 ps-0 no-p-767">
      <div class="card-smaller card">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column align-items-start">
            <div class="available-number" data-cy="quantity-stock">
              {{ productDetailService.product.quantityStock | number: '1.0-2' | replaceCommaToDot }}
            </div>
            <div class="available-amount">
              {{ 'totalInStock' | translate }}
            </div>
          </div>
          <div>
            <div class="circle-blue">
              <span class="circle-blue-icon"><i class="far fa-warehouse-alt"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3 no-p-767">
      <div class="card-smaller card">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column align-items-start">
            <div class="available-number" data-cy="quantity-reservation">
              {{ productDetailService.product.quantityReservation | number: '1.0-2' | replaceCommaToDot }}
            </div>
            <div class="available-amount">
              {{ 'totalReservation' | translate }}
            </div>
          </div>
          <div>
            <div class="circle-red">
              <span class="circle-red-icon"><i class="far fa-hourglass"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3 no-p-767">
      <div class="card-smaller card">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column align-items-start">
            <div class="available-number" data-cy="quantity-available">
              {{ productDetailService.product.quantityAvailable | number: '1.0-2' | replaceCommaToDot }}
            </div>
            <div class="available-amount">
              {{ 'totalAvailable' | translate }}
            </div>
          </div>
          <div>
            <div class="circle-green">
              <span class="circle-green-icon"><i class="far fa-check"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3 no-p-767">
      <div class="card-smaller card">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column align-items-start">
            <div class="available-number" data-cy="quantity-purchase">
              {{ productDetailService.product.quantityPurchase | number: '1.0-2' | replaceCommaToDot }}
            </div>
            <div class="available-amount">
              {{ 'goodsOnTheWay' | translate }}
            </div>
          </div>
          <div>
            <div class="circle-orange">
              <span class="circle-orange-icon"><i class="far fa-truck"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3 d-flex flex-row justify-content-between align-items-center mb-3 no-p-767">
      <form-row-toggle
        class="align-self-center"
        [label]="'isShowingNullPositions'"
        [(model)]="isShowingNullStock"
        [classesString]="'text-nowrap'"
      ></form-row-toggle>
      <btn [icon]="'fa-sync'" [title]="'product.stock.refresh' | translate" (onEvent)="refreshDepotStats()"></btn>
    </div>
  </div>
</div>

<div *ngIf="productDetailService.isStockEmptyYet" class="m-3 text-center">
  {{ 'no.stock.records.yet' | translate }}
</div>
<div *ngIf="!productDetailService.stock || productDetailService.stock?.length === 0" class="text-center">
  {{ 'no.records' | translate }}
</div>

<div *ngIf="!productDetailService.isStockEmptyYet && productDetailService.stock?.length > 0">
  <div *ngFor="let stockItem of productDetailService.stock">
    <div class="card" data-cy="stockItem">
      <div class="justify-content-center flex-gap-5">
        <div class="availability-icons">
          <div class="flex-gap-5 align-items-center">
            <div>
              <i class="far fa-warehouse-alt"></i>
            </div>
            <div data-cy="stockItem-sumAll">
              {{ stockItem.sumAll | number: '1.0-2' | replaceCommaToDot }}
            </div>
          </div>

          <div class="flex-gap-5 align-items-center">
            <div>
              <i class="far fa-hourglass"></i>
            </div>
            <div data-cy="stockItem-sumReservations">
              {{ stockItem.sumReservations | number: '1.0-2' | replaceCommaToDot }}
            </div>
          </div>

          <div class="flex-gap-5 align-items-center">
            <div>
              <i class="far fa-check"></i>
            </div>
            <div data-cy="stockItem-sumAvailable">
              {{ stockItem.sumAvailable | number: '1.0-2' | replaceCommaToDot }}
            </div>
          </div>
          <div class="flex-gap-5 align-items-center">
            <div>
              <i class="far fa-truck"></i>
            </div>
            <div>
              {{ productDetailService.product | quantityPurchase: stockItem.depot.id | number: '1.0-2' }}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <app-modal-product-depot-metric [product]="productDetailService.product" [stockItem]="stockItem">
          </app-modal-product-depot-metric>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table m-0">
          <thead>
            <tr>
              <th class="text-start ps-0 pt-0">
                <div class="flex-direction-column-gap-20">
                  <div>
                    <h2 class="mb-0 align-self-center card-title text-nowrap">
                      {{ 'depot' | translate }} {{ stockItem.depot ? stockItem.depot.name : stockItem.depot.name }}
                    </h2>
                  </div>
                  <div>
                    {{ 'supplier' | translate }}
                  </div>
                </div>
              </th>
              <th>{{ 'product.batch' | translate }}</th>
              <th>{{ 'product.expirationDate' | translate }}</th>
              <th class="text-center">{{ 'position' | translate }}</th>
              <th class="text-end text-nowrap">{{ 'purchasePrice' | translate }}</th>
              <th class="text-center pt-0">
                <div class="flex-direction-column-gap-20">
                  <div>
                    {{ 'products.availability' | translate }}
                  </div>
                </div>
              </th>
              <th class="text-start">{{ 'lastChange' | translate }}</th>
              <th class="fixed-column-width">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" *ngIf="!isShowingNullStock && areJustNullQuantitiesInTable(stockItem)">
              <td colspan="12">
                {{ 'no.items' | translate }}
              </td>
            </tr>
            <ng-container *ngFor="let pDepot of stockItem.productDepots; let i = index; trackBy: trackByFn">
              <ng-template
                [ngIf]="isShowingNullStock || !(pDepot.quantityStock == 0 && pDepot.quantityReservation == 0)"
              >
                <tr>
                  <td class="ps-1 text-nowrap" data-cy="pDepot-supplier">
                    {{ pDepot.supplier ? pDepot.supplier.name : '' }}
                  </td>
                  <td class="text-nowrap" data-cy="pDepot-batch">
                    {{ pDepot.batch }}
                  </td>
                  <td data-cy="pDepot-expirationDate">
                    <span *ngIf="pDepot.expirationDate">{{ pDepot.expirationDate | fmtDate: 'dd.MM.yyyy' }}</span>
                  </td>
                  <td class="text-center text-nowrap" data-cy="pDepot-position" *ngIf="pDepot.position">
                    {{ pDepot.position }}
                  </td>
                  <td class="text-end text-nowrap" data-cy="pDepot-purchasePrice">
                    {{ pDepot.purchasePrice | formatPrice }}
                  </td>
                  <td class="text-center text-nowrap">
                    <span class="quantityStock badge badge-pill" data-cy="pDepot-quantityStock">{{
                      pDepot.quantityStock | number: '1.0-2' | replaceCommaToDot
                    }}</span>
                    <span class="quantityReservation badge badge-pill" data-cy="pDepot-quantityReservation">{{
                      pDepot.quantityReservation | number: '1.0-2' | replaceCommaToDot
                    }}</span>
                    <span class="quantityAvailable badge badge-pill" data-cy="pDepot-quantityAvailable">
                      {{ pDepot.quantityAvailable | number: '1.0-2' | replaceCommaToDot }}</span
                    >
                  </td>
                  <td class="text-start text-nowrap">
                    {{ pDepot.updated | date: 'dd.MM.yyyy HH:mm' }}
                  </td>
                  <td>
                    <div class="flex-gap-5 align-items-center">
                      <modal-stock-load
                        *ngIf="!productDetailService.product.isBundle && pDepot.quantityStock > 0"
                        [product]="productDetailService.product"
                        [depots]="productDetailService.depots"
                        [suppliers]="productDetailService.suppliers"
                        [currencies]="productDetailService.currencies"
                        [isUnloadingStock]="true"
                        [currentStockItem]="pDepot"
                        (result)="modalStockResult()"
                      ></modal-stock-load>
                      <modal-stock-load
                        *ngIf="!productDetailService.product.isBundle"
                        [product]="productDetailService.product"
                        [depots]="productDetailService.depots"
                        [suppliers]="productDetailService.suppliers"
                        [currencies]="productDetailService.currencies"
                        [isUnloadingStock]="false"
                        [currentStockItem]="pDepot"
                        (result)="modalStockResult()"
                      ></modal-stock-load>
                      <modal-stock-positions
                        [(productDepot)]="stockItem.productDepots[i]"
                        [suppliers]="productDetailService.suppliers"
                        [productDepot]="pDepot"
                        (result)="modalStockResult()"
                      ></modal-stock-positions>
                      <btn [icon]="'fa-qrcode'" (onEvent)="printQR(pDepot)"></btn>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
