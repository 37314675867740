<div class="order-container">
  <page-header
    [icon]="'far-shopping-cart'"
    *ngIf="order"
    [isOrderLayout]="
      (orderDetailService.canCreateOrEdit && orderDetailService.activeLink === '') ||
      (order && order.isEditable && !orderDetailService.canCreateOrEdit)
    "
    [isHeadingNoWrap]="true"
  >
    <ng-container class="custom-title">
      <ng-template [ngIf]="order.id === 0">
        <span *ngIf="orderDetailService.activeLink !== ''">{{ 'order.stock.new' | translate }}</span>
        <span *ngIf="orderDetailService.activeLink === ''">{{ order?.customer?.name || '' }}</span>
      </ng-template>
      <ng-template [ngIf]="order.reservationNumber > 0 || order.billNumber > 0">
        <span data-cy="reservation-number">
          {{ order.reservationNumber > 0 ? ('reservation' | translate) + ' ' + order.reservationNumber : '' }}
          {{ order.reservationNumber > 0 && order.billNumber > 0 ? ' - ' : '' }}
          {{ order.billNumber > 0 && !order?.relatedParent?.id ? ('bill' | translate) + ' ' + order.billNumber : '' }}
        </span></ng-template
      >
      <ng-template [ngIf]="order.billNumber && order.relatedParent?.id">
        {{
          ('corrective.tax.document' | translate) +
            ' ' +
            order.billNumber +
            (order.relatedParent?.billNumber ? ' ' + ('forBillNo' | translate) + order.relatedParent?.billNumber : '')
        }}
      </ng-template>
    </ng-container>
    <ng-container class="header-button">
      <div class="row">
        <div class="col-12">
          <btn
            *ngIf="orderDetailService.canCreateOrEdit && orderDetailService.activeLink === ''"
            [label]="'save'"
            [title]="'save' | translate"
            [classes]="['float-right', 'btn-first']"
            [icon]="'far-check'"
            [isLoading]="orderDetailService.loading"
            [spinnerClassesString]="'spinner-white'"
            (onEvent)="orderDetailService.isCreating ? createOrder() : updateOrder()"
            [dataCyAttribute]="'save'"
          ></btn>
          <btn
            *ngIf="order.status && order.status.id === 'reservation' && !orderDetailService.isCreating"
            [label]="'order.confirm.and.send'"
            [title]="'order.confirm.and.send' | translate"
            [classes]="['btn-first', 'float-right', 'me-2']"
            [icon]="'far-cart-arrow-down'"
            [isLoading]="orderDetailService.loading"
            (onEvent)="confirmOrder()"
            [dataCyAttribute]="'confirm-and-send'"
          ></btn>
          <btn
            *ngIf="order.status && order.status.id === 'reservation' && !orderDetailService.isCreating"
            [label]="'reservation.delete'"
            [title]="'reservation.delete' | translate"
            [classes]="['btn-first-red', 'me-2', 'float-right']"
            [isLoading]="orderDetailService.loading"
            [icon]="'far-trash-alt'"
            (onEvent)="deleteReservation()"
            [dataCyAttribute]="'delete-order'"
          ></btn>
          <btn
            *ngIf="order && order.isEditable && !orderDetailService.canCreateOrEdit"
            [label]="'edit'"
            [title]="'edit' | translate"
            [classes]="['btn-first-orange', 'me-2', 'float-right']"
            [icon]="'far-edit'"
            (onEvent)="orderDetailService.canCreateOrEdit = !orderDetailService.canCreateOrEdit"
          ></btn>
          <btn
            *ngIf="
              !orderDetailService.isCreating &&
              !orderDetailService.canCreateOrEdit &&
              orderDetailService.canCreateOrEditNotes
            "
            [label]="'save'"
            [title]="'save' | translate"
            [classes]="['float-right', 'btn-first']"
            [icon]="'far-check'"
            [isLoading]="orderDetailService.loading"
            (onEvent)="updateOrder()"
            [dataCyAttribute]="'save'"
          ></btn>
          <button
            *ngIf="order.status?.id && !orderDetailService.isCreating"
            class="btn-third me-2 float-right dropdown-toggle"
            id="dropdownMenu2"
            data-bs-toggle="dropdown"
            data-cy="download-receipt-dropdown"
          >
            {{ 'print' | translate }}
          </button>
          <div class="dropdown-menu margin-right-150" aria-labelledby="dropdownMenu2">
            <button
              *ngIf="order.status?.id === 'bill'"
              class="dropdown-item"
              type="button"
              (click)="downloadInvoiceUrl()"
            >
              {{ 'invoice' | translate }} A4
            </button>
            <button
              *ngIf="order.status?.id === 'reservation' || !order.relatedParent?.id"
              class="dropdown-item"
              type="button"
              (click)="downloadReservationUrl()"
              data-cy="download-receipt"
            >
              {{ 'order-bill' | translate }} A4
            </button>
          </div>
          <btn
            *ngIf="orderDetailService.isCreating && orderDetailService.activeLink !== ''"
            [btnDisabled]="
              orderDetailService.activeLink === 'payment-info' &&
              (!this.order.checkout?.id || !this.order.carrier?.id || !this.order.currency?.id)
            "
            [icon]="'far-arrow-right'"
            [label]="orderDetailService.activeLink === 'customer' ? 'order.continueWithoutCustomer' : 'continue'"
            [title]="
              orderDetailService.activeLink === 'customer'
                ? ('order.continueWithoutCustomer' | translate)
                : ('continue' | translate)
            "
            [classes]="['btn-first', 'float-right']"
            (onEvent)="triggerNextStep()"
            [dataCyAttribute]="'continue'"
          ></btn>
        </div>
      </div>
    </ng-container>
    <ng-container class="back-button">
      <btn
        [label]="'back'"
        [title]="'back' | translate"
        [isBackButton]="true"
        [classes]="['btn-third', 'float-right', 'me-2']"
        [dataCyAttribute]="'order-back-button'"
        [icon]="'far-arrow-left'"
        (onEvent)="triggerBackBtn()"
      ></btn>
    </ng-container>
  </page-header>
  <section class="content order-content">
    <router-outlet></router-outlet>
  </section>
</div>
