import { inject } from '@angular/core'
import { ListingItem } from 'depoto-core/src/models'
import { OptionsConfig } from '../../configs'
import { CoreService } from '../../services'
import { StorageKeyService } from '../../services/storage-key.service'

type StorageKey = { type: string; key: string }

export abstract class BaseListing {
  listingColumns: ListingItem[]
  totalItems = 0
  currentPage: number
  endPage: number
  sortProp = 'id'
  sortDir = 'desc'
  loading = true
  reloading = true
  refreshInterval: any = null
  storageKey: StorageKey
  protected keyService = inject(StorageKeyService)

  protected constructor(protected core: CoreService) {}

  abstract filter(pageNo: number, sortProp: string, sortDir: string, concat: boolean): void

  getStorageKey() {
    // todo
    const key = this.keyService.getStorageKey()
    if (key) {
      this.storageKey = key
    } else {
      this.core.services.oauth.logout()
    }
  }

  getStorageKeyForEntity(entity: string): StorageKey {
    return this.keyService.getStorageKeyForEntity(entity, 'baseListing')
  }

  switchPage(event: any = null): void {
    this.sortProp = event.sortProp
    this.sortDir = event.sortDir
    if (event) {
      this.filter(event.currentPage, event.sortProp, event.sortDir, event.concat)
    } else {
      this.filter(this.currentPage, this.sortProp, this.sortDir, false)
    }
  }

  callAction(event: any): void {
    this[event.action](event.item)
  }

  saveViewSettings(viewName: string, data: any): void {
    this.core.services.storage.set(this.core.services.storage.keys.views[viewName], data)
  }

  loadViewSettings(viewName: string): any {
    return this.core.services.storage.getSync(this.core.services.storage.keys.views[viewName])
  }

  loadSorting(listName: string): void {
    const sorting = this.core.services.storage.getSync(this.getStorageKeyForEntity(listName))
    this.sortProp = sorting?.sortProp || this.sortProp
    this.sortDir = sorting?.sortDir || this.sortDir
  }

  clearFilters(): void {
    console.warn('baseList:clearFilters() unimplemented') // TODO: remove
  }

  openUrl(entity: any): void {
    window.open(entity.url)
  }

  downloadReport(report: any): void {
    this.core.services.download.downloadDocumentAndShowInNewWindow(report.url)
  }

  startRefreshing(reloadFn = 'filter', page = 1): void {
    if (!this.refreshInterval) {
      this.refreshInterval = setInterval(() => {
        this[reloadFn](page)
      }, OptionsConfig.strings.refreshIntervalTimeMS)
    }
  }

  stopRefreshing(): void {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
      this.refreshInterval = undefined
    }
  }
}
