<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="companySettingsForm" (ngSubmit)="updateCompany()" *ngIf="company">
    <page-header
      [title]="'company.settings'"
      [appendNoTranslationTitlePart]="company ? company.name : ''"
      [icon]="'far-cog'"
    >
      <ng-container class="header-button">
        <btn
          [label]="'save'"
          [title]="'save' | translate"
          [classes]="['btn-first', 'float-right']"
          [icon]="'far-check'"
          [btnType]="'submit'"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-white'"
          [btnDisabled]="loading"
          [dataCyAttribute]="'edit-company'"
        ></btn>
      </ng-container>
    </page-header>

    <section class="content">
      <div class="card">
          <div class="row">
            <div class="col-sm-6">
              <form-row-input
                [label]="'name'"
                [icon]="null"
                [formGroup]="companySettingsForm"
                formControlName="name"
                [dataCyAttribute]="'name'"
              ></form-row-input>
              <form-row-input
                [label]="'email'"
                [icon]="null"
                [formGroup]="companySettingsForm"
                formControlName="email"
                [required]="companySettingsForm.get('email').errors !== null && companySettingsForm.get('email').errors.required"
                [requiredCssClass]="submitted && formControls.email.errors"
                [dataCyAttribute]="'email'"
              ></form-row-input>
              <form-row-input
                [label]="'phone'"
                [icon]="null"
                [formGroup]="companySettingsForm"
                formControlName="phone"
                [dataCyAttribute]="'phone'"
              ></form-row-input>
              <form-row-input
                [label]="'company.ic'"
                [icon]="null"
                [formGroup]="companySettingsForm"
                formControlName="ic"
                [dataCyAttribute]="'ic'"
              ></form-row-input>
              <form-row-input
                [label]="'company.dic'"
                [icon]="null"
                [formGroup]="companySettingsForm"
                formControlName="dic"
                [dataCyAttribute]="'dic'"
              ></form-row-input>
              <form-row-input
                [label]="'street'"
                [icon]="null"
                [formGroup]="companySettingsForm"
                formControlName="street"
                [dataCyAttribute]="'street'"
              ></form-row-input>
              <form-row-input
                [label]="'city'"
                [icon]="null"
                [formGroup]="companySettingsForm"
                formControlName="city"
                [dataCyAttribute]="'city'"
              ></form-row-input>
              <form-row-input
                [label]="'zip'"
                [icon]="null"
                [formGroup]="companySettingsForm"
                formControlName="zip"
                [dataCyAttribute]="'zip'"
              ></form-row-input>
              <form-row-select
                [label]="'country'"
                [options]="countries"
                [icon]="null"
                [formGroup]="companySettingsForm"
                formControlName="country"
                [optionKey]="'code'"
                [required]="companySettingsForm.get('country').errors !== null && companySettingsForm.get('country').errors.required"
                [requiredCssClass]="submitted && formControls.country.errors"
                [dataCyAttribute]="'company-country-select'"
              >
              </form-row-select>
              <form-row-input
                [label]="'company.registrationNote'"
                [icon]="null"
                [formGroup]="companySettingsForm"
                formControlName="registrationNote"
                [dataCyAttribute]="'registrationNote'"
              ></form-row-input>
              <form-row-input
                [label]="'defaultVoucherValidity'"
                [icon]="null"
                [inputType]="'number'"
                [formGroup]="companySettingsForm"
                formControlName="defaultVoucherValidity"
                [dataCyAttribute]="'defaultVoucherValidity'"
              ></form-row-input>
<!--              todo-->
<!--              <form-row-input-->
<!--                [label]="'billLogo'"-->
<!--                [icon]="null"-->
<!--                [formGroup]="companySettingsForm"-->
<!--                formControlName="billLogo"-->
<!--                data-cy="billLogo"-->
<!--              ></form-row-input>-->
            </div>
          </div>
      </div>
    </section>
  </form>
</div>
