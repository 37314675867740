import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core'
import { Category } from 'depoto-core/src/entities'
import { ModalContainerComponent } from '../../_common/modal-container/modal-container.component'
import { CoreService } from '../../../services'
import { CategoryTreeType } from '../../../models'
import { SchemaUtil } from '../../../utils'

@Component({
  selector: 'app-modal-category-search',
  templateUrl: './modal-category-search.component.html',
  styleUrls: ['./modal-category-search.component.scss'],
})
export class ModalCategorySearchComponent {
  @ViewChild('modal') private childModal: ModalContainerComponent
  @Input() isForFilterCategories = false
  @Input() category: Category
  @Input() selectedCategories: Category[] = []
  @Output() selectedCategoriesChange: EventEmitter<any> = new EventEmitter()
  categories: Category[] = []
  public categoryTreeTypeEnum = CategoryTreeType

  constructor(protected core: CoreService) {}

  public async showChildModal() {
    await this.fetchCategories()
    await this.childModal.showChildModal()
  }

  async fetchCategories() {
    this.categories = await this.core.services.category
      .getList({ direction: 'ASC', filters: { hasParent: false } }, SchemaUtil.category)
      .then(result => result.items)
  }

  public hideChildModal(): void {
    this.childModal.hideChildModal()
  }

  get isModalShown(): boolean {
    return this.childModal?.isShown() || false
  }

  getNamesInString(): string {
    if (this.selectedCategories?.length) {
      const names = this.selectedCategories?.map(cat => cat.name) || []
      return names.toString()
    }
  }

  public removeCategories() {
    this.selectedCategories = []
    this.selectedCategoriesChange.emit(this.selectedCategories)
  }

  saveSelection() {
    //vratit ty vybrane kategorie
    // console.log(this.selectedCategories)
    // const ids = this.selectedCategories.map(cat => {
    //   if (cat.id) return cat.id
    // })
    // console.log(ids)
    this.selectedCategoriesChange.emit(this.selectedCategories)
    this.hideChildModal()
  }
}
