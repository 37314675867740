<div class="card">
  <div>
    <h3 class="card-title">
      {{ 'bill.vat.label' | translate }}
      {{ order.currency && order.currency.id ? (order.currency.id | translate) : 'Kč' }}
    </h3>
  </div>
  <div class="table-responsive">
    <table class="table m-0">
      <tbody *ngIf="order && order.rounding && order.rounding !== 0">
        <tr>
          <th class="no-hover" colspan="4">
            {{ 'order.rounding' | translate }}
          </th>
        </tr>
        <tr *ngIf="order.items && order.items.length > 0">
          <td class="text-end no-hover">1</td>
          <td class="text-end no-hover">
            {{ (order.rounding * order.items[0].vat.coefficient).toFixed(2) }}
            {{ order.currency && order.currency.id ? (order.currency.id | translate) : 'Kč' }}
          </td>
          <td class="text-end no-hover">
            {{ order.rounding.toFixed(2) }}
            {{ order.currency && order.currency.id ? (order.currency.id | translate) : 'Kč' }}
          </td>
          <td class="text-end no-hover">{{ order.items[0].vat.percent || 0 }}%</td>
        </tr>
      </tbody>
    </table>
    <table class="table m-0">
      <div class="text-center" *ngIf="!order.vatAllocations || order.vatAllocations?.length < 1">
        {{ 'no.items' | translate }}
      </div>
      <thead *ngIf="order.vatAllocations && order.vatAllocations.length > 0">
        <tr>
          <th class="text-end">{{ 'bill.vat.rate' | translate }}</th>
          <th class="text-end">{{ 'bill.vat.base' | translate }}</th>
          <th class="text-end">{{ 'bill.vat.vat' | translate }}</th>
          <th class="text-end">{{ 'bill.vat.total' | translate }}</th>
        </tr>
      </thead>
      <tbody *ngIf="order.vatAllocations && order.vatAllocations.length > 0">
        <tr *ngFor="let row of order.vatAllocations">
          <td class="text-end no-hover" *ngIf="row.vat">{{ row.vat.percent }}%</td>
          <td class="text-end no-hover" *ngIf="!row.vat">Celkem</td>
          <td class="text-end no-hover text-nowrap">
            {{
              row.priceWithoutVat
                ? (row.priceWithoutVat | formatPrice: order?.currency)
                : ('0.00' | formatPrice: order?.currency)
            }}
          </td>
          <td class="text-end no-hover text-nowrap">
            {{ row.vatPrice ? (row.vatPrice | formatPrice: order?.currency) : ('0.00' | formatPrice: order?.currency) }}
          </td>
          <td class="text-end no-hover text-nowrap">
            {{ row.price ? (row.price | formatPrice: order?.currency) : ('0.00' | formatPrice: order?.currency) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
