<div class="col-12 col-md-8 col-lg-12 col-xl-7 ps-0 pe-0">
  <div class="card">
    <div
      class="files-dropzone p-1 mb-2"
      #drop
      ngx-dropzone
      (change)="onSelect($event)"
      [disableClick]="true"
      accept="*"
    >
      <div class="row w-100">
        <div class="col-md-5 d-flex align-items-center ps-0">
          <h3 class="card-title">{{ 'address.directory' | translate }}</h3>
        </div>
        <div class="col-md-7 pe-0 d-flex justify-content-end">
          <div>
            <btn
              [label]="'upload'"
              [icon]="'far-file-import'"
              [title]="'upload' | translate"
              (onEvent)="drop.showFileSelector()"
              [classes]="['btn-third', 'btn-sm', 'me-2']"
              [dataCyAttribute]="'show-file-selector'"
            ></btn>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex mt-2 flex-gap-5 padding-left-2 padding-right-2"
      *ngFor="let file of productDetailService.product.files"
    >
      <div class="col flex-gap-5">
        <i class="fa fa-file-image" *ngIf="file.mimeType?.includes('image/')" title="Image"></i>
        <i
          class="fa fa-file-text"
          *ngIf="
            file.mimeType?.includes('text/') ||
            file.mimeType?.includes('/msword') ||
            file.mimeType?.includes('wordprocessingml')
          "
          title="Text"
        ></i>
        <i class="fa fa-file-pdf" *ngIf="file.mimeType?.includes('application/pdf')" title="Pdf"></i>
        <i
          class="fa fa-file-excel"
          *ngIf="file.mimeType?.includes('spreadsheet') || file.mimeType?.includes('ms-excel')"
          title="Excel"
        ></i>
        <i class="fa fa-file" *ngIf="unknownType(file.mimeType)" title="File"></i>
        <a class="link" href="{{ file.url }}" target="_blank" data-cy="file-url">
          {{ file.originalFilename || file.text || 'file' }}
        </a>
      </div>
      <div class="me-2">{{ file.size / 1024 | number: '1.0-2' | replaceCommaToDot }}&nbsp;kB</div>
      <btn
        [title]="'delete' | translate"
        [icon]="'far-trash-alt'"
        [classes]="['btn-trash', 'btn-sm', 'float-right']"
        (click)="removeFile(file)"
        [dataCyAttribute]="'product-file-delete'"
      ></btn>
    </div>
  </div>
</div>
