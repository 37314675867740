import 'reflect-metadata'
import { BrowserModule } from '@angular/platform-browser'
import { NgModule, ErrorHandler as DefaultErrorHandler, APP_INITIALIZER, LOCALE_ID } from '@angular/core'
import { registerLocaleData, DecimalPipe } from '@angular/common'
import localeCs from '@angular/common/locales/cs'
registerLocaleData(localeCs)
// registerLocaleData(localeFr)
import { Router } from '@angular/router'
import * as Sentry from '@sentry/angular'

import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-alt-snotify'
import { PhotoGalleryGroupDirective, PhotoGalleryModule } from '@twogate/ngx-photo-gallery'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AccordionModule } from 'ngx-bootstrap/accordion'
import { AlertModule } from 'ngx-bootstrap/alert'
import { ButtonsModule } from 'ngx-bootstrap/buttons'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { ModalModule } from 'ngx-bootstrap/modal'
import { ProgressbarModule } from 'ngx-bootstrap/progressbar'

import { NgSelectModule } from '@ng-select/ng-select'

import { AppRoutingModule, routes as AppRoutes } from './app-routing.module'
import { AppComponent } from './app.component'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'

import { AppLoaderComponent } from './components/_common/app-loader/app-loader.component'
import { BaseListingComponent } from './components/_common/base-listing/base-listing.component'
import { BtnComponent } from './components/_common/btn/btn.component'
import { DropdownComponent } from './components/_common/dropdown/dropdown.component'
import { DropzonePreviewComponent } from './components/_common/dropzone-preview/dropzone-preview.component'
import { FormRowCheckboxComponent } from './components/_common/form-row-checkbox/form-row-checkbox.component'
import { FormRowInputComponent } from './components/_common/form-row-input/form-row-input.component'
import { FormRowSelectComponent } from './components/_common/form-row-select/form-row-select.component'
import { FormRowSelectMultipleComponent } from './components/_common/form-row-select-multiple/form-row-select-multiple.component'
import { FormRowTextareaComponent } from './components/_common/form-row-textarea/form-row-textarea.component'
import { FormRowToggleComponent } from './components/_common/form-row-toggle/form-row-toggle.component'
import { InfoTooltipComponent } from './components/_common/info-tooltip/info-tooltip.component'
import { ModalOverduePaymentsComponent } from './components/modal-components/modal-overdue-payments/modal-overdue-payments.component'

import { LabelPrintComponent } from './components/label-print/label-print.component'
import { QrcodePrintComponent } from './components/product-components/qrcode-print/qrcode-print.component'
import { BarcodePrintArrayComponent } from './components/product-components/barcode-print-array/barcode-print-array.component'
import { BarcodeListPrintArrayComponent } from './components/barcode-list-print-array/barcode-list-print-array.component'
import { PrintCodeComponent } from './components/print-code/print-code.component'
import { SideMenuComponent } from './components/side-menu/side-menu.component'
import { HeaderComponent } from './components/header/header.component'
import { ChartComponent } from './components/chart/chart.component'
import { ChartsStatsCheckoutComponent } from './components/charts-stats-checkout/charts-stats-checkout.component'
import { MapDepotComponent } from './components/map-depot/map-depot.component'
import { ModalBatchImportFromFileComponent } from './components/modal-components/modal-batch-import-from-file/modal-batch-import-from-file.component'
import { ModalAddressComponent } from './components/modal-components/modal-address/modal-address.component'
import { ModalChangePassComponent } from './components/modal-components/modal-change-pass/modal-change-pass.component'
import { ModalClearanceItemComponent } from './components/modal-components/modal-clearance-item/modal-clearance-item.component'
import { ModalCustomerComponent } from './components/modal-components/modal-customer/modal-customer.component'
import { ModalCustomerSearchComponent } from './components/modal-components/modal-customer-search/modal-customer-search.component'
import { ModalExportCreateComponent } from './components/modal-components/modal-export-create/modal-export-create.component'
import { ModalProcessStatusComponent } from './components/modal-components/modal-process-status/modal-process-status.component'
import { ModalPrintExportComponent } from './components/modal-components/modal-print-export/modal-print-export.component'
import { ModalOrdersImportComponent } from './components/modal-components/modal-orders-import/modal-orders-import.component'
import { ModalPurchaseOrdersImportComponent } from './components/modal-components/modal-purchase-orders-import/modal-purchase-orders-import.component'
import { ModalOrderItemsSaleComponent } from './components/order-components/modal-order-items-sale/modal-order-items-sale.component'
import { ModalProductAdvancedPricesComponent } from './components/modal-components/modal-product-advanced-prices/modal-product-advanced-prices'
import { ModalProductChooseComponent } from './components/modal-components/modal-product-choose/modal-product-choose.component'
import { ModalProductParentComponent } from './components/modal-components/modal-product-parent/modal-product-parent.component'
import { ModalProductPacksComponent } from './components/modal-components/modal-product-packs/modal-product-packs'
import { ModalStatusComponent } from './components/modal-components/modal-status/modal-status.component'
import { ModalStockLoadComponent } from './components/modal-components/modal-stock-load/modal-stock-load.component'
import { ModalStockOperationCardComponent } from './components/modal-components/modal-stock-operation-card/modal-stock-operation-card.component'
import { ModalStockOverviewComponent } from './components/modal-components/modal-stock-overview/modal-stock-overview.component'
import { ModalStockPositionsComponent } from './components/modal-components/modal-stock-positions/modal-stock-positions.component'
import { ProductMovesListComponent } from './components/product-components/product-moves-list/product-moves-list.component'
import { BoxUserComponent } from './components/box-user/box-user.component'

import { AddressesComponent } from './pages/addresses/addresses.component'
import { CheckoutComponent } from './pages/checkout/checkout.component'
import { CheckoutsComponent } from './pages/checkouts/checkouts.component'
import { CompanyCarrierComponent } from './pages/company-carrier/company-carrier.component'
import { CompanyCarriersComponent } from './pages/company-carriers/company-carriers.component'
import { CompanySettingsComponent } from './pages/company-settings/company-settings.component'
import { ComponentListComponent } from './pages/component-list/component-list.component'
import { ConsentComponent } from './pages/consent/consent.component'
import { ConsentsComponent } from './pages/consents/consents.component'
import { CustomerComponent } from './pages/customer/customer.component'
import { CustomersComponent } from './pages/customers/customers.component'
import { DepotComponent } from './pages/depot/depot.component'
import { DepotsComponent } from './pages/depots/depots.component'
import { ExportsComponent } from './pages/exports/exports.component'
import { HomeComponent } from './pages/home/home.component'
import { LoginComponent } from './pages/login/login.component'
import { OrderComponent } from './pages/order/order.component'
import { OrdersComponent } from './pages/orders/orders.component'
import { ParameterComponent } from './pages/parameter/parameter.component'
import { ParametersComponent } from './pages/parameters/parameters.component'
import { PaymentComponent } from './pages/payment/payment.component'
import { PaymentsComponent } from './pages/payments/payments.component'
import { PrintInventoryDetailComponent } from './pages/print-inventory-detail/print-inventory-detail.component'
import { PrintInventoryExportsComponent } from './pages/print-inventory-exports/print-inventory-exports.component'
import { PrintOrderExportsComponent } from './pages/print-order-exports/print-order-exports.component'
import { PrintPurchaseExportsComponent } from './pages/print-purchase-exports/print-purchase-exports.component'
import { PrintSaleExportsComponent } from './pages/print-sale-exports/print-sale-exports.component'
import { PrintStockExportsComponent } from './pages/print-stock-exports/print-stock-exports.component'
import { ProducerComponent } from './pages/producer/producer.component'
import { ProducersComponent } from './pages/producers/producers.component'
import { ProductComponent } from './pages/product/product.component'
import { ProductMovePackComponent } from './pages/product-move-pack/product-move-pack.component'
import { ProductMovePacksComponent } from './pages/product-move-packs/product-move-packs.component'
import { ProductsComponent } from './pages/products/products.component'
import { SoldItemsComponent } from './pages/sold-items-component/sold-items.component'
import { StockOperationListComponent } from './pages/stock-operation-list/stock-operation-list.component'
import { SupplierComponent } from './pages/supplier/supplier.component'
import { SuppliersComponent } from './pages/suppliers/suppliers.component'
import { UserComponent } from './pages/user/user.component'
import { UsersComponent } from './pages/users/users.component'
import { UserGroupComponent } from './pages/usergroup/usergroup.component'
import { UserGroupsComponent } from './pages/usergroups/usergroups.component'
import { VatComponent } from './pages/vat/vat.component'
import { VatsComponent } from './pages/vats/vats.component'

import { AuthGuardService, NotificationService } from './services'

import { ErrorHandler } from './utils'

import {
  ColumnSettingsOptionsPipe,
  FmtDatePipe,
  FormatPricePipe,
  FormatCurrencyWithoutPricePipe,
  FormatPriceWithCurrencyFromOrderPipe,
  FormErrorPipe,
  Nl2brPipe,
  OperationsPriceSumPipe,
  ListLengthPipe,
  LogPipe,
  OrderItemsPriceSumPipe,
  OrderNumberPipe,
  ReplacePipe,
  StrToDatePipe,
  QuantityPurchaseAccordingToDepotPipe,
} from './pipes'

import { ReplaceCommaDirective, TranslateBarcodeDirective } from './directives'

import { NgxDropzoneModule } from 'ngx-dropzone'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'

import { CategoriesComponent } from './pages/categories/categories.component'
import { CategoryComponent } from './pages/category/category.component'
import { CategoryTreeRowComponent } from './components/category-tree-row/category-tree-row.component'
import { CategoryTreeComponent } from './components/category-tree/category-tree.component'
import { ModalContainerComponent } from './components/_common/modal-container/modal-container.component'
import { PageHeaderComponent } from './components/_common/page-header/page-header.component'
import { ProductImageComponent } from './components/_common/product-image/product-image.component'
import { FilterFormComponent } from './components/_common/filter-form/filter-form.component'
import { DndModule } from 'ngx-drag-drop'
import { VouchersComponent } from './pages/vouchers/vouchers.component'
import { VoucherComponent } from './pages/voucher/voucher.component'
import { PriceLevelsComponent } from './pages/price-levels/price-levels.component'
import { PriceLevelComponent } from './pages/price-level/price-level.component'
import { CustomerSearchComponent } from './components/customer-search/customer-search.component'
import { TabsModule } from 'ngx-bootstrap/tabs'
import { OrderAddressPageComponent } from './components/order-components/order-address-page/order-address-page.component'
import { AddressFormComponent } from './components/address-form/address-form.component'
import { ModalAddressChooseComponent } from './components/modal-components/modal-address-choose/modal-address-choose.component'
import { OrderProductsComponent } from './components/order-components/order-products/order-products.component'
import { OrderBillVatComponent } from './components/order-components/order-bill-vat/order-bill-vat.component'
import { OrderPaymentsComponent } from './components/order-components/order-payments/order-payments.component'
import { OrderShippingComponent } from './components/order-components/order-shipping/order-shipping.component'
import { OrderTagsComponent } from './components/order-components/order-tags/order-tags.component'
import { OrderFilesComponent } from './components/order-components/order-files/order-files.component'
import { OrderCustomerComponent } from './components/order-components/order-customer/order-customer.component'
import { OrderInfoComponent } from './components/order-components/order-info/order-info.component'
import { OrderNotesComponent } from './components/order-components/order-notes/order-notes.component'
import { OrderAddressComponent } from './components/order-components/order-address/order-address.component'
import { OrderPackagesComponent } from './components/order-components/order-packages/order-packages.component'
import { OrderProductMovesComponent } from './components/order-components/order-product-moves/order-product-moves.component'
import { ItemsListingComponent } from './components/_common/items-listing/items-listing.component'
import { ModalOrderItemComponent } from './components/modal-components/modal-order-item/modal-order-item.component'
import { FormRowNumberWithControlsComponent } from './components/_common/form-row-number-with-controls/form-row-number-with-controls.component'
import { ModalConsentComponent } from './components/modal-components/modal-consent/modal-consent.component'
import { LoadingSpinnerComponent } from './components/_common/loading-spinner/loading-spinner.component'
import { ProductFilesComponent } from './components/product-components/product-files/product-files.component'
import { ProductFormComponent } from './components/product-components/product-form/product-form.component'
import { ProductCategoriesComponent } from './components/product-components/product-categories/product-categories.component'
import { ProductAdvancedPricesFormComponent } from './components/product-components/product-advanced-prices-form/product-advanced-prices-form.component'
import { ProductDepotsFormComponent } from './components/product-components/product-depots-form/product-depots-form.component'
import { ProductImagesComponent } from './components/product-components/product-images/product-images.component'
import { ProductPacksComponent } from './components/product-components/product-packs/product-packs.component'
import { ProductParametersComponent } from './components/product-components/product-parameters/product-parameters.component'
import { ProductPriceLevelsComponent } from './components/product-components/product-price-levels/product-price-levels.component'
import { TagsComponent } from './pages/tags/tags.component'
import { TagComponent } from './pages/tag/tag.component'
import { OrderPreviewComponent } from './components/order-components/order-preview/order-preview.component'
import { PopoverModule } from 'ngx-bootstrap/popover'
import { PaginationBtnComponent } from './components/pagination-btn/pagination-btn.component'
import { ModalBulkEditsComponent } from './components/modal-components/bulk-edits/modal-bulk-edits.component'
import { ProductLogisticDetailsComponent } from './components/product-components/product-logistic-details/product-logistic-details.component'
import { ModalProductDepotMetricComponent } from './components/modal-components/modal-product-depot-metric/modal-product-depot-metric.component'
import { ModalCategorySearchComponent } from './components/modal-components/modal-category-search/modal-category-search.component'
import { ModalMoveOrderItemsComponent } from './components/modal-components/modal-move-order-items/modal-move-order-items.component'
import { OrderExpeditionComponent } from './components/order-components/order-expedition/order-expedition.component'
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'
import { PaymentInfoSearchComponent } from './components/payment-info-search/payment-info-search.component'
import { ProductPurchaseOrderCreationComponent } from './pages/product-purchase-order-creation/product-purchase-order-creation.component'
import { ModalPurchaseChooseProductComponent } from './pages/product-purchase-order-creation/components/modal-product-choose-purchase/modal-purchase-choose-product.component'
import { ModalStockOperationCardPurchaseComponent } from './pages/product-purchase-order-creation/components/modal-stock-operation-card-purchase/modal-stock-operation-card-purchase.component'
import { ModalProductPurchaseComponent } from './pages/products/components/modal-product-purchase/modal-product-purchase.component'
import { PurchaseOrdersComponent } from './pages/purchase-orders/purchase-orders.component'
import { OrderFormComponent } from './components/order-components/order-form/order-form.component'
import { ModalSerialNumbersComponent } from './modal-serial-numbers/modal-serial-numbers.component'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { CustomPageTitleStrategy } from './services/custom-page-title-strategy.service'
import { provideRouter, TitleStrategy, withRouterConfig } from '@angular/router'

@NgModule({
  declarations: [
    AppComponent,

    // common, atomical components
    AppLoaderComponent,
    BaseListingComponent,
    BtnComponent,
    DropdownComponent,
    DropzonePreviewComponent,
    FormRowCheckboxComponent,
    FormRowInputComponent,
    FormRowSelectComponent,
    FormRowSelectMultipleComponent,
    FormRowTextareaComponent,
    FormRowToggleComponent,
    InfoTooltipComponent,
    ModalContainerComponent,
    ModalOverduePaymentsComponent,

    // part view components
    AddressesComponent,
    LabelPrintComponent,
    QrcodePrintComponent,
    BarcodePrintArrayComponent,
    BarcodeListPrintArrayComponent,
    PrintCodeComponent,
    BoxUserComponent,
    CategoriesComponent,
    CategoryComponent,
    CategoryTreeRowComponent,
    CategoryTreeComponent,
    ChartComponent,
    ChartsStatsCheckoutComponent,
    CheckoutComponent,
    CheckoutsComponent,
    CompanyCarrierComponent,
    CompanyCarriersComponent,
    CompanySettingsComponent,
    ComponentListComponent,
    ConsentComponent,
    ConsentsComponent,
    CustomerComponent,
    CustomersComponent,
    DepotComponent,
    DepotsComponent,
    ExportsComponent,
    LoginComponent,
    HeaderComponent,
    HomeComponent,
    MapDepotComponent,
    ModalAddressComponent,
    ModalBatchImportFromFileComponent,
    ModalChangePassComponent,
    ModalClearanceItemComponent,
    ModalCustomerComponent,
    ModalCustomerSearchComponent,
    ModalExportCreateComponent,
    ModalProcessStatusComponent,
    ModalPrintExportComponent,
    ModalOrdersImportComponent,
    ModalPurchaseOrdersImportComponent,
    ModalOrderItemsSaleComponent,
    ModalStockLoadComponent,
    ModalStockOperationCardComponent,
    ModalStockOverviewComponent,
    ModalStockPositionsComponent,
    ModalProductAdvancedPricesComponent,
    ModalProductChooseComponent,
    ModalProductParentComponent,
    ModalProductPacksComponent,
    ModalStatusComponent,
    OrderComponent,
    OrdersComponent,
    PurchaseOrdersComponent,
    ParameterComponent,
    ParametersComponent,
    PaymentComponent,
    PaymentsComponent,
    PrintInventoryDetailComponent,
    PrintInventoryExportsComponent,
    PrintOrderExportsComponent,
    PrintPurchaseExportsComponent,
    PrintSaleExportsComponent,
    PrintStockExportsComponent,
    ProducerComponent,
    ProducersComponent,
    ProductComponent,
    ProductMovePackComponent,
    ProductMovePacksComponent,
    ProductMovesListComponent,
    ProductsComponent,
    SideMenuComponent,
    SoldItemsComponent,
    StockOperationListComponent,
    SupplierComponent,
    SuppliersComponent,
    UserComponent,
    UsersComponent,
    UserGroupComponent,
    UserGroupsComponent,
    VatComponent,
    VatsComponent,

    // pipes
    ColumnSettingsOptionsPipe,
    FmtDatePipe,
    FormatPricePipe,
    ReplacePipe, //todo
    FormatCurrencyWithoutPricePipe,
    FormatPriceWithCurrencyFromOrderPipe,
    FormErrorPipe,
    Nl2brPipe,
    OperationsPriceSumPipe,
    ListLengthPipe,
    LogPipe,
    OrderItemsPriceSumPipe,
    OrderNumberPipe,
    StrToDatePipe,
    QuantityPurchaseAccordingToDepotPipe,

    // directives
    PageHeaderComponent,
    ProductImageComponent,
    ReplaceCommaDirective,
    TranslateBarcodeDirective,
    FilterFormComponent,
    VouchersComponent,
    VoucherComponent,
    PriceLevelsComponent,
    PriceLevelComponent,
    CustomerSearchComponent,
    OrderAddressPageComponent,
    AddressFormComponent,
    ModalAddressChooseComponent,
    OrderProductsComponent,
    OrderBillVatComponent,
    OrderPaymentsComponent,
    OrderShippingComponent,
    OrderTagsComponent,
    OrderFilesComponent,
    OrderCustomerComponent,
    OrderInfoComponent,
    OrderNotesComponent,
    OrderAddressComponent,
    OrderPackagesComponent,
    OrderProductMovesComponent,
    ItemsListingComponent,
    ModalOrderItemComponent,
    FormRowNumberWithControlsComponent,
    ModalConsentComponent,
    LoadingSpinnerComponent,
    ProductFilesComponent,
    ProductFormComponent,
    ProductCategoriesComponent,
    ProductAdvancedPricesFormComponent,
    ProductDepotsFormComponent,
    ProductImagesComponent,
    ProductPacksComponent,
    ProductParametersComponent,
    ProductPriceLevelsComponent,
    TagsComponent,
    TagComponent,
    OrderPreviewComponent,
    PaginationBtnComponent,
    ModalBulkEditsComponent,
    ProductLogisticDetailsComponent,
    ModalProductDepotMetricComponent,
    ModalCategorySearchComponent,
    ModalMoveOrderItemsComponent,
    OrderExpeditionComponent,
    ConfirmDialogComponent,
    PaymentInfoSearchComponent,
    OrderFormComponent,
    ProductPurchaseOrderCreationComponent,
    ModalPurchaseChooseProductComponent,
    ModalStockOperationCardPurchaseComponent,
    ModalProductPurchaseComponent,
    ModalSerialNumbersComponent,
  ],
  imports: [
    // angular modules
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SnotifyModule.forRoot(),
    PhotoGalleryModule.forRoot({
      defaultOptions: {
        arrowEl: true,
        closeEl: true,
        captionEl: true,
        fullscreenEl: true,
        zoomEl: true,
        shareEl: true,
        counterEl: true,
        preloaderEl: true,
        indexIndicatorSep: ' / ',
      },
    }),
    CKEditorModule,

    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    NgxDropzoneModule,
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    DndModule,

    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    PopoverModule,
    TranslateModule.forRoot({
      defaultLanguage: 'cs',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    DecimalPipe,
    ReplacePipe,
    { provide: LOCALE_ID, useValue: 'cs-CZ' },
    // { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: DefaultErrorHandler, useClass: ErrorHandler },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    { provide: TitleStrategy, useClass: CustomPageTitleStrategy },
    provideRouter(
      AppRoutes,
      withRouterConfig({
        canceledNavigationResolution: 'computed',
      })
    ),
    SnotifyService,
    AuthGuardService,
    NotificationService,
    PhotoGalleryGroupDirective,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
