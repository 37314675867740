<div class="app-container">
  <loading-spinner [isLoading]="loading" [classesString]="'top-50'"></loading-spinner>
  <form [formGroup]="categoryForm" (ngSubmit)="isCreating ? createCategory() : updateCategory()">
    <page-header [icon]="'far-cube'" [title]="'category'">
      <ng-container class="header-button">
        <btn
          [btnType]="'submit'"
          [classes]="['btn-first', 'float-right', isCreating ? '' : 'ms-2']"
          [label]="'save'"
          [title]="'save' | translate"
          [icon]="'far-check'"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-white'"
          [btnDisabled]="loading"
          [dataCyAttribute]="'create-category'"
        ></btn>
        <btn
          *ngIf="!isCreating"
          [icon]="'far-trash-alt'"
          [label]="'category.delete'"
          [title]="'category.delete' | translate"
          [classes]="['btn-first-red', 'float-right']"
          (onEvent)="deleteCategory()"
          [isLoading]="loading"
          [spinnerClassesString]="'spinner-white'"
          [dataCyAttribute]="'delete-category'"
        ></btn>
      </ng-container>
      <ng-container class="back-button">
        <btn
          [label]="'back'"
          [title]="'back' | translate"
          [isBackButton]="true"
          [classes]="['btn-third', 'float-right', 'me-2']"
          [icon]="'far-arrow-left'"
          (onEvent)="goBack()"
        ></btn>
      </ng-container>
    </page-header>

    <section class="content">
      <div class="card" [ngClass]="{ h150: loading }">
        <ng-template [ngIf]="isCreating || (editedCategory && editedCategory.name)">
          <form-row-input
            [formGroup]="categoryForm"
            formControlName="name"
            [required]="categoryForm.get('name').errors !== null && categoryForm.get('name').errors.required"
            [requiredCssClass]="submitted && formControls.name.errors"
            [label]="'category.name'"
            [icon]="'fa-file-alt'"
            [dataCyAttribute]="'name'"
          ></form-row-input>
          <form-row-input
            [formGroup]="categoryForm"
            formControlName="externalId"
            [label]="'category.externalId'"
            [icon]="'fa-id-badge'"
            [dataCyAttribute]="'external-id'"
          ></form-row-input>
          <form-row-textarea
            [label]="'category.text'"
            [icon]="'fa-sticky-note'"
            [(model)]="editedCategory.text"
            [dataCyAttribute]="'description'"
          ></form-row-textarea>
          <category-tree
            [categoryTreeLabel]="'category.parentId'"
            [type]="categoryTreeTypeEnum.radioParentCategory"
            [editedCategory]="editedCategory"
          ></category-tree>
        </ng-template>
      </div>
    </section>
  </form>
</div>
