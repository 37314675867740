<div class="app-container">
  <page-header [icon]="'far fa-analytics'" [title]="'sale.statistics'">
    <ng-container class="header-button">
      <app-exports #exportsModal [entity]="'soldItems'"></app-exports>
    </ng-container>
  </page-header>

  <section class="content">
    <app-filter-form
      [filters]="queryFilters"
      [loading]="loading || reloading"
      [filterVisible]="filtersExpanded"
      [entity]="'soldItems'"
      [isExportButtonShown]="true"
      (onSubmit)="filter()"
      (onClearClick)="clearFilters()"
      (onCreateModalHidden)="exportsModal.showChildModal()"
      (filterVisibleChange)="filtersExpanded = $event; changeDetector.detectChanges()"
    >
      <div class="row">
        <div class="col-12 col-sm-4 col-md-2">
          <form-row-input
            [label]="'fulltext'"
            [icon]="null"
            [(model)]="filters.productFulltext"
            (keyup.enter)="filter()"
          ></form-row-input>
        </div>
        <div class="col-12 col-sm-4 col-md-2">
          <form-row-input
            [label]="'orders.customerName'"
            [icon]="null"
            [(model)]="filters.customerFulltext"
            (keyup.enter)="filter()"
          ></form-row-input>
        </div>
        <div class="col-12 col-sm-4 col-md-2">
          <form-row-input
            [label]="'sold-items.date-from'"
            [icon]="null"
            [inputType]="'date'"
            [(model)]="filters.dateFrom"
          ></form-row-input>
        </div>
        <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
          <form-row-input
            [label]="'sold-items.date-from'"
            [icon]="null"
            [inputType]="'date'"
            [(model)]="filters.dateTo"
          ></form-row-input>
        </div>
        <div class="col-12 col-sm-4 col-md-2">
          <form-row-select-multiple
            [label]="'products.checkouts'"
            [options]="checkouts"
            [(selectedValues)]="filters.checkouts"
          ></form-row-select-multiple>
        </div>
        <div class="col-12 col-sm-4 col-md-2">
          <form-row-select-multiple
            [label]="'products.depots'"
            [options]="depots"
            [(selectedValues)]="filters.depots"
          ></form-row-select-multiple>
        </div>
      </div>
      <div class="custom-dropdown" [ngClass]="{ open: filtersExpanded }">
        <div class="row">
          <div class="col-12 col-sm-4 col-md-2">
            <form-row-select-multiple
              [label]="'payments'"
              [options]="payments"
              [(selectedValues)]="filters.payments"
            ></form-row-select-multiple>
          </div>
          <div class="col-12 col-sm-4 col-md-2">
            <form-row-select
              [label]="'order.status'"
              [icon]="null"
              [(model)]="filters.status"
              [options]="statusTypesOptions"
            ></form-row-select>
          </div>
          <div class="col-12 col-sm-4 col-md-2">
            <form-row-select
              [label]="'processStatus'"
              [icon]="null"
              [(model)]="filters.processStatus"
              [options]="processStatesOptions"
            ></form-row-select>
          </div>
          <div class="col-12 col-sm-4 col-md-2">
            <form-row-select-multiple
              [label]="'carriers'"
              [(selectedValues)]="filters.carriers"
              [options]="carriers"
            ></form-row-select-multiple>
          </div>
          <div class="col-12 col-sm-4 col-md-2">
            <form-row-select-multiple
              [label]="'orderTags'"
              [options]="orderTags"
              [(selectedValues)]="filters.orderTags"
            ></form-row-select-multiple>
          </div>
          <div class="col-12 col-sm-4 col-md-2">
            <form-row-select-multiple
              [label]="'productTags'"
              [options]="productTags"
              [(selectedValues)]="filters.productTags"
            ></form-row-select-multiple>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-4 col-md-2">
            <form-row-select-multiple
              [label]="'producers'"
              [options]="producers"
              [(selectedValues)]="filters.producers"
            ></form-row-select-multiple>
          </div>
          <div class="col-12 col-sm-4 col-md-2">
            <form-row-select-multiple
              [label]="'suppliers'"
              [options]="suppliers"
              [(selectedValues)]="filters.suppliers"
            ></form-row-select-multiple>
          </div>
          <div class="col-12 col-sm-4 col-md-2">
            <form-row-input
              [label]="'sold-items.date-bill-from'"
              [icon]="null"
              [inputType]="'date'"
              [(model)]="filters.dateBillFrom"
            ></form-row-input>
          </div>
          <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <form-row-input
              [label]="'sold-items.date-bill-to'"
              [icon]="null"
              [inputType]="'date'"
              [(model)]="filters.dateBillTo"
            ></form-row-input>
          </div>
        </div>
      </div>
    </app-filter-form>
    <app-base-listing
      [listName]="'soldItems'"
      [storageKey]="storageKey"
      [loadingNextItems]="loading"
      [loadingItems]="reloading"
      [items]="soldItems"
      [columns]="listingColumns"
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [endPage]="endPage"
      [filters]="filters"
      [(sortProp)]="sortProp"
      [(sortDir)]="sortDir"
      (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
      (callAction)="callAction($event)"
      (reloadList)="filter()"
      [loadStringifiedFilters]="true"
      [stringifiedFilters]="stringifiedFilters"
    ></app-base-listing>
  </section>
</div>
