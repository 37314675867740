import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CategoryTreeType } from '../../models'
import { DndDropEvent } from 'ngx-drag-drop'
import { Category } from 'depoto-core/src/entities'
import { CoreService } from '../../services'
import { SchemaUtil } from '../../utils'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'category-tree',
  templateUrl: './category-tree.component.html',
})
export class CategoryTreeComponent implements OnInit {
  @Input() public type: CategoryTreeType
  @Input() public editedCategory: Category
  @Input() public selectedCategories: Category[]
  @Output() public selectedCategoriesChange: EventEmitter<any> = new EventEmitter<any>()
  @Input() public categoryTreeLabel: string
  @Input() public categories: Category[] = []
  @Input() public mainCategory: Category
  @Output() public mainCategoryChange: EventEmitter<Category> = new EventEmitter<Category>()
  @Input() isInBulkEdits = false
  @Input() isForFilterCategories = false
  @Output() public categoriesChange: EventEmitter<any> = new EventEmitter<any>()
  public loading = true
  public categoryTreeTypeEnum = CategoryTreeType

  constructor(
    private core: CoreService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.loading = true
    if (this.type && (!this.categories || (this.categories && !this.categories.length))) {
      this.categories = await this.core.services.category
        .getList({ direction: 'ASC', filters: { hasParent: false } }, SchemaUtil.category)
        .then(result => result.items)
    } else if (!this.type) {
      alert(this.translateService.instant('category-tree.unknown'))
    }
    this.loading = false
  }

  categoriesChanged(event) {
    this.selectedCategories = event
    this.selectedCategoriesChange.emit(this.selectedCategories)
  }

  mainCategoryValueChanged(event) {
    this.mainCategory = event
    this.mainCategoryChange.emit(this.mainCategory)
  }

  public async onDrop(event: DndDropEvent, list: Category[]) {
    if (list) {
      let index = event.index
      if (typeof index === 'undefined') {
        index = list.length
      }
      const droppedCategory: Category = event.data
      list.splice(index, 0, droppedCategory)
      droppedCategory.position = index - 1 > 0 ? index - 1 : 0 // todo server, fe is fine
      droppedCategory.parent = null
      await this.core.services.category.update(droppedCategory)
    }
  }

  public onDragMove(item: Category, list: Category[]) {
    const index = list.indexOf(item)
    list.splice(index, 1)
  }
}
