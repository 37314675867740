import { Injectable } from '@angular/core'
import {
  Depot,
  Producer,
  Product,
  ProductMove,
  ProductParameter,
  ProductPriceLevel,
  Supplier,
  Tag,
  Vat,
} from 'depoto-core/src/entities'
import { Currency } from 'depoto-core/src/entities/Currency'
import { Stock } from './models'
import { CoreService } from './services'
import { Subject, Observable } from 'rxjs'
import { SchemaUtil } from './utils'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root',
})
export class ProductDetailService {
  public product: Product
  public productMoves: ProductMove[] = []
  public depots: Depot[] = []
  public stock: Stock[] = []
  public suppliers: Supplier[] = []
  public currencies: Currency[] = []
  public producers: Producer[] = []
  public tags: Tag[] = []
  public vats: Vat[] = []
  parameters: ProductParameter[] = []
  freshProductParameters: ProductParameter[] = []

  public isCreating: boolean
  public loading = true
  public submitted: boolean

  private refreshDataSubject = new Subject<any>()
  public refreshDataEvent$ = this.refreshDataSubject as Observable<any>

  private resolveStockLoadSubject = new Subject<any>()
  public resolveStockEvent$ = this.resolveStockLoadSubject as Observable<any>

  isDuplicatedProduct
  isStockEmptyYet

  hasCreateRole
  hasUpdateRole
  hasDeleteRole
  hasStockOperationInRole
  hasStockOperationOutRole
  hasRoleUpdateMetrics
  hasRoleCreateMetrics

  stockDepotsChartData: any[] = []

  updatedProductPriceLevels: ProductPriceLevel[] = []

  constructor(
    public core: CoreService,
    private translateService: TranslateService
  ) {}

  refreshProduct() {
    this.refreshDataSubject.next('refreshProduct')
  }

  async updateParams() {
    for (const param of this.parameters) {
      await this.onParamUpdated(param)
    }
    await this.getParameters(true)
    return this.parameters
  }

  async onParamUpdated(param: ProductParameter) {
    if (!this.isStoredOnProduct(param) && param.value) {
      await this.createParameter(param)
    } else {
      if (!param.value && param.externalId && param.externalId.length > 0) {
        alert(this.translateService.instant('product-parameters.enter-value'))
        return
      }
      if ((param.value && param.value.length > 0) || (param.externalId && param.externalId.length > 0)) {
        await this.updateParameter(param)
        // todo check
      } else if ((!param.value || param.value.length === 0) && this.isStoredOnProduct(param)) {
        await this.removeParameter(param)
      }
    }
    return Promise.resolve()
  }

  async getParameters(fetchFreshProduct?: boolean) {
    try {
      if (fetchFreshProduct) {
        const p = await this.core.services.product.getById(this.product.id, {
          id: null,
          productParameters: {
            id: null,
            value: null,
            externalId: null,
            product: { id: null },
            parameter: { id: null, name: null, type: null, enumValues: null, text: null },
          },
        })
        this.product.productParameters = p.productParameters
        this.freshProductParameters = [...p.productParameters]
      } else {
        this.freshProductParameters = [...this.product.productParameters]
      }
      const allParams = await this.core.services.parameter.getList({}, SchemaUtil.parameter).then(res => res.items)
      const allProductParams = [...allParams.map(p => new ProductParameter({ product: this.product, parameter: p }))]
      allProductParams.forEach((param, i) => {
        if (this.isStoredOnProduct(param)) {
          allProductParams[i] = this.getStoredOnProduct(param)[0]
        }
      })
      this.parameters = allProductParams
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
    }
  }

  async createParameter(param: ProductParameter) {
    this.loading = true
    try {
      await this.core.services.productParameter.create(param, { id: null })
    } catch (_) {
      console.error(_)
    }
    this.loading = false
  }

  async updateParameter(param: ProductParameter) {
    this.loading = true
    try {
      await this.core.services.productParameter.update(param, { id: null })
    } catch (_) {
      console.error(_)
    }
    this.loading = false
  }

  async removeParameter(param: ProductParameter) {
    this.loading = true
    try {
      await this.core.services.productParameter.delete(param)
    } catch (_) {
      console.error(_)
    }
    this.loading = false
  }

  getStoredOnProduct(param): ProductParameter[] {
    return this.freshProductParameters.filter(p => p.parameter.id === param.parameter.id)
  }

  isStoredOnProduct(param) {
    return this.getStoredOnProduct(param).length > 0
  }

  getCoreEntityLists(): void {
    this.depots = this.core.lists.depots
    this.suppliers = this.core.lists.suppliers
    this.tags = this.core.lists.tags
    this.vats = this.core.lists.vats
    this.producers = this.core.lists.producers
    this.currencies = this.core.lists.currencies
  }

  getRoles() {
    this.hasCreateRole =
      this.core.services.user.hasRole('ROLE_PRODUCT_CREATE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
    this.hasUpdateRole =
      this.core.services.user.hasRole('ROLE_PRODUCT_UPDATE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
    this.hasDeleteRole =
      this.core.services.user.hasRole('ROLE_PRODUCT_DELETE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
    this.hasRoleUpdateMetrics =
      this.core.services.user.hasRole('ROLE_PRODUCT_DEPOT_METRIC_UPDATE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
    this.hasRoleCreateMetrics =
      this.core.services.user.hasRole('ROLE_PRODUCT_DEPOT_METRIC_CREATE') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
    this.hasStockOperationInRole =
      this.core.services.user.hasRole('CLIENT_MENU_IN') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
    this.hasStockOperationOutRole =
      this.core.services.user.hasRole('CLIENT_MENU_OUT') ||
      this.core.services.user.hasRole('ROLE_SUPER_ADMIN') ||
      this.core.services.user.hasRole('ROLE_ADMIN')
  }

  public resolveStock(): void {
    this.resolveStockLoadSubject.next('resolveStock')
  }
}
