import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core'
import { ticketPrintableStyles } from '../../utils'
import 'jsbarcode'
import QRCode from 'qrcode'
import { CoreService, NotificationService } from '../../services'
import { ProductDetailService } from '../../product-detail.service'
import { TranslateService } from '@ngx-translate/core'
import { Product, ProductMovePack } from 'depoto-core/src/entities'
import { FormatPricePipe, ReplacePipe } from '../../pipes'
import { Router } from '@angular/router'
import { DecimalPipe } from '@angular/common'

declare const JsBarcode // workaround

type TicketData = {
  finalCode: string
  labelTitle: string
  labelEan: string
  labelPrice: string
  base64: string
}

@Component({
  selector: 'print-code',
  templateUrl: 'print-code.component.html',
  styleUrls: ['print-code.component.scss'],
})
export class PrintCodeComponent implements OnInit {
  @ViewChild('barcode', { static: true }) barcode: ElementRef
  @ViewChild('printable') printableEl: ElementRef
  @ViewChild('printableMulti') printableMultiEl: ElementRef
  @ViewChild('printableMultiToLabelPrinter') printableMultiToLabelPrinterEl: ElementRef
  @Input() product: Product
  @Input() productMovePack: Partial<ProductMovePack> // pmp.moves[x].serialNumbers: string[]
  @Input() doRecalculateArray = true
  quantity = 39
  startAt = 0
  printType: 'single' | 'multi' = 'single'
  printTypeMultiToLabelPrinter: boolean = false
  codeType: 'qr' | 'barcode' = 'qr'
  isPrintingSerialNumbers = false
  isGS1 = false
  isBarcodeTitleVisible = true
  isBarcodeEanVisible = true
  isBarcodePriceVisible = true
  isBarcodeWide = true
  isCustomData = false
  isCustomDimensions = false
  customTitle: string = ''
  customEan: string = ''
  customPrice: string = ''
  customBatch: string = ''
  customExpirationDate: string = ''
  finalCode: string = ''
  labelTitle: string = ''
  labelEan: string = ''
  labelPrice: string = ''
  multiTicketData: TicketData[] = []
  singleTicketData: TicketData = {
    finalCode: '',
    labelTitle: '',
    labelPrice: '',
    labelEan: '',
    base64: '',
  }
  barcodeBase64 = ''
  qrBase64 = ''
  ticketArrayWidthMm: number = 210
  ticketWidthMm: number = 70
  ticketHeightMm: number = 20
  textSizeMultiplier: number = 1
  barcodePaddingHorizontalMm = 0
  barcodePaddingVerticalMm = 0
  pageMarginTopMm = 0
  pageMarginLeftMm = 0
  qrHeightMm: number = 0
  fmtPricePipe = new FormatPricePipe(new DecimalPipe('cs-CZ'), new ReplacePipe())

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private core: CoreService,
    public productDetailService: ProductDetailService,
    private translateService: TranslateService
  ) {
    if (this.router.url.startsWith('/product/')) {
      this.product = this.productDetailService.product
      if (!this.product?.ean || !this.product?.ean?.length) {
        this.notificationService.error(this.translateService.instant('barcode-print-array.no-ean'))
      }
    }
  }

  ngOnInit() {
    this.loadViewSettings()
    this.hydrate()
  }

  loadViewSettings(): void {
    const settings = this.core.services.storage.getSync({ key: 'views_print_code', type: 'Object' })
    if (settings && Object.keys(settings).length > 0) {
      this.printType = settings.printType || 'single'
      this.printTypeMultiToLabelPrinter = settings.printTypeMultiToLabelPrinter || false
      this.codeType = settings.codeType || 'qr'
      this.isBarcodeTitleVisible = settings.isBarcodeTitleVisible || true
      this.isBarcodeEanVisible = settings.isBarcodeEanVisible || true
      this.isBarcodePriceVisible = settings.isBarcodePriceVisible || true
      this.isBarcodeWide = settings.isBarcodeWide || false
      this.isCustomDimensions = settings.isCustomDimensions || false
      this.quantity = Number(settings.quantity) || 39
      this.textSizeMultiplier = Number(settings.textSizeMultiplier) || 1
      this.ticketArrayWidthMm = Number(settings.ticketArrayWidthMm) || 210
      this.ticketWidthMm = Number(settings.ticketWidthMm) || 70
      this.ticketHeightMm = Number(settings.ticketHeightMm) || 20
      this.barcodePaddingHorizontalMm = Number(settings.barcodePaddingHorizontalMm) || 0
      this.barcodePaddingVerticalMm = Number(settings.barcodePaddingVerticalMm) || 0
      this.pageMarginTopMm = Number(settings.pageMarginTopMm) || 0
      this.pageMarginLeftMm = Number(settings.pageMarginLeftMm) || 0
      this.qrHeightMm = Number(settings.qrHeightMm) || 0
    }
  }

  saveViewSettings(): void {
    const settings = {
      printType: this.printType,
      printTypeMultiToLabelPrinter: this.printTypeMultiToLabelPrinter,
      codeType: this.codeType,
      isBarcodeTitleVisible: this.isBarcodeTitleVisible,
      isBarcodeEanVisible: this.isBarcodeEanVisible,
      isBarcodePriceVisible: this.isBarcodePriceVisible,
      isBarcodeWide: this.isBarcodeWide,
      isCustomDimensions: this.isCustomDimensions,
      quantity: this.quantity,
      textSizeMultiplier: this.textSizeMultiplier,
      ticketArrayWidthMm: this.ticketArrayWidthMm,
      ticketWidthMm: this.ticketWidthMm,
      ticketHeightMm: this.ticketHeightMm,
      barcodePaddingHorizontalMm: this.barcodePaddingHorizontalMm,
      barcodePaddingVerticalMm: this.barcodePaddingVerticalMm,
      pageMarginTopMm: this.pageMarginTopMm,
      pageMarginLeftMm: this.pageMarginLeftMm,
      qrHeightMm: this.qrHeightMm,
    }
    this.core.services.storage.set({ key: 'views_print_code', type: 'Object' }, settings)
  }

  async hydrate() {
    this.multiTicketData = []
    this.finalCode = ''
    this.labelTitle = ''
    this.labelEan = ''
    this.labelPrice = ''
    if (window['dataQR']) {
      this.isGS1 = true
      this.customTitle = window['dataQR'].title || this.productDetailService?.product?.fullName || ''
      this.customEan = window['dataQR'].ean
      this.customBatch = window['dataQR'].batch
      this.customExpirationDate = window['dataQR'].expirationDate
      window['dataQR'] = undefined
    }
    if (this.isGS1) {
      this.printType = 'single'
      this.codeType = 'qr'
      this.isCustomData = true
      this.labelTitle = this.customTitle
      this.labelEan = this.customEan
      this.labelPrice = this.fmtPricePipe.transform(this.customPrice)
      this.singleTicketData = {
        finalCode: '',
        labelTitle: this.labelTitle,
        labelEan: this.labelEan,
        labelPrice: this.labelPrice,
        base64: '',
      }
      return this.generateGS1QR()
    }
    const codeType = this.codeType === 'barcode' ? 'barcodeBase64' : 'qrBase64'
    if (this.productMovePack?.moves?.length) {
      this.printType = 'multi'
      if (this.isPrintingSerialNumbers) {
        this.productMovePack.moves.forEach(async m => {
          if (m.isEANPrintable) {
            if (m.serialNumbers?.length) {
              m.serialNumbers.forEach(async sn => {
                this.multiTicketData.push({
                  finalCode: sn,
                  labelTitle: m.product.fullName,
                  labelEan: `SN: ${sn} (EAN: ${m.product.ean})`,
                  labelPrice: this.fmtPricePipe.transform(m.product.sellPrice, m.product.purchaseCurrency),
                  base64: (await this.generateCode(sn))[codeType],
                })
              })
            } else {
              for (let x = 0; x < m.amount; x++) {
                this.multiTicketData.push({
                  finalCode: m.product.ean,
                  labelTitle: m.product.fullName,
                  labelEan: m.product.ean,
                  labelPrice: this.fmtPricePipe.transform(m.product.sellPrice, m.product.purchaseCurrency),
                  base64: (await this.generateCode(m.product.ean))[codeType],
                })
              }
            }
          }
        })
      } else {
        this.productMovePack.moves.forEach(async m => {
          if (m.isEANPrintable) {
            for (let x = 0; x < m.amount; x++) {
              this.multiTicketData.push({
                finalCode: m.product.ean,
                labelTitle: m.product.fullName,
                labelEan: m.product.ean,
                labelPrice: this.fmtPricePipe.transform(m.product.sellPrice, m.product.purchaseCurrency),
                base64: (await this.generateCode(m.product.ean))[codeType],
              })
            }
          }
        })
      }
    } else if (this.isCustomData) {
      this.finalCode = this.customEan
      this.labelTitle = this.customTitle
      this.labelEan = this.customEan
      this.labelPrice = this.fmtPricePipe.transform(this.customPrice)
    } else if (this.product?.id) {
      this.finalCode = this.customEan = this.product.ean
      this.labelTitle = this.customTitle = this.product.fullName
      this.labelEan = this.product.ean
      this.labelPrice = this.fmtPricePipe.transform(this.product.sellPrice, this.product.purchaseCurrency)
      this.customPrice = `${this.product.sellPrice}`
    }
    this.singleTicketData = {
      finalCode: this.finalCode,
      labelTitle: this.labelTitle,
      labelEan: this.labelEan,
      labelPrice: this.labelPrice,
      base64: this.finalCode?.length ? (await this.generateCode(this.finalCode))[codeType] : '',
    }
    if (this.doRecalculateArray) {
      this.recalculateArray()
    }
  }

  updateStartAt(field: any): void {
    // todo remove
    this.startAt = field.id
    this.recalculateArray()
  }

  async recalculateArray(): Promise<void> {
    if (this.printType === 'multi' && !this.multiTicketData?.length) {
      const codeType = this.codeType === 'barcode' ? 'barcodeBase64' : 'qrBase64'
      this.multiTicketData = []
      const quantity = 0
      for (let x = 0; x < this.quantity; x++) {
        // let value: string = null
        // if (x >= this.startAt && quantity < this.quantity) {
        //   value = this.barcodeBase64
        //   quantity++
        // }
        this.multiTicketData.push({
          finalCode: this.finalCode,
          labelTitle: this.labelTitle,
          labelEan: this.labelEan,
          labelPrice: this.labelPrice,
          base64: this.finalCode?.length ? (await this.generateCode(this.finalCode))[codeType] : '',
        })
      }
    }
    // this.printArray = []
    // let quantity = 0
    // for (let x = 0; x < 39; x++) {
    //   let value: string = null
    //   if (x >= this.startAt && quantity < this.quantity) {
    //     value = this.barcodeBase64
    //     quantity++
    //   }
    //   this.printArray.push({
    //     id: x,
    //     val: value,
    //   })
    // }
    // }
  }

  onSettingChange(): void {
    this.saveViewSettings()
    this.hydrate()
  }

  async generateCode(finalCode: string): Promise<{ barcodeBase64: string; qrBase64: string }> {
    JsBarcode(this.barcode.nativeElement, finalCode, {
      lineColor: '#000',
      width: this.isBarcodeWide ? 2 : 1,
      height: 25,
      displayValue: false,
    })
    this.barcodeBase64 = this.barcode.nativeElement.toDataURL()
    this.qrBase64 = await QRCode.toDataURL(finalCode.split('(').join('').split(')').join(''))
    return { barcodeBase64: this.barcodeBase64, qrBase64: this.qrBase64 }
  }

  async generateGS1QR() {
    try {
      const padZero = (s: string, len: number) => {
        return s.length === len ? s : padZero(`0${s}`, len)
      }
      const paddedEan = this.customEan.length === 14 ? this.customEan : padZero(this.customEan, 14)
      const expDate =
        this.customExpirationDate?.length >= 10
          ? `${this.customExpirationDate[2]}${this.customExpirationDate[3]}${this.customExpirationDate[5]}${this.customExpirationDate[6]}${this.customExpirationDate[8]}${this.customExpirationDate[9]}`
          : null
      this.finalCode = `${this.customEan}`
      if (this.customBatch?.length > 0 || this.customExpirationDate?.length > 0) {
        this.finalCode = `(01)${this.customEan}${expDate?.length ? `(17)${expDate}` : ''}${this.customBatch?.length ? `(10)${this.customBatch}` : ''}`
      }
      this.qrBase64 = await QRCode.toDataURL(this.finalCode.split('(').join('').split(')').join(''))
      this.singleTicketData.base64 = this.qrBase64
      console.log('gs1', this.finalCode)
    } catch (err) {
      this.notificationService.error(this.translateService.instant('qrcode.no-ean'))
      console.error(err)
    }
  }

  confirmPreview(): void {
    const pWidth = 900
    const pHeight = 720
    const popupWin = window.open(
      '',
      'Tisk štítků',
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${pWidth},height=${pHeight},top=${
        screen.height - (screen.height + pHeight) / 2
      },left=${screen.width - (screen.width + pWidth) / 2}`
    )
    popupWin.document.body.innerHTML =
      this.printType === 'single'
        ? this.printableEl.nativeElement.innerHTML
        : this.printTypeMultiToLabelPrinter
          ? this.printableMultiToLabelPrinterEl.nativeElement.innerHTML
          : this.printableMultiEl.nativeElement.innerHTML
    const styles = document.createElement('style')
    styles.innerHTML = ticketPrintableStyles(!this.printTypeMultiToLabelPrinter)
    popupWin.document.head.appendChild(styles)
    setTimeout(() => {
      popupWin.print()
    }, 400)
    setTimeout(() => {
      popupWin.close()
    }, 800)
  }
}
