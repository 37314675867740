<div class="app-container">
  <page-header
    [title]="isCreationModeEnabled ? 'purchase.new' : ('purchase.detail' | translate) + ' ' + purchaseOrder?.number"
  >
    <ng-container *ngIf="!loading && !isCreationModeEnabled" class="custom-title">
      <div
        class="status-div margin-left"
        [ngClass]="'status-' + purchaseOrder?.status?.id"
        [title]="purchaseOrder?.status?.name | translate"
      >
        {{ purchaseOrder?.status?.name | translate }}
      </div>
    </ng-container>
    <ng-container class="header-button">
      <btn
        [label]="'save'"
        [title]="'save' | translate"
        [classes]="['btn-first']"
        [icon]="'far-check'"
        [spinnerClassesString]="'spinner-white'"
        [btnType]="'submit'"
        (click)="create()"
        [btnDisabled]="
          !(
            this.purchaseOrderInfo.dateEstimatedArrival &&
            this.purchaseOrderInfo.supplier &&
            this.purchaseOrderInfo.depot
          ) || this.areFilesUploading
        "
        [dataCyAttribute]="'create-purchase-order-button'"
      ></btn>
      <btn
        *ngIf="!isCreationModeEnabled"
        [label]="'purchase-order.delete-label'"
        [title]="'purchase-order.delete-label' | translate"
        [classes]="['btn-first-red', 'me-2', 'float-right']"
        [isLoading]="loading"
        [icon]="'far-trash-alt'"
        (onEvent)="deletePurchaseOrder()"
        data-cy="delete-purchase-order"
      ></btn>
    </ng-container>
    <ng-container class="back-button">
      <btn
        [label]="'back'"
        [title]="'back' | translate"
        [isBackButton]="true"
        [classes]="['btn-third', 'float-right', 'me-2']"
        [icon]="'far-arrow-left'"
        [routerLink]="['/purchase-orders']"
      ></btn>
    </ng-container>
  </page-header>
  <section class="content">
    <loading-spinner [isLoading]="loading" [classesString]="'top-25'"></loading-spinner>
    <div class="row" *ngIf="!loading">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 h-25 ps-0">
        <div class="card">
          <div class="row">
            <div class="col-6 align-self-center">
              <span class="card-title text-nowrap">
                {{ 'purchase.product-list' | translate }}
              </span>
            </div>
            <div class="col-6 d-flex flex-row-reverse">
              <modal-product-choose-purchase
                (chosenProduct)="addProduct($event)"
                data-cy="product-choose"
              ></modal-product-choose-purchase>
            </div>
          </div>
          <p *ngIf="!productList?.length" class="text-align-center">{{ 'no.items' | translate }}</p>
          <div *ngIf="productList?.length" class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="ps-0">{{ 'product.name' | translate }}</th>
                  <th class="text-nowrap text-end">{{ 'purchase.prive-without-vat' | translate }}</th>
                  <th class="text-nowrap text-end">{{ 'numberOfItems' | translate }}</th>
                  <th class="text-nowrap text-end">{{ 'done' | translate }}</th>
                  <th class="text-nowrap text-end">{{ 'price.total' | translate }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of productList"
                  [ngClass]="
                    item.quantity === item.quantityIn
                      ? 'colorGrey'
                      : item.quantityIn > 0 && item.quantityIn < item.quantity
                        ? 'colorYellow'
                        : ''
                  "
                >
                  <td class="ps-1 text-start">
                    <div class="flex-gap-10 align-items-center">
                      <div
                        [photoGallery]="item.mainImage?.url"
                        [photoGalleryCaption]="
                          item.fullName +
                          '<br>' +
                          (item.code ? ('product.code' | translate) + item.code : '') +
                          '<br>' +
                          (item.ean ? ('product.ean' | translate) + item.ean : '')
                        "
                      >
                        <div class="images-item">
                          <img class="images-item-image" [src]="getThumbUrl(item.mainImage)" [alt]="item.fullName" />
                        </div>
                      </div>
                      <div>
                        <a class="link-bold" [routerLink]="['/product', item.product]">{{ item.fullName }}</a>
                        <div class="product-details flex-gap-5 flex-wrap">
                          <div *ngIf="item.code && item.code.length > 0">
                            {{ 'product.code' | translate }}:<span> {{ item.code }}</span>
                          </div>
                          <div *ngIf="item.ean && item.ean.length > 0">
                            {{ 'product.ean' | translate }}:<span> {{ item.ean }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-end">{{ item.purchasePrice | formatPrice: item.currency }}</td>
                  <td class="text-end">{{ item.quantity | number: '1.0-2' | replaceCommaToDot }}</td>
                  <td class="text-end">{{ item.quantityIn | number: '1.0-2' | replaceCommaToDot }}</td>
                  <td class="text-end">{{ item.purchasePriceAll | formatPrice: item.currency }}</td>
                  <td class="text-end text-nowrap">
                    <btn
                      [icon]="'far-pencil'"
                      [title]="'edit' | translate"
                      [classes]="['me-2', 'btn-edit', 'btn-sm']"
                      (onEvent)="modalStockOperation.showProductOnChildModal(item)"
                    ></btn>
                    <btn
                      [title]="'delete' | translate"
                      [icon]="'far-trash-alt'"
                      [classes]="['btn-sm', 'btn-trash']"
                      (click)="remove(item)"
                    ></btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="!isCreationModeEnabled" class="card">
          <span class="card-title text-nowrap">
            {{ 'product.moves' | translate }}
          </span>
          <app-base-listing
            [listName]="'productMovePacksPurchaseOrder'"
            [storageKey]="storageKey"
            [loadingNextItems]="loading"
            [loadingItems]="reloading"
            [items]="productMovePacks"
            [columns]="listingColumns"
            [totalItems]="totalItems"
            [currentPage]="currentPage"
            [endPage]="endPage"
            [(sortProp)]="sortProp"
            [(sortDir)]="sortDir"
            (reloadList)="filter()"
            (switchPage)="filter($event.currentPage, $event.sortProp, $event.sortDir, $event.concat)"
            (callAction)="callAction($event)"
          ></app-base-listing>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 pe-0">
        <div class="card">
          <form-row-select
            [label]="'purchase.depot-to'"
            [options]="depots"
            [(ngModel)]="purchaseOrderInfo.depot"
            [required]="true"
            #depot="ngModel"
          ></form-row-select>
          <form-row-select
            [label]="'supplier'"
            [options]="suppliers"
            [(ngModel)]="purchaseOrderInfo.supplier"
            [dataCyAttribute]="'supplier-select'"
            [required]="true"
            #supplier="ngModel"
          ></form-row-select>
          <form-row-input
            [label]="'purchase.reference'"
            [icon]="null"
            [(model)]="purchaseOrderInfo.externalReference"
          ></form-row-input>
          <form-row-input
            [label]="'purchase.supplierReference'"
            [icon]="null"
            [(model)]="purchaseOrderInfo.supplierReference"
          ></form-row-input>
          <form-row-input
            *ngIf="!isCreationModeEnabled"
            [label]="'purchase.date-created'"
            [icon]="null"
            [inputType]="'date'"
            [inputDisabled]="true"
            [(model)]="purchaseOrderInfo.created"
          ></form-row-input>
          <form-row-input
            [label]="'purchase.expected-date'"
            [icon]="null"
            [inputType]="'date'"
            [minimumVal]="purchaseOrderInfo.created"
            [(ngModel)]="purchaseOrderInfo.dateEstimatedArrival"
            [required]="true"
            [dataCyAttribute]="'dateEstimatedArrival-input'"
            #dateEstimatedArrival="ngModel"
          ></form-row-input>
          <form-row-input
            *ngIf="!isCreationModeEnabled"
            [label]="'createdBy'"
            [icon]="null"
            [(model)]="purchaseOrderInfo.createdBy"
            [inputDisabled]="true"
          ></form-row-input>
          <form-row-select-multiple
            [label]="'tags'"
            [options]="tags"
            [(selectedValues)]="purchaseOrderInfo.tags"
          ></form-row-select-multiple>
          <form-row-textarea
            [icon]="'far-note'"
            [label]="'note'"
            [(model)]="purchaseOrderInfo.note"
          ></form-row-textarea>
          <form-row-textarea
            [icon]="'far-note'"
            [label]="'note.private'"
            [(model)]="purchaseOrderInfo.privateNote"
          ></form-row-textarea>
        </div>
        <app-order-files
          [isOnPurchaseOrder]="true"
          [(purchaseOrder)]="purchaseOrder"
          [(isEditedFiles)]="isEditedFiles"
          (areFilesUploadingChange)="areFilesUploading = $event"
          (onFileAdded)="fileAdded($event)"
        >
        </app-order-files>
      </div>
    </div>
  </section>
</div>

<modal-stock-operation-card-purchase (result)="edit($event)"></modal-stock-operation-card-purchase>
