import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router'
import { CoreService } from './core.service'
import { OAuthSession, OAuthToken } from 'depoto-core/src/models'
import { NotificationService } from './notification.service'
import { SchemaUtil } from '../utils'
import { User } from 'depoto-core/src/entities/User'
import { Events } from 'depoto-core/src/utils'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  session: OAuthSession

  constructor(
    private router: Router,
    private core: CoreService,
    public notificationService: NotificationService,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.route.queryParams.subscribe(params => {
      if (!params?.access_token || !params?.refresh_token) {
        return
      }

      this.core.services.oauth.session = new OAuthSession({
        clientType: (this.session.clientType = this.core.services.storage.getSync(
          this.core.services.storage.keys.auth.clientType
        )),
        token: new OAuthToken({
          accessToken: params.access_token,
          refreshToken: params.refresh_token,
          tokenType: params.token_type,
          scope: params.scope,
          expiresIn: params.expires_in,
        }),
      })

      this.loadUserProfile().then(() => {
        this.core.services.oauth.session.isAuthenticated = true
        this.router.navigate(['/home'])
      })
    })
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.core.services.http.abortAllRequests()
    if (route.routeConfig.path === 'external-login') {
      try {
        this.session = new OAuthSession()
        this.session.token.accessToken = route.queryParams.access_token
        this.session.token.refreshToken = route.queryParams.refresh_token
        this.session.token.tokenType = route.queryParams.token_type
        this.session.token.scope = route.queryParams.scope
        this.session.token.expiresIn = Number(route.queryParams.expires_in)
        this.session.token.timestamp = +new Date()
        this.session.clientType = this.core.services.storage.getSync(this.core.services.storage.keys.auth.clientType)
        this.session.isAuthenticated = true
        this.session.isRememberMe = true
        this.core.services.oauth.session = this.session
        this.core.services.storage.set(this.core.services.storage.keys.auth.data, {
          token: {
            accessToken: route.queryParams.access_token,
            refreshToken: route.queryParams.refresh_token,
            expiresIn: Number(route.queryParams.expires_in),
            scope: route.queryParams.scope,
            tokenType: route.queryParams.token_type,
          },
          clientType: 'prod',
        })
        this.core.services.storage.set(
          this.core.services.storage.keys.auth.isAuthenticated,
          this.session.isAuthenticated
        )
        this.core.services.storage.set(this.core.services.storage.keys.auth.clientType, this.session.clientType)
        this.core.services.storage.set(this.core.services.storage.keys.auth.rememberMe, this.session.isRememberMe)
      } catch (err) {
        console.error(err)
      } finally {
        const userProfile = await this.core.services.user.getMyProfile(SchemaUtil.userProfile)
        const user = new User(userProfile)
        this.core.services.storage.set(this.core.services.storage.keys.auth.company, user.company)
        this.core.services.storage.set(this.core.services.storage.keys.auth.email, user.email)
        await this.core.services.user.setCurrentUser(user.email)
        // location.pathname = 'home'
        this.router.navigate(['/home'])
      }
      return true
    }

    type SessionData = {
      clientType: string
      token: any
    }
    const sessionDataInLocalStorage: SessionData = await this.core.services.storage.get({
      key: 'auth_data',
      type: 'Object',
    })
    if (sessionDataInLocalStorage?.token) {
      this.core.services.oauth.session.clientType = sessionDataInLocalStorage.clientType
      this.core.services.oauth.session.token = sessionDataInLocalStorage.token
    }

    this.core.services.oauth.session.clientType = this.core.services.storage.getSync(
      this.core.services.storage.keys.auth.clientType
    )
    if (!this.core.services.user.user?.id) {
      await this.loadUserProfile()
    }
    await this.checkRoles(route, state)
    return true // embedded login
  }

  private async checkRoles(route, state) {
    if (
      !this.core.services.user.hasRole('ROLE_SUPER_ADMIN') &&
      !this.core.services.user.hasRole('ROLE_ADMIN') &&
      ((state.url === '/products' && !this.core.services.user.hasRole('ROLE_PRODUCT_LIST')) ||
        (state.url === '/product' && !this.core.services.user.hasRole('ROLE_PRODUCT_CREATE')) ||
        (route.routeConfig.path === 'product/:id' && !this.core.services.user.hasRole('ROLE_PRODUCT_SHOW')) ||
        (state.url === '/stock-batch-out' && !this.core.services.user.hasRole('CLIENT_MENU_OUT')) ||
        (state.url === '/stock-batch-in' && !this.core.services.user.hasRole('CLIENT_MENU_IN')) ||
        (state.url === '/stock-batch-transfer' && !this.core.services.user.hasRole('CLIENT_MENU_TRANSFER')) ||
        ((state.url === '/product-move-packs' || state.url === '/product-move-pack') &&
          !this.core.services.user.hasRole('ROLE_PRODUCT_MOVE_PACK_LIST')) ||
        (route.routeConfig.path === 'product-move-pack/:id' &&
          !this.core.services.user.hasRole('ROLE_PRODUCT_MOVE_PACK_SHOW')) ||
        (state.url === '/label-print' && !this.core.services.user.hasRole('CLIENT_MENU_LABELS')) ||
        ((state.url === '/inventory-exports' || route.routeConfig.path === 'inventory-detail/:id') &&
          !this.core.services.user.hasRole('CLIENT_MENU_INVENTORY')) ||
        ((state.url === '/users' || route.routeConfig.path === '/user') &&
          !this.core.services.user.hasRole('ROLE_USER_LIST')) ||
        (route.routeConfig.path === 'user/:id' && !this.core.services.user.hasRole('ROLE_USER_SHOW')) ||
        ((state.url === '/categories' || state.url === '/category') &&
          !this.core.services.user.hasRole('ROLE_CATEGORY_LIST')) ||
        (route.routeConfig.path === 'category/:id' && !this.core.services.user.hasRole('ROLE_CATEGORY_SHOW')) ||
        ((state.url === '/depots' || state.url === '/depot') && !this.core.services.user.hasRole('ROLE_DEPOT_LIST')) ||
        (route.routeConfig.path === 'depot/:id' && !this.core.services.user.hasRole('ROLE_DEPOT_SHOW')) ||
        ((state.url === '/institutions' || state.url === '/institution') &&
          !this.core.services.user.hasRole('ROLE_INSTITUTION_SHOW')) ||
        (route.routeConfig.path === 'institution/:id' && !this.core.services.user.hasRole('ROLE_INSTITUTION_SHOW')) ||
        ((state.url === '/surgeries' || state.url === '/surgery') &&
          !this.core.services.user.hasRole('ROLE_CHECKOUT_LIST')) ||
        (route.routeConfig.path === 'surgery/:id' && !this.core.services.user.hasRole('ROLE_CHECKOUT_SHOW')) ||
        ((state.url === '/suppliers' || state.url === '/supplier') &&
          !this.core.services.user.hasRole('ROLE_SUPPLIER_LIST')) ||
        (route.routeConfig.path === 'supplier/:id' && !this.core.services.user.hasRole('ROLE_SUPPLIER_SHOW')))
    ) {
      this.notificationService.error(this.translateService.instant('access-denied'))
      return false
    } else return false
  }

  async loadUserProfile() {
    this.core.services.user.currentUserEmitter.subscribe().then(user => {
      if (user?.id > 0) {
        Events.dispatch('app:user:loaded')
        this.core.loadLists()
      }
    })

    let user: User | undefined
    try {
      user = await this.core.services.user.getMyProfile(SchemaUtil.userProfile)
    } catch (e) {
      console.warn(e)
      if (e && e.status === 401) {
        // Sentry.captureException(e)
      }
    }
    if (user?.email) {
      this.core.services.storage.set(
        this.core.services.storage.keys.auth.companyCarriers,
        user.company.carrierRelations
      )
      this.core.services.user.afterSetCurrentUser(user)
    } else {
      this.core.services.oauth.logout()
    }
  }
}
