import { EventEmitter, Injectable, Output } from '@angular/core'
import { DepotoCore } from 'depoto-core/DepotoCore'
import { ServiceContainer } from 'depoto-core/src/interfaces'
import {
  Carrier,
  Category,
  Checkout,
  Currency,
  Depot,
  Parameter,
  Payment,
  Producer,
  Supplier,
  Tag,
  Vat,
} from 'depoto-core/src/entities'
import { OptionsConfig } from '../configs'
import { TranslateService } from '@ngx-translate/core'
import { SchemaUtil } from '../utils'

type CoreEntityLists = {
  carriers: Carrier[]
  categories: Category[]
  carriersFromCompanyCarriers: Carrier[]
  currencies: Currency[]
  checkouts: Checkout[]
  depots: Depot[]
  allDepots: Depot[]
  eventTypes: string[]
  parameters: Parameter[]
  payments: Payment[]
  producers: Producer[]
  suppliers: Supplier[]
  tags: Tag[]
  vats: Vat[]
}

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  private core: DepotoCore
  private entityLists: CoreEntityLists = {
    carriers: [],
    categories: [],
    carriersFromCompanyCarriers: [],
    currencies: [],
    checkouts: [],
    depots: [],
    allDepots: [],
    eventTypes: [],
    parameters: [],
    payments: [],
    producers: [],
    suppliers: [],
    tags: [],
    vats: [],
  }
  @Output() public onCoreEntityListsLoaded: EventEmitter<CoreEntityLists> = new EventEmitter()

  public userHasAllDepots = false
  public userHasAllCheckouts = false
  constructor(private readonly translateService: TranslateService) {
    this.core = new DepotoCore(OptionsConfig.strings.app_prefix, null, null, null)
  }

  get services(): ServiceContainer {
    return this.core.services
  }

  get lists(): CoreEntityLists {
    return this.entityLists
  }

  private setEntityLists(lists: CoreEntityLists): void {
    this.entityLists = lists
    this.onCoreEntityListsLoaded.emit(this.entityLists)
  }

  async loadLists() {
    const results: CoreEntityLists = {
      carriers: [],
      categories: [],
      carriersFromCompanyCarriers: [],
      currencies: [],
      checkouts: [],
      depots: [],
      allDepots: [],
      eventTypes: [],
      parameters: [],
      payments: [],
      producers: [],
      suppliers: [],
      tags: [],
      vats: [],
    }
    const promises = [
      this.services.carrier.getList({}, { id: null, name: null }).then(result => result?.items ?? []),
      this.services.category
        .getList({ direction: 'ASC', filters: { hasParent: false } }, { id: null, name: null })
        .then(result => result?.items ?? []),
      this.services.companyCarrier
        .getList({}, { id: null, carrier: SchemaUtil.carrier })
        .then(result => (result?.items ?? []).map(companyCarrier => companyCarrier.carrier)),
      this.services.payment
        .getCurrencies({}, { id: null, name: null, ratio: null })
        .then(result => result?.items ?? []),
      // if role salesman, fetch just salesman's checkouts
      this.services.checkout
        .getList(
          {
            page: 1,
            sort: 'id',
            direction: 'asc',
          },
          { id: null, name: null }
        )
        .then(result => result?.items ?? []),
      // if role salesman, fetch just depots with salesman's checkouts
      this.services.depot
        .getList(
          {
            sort: 'name',
            direction: 'asc',
          },
          { id: null, name: null }
        )
        .then(result => result?.items ?? []),
      this.services.checkout.getEventTypes().then(result => result?.items ?? []),
      this.services.parameter.getList({}, { id: null, name: null }).then(result => result?.items ?? []),
      this.services.payment
        .getList({}, { id: null, name: null, type: { id: null } })
        .then(result => result?.items ?? []),
      this.services.producer
        .getList({ sort: 'name', direction: 'asc' }, { id: null, name: null })
        .then(result => result?.items ?? []),
      this.services.supplier
        .getList({ sort: 'name', direction: 'asc' }, { id: null, name: null })
        .then(result => result?.items ?? []),
      this.services.tag.getList({}, { id: null, name: null, type: null }).then(result => result?.items ?? []),
      this.services.vat
        .getList({}, { id: null, name: null, percent: null, default: null })
        .then(result => result?.items ?? []),
    ]

    try {
      ;[
        results.carriers,
        results.categories,
        results.carriersFromCompanyCarriers,
        results.currencies,
        results.checkouts,
        results.depots,
        results.eventTypes,
        results.parameters,
        results.payments,
        results.producers,
        results.suppliers,
        results.tags,
        results.vats,
      ] = await Promise.all(promises)

      // depots are user's depots, we need also all
      results.allDepots = results.depots

      // set if we need filter user's depots/checkouts or he can see all
      this.userHasAllDepots = results.depots.every(depot => {
        return this.services.user.user?.depots.some(d => d.id === depot.id)
      })
      this.userHasAllCheckouts = results.checkouts.every(checkout => {
        this.services.user.user?.checkouts.find(ch => ch.id === checkout.id)
      })

      if (this.services.user.hasRole('ROLE_SALESMAN') && !this.services.user.user?.depots?.length) {
        // salesman with no depots can't see any
        results.depots = []
      } else if (!this.userHasAllDepots && this.services.user.user?.depots?.length) {
        // if not all or nothing, show just user's depots
        results.depots = this.services.user.user.depots
      }
      if (this.services.user.hasRole('ROLE_SALESMAN') && !this.services.user.user?.checkouts?.length) {
        // salesman with no checkouts can't see any
        results.checkouts = []
      } else if (!this.userHasAllCheckouts && this.services.user.user?.checkouts?.length) {
        // if not all or nothing, show just user's checkouts
        results.checkouts = this.services.user.user.checkouts
      }

      this.setEntityLists(results)
    } catch (err) {
      if (err.name === 'AbortError') {
        console.warn('The user aborted a request.')
      } else {
        console.error({ message: 'core-service: ' + err.message, ...err })
      }
    }
  }

  cleanLocalStorage(email: string, log: 'login' | 'logout'): void {
    // not all keys will be deleted
    if (!('localStorage' in window)) {
      return
    }
    for (const key of Object.keys(localStorage)) {
      if (
        (log === 'login' &&
          key.endsWith(email) &&
          !key.startsWith(OptionsConfig.strings.app_prefix + this.services.storage.keys.views.baseListing.key)) ||
        // the last line preserves columns and sort directions
        // in case you want to preserve column only, change it to
        // key !== OptionsConfig.strings.app_prefix + this.services.storage.keys.views.baseListing.key+'.'+email
        (log === 'logout' && !key.includes('@')) ||
        // we have to repeat the last action onLogIn too (in case when the user is log-out by server)
        // but we must not delete auth-keys
        (log === 'login' &&
          !key.includes('@') &&
          !key.includes('_user_roles') &&
          !key.startsWith(OptionsConfig.strings.app_prefix + 'auth_'))
      ) {
        localStorage.removeItem(key)
      }
    }
  }

  getDefaultVat(): Vat {
    if (this.lists.vats?.length) {
      return this.lists.vats.filter(v => v.default)[0]
    }
    alert(this.translateService.instant('vat.not-loaded'))
    return null
  }
}
