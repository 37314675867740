import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, FormRowBase } from '../form-row-base/form-row-base'

@Component({
  selector: 'form-row-number-with-controls',
  templateUrl: './form-row-number-with-controls.component.html',
  styleUrls: ['./form-row-number-with-controls.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR(FormRowNumberWithControlsComponent)],
})
export class FormRowNumberWithControlsComponent extends FormRowBase {
  @Input() label: string
  @Input() minWidthPx = 0
  @Input() maxWidthPx: string | number = 'none'
  @Input() noMarginBottom = false
  @Input() model: string | number
  @Input() required: boolean
  @Input() requiredCssClass: any
  @Input() maxValue = undefined
  @Input() minValue = undefined
  @Input() dataCyAttribute = 'product-amount-input'
  @Output() modelChange: EventEmitter<any> = new EventEmitter()

  public onNgModelChange(value: number) {
    this.modelChange.emit(Number(value))
  }

  public deductOne() {
    if (this.minValue && this.model === this.minValue) return
    this.model = Number(this.model) - 1
    this.modelChange.emit(this.model)
  }

  public addOne() {
    if (this.maxValue && this.model === this.maxValue) return
    this.model = Number(this.model) + 1
    this.modelChange.emit(this.model)
  }

  checkCharacter(value: any) {
    if (value.key == '.') {
      value.key = ''
    }
  }
}
