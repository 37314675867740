<div class="card">
  <div>
    <table class="margin-bottom">
      <tbody *ngIf="productDetailService.parameters?.length">
        <tr *ngFor="let param of productDetailService.parameters">
          <td class="">
            {{ param.parameter.name }}
          </td>
          <td class="">
            <form-row-select
              *ngIf="param.parameter.type === 'enum'"
              [inputDisabled]="productDetailService.loading"
              [options]="getOptionsForEnum(param)"
              [(model)]="param.value"
              [noMarginBottom]="true"
              [dataCyAttribute]="'product-parameter-enum'"
            ></form-row-select>
            <form-row-select-multiple
              *ngIf="param.parameter.type === 'enum_multi'"
              [inputDisabled]="productDetailService.loading"
              [options]="param.parameter.enumValues"
              [(selectedValues)]="param.value"
              [dataCyAttribute]="'product-parameter-enum-multi'"
              [noMarginBottom]="true"
            ></form-row-select-multiple>
            <form-row-input
              *ngIf="param.parameter.type === 'text' || param.parameter.type === 'number'"
              [inputDisabled]="productDetailService.loading"
              [inputType]="param.parameter.type === 'text' ? 'text' : 'number'"
              [(model)]="param.value"
              [noMarginBottom]="true"
              [dataCyAttribute]="'product-parameter-string'"
            ></form-row-input>
            <form-row-select
              *ngIf="param.parameter.type === 'boolean'"
              [inputDisabled]="productDetailService.loading"
              [options]="isTrueFalseOptions"
              [(model)]="param.value"
              [noMarginBottom]="true"
              [dataCyAttribute]="'product-parameter-boolean'"
            ></form-row-select>
          </td>
          <td class="">
            {{ param.parameter.unit }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-12" *ngIf="!productDetailService.parameters || productDetailService.parameters?.length === 0">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <p class="m-0">
            {{ 'product-parameters.no-params.1' | translate }}
            <a [routerLink]="['/parameters']">{{ 'product-parameters.no-params.2' | translate }}</a
            >.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
